import React, { Fragment, useContext, useEffect } from 'react'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback } from 'reactstrap'
import { ContentContext } from './context'
import * as Icon from 'react-feather'
import { useForm, Controller } from "react-hook-form"
import { customStyles } from '../../../Globales/index'
const Formulario = () => {
    const { toggleModal, modal, StoreUpdate, opcion, oneData } = useContext(ContentContext),
        { handleSubmit, formState: { errors }, setValue, control, reset } = useForm(),
        onSubmit = (data) => {
            const json = {
                id: (oneData) ? oneData.id : null,
                nombre: data.nombre
            }
            StoreUpdate(json)
        },
        setData = async () => {
            await setValue('nombre', oneData.nombre)
        },
        clearData = async () => {
            reset({ nombre: '' })
        }

    useEffect(
        () => {
            async function fetchMyAPI() {
                if (await opcion > 1 && oneData) { await setData() }
                if (await opcion === 1) { clearData() }
            }

            fetchMyAPI()

        }, [opcion, oneData]
    )

    return (
        <Fragment>
            <Button onClick={() => toggleModal(1)} id="crear" color="primary" size="sm">
                <Icon.Plus size={16} />
            </Button>
            <UncontrolledTooltip placement='left' target='crear' placementPrefix="bs-tooltip">
                Crear
            </UncontrolledTooltip>

            <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 1 && 'crear'} Eje de Gobernanza</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for='nombre' className="fw-bolder">* Eje</Label>
                                    <Controller

                                        control={control}
                                        id='nombre'
                                        name='nombre'
                                        render={({ field }) => (
                                            <Input
                                                rows={3}
                                                type='textarea'
                                                readOnly={opcion === 2}
                                                invalid={errors.nombre && true}
                                                defaultValue={null}
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.nombre && errors.nombre.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Save size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion !== 2 && opcion === 1 ? 'Crear' : 'Actualizar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default Formulario