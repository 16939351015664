import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from "../../../assets/images/logo_oficial-1-SIN-AROS.png";

var base64 = null;
toDataURL(Logo, function (Base) {
  base64 = Base;
});

export default function PDFPrestamo(dataHeader, dataDetalle, dataAsignaciones) {

  // Inicializar PDF
  let doc = new jsPDF('p', 'pt', [500, 360]).setProperties({ title: 'PRESTAMO DE EQUIPO' }),
    columns = [],
    rows = [],
    ColorFact = [0, 0, 0],
    maxY = 0;

  const margin = 20; // Define el margen en puntos
  const pageWidth = doc.internal.pageSize.getWidth();
  const leftColWidth = 20; // Define el ancho de la columna izquierda
  const maxWidth = 344; // Define el ancho máximo para el texto

  function pageContent() {
    const fecha = dataHeader?.fechaEnvioARevision?.split('-');
    const toCapitalize = (text) => (text[0].toUpperCase()) + (text.substring(1));

    // Logo Empresa
    doc.setFontSize(6).setFont("helvetica", "bold");
    doc.addImage(base64, "PNG", 17, 20, 80, 42);

    doc.setFont("helvetica", "bold").text(20, 70, 'Solicitante:', { align: 'left', width: 140 });
    doc.setFont("helvetica", "normal").text(58, 70, dataHeader?.solicitantePersona === '' ? dataHeader?.solicitanteFADN : dataHeader?.solicitantePersona, { align: 'left', width: 140 });

    doc.setFont("helvetica", "bold").text(20, 80, 'Solicitud de:', { align: 'left', width: 140 });
    doc.setFont("helvetica", "normal").text(58, 80, dataHeader?.esFADN === 1 ? dataHeader?.nombreFADN : dataHeader?.nombreUnidad, { align: 'left', width: 140 });

    doc.setFont("helvetica", "bold").text(20, 90, 'Actividad o Evento:', { align: 'left', width: 140 });
    doc.setFont("helvetica", "normal").text(80, 90, dataDetalle?.descripcionNombreActividad, { align: 'left', width: 140 });

    doc.setFont("helvetica", "bold").text('Fecha Inicio Prestamo:', 20, 100);
    doc.setFont("helvetica", "normal").text(dataDetalle?.fechaUso2, 100, 100);

    // yPosition += 10;
    doc.setFont("helvetica", "bold").text('Fecha Fin Prestamo:', 20, 110);
    doc.setFont("helvetica", "normal").text(dataDetalle?.fechaDevolucion2, 100, 110);

    // Cuadro Documento Tributario Electrónico
    doc.setFillColor(ColorFact[0], ColorFact[1], ColorFact[2]);
    doc.setLineWidth(0.2).setDrawColor(ColorFact[0], ColorFact[1], ColorFact[2]).rect(208, 23, 135, 10, "F");
    doc.setFontSize(8).setTextColor(255).setFont("helvetica", "bold").text(275, 32, "Préstamo de Equipo - Informática", { align: 'center', width: 140 });
    doc.setFontSize(6);
    doc.setTextColor(0).setFont("helvetica", "bold").text(208, 40, `Código:`, { align: 'left', width: 140 });
    doc.setFontSize(6);
    doc.setFont("helvetica", "normal").text(268, 40, 'INF-FOR-02 V3', { align: 'left', width: 140 });

    // doc.setFont("helvetica", "bold").text(208, 52, `Para:`, { align: 'left', width: 140 });
    // doc.setFontSize(5);
    // doc.setFont("helvetica", "normal").text(268, 52, dataHeader?.tipoSolicitante === 1 ? 'Comité Olímpico Guatemalteco' : 'FADN', { align: 'left', width: 140 });
    doc.setFontSize(6);
    doc.setFont("helvetica", "bold").text(208, 52, `Correlativo:`, { align: 'left', width: 140 });
    doc.setFontSize(7);
    doc.setFont("helvetica", "normal").text(268, 52, dataHeader?.correlativo, { align: 'left', width: 140 });
    doc.setFontSize(6);

    // Fecha
    doc.setFillColor(ColorFact[0], ColorFact[1], ColorFact[2]);
    doc.setLineWidth(0.2).setDrawColor(ColorFact[0], ColorFact[1], ColorFact[2]).rect(208, 75, 135, 20);
    doc.setFillColor(ColorFact[0], ColorFact[1], ColorFact[2]);
    doc.setDrawColor(ColorFact[0], ColorFact[1], ColorFact[2]).rect(208, 73, 135, 10, "F");
    doc.setLineWidth(0.1).line(245, 73, 245, 95);
    doc.line(300, 73, 300, 95);

    doc.setTextColor(255).text(221, 80, `DÍA`, { align: 'left' });
    doc.text(265, 80, `MES`, { align: 'left' });
    doc.text(315, 80, `AÑO`, { align: 'left' });

    doc.setTextColor(0).text(223, 90, fecha[2]?.split('T')[0], { align: 'left' });
    doc.text(268, 90, fecha[1], { align: 'left' });
    doc.text(315, 90, fecha[0], { align: 'left' });

    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(5);
    doc.text('Página ' + pageCount, doc.internal.pageSize.width - 39, doc.internal.pageSize.height - 40);
  }

  function didDrawCell(data) {
    if (data.section === 'body') {
      maxY = Math.max(data?.cell.y + data?.cell.height);
    }
  }

  // Definición de la función drawLines
  function drawLines() {
    doc.setDrawColor(0, 0, 0); // Establecer el color de la línea
    doc.setLineWidth(0.1); // Establecer el ancho de la línea

    // Dibuja las líneas verticales usando la posición 'y' más baja registrada
    doc.line(20, 233, 20, maxY);
    doc.line(133, 233, 133, maxY);
    doc.line(343, 233, 343, maxY);

    // Línea horizontal final
    doc.line(20, maxY, 343, maxY);
  }
  // Aquí va el código que necesitas antes de la tabla
  let y = 130; // Coordenada Y inicial

  // Descripción de Solicitud
  doc.setFontSize(6);
  doc.setFont("helvetica", "bold").text(margin + 5, y, 'Descripción de Solicitud: ', { align: 'left', width: 140 });
  doc.setFontSize(4.5);
  const text = dataHeader?.descripcion;
  const lines = doc.splitTextToSize(text, 333); // Divide el texto en líneas
  // Calcular altura del bloque de texto para el rectángulo
  let rectHeight = 38; // Ajusta para margen interno del rectángulo
  
  // Dibujar el rectángulo alrededor del texto
  doc.setFillColor(255, 255, 255); // Color blanco para el fondo del rectángulo
  doc.setDrawColor(ColorFact[0], ColorFact[1], ColorFact[2]);
  doc.setLineWidth(0.5).rect(20, y - 6, 323, rectHeight); // Dibuja el rectángulo
  const lineHeight = 5;

  // Añadir el texto dentro del rectángulo
  lines.forEach((line, i) => {
    doc.setFont("helvetica", "normal").text(line, margin + 5, y + 10 + (i * lineHeight)); // Ajusta el multiplicador de 'i' según el espacio deseado entre líneas
  });

  y += rectHeight + 10; // Incrementa Y para la siguiente sección y da espacio adicional

  // Detalle de equipo solicitado
  doc.setFontSize(6);
  doc.setFont("helvetica", "bold").text(margin + 5, y, 'Detalle de equipo solicitado:', { align: 'left', width: 140 });
  doc.setFontSize(4.5);
  const text2 = dataDetalle?.descripcionEQSol;
  const lines2 = doc.splitTextToSize(text2, 333); // Divide el texto en líneas

  // Calcular altura del bloque de texto para el rectángulo
  let rectHeight2 = 38;  // Ajusta para margen interno del rectángulo

  // Dibujar el rectángulo alrededor del texto
  doc.setFillColor(255, 255, 255); // Color blanco para el fondo del rectángulo
  doc.setDrawColor(ColorFact[0], ColorFact[1], ColorFact[2]);
  doc.setLineWidth(0.5).rect(20, y - 6, 323, rectHeight2); // Dibuja el rectángulo

  // Añadir el texto dentro del rectángulo
  lines2.forEach((line, i) => {
    doc.setFont("helvetica", "normal").text(line, margin + 5, y + 10 + (i * lineHeight));
  });

  y += rectHeight2 + 17; // Incrementa Y para la siguiente sección

  // Información adicional
  let yPosition = y + ((y) - 247);


  // Ahora, dibuja la tabla de asignaciones justo debajo del texto anterior
  columns = [['TECNICO ASIGNADO', 'COMENTARIO']];
  dataAsignaciones.length !== 0 && dataAsignaciones.map((item, i) => {
    if (i === dataAsignaciones.length - 1) {
      rows[i] = [
        item.persona,
        item.comentarioTecnico
      ];
    } else {
      rows[i] = [
        item.persona,
        item.comentarioTecnico
      ];
      rows[i + 1] = [];
    }
  });

  doc.autoTable({
    head: columns,
    body: rows,
    tableWidth: 'auto',
    headStyles: {
      fillColor: [ColorFact[0], ColorFact[1], ColorFact[2]],
      textColor: [255],
      valign: 'middle',
      halign: 'center'
    },
    styles: {
      fontSize: 5,
      fillColor: false,
      lineWidth: 0,
      lineColor: 48,
      textColor: 20,
      pageBreak: 'auto',
      overFlow: 'linebreak',
    },
    columnStyles: {
      0: { cellWidth: 115, halign: 'center' },
      1: { halign: 'center', width: 'wrap' },
    },
    didDrawCell: didDrawCell,
    didDrawPage: function (data) {
      pageContent(data);
      drawLines(); // Llama a drawLines aquí para dibujar las líneas después de la tabla
    },
    margin: {
      left: margin,
      right: margin - 3,
      top: 112,
      bottom: 110
    },
    startY: yPosition, // Ajusta la posición inicial en Y
  });

  // Ajustes para las firmas y demás contenido
  const firmaColumnWidthTop = pageWidth / 2; // Ancho de la columna para la fila superior
  const firmaColumnWidthBottom = pageWidth / 3; // Ancho de la columna para la fila inferior
  const firmaHeight = 40; // Espacio vertical para cada firma, aumentado para dos filas
  const firmaStartY = doc.lastAutoTable.finalY + 128;
  const firmaStartYTop = firmaStartY + 10; // Ajusta la altura para insertar la nueva firma arriba
  const firmaStartYAdjusted = firmaStartYTop - 70; // Desplazar las firmas hacia abajo
  const firmantesTop = [
    { titulo: "Solicitante", nombre: dataHeader?.solicitantePersona },
    { titulo: "Técnico quién entrega", nombre: dataAsignaciones[0]?.persona },
    { titulo: "Vo. Bo. Dirección de Informática", nombre: dataAsignaciones[0]?.aprobador }
  ];

  // Ajustar la posición de las firmas
  // Dibujar las firmas de la fila inferior
  const bottomRowY = firmaStartY + firmaHeight - 100; // Posición inicial de la fila inferior de firmas
  firmantesTop.forEach((firmante, index) => {
    const x = (index * firmaColumnWidthBottom + firmaColumnWidthBottom) - 120;
    doc.setLineWidth(0.5).line(x + 10, bottomRowY, x + firmaColumnWidthBottom - 10, bottomRowY);
    doc.setFont("helvetica", "normal").text(x + firmaColumnWidthBottom / 2, bottomRowY + 7, firmante.nombre, { align: 'center' });
    doc.setFont("helvetica", "bold").text(x + firmaColumnWidthBottom / 2, bottomRowY + 14, firmante.titulo, { align: 'center' });
  });

  // Dibujar las notas
  let notasStartY = firmaStartYAdjusted + firmaHeight - 5; // Ajusta la posición de las notas

  doc.setFont("helvetica", "bold").text(20, notasStartY, 'Aceptación de prestamos de equipo: ', { align: 'left', width: 140 });

  const notas = [
    ` Yo,  ${dataHeader?.solicitantePersona} me hago responsable del equipo solicitado en calidad de préstamo, comprometiéndome a devolverlo en óptimas condiciones tal como lo estoy recibiendo. La devolución debe realizarse un día hábil (1) posterior a la finalización del evento.`,
    "Nota: El presente formulario deberá entregarse a la Dirección de Informatica como mínimo con cinco (5) días hábiles de anticipación al evento si es una Unidad Administrativa del COG y  diez (10) días hábiles de anticipación al evento si el solicitante es una FADN. Es importante tomar en cuenta que esto no garantiza la disponibilidad del equipo, ya que todas las solicitudes entran en cola y se atienden en orden de entrada."
  ];

  doc.setFont("helvetica", "normal").setFontSize(4);
  notas.forEach(nota => {
    const lines = doc.splitTextToSize(nota, maxWidth - 20); // Ajusta el texto a la anchura máxima
    lines.forEach(line => {
      doc.text(line, 20, notasStartY + 10); // Ajusta el texto a la izquierda
      notasStartY += 10; // Incrementa la posición Y para la siguiente línea
    });
    notasStartY += 5; // Agrega un poco de espacio adicional después de cada nota
  });

  return doc.output('datauristring');
}

function toDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    }
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}
