import { useState, useEffect } from 'react'

export const useDataUser = () => {
    const [dataUser, setDataUser] = useState([]);
    const Blowfish = require('javascript-blowfish');
    const bf = new Blowfish(process.env.REACT_APP_CONFIG_KEY);

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            const Decript = bf.decrypt(bf.base64Decode(localStorage.getItem('authUser')));
            const data = JSON.parse(Decript.replace(/\0/g, ''));
            setDataUser(data);
        }
    }, []);

    return { dataUser }
}