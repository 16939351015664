/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react'
import { CardTitle, CardText, Form, Input, Button, Label, Row, Col, CardBody, Card, Alert, Container, FormFeedback, FormGroup } from 'reactstrap'
import { Link } from 'react-router-dom'
import * as Icon from 'react-feather'
import { OptionsToast } from '../../Globales/index'
import { toast } from 'react-toastify'
import { useForm, Controller } from 'react-hook-form'
import { PostRoute } from '../../services/Public'
import { toAbsoluteUrl } from '../../helpers/AssetHelper'

import InputPasswordToggle from '../../components/input-password-toggle'
import { useParams } from "react-router-dom"

const Reset = () => {
    const { codigo } = useParams(),
        [Show, setShow] = useState(false),
        { formState: { errors }, control, handleSubmit } = useForm(),
        redirectLogin = () => {
            window.location.href = '/'
        },
        onSubmit = async (data) => {
            if (data.passwordTres === data.passwordDos) {
                const response = await PostRoute(`usuario/resetpass`, {
                    usuario: data.usuario,
                    codigo,
                    password: data.passwordTres
                })
                toast.success(response.message, OptionsToast)
                redirectLogin()
            } else {
                toast.error('Contraseña no coincide', OptionsToast)
            }
        },
        tokenFail = async () => {

            const response = await PostRoute(`usuario/tknreset`, { codigo })
            if (response !== null && response.value !== 2009) {
                setShow(true)
            }
        }

    useEffect(
        () => {
            async function fetchMyAPI() {
                await tokenFail()
            }

            fetchMyAPI()

        }, [codigo]
    )

    return (
        <Fragment>
            <div className="p-0 container-fluid">
                <div className="g-0 row">
                    <div className="col-xl-8 col-lg-8">
                        <div className="auth-full-bg pt-lg-5 p-4">
                            <div className="w-100">
                                <div className="bg-overlay" />
                                <div className="d-flex h-100 flex-column">
                                    <div style={{ marginBlock: "auto" }}>
                                        <div className="row justify-content-center">
                                            <div className="col-lg-8">
                                                <div className="text-center">
                                                    <span >
                                                        <img
                                                            src={"https://i.ibb.co/NN7tCj7/Logo-COGblanco.png"}
                                                            alt=""
                                                            // className="rounded-circle"
                                                            height="500"
                                                        />
                                                    </span>
                                                    <div dir="ltr">
                                                        <div className="carousel-root slider_css">
                                                            <div
                                                                className="carousel carousel-slider"
                                                                style={{ width: "100%" }}
                                                            >
                                                                <div className="slider-wrapper axis-horizontal">
                                                                </div>
                                                                <button
                                                                    type="button"
                                                                    aria-label="next slide / item"
                                                                    className="control-arrow control-next"
                                                                />
                                                                <p className="carousel-status">1 of 2</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl col-lg">
                        <div className="auth-full-page-content p-md-5 p-4">
                            <div className="w-100">
                                <div className="d-flex flex-column h-100">
                                    <div className="text-center">
                                        <span>
                                            <img
                                                src={toAbsoluteUrl("/media/logos/SIPA-completo.png")}
                                                alt=""
                                                // className="rounded-circle"
                                                height="100%"
                                                width="55%"
                                            />
                                        </span>
                                    </div>
                                    <div className="my-auto">
                                        {Show ? <Form autoComplete="off"onSubmit={handleSubmit(onSubmit)}>
                                            <Row>
                                                <Col className="text-center fw-bolder text-uppercase mb-2">
                                                    Reestablecer Contraseña
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label for="exampleEmail">* Ingrese Usuario</Label>
                                                        <Controller
                                                            defaultValue=''
                                                            control={control}
                                                            id='usuario'
                                                            name='usuario'
                                                            render={({ field }) => (
                                                                <Input
                                                                    type='text'
                                                                    invalid={errors.usuario && true}
                                                                    {...field}
                                                                />
                                                            )
                                                            }
                                                            rules={
                                                                {
                                                                    required: {
                                                                        value: true,
                                                                        message: 'Este campo es requerido'
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <FormFeedback>
                                                            {!!errors.usuario && errors.usuario.message}
                                                        </FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label for="exampleEmail">* Nueva contraseña</Label>
                                                        <Controller
                                                            defaultValue=''
                                                            control={control}
                                                            id='passwordDos'
                                                            name='passwordDos'
                                                            render={({ field }) => (
                                                                <InputPasswordToggle
                                                                    label=''
                                                                    htmlFor='basic-default-password'
                                                                    invalid={errors.passwordDos && true}
                                                                    {...field}
                                                                />
                                                            )
                                                            }
                                                            rules={
                                                                {
                                                                    required: {
                                                                        value: true,
                                                                        message: 'Este campo es requerido'
                                                                    },
                                                                    pattern:
                                                                    {
                                                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!_%*?&])[A-Za-z\d$@$!_%*?&]{8,15}[^'\s]/,
                                                                        message: "La contraseña no cumple con los requisitos"
                                                                    },
                                                                    minLength:
                                                                    {
                                                                        value: 8,
                                                                        message: 'Mínimo 8 carácteres!'
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <FormFeedback>
                                                            {!!errors.passwordDos && errors.passwordDos.message}
                                                        </FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label for="exampleEmail">* Repertir Contraseña</Label>
                                                        <Controller
                                                            defaultValue=''
                                                            control={control}
                                                            id='passwordTres'
                                                            name='passwordTres'
                                                            render={({ field }) => (
                                                                <InputPasswordToggle
                                                                    label=''
                                                                    htmlFor='basic-default-password'
                                                                    invalid={errors.passwordTres && true}
                                                                    {...field}
                                                                />
                                                            )
                                                            }
                                                            rules={
                                                                {
                                                                    required: {
                                                                        value: true,
                                                                        message: 'Este campo es requerido'
                                                                    },
                                                                    pattern:
                                                                    {
                                                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!_%*?&])[A-Za-z\d$@$!_%*?&]{8,15}[^'\s]/,
                                                                        message: "La contraseña no cumple con los requisitos"
                                                                    },
                                                                    minLength:
                                                                    {
                                                                        value: 8,
                                                                        message: 'Mínimo 8 carácteres!'
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <FormFeedback>
                                                            {!!errors.passwordTres && errors.passwordTres.message}
                                                        </FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="d-flex justify-content-center">
                                                    <FormGroup>
                                                        <Button color="success">
                                                            <Icon.Save size={16} />
                                                        </Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Form> : "Tu enlace de restablecimiento de contraseña ha expirado"}

                                </div>
                                <div className="mt-4 mt-md-5 text-center">
                                    <p className="mb-0">
                                        © {new Date().getFullYear()} SIPA {"|"} Comité Olímpico Guatemalteco
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </Fragment >
    )
}

export default Reset