import React, { createContext, useState } from 'react'
import { useRequest } from '../../hooks/useRequestSWR'
import { returnYear } from '../../Globales/index'
export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    const getCurrentCuatrimestre = () => {
        const currentMonth = startDate.getMonth() + 1;
        let currentCuatrimestre;

        if (currentMonth >= 1 && currentMonth <= 4) {
            currentCuatrimestre = 1;
        } else if (currentMonth >= 5 && currentMonth <= 8) {
            currentCuatrimestre = 2;
        } else {
            currentCuatrimestre = 3;
        }

        return currentCuatrimestre;
    };
    const [startDate, setStartDate] = useState(new Date());
    const [cuatrimestre, setCuatrimestre] = useState(getCurrentCuatrimestre());


    const nameController1 = 'Gestiones';
    const { data: allIndicadorSatisDI } = useRequest(`${nameController1}/indicadorSatisDI`, 'POST', { cuatrimestre: cuatrimestre, año: returnYear(startDate) });
    const { data: allIndicadorDiasDI } = useRequest(`${nameController1}/indicadorDiasDI`, 'POST', { cuatrimestre: cuatrimestre, año: returnYear(startDate) });
    const { data: allIndicadorSatisPrestamo } = useRequest(`${nameController1}/indicadorSatisPrestamo`, 'POST', { cuatrimestre: cuatrimestre, año: returnYear(startDate) });
    const { data: allIndicadorSatisSGTransporte } = useRequest(`${nameController1}/indicadorSatisSGTransporte`, 'POST', { cuatrimestre: cuatrimestre, año: returnYear(startDate) });

    const labelCuatrimestre = [
        { value: 1, label: 'Q1 (Ene - Abr)' },
        { value: 2, label: 'Q2 (May - Ago)' },
        { value: 3, label: 'Q3 (Sep - Dic)' }
    ];
    const value = {
        allIndicadorSatisDI,
        allIndicadorDiasDI,
        allIndicadorSatisPrestamo,
        allIndicadorSatisSGTransporte,
        startDate,
        setStartDate,
        cuatrimestre,
        setCuatrimestre,
        labelCuatrimestre
    };

    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    );
};
