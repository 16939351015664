
// import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { ContentContext } from './context'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback, Card, CardBody, CardTitle, CardSubtitle, CardText, Badge } from 'reactstrap'
import { useForm, Controller } from "react-hook-form"
import * as Icon from 'react-feather'
import Select from 'react-select'
import { formatDate, customStyles, stringToDate } from '../../../Globales/index'
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput'
import classnames from 'classnames'
import { Text } from "@nextui-org/react";
// import Objetivos from '../ObjetivosEstrategicos/index'
// import { ContentProvider } from '../ObjetivosEstrategicos/context'
import revision from '../../../assets/images/Checklist.png'
import DatePicker, { registerLocale } from "react-datepicker"
import es from "date-fns/locale/es"
registerLocale("es", es); // register it with the name you want


const Header = () => {
    // const [startDate, setStartDate] = useState(Date.parse(2019) + 1);
    const [startDate, setStartDate] = useState(new Date());
    const [validador, setValidador] = useState(false);
    const { toggleModal, modal, StoreUpdate, opcion, oneData, labelAcciones, setId, labelFinanciamiento, idPresupuestoPoa, labelTipoGasto, labelTipoPedido, labelFand, useDataUser, nameComponent, labelJefeDireccion, solicitante, setAgregarRenglon, setIdModalidad, idModalidad, labelTipoPago, idTipoPago, setidTipoPago, labelCategoria } = useContext(ContentContext),
        [updateData, setUpdateData] = useState(false),
        { handleSubmit, formState: { errors }, setValue, control, reset, watch } = useForm(),
        seleccionableTipoGasto = watch('tipogasto'),
        inputTipoPago = watch("tipoPago"),
        onSubmit = (data) => {
            const json = {
                id: oneData?.id ?? null,
                fand: data?.fadn?.value ?? null,
                justificacion: data?.justificacion,
                fechaPedido: formatDate(startDate),
                modalidad: idModalidad,
                correlativo: data?.correlativo,
                cur: data?.cur,
                curDev: data?.curDev,
                noFolios: data?.noFolios,
                tipoPago: data?.tipoPago?.value,
                categoriaExpediente: data?.categoriaExpediente?.value,
                noCheque: data?.noCheque,
                noTransferenciaNDPRO: data?.noTransferenciaNDPRO,

            }
            StoreUpdate(json, (oneData) ? oneData.id : null, idModalidad)
            // setUpdateData(true)
        },
        setData = async () => {
            await setValue('justificacion', oneData.justificacion)
            await setIdModalidad(oneData.modalidad)
            setStartDate(new Date(oneData.fechaPedido))
            await setValue('correlativo', oneData.correlativo2)
            await setValue('cur', oneData.cur)
            await setValue('curDev', oneData.curDev)
            await setValue('noFolios', oneData.noFolios)
            await setValue('tipoPago', { label: oneData.tipoPago, value: oneData?.idtipoPago })
            await setValue('categoriaExpediente', { label: oneData.categoriaExpediente, value: oneData?.idCategoriaExpediente })
            await setValue('noCheque', oneData.noCheque)
            await setValue('noTransferenciaNDPRO', oneData.noTransferenciaNDPRO)
            await setValue('fadn', { label: oneData.fadn, value: oneData.idFand })
            await setValue('tipogasto', { label: oneData.fand === 'N/A' ? 'COG' : 'FADNs e Instituciones', value: oneData.fand === 'N/A' ? 1 : 2 })


        },
        // setJefeFirmante = async () => {
        //     setValue('jefeDireccion', { label: labelJefeDireccion[0]?.label, value: labelJefeDireccion[0]?.value })

        // },
        clearData = async () => {
            reset({ monto: '', unidad: '', financiamiento: '' })
        },
        setEstado = (estado) => {
            setUpdateData(estado)
        },
        habilitarEdicion = (e, estado) => {
            e.preventDefault()
            setEstado(estado)
        }

    useEffect(
        () => {
            if (oneData) {
                setUpdateData(true)
            }
        }, [oneData]
    )
    useEffect(
        () => {
            async function fetchMyAPI() {
                if (await opcion > 1 && oneData) { await setData() }
                if (await opcion === 1) {
                    clearData(), setUpdateData(false)
                    setIdModalidad(0)
                }
            }

            fetchMyAPI()

        }, [opcion, oneData]
    )
    useEffect(() => {
        let abortController = new AbortController();
        async function fetchMyAPI() {
            if (inputTipoPago) {
                if (inputTipoPago?.value !== 0) {
                    setidTipoPago(inputTipoPago?.value)
                } else {
                    setidTipoPago(0)
                }
            } if (inputTipoPago === null || inputTipoPago === '') {
                setidTipoPago(0)
            }
        }
        fetchMyAPI()
        return () => { abortController.abort(); }
    }, [inputTipoPago])


    useEffect(() => {
        let abortController = new AbortController();
        async function fetchMyAPI() {
            if (seleccionableTipoGasto) {
                if (seleccionableTipoGasto.value === 2) {

                    setValidador(true)
                } else {
                    setValidador(false)
                }
            } if (seleccionableTipoGasto === null || seleccionableTipoGasto === '') {
                setValidador(false)
            }
        }
        fetchMyAPI()
        return () => { abortController.abort(); }
    }, [seleccionableTipoGasto])


    // console.log(labelJefeDireccion[0])
    return (
        <Fragment>
            <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                {opcion === 1 && <>
                    <div className="container py-5">
                        <h1 className='text-center mb-10'>Tipo de Compra</h1>
                        {/* <p className="text-center">
                            {" "}
                            Elige el tipo de compra para iniciar el proceso correspondiente.{" "}
                        </p> */}

                        <div className="row mx-0 gy-3 px-lg-5">
                            {/* Basic */}
                            <div className="col-lg mb-md-0 mb-4">
                                <div className={`card ${idModalidad === 1 ? 'card-box-shadow-active' : ''} border shadow-none`}>
                                    <div className="card-body">
                                        <h3 className={`card-title text-center text-capitalize mb-1 ${idModalidad === 1 ? '' : 'text-muted'}`}>Pedido</h3>
                                        {/* <p className="text-center">A simple start for everyone</p>
                                        <div className="text-center">
                                            <div className="d-flex justify-content-center">
                                                <sup className="h6 pricing-currency mt-3 mb-0 me-1 text-primary">
                                                    $
                                                </sup>
                                                <h1 className="display-4 mb-0 text-primary">0</h1>
                                                <sub className="h6 pricing-duration mt-auto mb-2 text-muted fw-normal">
                                                    /month
                                                </sub>
                                            </div>
                                        </div>
                                        <ul className="ps-3 my-4 pt-2">
                                            <li className="mb-2">100 responses a month</li>
                                            <li className="mb-2">Unlimited forms and surveys</li>
                                            <li className="mb-2">Unlimited fields</li>
                                            <li className="mb-2">Basic form creation tools</li>
                                            <li className="mb-0">Up to 2 subdomains</li>
                                        </ul> */}
                                        <div className='text-center'><Button color="success" onClick={() => setIdModalidad(1)}>Elegir</Button></div>
                                    </div>
                                </div>
                            </div>
                            {/* Pro */}
                            <div className="col-lg mb-md-0 mb-4">
                                <div className={`card ${idModalidad === 2 ? 'card-box-shadow-active' : ''} border shadow-none`}>
                                    <div className="card-body position-relative">
                                        <div className="position-absolute end-0 me-4 top-0 mt-4">
                                            <span className="badge bg-label-primary">Popular</span>
                                        </div>
                                        <h3 className={`card-title text-center text-capitalize mb-1 ${idModalidad === 2 ? '' : 'text-muted'} `}>Gasto</h3>
                                        {/* <p className="text-center">For small to medium businesses</p>
                                        <div className="text-center">
                                            <div className="d-flex justify-content-center">
                                                <sup className="h6 pricing-currency mt-3 mb-0 me-1 text-primary">
                                                    $
                                                </sup>
                                                <h1 className="price-toggle price-yearly display-4 text-primary mb-0">
                                                    7
                                                </h1>
                                                <h1 className="price-toggle price-monthly display-4 text-primary mb-0 d-none">
                                                    9
                                                </h1>
                                                <sub className="h6 text-muted pricing-duration mt-auto mb-2 fw-normal">
                                                    /month
                                                </sub>
                                            </div>
                                        </div>
                                        <ul className="ps-3 my-4 pt-2">
                                            <li className="mb-2">Up to 5 users</li>
                                            <li className="mb-2">120+ components</li>
                                            <li className="mb-2">Basic support on Github</li>
                                            <li className="mb-2">Monthly updates</li>
                                            <li className="mb-0">Integrations</li>
                                        </ul> */}
                                        <div className='text-center'><Button color="primary" onClick={() => setIdModalidad(2)}>Elegir</Button></div>
                                    </div>
                                </div>
                            </div>
                            {/* Enterprise */}
                            <div className="col-lg">
                                <div className={`card ${idModalidad === 3 ? 'card-box-shadow-active' : ''} border shadow-none`}>
                                    <div className="card-body">
                                        <h3 className={`card-title text-center text-capitalize mb-1 ${idModalidad === 3 ? '' : 'text-muted'}`}>
                                            Gasto Caja Chica
                                        </h3>
                                        <div className='text-center'><Button color="danger" onClick={() => setIdModalidad(3)}>Elegir</Button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
                <div className={opcion === 1 || !updateData ? ` p-3` : `card p-3`}>
                    <Row>
                        {
                            updateData ?

                                <Col className="py-2" lg={3} xl={3}>
                                    <Label>No. Solicitud</Label><br></br>
                                    <span style={{ fontSize: '12px' }}>{oneData?.correlativo}</span>
                                </Col>

                                :
                                <Col>
                                    <FormGroup>
                                        <Label for='correlativo' className="">* No. Solicitud</Label>
                                        <Controller
                                            defaultValue=''
                                            control={control}
                                            id='correlativo'
                                            name='correlativo'
                                            render={({ field }) => (
                                                <Input
                                                    type='text'
                                                    invalid={errors.correlativo && true}
                                                    {...field}
                                                />
                                            )
                                            }
                                            rules={
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido'
                                                    }
                                                }
                                            }
                                        />
                                        <FormFeedback>
                                            {!!errors.correlativo && errors.correlativo.message}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                        }
                        {
                            updateData ?

                                <Col className="py-2" lg={3} xl={3}>
                                    <Label>Fecha Solicitud</Label><br></br>
                                    <span style={{ fontSize: '12px' }}>{oneData?.fechaPedido4}</span>
                                </Col>

                                :
                                <Col md={3}>

                                    <FormGroup>
                                        <Label for="fechaPedido">* Fecha Solicitud</Label>
                                        <Controller
                                            name='fechaPedido'
                                            control={control}
                                            render={() => {
                                                return (
                                                    < DatePicker
                                                        className={classnames('form-control invoice-edit-input due-date-picker ', {
                                                            'is-invalid': errors.fechaPedido && true
                                                        })}
                                                        selected={startDate}
                                                        // disabled={true}
                                                        locale="es"
                                                        onChange={(fechaPedido) => setStartDate(fechaPedido)}
                                                        // showYearPicker
                                                        name='fechaPedido'
                                                        id="fechaPedido"
                                                    // dateFormat="yyyy"
                                                    />
                                                )
                                            }
                                            }
                                        // rules={
                                        //     {
                                        //         required: {
                                        //             value: true,
                                        //             message: 'Este campo es requerido'
                                        //         }
                                        //     }
                                        // }
                                        />
                                        {/* <FormFeedback>
                                    {!!errors.fechaPedido && errors.fechaPedido.message}
                                </FormFeedback> */}
                                    </FormGroup>
                                </Col>
                        }
                        {
                            updateData ?

                                <Col className="py-2" lg={3} xl={3}>
                                    <Label>Tipo Pago</Label><br></br>
                                    <span style={{ fontSize: '12px' }}>{oneData?.tipoPago}</span>
                                </Col>

                                :
                                <Col>
                                    <FormGroup >
                                        <Label >* Tipo de Pago</Label>
                                        <Controller
                                            name="tipoPago"
                                            control={control}
                                            defaultValue={null}
                                            render={({ field }) => {
                                                return (
                                                    <Select
                                                        {...field}
                                                        isClearable
                                                        onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                        onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                        isDisabled={updateData}
                                                        options={labelTipoPago}
                                                        className='react-select'
                                                        classNamePrefix='select'
                                                        placeholder={'Seleccione una opción'}
                                                        noOptionsMessage={() => 'sin resultados'}
                                                        styles={(!!errors.tipoPago) ? customStyles : {}}

                                                    />
                                                )
                                            }
                                            }
                                            rules={
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido'
                                                    }
                                                }
                                            }
                                        />
                                        <small className="text-danger">{!!errors.tipoPago && errors.tipoPago.message}</small>
                                    </FormGroup>
                                </Col>
                        }

                        {(idTipoPago === 1 || oneData?.idtipoPago === 1) &&
                            <>
                                {
                                    updateData ?

                                        <Col className="py-2" lg={3} xl={3}>
                                            <Label>No. Cheque</Label><br></br>
                                            <span style={{ fontSize: '12px' }}>{oneData?.noCheque}</span>
                                        </Col>

                                        :
                                        <Col >
                                            <FormGroup>
                                                {/* <Label for="noCheque"> * No.Cheque o Transferencia </Label> */}
                                                <Label for="noCheque"> * No. Cheque</Label>
                                                <Controller
                                                    // defaultValue
                                                    control={control}
                                                    id='noCheque'
                                                    name='noCheque'
                                                    render={({ field }) => (
                                                        <Input
                                                            type='text'
                                                            // readOnly={opcion === 2}
                                                            invalid={errors.noCheque && true}
                                                            {...field}
                                                        />
                                                    )
                                                    }
                                                    rules={
                                                        {
                                                            required: {
                                                                value: true,
                                                                message: 'Este campo es requerido'
                                                            }
                                                        }
                                                    }
                                                />
                                                <FormFeedback>
                                                    {!!errors.noCheque && errors.noCheque.message}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                }
                            </>
                        }

                        {(idTipoPago === 2 || oneData?.idtipoPago === 2) &&
                            <>
                                {
                                    updateData ?

                                        <Col className="py-2" lg={3} xl={3}>
                                            <Label>No. Transferencia-ND</Label><br></br>
                                            <span style={{ fontSize: '12px' }}>{oneData?.noTransferenciaNDPRO}</span>
                                        </Col>

                                        :
                                        <Col >
                                            <FormGroup>
                                                {/* <Label for="noCheque"> * No.Cheque o Transferencia </Label> */}
                                                <Label for="noTransferenciaNDPRO"> * No. ND o Transferencia</Label>
                                                <Controller
                                                    // defaultValue
                                                    control={control}
                                                    id='noTransferenciaNDPRO'
                                                    name='noTransferenciaNDPRO'
                                                    render={({ field }) => (
                                                        <Input
                                                            type='text'
                                                            // readOnly={opcion === 2}
                                                            invalid={errors.noTransferenciaNDPRO && true}
                                                            {...field}
                                                        />
                                                    )
                                                    }
                                                    rules={
                                                        {
                                                            required: {
                                                                value: true,
                                                                message: 'Este campo es requerido'
                                                            }
                                                        }
                                                    }
                                                />
                                                <FormFeedback>
                                                    {!!errors.noTransferenciaNDPRO && errors.noTransferenciaNDPRO.message}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                }
                            </>

                        }
                    </Row>
                    <Row>
                        {
                            updateData ?
                                <>
                                    <Col className="py-2" lg={3} xl={3}>
                                        <Label>Solicitud Dirigida A</Label><br></br>
                                        <span style={{ fontSize: '12px' }}>{oneData?.fand === 'N/A' ? 'COG' : 'FADNs e Instituciones: ' + oneData?.fand}</span>
                                    </Col>
                                    <Col className="py-2" lg={3} xl={3}>
                                        <Label>CUR COM </Label><br></br>
                                        <span style={{ fontSize: '12px' }}>{oneData?.cur}</span>
                                    </Col>
                                    <Col className="py-2" lg={3} xl={3}>
                                        <Label>CUR DEV </Label><br></br>
                                        <span style={{ fontSize: '12px' }}>{oneData?.curDev}</span>
                                    </Col>
                                    <Col className="py-2" lg={3} xl={3}>
                                        <Label>No. Folios </Label><br></br>
                                        <span style={{ fontSize: '12px' }}>{oneData?.noFolios}</span>
                                    </Col>
                                </>
                                :
                                <Col>
                                    <FormGroup >
                                        <Label >* Tipo de Gasto</Label>
                                        <Controller
                                            name="tipogasto"
                                            control={control}
                                            defaultValue={null}
                                            render={({ field }) => {
                                                return (
                                                    <Select
                                                        {...field}
                                                        isClearable
                                                        onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                        onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                        isDisabled={updateData}
                                                        options={labelTipoGasto}
                                                        className='selector-react1'
                                                        classNamePrefix='select'
                                                        placeholder={'Seleccione una opción'}
                                                        noOptionsMessage={() => 'sin resultados'}
                                                        styles={(!!errors.tipogasto) ? customStyles : {}}

                                                    />
                                                )
                                            }
                                            }
                                            rules={
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido'
                                                    }
                                                }
                                            }
                                        />
                                        <small className="text-danger">{!!errors.tipogasto && errors.tipogasto.message}</small>
                                    </FormGroup>
                                </Col>
                        }
                        {
                            updateData ? null :
                                <>
                                    {validador === true &&
                                        <Col>
                                            <FormGroup >
                                                <Label >* FADN</Label>
                                                <Controller
                                                    name="fadn"
                                                    control={control}
                                                    defaultValue={null}
                                                    render={({ field }) => {
                                                        return (
                                                            <Select
                                                                {...field}
                                                                isClearable
                                                                onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                                onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                                isDisabled={updateData}
                                                                options={labelFand}
                                                                className='selector-react1'
                                                                classNamePrefix='select'
                                                                placeholder={'Seleccione una opción'}
                                                                noOptionsMessage={() => 'sin resultados'}
                                                                styles={(!!errors.fadn) ? customStyles : {}}

                                                            />
                                                        )
                                                    }
                                                    }
                                                    rules={
                                                        {
                                                            required: {
                                                                value: true,
                                                                message: 'Este campo es requerido'
                                                            }
                                                        }
                                                    }
                                                />
                                                <small className="text-danger">{!!errors.fadn && errors.fadn.message}</small>
                                            </FormGroup>
                                        </Col>
                                    }
                                    <Col>

                                        <FormGroup>
                                            <Label for="categoriaExpediente" className="d-flex justify-content-between">
                                                <span>*  Categoria</span>
                                            </Label>
                                            <Controller
                                                name="categoriaExpediente"
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            {...field}
                                                            isClearable
                                                            onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                            onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                            defaultValue={null}
                                                            options={labelCategoria}
                                                            // theme={selectThemeColors}
                                                            // isDisabled={!allMedidaInsumo}
                                                            className='selector-react1'
                                                            classNamePrefix='select'
                                                            placeholder={'Seleccione una opción'}
                                                            noOptionsMessage={() => 'sin resultados'}
                                                            styles={(!!errors.categoriaExpediente) ? customStyles : {}}
                                                        />
                                                    )
                                                }
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <small className="text-danger">
                                                {!!errors.categoriaExpediente && errors.categoriaExpediente.message}
                                            </small>
                                        </FormGroup>
                                    </Col>
                                </>

                        }
                    </Row>
                </div>
                {
                    updateData ?

                        null

                        :
                        <>
                            <div className={opcion === 1 || !updateData ? ` p-3` : `card p-3`}>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for='cur' className="">* CUR COM</Label>
                                            <Controller
                                                defaultValue=''
                                                control={control}
                                                id='cur'
                                                name='cur'
                                                render={({ field }) => (
                                                    <Input
                                                        type='text'
                                                        invalid={errors.cur && true}
                                                        {...field}
                                                    />
                                                )
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <FormFeedback>
                                                {!!errors.cur && errors.cur.message}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for='curDev' className="">* CUR DEV</Label>
                                            <Controller
                                                defaultValue=''
                                                control={control}
                                                id='curDev'
                                                name='curDev'
                                                render={({ field }) => (
                                                    <Input
                                                        type='text'
                                                        invalid={errors.curDev && true}
                                                        {...field}
                                                    />
                                                )
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <FormFeedback>
                                                {!!errors.curDev && errors.curDev.message}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for='noFolios' className="">* No. Folios</Label>
                                            <Controller
                                                defaultValue=''
                                                control={control}
                                                id='noFolios'
                                                name='noFolios'
                                                render={({ field }) => (
                                                    <Input
                                                        type='text'
                                                        invalid={errors.noFolios && true}
                                                        {...field}
                                                    />
                                                )
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <FormFeedback>
                                                {!!errors.noFolios && errors.noFolios.message}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </>
                }
                <div className={opcion === 1 || !updateData ? ` p-3` : `card p-3`}>
                    <Row>
                        {updateData ?
                            <Col className="py-2" >
                                <Label>Justificación</Label><br></br>
                                <span style={{ fontSize: '12px' }}>{oneData?.justificacion}</span>
                            </Col>
                            : null}
                        {updateData ? null
                            :
                            <Col>
                                <FormGroup>
                                    <Label for='justificacion' className="d-flex justify-content-between">* Justificación</Label>
                                    <Controller

                                        control={control}
                                        id='justificacion'
                                        name='justificacion'
                                        render={({ field }) => (
                                            <Input
                                                rows={3}
                                                type='textarea'
                                                // readOnly={opcion === 2}
                                                disabled={updateData}
                                                invalid={errors.justificacion && true}
                                                defaultValue={null}
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.justificacion && errors.justificacion.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>}


                        {
                            opcion === 80 ? null :
                                (
                                    <>
                                        <Col lg={2} className="align-self-center text-center">

                                            {updateData &&
                                                <Fragment>
                                                    <Button id='Actualizar1' color="warning" size="sm" type="button" className="text-center  me-2" onClick={(e) => habilitarEdicion(e, false)}>
                                                        <Icon.Edit size={16} />
                                                    </Button>
                                                    <UncontrolledTooltip placement='top' target='Actualizar1'>
                                                        Actualizar Encabezado
                                                    </UncontrolledTooltip>
                                                </Fragment>}

                                            {
                                                !updateData &&
                                                <Fragment>
                                                    <Button color="success" size="sm" type="submit" className="text-center me-2" id="Guardar">
                                                        <Icon.Save size={16} />
                                                    </Button>
                                                    <UncontrolledTooltip placement='top' target='Guardar'>
                                                        Guardar
                                                    </UncontrolledTooltip>
                                                    {
                                                        (((oneData && oneData.id)) && !updateData) &&
                                                        <Fragment>
                                                            <Button color="danger" size="sm" type="button" className="text-center" id="Cancelar1" onClick={(e) => habilitarEdicion(e, true)}>
                                                                <Icon.Slash size={16} />
                                                            </Button>
                                                            <UncontrolledTooltip placement='top' target='Cancelar1'>
                                                                Cancelar
                                                            </UncontrolledTooltip>
                                                        </Fragment>
                                                    }
                                                </Fragment>
                                            }

                                        </Col>
                                    </>
                                )
                        }
                    </Row>
                </div>
            </Form >

        </Fragment >
    )
}

export default Header