import React, { useContext, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, FormGroup, FormFeedback } from "reactstrap";
import { ContentContext } from './context'
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import classnames from 'classnames';
import Select from 'react-select'
import { customStyles } from '../../Globales/index'

const HeaderDI = () => {
  const { formState: { errors }, control, watch } = useForm();
  const { startDate, setStartDate, labelCuatrimestre, setCuatrimestre,  cuatrimestre } = useContext(ContentContext),
    seleccionableCuatrimestre = watch('cutrimestre')

  useEffect(() => {
    let abortController = new AbortController();
    async function fetchMyAPI() {
      if (seleccionableCuatrimestre) {
        if (seleccionableCuatrimestre?.value !== 0) {
          setCuatrimestre(seleccionableCuatrimestre?.value)
        } else {
          setCuatrimestre(cuatrimestre)
        }
      } if (seleccionableCuatrimestre === null || seleccionableCuatrimestre === '') {
        setCuatrimestre(cuatrimestre)
      }
    }
    fetchMyAPI()
    return () => { abortController.abort(); }
  }, [seleccionableCuatrimestre])

  return (
    <React.Fragment>
      <Row>
        <Col lg={7}>
          <CardTitle className="mb-4">INDICADORES DIRECCIÓN DE SERVICIOS GENERALES
          </CardTitle>
        </Col>
        <Col lg={4}>
          <FormGroup >
            <Controller
              name="cutrimestre"
              control={control}
              defaultValue={null}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isClearable
                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                    // isDisabled={opcion === 2}
                    options={labelCuatrimestre}
                    className='react-select'
                    classNamePrefix='select'
                    placeholder={'Seleccione un cuatrimestre'}
                    noOptionsMessage={() => 'sin resultados'}
                    styles={(!!errors.cutrimestre) ? customStyles : {}}

                  />
                )
              }
              }
              rules={
                {
                  required: {
                    value: true,
                    message: 'Este campo es requerido'
                  }
                }
              }
            />
            <small className="text-danger">{!!errors.cutrimestre && errors.cutrimestre.message}</small>
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Controller
              name='año'
              control={control}
              render={() => {
                return (
                  <DatePicker
                    className={classnames('form-control invoice-edit-input due-date-picker', {
                      'is-invalid': errors.año && true
                    })}
                    selected={startDate}
                    // disabled={opcion === 2 || opcion === 4}
                    locale="es"
                    onChange={(año) => setStartDate(año)}
                    showYearPicker
                    name='año'
                    id="año"
                    dateFormat="yyyy"
                  />
                );
              }}
            />
            <FormFeedback>
              {!!errors.año && errors.año.message}
            </FormFeedback>
          </FormGroup>
        </Col>

      </Row>
    </React.Fragment>
  );
};

export default HeaderDI;
