import React, { useState, useEffect, useContext, useMemo } from 'react';
import { ContentContext } from './context';

const NotificationTitle = () => {
  const { AllDataFilter, NotificacionesFilter, notificacionesSolInsumoUserFilter, NotificationActions, GestionesSolicitantesXUnidad, GestionesXAprobador, PedidosSolicitantes, PedidosXAprobador, ModificacionesSolicitantes, ModificacionesXAprobador } = useContext(ContentContext);
  const [notificationCount, setNotificationCount] = useState(0);

  const allNotifications = useMemo(() => [
    ...(AllDataFilter?.value !== 2009 && AllDataFilter?.value !== 7001 && AllDataFilter || []),
    ...(NotificacionesFilter?.value !== 2009 && NotificacionesFilter?.value !== 7001 && NotificacionesFilter || []),
    ...(notificacionesSolInsumoUserFilter?.value !== 2009 && notificacionesSolInsumoUserFilter?.value !== 7001 && notificacionesSolInsumoUserFilter || []),
    ...(GestionesSolicitantesXUnidad?.value !== 2009 && GestionesSolicitantesXUnidad?.value !== 7001 && GestionesSolicitantesXUnidad || []),
    ...(GestionesXAprobador?.value !== 2009 && GestionesXAprobador?.value !== 7001 && GestionesXAprobador || []),
    ...(PedidosSolicitantes?.value !== 2009 && PedidosSolicitantes?.value !== 7001 && PedidosSolicitantes || []),
    ...(PedidosXAprobador?.value !== 2009 && PedidosXAprobador?.value !== 7001 && PedidosXAprobador || []),
    ...(ModificacionesSolicitantes?.value !== 2009 && ModificacionesSolicitantes?.value !== 7001 && ModificacionesSolicitantes || []),
    ...(ModificacionesXAprobador?.value !== 2009 && ModificacionesXAprobador?.value !== 7001 && ModificacionesXAprobador || [])
  ], [AllDataFilter, NotificacionesFilter, notificacionesSolInsumoUserFilter, GestionesSolicitantesXUnidad, GestionesXAprobador, PedidosSolicitantes, PedidosXAprobador, ModificacionesSolicitantes, ModificacionesXAprobador]);

  const totalNotifications = allNotifications?.length ?? 0;

  useEffect(() => {
    setNotificationCount(totalNotifications);
  }, [totalNotifications]);

  useEffect(() => {
    document.title = `${notificationCount > 0 ? `(${notificationCount}) ` : ''}Sistema Integrado de Procesos Administrativos - SIPA`;
  }, [notificationCount]);

  // return (
  //   <div>
  //     {allNotifications?.length > 0 && <h1>Notificaciones: {totalNotifications}</h1>}
  //   </div>
  // );
};

export default NotificationTitle;
