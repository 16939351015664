import React, { useState, Fragment, useContext } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback } from 'reactstrap'
import { ContentContext } from '../context'
import * as Icon from 'react-feather'

export const PDF = () => {
    const RoutePathServer = process.env.REACT_APP_PATH_SERVER;
    const { oneData } = useContext(ContentContext);
    const isPdf = oneData?.extension?.toLowerCase() === '.pdf';
    const fileUrl = `${RoutePathServer + oneData?.archivo}`;
    const fileName = oneData?.extension === '.pdf' 
        ? `Factura_solicitud_${oneData?.correlativo}_orden_de_compra_${oneData?.noOrdenCompra}${oneData?.extension}` 
        : `Factura_solicitud_${oneData?.correlativo}_orden_de_compra_${oneData?.noOrdenCompra}${oneData?.extension}`;
    const [show, setShow] = useState(false),
        showModal = async () => {
            await setShow(true)
            // volver()
        }

    return (
        <Fragment>
            <span className="dropdown-item" onClick={() => [showModal()]}>
                <Icon.FileText size={14} /> {"Ver Adjunto"}
            </span>
            <Modal
                isOpen={show}
                backdrop='static'
                size="llg"
                className='modal-dialog-centered'
            >
                <ModalHeader >
                    <span className='h1 fw-bolder'>{"Visualizar"}</span>
                </ModalHeader>
                <ModalBody>

                    <div className="d-flex justify-content-around">
                        {
                            <>
                                {isPdf ? (
                                    <object
                                        className="Visor text-center"
                                        width="100%"
                                        height="750px"
                                        id="pdf-ficha"
                                        aria-labelledby="perfil"
                                        type="application/pdf"
                                        data={fileUrl}
                                    >
                                        <p>Tu navegador no soporta visualizar PDFs. <a href={fileUrl}>Descargar el PDF</a>.</p>
                                    </object>
                                ) : (
                                    <img
                                        src={fileUrl}
                                        width="100%"
                                        height="100%"
                                        className="text-center"
                                        alt="Factura"
                                    />
                                )}
                            </>
                        }
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button variant='secondary' onClick={() => setShow(false)}>
                        <Icon.ArrowLeft size={16} /> {'Salir'}
                    </Button>
                    <a
                        className='btn btn-danger '
                        download={fileName}
                        href={fileUrl}
                        target="_blank"
                        rel="noopener noreferrer">
                        <Icon.Download size={16} /> {'Descargar'}
                    </a>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}
export default PDF