const RouteBase = process.env.REACT_APP_ROUTE_BASE

const getJson = () => {
    const Blowfish = require('javascript-blowfish');
    const bf = new Blowfish(process.env.REACT_APP_CONFIG_KEY);
    const Decript = bf.decrypt(bf.base64Decode(localStorage.getItem('authUser')));
    const Token = JSON.parse(Decript.replace(/\0/g, ''));
    return Token;
};

export async function GetRoute(url) {
    const response = await fetch(`${RouteBase}/${url}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json',
            'Content-Type': 'application/json'
            // 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('authUser')).token}`
        }
    })
        .then((data) => data.json())
        .catch(() => []);
    return response;
}

export async function GetRouteSWR(url) {

    const response = await fetch(`${RouteBase}/${url}`,
        {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('authUserI')).token}`
            }
        }
    )
        .then(function (data) {
            //StatusCode(data);
            return data.json()
        })
    return await response;
}
// **************************************************************************
// Funcion guardar registros json
// **************************************************************************
export async function PostRoute(url, form) {
    const dataAuth = getJson();
    const data = JSON.stringify({
        usuario: dataAuth.id,
        ...form
    });
    // elviamos el formulario con fetch por el metodo post
    const response = await fetch(`${RouteBase}/${url}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${dataAuth.token}`
        },
        body: data
    })
        .then((data) => data.json())
        .catch(() => []);

    return response;
};

export async function PostRouteSWR(url, form) {

    const data = JSON.stringify
        (
            {
                usuario: JSON.parse(localStorage.getItem('authUser')).code,
                ...form
            }
        );
    // elviamos el formulario con fetch por el metodo post
    const response = await fetch(`${RouteBase}/${url}`,
        {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                //    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('authUserI')).token}`
            },
            body: data
        }
    )
        .then(function (data) {
            // StatusCode(data);
            return data.json()
        })

    return await response;
}

// **************************
// Funcion guardar registros con documentos
// **************************
export async function PostRouteFD(url, form) {
    const dataAuth = getJson();
    form.append('usuario', dataAuth.id);
    // elviamos el formulario con fetch por el metodo post
    const response = await fetch(`${RouteBase}/${url}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${dataAuth.token}`
        },
        body: form
    })
        .then((data) => data.json())
        .catch(() => []);

    return response;
}


export default {
    GetRoute,
    PostRoute,
    PostRouteFD
};
