import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { ContentContext } from './context'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback } from 'reactstrap'
import { useForm, Controller } from "react-hook-form"
import * as Icon from 'react-feather'
import DatePicker from "react-datepicker";
import Select from 'react-select'
import { customStyles } from '../../../Globales/index'
import classnames from 'classnames'
import { Text } from "@nextui-org/react";
import { useHistory, useParams } from "react-router-dom"
import AccionesComponent from '../Acciones/index'

const Formulario = () => {
    const history = useHistory();
    const { codigo } = useParams();
    const [percentEjecucion, setPercentEjecucion] = useState(0);
    const [tab, setTab] = useState(1);
    const { setCodigo, allData, mutate, nameController } = useContext(ContentContext),
        TabActive = (No) => {
            setTab(No)
        };

    const percentage_ejecucion = (n1, n2) => {

        if (n1 !== null && n2 !== null && n1 !== undefined && n2 !== undefined) {
            // Actual
            const num1 = parseInt(n1);
            // Limite
            const num2 = parseInt(n2);
            const result = (num1 * 100) / num2;

            setPercentEjecucion(result.toFixed(2));
        }
    }
    useEffect(() => {
        async function fetchMyAPI() {
            setCodigo(codigo)
            mutate([`${nameController}/One-code`, { code: codigo }])
        }
        fetchMyAPI()
    }, [codigo])

    useEffect(
        () => {
            const functionFetch = async () => {
                if (allData && allData[0] !== undefined) {
                    percentage_ejecucion(allData[0].monto, allData[0].monto)
                }

            }
            functionFetch();
        }, [allData]
    )

    return (
        <Fragment>

            <div className="card mb-6 mb-xl-9">
                <div className="card-body pt-9 pb-0 ">
                    <div className="d-flex flex-wrap flex-sm-nowrap">
                        <div
                            className=" mx-2 text-center d-flex justify-content-center align-self-center" style={{ height: '20vh', width: '10vw' }}
                        >
                            <span className="avatar-title rounded-4 text-ligth font-size-22 content-colorized">
                                {allData && allData[0] !== undefined && allData[0].correlativo}
                            </span>

                        </div>
                        <div className="flex-grow-1">
                            <div className="d-flex justify-content-between align-items-start flex-wrap ">
                                <div className="d-flex mx-3 flex-column">
                                    <div className="d-flex align-items-center mb-1">
                                        <a
                                            href="#"
                                            className="text-dark fs-4 fw-bold me-3"
                                        >
                                            {allData && allData[0] !== undefined && allData[0].unidad}
                                        </a>
                                        { allData && allData[0] !== undefined && allData[0].estado === 1 ?
                                        <span className="badge badge-light-success me-auto">
                                            Activo
                                        </span> :
                                        <span className="badge badge-light-danger me-auto">
                                            Inactivo
                                        </span>}
                                    </div>
                                    <div className="d-flex flex-wrap fw-semibold mb-4 fs-6 text-dark">
                                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                            <a
                                                href="#"
                                                className="d-flex align-items-center text-secondary bg-soft me-5 mb-2"
                                            >

                                                <span className="svg-icon svg-icon-4 me-1">
                                                    <Icon.Command size={16} />
                                                </span>
                                                {allData && allData[0] !== undefined && allData[0].codigo}
                                            </a>
                                            
                                        </div>

                                    </div>
                                </div>
                                <div className="d-flex align-items-center mt-3">
                                    <Button
                                        onClick={() => history.push(`/poa/presupuesto-poa`)}
                                        color="primary"
                                        size="sm"
                                        id="regresar"
                                    >
                                        <Icon.ArrowLeft size={16} />
                                    </Button>

                                    <UncontrolledTooltip placement='left' target='regresar' placementPrefix="bs-tooltip">
                                        Regresar
                                    </UncontrolledTooltip>
                                </div>

                            </div>

                            <Row >
                                <Col>
                                    <div className="d-flex flex-wrap justify-content-start mx-3">
                                        <div className="d-flex flex-wrap">
                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-2">
                                                <div className="d-flex align-items-center">

                                                    <div
                                                        className="fs-4 fw-bold counted" >
                                                        {allData && allData[0] !== undefined && allData[0].montoPoaActual}
                                                    </div>
                                                </div>
                                                <div className="fs-6 text-gray-400">Presupuesto Actual</div>
                                            </div>
                                        </div>

                                    </div></Col>
                                <Col></Col>
                                <Col>
                                    <div className="d-flex align-items-center flex-column m-3">
                                        <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                            <span className="fw-semibold fs-6 text-secondary">Presupuesto Ejecutado</span>
                                            <span className="fw-bold fs-6">{percentEjecucion}%</span>
                                        </div>
                                        <div className="h-5px mx-3 w-100 bg-light mb-3 progress-bar">
                                            <div className="progress-md progress">
                                                <div
                                                    className="progress-bar bg-success"
                                                    role="progressbar"
                                                    style={{ width: `${percentEjecucion}%` }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>


                    </div>
                    <div className="separator" />
                    <ul className="nav nav-tabs nav-tabs-custom nav-line-tabs-2x mb-1 fs-5 border-transparent fw-bolder" role="tablist">
                        <li className="nav-item" role="presentation" onClick={() => TabActive(1)}>
                            <a
                                className="nav-link active"
                                data-bs-toggle="tab"
                                href="#home1"
                                role="tab"
                                aria-selected="true"
                                tabIndex={-1}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="fas fa-home" />
                                </span>
                                <span className="d-none d-sm-block">Acciones</span>
                            </a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#profile1"
                                role="tab"
                                aria-selected="false"
                                tabIndex={-1}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="far fa-user" />
                                </span>
                                <span className="d-none d-sm-block">POA</span>
                            </a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#messages1"
                                role="tab"
                                aria-selected="false"
                                tabIndex={-1}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="far fa-envelope" />
                                </span>
                                <span className="d-none d-sm-block">Detalles</span>
                            </a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#settings1"
                                role="tab"
                                aria-selected="false"
                            >
                                <span className="d-block d-sm-none">
                                    <i className="fas fa-cog" />
                                </span>
                                <span className="d-none d-sm-block">Reportes</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="tab-content p-3 text-muted">
                <div className="tab-pane" id="home1" role="tabpanel">
                    <AccionesComponent />
                </div>
                <div className="tab-pane" id="profile1" role="tabpanel">
                    <p className="mb-0">
                        Food truck fixie locavore, accusamus mcsweeney's marfa nulla
                        single-origin coffee squid. Exercitation +1 labore velit, blog
                        sartorial PBR leggings next level wes anderson artisan four loko
                        farm-to-table craft beer twee. Qui photo booth letterpress, commodo
                        enim craft beer mlkshk aliquip jean shorts ullamco ad vinyl cillum
                        PBR. Homo nostrud organic, assumenda labore aesthetic magna delectus.
                    </p>
                </div>
                <div className="tab-pane" id="messages1" role="tabpanel">
                    <p className="mb-0">
                        Etsy mixtape wayfarers, ethical wes anderson tofu before they sold out
                        mcsweeney's organic lomo retro fanny pack lo-fi farm-to-table
                        readymade. Messenger bag gentrify pitchfork tattooed craft beer,
                        iphone skateboard locavore carles etsy salvia banksy hoodie helvetica.
                        DIY synth PBR banksy irony. Leggings gentrify squid 8-bit cred
                        pitchfork. Williamsburg banh mi whatever gluten-free carles.
                    </p>
                </div>
                <div className="tab-pane active show" id="settings1" role="tabpanel">
                    <p className="mb-0">
                        Trust fund seitan letterpress, keytar raw denim keffi
                        yeh etsy art
                        party before they sold out master cleanse gluten-free squid scenester
                        freegan cosby sweater. Fanny pack portland seitan DIY, art party
                        locavore wolf cliche high life echo park Austin. Cred vinyl keffiyeh
                        DIY salvia PBR, banh mi before they sold out farm-to-table VHS viral
                        locavore cosby sweater. Lomo wolf viral, mustache readymade keffiyeh
                        craft.
                    </p>
                </div>
            </div>
        </Fragment>
    )
}

export default Formulario