import React, { Fragment, useContext, useEffect } from 'react'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback } from 'reactstrap'
import { ContentContext } from './context'
import * as Icon from 'react-feather'
import { useForm, Controller } from "react-hook-form"
import Select from 'react-select'
import { customStyles } from '../../../Globales/index'
import { ContentContext as ContentPresupuesto } from '../PresupuestoPoa/context'

const Formulario = () => {
    const { idPresupuestoPoa } = useContext(ContentPresupuesto);
    const { StoreUpdate, opcion, oneData, labelEjes } = useContext(ContentContext),
        { handleSubmit, formState: { errors }, setValue, control, reset } = useForm(),
        onSubmit = (data) => {
            const json = {
                id: (oneData) ? oneData.id : null,
                nombre: data.nombre,
                eje: data.eje.value,
                idPresupuestoPoa: idPresupuestoPoa
            }
            StoreUpdate(json)
            clearData()
        },
        setData = async () => {
            await setValue('nombre', oneData.nombre)
            await setValue('eje', { label: oneData.eje, value: oneData.idEje })
        },
        clearData = async () => {
            reset({ nombre: '', eje: '' })
        }

    useEffect(
        () => {
            async function fetchMyAPI() {
                if (await opcion > 1 && oneData) { await setData() }
                if (await opcion === 1) { clearData() }
            }

            fetchMyAPI()

        }, [opcion, oneData]
    )

    return (
        <Fragment>
            <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

                <Row>
                    <Col>
                        <FormGroup >
                            <Label >* Eje</Label>
                            <Controller
                                name="eje"
                                control={control}
                                defaultValue={null}
                                render={({ field }) => {
                                    return (
                                        <Select
                                            {...field}
                                            isClearable
                                            onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                            onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                            isDisabled={opcion === 2}
                                            options={labelEjes}
                                            // className='react-select'
                                            classNamePrefix='select'
                                            placeholder={'Seleccione una opción'}
                                            noOptionsMessage={() => 'sin resultados'}
                                            styles={(!!errors.eje) ? customStyles : {}}

                                        />
                                    )
                                }
                                }
                                rules={
                                    {
                                        required: {
                                            value: true,
                                            message: 'Este campo es requerido'
                                        }
                                    }
                                }
                            />
                            <small className="text-danger">{!!errors.eje && errors.eje.message}</small>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for='nombre' className="">* Objetivo</Label>
                            <Controller

                                control={control}
                                id='nombre'
                                name='nombre'
                                render={({ field }) => (
                                    <Input
                                        rows={2}
                                        type='textarea'
                                        readOnly={opcion === 2}
                                        invalid={errors.nombre && true}
                                        defaultValue={null}
                                        {...field}
                                    />
                                )
                                }
                                rules={
                                    {
                                        required: {
                                            value: true,
                                            message: 'Este campo es requerido'
                                        }
                                    }
                                }
                            />
                            <FormFeedback>
                                {!!errors.nombre && errors.nombre.message}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col lg={2} xl={2} className="align-self-center text-center">
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion" className="text-center me-2">
                                    <Icon.Save size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion !== 2 && opcion === 1 ? 'Crear' : 'Actualizar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </Col>
                </Row>
            </Form>
        </Fragment>
    )
}

export default Formulario