import React, { Fragment, useState, useContext, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { Row, Col, FormGroup, Input, UncontrolledButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap'
import * as Icon from 'react-feather'
import { CustomText, EstadoText } from '../../../CustomList/index'
import { ContentContext } from './context'
import { useHistory } from 'react-router-dom'
import { ContentContext as ContentContextTheme } from '../../../components/CommonForBoth/TopbarDropdown/context'
import 'moment/locale/es-mx';

const List = (props) => {
  const { theme } = useContext(ContentContextTheme)
  const history = useHistory();
  const [roles, setRoles] = useState([]);
  const Blowfish = require('javascript-blowfish');
  const bf = new Blowfish(process.env.REACT_APP_CONFIG_KEY);
  const { allData, One, Actions, setIdPresupuestoPoa, setAccion, code, Imprimir, setPdf, BitacoraPedido, puestoUserLogged } = useContext(ContentContext)
  const [searchValue, setSearchValue] = useState(''),
    // GestionesXAprobador = allData ,


    //Transporte
    // idEstado=== 10 roles.includes('48372') //Jefe Transp
    // idEstado=== 3 roles.includes('C7F42') //Tecnica Gestiones TRANSP
    // idEstado=== 4 roles.includes('34E6E') //VoBo SEI/SG
    // idEstado=== 5 roles.includes ('48372') //Jefe Transp Asigna Pilotoy Vehiculo




    // hacer el filtro por roles y estado de la gestion 
    GestionesXAprobador = allData && allData?.value !== 2009 && allData?.filter(item => (


      //Soporte Tecnico 49366
      (item?.idEstado !== 0 && item?.idEstado !== 1 && item?.idEstado !== 2) &&
      item?.idActividadGestion === 3 && roles?.includes('49366') ||
      (item?.idEstado !== 0 && item?.idEstado !== 1 && item?.idEstado !== 2) &&
      item?.idActividadGestion === 7 && roles?.includes('49366') ||
      // Transporte 48372
      (item?.idEstado !== 0 && item?.idEstado !== 1 && item?.idEstado !== 2) &&
      item?.idActividadGestion === 1 && roles?.includes('48372') ||
      (item?.idEstado !== 0 && item?.idEstado !== 1 && item?.idEstado !== 2) &&
      item?.idActividadGestion === 2 && roles?.includes('48372')



    )),

    [filteredData, setFilteredData] = useState([]),
    handleFilter = e => {
      const value = e.target.value
      let updatedData = []
      setSearchValue(value)

      if (value.length) {
        updatedData = GestionesXAprobador && GestionesXAprobador.value !== 2009 && GestionesXAprobador.filter(item => {
          const startsWith =
            item.dependencia.toLowerCase().startsWith(value.toLowerCase()) ||
            item.correlativo.toLowerCase().startsWith(value.toLowerCase())

          const includes =
            item.dependencia.toLowerCase().startsWith(value.toLowerCase()) ||
            item.correlativo.toLowerCase().startsWith(value.toLowerCase())

          if (startsWith) {
            return startsWith
          } else if (!startsWith && includes) {
            return includes
          } else return null
        })
        setFilteredData(updatedData)
        setSearchValue(value)
      }
    },
    MuestraPDF = async (data) => {
      const doc = await Imprimir(data)
      setPdf(doc)
    },
    Columns = [
      {
        name: 'Correlativo',
        column: 'correlativo',
        sortable: true,
        center: false,
        grow: 2,
        cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '13px' }}>{row?.correlativo}</div> // Esto asegura que el contenido no se trunca
      },
      {
        name: 'Solicitante',
        column: 'correlativo',
        sortable: true,
        center: false,
        grow: 2,
        cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '11px' }}>{row?.solicitantePersona}</div> // Esto asegura que el contenido no se trunca
      },
      {
        name: 'Asignado a la Gestión',
        column: 'fechaUso4',
        sortable: true,
        center: false,
        grow: 2, // Ajusta el valor según sea necesario para dar más espacio a la columna
        wrap: true,
        cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '11px' }}>{row?.personasAsignadas}</div> // Esto asegura que el contenido no se trunca
      },
      {
        name: 'Fecha de Solicitud',
        column: 'fechaSolicitada2',
        sortable: true,
        center: false,
        grow: 2,
        cell: row => CustomText(row['fechaSolicitada2'])
      },
      {
        name: 'Tipo de Gestión',
        column: 'actividadGestion',
        sortable: true,
        center: false,
        grow: 3, // Ajusta el valor según sea necesario para dar más espacio a la columna
        wrap: true,
        cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '11px' }}>{row?.actividadGestion}</div> // Esto asegura que el contenido no se trunca
      },
      // {
      //   name: 'Justificación',
      //   column: 'justificacion',
      //   sortable: true,
      //   grow: 7, // Ajusta el valor según sea necesario para dar más espacio a la columna
      //   wrap: true,
      //   cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.justificacion}</div> // Esto asegura que el contenido no se trunca

      // },
      // {
      //   name: 'Año',
      //   column: 'año',
      //   sortable: true,
      //   center: true,
      //   cell: row => CustomText(row['año'])
      // },
      {
        name: 'Estado',
        column: 'estado',
        sortable: true,
        center: true,
        grow: 2,
        cell: row => <EstadoText row={row} />
      },
      {
        name: 'Acciones',
        column: 'id',
        sortable: true,
        center: true,
        grow: 1,
        cell: row => (
          <UncontrolledButtonDropdown direction='start'>

            <DropdownToggle className='pr-1' tag='span'>
              <Icon.MoreVertical size={15} />
            </DropdownToggle>
            <DropdownMenu className="!m-0"  >

              <DropdownItem className="w-100" onClick={() => [One(row, 2), setAccion(row.idAccion)]}>
                <Icon.Edit className="me-2" size={15} />
                <span className='align-middle ml-50'>Ver Gestión</span>
              </DropdownItem>

              {
                <DropdownItem className="w-100" onClick={() => [BitacoraPedido(row, 6), setIdPresupuestoPoa(row.id)]}>
                  <Icon.GitPullRequest className="me-2" size={15} />
                  <span className='align-middle ml-50'>Ver Proceso</span>
                </DropdownItem>
              }


            </DropdownMenu>
          </UncontrolledButtonDropdown>
        )
      }
    ];

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const Decript = bf.decrypt(bf.base64Decode(localStorage.getItem('authUser')));
      const data = JSON.parse(Decript.replace(/\0/g, ''));
      // const obj = JSON.parse(localStorage.getItem("authUser"));
      setRoles(data.roles);
    }
  }, [props.success]);

  return (
    <Fragment>
      <Row className='justify-content-end mx-0'>
        <Col>

        </Col>
        <Col className='justify-content-end mt-1' md='4' sm='12'>
          <FormGroup>
            <Input
              className='dataTable-filter mb-50'
              type='text'
              bsSize='md'
              placeholder={`Buscar`}
              id='search-input'
              value={searchValue}
              onChange={handleFilter}
            />
          </FormGroup>
        </Col>
      </Row>
      <div className='card'>
        <DataTable
          // dense
          striped
          noHeader
          highlightOnHover
          // expandableRows expandableRowsComponent={ExpandedComponent}
          pagination
          theme={theme}
          data={searchValue.length ? filteredData : (GestionesXAprobador && GestionesXAprobador.value !== 2009 ? GestionesXAprobador : [])}
          columns={Columns}
          className='table-responsive mt-4'
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          paginationComponentOptions={
            {
              rowsPerPageText: '',
              rangeSeparatorText: ''
            }
          }
          noDataComponent='Sin Registros'
        />
      </div>
    </Fragment>
  )
}

export default List