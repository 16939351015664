import React, { useContext, useEffect, useState } from "react"
import { Button, Form, Row, Col, UncontrolledTooltip } from "reactstrap"
import { ContentContext } from "./context"
import { useForm } from "react-hook-form"
import * as Icon from "react-feather"
import { OptionsToast } from "Globales/index"
import { toast } from "react-toastify"
import List from "./List"

export const Attached = ({ onUpload }) => {
  const RoutePathServer = process.env.REACT_APP_PATH_SERVER
  //const fileUrl = `${RoutePathServer + file?.archivo}`;

  // fileUrl = `${RoutePathServer + oneFile?.archivo}`;

  //desarrollo:
  const fileUrl = `${
    RoutePathServer +
    "Uploads/AdjuntosPedido/Factura_solicitud_GCC-0004-2024_orden_de_compra_0_fecha_25-07-2024-02-08-06.pdf"
  }`

  const [hasNewFile, setHasNewFile] = useState(false)

  const { storeFile, data2, oneFile } = useContext(ContentContext)
  const { register, handleSubmit, setValue } = useForm(),
    [Visualizar, setVisualizar] = useState(""),
    [getFoto, setGetFoto] = useState([]),
    onSubmitFile = async data => {
      if (!data?.file[0]) return

      let formData = new FormData()
      formData.append("documento", data2?.id)
      const file = data.file[0]
      const filename = file.name
      const extension = filename.slice(
        ((filename.lastIndexOf(".") - 1) >>> 0) + 2
      )
      formData.append("archivo", file, `${data2.correlativo}.${extension}`)
      Visualizar === ""
        ? toast.error("Debe seleccionar un archivo", OptionsToast)
        : store(formData)
      Limpiar()
    },
    MostrarDoc = value => {
      if (value.target.value) {
        var pdffile = value.target.files[0],
          pdffile_url = URL.createObjectURL(pdffile)
        setGetFoto(pdffile)
        setVisualizar(`${pdffile_url}`)
        setHasNewFile(true)
      } else {
        setVisualizar("")
      }
    },
    Limpiar = () => {
      setVisualizar("")
      setValue("file", null)
      setGetFoto()
    }

  useEffect(() => {
    console.log(oneFile)
    if (oneFile && oneFile.length > 0) {
      setVisualizar(fileUrl)
    }
  }, [])

  const store = async formData => {
    await storeFile(formData)
    console.log("Se ejecuta el evento")
    onUpload()
  }

  return (
    <>
      <Row>
        {/* <Col>      
            <List />
        </Col> */}
        <Col>
          <Row>
            <Col>
              <Form onSubmit={handleSubmit(onSubmitFile)}>
                <Row>
                  <Col>
                    <div className="file-input" id="Cargar">
                      <input
                        type="file"
                        id="file"
                        {...register("file")}
                        className="file"
                        accept="application/pdf,image/png,image/jpeg,image/jpg"
                        onChange={e => MostrarDoc(e)}
                      />
                      <label htmlFor="file">
                        <Icon.UploadCloud size={15} />
                        <p className="file-name"></p>
                      </label>
                      <UncontrolledTooltip
                        placement="top"
                        target="Cargar"
                        placementPrefix="bs-tooltip"
                      >
                        Cargar Adjunto
                      </UncontrolledTooltip>
                    </div>
                  </Col>
                  
                  {(hasNewFile && Visualizar.length > 0) ? (
                    <Col lg={2}>
                      <Button
                        className="symbol symbol-circle symbol-50px mt-3"
                        color="info"
                        type="submit"
                        id="Confirmar"
                      >
                        <Icon.Check size={18} />
                      </Button>
                      <UncontrolledTooltip
                        placement="top"
                        target="Confirmar"
                        placementPrefix="bs-tooltip"
                      >
                        Confirmar
                      </UncontrolledTooltip>
                    </Col>
                  ) : null}


                  {Visualizar.length > 0 && !(oneFile && oneFile.length > 0) ? (
                    <Col lg={2}>
                      <Button
                        className="symbol symbol-circle symbol-50px mt-3"
                        color="danger"
                        // type="submit"
                        onClick={() => Limpiar()}
                        id="Cancelar"
                      >
                        <Icon.X size={18} />
                      </Button>
                      <UncontrolledTooltip
                        placement="top"
                        target="Cancelar"
                        placementPrefix="bs-tooltip"
                      >
                        Cancelar
                      </UncontrolledTooltip>
                    </Col>
                  ) : null}
                </Row>
              </Form>
              <object
                className="Visor my-3"
                width="100%"
                height="420"
                id="nombre-visor"
                aria-labelledby="expediente-solicitud"
                type="application/pdf"
                data={Visualizar}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default Attached
