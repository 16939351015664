
// import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useContext, useEffect, useState, useRef } from 'react'
import { ContentContext } from './context'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback, CardBody, CardTitle } from 'reactstrap'
import { useForm, Controller } from "react-hook-form"
import * as Icon from 'react-feather'
import { Text } from "@nextui-org/react";
// import Detalle from '../DetallePedidos/form'
import Attached from './Adjunto/Attached'
// import { ContentProvider } from '../DetallePedidos/context'
import { ContentProvider as ContentProvider2 } from '../ConsultaExpedientes/Adjunto/context'
import { registerLocale } from "react-datepicker"
import es from "date-fns/locale/es"
import Moment from 'react-moment';
import 'moment/locale/es-mx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";
import "/node_modules/swiper/swiper.scss";
import { ContentContext as ContentContextTheme } from '../../../components/CommonForBoth/TopbarDropdown/context'
registerLocale("es", es); // register it with the name you want


const Formulario = () => {
    const { theme } = useContext(ContentContextTheme)
    // const [startDate, setStartDate] = useState(Date.parse(2019) + 1);
    const [startDate, setStartDate] = useState(new Date());
    const [validador, setValidador] = useState(false);
    const [tipoRechazo, setTipoRechazo] = useState(0);
    const { toggleModal, modal, StoreUpdate, opcion, oneData, labelAcciones, setId, agregarRenglon, setAgregarRenglon, labelFinanciamiento, idPresupuestoPoa, labelTipoGasto, labelTipoPedido, labelFand, useDataUser, nameComponent, labelJefeDireccion, solicitante, pdf, UpdateEstadoPedido, DataBitacoraPedido, EnviarARevision, opcion2, toggleModal2, modal2, } = useContext(ContentContext),
        { register, handleSubmit, formState: { errors }, control, reset, watch } = useForm(),
        inputTipoRechazo = watch("tipoRechazo"),
        onSubmitEstado = (data) => {
            if (opcion === 7) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: Number(data?.tipoRechazo),
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 1
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 8) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 2
                }

                UpdateEstadoPedido(json)
            }
            if (opcion === 9) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 3
                }

                UpdateEstadoPedido(json)
            }
            if (opcion === 10) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: Number(data?.tipoRechazo),
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 1
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 11) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 4
                }

                UpdateEstadoPedido(json)
            }
            if (opcion === 12) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: Number(data?.tipoRechazo),
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 1
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 13) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 6
                }

                UpdateEstadoPedido(json)
            }
            if (opcion === 14) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: Number(data?.tipoRechazo),
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 1
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 21) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: 3,
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 33,
                }
                UpdateEstadoPedido(json)
            }
            reset()
            toggleModal(0)
        },
        onSubmitEnviarRevision = (data) => {
            if (opcion === 20) {
                if (oneData?.idtipoPedido === 2) {//bien
                    const json2 = {
                        id: (oneData) ? oneData.id : null,
                        tipoRechazo: null,
                        descripcion: null,
                        estado: 13
                    }
                    const json = {
                        id: (oneData) ? oneData.id : null
                    }
                    UpdateEstadoPedido(json2)
                    EnviarARevision(json)
                }
                if (oneData?.idtipoPedido === 1) {//bien
                    const json2 = {
                        id: (oneData) ? oneData.id : null,
                        tipoRechazo: null,
                        descripcion: null,
                        estado: 2
                    }
                    const json = {
                        id: (oneData) ? oneData.id : null
                    }
                    UpdateEstadoPedido(json2)
                    EnviarARevision(json)
                }

            }
            if (opcion === 25) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 43
                }

                UpdateEstadoPedido(json)
            }

            reset()
            toggleModal(0)
        };

    const prevInputTipoRechazo = useRef(null);

    useEffect(() => {
        if (!isNaN(inputTipoRechazo)) {
            setTipoRechazo(Number(inputTipoRechazo));
            prevInputTipoRechazo.current = inputTipoRechazo;
        }
    }, [inputTipoRechazo]);

    // console.log(oneData?.idtipoPedido)
    return (
        <Fragment>
            
            {/* Opcion Ver PROCESO */}
            {opcion === 6 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered modal-xl'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>Proceso</ModalHeader>
                <Form autoComplete="off"
                >
                    <ModalBody>
                        <>
                            <Row>
                                <Col lg="12">
                                    <div>
                                        <CardBody>
                                            <CardTitle className="mb-4 text-center">Tiempo transcurrido: {DataBitacoraPedido && DataBitacoraPedido[DataBitacoraPedido.length - 1]?.tiempoTranscurrido} || Solicitado:   <Moment locale="es" format='lll' fromNow>{DataBitacoraPedido && DataBitacoraPedido[0]?.fechaCreado}</Moment></CardTitle>
                                            {/* <h5 className="mb-4">Línea de Tiempo</h5> */}

                                            <div className="hori-timeline">
                                                <Swiper
                                                    slidesPerView={1}
                                                    // spaceBetween={10}
                                                    navigation
                                                    pagination={{
                                                        clickable: true,
                                                    }}
                                                    breakpoints={{
                                                        678: {
                                                            slidesPerView: 2,
                                                        },
                                                        992: {
                                                            slidesPerView: 3,
                                                        },
                                                        1400: {
                                                            slidesPerView: 4,
                                                        }
                                                    }}
                                                    loop={false}
                                                    modules={[Pagination, Navigation]}
                                                    className="owl-carousel owl-theme  navs-carousel events"
                                                    id="timeline-carousel"
                                                >
                                                    {DataBitacoraPedido && DataBitacoraPedido?.map((item, index) => (
                                                        <SwiperSlide
                                                            key={index}
                                                            className="item event-list"
                                                            style={{ display: "inline-table" }}
                                                        >
                                                            <div>
                                                                <div className="event-date">
                                                                    <div className="text-primary mb-1">
                                                                        <Moment locale="es" format='lll' fromNow>{item?.creado}</Moment>
                                                                    </div>
                                                                    <h5 className="mb-4">{item?.estadoTexto}</h5>
                                                                </div>
                                                                <div className="event-down-icon">
                                                                    <i className={`bx ${(item?.estadoTexto?.includes('Rechazado')) || (item?.estadoTexto?.includes('Anulación Operada')) ? 'bx-x-circle text-danger' : 'bx-check-circle text-success'} h1  down-arrow-icon`} />
                                                                </div>

                                                                <div className="mt-3 px-3">
                                                                    <p className="text-muted">
                                                                        {item?.usuario}
                                                                    </p>
                                                                </div>
                                                                <div className="mt-3 px-3">
                                                                    <p className="text-muted">
                                                                        <strong>{item?.rechazoTipoTexto}</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}

                                                </Swiper>
                                            </div>
                                        </CardBody>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Regresar
                        </UncontrolledTooltip>
                        {/* {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Printer size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    Imprimir
                                </UncontrolledTooltip>
                            </Fragment>
                        } */}
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Ver PDF */}
            {opcion === 5 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered modal-xl'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>PDF</ModalHeader>
                <Form autoComplete="off"
                >
                    <ModalBody>
                        <Row>
                            <Col >
                                <object
                                    className='Visor'
                                    width='100%'
                                    height='600vh'
                                    id='pdf-ficha'
                                    aria-labelledby='perfil'
                                    type='application/pdf'
                                    // data={`https://s1.q4cdn.com/806093406/files/doc_downloads/test.pdf`}
                                    data={`${pdf}`}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Regresar
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <a className="btn btn-success " id="imprimir" download="pedido.pdf" href={`${pdf}`} target="_blank" rel="noopener noreferrer"><Icon.Download size={16} /> Descargar</a>
                                {/* <Button color='success' type="submit" id="accion">
                                    <Icon.Printer size={16} />
                                </Button> */}
                                {/* <UncontrolledTooltip placement='top' target='imprimir' placementPrefix="bs-tooltip">
                                    Imprimir
                                </UncontrolledTooltip> */}
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Aprobar Almacén */}
            {opcion === 8 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 8 && 'Aprobar'} Solicitud de Compra</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                    <ModalBody>

                        <Row>
                            <Label className=" justify-content-between">Indique Estado de Insumo</Label>
                            <Col >
                                <div className="form-check mb-2">
                                    <input className="form-check-input" {...register("existencia", { required: true })} type="radio" value={1} />
                                    <label className="form-check-label" htmlFor={1}>
                                        En Existencia
                                    </label>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" {...register("existencia", { required: true })} type="radio" value={2} />
                                    <label className="form-check-label" htmlFor={2}>
                                        Sin Existencia
                                    </label>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 8 && 'Aprobar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Aprobar Unidad */}
            {opcion === 40 &&
                <Modal
                    isOpen={modal}
                    backdrop='static'
                    toggle={() => toggleModal()}
                    style={{ maxWidth: '1800px', width: '80%', margin: '10px auto' }}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>FACTURAS Y DOCUMENTOS ADJUNTOS DE COMPRAS</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>
                            <Row >
                                <ContentProvider2>
                                    <Attached />
                                </ContentProvider2>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            {/* {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 9 && 'Aprobar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        } */}
                        </ModalFooter>
                    </Form>
                </Modal>}
            {/* Opcion Rechazar Unidad */}
            {opcion === 10 && <Modal
                isOpen={modal}
                backdrop='static'
                style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 10 && 'Rechazar'} esta solicitud de compra?</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                    <ModalBody>
                        <Row>
                            <Label className=" justify-content-between">Motivos de Rechazo</Label>
                            <Col >
                                <div className="form-check mb-2">
                                    <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={1} />
                                    <label className="form-check-label" htmlFor={1}>
                                        Cambio en Justificación
                                    </label>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={2} />
                                    <label className="form-check-label" htmlFor={2}>
                                        Cambio en Descripción / Insumo
                                    </label>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={3} />
                                    <label className="form-check-label" htmlFor={3}>
                                        Otro (Especifique)
                                    </label>
                                </div>
                            </Col>

                        </Row>
                        {tipoRechazo === 3 &&
                            <Row>
                                <Col >
                                    <FormGroup>
                                        <Label for="descripcion" className="">* Motivo Rechazo</Label>
                                        <Controller

                                            control={control}
                                            id='descripcion'
                                            name='descripcion'
                                            render={({ field }) => (
                                                <Input
                                                    rows={2}
                                                    type='textarea'
                                                    // readOnly={updateData}
                                                    invalid={errors.descripcion && true}
                                                    defaultValue=''
                                                    {...field}
                                                />
                                            )
                                            }
                                            rules={
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido'
                                                    }
                                                }
                                            }
                                        />
                                        <FormFeedback>
                                            {!!errors.descripcion && errors.descripcion.message}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Save size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 10 && 'Operar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Aprobar Financiero */}
            {opcion === 11 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 11 && 'Aprobar'} Solicitud de Compra</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                    <ModalBody>
                        <Row >
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    ¿Está seguro de que todo está bien y desea proceder?
                                </Text>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 11 && 'Aprobar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Rechazar Financiero */}
            {opcion === 12 && <Modal
                isOpen={modal}
                backdrop='static'
                style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 12 && 'Rechazar'} esta solicitud de compra?</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                    <ModalBody>
                        <Row>
                            <Label className=" justify-content-between">Motivos de Rechazo</Label>
                            <Col >
                                <div className="form-check mb-2">
                                    <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={1} />
                                    <label className="form-check-label" htmlFor={1}>
                                        Cambio en Justificación
                                    </label>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={2} />
                                    <label className="form-check-label" htmlFor={2}>
                                        Cambio en Descripción / Insumo
                                    </label>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={3} />
                                    <label className="form-check-label" htmlFor={3}>
                                        Otro (Especifique)
                                    </label>
                                </div>
                            </Col>

                        </Row>
                        {tipoRechazo === 3 &&
                            <Row>
                                <Col >
                                    <FormGroup>
                                        <Label for="descripcion" className="">* Motivo Rechazo</Label>
                                        <Controller

                                            control={control}
                                            id='descripcion'
                                            name='descripcion'
                                            render={({ field }) => (
                                                <Input
                                                    rows={2}
                                                    type='textarea'
                                                    // readOnly={updateData}
                                                    invalid={errors.descripcion && true}
                                                    defaultValue=''
                                                    {...field}
                                                />
                                            )
                                            }
                                            rules={
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido'
                                                    }
                                                }
                                            }
                                        />
                                        <FormFeedback>
                                            {!!errors.descripcion && errors.descripcion.message}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Save size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 12 && 'Operar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Aprobar Planificacion  */}
            {opcion === 13 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 13 && 'Aprobar'} Solicitud de Compra</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                    <ModalBody>
                        <Row >
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    ¿Está seguro de que todo está bien y desea proceder?
                                </Text>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 13 && 'Aprobar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Rechazar Planificacion */}
            {opcion === 14 && <Modal
                isOpen={modal}
                backdrop='static'
                style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 14 && 'Rechazar'} esta solicitud de compra?</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                    <ModalBody>
                        <Row>
                            <Label className=" justify-content-between">Motivos de Rechazo</Label>
                            <Col >
                                <div className="form-check mb-2">
                                    <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={1} />
                                    <label className="form-check-label" htmlFor={1}>
                                        Cambio en Justificación
                                    </label>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={2} />
                                    <label className="form-check-label" htmlFor={2}>
                                        Cambio en Descripción / Insumo
                                    </label>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={3} />
                                    <label className="form-check-label" htmlFor={3}>
                                        Otro (Especifique)
                                    </label>
                                </div>
                            </Col>

                        </Row>
                        {tipoRechazo === 3 &&
                            <Row>
                                <Col >
                                    <FormGroup>
                                        <Label for="descripcion" className="">* Motivo Rechazo</Label>
                                        <Controller

                                            control={control}
                                            id='descripcion'
                                            name='descripcion'
                                            render={({ field }) => (
                                                <Input
                                                    rows={2}
                                                    type='textarea'
                                                    // readOnly={updateData}
                                                    invalid={errors.descripcion && true}
                                                    defaultValue=''
                                                    {...field}
                                                />
                                            )
                                            }
                                            rules={
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido'
                                                    }
                                                }
                                            }
                                        />
                                        <FormFeedback>
                                            {!!errors.descripcion && errors.descripcion.message}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Save size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 14 && 'Operar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Enviar a Revisión Vista Previa */}
            {opcion === 20 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 20 && 'Enviar '} Solicitud de Compra</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEnviarRevision)}>
                    <ModalBody>
                        <Row >
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    ¿Está seguro de que todo está bien y desea proceder?
                                </Text>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Send size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 20 && 'Enviar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}

            {/* Opcion Anular Solicitud */}
            {opcion === 21 && <Modal
                isOpen={modal}
                backdrop='static'
                style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 21 && 'SOLICITAR ANULACION DE'} proceso</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                    <ModalBody>
                        <Row>
                            <Label className=" justify-content-between">Motivos</Label>

                            <Col>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={3} checked={true} onChange={() => setTipoRechazo(3)} />
                                    <label className="form-check-label" htmlFor={3}>
                                        Otro (Especifique)
                                    </label>
                                </div>
                            </Col>

                        </Row>
                        {/* {tipoRechazo === 3 && */}
                        <Row>
                            <Col >
                                <FormGroup>
                                    <Label for="descripcion" className=""></Label>
                                    <Controller

                                        control={control}
                                        id='descripcion'
                                        name='descripcion'
                                        render={({ field }) => (
                                            <Input
                                                rows={2}
                                                type='textarea'
                                                // readOnly={updateData}
                                                invalid={errors.descripcion && true}
                                                defaultValue=''
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.descripcion && errors.descripcion.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        {/* } */}
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Save size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 21 && 'Operar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Notificar de impresión */}
            {opcion2 === 24 && <Modal
                isOpen={modal2}
                backdrop='static'
                toggle={() => toggleModal2()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal2(0)}>Notificación</ModalHeader>
                <Form autoComplete="off" >
                    <ModalBody>
                        <Row >
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    ¡La solicitud fue marcada como impresa!
                                </Text>
                            </Col>
                            <br />
                            <br />
                            <span className='text-center'>
                                {` ${esFADN === '1' ? 'Esto notifica a Comité Olímpico Guatemalteco que la solicitud fue recibida ha conformidad por la Federación / Asociación Deportiva Nacional.' : 'Esto notifica a la Dirección de Compras que la solicitud ya ha sido impresa y que está pendiente de entrega fisicamente (ya firmada) en sus respectivas oficinas.'} `}
                            </span>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal2(0)}>
                            <Icon.X size={16} /> Cerrar
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Enviar a Unidad por Ajuste */}
            {opcion === 25 && <Modal
                isOpen={modal}
                backdrop='static'
                style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 25 && 'Enviar Ajuste de'} Solicitud de Compra</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEnviarRevision)}>
                    <ModalBody>
                        <Row >

                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                    color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                >
                                    Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                </Text>
                            </Col>
                        </Row>
                        {/* <Row >
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    ¿Está seguro de que todo está bien y desea proceder?
                                </Text>
                            </Col>
                        </Row> */}
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Send size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 25 && 'Enviar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}


        </Fragment >
    )
}

export default Formulario