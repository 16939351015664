import React, { useState } from 'react'
import { Row, Col, Card } from 'reactstrap'
import { ContentProvider } from './context'
import Formulario from './form'
import List from './list'

const Index = () => {
    const [tab, setTab] = useState(1),
        TabActive = (No) => {
            setTab(No)
        };

    return (
        <ContentProvider>
            <div className="page-content">
                <Row>
                    <Col lg="2" sm="2">
                    </Col>
                    <Col>
                        <div className="h1 font-weight-bolder text-uppercase text-center">
                            Gestión de Documentos
                        </div>
                    </Col>
                    <Col className="d-flex justify-content-end" lg="2" sm="2">
                        <Formulario />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <List />
                    </Col>
                </Row>
            </div>
        </ContentProvider >
    )
}

export default Index