import React, { createContext, useState, useEffect } from 'react'
import { PostRoute } from '../../../services/Private'
import { OptionsToast } from '../../../Globales/index'
import { toast } from 'react-toastify'
import { useRequest } from '../../../hooks/useRequestSWR'
import { useSWRConfig } from 'swr'
import { useDataUser } from 'hooks/useDataUser'
import PDFPedido from './PDFPedido.jsx'
import PDFGasto from './PDFGasto.jsx'
import PDFCajaChica from './PDFCajaChica.jsx'


export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    // Modal Init
    const code = useDataUser()?.dataUser?.codigoUnidad;
    const solicitante = useDataUser()?.dataUser?.i;
    const [idAccion, setAccion] = useState(0);
    const [validador, setValidador] = useState(false);
    const { mutate } = useSWRConfig();
    const [id, setId] = useState(0);
    const [mutateSaldoAccion, setMutateSaldoAccion] = useState(false);
    const [DataBitacoraPedido, setDataBitacoraPedido] = useState([]);
    const [idPresupuestoPoa, setIdPresupuestoPoa] = useState(0);
    const [idModalidad, setIdModalidad] = useState(0);

    const [pdf, setPdf] = useState([]);
    const nameComponent = 'Modificaciones Presupuestarias';
    const nameController = 'Modificaciones';
    // const nameController2 = 'Acciones';
    // const nameController6 = 'Persona';
    // const nameController7 = 'Unidad';
    // const nameController8 = 'Financiamiento';
    const { data: allData } = useRequest(`${nameController}/all`, 'GETSWR');
    const
        [modal, setModal] = useState(null),
        // [allData, setAllData] = useState([]),
        [oneData, setOneData] = useState([]),
        [agregarRenglon, setAgregarRenglon] = useState(0),
        [opcion, setOpcion] = useState(0),
        toggleModal = async (data) => {

            setOpcion(data)
            if (data === 1) { setOneData(null), setIdPresupuestoPoa(0) }
            setModal(!modal)

        },
        // activaMutateTotalSaldo = async (data) => {

        //     setOpcion(data)
        //     if (data === 1) { setOneData(null), setIdPresupuestoPoa(0) }
        //     setModal(!modal)

        // },
        // All = async () => {
        //     const response = await GetRoute(`${nameController}/all`)
        //     setAllData((response.length) ? response : [])
        // },
        One = async (data, opcion) => {
            const response = await PostRoute(`${nameController}/one`, { id: data.id })
            setOneData((response[0]) ? response[0] : [])
            toggleModal(opcion)

        },
        BitacoraPedido = async (data, opcion) => {
            const response = await PostRoute(`${nameController}/bitacoraPedido`, { id: data.id })
            setDataBitacoraPedido((response) ? response : [])
            toggleModal(opcion)

        },
        StoreUpdate = async (data, id) => {
            let response = []
            response = await PostRoute(`${nameController}/${!data.id ? 'store' : 'update'}`, data)
            const responseTwo = await PostRoute(`${nameController}/one`, { id: (!id ? response?.id : id) })
            setOneData((responseTwo[0]) ? responseTwo[0] : [])
            setOpcion(3)
            if (response.response === 0) {
                toast.error(response.message, OptionsToast)
            } else {
                toast.success(response.message, OptionsToast)
                setAccion(responseTwo[0]?.idAccion)

            }
            // toast.success(response.message, OptionsToast)
            // toggleModal(0)
            mutate(`${nameController}/all`)

        },
        Actions = async (data) => {

            const response = await PostRoute(`${nameController}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data.id })
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)

        },
        UpdateEstadoPedido = async (data) => {
            const response = await PostRoute(`${nameController}/${'UpdateEstadoPedido'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)

        },
        EnviarARevision = async (data, opcion) => {
            toggleModal(opcion)
            const response = await PostRoute(`${nameController}/${'enviarARevision'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)


        },
        Imprimir = async (data) => {
            const response = await PostRoute(`DetalleModificaciones/by-accion`, { id: data?.id })
            const TOTAL = await PostRoute(`${nameController}/totalDetallePedido`, { id: data?.id })
            if (response?.response === 0) {
                toast.error(response.message, OptionsToast)
            } else {
                // if (data?.modalidad === 1) {
                    const response3 = await PDFPedido(data, response, TOTAL)
                    //const response3 = await FormatoRazonamientoCarta(data, dataPersonaLog.data[0], dataJefe.data[0])
                    return response3
                // }
                // if (data?.modalidad === 2) {
                //     const response3 = await PDFGasto(data, response, TOTAL)
                //     //const response3 = await FormatoRazonamientoCarta(data, dataPersonaLog.data[0], dataJefe.data[0])
                //     return response3
                // }
                // if (data?.modalidad === 3) {
                //     const response3 = await PDFCajaChica(data, response, TOTAL)
                //     //const response3 = await FormatoRazonamientoCarta(data, dataPersonaLog.data[0], dataJefe.data[0])
                //     return response3
                // }



            }

        },
        labelTipoModificacion = [
            { value: 1, label: 'MODIFICACION DE PRESUPUESTO DE LA UNIDAD' },
            { value: 2, label: 'AMPLIACION O DISMINUCION DE PRESUPUESTO' },
            { value: 3, label: 'MODIFICACION PRESUPUESTARIA ENTRE UNIDADES' }
        ],
        value = {
            Actions,
            One,
            setId,
            setIdPresupuestoPoa,
            setAgregarRenglon,
            setAccion,
            StoreUpdate,
            toggleModal,
            useDataUser,
            agregarRenglon,
            idAccion,
            allData,
            idPresupuestoPoa,
            modal,
            nameComponent,
            oneData,
            opcion,
            solicitante,
            mutateSaldoAccion, setMutateSaldoAccion,
            code,
            setIdModalidad,
            idModalidad,
            Imprimir,
            pdf, setPdf,
            validador,
            setValidador,
            labelTipoModificacion,
            EnviarARevision,
            UpdateEstadoPedido,
            BitacoraPedido,
            DataBitacoraPedido
        }
    // console.log(useDataUser())
    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}
