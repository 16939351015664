import React, { createContext, useState } from 'react'
import { PostRoute } from '../../../services/Private'
import { OptionsToast } from '../../../Globales/index'
import { toast } from 'react-toastify'
import { useRequest } from '../../../hooks/useRequestSWR'
import { useSWRConfig } from 'swr'
import { useForm, Controller } from "react-hook-form";

export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    // Modal Init
    const { watch, control } = useForm();
    const { mutate } = useSWRConfig();
    const [id, setId] = useState(0);
    const nameController = 'Visitantes';
    const nameController2 = 'Unidad';
    const nameController3 = 'TipoVisita';
    const nameController4 = 'UbicacionVisita';
    const nameFileExport = 'Reporte Visitantes';
    const { data: labelUnidades } = useRequest(`${nameController2}/label`, 'GETSWR');
    const { data: labelTipoVisita } = useRequest(`${nameController3}/label`, 'GETSWR');
    const { data: labelUbicacionVisita } = useRequest(`${nameController4}/label`, 'GET5MILISEC');
    const { data: dataEmpleados } = useRequest(`${nameController}/label-x-Unidad`, 'POST', { id: id });
    const nameComponent = 'Visitantes';
    const [file, setFile] = useState([]);
    const tiempoTranscurrido = Date?.now();
    const hoy = new Date(tiempoTranscurrido);
    const FechaInicio = watch('FechaInicial');
    const FechaFin = watch('FechaFinal');
    let setFechaInicio = FechaInicio ?? '2020-01-01'
    let setFechaFin = FechaFin ?? hoy?.toLocaleDateString('en-CA')?.split('T')[0];
    // const [allData, setAllData] = useState([]);
    const { data: allData } = useRequest(`${nameController}/all`, 'POST', { fechaInicial: setFechaInicio, fechaFinal: setFechaFin });
    const { data: allDentro } = useRequest(`${nameController}/all-dentro`, 'GET5MILISEC');
    // const
    //     All = async () => {
    //         const response = await PostRoute(`${nameController}/all`, { fechaInicial: setFechaInicio, fechaFinal: setFechaFin })
    //         setAllData((response.length > 0) ? response : []);
    //         if (response.value === 2009) {
    //             toast.error("No existen registros en la fecha seleccionada", OptionsToast);
    //         }
    //         ;
    //     };
    const { data: labelFand } = useRequest(
        `fand/label`,
        "GETSWR"
      )
    const
        [modal, setModal] = useState(null),
        [modal2, setModal2] = useState(null),
        // [allData, setAllData] = useState([]),
        [oneData, setOneData] = useState([]),
        [oneData2, setOneData2] = useState([]),
        [opcion, setOpcion] = useState(0),
        [opcion2, setOpcion2] = useState(0),
        
        toggleModal = async (data) => {

            setOpcion(data)
            if (data === 1) { setOneData(null) }
            setModal(!modal)

        },
        toggleModal2 = async (data) => {

            setOpcion2(data)
            if (data === 1) { setOneData2(null) }
            setModal2(!modal2)

        },
        // All = async () => {
        //     const response = await GetRoute(`${nameController}/all`)
        //     setAllData((response.length) ? response : [])
        // },
        One = async (data, opcion) => {

            const response = await PostRoute(`${nameController}/one`, { id: data.id })
            const responseTwo = await PostRoute(`${nameController}/oneFile`, { id: data.id })
            setOneData((response[0]) ? response[0] : [])
            setFile((responseTwo.length > 0) ? responseTwo : []);
            toggleModal(opcion)

        },
        StoreUpdate = async (data) => {
            let response = []
            response = await PostRoute(`${nameController}/${!data.id ? 'store' : 'update'}`, data)
            toast.success(response.message, OptionsToast)
            toggleModal(0)
            mutate(`${nameController}/all-dentro`)
            mutate([`${nameController}/all`, { fechaInicial: setFechaInicio, fechaFinal: setFechaFin }])

        },
        Actions = async (data) => {
            const response = await PostRoute(`${nameController}/${data.idEstado === 1 ? 'destroy' : 'active'}`, { id: data.id })
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all-dentro`)
            mutate([`${nameController}/all`, { fechaInicial: setFechaInicio, fechaFinal: setFechaFin }])
        },
        value = {
            One,
            Actions,
            StoreUpdate,
            toggleModal,
            toggleModal2,
            mutate,
            setFile,
            setId,
            Controller,
            nameFileExport,
            control,
            file,
            allData,
            allDentro,
            dataEmpleados,
            modal,
            modal2,
            oneData,
            oneData2,
            opcion,
            opcion2,
            labelUnidades,
            labelTipoVisita,
            labelUbicacionVisita,
            nameComponent,
            nameController,
            FechaInicio,
            FechaFin,
            setFechaInicio,
            setFechaFin,
            hoy,
            labelFand
        }

    // Modal End
    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}
