import React, { createContext, useState, useContext } from 'react'
import { PostRoute } from 'services/Private'
import { OptionsToast } from 'Globales/index'
import { toast } from 'react-toastify'
import { useRequest } from 'hooks/useRequestSWR'
import { useSWRConfig } from 'swr'
import { useRouteCode } from 'hooks/useRouteCode'
import { ContentContext as ContentMeta } from '../AprobarModificaciones/context'
import { useDataUser } from 'hooks/useDataUser'
export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    const{ 
        idAccion, 
        setMutateSaldoAccion
     } = useContext(ContentMeta);
    const { mutate } = useSWRConfig();
    const code = useDataUser()?.dataUser?.codigoUnidad;
    const [accion, setAccion] = useState(0);
    const [idModificacion, setIdModificacion] = useState(0);
    const [idUnidad, setIdUnidad] = useState('');
    const [renglon, setRenglon] = useState([]);
    const nameController4 = 'Acciones';
    const nameController5 = 'DetalleModificaciones';
    // const nameController6 = 'Insumos';
    const nameController7 = 'Unidad';
    const nameController8 = 'Financiamiento';

    const { data: allData } = useRequest(`${nameController5}/by-accion`, 'POST', { id: idModificacion });
    const { data: totalDetallePedido } = useRequest(`${nameController5}/total`, 'POST', { id: idModificacion });
    const { data: allData2 } = useRequest(`${nameController4}/one`, 'POST', { id: idModificacion });
    // const { data: allInsumos } = useRequest(`${nameController6}/All`, 'POST', { renglon: renglon });
    // const { data: labelUnidadMedida } = useRequest(`${nameController5}/labelUnidadMedida`, 'GET');

    const { data: labelFinanciamiento } = useRequest(`${nameController8}/label`, 'GET');
    const { data: labelAcciones } = useRequest(`${nameController4}/label-Unidad`, 'POST', { code: idUnidad !== '' ? idUnidad : code });
    const { data: labelUnidades } = useRequest(`${nameController7}/label`, 'GET');
    const { data: labelAllRenglon } = useRequest(`Renglon/label`, 'GET');
    const { data: labelRenglonXAccion } = useRequest(`DetalleAcciones/renglonesXAccion`, 'POST', { accion: accion });

    const [btnDisable, setBtnDisable] = useState(false);
    const [actualiza, setActualiza] = useState(false),
        [modal, setModal] = useState(null),
        [oneData, setOneData] = useState([]),
        [opcion, setOpcion] = useState(0),
        OneDetalle = async (data) => {
            // setActualiza(true)
            setBtnDisable(false)
            setOneData((data) ? data : [])
        },
        toggleModal = async (data) => {
            setOpcion(data)
            if (data === 1) { setOneData(null) }
            setModal(!modal)
        },
        One = async (data, opcion) => {
            const response = await PostRoute(`${nameController5}/one`, { id: data.id })
            setOneData((response[0]) ? response[0] : [])
            // setMutateSaldoAccion(true)
            toggleModal(opcion)
        },
        StoreUpdate = async (data) => {
            let response = []
            response = await PostRoute(`${nameController5}/${!data.id ? 'store' : 'update'}`, data)
            toast.success(response.message, OptionsToast)
            toggleModal(0)
            setOneData([])
            mutate([`${nameController5}/by-accion`, { id: idModificacion }])
            mutate([`${nameController5}/total`, { id: idModificacion }])
            // setMutateSaldoAccion(true)
        },
        Actions = async (data) => {
            const response = await PostRoute(`${nameController5}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data?.id })
            toast.success(response.message, OptionsToast)
            mutate([`${nameController5}/by-accion`, { id: idModificacion }])
            mutate([`${nameController5}/total`, { id: idModificacion }])
            // setMutateSaldoAccion(true)
        },
        labelTipoOperacion = [
            { value: 1, label: 'DÉBITO' },
            { value: 2, label: 'CRÉDITO' },
            { value: 3, label: 'CRÉDITO NUEVO RENGLÓN' }
        ],
        value = {
            Actions,
            One,
            actualiza,
            OneDetalle,
            StoreUpdate,
            setAccion,
            setOneData,
            setActualiza,
            setRenglon,
            allData,
            // idModificacion,
            // allInsumos,
            allData2,
            btnDisable,
            // labelUnidadMedida,
            // labelRenglon,
            modal,
            oneData,
            opcion,
            totalDetallePedido,
            labelTipoOperacion,
            setIdUnidad,
            labelAcciones,
            labelUnidades,
            labelAllRenglon,
            labelFinanciamiento,
            labelRenglonXAccion,
            idModificacion, setIdModificacion
        }

    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}
