import React, { Fragment, useState, useContext, useEffect } from 'react'
import DataTable, { ExpanderComponentProps } from 'react-data-table-component'
import { Row, Col, FormGroup, Input, UncontrolledButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu, Card } from 'reactstrap'
import * as Icon from 'react-feather'
import { CustomText, EstadoText, SiguientePasoText } from '../../../CustomList/index'
import { ContentContext } from './context'
import { useHistory } from 'react-router-dom'
import { ContentContext as ContentContextTheme } from '../../../components/CommonForBoth/TopbarDropdown/context'
import Moment from 'react-moment';
import 'moment/locale/es-mx';

const List = (props) => {
  const { theme } = useContext(ContentContextTheme)
  const history = useHistory();
  const [roles, setRoles] = useState([]);
  const [dataUser, setDataUser] = useState([]);
  const Blowfish = require('javascript-blowfish');
  const bf = new Blowfish(process.env.REACT_APP_CONFIG_KEY);
  const { allData, One, Actions, setIdPresupuestoPoa, setAccion, code, Imprimir, setPdf, BitacoraPedido, esFADN, solicitante, idFADN, onSubmitEstado, One2, setValidaEncuesta } = useContext(ContentContext)
  const [searchValue, setSearchValue] = useState(''),
    PedidosXUnidad = ((allData?.length > 0) && (
      allData?.value !== 2009 && allData?.value !== 7001)) &&
      allData?.filter(item => (
        esFADN === '1' && Number(idFADN) === item?.idFADN ||
        code === item?.codigoUnidad && item?.idSolicitantePersona === Number(solicitante)
      )),
    [filteredData, setFilteredData] = useState([]),
    handleFilter = e => {
      const value = e.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      setSearchValue(value);
      let updatedData = []


      if (value?.length) {
        const updatedData = PedidosXUnidad && PedidosXUnidad?.filter(item => {
          const correlativo = item?.correlativo?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          const descripcion = item?.descripcion?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          const fechaPedido = item?.fechaSolicitada2?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          const estado = item?.estado?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          const siguientePaso = item?.actividadGestion?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");



          return correlativo?.includes(value) || descripcion?.includes(value) || fechaPedido?.includes(value) || estado?.includes(value) || siguientePaso?.includes(value);
        });

        setFilteredData(updatedData)
        setSearchValue(value)
      }
      if (value === '') {
        setFilteredData([])
      }
    },
    MuestraPDF = async (data) => {
      const doc = await Imprimir(data)
      setPdf(doc)
    },
    Columns = [
      {
        name: 'Correlativo',
        column: 'correlativo',
        sortable: true,
        center: false,
        grow: 2,
        cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '13px' }}>{row?.correlativo}</div> // Esto asegura que el contenido no se trunca
      },
      {
        name: 'Asignado a la Gestión',
        column: 'fechaUso4',
        sortable: true,
        center: false,
        grow: 2, // Ajusta el valor según sea necesario para dar más espacio a la columna
        wrap: true,
        cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '11px' }}>{row?.personasAsignadas}</div> // Esto asegura que el contenido no se trunca
      },
      {
        name: 'Fecha de Solicitud',
        column: 'fechaSolicitada2',
        sortable: true,
        center: false,
        grow: 2,
        cell: row => CustomText(row['fechaSolicitada2'])
      },
      {
        name: 'Tipo de Gestión',
        column: 'actividadGestion',
        sortable: true,
        center: false,
        grow: 3, // Ajusta el valor según sea necesario para dar más espacio a la columna
        wrap: true,
        cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '11px' }}>{row?.actividadGestion}</div> // Esto asegura que el contenido no se trunca
      },
      // {
      //   name: 'Justificación',
      //   column: 'justificacion',
      //   sortable: true,
      //   grow: 7, // Ajusta el valor según sea necesario para dar más espacio a la columna
      //   wrap: true,
      //   cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.justificacion}</div> // Esto asegura que el contenido no se trunca

      // },
      // {
      //   name: 'Año',
      //   column: 'año',
      //   sortable: true,
      //   center: true,
      //   cell: row => CustomText(row['año'])
      // },
      {
        name: 'Estado',
        column: 'estado',
        sortable: true,
        center: true,
        grow: 2,
        // width: '20%',
        cell: row => <EstadoText row={row} />
      },
      // {
      //   name: 'Siguiente Paso',
      //   column: 'siguientePaso',
      //   sortable: true,
      //   center: true,
      //   grow: 7, // Ajusta el valor según sea necesario para dar más espacio a la columna
      //   wrap: true,
      //   width: '22%',
      //   cell: row => <SiguientePasoText row={row} />
      // },
      {
        name: 'Acciones',
        column: 'id',
        sortable: true,
        grow: 1,
        center: true,
        // width: '10%',
        cell: row => (
          <UncontrolledButtonDropdown direction='start'>

            <DropdownToggle className='pr-1' tag='span'>
              <Icon.MoreVertical size={15} />
            </DropdownToggle>
            <DropdownMenu className="!m-0"  >

              {(row.idEstado === 2 || row.idEstado === 1) &&
                <DropdownItem className="w-100" onClick={() => [One(row, 2), setAccion(row.idAccion)]}>
                  <Icon.Edit className="me-2" size={15} />
                  <span className='align-middle ml-50'>Ver Gestión</span>
                </DropdownItem>}

              {(row.idEstado === 14 || row.idEstado === 15 || row.idEstado === 24 || row.idEstado === 18 || row.idEstado === 19 || row.idEstado === 20 || row.idEstado === 25) &&
                <DropdownItem className="w-100" onClick={() => [One(row, 50), setAccion(row.idAccion)]}>
                  <Icon.FileText className="me-2" size={15} />
                  <span className='align-middle ml-50'>Visualizar Gestión</span>
                </DropdownItem>}
              {
                (row.idEstado === 2 || row.idEstado === 1) &&
                <DropdownItem className={roles.includes('F8E50') ? ` w-100` : `d-none w-100`} onClick={() => One(row, 20)}>
                  <Icon.Send className="me-2" size={15} />
                  <span className='align-middle ml-50'>Enviar Gestión</span>
                </DropdownItem>
              }
              {
                ((row.idEstado === 1)) ? null :
                  <DropdownItem className="w-100" onClick={() => [BitacoraPedido(row, 6), setIdPresupuestoPoa(row.id)]}>
                    <Icon.GitPullRequest className="me-2" size={15} />
                    <span className='align-middle ml-50'>Ver Proceso</span>
                  </DropdownItem>
              }
              {
                ((row.idEstado === 14 && row?.idActividadGestion === 1)) && //transportes
                <DropdownItem className="w-100" onClick={() => [One(row, 21)]}>
                  <Icon.Clipboard className="me-2" size={15} />
                  <span className='align-middle ml-50'>Responder Encuesta</span>
                </DropdownItem>
              }
              {
                ((row.idEstado === 14 && row?.idActividadGestion === 3)) && //Soporte Técnico
                <DropdownItem className="w-100" onClick={() => [One(row, 21)]}>
                  <Icon.Clipboard className="me-2" size={15} />
                  <span className='align-middle ml-50'>Responder Encuesta</span>
                </DropdownItem>
              }
              {
                ((row.idEstado === 20 && row?.idActividadGestion === 7)) && //Prestamo Equipo
                <DropdownItem className="w-100" onClick={() => [One(row, 21)]}>
                  <Icon.Clipboard className="me-2" size={15} />
                  <span className='align-middle ml-50'>Responder Encuesta</span>
                </DropdownItem>
              }
              {
                ((row.idEstado === 14 || row.idEstado === 12 || row.idEstado === 8 || row.idEstado === 17 || row.idEstado === 18 || row.idEstado === 19 || row.idEstado === 20 || row.idEstado === 11 || row.idEstado === 15 || row.idEstado === 5 || row.idEstado === 24) && row.idActividadGestion === 1) &&
                <DropdownItem className={`w-100`} onClick={() => [One(row, 5), MuestraPDF(row), onSubmitEstado(row?.id, row?.idEstado, row?.idActividadGestion)
                ]}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-file-earmark-pdf"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                    <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                  </svg>
                  <span className='align-middle ml-50'>&nbsp;&nbsp;Ver PDF</span>
                </DropdownItem>
              }
              {
                ((row.idEstado === 12 || row.idEstado === 8 || row.idEstado === 17 || row.idEstado === 18 || row.idEstado === 19 || row.idEstado === 20 || row.idEstado === 11 || row.idEstado === 15 || row.idEstado === 5 || row.idEstado === 25) && row.idActividadGestion === 2) &&
                <DropdownItem className={`w-100`} onClick={() => [One(row, 5), MuestraPDF(row), onSubmitEstado(row?.id, row?.idEstado, row?.idActividadGestion)
                ]}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-file-earmark-pdf"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                    <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                  </svg>
                  <span className='align-middle ml-50'>&nbsp;&nbsp;Ver PDF</span>
                </DropdownItem>
              }
              {
                ((row.idEstado === 12 || row.idEstado === 8 || row.idEstado === 17 || row.idEstado === 18 || row.idEstado === 19 || row.idEstado === 20 || row.idEstado === 11 || row.idEstado === 15) && row.idActividadGestion === 7) &&
                <DropdownItem className={`w-100`} onClick={() => [One(row, 5), MuestraPDF(row), onSubmitEstado(row?.id, row?.idEstado)
                ]}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-file-earmark-pdf"
                    viewBox="0 0 16 16"
                  >
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                    <path d="M4.603 14.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.697 19.697 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.712 5.712 0 0 1-.911-.95 11.651 11.651 0 0 0-1.997.406 11.307 11.307 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.266.266 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.71 12.71 0 0 1 1.01-.193 11.744 11.744 0 0 1-.51-.858 20.801 20.801 0 0 1-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.876 3.876 0 0 0-.612-.053zM8.078 7.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                  </svg>
                  <span className='align-middle ml-50'>&nbsp;&nbsp;Ver PDF</span>
                </DropdownItem>
              }

              {
                row?.idEstado !== 20 && row?.idEstado !== 25 && row?.idEstado !== 1 && row?.idEstado !== 11 && row?.idEstado !== 12 && row?.idEstado !== 15 && row?.idEstado !== 0 &&
                <DropdownItem className={` w-100`} onClick={() => One(row, 25)}>
                  <Icon.XOctagon className="me-2" size={15} />
                  <span className='align-middle ml-50'>Solicitar Anulación</span>
                </DropdownItem>
              }
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        )
      }
    ];
  // ExpandedComponent = ({ data }) => {
  //   return (

  //     <Card className='p-5'>
  //       <h1>Ultimo Proceso</h1>
  //       <p className="fs-5"><b>{'Descripción de Solicitud: '}</b>{data?.dataBitacora}</p>
  //       <span><b>{'Teléfono: '}</b>{data?.telefono ?? 'No registro teléfono'}</span>
  //       <br />
  //       <Moment locale="es" format='lll' fromNow>{data.creado}</Moment>
  //       <span><b>{'Tipo de Respuesta: '}</b>{data?.tipoRespuesta}</span>
  //     </Card>
  //   )
  // };
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const Decript = bf.decrypt(bf.base64Decode(localStorage.getItem('authUser')));
      const data = JSON.parse(Decript.replace(/\0/g, ''));
      // const obj = JSON.parse(localStorage.getItem("authUser"));
      // console.log(data)
      setDataUser(data);
      setRoles(data.roles);
    }
  }, [props.success]);


  useEffect(() => {
    if (PedidosXUnidad?.length > 0) {
      const esValido = PedidosXUnidad?.every(pedido => (pedido.idEstado !== 14 && pedido.idEstado !== 20));

      if (esValido) {
        setValidaEncuesta(0)
      } else {
        setValidaEncuesta(1)
      }
    }
  }, [PedidosXUnidad])


  return (
    <Fragment>
      <Row className='justify-content-end mx-0'>
        <Col>

        </Col>
        <Col className='justify-content-end mt-1' md='4' sm='12'>
          <FormGroup>
            <Input
              className='dataTable-filter mb-50'
              type='text'
              bsSize='md'
              placeholder={`Buscar`}
              id='search-input'
              value={searchValue}
              onChange={handleFilter}
            />
          </FormGroup>
        </Col>
      </Row>
      <div className='card'>
        <DataTable
          // dense
          striped
          noHeader
          highlightOnHover
          // expandableRows expandableRowsComponent={ExpandedComponent}
          pagination
          theme={theme}
          data={searchValue.length ? filteredData : (PedidosXUnidad && PedidosXUnidad.value !== 2009 ? PedidosXUnidad : [])}
          columns={Columns}
          className='table-responsive mt-4'
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          paginationPerPage={100}
          paginationComponentOptions={
            {
              rowsPerPageText: '',
              rangeSeparatorText: ''
            }
          }
          noDataComponent='Sin Registros'
        />
      </div>
    </Fragment>
  )
}

export default List