import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { ContentContext } from './context'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback } from 'reactstrap'
import { useForm, Controller } from "react-hook-form"
import * as Icon from 'react-feather'
import DatePicker from "react-datepicker";
import Select from 'react-select'
import { customStyles } from '../../../Globales/index'
import classnames from 'classnames'
import { Text } from "@nextui-org/react";
import { useHistory } from "react-router-dom"
import Dependencias from './Dependencias/index'
import Objetivos from './Objetivos/index'
import Acciones from './Acciones/index'
import Presupuesto from './Presupuesto/index'
import POA from './POA/index'
import SALDOS from './Saldos/index'
import Resumenes from './Resumenes/index'

const CardOne = () => {
    const history = useHistory();
    const [percentEjecucion, setPercentEjecucion] = useState(0);
    const [tab, setTab] = useState(1);
    const { allData, mutate, nameController, allData4 } = useContext(ContentContext),
        TabActive = (No) => {
            setTab(No)
        };

    const percentage_ejecucion = (n1, n2) => {
        // console.log(n1, n2)
        if (n1 !== null && n2 !== null && n1 !== undefined && n2 !== undefined) {
            // Actual
            const num1 = parseInt(n1);
            // Limite
            const num2 = parseInt(n2);
            const result = (num1 * 100) / num2;

            setPercentEjecucion(result.toFixed(2));
        }
    }

    useEffect(
        () => {
            const functionFetch = async () => {
                if (allData && allData[0] !== undefined) {
                    percentage_ejecucion(Number(allData[0]?.totalEjecutadoNumerico), Number(allData[0]?.presupuestoModificadoActualNumerico))
                }
            }
            functionFetch();
        }, [allData]
    )

    return (
        <Fragment>

            <div className="card mb-6 mb-xl-9 m-3 d-print-none">
                <div className="card-body pt-9 pb-0 ">
                    <div className="d-flex flex-wrap flex-sm-nowrap">
                        <div
                            className=" mx-2 text-center d-flex justify-content-center align-self-center" style={{ height: '20vh', width: '10vw' }}
                        >
                            <span className="avatar-title rounded-4 text-ligth fs-1 content-colorized">
                                {allData && allData[0] !== undefined && allData[0].iniciales}
                            </span>

                        </div>
                        <div className="flex-grow-1">
                            <div className="d-flex justify-content-between align-items-start flex-wrap ">
                                <div className="d-flex mx-3 flex-column">
                                    <div className="d-flex align-items-center mb-1">
                                        <a
                                            href="#"
                                            className="text-dark fs-4 fw-bold me-3"
                                        >
                                            {allData && allData[0] !== undefined && allData[0].unidad}
                                        </a>
                                        {allData && allData[0] !== undefined && allData[0].estado === 1 ?
                                            <span className="badge badge-light-success me-auto">
                                                Activo
                                            </span> :
                                            <span className="badge badge-light-danger me-auto">
                                                Inactivo
                                            </span>}
                                    </div>
                                    <div className="d-flex flex-wrap fw-semibold mb-4 fs-6 text-dark">
                                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                            <a
                                                href="#"
                                                className="d-flex align-items-center text-secondary bg-soft me-5 mb-2"
                                            >

                                                <span className="svg-icon svg-icon-4 me-1">
                                                    <Icon.Command size={16} />
                                                </span>
                                                {allData && allData[0] !== undefined && allData[0].codigo}
                                            </a>

                                        </div>

                                    </div>
                                </div>
                                <div className="d-flex align-items-center mt-3">
                                    <Button
                                        onClick={() => history.push(`/dashboard`)}
                                        color="warning"
                                        size="sm"
                                        id="regresar"
                                    >
                                        <Icon.ArrowLeft size={16} />
                                    </Button>

                                    <UncontrolledTooltip placement='left' target='regresar' placementPrefix="bs-tooltip">
                                        Regresar
                                    </UncontrolledTooltip>
                                </div>

                            </div>

                            <Row >
                                <Col sm={12} md={4} lg={3}>
                                    <div className="d-flex flex-wrap justify-content-start mx-3">
                                        <div className="d-flex flex-wrap">
                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-2">
                                                <div className="d-flex align-items-center">

                                                    <div
                                                        className="fs-4 fw-bold counted" >
                                                        {allData && allData[0] !== undefined && allData[0]?.totalPresupuestoInicial}
                                                    </div>
                                                </div>
                                                <div className="fs-6 text-gray-400">Ppto. Inicial</div>
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                                <Col sm={12} md={4} lg={3}>
                                    <div className="d-flex flex-wrap justify-content-start mx-3">
                                        <div className="d-flex flex-wrap">
                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-2">
                                                <div className="d-flex align-items-center">

                                                    <div
                                                        className="fs-4 fw-bold counted" >
                                                        {allData && allData[0] !== undefined && allData[0]?.presupuestoModificadoActual}
                                                    </div>
                                                </div>
                                                <div className="fs-10 text-gray-400">Ppto. Actual Modificado</div>
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                                <Col sm={12} md={4} lg={3}>
                                    <div className="d-flex flex-wrap justify-content-start ">
                                        <div className="d-flex flex-wrap">
                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-2">
                                                <div className="d-flex align-items-center">

                                                    <div
                                                        className="fs-4 fw-bold counted" >
                                                        {allData && allData[0] !== undefined && allData[0]?.saldoTotalFormato}
                                                        {/* {'Q.00'} */}
                                                    </div>
                                                </div>
                                                <div className="fs-6 text-gray-400">Ppto. Disponible </div>
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                                <Col sm={12} md={4} lg={3}>
                                    <div className="d-flex flex-wrap justify-content-start ">
                                        <div className="d-flex flex-wrap">
                                            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-2">
                                                <div className="d-flex align-items-center">

                                                    <div
                                                        className="fs-4 fw-bold counted" >
                                                        {allData && allData[0] !== undefined && allData[0]?.totalEjecutadoFormato}
                                                        {/* {'Q.00'} */}
                                                    </div>
                                                </div>
                                                <div className="fs-6 text-gray-400">Ppto. Ejecutado </div>
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                                <Col sm={12} md={4} lg={12}>
                                    <div className="d-flex align-items-center flex-column m-3">
                                        <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                            <span className="fw-semibold fs-9 text-secondary">Ppto. Ejecutado</span>
                                            <span className="fw-bold fs-7">{percentEjecucion}%</span>
                                        </div>
                                        <div className="h-5px mx-3 w-100 bg-light mb-3 progress-bar">
                                            <div className="progress-md progress">
                                                <div
                                                    className="progress-bar bg-success"
                                                    role="progressbar"
                                                    style={{ width: `${percentEjecucion}%` }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>


                    </div>
                    {/* end::Header */}
                    <div className="separator" />
                    <ul className="nav nav-tabs nav-tabs-custom nav-line-tabs-2x mb-1 fs-5 border-transparent fw-bolder" role="tablist">
                        <li className="nav-item" role="presentation" onClick={() => TabActive(1)}>
                            <a
                                className="nav-link active"
                                data-bs-toggle="tab"
                                href="#presupuesto"
                                role="tab"
                                aria-selected="false"
                            >
                                <span className="d-block d-sm-none">
                                    <i className="fas fa-cog" />
                                </span>
                                <span className="d-none d-sm-block">Presupuesto</span>
                            </a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#objetivos"
                                role="tab"
                                aria-selected="false"
                                tabIndex={-1}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="far fa-envelope" />
                                </span>
                                <span className="d-none d-sm-block">Objetivos</span>
                            </a>
                        </li>
                        <li className="nav-item" role="presentation" >
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#acciones"
                                role="tab"
                                aria-selected="true"
                                tabIndex={-1}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="fas fa-home" />
                                </span>
                                <span className="d-none d-sm-block">Acciones</span>
                            </a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#poa"
                                role="tab"
                                aria-selected="false"
                                tabIndex={-1}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="far fa-user" />
                                </span>
                                <span className="d-none d-sm-block">POA</span>
                            </a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#dependencias"
                                role="tab"
                                aria-selected="false"
                                tabIndex={-1}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="far fa-envelope" />
                                </span>
                                <span className="d-none d-sm-block">Dependencias</span>
                            </a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#saldos"
                                role="tab"
                                aria-selected="false"
                                tabIndex={-1}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="far fa-envelope" />
                                </span>
                                <span className="d-none d-sm-block">Saldos</span>
                            </a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#resumenes"
                                role="tab"
                                aria-selected="false"
                                tabIndex={-1}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="far fa-envelope" />
                                </span>
                                <span className="d-none d-sm-block">Resumenes</span>
                            </a>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#pac"
                                role="tab"
                                aria-selected="false"
                                tabIndex={-1}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="far fa-envelope" />
                                </span>
                                <span className="d-none d-sm-block">PAC</span>
                            </a>
                        </li> */}
                        {/* <li className="nav-item" role="presentation">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#reportes"
                                role="tab"
                                aria-selected="false"
                            >
                                <span className="d-block d-sm-none">
                                    <i className="fas fa-cog" />
                                </span>
                                <span className="d-none d-sm-block">Reportes</span>
                            </a>
                        </li> */}
                    </ul>
                </div>
            </div>
            <div className="tab-content p-3 text-muted">
                <div className="tab-pane active show" id="presupuesto" role="tabpanel">
                    <div className="card p-3">
                        <Presupuesto />
                    </div>
                </div>
                <div className="tab-pane" id="acciones" role="tabpanel">
                    <div className="card p-3">
                        <Acciones />
                    </div>
                </div>
                <div className="tab-pane " id="poa" role="tabpanel">
                    <p className="mb-0">
                        <POA />
                    </p>
                </div>
                <div className="tab-pane " id="objetivos" role="tabpanel">
                    <Objetivos />
                </div>
                <div className="tab-pane" id="dependencias" role="tabpanel">
                    <div className="card p-3">
                        <Dependencias />
                    </div>
                </div>
                <div className="tab-pane" id="saldos" role="tabpanel">
                    <div className="card p-3">
                        <SALDOS />
                    </div>
                </div>
                <div className="tab-pane" id="resumenes" role="tabpanel">
                    <div className="card p-3">
                        <Resumenes />
                    </div>
                </div>
                {/* <div className="tab-pane" id="pac" role="tabpanel">
                    <div className="card p-3">
                        PAC
                    </div>
                </div> */}
                <div className="tab-pane " id="reportes" role="tabpanel">
                    <p className="mb-0">
                        Reportes
                    </p>
                </div>
            </div>
        </Fragment>
    )
}
export default CardOne