import React, { createContext, useState, useEffect } from 'react'
import { useRequest } from '../../../hooks/useRequestSWR'
import { PostRoute } from '../../../services/Private'
import { useSWRConfig } from 'swr'
import { useDataUser } from 'hooks/useDataUser'

export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
  // Modal Init
  const { mutate } = useSWRConfig();
  const roles = useDataUser()?.dataUser?.roles;
  const code = useDataUser()?.dataUser?.codigoUnidad;
  const solicitante = useDataUser()?.dataUser?.i;
  const nombreSolicitante = useDataUser()?.dataUser?.nombreCompleto;
  const puestoUserLogged = useDataUser()?.dataUser?.puesto;
  const idFADN = useDataUser()?.dataUser?.idFADN;
  const esFADN = useDataUser()?.dataUser?.esFADN;
  // const [dataUser, setDataUser] = useState([]);
  // const [roles, setRoles] = useState([]);
  const [theme, setTheme] = useState('solarized');
  const [theme2, setTheme2] = useState('light');
  const nameController = 'PlanOperativoAnual';
  const nameController2 = 'Theme';
  const nameController3 = 'Notificaciones';
  const nameController4 = 'Gestiones';
  const nameController5 = 'Pedido';
  const nameController6 = 'Modificaciones';
  const { data: allData } = useRequest(`${nameController}/new-poa`, 'GET');
  const { data: allDataTheme } = useRequest(`${nameController2}/all`, 'POST', {});
  const { data: notificaciones } = useRequest(`${nameController3}/all`, 'GET');
  const { data: notificacionesSolInsumoUser } = useRequest(`${nameController3}/All-usuario`, 'POST', {});
  const { data: allDataGestiones } = useRequest(`${nameController4}/all`, 'GETSWR');
  const { data: allDataPedidos } = useRequest(`${nameController5}/all`, 'GET2MIN');
  const { data: allDataModificaciones } = useRequest(`${nameController6}/all`, 'GET2MIN'),
    AllDataFilter = ((allData?.length > 0) && (
      allData?.value !== 2009 && allData?.value !== 7001)) &&
      allData?.filter(item => (
        roles?.includes('6B1D5')
      )),
    NotificacionesFilter = ((notificaciones?.length > 0) && (
      notificaciones?.value !== 2009 && notificaciones?.value !== 7001)) &&
      notificaciones?.filter(item => (
        roles?.includes('74AFF')
      )),
    notificacionesSolInsumoUserFilter = ((notificacionesSolInsumoUser?.length > 0) && (
      notificacionesSolInsumoUser?.value !== 2009 && notificacionesSolInsumoUser?.value !== 7001)) &&
      notificacionesSolInsumoUser?.filter(item => (
        roles?.includes('30D7E')
      )),
    GestionesSolicitantesXUnidad = ((allDataGestiones?.length > 0) && (
      allDataGestiones?.value !== 2009 && allDataGestiones?.value !== 7001)) &&
      allDataGestiones?.filter(item => (
        ((esFADN === '1')) && (Number(idFADN) === item?.idFADN) && (item?.idEstado === 11 ||
          item?.idEstado === 2) ||
        (code === item?.codigoUnidad) && (item?.idSolicitantePersona === Number(solicitante)) && (item?.idEstado === 11 || item?.idEstado === 2 || item?.idEstado === 14 || item?.idEstado === 20 || item?.idEstado === 17 || item.idEstado === 5)
      )),
    PedidosSolicitantes = ((allDataPedidos?.length > 0) && (
      allDataPedidos?.value !== 2009 && allDataPedidos?.value !== 7001)) &&
      allDataPedidos?.filter(item => (
        (code === item?.codigoUnidad)
        && (item?.idSolicitante === Number(solicitante)) //Para validar por persona las notifiaciones devolver idSolicitante
        && (item?.idEstado === 2 || item?.idEstado === 21 || item?.idEstado === 65)
      )),

    ModificacionesSolicitantes = ((allDataModificaciones?.length > 0) && (
      allDataModificaciones?.value !== 2009 && allDataModificaciones?.value !== 7001)) &&
      allDataModificaciones?.filter(item => (
        (
          code === item?.codigoUnidad)
        && (item?.idSolicitante === Number(solicitante)) //Para validar por persona las notifiaciones devolver idSolicitante
        && (item?.idEstado === 4 || item?.idEstado === 1 || item?.idEstado === 0)
      )),

    ModificacionesXAprobador = ((allDataModificaciones?.length > 0) && (
      allDataModificaciones?.value !== 2009 && allDataModificaciones?.value !== 7001)) &&
      allDataModificaciones?.filter(item => (
        item?.idEstado === 3 && roles?.includes('F870C') ||
        item?.idEstado === 4 && roles?.includes('F870C') ||
        // item?.idEstado === 5 && roles?.includes('F870C') ||
        item?.idEstado === 2 && roles?.includes('47E0A')
      )),

    PedidosXAprobador = ((allDataPedidos?.length > 0) && (
      allDataPedidos?.value !== 2009 && allDataPedidos?.value !== 7001)) &&
      allDataPedidos?.filter(item => (
        item?.idEstado === 37 && roles?.includes('046C2') ||
        (((item?.idEstado === 3 || item?.idEstado === 68) && roles?.includes('7A25E') && code === item?.codigoUnidad) || item?.idEstado === 3 && roles?.includes('4F148') && item?.jefeDireccion === nombreSolicitante) ||
        item?.idEstado === 20 && roles?.includes('7A25E') && code === item?.codigoUnidad ||
        (((item?.idEstado === 22) || (item?.idEstado === 23) || (item?.idEstado === 45) || (item?.idEstado === 46) || (item?.idEstado === 58)) && roles?.includes('0A0CC') && item?.modalidad !== 3 &&
          (item?.modalidad !== 2 || item?.esAplicableACompras === 'SI')) || // todos los pedidos de compras
        item?.idEstado === 23 && roles?.includes('7B49E') && item?.analistaCompras === nombreSolicitante ||
        item?.idEstado === 39 && (roles?.includes('0A0CC')) ||
        item?.idEstado === 39 && (roles?.includes('7B49E')) && item?.analistaCompras === nombreSolicitante ||
        item?.idEstado === 58 && roles?.includes('88399') && item?.modalidad === 3 || //Vales Caja Chica
        item?.idEstado === 57 && (roles?.includes('F870C')) || //Solicitud de anulacion
        //--------------------FINANCIERO---------------------
        item?.idEstado === 42 && (roles?.includes('BAB13')) || // RECIBIDO FINANCIERO COM - Operador Autorización Cur Ped
        item?.idEstado === 41 && (roles?.includes('BAB13')) || // ORDEN INGRESADA - Operador Ingreso Cur Ped
        item?.idEstado === 50 && (roles?.includes('BAB13')) || // RECIBIDO FINANCIERO COM - Operador Autorización Cur Ped
        // item?.idEstado === 42 && (roles?.includes('BAB13')) || // RECIBIDO FINANCIERO COM - Operador Ingreso Cur Ped
        item?.idEstado === 43 && (roles?.includes('51657')) || // COMPROMISO APROBADO - Operador Ingreso Cur Ped
        item?.idEstado === 44 && (roles?.includes('7B49E')) && item?.analistaCompras === nombreSolicitante || // CUR COMPROMISO INGRESADO - Operador Ingreso Cur Ped

        ((item?.idEstado === 48 && roles?.includes('4966A')) || (item?.idEstado === 58 && (item?.modalidad === 2 || item?.esAplicableACompras !== 'SI' && item?.modalidad !== 1)) || (item?.idEstado === 49)) && roles?.includes('4966A') && item?.modalidad !== 3 || (item?.idEstado === 48 && item?.modalidad === 1 && roles?.includes('4966A')) || // LIQUIDACIÓN FINALIZADA y DOCUMENTO IMPRESO - Todos las solicitudes de financiero
        item?.idEstado === 60 && (roles?.includes('32795')) || // RECIBIDO FINANCIERO DEV (PDD) - Operador Visa Previa Ped
        // item?.idEstado === 49 && (roles?.includes('4966A')) || // RECIBIDO FINANCIERO DEV (GST) - Operador Visa Previa Ped
        // item?.idEstado === 50 && (roles?.includes('4966A')) || // RECEPCIÓN DOCTOS DE RESPALDO - Operador Visa Previa Ped GST

        // item?.idEstado === 48 && (roles?.includes('F5C2')) || // LIQUIDACIÓN FINALIZADA - Operador Genera Devengado Ped PDD
        // item?.idEstado === 50 && (roles?.includes('F5C2')) || // RECEPCIÓN DOCTOS DE RESPALDO - Operador Genera Devengado Ped GST
        // item?.idEstado === 51 && (roles?.includes('F5C2')) || // DEVENGADO INGRESADO - Operador Genera Devengado Ped PDD
        // item?.idEstado === 51 && (roles?.includes('F5C2')) || // DEVENGADO INGRESADO - Operador Genera Devengado Ped GST

        item?.idEstado === 51 && (roles?.includes('32795')) || // DEVENGADO INGRESADO - Operador Aprobación Devengado Ped PDD
        // item?.idEstado === 50 && (roles?.includes('32795')) || // RECEPCIÓN DOCTOS DE RESPALDO - Operador Aprobación Devengado Ped GST
        // item?.idEstado === 52 && (roles?.includes('32795')) || // DEVENGADO APROBADO - Operador Aprobación Devengado Ped PDD
        // item?.idEstado === 52 && (roles?.includes('32795')) || // DEVENGADO APROBADO - Operador Aprobación Devengado Ped GST

        (item?.idEstado === 52 || item?.idEstado === 69) && (roles?.includes('59641')) || // DEVENGADO APROBADO - Operador Imprimir Cheque Ped
        (item?.modalidad === 2 && item?.idEstadoLiquidado === 71) && (roles?.includes('59641')) || // TESORERIA - OPERAR LIQUIDACIONES GASTOS
        (item?.modalidad === 2 && item?.idEstadoLiquidado === 72) && (roles?.includes('59641')) || // TESORERIA - OPERAR LIQUIDACIONES GASTOS
        (item?.modalidad === 2 && item?.idEstado === 75) && (roles?.includes('32795')) || // DEV - OPERAR LIQUIDACIONES GASTOS
        (item?.modalidad === 2 && item?.idEstado === 73) && (roles?.includes('BAB13')) || // COM - OPERAR LIQUIDACIONES GASTOS
        (item?.modalidad === 2 && item?.idEstado === 74) && (roles?.includes('59641')) || // TESORERIA ENVIA ARCHIVO - OPERAR LIQUIDACIONES GASTOS
        // item?.idEstado === 53 && (roles?.includes('59641')) || // IMPRESIÓN DE CHEQUE - Operador Imprimir Cheque Ped
        item?.idEstado === 62 && (roles?.includes('59641')) || // CHEQUE FIRMADO GG - Operador Imprimir Cheque Ped
        // item?.idEstado === 63 && (roles?.includes('59641')) || // CHEQUE RECIBIDO TESORERÍA - Operador Imprimir Cheque Ped

        item?.idEstado === 53 && (roles?.includes('44E7A')) || // IMPRESIÓN DE CHEQUE - Operador Firmar Cheque Ped
        // item?.idEstado === 54 && (roles?.includes('D1B49')) || // CHEQUE FIRMADO SORF - Operador Firmar Cheque Ped

        item?.idEstado === 54 && (roles?.includes('44E7A')) || // CHEQUE FIRMADO SORF - Cheques GG Pedidos
        item?.idEstado === 61 && (roles?.includes('44E7A')) || // RECEPCIÓN CHEQUE GG - Cheques GG Pedidos
        item?.idEstado === 61 && (roles?.includes('44E7A')) || // RECEPCIÓN CHEQUE GG - Cheques GG Pedidos
        // item?.idEstado === 62 && (roles?.includes('44E7A')) || // CHEQUE FIRMADO GG - Cheques GG Pedidos

        item?.idEstado === 63 && (roles?.includes('3A353')) || // CHEQUE RECIBIDO TESORERÍA - Operador Entregar Cheque Ped
        // item?.idEstado === 55 && (roles?.includes('3A353')) || // CHEQUE ENTREGADO - Operador Entregar Cheque Ped

        (item?.idEstado === 55 || item?.idEstado === 70) && (roles?.includes('59641')) || // CHEQUE ENTREGADO - Operador Archivo Pedidos
        item?.idEstado === 67 && (roles?.includes('3A61E')) || // RECEPCION DE ARCHIVO
        item?.idEstado === 56 && (roles?.includes('3A61E')) || // RECIBIDO EN ARCHIVO - Operador Archivo Pedidos


        //--------------------FINANCIERO---------------------
        // item?.idEstado === 44 && (roles?.includes('0A0CC')) && item?.modalidad !== 3 &&
        // (item?.modalidad !== 2 || item?.esAplicableACompras === 'SI') || //Recibe expediente compras 
        (item?.idEstado === 45 || item?.idEstado === 46 || item?.idEstado === 47 || item?.idEstado === 65) && (roles?.includes('7B49E')) && item?.modalidad !== 3 &&
        (item?.modalidad !== 2 || item?.esAplicableACompras === 'SI') && item?.analistaCompras === nombreSolicitante || //Inicia Liquidacion Compras 

        item?.idEstado === 4 && roles?.includes('F870C') ||
        item?.idEstado === 5 && roles?.includes('47E0A')
      )),

    GestionesXAprobador = ((allDataGestiones?.length > 0) && (
      allDataGestiones?.value !== 2009 && allDataGestiones?.value !== 7001)) &&
      allDataGestiones?.filter(item => (
        //Estos son solo de transportes 1
      //Transporte
      item?.idEstado === 23 && item?.idActividadGestion === 1 && roles?.includes('48372') || //anulacion
      item?.idEstado === 10 && item?.idActividadGestion === 1 && roles?.includes('48372') ||
      item?.idEstado === 3 && item?.idActividadGestion === 1 && roles?.includes('C7F42') ||
      item?.idEstado === 4 && item?.idActividadGestion === 1 && roles?.includes('34E6E') ||
      item?.idEstado === 7 && item?.idActividadGestion === 1 && roles?.includes('34E6E') ||
      item?.idEstado === 8 && item?.idActividadGestion === 1 && roles?.includes('34E6E') ||
      item?.idEstado === 24 && item?.idActividadGestion === 1 && roles?.includes('48372') ||
      //Vehiculos
      item?.idEstado === 23 && item?.idActividadGestion === 2 && roles?.includes('48372') || //anulacion
      item?.idEstado === 10 && item?.idActividadGestion === 2 && roles?.includes('48372') ||
      item?.idEstado === 3 && item?.idActividadGestion === 2 && roles?.includes('C7F42') ||
      item?.idEstado === 4 && item?.idActividadGestion === 2 && roles?.includes('34E6E') ||
      item?.idEstado === 7 && item?.idActividadGestion === 2 && roles?.includes('34E6E') ||
      // item?.idEstado === 5 && item?.idActividadGestion === 2 && roles?.includes('48372') ||
      item?.idEstado === 8 && item?.idActividadGestion === 2 && roles?.includes('34E6E') || // Presta y recibe vehiculos
      item?.idEstado === 18 && item?.idActividadGestion === 2 && roles?.includes('52B6E') || // Presta y recibe vehiculos
      item?.idEstado === 19 && item?.idActividadGestion === 2 && roles?.includes('52B6E') || // Presta y recibe vehiculos
      //Soporte Tecnico
      item?.idEstado === 23 && item?.idActividadGestion === 3 && roles?.includes('49366') || //anulacion
      item?.idEstado === 10 && item?.idActividadGestion === 3 && roles?.includes('49366') || // DIRECTOR INFORMATICA
      item?.idEstado === 8 && item?.idActividadGestion === 3 && roles?.includes('CA85D')
      && (item?.personasAsignadas?.includes(nombreSolicitante)) ||//TECNICO SOPORTE TECNICO
      //Prestamo de Equipos
      item?.idEstado === 23 && item?.idActividadGestion === 7 && roles?.includes('49366') || //anulacion
      item?.idEstado === 10 && item?.idActividadGestion === 7 && roles?.includes('49366') || // DIRECTOR INFORMATICA
      item?.idEstado === 8 && item?.idActividadGestion === 7 && roles?.includes('CA85D')
      && (item?.personasAsignadas?.includes(nombreSolicitante)) || //TECNICO PRESTAMO DE EQUIPOS
      item?.idEstado === 18 && item?.idActividadGestion === 7 && roles?.includes('CA85D')
      && (item?.personasAsignadas?.includes(nombreSolicitante)) || //TECNICO PRESTAMO DE EQUIPOS
      item?.idEstado === 19 && item?.idActividadGestion === 7 && roles?.includes('CA85D')
      && (item?.personasAsignadas?.includes(nombreSolicitante)) //TECNICO PRESTAMO DE EQUIPOS


      )),
    Actions = async (data, opcion) => {
      // console.log('data: ' + data, 'opcion: ' + opcion)
      // const response =
      await PostRoute(`${nameController2}/${'store'}`, { nombre: opcion === 'light' ? 'light' : 'dark', estado: opcion === 'light' ? 1 : 0 })
      // toast.success(response.message, OptionsToast)
      mutate([`${nameController2}/all`, {}])
    },
    NotificationActions = async (data) => {
      await PostRoute(`${nameController3}/status`, {
        id: data.id,
        estado: 0,
      })
      mutate(`${nameController}/all`)
      mutate([`${nameController}/All-usuario`, {}])
    },
    value = {
      allData,
      AllDataFilter,
      GestionesSolicitantesXUnidad,
      GestionesXAprobador,
      PedidosSolicitantes,
      PedidosXAprobador,
      ModificacionesSolicitantes,
      ModificacionesXAprobador,
      notificaciones,
      NotificacionesFilter,
      notificacionesSolInsumoUser,
      notificacionesSolInsumoUserFilter,
      allDataTheme,
      theme,
      theme2,
      Actions,
      NotificationActions
    }
  // Modal End

  useEffect(() => {
    // Si allDataTheme es válido y el estado es 1, cambiar a 'dark', de lo contrario, mantener 'solarized'
    if (allDataTheme && allDataTheme[0]?.estado === 0) {
      console.log(allDataTheme)
      setTheme('dark');
    } else {
      setTheme('solarized');
    }
  }, [allDataTheme]); // Dependencia: allDataTheme
  useEffect(() => {
    // Si allDataTheme es válido y el estado es 1, cambiar a 'dark', de lo contrario, mantener 'solarized'
    if (allDataTheme && allDataTheme[0]?.estado === 0) {
      console.log(allDataTheme)
      setTheme2('dark');
    } else {
      setTheme2('light');
    }
  }, [allDataTheme]); // Dependencia: allDataTheme


  // console.log(GestionesSolicitantesXUnidad, 'Solicitantes')
  return (
    <ContentContext.Provider value={value}>
      {children}
    </ContentContext.Provider>
  )
}
