import React, { createContext, useState } from 'react'
// import { PostRoute } from '../../../services/Private'
// import { OptionsToast } from '../../../Globales/index'
// import { toast } from 'react-toastify'
// import { useRequest } from '../../../hooks/useRequestSWR'
// import { useSWRConfig } from 'swr'
// import { useRouteCode } from 'hooks/useRouteCode'
export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    // Modal Init
    // const nameController2 = 'DetalleAcciones';
    // const { mutate } = useSWRConfig();
    // const { data: allData4 } = useRequest(`${nameController2}/totalPoa`, 'POST', { code: useRouteCode()?.codigo });
    // const [codigo, setCodigo] = useState(0);
    // const nameController = 'Unidad';
    // const nameController2 = 'Unidad';
    // const nameController3 = 'PresupuestoDependencia';
    // const { data: allData } = useRequest(`${nameController}/all`, 'GET');
    // const { data: labelUnidades } = useRequest(`${nameController2}/label`, 'GET');
    // const { data: allData } = useRequest(`${nameController}/One-code`, 'POST', { code: useRouteCode()?.codigo }),
    const
        [modal, setModal] = useState(null),
        //     // [allData, setAllData] = useState([]),
        //     [oneData, setOneData] = useState([]),
        //     [opcion, setOpcion] = useState(0),
        toggleModal = async (data) => {

            // setOpcion(data)
            // if (data === 1) { setOneData(null) }
            setModal(!modal)

        },
        //     // All = async () => {
        //     //     const response = await GetRoute(`${nameController}/all`)
        //     //     setAllData((response.length) ? response : [])
        //     // },
        //     One = async (data, opcion) => {
        //         
        //         const response = await PostRoute(`${nameController}/one`, { id: data.id })
        //         setOneData((response[0]) ? response[0] : [])
        //         toggleModal(opcion)
        //         
        //     },
        //     StoreUpdate = async (data) => {
        //         
        //         let response = []
        //         response = await PostRoute(`${nameController}/${!data.id ? 'store' : 'update'}`, data)
        //         toast.success(response.message, OptionsToast)
        //         toggleModal(0)
        //         mutate(`${nameController}/all`)
        //         
        //     },
        //     updatePOA = async (data) => {
        //         
        //         let response = []
        //         response = await PostRoute(`${nameController}/updatePOA`, data)
        //         toast.success(response.message, OptionsToast)
        //         toggleModal(0)
        //         // mutate(`${nameController}/all`)
        //         // mutate(`${nameController}/new-poa`)
        //         
        //     },
        //     Actions = async (data) => {
        //         
        //         const response = await PostRoute(`${nameController}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data.id })
        //         toast.success(response.message, OptionsToast)
        //         mutate(`${nameController}/all`)
        //         
        //     },
        value = {
            // One,
            // Actions,
            // StoreUpdate,
            // mutate,
            // setCodigo,
            toggleModal,
            // updatePOA,
            // allData,
            // allData4,
            // nameController
            modal
            // oneData,
            // opcion
        }

    // Modal End

    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}
