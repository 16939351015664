
// import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { ContentContext } from './context'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback, Card, CardBody, CardTitle, CardSubtitle, CardText, Badge } from 'reactstrap'
import { useForm, Controller } from "react-hook-form"
import * as Icon from 'react-feather'
import Select from 'react-select'
import { formatDate, customStyles, stringToDate } from '../../../Globales/index'
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput'
import classnames from 'classnames'
import { Text } from "@nextui-org/react";
// import Objetivos from '../ObjetivosEstrategicos/index'
// import { ContentProvider } from '../ObjetivosEstrategicos/context'
import revision from '../../../assets/images/Checklist.png'
import DatePicker, { registerLocale } from "react-datepicker"
import es from "date-fns/locale/es"
registerLocale("es", es); // register it with the name you want


const Header = () => {
    // const [startDate, setStartDate] = useState(Date.parse(2019) + 1);
    const [startDate, setStartDate] = useState(new Date());
    const [startDateUso, setStartDateUso] = useState(new Date());
    const [validador, setValidador] = useState(false);
    const { toggleModal, modal, StoreUpdate, opcion, oneData, labelAcciones, setId, labelFinanciamiento, idPresupuestoPoa, labelTipoGasto, labelTipoPedido, labelFand, useDataUser, nameComponent, labelJefeDireccion, solicitante, setAgregarRenglon, setIdModalidad, idModalidad, labelActividades, idFADN, esFADN } = useContext(ContentContext),
        [updateData, setUpdateData] = useState(false),
        { handleSubmit, formState: { errors }, setValue, control, reset, watch } = useForm(),
        seleccionableTipoGasto = watch('tipogasto'),
        seleccionableLabelActividades = watch('accion'),
        onSubmit = (data) => {
            const json = {
                id: oneData?.id ?? idPresupuestoPoa ?? null,
                tipoSolicitante: esFADN === '1' ? 2 : 1, // persona 1 FADN 2
                fechaSolicitada: formatDate(startDate),
                fechaUso: formatDate(startDateUso),
                solicitantePersona: esFADN === '1' ? null : solicitante,
                solicitanteFADN: esFADN === '1' ? solicitante : null,
                descripcion: data.justificacion,
                actividadGestion: idModalidad
            }
            StoreUpdate(json, (oneData) ? oneData.id : null)
            // setUpdateData(true)
        },
        setData = async () => {
            await setIdModalidad(oneData.modalidad)
            await setValue('justificacion', oneData.descripcion)
            setStartDate(new Date(oneData.fechaSolicitada))
            setStartDateUso(new Date(oneData.fechaUso))
            await setValue('accion', { label: oneData.actividadGestion, value: oneData.idActividadGestion })

        },
        clearData = async () => {
            reset({ monto: '', unidad: '', financiamiento: '' })
        },
        setEstado = (estado) => {
            setUpdateData(estado)
        },
        habilitarEdicion = (e, estado) => {
            e.preventDefault()
            setEstado(estado)
        }

    useEffect(
        () => {
            if (oneData) {
                setUpdateData(true)
            }
        }, [oneData]
    )
    useEffect(
        () => {
            async function fetchMyAPI() {
                if (await opcion > 1 && oneData) { await setData() }
                if (await opcion === 1) { clearData(), setUpdateData(false) }
            }

            fetchMyAPI()

        }, [opcion, oneData]
    )

    useEffect(() => {
        let abortController = new AbortController();
        async function fetchMyAPI() {
            if (seleccionableTipoGasto) {
                if (seleccionableTipoGasto.value === 2) {

                    setValidador(true)
                } else {
                    setValidador(false)
                }
            } if (seleccionableTipoGasto === null || seleccionableTipoGasto === '') {
                setValidador(false)
            }
        }
        fetchMyAPI()
        return () => { abortController.abort(); }
    }, [seleccionableTipoGasto])

    useEffect(() => {
        let abortController = new AbortController();
        async function fetchMyAPI() {
            if (seleccionableLabelActividades) {
                if (seleccionableLabelActividades?.value !== 0) {
                    setIdModalidad(seleccionableLabelActividades?.value)
                } else {
                    setIdModalidad(0)
                }
            } if (seleccionableLabelActividades === null || seleccionableLabelActividades === '') {
                setIdModalidad(0)
            }
        }
        fetchMyAPI()
        return () => { abortController.abort(); }
    }, [seleccionableLabelActividades])

    return (
        <Fragment>
            <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

                <div className={opcion === 1 || !updateData ? ` p-3` : `card p-3`}>
                    <Row>
                        {updateData ?
                            <>
                                <Col>
                                    <Label >Correlativo</Label><br></br><span >{oneData?.correlativo}</span>
                                </Col> </> : null
                        }
                        <Col>
                            {updateData ? <><Label >Tipo de Gestión</Label><br></br><span >{oneData?.actividadGestion}</span></>
                                :
                                <FormGroup >
                                    <Label >* Tipo de Gestión</Label>
                                    <Controller
                                        name="accion"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                    isDisabled={opcion > 1}
                                                    options={labelActividades}
                                                    className='react-select'
                                                    classNamePrefix='select'
                                                    placeholder={'Seleccione una opción'}
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={(!!errors.accion) ? customStyles : {}}

                                                />
                                            )
                                        }
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <small className="text-danger">{!!errors.accion && errors.accion.message}</small>
                                </FormGroup>}
                        </Col>
                        <Col md={3}>
                            {updateData ? <><Label >Fecha Solicitud</Label><br></br><span>{oneData?.fechaSolicitada4}</span></>
                                :
                                null
                            }
                        </Col>
                        <Col md={3} className='d-none'>
                            {updateData ? <><Label >Fecha Inicio</Label><br></br><span>{oneData?.fechaUso4}</span></>
                                :

                                <FormGroup>
                                    <Label for="fechaUso">* Fecha Inicio</Label>
                                    <Controller
                                        name='fechaUso'
                                        control={control}
                                        render={() => {
                                            return (
                                                < DatePicker
                                                    className={classnames('form-control invoice-edit-input due-date-picker ', {
                                                        'is-invalid': errors.fechaUso && true
                                                    })}
                                                    selected={startDateUso}
                                                    // disabled={true}
                                                    locale="es"
                                                    onChange={(fechaUso) => setStartDateUso(fechaUso)}
                                                    // showYearPicker
                                                    name='fechaUso'
                                                    id="fechaUso"
                                                // dateFormat="yyyy"
                                                />
                                            )
                                        }
                                        }
                                    // rules={
                                    //     {
                                    //         required: {
                                    //             value: true,
                                    //             message: 'Este campo es requerido'
                                    //         }
                                    //     }
                                    // }
                                    />
                                    <FormFeedback>
                                        {!!errors.fechaUso && errors.fechaUso.message}
                                    </FormFeedback>
                                </FormGroup>}
                        </Col>
                        {updateData ?
                            <>
                                <Col>
                                    <Label >Para</Label><br></br><span >{oneData?.tipoSolicitante === 1 ? 'COG' : 'FADN'}</span>
                                </Col> </> : null
                        }


                    </Row>
                    {/* <Row>
                        <Col>
                            {updateData ? null
                                : <FormGroup >
                                    <Label >* Tipo de Solicitante</Label>
                                    <Controller
                                        name="tipogasto"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                    isDisabled={updateData}
                                                    options={labelTipoGasto}
                                                    className='selector-react1'
                                                    classNamePrefix='select'
                                                    placeholder={'Seleccione una opción'}
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={(!!errors.tipogasto) ? customStyles : {}}

                                                />
                                            )
                                        }
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <small className="text-danger">{!!errors.tipogasto && errors.tipogasto.message}</small>
                                </FormGroup>}
                        </Col>
                        {validador === true &&
                            <Col>
                                <FormGroup >
                                    <Label >* FADN</Label>
                                    <Controller
                                        name="fadn"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                    isDisabled={updateData}
                                                    options={labelFand}
                                                    className='selector-react1'
                                                    classNamePrefix='select'
                                                    placeholder={'Seleccione una opción'}
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={(!!errors.fadn) ? customStyles : {}}

                                                />
                                            )
                                        }
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <small className="text-danger">{!!errors.fadn && errors.fadn.message}</small>
                                </FormGroup>
                            </Col>
                        }
                    </Row> */}
                </div>
                <div className={opcion === 1 || !updateData ? ` p-3` : `card p-3`}>
                    <Row>
                        {updateData ? <Row >
                            <Col className="py-2" lg={12} xl={12}>
                                <Label>Descripción de solicitud</Label><br></br>
                                <span style={{ fontSize: '12px' }}>{oneData?.descripcion}</span>
                            </Col>
                        </Row> : null}
                        {updateData ? null
                            :
                            <Col>
                                <FormGroup>
                                    <Label for='justificacion' className="d-flex justify-content-between">* Descripción de solicitud</Label>
                                    <Controller

                                        control={control}
                                        id='justificacion'
                                        name='justificacion'
                                        render={({ field }) => (
                                            <Input
                                                rows={3}
                                                type='textarea'
                                                // readOnly={opcion === 2}
                                                disabled={updateData}
                                                invalid={errors.justificacion && true}
                                                defaultValue={null}
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.justificacion && errors.justificacion.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>}
                    </Row>
                    <Row>
                        {updateData ? <>
                            <Col className='py-2'><Label >Solicitante</Label><br></br><span style={{ fontSize: '12px' }}>{oneData?.solicitantePersona === '' ? oneData?.solicitanteFADN : oneData?.solicitantePersona}</span></Col>
                            <Col className='py-2'><Label >Pertenece A</Label><br></br><span style={{ fontSize: '12px' }}>{oneData?.esFADN === 1 ? oneData?.nombreFADN : oneData?.nombreUnidad}</span></Col>
                            <Col className='py-2'><Label >Estado Actual</Label><br></br><span style={{ fontSize: '12px' }}>{oneData?.estado}</span></Col>
                            {/* <Col className='py-2'><Label >Estado Siguiente</Label><br></br><span style={{ fontSize: '12px' }}>{oneData?.estadoSiguiente}</span></Col>
                            <Col className='py-2'><Label >Pre Orden de Compra</Label><br></br><span style={{ fontSize: '12px' }}>{oneData?.noPreordenCompra}</span></Col> */}

                            {/* <Col className='py-2'>
                                <Label >Saldo Inicial</Label><br></br>
                                <span >{oneData.montoDependenciaActual}</span>
                            </Col>
                            <Col className='py-2'>
                                <Label >Saldo Actual</Label><br></br>
                                
                                <span >{allData2 && allData2[0]?.actualProgramaDependenciaConQ ? allData2[0]?.actualProgramaDependenciaConQ : `Q.00`}</span>
                            </Col> */}
                        </>
                            :
                            null
                            // <Col>
                            //     <FormGroup>
                            //         <Label for="jefeDireccion" className="d-flex justify-content-between">
                            //             <span>* Jefe Dirección</span>
                            //         </Label>
                            //         <Controller
                            //             name="jefeDireccion"
                            //             control={control}
                            //             render={({ field }) => {
                            //                 return (
                            //                     <Select
                            //                         {...field}
                            //                         isClearable
                            //                         onChange={field.onChange} //resuelve el tener que dar doble click en movil
                            //                         onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                            //                         defaultValue={null}
                            //                         options={labelJefeDireccion}
                            //                         // theme={selectThemeColors}
                            //                         // isDisabled={!allMedidaInsumo}
                            //                         isDisabled={updateData}
                            //                         classNamePrefix='select'
                            //                         className='selector-react2'
                            //                         placeholder={'Seleccione una opción'}
                            //                         noOptionsMessage={() => 'sin resultados'}
                            //                         styles={(!!errors.jefeDireccion) ? customStyles : {}}
                            //                     />
                            //                 )
                            //             }
                            //             }
                            //             rules={
                            //                 {
                            //                     required: {
                            //                         value: true,
                            //                         message: 'Este campo es requerido'
                            //                     }
                            //                 }
                            //             }
                            //         />
                            //         <small className="text-danger">
                            //             {!!errors.jefeDireccion && errors.jefeDireccion.message}
                            //         </small>
                            //     </FormGroup>
                            // </Col>
                        }
                        {updateData ? null

                            : <>
                                {/* 
                                <Col>
                                    <FormGroup>
                                        <Label for="tipoPedido" className="d-flex justify-content-between">
                                            <span>* Tipo Pedido</span>
                                        </Label>
                                        <Controller
                                            name="tipoPedido"
                                            control={control}
                                            render={({ field }) => {
                                                return (
                                                    <Select
                                                        {...field}
                                                        isClearable
                                                        onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                        onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                        defaultValue={null}
                                                        options={labelTipoPedido}
                                                        // theme={selectThemeColors}
                                                        // isDisabled={!allMedidaInsumo}
                                                        isDisabled={updateData}
                                                        classNamePrefix='select'
                                                        className='selector-react2'
                                                        placeholder={'Seleccione una opción'}
                                                        noOptionsMessage={() => 'sin resultados'}
                                                        styles={(!!errors.tipoPedido) ? customStyles : {}}
                                                    />
                                                )
                                            }
                                            }
                                            rules={
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido'
                                                    }
                                                }
                                            }
                                        />
                                        <small className="text-danger">
                                            {!!errors.tipoPedido && errors.tipoPedido.message}
                                        </small>
                                    </FormGroup>
                                </Col> */}
                            </>}

                    </Row>
                </div>
            </Form>

        </Fragment >
    )
}

export default Header