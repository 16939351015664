import React, { createContext, useState } from 'react'
import { PostRoute } from '../../../services/Private'
import { OptionsToast } from '../../../Globales/index'
import { toast } from 'react-toastify'
import { useRequest } from '../../../hooks/useRequestSWR'
import { useSWRConfig } from 'swr'

export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    // Modal Init
    const { mutate } = useSWRConfig();
    const [id, setId] = useState(0);
    const [code, setCode] = useState([]);
    const nameController = 'PlanOperativoAnual';
    const nameController2 = 'DetalleAcciones';
    const nameController3 = 'PlanOperativoAnual';
    const nameController4 = 'ObjetivosEstrategicos';
    const nameController5 = 'ObjetivosOperativos';
    const nameController6 = 'Acciones';

    const { data: allData } = useRequest(`${nameController6}/Acciones-X-Unidad`, 'POST', { code: code });
    const { data: allData2 } = useRequest(`${nameController2}/allDetalles`, 'POST', { code: code });
    const { data: allData3 } = useRequest(`${nameController2}/subTotales`, 'POST', { code: code });
    const { data: allData4 } = useRequest(`${nameController2}/totalPoa`, 'POST', { code: code });
    const { data: allDataOE } = useRequest(`${nameController4}/All-code`, 'POST', { code: code });
    const { data: allDataOOU } = useRequest(`${nameController5}/Objetivos-Op-X-Unidad`, 'POST', { code: code });
    // const nameController2 = 'Unidad';
    // const nameController3 = 'PresupuestoDependencia';
    const { data: dataAll } = useRequest(`${nameController}/all`, 'GET');
    // const { data: labelUnidades } = useRequest(`${nameController2}/label`, 'GET');
    // const { data: presupuestoActual } = useRequest(`${nameController3}/presupuestoActual`, 'POST', { id: id });
    const
        [modal, setModal] = useState(null),
        // [allData, setAllData] = useState([]),
        [oneData, setOneData] = useState([]),
        [opcion, setOpcion] = useState(0),
        toggleModal = async (data) => {

            setOpcion(data)
            if (data === 1) { setOneData(null) }
            setModal(!modal)

        },
        // All = async () => {
        //     const response = await GetRoute(`${nameController}/all`)
        //     setAllData((response.length) ? response : [])
        // },
        One = async (data, opcion) => {
            setCode([])
            const response = await PostRoute(`${nameController}/one`, { id: data.id })
            setCode(data.codigoUnidad)

            setOneData((response[0]) ? response[0] : [])
            toggleModal(opcion)

        },
        StoreUpdate = async (data) => {

            let response = []
            response = await PostRoute(`${nameController}/${!data.id ? 'store' : 'update'}`, data)
            toast.success(response.message, OptionsToast)
            toggleModal(0)
            mutate(`${nameController}/all`)

        },
        updatePOA = async (data) => {

            let response = []
            response = await PostRoute(`${nameController}/updatePOA`, data)
            toast.success(response.message, OptionsToast)
            toggleModal(0)
            mutate(`${nameController}/all`)
            mutate(`${nameController}/new-poa`)

        },
        Actions = async (data) => {

            const response = await PostRoute(`${nameController}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data.id })
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)

        },
        value = {
            One,
            Actions,
            StoreUpdate,
            setId,
            toggleModal,
            updatePOA,
            allData,
            allData2,
            allData3,
            allData4,
            allDataOE,
            allDataOOU,
            dataAll,
            modal,
            oneData,
            opcion,
            // labelUnidades,
            // presupuestoActual
        }

    // Modal End

    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}
