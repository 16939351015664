import React, { Fragment, useContext } from 'react'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback } from 'reactstrap'
import { ContentContext } from './context'
import * as Icon from 'react-feather'
import { useForm, Controller } from "react-hook-form"

const Formulario = () => {
    const { toggleModal2, modal2, StoreUpdate2, opcion2, nameComponent2 } = useContext(ContentContext),
        { formState: { errors }, control, reset, watch } = useForm(),
        Nombre = watch('nombre'),
        onSubmit2 = () => {
            const json = {
                nombre: Nombre
            }
            StoreUpdate2(json)
            reset()
        }

    return (
        <Fragment>
            <Button onClick={() => toggleModal2(1)} id="crear2" color="primary" size="sm">
                <Icon.Plus size={16} />
            </Button>
            <UncontrolledTooltip placement='left' target='crear2' placementPrefix="bs-tooltip">
                Nuevo Origen Externo
            </UncontrolledTooltip>

            <Modal
                isOpen={modal2}
                backdrop='static'
                toggle={() => toggleModal2()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal2(0)}>{opcion2 === 1 && 'crear'} {nameComponent2}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for='nombre' className="">* Nombre</Label>
                                <Controller
                                    defaultValue=''
                                    control={control}
                                    id='nombre'
                                    name='nombre'
                                    render={({ field }) => (
                                        <Input
                                            type='text'
                                            invalid={errors.nombre && true}
                                            {...field}
                                        />
                                    )
                                    }
                                    rules={
                                        {
                                            required: {
                                                value: true,
                                                message: 'Este campo es requerido'
                                            }
                                        }
                                    }
                                />
                                <FormFeedback>
                                    {!!errors.nombre && errors.nombre.message}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>


                </ModalBody>
                <ModalFooter>
                    <Button id="salir" color='secondary' onClick={() => toggleModal2(0)}>
                        <Icon.CornerUpLeft size={16} />
                    </Button>
                    <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                        Salir
                    </UncontrolledTooltip>
                    {
                        opcion2 !== 2 &&
                        <Fragment>
                            <Button color='success' type="submit" id="accion">
                                <Icon.Save size={16} onClick={() => onSubmit2()} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                Guardar
                            </UncontrolledTooltip>
                        </Fragment>
                    }
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}

export default Formulario