import React, { createContext, useState, useContext } from 'react'
import { PostRoute } from 'services/Private'
import { OptionsToast } from 'Globales/index'
import { toast } from 'react-toastify'
import { useRequest } from 'hooks/useRequestSWR'
import { useSWRConfig } from 'swr'
import { useRouteCode } from 'hooks/useRouteCode'
import { ContentContext as ContentMeta } from '../AprobarPedido/context'
export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    const{ idAccion, setMutateSaldoAccion, opcion: Opcion2, oneData: oneData2 } = useContext(ContentMeta);
    const { mutate } = useSWRConfig();
    const code = useRouteCode()?.codigo;
    const [accion, setAccion] = useState(0);
    const [liquidado, setLiquidado] = useState(0);
    const [renglon, setRenglon] = useState([]);
    const nameController = 'DetalleAcciones';
    const nameController2 = 'Renglon';
    const nameController4 = 'Acciones';
    const nameController5 = 'PedidoDetalle';
    const nameController6 = 'Insumos';
    const nameController7 = 'Proveedores';
    const nameController8 = 'Pedido';
    const { data: allData } = useRequest(`${nameController5}/allDetalle`, 'POST', { id: accion });
    const { data: totalDetallePedido } = useRequest(`${nameController5}/totalDetallePedido`, 'POST', { id: accion });
    const { data: allData2 } = useRequest(`${nameController4}/one`, 'POST', { id: accion });
    const { data: labelRenglon } = useRequest(`${nameController}/renglonesXAccion`,'POST', { accion: idAccion });
    const { data: allInsumos } = useRequest(`${nameController6}/All`, 'POST', { renglon: renglon });
    const { data: labelUnidadMedida } = useRequest(`${nameController5}/labelUnidadMedida`, 'GET');
    const { data: labelProveedores } = useRequest(`${nameController7}/label`, 'GET');
    const [btnDisable, setBtnDisable] = useState(false);
    const [actualiza, setActualiza] = useState(false),
        [modal, setModal] = useState(null),
        [oneData, setOneData] = useState([]),
        [opcion, setOpcion] = useState(0),
        OneDetalle = async (data) => {
            // setActualiza(true)
            setBtnDisable(false)
            setOneData((data) ? data : [])
        },
        toggleModal = async (data) => {
            setOpcion(data)
            if (data === 1) { setOneData(null) }
            setModal(!modal)
        },
        One = async (data, opcion) => {
            const response = await PostRoute(`${nameController5}/one`, { id: data.id })
            setOneData((response[0]) ? response[0] : [])
            setMutateSaldoAccion(true)
            toggleModal(opcion)
        },
        StoreUpdate = async (data) => {
            let response = []
            response = await PostRoute(`${nameController5}/${!data.id ? 'store' : 'update'}`, data)
            toast.success(response.message, OptionsToast)
            toggleModal(0)
            setOneData([])
            mutate([`${nameController5}/allDetalle`, { id: accion }])
            mutate([`${nameController5}/totalDetallePedido`, { id: accion }])
            setMutateSaldoAccion(true)
        },
        Actions = async (data) => {
            const response = await PostRoute(`${nameController5}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data?.id })
            toast.success(response.message, OptionsToast)
            mutate([`${nameController5}/allDetalle`, { id: accion }])
            mutate([`${nameController5}/totalDetallePedido`, { id: accion }])
            setMutateSaldoAccion(true)
        },
        
        Liquidar = async (data) => {
            const response = await PostRoute(`${nameController8}/${ Opcion2 === 200 ? 'ingresarLiquidacionSTH': 'ingresarLiquidacion'}`, data )
            toast.success(response.message, OptionsToast)
            mutate([`${nameController5}/allDetalle`, { id: accion }])
            mutate(`${nameController8}/all`)

        },
        value = {
            Actions,
            One,
            actualiza,
            OneDetalle,
            StoreUpdate,
            setAccion,
            setOneData,
            setActualiza,
            setRenglon,
            allData,
            idAccion,
            allInsumos,
            allData2,
            btnDisable,
            labelUnidadMedida,
            labelRenglon,
            modal,
            oneData,
            opcion,
            totalDetallePedido,
            Opcion2,
            labelProveedores,
            liquidado, setLiquidado,
            Liquidar,
            oneData2
        }

    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}
