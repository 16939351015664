
// import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useContext, useEffect, useState, useRef } from 'react'
import { ContentContext } from './context'
import { useForm, Controller } from "react-hook-form"
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback, CardBody, CardTitle } from 'reactstrap'
import * as Icon from 'react-feather'
import Select from 'react-select'
import { formatDate, customStyles, stringToDate } from '../../../Globales/index'
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput'
import classnames from 'classnames'
import { Text } from "@nextui-org/react";
import Header from './header'
import Detalle from '../DetalleModificaciones/form'
import { ContentProvider } from '../DetalleModificaciones/context'
import revision from '../../../assets/images/Checklist.png'
import DatePicker, { registerLocale } from "react-datepicker"
import es from "date-fns/locale/es"
import Moment from 'react-moment';
import 'moment/locale/es-mx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";
import "/node_modules/swiper/swiper.scss";
registerLocale("es", es); // register it with the name you want


const Formulario = () => {
    // const [startDate, setStartDate] = useState(Date.parse(2019) + 1);
    const [startDate, setStartDate] = useState(new Date());

    const { toggleModal, modal, StoreUpdate, opcion, oneData, labelAcciones, setId, agregarRenglon, setAgregarRenglon, labelFinanciamiento, idPresupuestoPoa, labelTipoGasto, labelTipoPedido, labelFand, useDataUser, nameComponent, labelJefeDireccion, solicitante, pdf, setAccion, validador, labelTipoOperacion, UpdateEstadoPedido, EnviarARevision, DataBitacoraPedido } = useContext(ContentContext),
    { register, handleSubmit, formState: { errors }, control, reset, watch } = useForm(),
    inputTipoRechazo = watch("tipoRechazo"),
    onSubmitEnviarRevision = () => {
        if (opcion === 20) {
                const json2 = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 2
                }
                const json = {
                    id: (oneData) ? oneData.id : null
                }
                UpdateEstadoPedido(json2) 
                EnviarARevision(json)
        }

        reset()
        toggleModal(0)
    };
    const prevInputTipoRechazo = useRef(null);

    useEffect(() => {
        if (!isNaN(inputTipoRechazo)) {
            setTipoRechazo(Number(inputTipoRechazo));
            prevInputTipoRechazo.current = inputTipoRechazo;
        }
    }, [inputTipoRechazo]);
    return (
        <Fragment>
            <Button onClick={() => toggleModal(1)} id="crear" color="primary" size="sm">
                <Icon.Plus size={16} />
            </Button>
            <UncontrolledTooltip placement='left' target='crear' placementPrefix="bs-tooltip">
                Crear
            </UncontrolledTooltip>

            {opcion > 0 && opcion < 5 && <Modal
                isOpen={modal}
                backdrop='static'
                style={{ maxWidth: '1600px', width: '80%', margin: '10px auto' }}
                toggle={() => toggleModal()}
                className='modal-dialog-centered modal-xl'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}> {opcion === 1 ? "Crear" : opcion === 3 ? "Actualizar" : "Visualizar"}{` ${nameComponent}`}</ModalHeader>
                <ModalBody style={(opcion === 1 ? {} : { backgroundColor: 'rgb(0 0 0 / 7%)', paddingBottom: '0' })}>
                    <Header />
                    {(oneData && oneData.id) &&
                        <ContentProvider>
                            <Detalle oneData={oneData} agregarRenglon={agregarRenglon} setAgregarRenglon={setAgregarRenglon} setAccion={setAccion} validador={validador} />
                        </ContentProvider>
                    }
                </ModalBody>
                {/* <ModalFooter>
                    <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                        <Icon.CornerUpLeft size={16} />
                    </Button>
                    <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                        Salir
                    </UncontrolledTooltip>
                </ModalFooter> */}
            </Modal>}
            {/* Opcion Ver PROCESO */}
            {opcion === 6 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered modal-xl'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>Proceso</ModalHeader>
                <Form autoComplete="off"
                >
                    <ModalBody>
                        <>
                            <Row>
                                <Col lg="12">
                                    <div>
                                        <CardBody>
                                            <CardTitle className="mb-4 text-center">Tiempo transcurrido: {DataBitacoraPedido && DataBitacoraPedido[DataBitacoraPedido.length - 1]?.tiempoTranscurrido} || Solicitado:   <Moment locale="es" format='lll' fromNow>{DataBitacoraPedido && DataBitacoraPedido[0]?.fechaCreado}</Moment></CardTitle>
                                            {/* <h5 className="mb-4">Línea de Tiempo</h5> */}

                                            <div className="hori-timeline">
                                                <Swiper
                                                    slidesPerView={1}
                                                    // spaceBetween={10}
                                                    navigation
                                                    pagination={{
                                                        clickable: true,
                                                    }}
                                                    breakpoints={{
                                                        678: {
                                                            slidesPerView: 2,
                                                        },
                                                        992: {
                                                            slidesPerView: 3,
                                                        },
                                                        1400: {
                                                            slidesPerView: 4,
                                                        }
                                                    }}
                                                    loop={false}
                                                    modules={[Pagination, Navigation]}
                                                    className="owl-carousel owl-theme  navs-carousel events"
                                                    id="timeline-carousel"
                                                >
                                                    {DataBitacoraPedido && DataBitacoraPedido?.map((item, index) => (
                                                        <SwiperSlide
                                                            key={index}
                                                            className="item event-list"
                                                            style={{ display: "inline-table" }}
                                                        >
                                                            <div>
                                                                <div className="event-date">
                                                                    <div className="text-primary mb-1">
                                                                        <Moment locale="es" format='lll' fromNow>{item?.creado}</Moment>
                                                                    </div>
                                                                    <h5 className="mb-4">{item?.estadoTexto}</h5>
                                                                </div>
                                                                <div className="event-down-icon">
                                                                    <i className={`bx ${(item?.estadoTexto?.includes('Rechazado')) || (item?.estadoTexto?.includes('Anulación Operada')) ? 'bx-x-circle text-danger' : 'bx-check-circle text-success'} h1  down-arrow-icon`} />
                                                                </div>

                                                                <div className="mt-3 px-3">
                                                                    <p className="text-muted">
                                                                        {item?.usuario}
                                                                    </p>
                                                                </div>
                                                                <div className="mt-3 px-3">
                                                                    <p className="text-muted">
                                                                        <strong>{item?.rechazoTipoTexto}</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}

                                                </Swiper>
                                            </div>
                                        </CardBody>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Regresar
                        </UncontrolledTooltip>
                        {/* {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Printer size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    Imprimir
                                </UncontrolledTooltip>
                            </Fragment>
                        } */}
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Ver PDF */}
            {opcion === 5 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered modal-xl'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>PDF</ModalHeader>
                <Form autoComplete="off"
                >
                    <ModalBody>
                        <Row>
                            <Col >
                                <object
                                    className='Visor'
                                    width='100%'
                                    height='600vh'
                                    id='pdf-ficha'
                                    aria-labelledby='perfil'
                                    type='application/pdf'
                                    // data={`https://s1.q4cdn.com/806093406/files/doc_downloads/test.pdf`}
                                    data={`${pdf}`}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Regresar
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <a className="btn btn-success " id="imprimir" download="pedido.pdf" href={`${pdf}`} target="_blank" rel="noopener noreferrer"><Icon.Download size={16} /> Descargar</a>
                                {/* <Button color='success' type="submit" id="accion">
                                    <Icon.Printer size={16} />
                                </Button> */}
                                {/* <UncontrolledTooltip placement='top' target='imprimir' placementPrefix="bs-tooltip">
                                    Imprimir
                                </UncontrolledTooltip> */}
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
           {/* Opcion Enviar a Revisión */}
           {opcion === 20 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 20 && 'Enviar '} Solicitud de Modificación Presupuestaria</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEnviarRevision)}>
                    <ModalBody>
                        <Row >
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    ¿Está seguro de que todo está bien y desea proceder?
                                </Text>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Send size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 20 && 'Enviar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}

        </Fragment >
    )
}

export default Formulario