import React, { useState, useContext } from "react"
import { Row, Col, Card, CardBody, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Spinner } from 'reactstrap'
import * as Icon from "react-feather"
import Uppy from "@uppy/core"
// import { useParams } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { Loading } from "@nextui-org/react";
import DragDrop from "@uppy/react/lib/DragDrop"
import { OptionsToast } from 'Globales/index'
import { toast } from 'react-toastify'
import "uppy/dist/uppy.css"
import '@uppy/status-bar/dist/style.css'
import "../../../../../assets/scss/react/libs/file-uploader/file-uploader.scss"
import { toAbsoluteUrl } from 'helpers/index'
// import {toAbsoluteUrl} from '../../../../../_metronic/helpers'

// import { Notification } from "../../../../../utility/Notify/index"
import { PostRouteFD } from "../../../../../services/Private"
// import { LoadingContext } from "../../../../../utility/Loading/provider"
import { ContentContext } from './context'
const ErrorToast = () => (
  <>
    <div className="toastify-header">
      <div className="title-wrapper">
        <h6 className="toast-title">Tipo no admitido</h6>
      </div>
      <small className="text-muted">Hace un momento.</small>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        Solo son aceptados los archivos con extensión:{" "}
        <span className="font-weight-bolder">.xlsx</span>,{" "}
        <span className="font-weight-bolder">.xls</span> &{" "}
        <span className="font-weight-bolder">.csv</span> !.
      </span>
    </div>
  </>
)

const Import = () => {
  // const [showLoading, setShowLoading] = useState(false)
  const { toggleModal, modal } = useContext(ContentContext)
  const { handleSubmit } = useForm()

  const [file, setFile] = useState([]),

    // const [modalShow, setModalShow] = useState(false),
    handleClose = () => setModalShow(false)
  // { setShowLoad } = useContext(LoadingContext),
  // handleCloseLoading = () => setShowLoading(false),
  // handleShow = () => setModalShow(true);
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  function niceBytes(x) {

    let l = 0, n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n = n / 1024;
    }

    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
  }
  const
    [load, setLoad] = useState(false),
    [name, setName] = useState(""),
    [size, setSize] = useState([]),
    onSubmit = async () => {
      // handleClose()
      // setShowLoading(true)
      setLoad(true)
      var formData = new FormData()
      formData.append("file", file)
      const response = await PostRouteFD("insumos/carga-insumos", formData)
      // setShowLoading(false)

      if (response.response === 1) {
        var formDataTwo = new FormData()
        formDataTwo.append("file", file)
        formDataTwo.append("id", response.value)
        let responseTwo = await PostRouteFD("insumos/carga-file-insumos", formDataTwo)
        if (responseTwo.response === 1) {
          setFile([])
          setName("")
          setSize([])
          // Notification(responseTwo.message, responseTwo.response)
          toast.success(responseTwo.message, OptionsToast)
          setLoad(false)
          toggleModal()
        } else {
          toast.success(responseTwo.message, OptionsToast)
          setLoad(false)
          toggleModal()
          // Notification(responseTwo.message, response.response)
        }
      } else {
        // Notification(response.message, response.response)
        toast.success(response.message, OptionsToast)
        setLoad(false)
        toggleModal()
      }
    }
  const uppy = new Uppy({
    restrictions: { maxNumberOfFiles: 1, maxTotalFileSize: 100 * 1024 * 1024 },
    autoProceed: true
  })

  uppy.on("complete", result => {
    // setShowLoad(true)

    if (
      result.successful[0].extension === "xlsx" ||
      result.successful[0].extension === "csv" ||
      result.successful[0].extension === "xls"
    ) {
      setFile(result.successful[0].data)
      setName(result.successful[0].data.name)
      setSize(result.successful[0].data.size)
      // setShowLoad(false)
    } else {
      toast.error(<ErrorToast />, { hideProgressBar: true })
      // setShowLoad(false)
    }
  })

  const EraseFile = () => {
    setFile([])
    setName("")
  }

  return (
    <>
      {/* <Loading show={showLoading} onHide={handleCloseLoading} /> */}
      <Modal
        isOpen={modal}
        backdrop='static'
        toggle={() => toggleModal()}
        className='modal-dialog-centered'
      >
        <ModalHeader className="text-uppercase" toggle={() => toggleModal()}>Confirmación</ModalHeader>
        <ModalBody>
          <h3 className="text-center">
            <b >
              Está a punto de cargar un nuevo catálogo de insumos
              <br></br>
              ¿Desea	continuar?
            </b>
          </h3>
          {load === true &&
            <div className="text-center">
              <Loading size="md">Subiendo Archivo</Loading>
            </div>
          }
        </ModalBody>
        <ModalFooter>
          <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
            <Icon.CornerUpLeft size={16} />
          </Button>
          <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
            Cancelar
          </UncontrolledTooltip>
          <Button color="success" onClick={onSubmit}>
            Continuar
          </Button>
        </ModalFooter>
      </Modal>
      {/* <Modal
        isOpen={modal}
        backdrop='static'
        toggle={() => toggleModal()}
        className='modal-dialog-centered'
      >
        <ModalHeader>
          <div id="contained-modal-title-vcenter">
            Confirmacion de envío
          </div>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col className={"text text-center"}>
              <h3>
                <b>
                  Estoy seguro que la información registrada en el documento
                  esta correctamente asignada y he verificado cada uno de los
                  registros
                </b>
              </h3>
            </Col>
          </Row>
        </ModalBody>
        <Row>
          <Col className="mx-3 mb-4 justify-content-end d-flex">
            <Button variant="danger" onClick={handleClose}>
              Cancelar
            </Button>
          </Col>
          <Col className="mx-3 mb-4 justify-content-start d-flex">
            <Button variant="success" onClick={onSubmit}>
              Confirmar
            </Button>
          </Col>
        </Row>
      </Modal> */}

      {/* <Form onSubmit={handleSubmit(onSubmit)}> */}
      <Row className="import-component">
        <Col sm="12">
          <Card>
            <CardBody>
              <Row>
                <Col sm="12">
                  <DragDrop
                    uppy={uppy}
                    locale={{
                      strings: {
                        dropHereOr: "SUBA EL ARCHIVO DE INSUMOS AQUÍ",
                        browse: "browse"
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row className="d-flex align-items-center">
                {name !== "" && (
                  <>
                    <Col>
                      <div className="d-flex align-items-center p-3">
                        <div className="flex-shrink-0 me-3 ">
                          <img
                            className="rounded avatar-sm"
                            src={toAbsoluteUrl("/media/logos/excel-logo.png")}
                            alt=""
                          />
                        </div>
                        <div className="">
                          <h5>{name}</h5>
                          <p className="mb-0">
                            {niceBytes(size)}
                          </p>
                        </div>
                        <Button
                          // style={{ padding: "0px", margin: "-8px" }}
                          color="danger"
                          className=" btn-icon btn-sm rounded-circle m-3"
                          onClick={() => EraseFile()}
                          id="borrar"
                        >
                          <Icon.X className="text text-soft" />
                        </Button>
                        <UncontrolledTooltip placement='right' target='borrar' placementPrefix="bs-tooltip">
                          Cancelar
                        </UncontrolledTooltip>
                      </div>
                    </Col>
                    <Col lg={2} className="p-3 text-end">
                      {
                        <>
                          <Button
                            onClick={() => toggleModal(1)}
                            style={{ display: name === "" ? "none" : "" }}
                            color="success"
                            size="sm"
                            id="enviar"
                          >
                            <Icon.Send size={22} />
                          </Button>

                          <UncontrolledTooltip placement='left' target='enviar' placementPrefix="bs-tooltip">
                            Enviar Archivo
                          </UncontrolledTooltip>
                        </>
                      }
                    </Col>
                  </>
                )}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* </Form> */}
    </>
  )
}

export default Import
