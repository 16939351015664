import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { ContentContext } from './context'
import { ContentProvider as ContentProvider2 } from './Insumo/File/context'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback } from 'reactstrap'
import { useForm, Controller } from "react-hook-form"
import * as Icon from 'react-feather'
import DatePicker from "react-datepicker";
import Select from 'react-select'
import { customStyles } from '../../../Globales/index'
import classnames from 'classnames'
import { Text } from "@nextui-org/react";
import { useHistory } from "react-router-dom"
import FileUpload from './Insumo/File/FileUpload'
import Insumos from './Insumo/Insumos/index'
import Historico from './Insumo/GestorInsumo/index'
import SolicitudInsumos from './Insumo/AdminSolicitudInsumos/index'
// import Objetivos from './Objetivos/index'
// import Acciones from './Acciones/index'
// import Presupuesto from './Presupuesto/index'
// import POA from './POA/index'

const CardOne = () => {
    const history = useHistory();
    const [tab, setTab] = useState(1),
        TabActive = (No) => {
            setTab(No)
        };


    return (
        <Fragment>

            <div className="card mb-6 mb-xl-9 m-3 d-print-none">
                <div className="card-body pt-9 pb-0 ">
                    <div className="d-flex flex-wrap flex-sm-nowrap">
                        <div
                            className=" mx-2 text-center d-flex justify-content-center align-self-center" style={{ height: '20vh', width: '10vw' }}
                        >
                            <span className="avatar-title rounded-4 text-ligth fs-1 content-colorized">
                                <i className="dripicons-view-thumb  mt-2"></i>
                            </span>

                        </div>
                        <div className="flex-grow-1">
                            <div className="d-flex justify-content-between align-items-start flex-wrap ">
                                <div className="d-flex mx-3 flex-column">
                                    <div className="d-flex align-items-center mb-1">
                                        <a
                                            href="#"
                                            className="text-dark fs-4 fw-bold me-3"
                                        >
                                            Gestión del Catálogo de Insumos
                                        </a>
                                    </div>

                                </div>
                                <div className="d-flex align-items-center mt-3">
                                    <Button
                                        onClick={() => history.push(`/dashboard`)}
                                        color="warning"
                                        size="sm"
                                        id="regresar"
                                    >
                                        <Icon.ArrowLeft size={16} />
                                    </Button>

                                    <UncontrolledTooltip placement='left' target='regresar' placementPrefix="bs-tooltip">
                                        Regresar
                                    </UncontrolledTooltip>
                                </div>

                            </div>


                        </div>


                    </div>
                    {/* end::Header */}
                    <div className="separator" />
                    <ul className="nav nav-tabs nav-tabs-custom nav-line-tabs-2x mb-1 fs-5 border-transparent fw-bolder" role="tablist">
                        <li className="nav-item" role="presentation" onClick={() => TabActive(1)}>
                            <a
                                className="nav-link active"
                                data-bs-toggle="tab"
                                href="#insumos"
                                role="tab"
                                aria-selected="false"
                            >
                                <span className="d-block d-sm-none">
                                    <i className="fas fa-cog" />
                                </span>
                                <span className="d-none d-sm-block">Insumos</span>
                            </a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#carga"
                                role="tab"
                                aria-selected="false"
                                tabIndex={-1}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="far fa-envelope" />
                                </span>
                                <span className="d-none d-sm-block">Carga de Insumos</span>
                            </a>
                        </li>
                        <li className="nav-item" role="presentation" >
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#historico"
                                role="tab"
                                aria-selected="true"
                                tabIndex={-1}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="fas fa-home" />
                                </span>
                                <span className="d-none d-sm-block">Historico</span>
                            </a>
                        </li>
                        <li className="nav-item" role="presentation" >
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                href="#solicitudes"
                                role="tab"
                                aria-selected="true"
                                tabIndex={-1}
                            >
                                <span className="d-block d-sm-none">
                                    <i className="fas fa-home" />
                                </span>
                                <span className="d-none d-sm-block">Solicitudes</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="tab-content p-3 mb-20 text-muted">
                <div className="tab-pane active show" id="insumos" role="tabpanel">
                    <div className="card" style={{paddingBottom: '200px', paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px'}}>
                        <Insumos />
                    </div>
                </div>
                <div className="tab-pane" id="carga" role="tabpanel">
                    <div className="card p-3">
                        <ContentProvider2>
                            <FileUpload />
                        </ContentProvider2>
                    </div>
                </div>
                <div className="tab-pane " id="historico" role="tabpanel">
                    <p className="mb-0">
                        <Historico />
                    </p>
                </div>
                <div className="tab-pane" id="solicitudes" role="tabpanel">
                    <div className="card p-3">
                        <SolicitudInsumos />
                    </div>
                </div>
                <div className="tab-pane " id="objetivos" role="tabpanel">

                </div>
                <div className="tab-pane " id="reportes" role="tabpanel">
                    <p className="mb-0">
                        Reportes
                    </p>
                </div>
            </div>
        </Fragment>
    )
}
export default CardOne