import { Button, Col, Label, Row, UncontrolledTooltip } from 'reactstrap'
import { ContentContext } from './context'
import { TextDetalleAccion, EstadoLista } from '../../../CustomList/index'
import * as Icon from 'react-feather'
import DataTable from 'react-data-table-component'
import React, { Fragment, useContext, useState, useEffect } from 'react'
import { ContentContext as ContentContextTheme } from '../../../components/CommonForBoth/TopbarDropdown/context'


const ListAjuste = (props) => {
  const { theme } = useContext(ContentContextTheme)
  const { allData, OneDetalle, Actions, btnDisable, setActualiza, totalDetallePedido, opcion, oneData2 } = useContext(ContentContext);

  // const [columns, setColumns] = useState([]);
  // useEffect(() => {

  const columnasBase =
    [
      {
        name: 'Renglón',
        column: 'renglon',
        sortable: true,
        center: false,
        cell: row => TextDetalleAccion(row['renglon'])
      },
      {
        name: 'Medida',
        column: 'unidadMedidaInsumo',
        sortable: true,
        center: false,
        cell: row => TextDetalleAccion(row['unidadMedidaInsumo'])
      },
      {
        name: 'Cantidad',
        column: 'cantidad',
        center: false,
        grow: 1,
        wrap: true,
        cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '10px' }}>{row.cantidad}</div>
      },
      {
        name: 'Descripción',
        column: 'descripcion',
        sortable: true,
        grow: 7, // Ajusta el valor según sea necesario para dar más espacio a la columna
        wrap: true,
        cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '10px' }}>{row.descripcionConPartida}</div> // Esto asegura que el contenido no se trunca

      },
      // {
      //   name: 'Costo U.',
      //   column: 'costoUnitario',
      //   sortable: true,
      //   center: false,
      //   cell: row => TextDetalleAccion(row['costoUnitario'])
      // },
      {
        name: 'Inicial',
        column: 'costo',
        sortable: true,
        center: false,
        cell: row => TextDetalleAccion(row['costo'])
      },
      {
        name: 'Ajuste',
        column: 'costoReal',
        sortable: true,
        center: false,
        cell: row => TextDetalleAccion(row['costoAjusteMonto'])
      },
      // {
      //   name: 'Cod. Insumo',
      //   column: 'insumo',
      //   sortable: true,
      //   center: false,
      //   cell: row => TextDetalleAccion(row['insumo'])
      // },
      // {
      //   name: 'Cod. Pres',
      //   column: 'presentacion',
      //   sortable: true,
      //   center: false,
      //   cell: row => TextDetalleAccion(row['presentacion'])
      // },
      {
        name: 'Total',
        column: 'costoEstimadoMonto',
        sortable: true,
        center: false,
        cell: row => TextDetalleAccion(row['costoEstimadoMonto'])
      },
      // {
      //   name: 'Acciones',
      //   column: 'id',
      //   sortable: true,
      //   center: true,
      //   cell: row => (
      //     <Fragment>
      //       {
      //         <>
      //           <div className={`btn-icon  px-2 cursor-pointer ${btnDisable && 'cursor-not-allowed'}`} color='flat-white' id='Actualizar' disabled={btnDisable} style={{ outline: 'none' }} size="sm"
      //             onClick={() => [props.agregarRenglon(2), OneDetalle(row), setActualiza(true)]}>
      //             <Icon.PlusCircle size={16} />
      //           </div>
      //           <UncontrolledTooltip placement='top' target='Actualizar'>
      //             Ajustar
      //           </UncontrolledTooltip>
      //         </>
      //       }
      //     </Fragment>
      //   )
      // }
    ];



  //   setColumns(columnasBase);
  // }, [liquidado]); // Dependencias vacías para que se ejecute solo al montar el componente


  return (
    <Fragment>
      <div className='card'>
        <DataTable
          // dense
          striped
          noHeader
          fixedHeader
          fixedHeaderScrollHeight="275px"
          highlightOnHover
          theme={theme}
          // pagination
          data={(allData && allData.value !== 2009 ? allData : [])}
          columns={columnasBase}
          // className='ps'
          noDataComponent='Sin Registros'
        />
        <Row style={{ padding: '0' }}>
          <Col className='text-center'>
            {/* <div className='card'><Row ><Label >Total POA</Label><br></br><span className='fs-4'>{
              props.total && props?.total[0]?.totalPOAconQ ? props.total?.at(0)?.totalPOAconQ : `Q.00`
            }</span></Row></div> */}
          </Col>
          <Col className='text-center'>
            {/* <div className='card'><Row ><Label >Total Ejecutado</Label><br></br><span className='fs-4'>{'Q.0.00'}</span></Row></div> */}
          </Col>
          {oneData2?.ajuste === 1 ?
            <Col className='text-center'>
              <div className='card'><Row ><Label >Total Pedido</Label><br></br><span className='fs-4 fw-bold'>{totalDetallePedido?.[0]?.totalEsQ ? totalDetallePedido?.[0]?.totalEsQ : `Q.00`}</span></Row></div>
            </Col>
            :
            <Col className='text-center'>
              <div className='card'><Row ><Label >Total Pedido</Label><br></br><span className='fs-4 fw-bold'>{totalDetallePedido?.[0]?.totalQ ? totalDetallePedido?.[0]?.totalQ : `Q.00`}</span></Row></div>
            </Col>}
        </Row>

      </div>
    </Fragment>
  )
}

export default ListAjuste