import React, { useState } from 'react';
import Webcam from 'react-webcam';
import { Button } from 'reactstrap'
import * as Icon from 'react-feather'

function Camara({ setFoto, setFileFoto }) {
  const [activa, setActiva] = useState(false);
  const webcamRef = React.useRef(null);

  const tomarFoto = () => {
    const foto = webcamRef.current.getScreenshot();
    fetch(foto)
      .then(res => res.blob())
      .then(blob => {
        const archivo = new File([blob], 'foto.png', { type: 'image/png' });
        setFoto(foto);
        setFileFoto(archivo);
        setActiva(false);
      });
  };

  return (
    <div>
      {activa ? (
        <div>
          <Webcam audio={false} ref={webcamRef} />
          <Button onClick={tomarFoto}><Icon.Aperture size={16} /> Tomar foto</Button>
        </div>
      ) : (
        <Button className='text-center' onClick={() => setActiva(true)}> <Icon.Camera size={16} /> Activar cámara</Button>
      )}
    </div>
  );
}

export default Camara;
