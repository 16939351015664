import React, { useContext, useEffect } from 'react'
import PropTypes from "prop-types";
import { ContentContext } from '../../context/ThemeContext'
import { ContentContext as ContentContextNotifications } from '../CommonForBoth/TopbarDropdown/context'

import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutMode,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changePreloader,
  changeTopbarTheme,
  showRightSidebarAction,
} from "../../store/actions";

//SimpleBar
import SimpleBar from "simplebar-react";

import { Link } from "react-router-dom";

import "../../components/CommonForBoth/rightbar.scss";


//constants
import {
  layoutModeTypes,
} from "../../constants/layout";

// const ThemeModeContext = createContext({
//   mode: 'light',
//   // menuMode: defaultThemeMode.menuMode,
//   // updateMode: (_mode: ThemeModeType) => {},
//   // updateMenuMode: (_menuMode: ThemeModeType) => {},
// })

// const useThemeMode = () => useContext(ThemeModeContext)
// const ThemeModeProvider = ({children}) => {
//   const [mode, setMode] = useState<ThemeModeType>(defaultThemeMode.mode)

//   const updateMode = (_mode) => {
//     setMode(_mode)


//   useEffect(() => {
//     updateMode(mode)
//     // updateMenuMode(menuMode, false)
//   }, [])

//   return (
//     <ThemeModeContext.Provider value={{mode}}>
//       {children}
//     </ThemeModeContext.Provider>
//   )
// }
// }

const RightSidebar = props => {
  // const { setTheme } = useContext(ContentContext)
  const { allDataTheme, Actions, theme2 } = useContext(ContentContextNotifications)

  useEffect(() => {
    props?.changeLayoutMode(theme2);
  }, [theme2]);
  return (
    <React.Fragment>
      <div className="right-bar" id="right-bar">
        <SimpleBar style={{ height: "900px" }}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title px-3 py-4">
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault()
                  props.showRightSidebarAction(false)
                }}
                className="right-bar-toggle float-end"
              >
                <i className="mdi mdi-close noti-icon" />
              </Link>
              <h5 className="m-0">Configuración</h5>
            </div>


            <div className="p-4">
              <div className="radio-toolbar">
                <span className="mb-2 d-block">Tema</span>
                <input
                  type="radio"
                  id="radioLight"
                  name="radioLight"
                  value={layoutModeTypes.LIGHT}
                  checked={props.layoutModeType === layoutModeTypes.LIGHT}
                  onChange={e => {
                    if (e.target.checked) {
                      props.changeLayoutMode(e.target.value);
                      Actions(allDataTheme[0], e.target.value)
                      // setTheme(e.target.value)
                    }
                  }}
                />
                <label className="me-1" htmlFor="radioLight">Light</label>
                <input
                  type="radio"
                  id="radioDark"
                  name="radioDark"
                  value={layoutModeTypes.DARK}
                  checked={props.layoutModeType === layoutModeTypes.DARK}
                  onChange={e => {
                    if (e.target.checked) {
                      props.changeLayoutMode(e.target.value);
                      Actions(allDataTheme[0], e.target.value)
                    }
                  }}
                />
                <label htmlFor="radioDark">Dark</label>
              </div>
            </div>
          </div>
        </SimpleBar>
      </div>
      <div className="rightbar-overlay"></div>
    </React.Fragment>
  );
};

RightSidebar.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changePreloader: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarThemeImage: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  isPreloader: PropTypes.any,
  layoutType: PropTypes.any,
  layoutModeType: PropTypes.any,
  changeLayoutMode: PropTypes.func,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarThemeImage: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  topbarTheme: PropTypes.any,
  onClose: PropTypes.func,
};

const mapStateToProps = state => {

  // setLayout(state.Layout)
  return { ...state.Layout };
};

// const { setLayout } = useContext(ContentContext)
export default connect(mapStateToProps, {
  changeLayout,
  changeLayoutMode,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeLayoutWidth,
  changeTopbarTheme,
  changePreloader,
  showRightSidebarAction,
})(RightSidebar);
