import React, { createContext, useState } from 'react'
import { PostRoute } from '../../../services/Private'
import { OptionsToast } from '../../../Globales/index'
import { toast } from 'react-toastify'
import { useRequest } from '../../../hooks/useRequestSWR'
import { useSWRConfig } from 'swr'
import { useRouteCode } from 'hooks/useRouteCode'

export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    const { mutate } = useSWRConfig();
    const code = useRouteCode()?.codigo;
    const [accion, setAccion] = useState(0);
    const nameController = 'DetalleAcciones';
    const nameController2 = 'Renglon';
    const nameController3 = 'Financiamiento';
    const nameController4 = 'Acciones';
    const { data: allData } = useRequest(`${nameController}/by-accion`, 'POST', { accion: accion });
    const { data: allData2 } = useRequest(`${nameController4}/one`, 'POST', { id: accion });
    const { data: total } = useRequest(accion !== 0 ? `${nameController}/total` : null, 'POST', { accion: accion });
    const { data: labelRenglon } = useRequest(`${nameController2}/label`, 'GET');
    const { data: labelFinanciamiento } = useRequest(`${nameController3}/label`, 'GET');
    const { data: allData3 } = useRequest(`${nameController}/allDetalles`, 'POST', { code: code });
    const { data: allData5 } = useRequest(`${nameController}/subTotales`, 'POST', { code: code });
    const { data: allData4 } = useRequest(`${nameController}/totalPoa`, 'POST', { code: code });
    const [btnDisable, setBtnDisable] = useState(false),
        [modal, setModal] = useState(null),
        [oneData, setOneData] = useState([]),
        [opcion, setOpcion] = useState(0),
        OneDetalle = async (data) => {
            setBtnDisable(false)
            setOneData((data) ? data : [])
        },
        toggleModal = async (data) => {
            setOpcion(data)
            if (data === 1) { setOneData(null) }
            setModal(!modal)
        },
        One = async (data, opcion) => {
            const response = await PostRoute(`${nameController}/one`, { id: data.id })
            setOneData((response[0]) ? response[0] : [])
            toggleModal(opcion)
        },

        // StorePersonal = async (data, type) => {
        //     setShowLoad(true)
        //     if (type === 0) {
        //         Notification("La persona no puede estar asignada dos veces.", 2)
        //     } else {
        //         let response = []
        //         response = await PostRoute(`${nameController}/${!data.id ? 'asignar-persona-copes' : 'update'}`, data)
        //         if (response.response === 0) {
        //             Notification(response.message, 2)
        //         } else {
        //             Notification(response.message, 1)
        //         }
        //         // toggleModal(0)
        //         mutate([`${nameController}/all-personas-plan`, { idPlanAnual: idPlanificacion,idCooperativa: idCooperativa }])
        //     }
        //     setShowLoad(false)
        // },
        StoreUpdate = async (data, type) => {
            if (type === 0) {
                toast.error('El renglón ya está agregado a esta acción, si desea modificar el renglon presione el boton actualizar.', OptionsToast)
            } else {
                let response = []
                response = await PostRoute(`${nameController}/${!data.id ? 'store' : 'update'}`, data)
                toast.success(response.message, OptionsToast)
                toggleModal(0)
                setOneData([])
                mutate([`${nameController}/by-accion`, { accion: accion }])
                mutate([`${nameController4}/one`, { id: accion }])
                mutate([`${nameController}/total`, { accion: accion }])
                mutate([`${nameController}/allDetalles`, { code: code }])
                mutate([`${nameController}/totalPoa`, { code: code }])
                mutate([`${nameController}/subTotales`, { code: code }])
            }
        },
        Actions = async (data) => {
            const response = await PostRoute(`${nameController}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data.id })
            toast.success(response.message, OptionsToast)
            mutate([`${nameController}/by-accion`, { accion: accion }])
            mutate([`${nameController4}/one`, { id: accion }])
            mutate([`${nameController}/total`, { accion: accion }])
            mutate([`${nameController}/allDetalles`, { code: code }])
            mutate([`${nameController}/totalPoa`, { code: code }])
            mutate([`${nameController}/subTotales`, { code: code }])
        },
        value = {
            Actions,
            One,
            OneDetalle,
            StoreUpdate,
            setAccion,
            allData,
            allData2,
            btnDisable,
            labelFinanciamiento,
            labelRenglon,
            modal,
            oneData,
            opcion,
            total
        }

    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}
