
// import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useContext, useEffect, useState, useRef } from 'react'
import { ContentContext } from './context'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback, CardBody, CardTitle } from 'reactstrap'
import { useForm, Controller } from "react-hook-form"
import * as Icon from 'react-feather'
import Select from 'react-select'
import { formatDate, customStyles, stringToDate } from '../../../Globales/index'
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput'
import classnames from 'classnames'
import { Text } from "@nextui-org/react";
import Header from './header'
import ListAsignados from './listAsignados'
import ListAsigVeh from './listAsigVeh'
import Detalle from '../AdminGestionesDetalle/form'
import { ContentProvider } from '../AdminGestionesDetalle/context'
import revision from '../../../assets/images/Checklist.png'
import DatePicker, { registerLocale } from "react-datepicker"
import es from "date-fns/locale/es"
import Moment from 'react-moment';
import 'moment/locale/es-mx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";
import "/node_modules/swiper/swiper.scss";
registerLocale("es", es); // register it with the name you want


const Formulario = () => {
    // const [startDate, setStartDate] = useState(Date.parse(2019) + 1);
    const [startDate, setStartDate] = useState(new Date());
    const [validador, setValidador] = useState(false);
    const [tipoRechazo, setTipoRechazo] = useState(0);
    const { toggleModal, modal, StoreUpdate, opcion, oneData, labelAcciones, setId, agregarRenglon, setAgregarRenglon, labelFinanciamiento, idPresupuestoPoa, labelTipoGasto, labelTipoPedido, labelFand, useDataUser, nameComponent, labelJefeDireccion, solicitante, pdf, UpdateEstadoPedido, DataBitacoraPedido, EnviarARevision, idModalidad, esFADN,
        labelPilotos, labelVehiculos, AsignarGestTransporte, allDataAsignaciones, FinalizarST} = useContext(ContentContext),
        { register, handleSubmit, formState: { errors }, control, reset, watch } = useForm(),
        inputTipoRechazo = watch("tipoRechazo"),
        onSubmitEstado = (data) => {
            if (opcion === 9) { // Aprobar Jefe Transporte
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: (oneData?.esFADN) === 0 ? 7 : 3, // 0 COG 1 FADN // 11 FINALIZA 3 PASA A TECNICA
                }

                UpdateEstadoPedido(json)
            }
            if (opcion === 10) { // Rechazar Jefe Transporte
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: 3,
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 2
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 11) { // Aprobar Tecnica
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 4
                }

                UpdateEstadoPedido(json)
            }
            if (opcion === 12) {// Rechazo Tecnica
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: 3,
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 2
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 13) { //Aprobar SEI/SG
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 5
                }

                UpdateEstadoPedido(json)
            }
            if (opcion === 14) {//Rechazo SEI/SG
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: 3,
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 2
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 16) {// Marcar Como Finalizada
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 11
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 17) {// Marcar Como Finalizada Soporte Tecnico
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: (data?.descripcionTrabajoST?.length !== 0) ? data?.descripcionTrabajoST : null, 
                    estado: 14
                }
                UpdateEstadoPedido(json)
                FinalizarST(json)
            }

            reset()
            toggleModal(0)
        },
        onSubmitEnviarRevision = (data) => {
            if (opcion === 20) {
                const json = {
                    id: (oneData) ? oneData.id : null
                }

                EnviarARevision(json)
            }

            reset()
            toggleModal(0)
        },
        asignarGestion = (data) => {
            if (allDataAsignaciones && allDataAsignaciones?.value !== 2009 && allDataAsignaciones?.some(item => item?.idPersona === data?.labelPilotos?.value) === true) {

                AsignarGestTransporte(null, 0)
            } else {
                if (opcion === 15) {//Asignar Gestión Personal Transporte
                    const json = {
                        id: (oneData) ? oneData.id : null,
                        persona: data?.labelPilotos?.value ?? null,
                        vehiculo: data?.labelVehiculos?.value ?? null,
                        tipoRechazo: ((data?.descripcionAsignar?.length !== 0) ? 9 : 3) ?? null,
                        descripcion: (data?.descripcionAsignar?.length !== 0) ? data?.descripcionAsignar : null,
                        estado: 8
                    }
                    AsignarGestTransporte(json)
                    UpdateEstadoPedido(json)
                }
                reset({ descripcionAsignar: '', labelVehiculos: '', labelPilotos: '' })
            }
            // toggleModal(0)
        };

    const prevInputTipoRechazo = useRef(null);

    useEffect(() => {
        if (!isNaN(inputTipoRechazo)) {
            setTipoRechazo(Number(inputTipoRechazo));
            prevInputTipoRechazo.current = inputTipoRechazo;
        }
    }, [inputTipoRechazo]);
    // console.log(idModalidad, 'idTipoActividad')
    return (
        <Fragment>
            {/* <Button onClick={() => toggleModal(1)} id="crear" color="primary" size="sm">
                <Icon.Plus size={16} />
            </Button>
            <UncontrolledTooltip placement='left' target='crear' placementPrefix="bs-tooltip">
                Crear
            </UncontrolledTooltip> */}

            {opcion > 0 && opcion < 5 && <Modal
                isOpen={modal}
                backdrop='static'
                style={{ maxWidth: '1600px', width: '80%', margin: '10px auto' }}
                toggle={() => toggleModal()}
                className='modal-dialog-centered modal-xl'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}> {opcion === 1 ? "Crear" : opcion === 3 ? "Actualizar" : "Visualizar"}{` ${nameComponent}`}</ModalHeader>
                <ModalBody style={(opcion === 1 ? {} : { backgroundColor: 'rgb(0 0 0 / 7%)', paddingBottom: '0' })}>
                    <Header />
                    {(oneData && oneData.id) &&
                        <ContentProvider>
                            <Detalle oneData={oneData} agregarRenglon={agregarRenglon} setAgregarRenglon={setAgregarRenglon} />
                        </ContentProvider>
                    }
                </ModalBody>
                {/* <ModalFooter>
                    <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                        <Icon.CornerUpLeft size={16} />
                    </Button>
                    <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                        Salir
                    </UncontrolledTooltip>
                </ModalFooter> */}
            </Modal>}
            {/* Opcion Ver PROCESO */}
            {opcion === 6 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered modal-xl'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>Proceso</ModalHeader>
                <Form autoComplete="off"
                >
                    <ModalBody>
                        <>
                            <Row>
                                <Col lg="12">
                                    <div>
                                        <CardBody>
                                            <CardTitle className="mb-4 text-center">Tiempo transcurrido: {DataBitacoraPedido && DataBitacoraPedido[DataBitacoraPedido.length - 1]?.tiempoTranscurrido} || Solicitado:   <Moment locale="es" format='lll' fromNow>{DataBitacoraPedido && DataBitacoraPedido[0]?.fechaCreado}</Moment></CardTitle>
                                            {/* <h5 className="mb-4">Línea de Tiempo</h5> */}

                                            <div className="hori-timeline">
                                                <Swiper
                                                    slidesPerView={1}
                                                    // spaceBetween={10}
                                                    navigation
                                                    pagination={{
                                                        clickable: true,
                                                    }}
                                                    breakpoints={{
                                                        678: {
                                                            slidesPerView: 2,
                                                        },
                                                        992: {
                                                            slidesPerView: 3,
                                                        },
                                                        1400: {
                                                            slidesPerView: 4,
                                                        }
                                                    }}
                                                    loop={false}
                                                    modules={[Pagination, Navigation]}
                                                    className="owl-carousel owl-theme  navs-carousel events"
                                                    id="timeline-carousel"
                                                >
                                                    {DataBitacoraPedido && DataBitacoraPedido?.map((item, index) => (
                                                        <SwiperSlide
                                                            key={index}
                                                            className="item event-list"
                                                            style={{ display: "inline-table" }}
                                                        >
                                                            <div>
                                                                <div className="event-date">
                                                                    <div className="text-primary mb-1">
                                                                        <Moment locale="es" format='lll' fromNow>{item?.creado}</Moment>
                                                                    </div>
                                                                    <h5 className="mb-4">{item?.estadoTexto}</h5>
                                                                </div>
                                                                <div className="event-down-icon">
                                                                    <i className={`bx ${item?.estadoTexto === 'Rechazado' ? 'bx-x-circle text-danger' : 'bx-check-circle text-success'} h1  down-arrow-icon`} />
                                                                </div>

                                                                <div className="mt-3 px-3">
                                                                    <p className="text-muted">
                                                                        {item?.usuario}
                                                                    </p>
                                                                </div>
                                                                <div className="mt-3 px-3">
                                                                    <p className="text-muted">
                                                                        <strong>{item?.rechazoTipoTexto}</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}

                                                </Swiper>
                                            </div>
                                        </CardBody>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Regresar
                        </UncontrolledTooltip>
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Ver PDF */}
            {opcion === 5 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered modal-xl'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>PDF</ModalHeader>
                <Form autoComplete="off"
                >
                    <ModalBody>
                        <Row>
                            <Col >
                                <object
                                    className='Visor'
                                    width='100%'
                                    height='600vh'
                                    id='pdf-ficha'
                                    aria-labelledby='perfil'
                                    type='application/pdf'
                                    // data={`https://s1.q4cdn.com/806093406/files/doc_downloads/test.pdf`}
                                    data={`${pdf}`}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Regresar
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <a className="btn btn-success " id="imprimir" download="pedido.pdf" href={`${pdf}`} target="_blank" rel="noopener noreferrer"><Icon.Download size={16} /> Descargar</a>
                                {/* <Button color='success' type="submit" id="accion">
                                    <Icon.Printer size={16} />
                                </Button> */}
                                {/* <UncontrolledTooltip placement='top' target='imprimir' placementPrefix="bs-tooltip">
                                    Imprimir
                                </UncontrolledTooltip> */}
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}

            {/* Opcion Aprobar Jefe Transporte */}
            {opcion === 9 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 9 && 'Aprobar'} Gestión</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                    <ModalBody>
                        <Row >
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    ¿Está seguro de que todo está bien y desea proceder?
                                </Text>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 9 && 'Aprobar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Rechazar Jefe Transporte */}
            {opcion === 10 && <Modal
                isOpen={modal}
                backdrop='static'
                style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 10 && 'Rechazar'}  Gestión</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                    <ModalBody>

                        {/* {tipoRechazo === 3 && */}
                        <Row>
                            <Col >
                                <FormGroup>
                                    <Label for="descripcion" className="">* Especifique el motivo de Rechazo</Label>
                                    <Controller

                                        control={control}
                                        id='descripcion'
                                        name='descripcion'
                                        render={({ field }) => (
                                            <Input
                                                rows={2}
                                                type='textarea'
                                                // readOnly={updateData}
                                                invalid={errors.descripcion && true}
                                                defaultValue=''
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.descripcion && errors.descripcion.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        {/* } */}
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Save size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 10 && 'Operar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Aprobar Tecnica */}
            {opcion === 11 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 11 && 'Aprobar'} Gestión</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                    <ModalBody>
                        <Row >
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    ¿Está seguro de que todo está bien y desea proceder?
                                </Text>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Save size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 11 && 'Operar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Rechazar Tecnica */}
            {opcion === 12 && <Modal
                isOpen={modal}
                backdrop='static'
                style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 12 && 'Rechazar'}  Gestión</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                    <ModalBody>

                        {/* {tipoRechazo === 3 && */}
                        <Row>
                            <Col >
                                <FormGroup>
                                    <Label for="descripcion" className="">* Especifique el motivo de Rechazo</Label>
                                    <Controller

                                        control={control}
                                        id='descripcion'
                                        name='descripcion'
                                        render={({ field }) => (
                                            <Input
                                                rows={2}
                                                type='textarea'
                                                // readOnly={updateData}
                                                invalid={errors.descripcion && true}
                                                defaultValue=''
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.descripcion && errors.descripcion.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        {/* } */}
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Save size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 12 && 'Operar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Aprobar Planificacion  */}
            {opcion === 13 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 13 && 'Aprobar'} Gestión</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                    <ModalBody>
                        <Row >
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    ¿Está seguro de que todo está bien y desea proceder?
                                </Text>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 13 && 'Aprobar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Rechazar Planificacion */}
            {opcion === 14 && <Modal
                isOpen={modal}
                backdrop='static'
                style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 14 && 'Rechazar'} esta solicitud de compra?</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                    <ModalBody>

                        {/* {tipoRechazo === 3 && */}
                        <Row>
                            <Col >
                                <FormGroup>
                                    <Label for="descripcion" className="">* Especifique el motivo de Rechazo</Label>
                                    <Controller

                                        control={control}
                                        id='descripcion'
                                        name='descripcion'
                                        render={({ field }) => (
                                            <Input
                                                rows={2}
                                                type='textarea'
                                                // readOnly={updateData}
                                                invalid={errors.descripcion && true}
                                                defaultValue=''
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.descripcion && errors.descripcion.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        {/* } */}
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Save size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 14 && 'Operar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion 15 Asigna Piloto transportes */}
            {opcion === 15 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                size='llg'
                className='modal-dialog-centered '
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 15 && 'Asignar'} Gestión</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(asignarGestion)}>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Row>
                                    {idModalidad === 1 ?
                                        <Col>
                                            {
                                                <FormGroup>
                                                    <Label for="labelPilotos" className="d-flex justify-content-between">
                                                        <span>*  Piloto</span>
                                                    </Label>
                                                    <Controller
                                                        name="labelPilotos"
                                                        control={control}
                                                        render={({ field }) => {
                                                            return (
                                                                <Select
                                                                    {...field}
                                                                    isClearable
                                                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                                    defaultValue={null}
                                                                    options={labelPilotos}
                                                                    // theme={selectThemeColors}
                                                                    // isDisabled={!allMedidaInsumo}
                                                                    className='selector-react1'
                                                                    classNamePrefix='select'
                                                                    placeholder={'Seleccione una opción'}
                                                                    noOptionsMessage={() => 'sin resultados'}
                                                                    styles={(!!errors.labelPilotos) ? customStyles : {}}
                                                                />
                                                            )
                                                        }
                                                        }
                                                        rules={
                                                            {
                                                                required: {
                                                                    value: true,
                                                                    message: 'Este campo es requerido'
                                                                }
                                                            }
                                                        }
                                                    />
                                                    <small className="text-danger">
                                                        {!!errors.labelPilotos && errors.labelPilotos.message}
                                                    </small>
                                                </FormGroup>
                                            }
                                        </Col>
                                        : null}
                                    <Col>
                                        {
                                            <FormGroup>
                                                <Label for="labelVehiculos" className="d-flex justify-content-between">
                                                    <span>* Vehículo</span>
                                                </Label>
                                                <Controller
                                                    name="labelVehiculos"
                                                    control={control}
                                                    render={({ field }) => {
                                                        return (
                                                            <Select
                                                                {...field}
                                                                isClearable
                                                                onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                                onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                                defaultValue={null}
                                                                options={labelVehiculos}
                                                                // theme={selectThemeColors}
                                                                // isDisabled={!allMedidaInsumo}
                                                                className='selector-react1'
                                                                classNamePrefix='select'
                                                                placeholder={'Seleccione una opción'}
                                                                noOptionsMessage={() => 'sin resultados'}
                                                                styles={(!!errors.labelVehiculos) ? customStyles : {}}
                                                            />
                                                        )
                                                    }
                                                    }
                                                    rules={
                                                        {
                                                            required: {
                                                                value: true,
                                                                message: 'Este campo es requerido'
                                                            }
                                                        }
                                                    }
                                                />
                                                <small className="text-danger">
                                                    {!!errors.labelVehiculos && errors.labelVehiculos.message}
                                                </small>
                                            </FormGroup>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col >
                                        <FormGroup>
                                            <Label for="descripcionAsignar" className="">Observaciones (opcional)</Label>
                                            <Controller
                                                control={control}
                                                id='descripcionAsignar'
                                                name='descripcionAsignar'
                                                render={({ field }) => (
                                                    <Input
                                                        rows={2}
                                                        type='textarea'
                                                        // readOnly={updateData}
                                                        // invalid={errors.descripcion && true}
                                                        defaultValue=''
                                                        {...field}
                                                    />
                                                )
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={2} xl={2} className="align-self-center text-center">
                                <Fragment>
                                    <Button color='success' type="submit" id="accion">
                                        <Icon.CheckSquare size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                        {opcion === 15 && 'Asignar'}
                                    </UncontrolledTooltip>
                                </Fragment>
                            </Col>
                        </Row>
                        {idModalidad === 1 ? <ListAsignados /> : null}
                        {idModalidad === 2 ? <ListAsigVeh /> : null}
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {/* {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Send size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 15 && 'Enviar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        } */}
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Enviar a Revisión Vista Previa */}
            {opcion === 16 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 16 && 'Marcar la'} Gestión como finalizada</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                    <ModalBody>
                        <Row >
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    ¿Está seguro de que todo está bien y desea proceder?
                                </Text>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Send size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 16 && 'Marcar como finalizado'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Enviar a Revisión Vista Previa */}
            {opcion === 17 && <Modal
                isOpen={modal}
                backdrop='static'
                size='lg'
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 17 && 'Marcar la'} Gestión como finalizada</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                    <ModalBody>
                        <Row >
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    ¿Está seguro de que la gestión <strong>{oneData && oneData?.correlativo}</strong> es la correcta y desea proceder?
                                </Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for='descripcionTrabajoST' className="d-flex justify-content-between">* Descripción de Trabajo Realizado <small>(Máximo 500 caracteres)</small></Label>
                                    <Controller

                                        control={control}
                                        id='descripcionTrabajoST'
                                        name='descripcionTrabajoST'
                                        render={({ field }) => (
                                            <Input
                                                rows={3}
                                                type='textarea'
                                                // readOnly={opcion === 2}
                                                // disabled={updateData}
                                                maxLength={500}
                                                invalid={errors.descripcionTrabajoST && true}
                                                defaultValue={null}
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.descripcionTrabajoST && errors.descripcionTrabajoST.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Send size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 17 && 'Marcar como finalizado'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Enviar a Revisión Vista Previa */}
            {opcion === 20 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 20 && 'Enviar la'} Gestión?</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEnviarRevision)}>
                    <ModalBody>
                        <Row >
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    ¿Está seguro de que todo está bien y desea proceder?
                                </Text>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Send size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 20 && 'Enviar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
        </Fragment >
    )
}

export default Formulario