
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const handleSwtAlert = (data, nameFunction) => {
    const MySwal = withReactContent(Swal)

    MySwal.fire({
        title: '¿Está Seguro?',
        text: `Esta acción ${data.idEstado === 1 ? 'desactivará' : 'activará'} este registro`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#50cd89',
        confirmButtonText: 'Sí, estoy seguro!',
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#f1416c',
        reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {
            nameFunction(data)
            Swal.fire(
                `${data.idEstado === 1 ? 'Desactivado' : 'Activado'}!`,
                `El registro ha sido ${data.idEstado === 1 ? 'desactivado' : 'activado'} exitosamente.`,
                'success'
            )
        }
    });
}

export const confirmAutorizarRechazar = (data, nameFunction, estado) => {
    const MySwal = withReactContent(Swal)

    MySwal.fire({
        title: '¿Está Seguro?',
        text: `Esta acción ${estado === 2 ? 'Autorizará' : 'Rechazara'} este registro`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#50cd89',
        confirmButtonText: 'Sí, estoy seguro!',
        cancelButtonText: 'Cancelar',
        cancelButtonColor: '#f1416c',
        reverseButtons: true
    }).then((result) => {
        if (result.isConfirmed) {
            nameFunction(data, estado)
            Swal.fire(
                `${estado === 2 ? 'Autorizará' : 'Rechazara' }!`,
                `El registro ha sido ${estado === 2 ? 'desactivado' : 'activado'} exitosamente.`,
                'success'
            )
        }
    });
}

export const handleSwtAlertRecepcion = (data, nameFunction) => {
  const MySwal = withReactContent(Swal)

  MySwal.fire({
    title: '¿Está Seguro?',
    text: `Esta acción ${data.idEstado === 1 ? 'Retornara' : 'Pondra En transito'} este registro`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#50cd89',
    confirmButtonText: 'Sí, estoy seguro!',
    cancelButtonText: 'Cancelar',
    cancelButtonColor: '#f1416c',
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      nameFunction(data)
      Swal.fire(
        `${data.idEstado === 1 ? 'Retornado' : 'Puesto en transito'}!`,
        `El registro ha sido ${data.idEstado === 1 ? 'Retornado' : 'Puesto en transito'} exitosamente.`,
        'success'
      )
    }
  })
}
export const handleSwtAlertPublicar = (data, nameFunction) => {
  const MySwal = withReactContent(Swal)

  MySwal.fire({
    title: '¿Está Seguro?',
    text: `Esta acción ${data.idEstadoPublicacion === 1 ? 'Retira' : 'Publicará'} esta micro cápsula`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#50cd89',
    confirmButtonText: 'Sí, estoy seguro!',
    cancelButtonText: 'Cancelar',
    cancelButtonColor: '#f1416c',
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      nameFunction(data)
      Swal.fire(
        `${data.idEstadoPublicacion === 1 ? 'Ya no es publica' : 'Ya es publica'}!`,
        `La micro capsula ha sido ${data.idEstadoPublicacion === 0 ? 'Publicada' : 'Retirada del Público'} exitosamente.`,
        'success'
      )
    }
  })
}

export default {handleSwtAlert, confirmAutorizarRechazar, handleSwtAlertRecepcion, handleSwtAlertPublicar}
