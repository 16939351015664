import React from "react";
import { ContentProvider } from './context'
import Formulario from './form'
// import List from './list'
const Index = () => {
 

    return (
        <>
            <ContentProvider>
                <div className="page-content">
                    <Formulario />
                </div>
            </ContentProvider>
        </>
    );
}

export default Index