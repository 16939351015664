import React, { Fragment, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { Row, Col, FormGroup, FormFeedback } from 'reactstrap';
import { useForm, Controller } from "react-hook-form";
import * as Icon from 'react-feather';
import { ContentContext } from './context';
import { ContentContext as ContentContextTheme } from '../../../../components/CommonForBoth/TopbarDropdown/context';
import DatePicker from "react-datepicker";
import classnames from 'classnames';

const List = () => {
  const { allData, One, Actions, opcion, startDate, setStartDate } = useContext(ContentContext);
  const { theme } = useContext(ContentContextTheme);
  const [searchValue, setSearchValue] = useState('');
  const { handleSubmit, formState: { errors }, control } = useForm();
  const [filteredData, setFilteredData] = useState([]);

  const Columns = [
    {
      name: 'Seleccionar',
      selector: 'id',
      sortable: true,
      center: true,
      grow: 0.5,
      cell: row => (
        <Fragment>
          <div className={`btn-icon px-2 cursor-pointer`} color='success' id='Actualizar' style={{ outline: 'none' }} size="sm"
            onClick={() => [One(row, 12)]}>
            <Icon.CheckSquare size={22} />
          </div>
        </Fragment>
      )
    },
    {
      name: 'No.Renglon',
      selector: 'renglon',
      sortable: true,
      center: false,
      grow: 4,
      wrap: true,
      cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.renglon}</div>
    },
    {
      name: 'Fuente',
      selector: 'financiamiento',
      sortable: true,
      center: false,
      grow: 2,
      wrap: true,
      cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.financiamiento}</div>
    },
    {
      name: 'Inicial',
      selector: 'costoInicialConQ',
      sortable: true,
      center: false,
      grow: 2,
      wrap: true,
      cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.costoInicialConQ}</div>
    },
    {
      name: 'Comprometido',
      selector: 'costoEstimadoConQ',
      sortable: true,
      center: false,
      grow: 2,
      wrap: true,
      cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.costoEstimadoConQ}</div>
    },
    {
      name: 'Saldo',
      selector: 'saldoDisponibleConQ',
      sortable: true,
      center: false,
      grow: 2,
      wrap: true,
      cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.saldoDisponibleConQ}</div>
    },
    {
      name: 'Estimado',
      selector: 'saldoCostoPedidoConQ',
      sortable: true,
      center: false,
      grow: 2,
      wrap: true,
      cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.saldoCostoPedidoConQ}</div>
    },
    {
      name: 'Real',
      selector: 'saldoRealConQ',
      sortable: true,
      center: false,
      grow: 2,
      wrap: true,
      cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.saldoRealConQ}</div>
    }
  ];

  const data = (((allData && allData?.response !== 2009) || (allData && allData?.response !== 1002)) ? allData : []);

  return (
    <Fragment>
      <Row className='justify-content-end mx-0' style={{ height: data && data?.length === 0 ? '25vh' : 'auto' }}
      >
        <Col>
          <FormGroup>
            <Controller
              name='año'
              control={control}
              render={() => {
                return (
                  <DatePicker
                    className={classnames('form-control invoice-edit-input due-date-picker', {
                      'is-invalid': errors.año && true
                    })}
                    selected={startDate}
                    disabled={opcion === 2 || opcion === 4}
                    locale="es"
                    onChange={(año) => setStartDate(año)}
                    showYearPicker
                    name='año'
                    id="año"
                    dateFormat="yyyy"
                  />
                );
              }}
            />
            <FormFeedback>
              {!!errors.año && errors.año.message}
            </FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <div className='card'>
        <DataTable
          striped
          noHeader
          highlightOnHover
          pagination
          theme={theme}
          data={data}
          columns={Columns}
          className='table-responsive mt-4'
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          paginationPerPage={100}
          paginationComponentOptions={{
            rowsPerPageText: '',
            rangeSeparatorText: ''
          }}
          noDataComponent='Sin Registros'
        />
      </div>
    </Fragment>
  );
};

export default List;
