
import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { ContentContext } from './context'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback } from 'reactstrap'
import { useForm, Controller } from "react-hook-form"
import * as Icon from 'react-feather'
import DatePicker, { registerLocale } from "react-datepicker"
import Select from 'react-select'
import { formatDate, customStyles, stringToDate } from '../../../Globales/index'
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput'
import classnames from 'classnames'
import { Text } from "@nextui-org/react";
import Objetivos from '../ObjetivosEstrategicos/index'
import { ContentProvider } from '../ObjetivosEstrategicos/context'
import revision from '../../../assets/images/Checklist.png'
import es from "date-fns/locale/es"
registerLocale("es", es);

const Formulario = () => {
    // const [startDate, setStartDate] = useState(Date.parse(2019) + 1);
    const [startDate, setStartDate] = useState(new Date());
    const { toggleModal, modal, StoreUpdate, opcion, oneData, labelUnidades, setId, presupuestoActual, labelEjes, labelFinanciamiento, idPresupuestoPoa } = useContext(ContentContext),
        [updateData, setUpdateData] = useState(false),
        { handleSubmit, formState: { errors }, setValue, control, reset, watch } = useForm(),
        seleccionableUnidad = watch('unidad'),
        onSubmit = (data) => {
            const json = {
                id: oneData?.id ?? idPresupuestoPoa ?? null,
                monto: data.monto.replace('Q', '').replace(',', '').replace(',', ''),
                unidad: data.unidad.value,
                año: startDate,
                financiamiento: data.financiamiento.value
            }
            StoreUpdate(json)
            setUpdateData(true)
        },
        setData = async () => {
            await setValue('monto', oneData.monto)
            await setValue('anio', new Date(stringToDate(oneData.anio2, "dd/MM/yyyy", "/")))
            await setValue('unidad', { label: oneData.unidad, value: oneData.idUnidad })
            await setValue('financiamiento', { label: oneData.financiamiento, value: oneData.idFinanciamiento })

        },
        clearData = async () => {
            reset({ monto: '', unidad: '', financiamiento: '' })
        },
        setEstado = (estado) => {
            setUpdateData(estado)
        },
        habilitarEdicion = (e, estado) => {
            e.preventDefault()
            setEstado(estado)
        }

    useEffect(
        () => {
            if (oneData) {
                setUpdateData(true)
            }
        }, [oneData]
    )
    useEffect(
        () => {
            async function fetchMyAPI() {
                if (await opcion > 1 && oneData) { await setData() }
                if (await opcion === 1) { clearData(), setUpdateData(false) }
            }

            fetchMyAPI()

        }, [opcion, oneData]
    )

    // useEffect(() => {
    //     let abortController = new AbortController();
    //     async function fetchMyAPI() {
    //         if (seleccionableUnidad) {
    //             setValue('monto', '')
    //             setId(seleccionableUnidad.value)
    //         }
    //         if (seleccionableUnidad === null || seleccionableUnidad === '') {
    //             setId(0);
    //             setValue('monto', '')
    //         }
    //     }
    //     fetchMyAPI()
    //     return () => { abortController.abort(); }
    // }, [seleccionableUnidad])

    // useEffect(() => {
    //     let abortController = new AbortController();
    //     async function fetchMyAPI() {
    //         if (presupuestoActual && presupuestoActual !== null && presupuestoActual.at(0) && presupuestoActual.at(0).montoActualTotal) {
    //             setValue('monto', presupuestoActual && presupuestoActual.at(0) && presupuestoActual.at(0).montoActualTotal)
    //         }
    //     }
    //     fetchMyAPI()
    //     return () => { abortController.abort(); }
    // }, [presupuestoActual])

    return (
        <Fragment>
            <Button onClick={() => toggleModal(1)} id="crear" color="primary" size="sm">
                <Icon.Plus size={16} />
            </Button>
            <UncontrolledTooltip placement='left' target='crear' placementPrefix="bs-tooltip">
                Crear
            </UncontrolledTooltip>

            {opcion > 0 && opcion < 5 && <Modal
                isOpen={modal}
                backdrop='static'
                size="xl"
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 1 && 'crear'} Presupuesto Inicial</ModalHeader>
                <ModalBody>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col>
                                <FormGroup >
                                    <Label >* Unidad</Label>
                                    <Controller
                                        name="unidad"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                    isDisabled={updateData}
                                                    options={labelUnidades}
                                                    className='selector-react1'
                                                    classNamePrefix='select'
                                                    placeholder={'Seleccione una opción'}
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={(!!errors.unidad) ? customStyles : {}}

                                                />
                                            )
                                        }
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <small className="text-danger">{!!errors.unidad && errors.unidad.message}</small>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for='monto' className="">* Monto</Label>
                                    <Controller
                                        defaultValue=''
                                        control={control}
                                        id='monto'
                                        name='monto'
                                        render={({ field }) => (
                                            <CurrencyInput placeholder="Q. 0.00" type="text"
                                                // className="form-control"
                                                className={classnames('form-control  invoice-edit-input due-date-picker', {
                                                    'is-invalid': errors.monto && true
                                                })}
                                                disabled={updateData}
                                                // invalid={errors.monto && true}
                                                {...field}
                                            />
                                            // <Input
                                            //     type='number'
                                            //     step="0.00"
                                            //     disabled={updateData}
                                            //     min={1}
                                            //     // max={presupuestoActual && presupuestoActual[0] && presupuestoActual[0].montoActualTotal ? presupuestoActual[0].montoActualTotal : 0}
                                            //     invalid={errors.monto && true}
                                            //     {...field}
                                            // />
                                        )
                                        }
                                        rules={
                                            {

                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.monto && errors.monto.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="año">* Año</Label>
                                    <Controller
                                        name='año'
                                        control={control}
                                        render={() => {
                                            return (
                                                < DatePicker
                                                    className={classnames('form-control  invoice-edit-input due-date-picker', {
                                                        'is-invalid': errors.año && true
                                                    })}
                                                    selected={startDate}
                                                    disabled={updateData}
                                                    onChange={(año) => setStartDate(año)}
                                                    showYearPicker
                                                    locale="es"
                                                    name='año'
                                                    id="año"
                                                    dateFormat="yyyy"
                                                />
                                            )
                                        }
                                        }
                                    // rules={
                                    //     {
                                    //         required: {
                                    //             value: true,
                                    //             message: 'Este campo es requerido'
                                    //         }
                                    //     }
                                    // }
                                    />
                                    <FormFeedback>
                                        {!!errors.año && errors.año.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="financiamiento" className="d-flex justify-content-between">
                                        <span>* Fuente</span>
                                    </Label>
                                    <Controller
                                        name="financiamiento"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                    defaultValue={null}
                                                    options={labelFinanciamiento}
                                                    // theme={selectThemeColors}
                                                    // isDisabled={!allMedidaInsumo}
                                                    isDisabled={updateData}
                                                    classNamePrefix='select'
                                                    className='selector-react2'
                                                    placeholder={'Seleccione una opción'}
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={(!!errors.financiamiento) ? customStyles : {}}
                                                />
                                            )
                                        }
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <small className="text-danger">
                                        {!!errors.financiamiento && errors.financiamiento.message}
                                    </small>
                                </FormGroup>
                            </Col>
                            <Col lg={2} xl={2} className="align-self-center text-center">
                                {
                                    updateData &&
                                    <Fragment>
                                        <Button id='Actualizar1' color="warning" size="sm" type="button" className="text-center  me-2" onClick={(e) => habilitarEdicion(e, false)}>
                                            <Icon.Edit size={16} />
                                        </Button>
                                        <UncontrolledTooltip placement='top' target='Actualizar1'>
                                            Actualizar Presupuesto
                                        </UncontrolledTooltip>
                                    </Fragment>
                                }
                                {
                                    !updateData &&
                                    <Fragment>
                                        <Button color="success" size="sm" type="submit" className="text-center me-2" id="Guardar">
                                            <Icon.Save size={16} />
                                        </Button>
                                        <UncontrolledTooltip placement='top' target='Guardar'>
                                            Guardar
                                        </UncontrolledTooltip>
                                        {
                                            (((oneData && oneData.id)) && !updateData) &&
                                            <Fragment>
                                                <Button color="danger" size="sm" type="button" className="text-center" id="Cancelar1" onClick={(e) => habilitarEdicion(e, true)}>
                                                    <Icon.Slash size={16} />
                                                </Button>
                                                <UncontrolledTooltip placement='top' target='Cancelar1'>
                                                    Cancelar
                                                </UncontrolledTooltip>
                                            </Fragment>
                                        }
                                    </Fragment>
                                }

                            </Col>
                        </Row>
                    </Form>
                    {idPresupuestoPoa !== 0 &&
                        <>
                            <div className="text-center">
                                <hr />
                                <span className="text-dark">Objetivo Estratégico</span>
                            </div>
                            <ContentProvider>
                                <Objetivos />
                            </ContentProvider>

                        </>}
                </ModalBody>
                <ModalFooter>
                    <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                        <Icon.CornerUpLeft size={16} />
                    </Button>
                    <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                        Salir
                    </UncontrolledTooltip>
                </ModalFooter>
            </Modal>}
            {
                opcion === 5 &&
                <Modal
                    isOpen={modal}
                    backdrop='static'
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}> Enviar a Revisión</ModalHeader>
                    <Form autoComplete="off"
                    // onSubmit={handleSubmit(onSubmitEstado)}
                    >
                        <ModalBody>
                            <Row >
                                <Col className="text-center">
                                    <img className="card-img img-fluid text-center" style={{ height: '250px', width: '270px' }} src={revision} />
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                    >
                                        Esta a punto de enviar este presupuesto a revisión, ¿Esta seguro?
                                    </Text>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            {
                                opcion !== 2 &&
                                <Fragment>
                                    <Button color='success' type="submit" id="accion">
                                        <Icon.CheckSquare size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                        {opcion === 5 && 'Si estoy seguro, Enviar'}
                                    </UncontrolledTooltip>
                                </Fragment>
                            }
                        </ModalFooter>
                    </Form>
                </Modal>
            }
        </Fragment >
    )
}

export default Formulario