import React, { Fragment, useState, useContext } from 'react'
import DataTable from 'react-data-table-component'
import { Row, Col, FormGroup, Input, UncontrolledButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap'
import * as Icon from 'react-feather'
import { CustomText, EstadoLista } from '../../../CustomList/index'
import { ContentContext } from './context'
import { ContentContext as ContentContextTheme } from '../../../components/CommonForBoth/TopbarDropdown/context'

const List = () => {

  const { allData, One, Actions } = useContext(ContentContext)
  const { theme } = useContext(ContentContextTheme)
  const [searchValue, setSearchValue] = useState(''),
    [filteredData, setFilteredData] = useState([]),
    handleFilter = e => {
      const value = e.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      setSearchValue(value);
      let updatedData = []


      if (value?.length) {
        const updatedData = (allData && allData?.value !== 2009) ? allData?.filter(item => {
          const correlativo = item?.correlativo?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          const correlativoHistorico = item?.correlativoHistorico?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          const creadoPor = item?.creadoPor2?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          const estado = item?.estado?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          const siguientePaso = item?.siguientePaso?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");



          return correlativo?.includes(value) || correlativoHistorico?.includes(value) || creadoPor?.includes(value) || estado?.includes(value) || siguientePaso?.includes(value);
        }) : [];

        setFilteredData(updatedData)
        setSearchValue(value)
      }
      if (value === '') {
        setFilteredData([])
      }
    },
    Columns = [
      {
        name: 'Correlativo',
        column: 'correlativo',
        sortable: true,
        center: false,
        cell: row => CustomText(row?.correlativo === 'N/A' ? row['correlativoHistorico']  :  row['correlativo'])
      },
      {
        name: 'Descargado Por',
        column: 'creadoPor',
        sortable: true,
        center: false,
        cell: row => CustomText(row['creadoPor'])
      },
      {
        name: 'Fecha',
        column: 'creado3',
        sortable: true,
        center: false,
        cell: row => CustomText(row['creado3'])
      }
      // ,
      // {
      //   name: 'Descargado Por',
      //   column: 'estado',
      //   sortable: true,
      //   center: true,
      //   width: '200px',
      //   cell: row => <EstadoLista row={row} />
      // },
      // {
      //   name: 'Acciones',
      //   column: 'id',
      //   sortable: true,
      //   center: true,
      //   // width: '150px',
      //   cell: row => (
      //     <UncontrolledButtonDropdown style={{ margin: 0 }} >

      //       <DropdownToggle style={{ margin: 0 }} className='pr-1' tag='span'>
      //         <Icon.MoreVertical size={15} />
      //       </DropdownToggle>
      //       <DropdownMenu className="!m-0" style={{ margin: 0 }} >

      //         <DropdownItem className="w-100" onClick={() => One(row, 2)}>
      //           <Icon.Eye className="me-2" size={15} />
      //           <span className='align-middle ml-50'>Visualizar</span>
      //         </DropdownItem>

      //         {
      //           row.estado === 1 &&
      //           <DropdownItem className="w-100" onClick={() => One(row, 3)}>
      //             <Icon.Edit className="me-2" size={15} />
      //             <span className='align-middle ml-50'>Actualizar</span>
      //           </DropdownItem>
      //         }

      //         <DropdownItem className="w-100" onClick={() => Actions(row)}>
      //           {row.estado === 1 ? <Icon.Trash className="me-2" size={15} /> : <Icon.Check className="me-2" size={15} />}
      //           <span className='align-middle ml-50'>{row.estado === 1 ? 'Desactivar' : 'Activar'}</span>
      //         </DropdownItem>
      //       </DropdownMenu>
      //     </UncontrolledButtonDropdown>
      //   )
      // }
    ]

  return (
    <Fragment>
      <Row className='justify-content-end mx-0'>
        <Col>

        </Col>
        <Col className='justify-content-end mt-1' md='4' sm='12'>
          <FormGroup>
            <Input
              className='dataTable-filter mb-50'
              type='text'
              bsSize='md'
              placeholder={`Buscar`}
              id='search-input'
              value={searchValue}
              onChange={handleFilter}
            />
          </FormGroup>
        </Col>
      </Row>
      <div className='card'>
        <DataTable
          // dense
          striped
          noHeader
          highlightOnHover
          pagination
          theme={theme}
          data={searchValue.length ? filteredData : (allData && allData.value !== 2009 ? allData : [])}
          columns={Columns}
          className='table-responsive mt-4'
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          paginationPerPage={100}
          paginationComponentOptions={
            {
              rowsPerPageText: '',
              rangeSeparatorText: ''
            }
          }
          noDataComponent='Sin Registros'
        /></div>
    </Fragment>
  )
}

export default List