import React, { useEffect, useContext } from 'react'
import { customStyles } from '../../../Globales/index'
// import { Notification } from '../../../../components/notify/index'
import { Button, Row, Col, Form, Label, FormGroup } from "reactstrap"
import { useForm, Controller } from "react-hook-form"
import { ContentContext } from './context'
import * as Icon from 'react-feather'
import Select from 'react-select'
import { OptionsToast } from '../../../Globales/index'
import { toast } from 'react-toastify'
// ** Utils
// import { selectThemeColors } from '@utils'

const Formulario = () => {
    const { handleSubmit,  watch, formState: { errors }, control } = useForm(),
          { dataEmpleados: empleados, getRolUsuario, setShowRoles } = useContext(ContentContext),
          EmpleadoOne = watch('persona'),
          onSubmit = (data) => {
            if (EmpleadoOne) {
              getRolUsuario({persona: data.persona.value, usuario: data.persona.usuario})
            } else {
              // Notification('Debe seleccionar una persona para asignar roles', 2)
              toast.warning('Debe seleccionar una persona para asignar roles', OptionsToast)
            }
          }

          useEffect(
            () => {
                if (EmpleadoOne) {
                    setShowRoles(false)
                  }
                if (EmpleadoOne === null || EmpleadoOne === undefined || EmpleadoOne === '') {
                    setShowRoles(false)
                  }
            }, [EmpleadoOne]
          )

    return (
      <Form autoComplete="off"onSubmit={handleSubmit(onSubmit)}>
          <Row className=''>
            <Col lg={4} >
              <FormGroup >
                <Label >* Empleado</Label>
                <Controller
                  name="persona"
                  control={control}
                  defaultValue={null}
                  render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          isClearable
                          onChange={field.onChange} //resuelve el tener que dar doble click en movil
                          onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                          
                          options={empleados}
                          className=''
                          classNamePrefix='select'
                          placeholder={'Seleccione una opción'}
                          noOptionsMessage={() => 'sin resultados'}
                          styles={(!!errors.persona) ? customStyles : {}}
                          
                        />
                      )
                    }
                  } 
                  rules={
                    { 
                      required: {
                        value: true,
                        message: 'Este campo es requerido'
                      } 
                    } 
                  }
                />
                <small className="text-danger">{!!errors.persona && errors.persona.message}</small>
              </FormGroup>
            </Col>

            <Col className='py-4' lg={2}>
              <Button color='primary' type="submit" className="mt-2"><Icon.Search size={16}/></Button>
            </Col>      
          </Row>
      </Form>
    )
  }
  
export default Formulario