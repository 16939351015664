import React, { createContext, useState, useContext } from 'react'
import { PostRoute } from 'services/Private'
import { OptionsToast } from 'Globales/index'
import { toast } from 'react-toastify'
import { useRequest } from 'hooks/useRequestSWR'
import { useSWRConfig } from 'swr'
import { ContentContext as ContentMeta } from '../Gestiones/context'
export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    const{ idAccion, setMutateSaldoAccion, idModalidad, idTipoSolicitudSoporte, setidTipoSolicitudSoporte,idEquipoPrestamo, setidEquipoPrestamo, oneData: oneDataALL, opcion: opcionModal } = useContext(ContentMeta);
    const { mutate } = useSWRConfig();
    const [gestion, setGestion] = useState(0);
    const [renglon, setRenglon] = useState([]);
    const nameController5 = 'GestionesDetalle';
    const nameController6 = 'Gestiones';
    const { data: allData } = useRequest(`${nameController5}/allDetalle`, 'POST', { id: gestion });
    const { data: allDataSoporte } = useRequest(`${nameController5}/allDetalleSoporte`, 'POST', { id: gestion });
    const { data: allDataPrestamo } = useRequest(`${nameController5}/allDetallePrestamo`, 'POST', { id: gestion });
    // const { data: totalDetallePedido } = useRequest(`${nameController5}/totalDetallePedido`, 'POST', { id: accion });
    // const { data: allData2 } = useRequest(`${nameController4}/one`, 'POST', { id: accion });
    // const { data: labelRenglon } = useRequest(`${nameController}/renglonesXAccion`,'POST', { accion: idAccion });
    // const { data: allInsumos } = useRequest(`${nameController6}/All`, 'POST', { renglon: renglon });
    const { data: labelTiposServiciosST } = useRequest(`${nameController6}/labelTiposServiciosST`, 'getInmutable');
    const { data: labelUbicaciones } = useRequest(`${nameController6}/labelUbicaciones`, 'getInmutable');
    // const { data: labelEquipoPrestamo } = useRequest(`${nameController6}/labelEquipoPrestamo`, 'getInmutable');
    const [btnDisable, setBtnDisable] = useState(false);
    const [actualiza, setActualiza] = useState(false),
        [modal, setModal] = useState(null),
        [oneData, setOneData] = useState([]),
        [opcion, setOpcion] = useState(0),
        OneDetalle = async (data) => {
            // setActualiza(true)
            setBtnDisable(false)
            setOneData((data) ? data : [])
        },
        toggleModal = async (data) => {
            setOpcion(data)
            if (data === 1) { setOneData(null) }
            setModal(!modal)
        },
        One = async (data, opcion) => {
            const response = await PostRoute(`${nameController5}/one`, { id: data.id })
            setOneData((response[0]) ? response[0] : [])
            setMutateSaldoAccion(true)
            toggleModal(opcion)
        },
        StoreUpdate = async (data) => {
            let response = []
            response = await PostRoute(`${nameController5}/${!data.id ? 'store' : 'update'}`, data)
            toast.success(response.message, OptionsToast)
            // toggleModal(0)
            setOneData([])
            mutate([`${nameController5}/allDetalle`, { id: gestion }])
        },
        StoreUpdateSoporte = async (data) => {
            let response = []
            response = await PostRoute(`${nameController5}/${!data.id ? 'storeSoporte' : 'updateSoporte'}`, data)
            toast.success(response.message, OptionsToast)
            // toggleModal(0)
            setOneData([])
            mutate([`${nameController5}/allDetalleSoporte`, { id: gestion }])
        },
        StoreUpdatePrestamo = async (data) => {
            let response = []
            response = await PostRoute(`${nameController5}/${!data.id ? 'storePrestamo' : 'updatePrestamo'}`, data)
            toast.success(response.message, OptionsToast)
            // toggleModal(0)
            setOneData([])
            mutate([`${nameController5}/allDetallePrestamo`, { id: gestion }])
        },
        Actions = async (data) => {
            const response = await PostRoute(`${nameController5}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data?.id })
            toast.success(response.message, OptionsToast)
            mutate([`${nameController5}/allDetalle`, { id: accion }])
            mutate([`${nameController5}/totalDetallePedido`, { id: accion }])
            setMutateSaldoAccion(true)
        },
        labelTipoVehiculo = [
            { value: 1, label: 'Autobus' },
            { value: 2, label: 'Panel' },
            { value: 3, label: 'Camión' },
            { value: 4, label: 'Pick up' }
          ],
        
        value = {
            Actions,
            One,
            actualiza,
            OneDetalle,
            StoreUpdate,
            StoreUpdateSoporte,
            setGestion,
            setOneData,
            setActualiza,
            setRenglon,
            allData,
            allDataSoporte,
            idAccion,
            // allInsumos,
            // allData2,
            btnDisable,
            // labelUnidadMedida,
            // labelRenglon,
            modal,
            oneData,
            opcion,
            // totalDetallePedido,
            idModalidad,
            labelTipoVehiculo,
            labelUbicaciones,
            // labelEquipoPrestamo,
            labelTiposServiciosST,
            idTipoSolicitudSoporte, setidTipoSolicitudSoporte,
            idEquipoPrestamo, setidEquipoPrestamo,
            oneDataALL,
            allDataPrestamo ,
            StoreUpdatePrestamo,
            opcionModal
        }

    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}
