import React, { useContext } from 'react';
import { ContentContext } from './context'
// import { POA_Carta } from '../../../../utility/FormatosPDF/POA_Carta'
import { Form, Row, Col, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap'
import logo from "../../../../assets/images/logo_oficial-1-SIN-AROS.png";
import logoSIPA from "../../../../assets/images/COG/SIPA-completo.png";
import * as Icon from 'react-feather'
import { Link } from "react-router-dom";
import revision from '../../../../assets/images/Checklist.png'
import { useForm } from "react-hook-form"

const Index = () => {
    // const [pdf, setPdf] = useState([])
    const { allData, allData2, allData3, allData4, code, opcion, toggleModal, One, modal, updatePOA, allDataOE,
        allDataOOU, poaFechaEnvio, allDataHeaderPOA } = useContext(ContentContext)
    const { handleSubmit } = useForm();
    // const
    //     Imprimir = async (data) => {
    //         const response = await POA_Carta(data)
    //         return response
    //     }

    const onSubmitEstado = (data) => {
        if (opcion === 5) {
            const json = {
                code: code,
                año: allData[0]?.anio,
                monto: allData4[0]?.totalQ.replace('Q', '').replace(',', '').replace(',', ''),
            }
            updatePOA(json)
        }

    };
    const
        Imprimir = () => {
            let printContents = document?.getElementById('printablediv')?.innerHTML;
            let originalContents = document.body.innerHTML;
            // document.body.innerHTML = printContents;
            // let w = window.open();
            // w.document.write(printContents);
            // w.document.close(); // necessary for IE >= 10
            window.focus(); // necessary for IE >= 10
            window.print();
            // document.body.innerHTML = originalContents;
            window.close();
            return true;
        };
    // useEffect(
    //     () => {
    //         async function fetchMyAPI() {
    //             const doc = await Imprimir()
    //             setPdf(doc)
    //         }
    //         fetchMyAPI()
    //     }, []
    // )

    return (
        <>
            <div id='printablediv' className='card printablediv p-5 '>
                <div className="invoice-title">
                    <Row className='align-items-center m-0 p-0'>
                        <Col >
                            <div >
                                <img src={logo} alt="logo" height="100" />
                            </div>
                        </Col>
                        <Col className='text-center'><span className='text-dark  fs-3 fw-bold'>Plan Operativo Anual</span></Col>
                        <Col>
                            <h4 className="float-end">
                                <img src={logoSIPA} alt="logo2" height="50" />
                            </h4>
                        </Col>
                    </Row>
                </div>
                <hr />
                <div>
                    <Row className='mb-0'>
                        <Col lg={12} className='text-start mb-2'>
                            <h1 className='fw-bold fs-5'>Ejes Estratégicos:</h1>
                            {
                                allDataHeaderPOA && allDataHeaderPOA?.value !== 2009
                                    ? (() => {
                                        const uniqueData = new Set();
                                        return allDataHeaderPOA
                                            ?.filter(data => {
                                                if (!uniqueData.has(data?.eje)) {
                                                    uniqueData.add(data?.eje);
                                                    return true;
                                                }
                                                return false;
                                            })
                                            .map((data, index) => (
                                                <p key={index} className='fs-6'>{data?.eje}</p>
                                            ));
                                    })()
                                    : null
                            }
                        </Col>
                        <Col lg={12} className='text-start mb-2'>
                            <h1 className='fw-bold fs-5'>Objetivos Estratégicos:</h1>
                            {
                                allDataHeaderPOA?.value !== 2009
                                    ? allDataHeaderPOA && allDataHeaderPOA?.map((data, index) => (
                                        <> <p id={index} className='fs-6'>{data?.objetivoEstrategico} </p>
                                        </>
                                    ))
                                    : null
                            }
                        </Col>
                        <Col lg={12} className='text-start'>
                            <h1 className='fw-bold fs-5'>Objetivos Operativos: </h1>
                            {
                                allDataHeaderPOA?.value !== 2009
                                    ? allDataHeaderPOA && allDataHeaderPOA?.map((data, index) => (
                                        <> <p id={index} className='fs-6'>{data?.objetivoOperativo} </p>
                                        </>
                                    ))
                                    : null
                            }
                        </Col>
                    </Row>
                </div>
                <hr />
                {
                    allData?.value !== 2009
                        ? allData && allData?.map((data, index) => (
                            <div className="table-responsive" key={index}>
                                <span>{'Acción '}</span><h4><span>{data?.accion}</span></h4>
                                <Table className="table-nowrap">
                                    <thead>
                                        <tr>
                                            <th>Renglón</th>
                                            <th>Financiamiento</th>
                                            <th className="text-end">Monto</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allData2 && allData2?.value !== 2009 ? allData2?.filter(data2 => data2?.idAccion === data?.id).map((data2, index2) => (
                                            <tr key={index2}>
                                                <td style={{ width: '60%', height: '5px' }}>{data2?.renglon}</td>
                                                <td>{data2?.financiamiento}</td>
                                                <td className="text-end">{data2?.costoConQ}</td>
                                            </tr>
                                        ))
                                            : null}
                                        {allData3 && allData3?.value !== 2009 ? allData3?.filter(data3 => data3?.idAccion === data?.id).map((data3, index3) => (
                                            <tr key={index3} >
                                                <td colSpan="2" className="text-end">
                                                    Sub Total
                                                </td>
                                                <td style={{ width: '25%' }} className="text-end">
                                                    {data3?.total}
                                                </td>
                                            </tr>
                                        ))
                                            : null}

                                    </tbody>
                                </Table>
                            </div>
                        ))
                        : null}
                <div className="table-responsive">
                    <Table className="table-nowrap">
                        <thead>
                            <tr>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ width: '76%' }} className="border-0 text-end fs-5">
                                    <strong>Total POA</strong>
                                </td>
                                <td className="border-0 text-end">
                                    <strong><h4 className="m-0">
                                        {allData4 && allData4[0]?.totalQ ? allData4[0]?.totalQ : 'Q.00'}
                                    </h4></strong>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>

            </div>
            <div className={`d-print-none ${allData?.value !== 2009
                ? '' : 'd-none'}`}>
                <div className="text-end">
                    <Link
                        to="#"
                        onClick={Imprimir}
                        // id="print"
                        className="btn btn-success  me-2"
                    >
                        <i className="fa fa-print" /> Imprimir
                    </Link>
                    {poaFechaEnvio?.value === 2009 ?
                        <span className="btn btn-primary w-md " onClick={() => One(null, 5)}>
                            Enviar a Verificar
                        </span>
                        : poaFechaEnvio && poaFechaEnvio[0]?.idEstado === 1 ?
                            <>
                                {"Enviado a Revisión: "}<strong>{poaFechaEnvio && poaFechaEnvio[0]?.fechaEnvioRevision} </strong>
                            </>
                            : poaFechaEnvio && poaFechaEnvio[0]?.idEstado === 2 ?
                                <>
                                    {`POA `}<span className='text-success'>{poaFechaEnvio && poaFechaEnvio[0]?.estado}  </span> el {poaFechaEnvio && poaFechaEnvio[0]?.fechaAprobacion} {' '}
                                </>
                                : poaFechaEnvio && poaFechaEnvio[0]?.idEstado === 3 ?
                                    <>
                                        <span className="btn btn-primary w-md " onClick={() => One(null, 5)}>
                                            Enviar a Verificar
                                        </span>
                                        <hr />
                                        {`POA `}<span className='text-danger'>{poaFechaEnvio && poaFechaEnvio[0]?.estado}  </span> el {poaFechaEnvio && poaFechaEnvio[0]?.fechaRechazo} {' '}
                                        Motivo: <strong><span>{poaFechaEnvio && poaFechaEnvio[0]?.descripcion}</span></strong>
                                    </>
                                    :
                                    null
                    }
                </div>
            </div>

            {
                opcion === 5 &&
                <Modal
                    isOpen={modal}
                    backdrop='static'
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}> Enviar a Revisión</ModalHeader>
                    <Form autoComplete="off"
                        onSubmit={handleSubmit(onSubmitEstado)}
                    >
                        <ModalBody>
                            <Row >
                                <Col className="text-center">
                                    <img className="card-img img-fluid text-center" style={{ height: '250px', width: '270px' }} src={revision} />
                                </Col>
                                <Col lg='12' className='text-center'>
                                    <span className='fs-5'>Esta a punto de enviar este POA a revisión.</span>
                                    <br />
                                    <span className='fs-5'>¿Está seguro?</span>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            {
                                opcion !== 2 &&
                                <>
                                    <Button color='success' type="submit" id="accion">
                                        <Icon.CheckSquare size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                        {opcion === 5 && 'Si estoy seguro, Enviar'}
                                    </UncontrolledTooltip>
                                </>
                            }
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Visor PDF */}
            {/* <object
                    className='Visor'
                    width='100%'
                    height='720px'
                    id='control-actas'
                    aria-labelledby='control-actas'
                    type='application/pdf'
                    data={`${pdf}`}
                /> */}
        </>
    );
}

export default Index;