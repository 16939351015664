// import { ContentContext as ContentContextNotifications } from '../../components/CommonForBoth/TopbarDropdown/context'
import { PostRoute } from '../../services/Private'
import React, { useState, useEffect } from "react";
// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_SIDEBAR_THEME_IMAGE,
  CHANGE_PRELOADER,
  TOGGLE_LEFTMENU,
  SHOW_SIDEBAR,
  CHANGE_LAYOUT_MODE
} from "./actionTypes"

//constants
import {
  layoutTypes,
  layoutModeTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftBarThemeImageTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from "../../constants/layout";

// var data
// // const All = async () => {
//   const response =  PostRoute(`theme/all`, {})
// //   // console.log(response)
// //   return response
// // }

// // data.push(All().then((res) => {return res[0].Promise}))
// console.log(response.then((result) => {
//   console.log(result[0]);
//   data = result;
// })
// .catch((error) => {
//   console.log(error);
// }))
// console.log(data)


const storage = localStorage.getItem('authUser')
const getJson = () => {
  if (storage) {
    const Blowfish = require('javascript-blowfish');
    const bf = new Blowfish(process.env.REACT_APP_CONFIG_KEY);
    const Decript = bf?.decrypt(bf?.base64Decode(localStorage?.getItem('authUser')));
    const Token = JSON?.parse(Decript?.replace(/\0/g, ''));
    return Number(Token?.theme);
  } else {
    return 1;
  }

};

const INIT_STATE = {

  layoutType: layoutTypes.VERTICAL,
  // layoutModeType: layoutModeTypes.LIGHT,
  layoutModeType: getJson() === 0 ? layoutModeTypes.DARK : layoutModeTypes.LIGHT,
  layoutWidth: layoutWidthTypes.FLUID,
  leftSideBarTheme: leftSideBarThemeTypes.DARK,
  leftSideBarThemeImage: leftBarThemeImageTypes.NONE,
  leftSideBarType: leftSidebarTypes.DEFAULT,
  topbarTheme: topBarThemeTypes.LIGHT,
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
}

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      }
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      }

    case CHANGE_LAYOUT_MODE:
      return {
        ...state,
        layoutModeType: action.payload,
      }

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      }
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      }
    case CHANGE_SIDEBAR_THEME_IMAGE:
      return {
        ...state,
        leftSideBarThemeImage: action.payload,
      }
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      }
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      }
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: action.payload,
      }
    case SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: action.payload,
      }
    case TOGGLE_LEFTMENU:
      return {
        ...state,
        leftMenu: action.payload,
      }

    default:
      return state
  }
}

export default Layout
