import React, { useContext, useState } from 'react'
import { Button, Form, Row, Col, UncontrolledTooltip } from 'reactstrap'
import { ContentContext } from '../context'
import { useForm } from "react-hook-form"
import * as Icon from 'react-feather'
import { OptionsToast } from 'Globales/index'
import { toast } from 'react-toastify'
import List from './List'
import { ContentProvider } from './context'
export const Attached = () => {
  const
    { register, handleSubmit, setValue, } = useForm(),
    [Visualizar, setVisualizar] = useState(''),
    [getFoto, setGetFoto] = useState([]),
    { storeFile, idMicroCapsula } = useContext(ContentContext),
    onSubmitFile = async (data) => {
      console.log(data)
      var formData = new FormData();
      formData.append('solicitudInsumo', idMicroCapsula);
      formData.append('file', data.file[0]);
      Visualizar === '' ? toast.error('Debe seleccionar un archivo', OptionsToast) : storeFile(formData)
      Limpiar()
    },
    // setData = async () => {
    //   await setValue('vigente', oneData.vigente)
    //   await setValue('ejecutado', oneData.ejecutado)
    //   await setValue('total', oneData.total)
    // },
    MostrarDoc = (value) => {
      if (value.target.value) {
        var pdffile = value.target.files[0],
          pdffile_url = URL.createObjectURL(pdffile);
        setGetFoto(pdffile)
        setVisualizar(`${pdffile_url}`);
      }
      else {
        setVisualizar('');
      }
    },
    Limpiar = () => {
      setVisualizar('');
      setValue('file', null);
      setGetFoto()
    }

  return (
    <>
          <ContentProvider>
            <List />
          </ContentProvider>
    </>
  )
}

export default Attached