import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from "../../../assets/images/logo_oficial-1-SIN-AROS.png";

var base64 = null;
toDataURL(Logo, function (Base) {
  base64 = Base;
});

export default function PDFCajaChica(header, detalle, TOTAL) {
  // console.log(header)
  // console.log(detalle)
  // console.log(TOTAL)
  let doc = new jsPDF('p', 'pt', [500, 360]).setProperties({ title: 'Gasto Caja Chica' }),
    columns = [],
    rows = [],
    fecha = header?.fechaPedido?.split('-'),
    ColorFact = [0, 0, 0],
    maxY = 0;

  function pageContent() {

    // ************************************************************
    // Logo Empresa
    doc.setFontSize(6).setFont("helvetica", "bold");

    doc.addImage(base64, "PNG", 20, 20, 65, 35);

    doc.text(20, 70, header?.nombreUnidad, { align: 'left', width: 140 });

    doc.setFont("helvetica", "bold").text(20, 80, 'Acción:', { align: 'left', width: 140 });
    doc.setFont("helvetica", "normal").text(50, 80, header?.accion, { align: 'left', width: 140 });

    doc.setFont("helvetica", "bold").text(20, 90, 'No. Preorden de Compra:', { align: 'left', width: 140 });
    doc.setFont("helvetica", "normal").text(110, 90, header?.noPreordenCompra, { align: 'left', width: 140 });

    doc.setFont("helvetica", "bold").text(20, 100, 'Almacén:', { align: 'left', width: 140 });
    doc.setFont("helvetica", "normal").text(50, 100, header?.existenciaTexto, { align: 'left', width: 140 });

    // ************************************************************

    // ************************************************************
    // // Cuadro Documento Tributario Electronico
    // doc.setFillColor(ColorFact[0], ColorFact[1], ColorFact[2]);
    // doc.setLineWidth(0.2).setDrawColor(ColorFact[0], ColorFact[1], ColorFact[2]).rect(200, 20, 140, 50);
    // // Contenido primer cuadro
    // doc.text(208, 30, `DOCUMENTO TRIBUTARIO ELECTRÓNICO`, { align: 'left', width: 140 });
    doc.setFillColor(ColorFact[0], ColorFact[1], ColorFact[2]);
    doc.setLineWidth(0.2).setDrawColor(ColorFact[0], ColorFact[1], ColorFact[2]).rect(208, 23, 135, 10, "F");
    doc.setFontSize(11).setTextColor(255).setFont("helvetica", "bold").text(275, 32, header?.modalidadTexto, { align: 'center', width: 140 });
    doc.setFontSize(6)
    doc.setTextColor(0).setFont("helvetica", "bold").text(208, 52, `Correlativo:`, { align: 'left', width: 140 });
    doc.setFontSize(7);
    doc.setFont("helvetica", "normal").text(268, 52, header?.correlativo, { align: 'left', width: 140 });
    doc.setFontSize(6)
    doc.setFont("helvetica", "bold").text(208, 40, `Código:`, { align: 'left', width: 140 });
    doc.setFont("helvetica", "normal").text(268, 40, 'COM-FOR-01 V4', { align: 'left', width: 140 });

    // ************************************************************
    // Fecha 
    doc.setFillColor(ColorFact[0], ColorFact[1], ColorFact[2]);
    doc.setLineWidth(0.2).setDrawColor(ColorFact[0], ColorFact[1], ColorFact[2]).rect(208, 75, 135, 20);
    doc.setFillColor(ColorFact[0], ColorFact[1], ColorFact[2]);
    doc.setDrawColor(ColorFact[0], ColorFact[1], ColorFact[2]).rect(208, 73, 135, 10, "F");
    doc.setLineWidth(0.1).line(245, 73, 245, 95);
    doc.line(300, 73, 300, 95);

    doc.setTextColor(255).text(221, 80, `DÍA`, { align: 'left' });
    doc.text(265, 80, `MES`, { align: 'left' });
    doc.text(315, 80, `AÑO`, { align: 'left' });

    doc.setTextColor(0).text(223, 90, fecha[2]?.split('T00:00:00'), { align: 'left' });
    doc.text(268, 90, fecha[1], { align: 'left' });
    doc.text(315, 90, fecha[0], { align: 'left' });

    // ************************************************************
    // Lineas Detalle
    // doc.line(20, 112, 20, finalY);
    // doc.line(60, 112, 60, finalY);
    // doc.line(230, 112, 230, finalY);
    // doc.line(285, 112, 285, finalY);
    // doc.line(340, 112, 340, finalY);
    // // Lineas Total 
    // let altura = finalY + 10;
    // doc.line(20, altura, 340, altura);
    // altura += 15;
    // doc.line(20, altura, 340, altura);
    // altura += 15;

    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize(5);
    doc.text('Página ' + pageCount, doc.internal.pageSize.width - 39, doc.internal.pageSize.height - 40);

    // doc.setTextColor(255).setFont("helvetica", "bold").text(248, 545, `TOTAL`, { align: 'left' });

    // doc.text(105, 590, 'header?.piePagina', { align: 'center', width: 170 });

    // let direccion = doc.splitTextToSize('header?.piePagina', 320);
    // doc.text(180, 590, direccion, { align: 'center' });

  }


  function didDrawCell(data) {
    // console.log(data)
    if (data.section === 'body') {
      // maxY = 506.75;
      // maxY = Math.max(maxY, data?.cell.y + data?.cell.height);
      maxY = Math.max(data?.cell.y + data?.cell.height);
    }
  }
  //Una 
  detalle.push({ descripcionConPartida: "--------------------------------------- Última Línea --------------------------------------" })
  detalle.push({ idRenglon: "TOTAL", costo: TOTAL[0]?.totalQ })
  columns = [['CANTIDAD', 'DESCRIPCION', 'RENGLON', 'VALOR ESTIMADO']]
  detalle.length !== 0 && detalle.map((item, i) => {
    if (i === detalle.length - 1) {
      rows[i] = [
        item.cantidad,
        item.descripcionConPartida,
        item.idRenglon,
        item?.costo
      ];
    } else {
      rows[i] = [
        item.cantidad,
        item.descripcionConPartida,
        item.idRenglon,
        item?.costo
      ];
      rows[i + 1] = [];
    }
  });

  function drawLines() {
    doc.setDrawColor(0, 0, 0); // Establecer el color de la línea
    doc.setLineWidth(0.1); // Establecer el ancho de la línea
    // Dibuja cada línea vertical usando la posición 'y' más baja registrada

    doc.line(20, 112, 20, maxY);
    doc.line(60, 112, 60, maxY);
    doc.line(230, 112, 230, maxY);
    doc.line(285, 112, 285, maxY);
    doc.line(340, 112, 340, maxY);

    // Lineas Total 

    doc.line(20, maxY, 340, maxY);
    doc.line(20, maxY, 340, maxY);
    //... and so on for each line you need

    // doc.setFont("helvetica", "bold").text(260, maxY + 15, `TOTAL`, { align: 'right' });
    // doc.setFont("helvetica", "bold").text(313, maxY + 15, TOTAL[0]?.totalQ, { align: 'center' });
  }

  doc.autoTable(
    {
      head: columns,
      body: rows,
      tableWidth: 'auto',
      headStyles:
      {
        fillColor: [ColorFact[0], ColorFact[1], ColorFact[2]],
        textColor: [255],
        valign: 'middle',
        halign: 'center'
      },
      styles:
      {
        fontSize: 5,
        fillColor: false,
        lineWidth: 0,
        lineColor: 48,
        textColor: 20,
        pageBreak: 'auto',
        overFlow: 'linebreak',
      },
      columnStyles:
      {
        0: { cellWidth: 40, halign: 'center' },
        1: { halign: 'left', width: 'wrap' },
        2: { cellWidth: 55, halign: 'center' },
        3: { cellWidth: 55, halign: 'center' }
      },
      didParseCell: function (data) {
        // Revisa si la celda actual es la del total
        if (data?.row?.raw[2] === 'TOTAL') {
          // doc.setFontSize(7)
          data.cell.styles.fontStyle = 'bold';
          // data.doc.setFont("helvetica", "bold"); // Aplica el estilo de fuente en negrita
        }
      },
      didDrawCell: didDrawCell,
      didDrawPage: function (data) {
        pageContent(data);
        drawLines(); // Llama a drawLines aquí para dibujar las líneas después de la tabla
      },
      margin:
      {
        left: 20,
        right: 20,
        top: 112,
        bottom: 110
      },
      startY: 112, // Remove doc.lastAutoTable.finalY
    }
  )
  doc.setFontSize(6)
  const x = 20; // Coordenada x
  const maxWidth = 344;
  const y = doc.lastAutoTable.finalY + 10; // Coordenada y (agregando un espacio de 10 unidades)
  doc.setFont("helvetica", "bold").text(x, y, 'JUSTIFICACIÓN: ', { align: 'left', width: 140 });
  // doc.text(x, y + 10, header.justificacion, { align: 'left', width: 140 });
  doc.setFontSize(5);
  const text = header.justificacion;
  // const text = 'Renovación de licencia de usuario INFILE según lo solicitado en oficio Ref: 125-2023-DAJ-AAG-djps para uso de la Dirección de Asuntos Jurídicos el cual permite la consulta de legislación de Guatemala y actualización de leyes de forma inmediata y de la reglamentación vigente para Guatemala, Renovación de licencia de usuario INFILE según lo solicitado en oficio Ref: 125-2023-DAJ-AAG-djps para uso de la Dirección de Asuntos Jurídicos el cual permite la consulta de legislación de Guatemala y actualización de leyes de forma inmediata y de la reglamentación vigente para Guatemala,Renovación de licencia de usuario INFILE según lo solicitado en oficio Ref: 125-2023-DAJ-AAG-djps para uso de la Dirección de Asuntos Jurídicos el cual permite la consulta de legislación de Guatemala y actualización de leyes de forma inmediata y de la reglamentación vigente para Guatemala,Renovación de licencia de usuario INFILE según lo solicitado en oficio Ref: 125-2023-DAJ-AAG-djps para uso de la Dirección de Asuntos Jurídicos el cual permite la consulta de legislación de Guatemala y actualización de leyes de forma inmediata y de la reglamentación vigente para Guatemala,Renovación de licencia de usuario INFILE según lo solicitado en oficio Ref: 125-2023-DAJ-AAG-djps para uso de la Dirección de Asuntos Jurídicos el cual permite la consulta de legislación de Guatemala y actualización de leyes de forma inmediata y de la reglamentación vigente para Guatemala';
  // const textWidth = 200 - 20 - 20; // Ancho de la hoja menos los márgenes de derecha e izquierda
  const lines = doc.splitTextToSize(text, maxWidth); // Divide el texto en líneas

  lines.forEach((line, i) => {
    doc.setFont("helvetica", "normal").text(line, x, (y + 10) + (i * 10)); // Ajusta el multiplicador de 'i' según el espacio deseado entre líneas

  });


  // Ajustes para las firmas
  const pageWidth = doc.internal.pageSize.width;
  const firmaColumnWidthTop = pageWidth / 3; // Ancho de la columna para la fila superior
  const firmaColumnWidthBottom = pageWidth / 3; // Ancho de la columna para la fila inferior
  const firmaHeight = 40; // Espacio vertical para cada firma, aumentado para dos filas

  // Calcula la posición final del párrafo de justificación
  const yJustificacion = doc.lastAutoTable.finalY + 23;
  const linesJustificacion = doc.splitTextToSize(text, maxWidth);
  const alturaJustificacion = linesJustificacion.length * 10;
  const firmaStartY = yJustificacion + alturaJustificacion + 35; // Posición inicial de la fila superior de firmas


  // Lista de firmantes con sus títulos
  const firmantesTop = [
    { titulo: "SOLICITANTE", nombre: header?.solicitante },
    { titulo: "Vo. Bo. JEFE INMEDIATO", nombre: header?.jefeDireccion },
    { titulo: "Vo. Bo. JEFE DE PRESUPUESTO", nombre: header?.jefePresupuesto },
  ];




  // Dibujar las firmas de la fila superior
  firmantesTop.forEach((firmante, index) => {
    const x = index * firmaColumnWidthTop;
    doc.setLineWidth(0.5).line(x + 10, firmaStartY, x + firmaColumnWidthTop - 10, firmaStartY);
    doc.setFont("helvetica", "normal").text(x + firmaColumnWidthTop / 2, firmaStartY + 7, firmante.nombre, { align: 'center' });
    doc.setFont("helvetica", "bold").text(x + firmaColumnWidthTop / 2, firmaStartY + 14, firmante.titulo, { align: 'center' });
  });



  // //---Firmas
  const bottomRowY = firmaStartY + firmaHeight; // Posición inicial de la fila inferior de firmas
  let notasStartY = bottomRowY + 30; // Agrega un espacio después de las firmas

  // Texto de las notas, como ejemplo, lo tienes directamente aquí, pero lo ideal sería pasarlo como parámetro o variable
  const notas = [
    "Nota 1: Este gasto tiene un plazo de 3 días hábiles para su liquidación, de lo contrario el dinero debera ser reintegrado inmediatamente.",
    "Nota 2: Al presentar la liquidación del presente gasto, debera entregar la factura debidamente razonada con las firmas del responsable de la compra y jefe inmediato, identificando nombre y puesto.",
    "Nota 3: El control interno de toda adquisición de Alimentos, Bienes o Servicios queda bajo la responsabilidad de la Unidad Ejecutora."
  ];

  // Ajuste de fuente para las notas
  doc.setFont("helvetica", "normal").setFontSize(10);

  // Dibuja el texto de las notas
  notas.forEach(nota => {
    doc.setFontSize(6);
    const lines = doc.splitTextToSize(nota, maxWidth); // Ajusta el texto a la anchura máxima
    lines.forEach(line => {
      doc.text(line, 20, notasStartY); // Ajusta el texto a la izquierda
      notasStartY += 5; // Incrementa la posición Y para la siguiente línea
    });
    notasStartY += 5; // Agrega un poco de espacio adicional después de cada nota
  });

  // // Calcula la posición final del párrafo de justificación
  // const yJustificacion = doc.lastAutoTable.finalY + 23; // Posición inicial de la justificación
  // const linesJustificacion = doc.splitTextToSize(text, maxWidth); // Divide el texto en líneas
  // const alturaJustificacion = linesJustificacion.length * 10; // Altura total del texto de justificación
  // const firmaStartY = yJustificacion + alturaJustificacion + 10; // Inicio de las firmas, 10 unidades debajo de la justificación

  // // Ajustes para las firmas
  // const firmaHeight = 20; // Espacio vertical para cada firma
  // let currentY = firmaStartY;

  // // Lista de firmantes con sus títulos
  // const firmantes = [
  //   { titulo: "SOLICITANTE", nombre: header?.solicitante },
  //   { titulo: "Vo. Bo. JEFE INMEDIATO", nombre: header?.jefeDireccion },
  //   { titulo: "Vo. Bo. JEFE DE PRESUPUESTO", nombre: header?.jefePresupuesto },
  //   { titulo: "Vo. Bo. SORF", nombre: header?.subgerenteSorf },
  //   { titulo: "APROBADO GERENCIA GENERAL", nombre: header?.gerente }
  // ];

  // const pageWidth = doc.internal.pageSize.width;
  // const firmaColumnWidth = pageWidth / firmantes.length; // Dividir el ancho de la página por el número de firmantes
  // let firmaStartX = 20; // Margen izquierdo para empezar las firmas

  // // Dibujar cada firma
  // firmantes.forEach((firmante, index) => {
  //   const x = firmaStartX + index * firmaColumnWidth;
  //   doc.setFontSize(3);
  //   doc.setFont("helvetica", "bold").text(x, firmaStartY, firmante.titulo, { align: 'center' });
  //   doc.setFont("helvetica", "normal").text(x, firmaStartY + 10, firmante.nombre, { align: 'center' });
  //   doc.setLineWidth(0.5).line(x, firmaStartY + 15, x + firmaColumnWidth - 20, firmaStartY + 15);
  // });

  // //---Firmas
  // doc.text(x, y + 10, lines, { align: 'justify', width: textWidth, valign: 'top' });
  // Total page number plugin only available in jspdf v1.0+
  // if (typeof doc.putTotalPages === 'function') {
  //     doc.putTotalPages(totalPagesExp)
  // } 
  // if (opcion !== 1) {
  return doc.output('datauristring')
  // } else {
  //   doc.output('dataurlnewwindow');
  // }
}


function toDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    }
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}