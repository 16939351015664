import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { ContentContext } from './context'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback, Table } from 'reactstrap'
import { useForm, Controller } from "react-hook-form"
import * as Icon from 'react-feather'
import DatePicker from "react-datepicker";
import Select from 'react-select'
import { customStyles } from '../../../Globales/index'
import classnames from 'classnames'
import { Text } from "@nextui-org/react";
import logo from "assets/images/COG/logo-COG.png";
import logoSIPA from "assets/images/COG/SIPA-completo.png";
const Formulario = () => {
    const [startDate, setStartDate] = useState(Date.parse(2019) + 1);
    const { toggleModal, modal, StoreUpdate, updatePOA, opcion, allData, allData2, allData3, allData4, allDataOE,
        allDataOOU, oneData } = useContext(ContentContext),
        { handleSubmit, formState: { errors }, control, reset, watch } = useForm(),
        onSubmit = (data) => {
            const json = {
                id: (oneData) ? oneData.id : null,
                monto: data.monto,
                unidad: data.unidad.value,
                año: startDate
            }
            StoreUpdate(json)
            setStartDate(Date.parse(2019) + 1)
        },
        onSubmitEstado = (data) => {
            if (opcion === 5) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    TipoTransaccion: 1
                }
                updatePOA(json)
            }
            if (opcion === 6) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    TipoTransaccion: 2,
                    descripcion: data.descripcion
                }
                updatePOA(json)
            }
        }
    return (
        <Fragment>
            {/* <Button onClick={() => toggleModal(1)} id="crear" color="primary" size="sm">
                <Icon.Plus size={16} />
            </Button>
            <UncontrolledTooltip placement='left' target='crear' placementPrefix="bs-tooltip">
                Crear
            </UncontrolledTooltip> */}

            {opcion > 0 && opcion < 5 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
                size='llg'
            >
                {/* <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}></ModalHeader> */}
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody>
                        <div id='printablediv' className='printablediv p-3 mb-0'>
                            <div className="invoice-title">
                                <Row className='align-items-center m-0 p-0'>
                                    <Col >
                                        <div >
                                            <img src={logo} alt="logo" height="115" />
                                        </div>
                                    </Col>
                                    <Col className='text-center'><span className='text-dark  fs-3 fw-bold'>Plan Operativo Anual</span></Col>
                                    <Col>
                                        <h4 className="float-end">
                                            <img src={logoSIPA} alt="logo2" height="50" />
                                        </h4>
                                    </Col>
                                </Row>
                            </div>
                            <hr />
                            <div>
                                <Row className='mb-0'>
                                    <Col lg={12} className='text-start mb-2'>
                                        <span className='fw-bold fs-5'>Objetivo Estratégico:</span> {allDataOE?.value !== 2009 ? allDataOE && allDataOE[0]?.nombre : null}
                                    </Col>
                                    <Col lg={12} className='text-start'>
                                        <span className='fw-bold fs-5'>Objetivo Operativo: </span> {allDataOOU?.value !== 2009 ? allDataOOU && allDataOOU[0]?.nombre : null}
                                    </Col>
                                </Row>
                            </div>
                            <hr />
                            {
                                allData?.value !== 2009
                                    ? allData && allData.map((data, index) => (
                                        <div className="table-responsive" key={index}>
                                            <span>{'Dependencia '}</span><h4><span>{data.nombreDependencia}</span></h4>
                                            <Table className="table-nowrap">
                                                <thead>
                                                    <tr>
                                                        <th>Renglón</th>
                                                        <th>Financiamiento</th>
                                                        <th className="text-end">Monto</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allData2 && allData2?.value !== 2009
                                                        && allData2.filter(data2 => data2.idAccion === data.id).map((data2, index2) => (
                                                            <tr key={index2}>
                                                                <td style={{ width: '60%', height: '5px' }}>{data2.renglon}</td>
                                                                <td>{data2.financiamiento}</td>
                                                                <td className="text-end">{data2.costoConQ}</td>
                                                            </tr>
                                                        ))}
                                                    {allData3 && allData3.filter(data3 => data3.idAccion === data.id).map((data3, index3) => (
                                                        <tr key={index3} >
                                                            <td colSpan="2" className="text-end">
                                                                Sub Total
                                                            </td>
                                                            <td style={{ width: '25%' }} className="text-end">
                                                                {data3.total}
                                                            </td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </Table>
                                        </div>
                                    ))
                                    : null}
                            <div className="table-responsive">
                                <Table className="table-nowrap p-0 m-0">
                                    <thead>
                                        <tr>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: '76%' }} className="border-0 text-end fs-5 ">
                                                <strong>Total POA</strong>
                                            </td>
                                            <td className="border-0 text-end">
                                                <strong><h4 className="m-0">
                                                    {allData4 && allData4[0]?.totalQ ? allData4[0]?.totalQ : 'Q.00'}
                                                </h4></strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>

                    </ModalFooter>
                </Form>
            </Modal>}
            {opcion === 5 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 1 && 'crear'} Plan Operativo Anual</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                    <ModalBody>
                        <Row >
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    ¿Desea aprobar este Plan Operativo Anual?
                                </Text>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 5 && 'Aprobar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {opcion === 6 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 6 && 'Rechazar'} Plan Operativo Anual</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                    <ModalBody>
                        <Row>
                            <Col >
                                <FormGroup>
                                    <Label for="descripcion" className="">* Motivo Rechazo</Label>
                                    <Controller

                                        control={control}
                                        id='descripcion'
                                        name='descripcion'
                                        render={({ field }) => (
                                            <Input
                                                rows={2}
                                                type='textarea'
                                                // readOnly={updateData}
                                                invalid={errors.descripcion && true}
                                                defaultValue=''
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.descripcion && errors.descripcion.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Save size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 6 && 'Operar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
        </Fragment>
    )
}

export default Formulario