import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from "../../../assets/images/logo_oficial-1-SIN-AROS.png";

var base64 = null;
toDataURL(Logo, function (Base) {
  base64 = Base;
});

export default function PDFTransportes() {
  let doc = new jsPDF('p', 'pt', [500, 360]).setProperties({ title: 'Solicitud' });

  // Encabezado de la solicitud
  doc.setFontSize(6).setFont("helvetica", "bold");

  // Logo de la empresa
  doc.addImage(base64, "PNG", 20, 20, 65, 35);
  doc.text(20, 70, 'Nombre de la Empresa', { align: 'left' });

  // Título de la solicitud
  doc.setFontSize(12).text(200, 40, 'Formulario de Solicitud', { align: 'center' });

  // Campos de la solicitud
  doc.setFontSize(10).setFont("helvetica", "normal");
  doc.text(20, 100, 'Nombre del Solicitante:', { align: 'left' });
  doc.text(20, 130, 'Fecha de Solicitud:', { align: 'left' });
  doc.text(20, 160, 'Descripción de la Solicitud:', { align: 'left' });

  // Dibujar cajas para llenar
  doc.rect(180, 90, 300, 20);  // Caja para Nombre del Solicitante
  doc.rect(180, 120, 300, 20);  // Caja para Fecha de Solicitud
  doc.rect(180, 150, 300, 60);  // Caja para Descripción de la Solicitud

  // Páginas
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(5);
  doc.text('Página ' + pageCount, doc.internal.pageSize.width - 39, doc.internal.pageSize.height - 40);

  return doc.output('datauristring');
}

function toDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    }
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}
