import React, { Row, Col } from 'reactstrap'
import { ContentProvider } from './context'
import Formulario from './form'
import List from './list'
const Index = () => {
    return (
        <ContentProvider>
            <div className="page-content">
                <Row>
                    <Col lg="2" sm="2">
                    </Col>
                    <Col>
                        <div className="h1 font-weight-bolder text-uppercase text-center">
                            Catálogo de Gestión POA
                        </div>
                    </Col>
                    <Col className="d-flex justify-content-end" lg="2" sm="2">
                        <Formulario />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <List />
                    </Col>
                </Row>
            </div>
        </ContentProvider>
    )
}

export default Index