import React, { createContext, useState, useEffect } from 'react'
import { PostRoute } from '../../../services/Private'
import { OptionsToast } from '../../../Globales/index'
import { toast } from 'react-toastify'
import { useRequest } from '../../../hooks/useRequestSWR'
import { useSWRConfig } from 'swr'
import { useDataUser } from 'hooks/useDataUser'
import PDFPedido from './PDFPedido.jsx'
import PDFGasto from './PDFGasto.jsx'
import PDFCajaChica from './PDFCajaChica.jsx'


export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    // Modal Init
    const code = useDataUser()?.dataUser?.codigoUnidad;
    const solicitante = useDataUser()?.dataUser?.i;
    const nombreSolicitante = useDataUser()?.dataUser?.nombreCompleto;
    const [idAccion, setAccion] = useState(0);
    const { mutate } = useSWRConfig();
    const [id, setId] = useState(0);
    const [mutateSaldoAccion, setMutateSaldoAccion] = useState(false);
    const [idPresupuestoPoa, setIdPresupuestoPoa] = useState(0);
    const [idModalidad, setIdModalidad] = useState(0);
    const [idTipoPago, setidTipoPago] = useState(0);
    const [pdf, setPdf] = useState([]);
    const nameComponent = 'Pedido';
    const nameController = 'Pedido';
    const nameController2 = 'Acciones';
    const nameController3 = 'TipoPedido';
    const nameController4 = 'TipoGasto';
    const nameController5 = 'Fand';
    const nameController6 = 'Persona';
    const { data: allData } = useRequest(`${nameController}/all`, 'GETSWR');
    const { data: allDataHistorica } = useRequest(`${nameController}/allHistorico`, 'GETSWR');
    const { data: labelAcciones } = useRequest(`${nameController2}/label-Unidad`, 'POST', { code: code });
    const { data: labelJefeDireccion } = useRequest(`${nameController6}/jefeDireccion`, 'POST', {});
    const { data: labelTipoPedido } = useRequest(`${nameController3}/label`, 'GET');
    const { data: labelTipoGasto } = useRequest(`${nameController4}/label`, 'GET');
    const { data: labelFand } = useRequest(`${nameController5}/label`, 'GET');
    const { data: labelCategoria } = useRequest(`${nameController}/labelCategoriaExpediente`, 'GET');
    const { data: labelOjetoGasto } = useRequest(`${nameController}/label-objetoGasto`, 'GET');
    const { data: labelAnalistas } = useRequest(`${nameController}/label-analistas`, 'getInmutable');
    const
        [modal, setModal] = useState(null),
        // [allData, setAllData] = useState([]),
        [oneData, setOneData] = useState([]),
        [DataBitacoraPedido, setDataBitacoraPedido] = useState([]),
        [agregarRenglon, setAgregarRenglon] = useState(0),
        [opcion, setOpcion] = useState(0),
        toggleModal = async (data) => {

            setOpcion(data)
            if (data === 1) { setOneData(null), setIdPresupuestoPoa(0) }
            setModal(!modal)

        },
        // activaMutateTotalSaldo = async (data) => {

        //     setOpcion(data)
        //     if (data === 1) { setOneData(null), setIdPresupuestoPoa(0) }
        //     setModal(!modal)

        // },
        // All = async () => {
        //     const response = await GetRoute(`${nameController}/all`)
        //     setAllData((response.length) ? response : [])
        // },
        One = async (data, opcion) => {
            const response = await PostRoute(`${nameController}/one`, { id: data.id })
            setOneData((response[0]) ? response[0] : [])
            toggleModal(opcion)

        },
        BitacoraPedido = async (data, opcion) => {
            const response = await PostRoute(`${nameController}/bitacoraPedido`, { id: data.id })
            setDataBitacoraPedido((response) ? response : [])
            toggleModal(opcion)

        },
        StoreUpdate = async (data, id) => {
            let response = []
            response = await PostRoute(`${nameController}/${!data.id ? 'store' : 'update'}`, data)
            const responseTwo = await PostRoute(`${nameController}/one`, { id: (!id ? response?.id : id) })
            setOneData((responseTwo[0]) ? responseTwo[0] : [])
            setOpcion(3)
            if (response.response === 0) {
                toast.error(response.message, OptionsToast)
            } else {
                toast.success(response.message, OptionsToast)
                setAccion(responseTwo[0]?.idAccion)

            }
            // toast.success(response.message, OptionsToast)
            // toggleModal(0)
            mutate(`${nameController}/all`)

        },
        Actions = async (data) => {

            const response = await PostRoute(`${nameController}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data.id })
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)

        },
        UpdateEstadoPedido = async (data) => {
            const response = await PostRoute(`${nameController}/${'UpdateEstadoPedido'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)
            mutate(`${nameController}/allHistorico`)

        },
        AsignaPreOrdenCompra = async (data) => {
            const response = await PostRoute(`${nameController}/${'ingresarPreOrden'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)
            mutate(`${nameController}/allHistorico`)

        },
        AsignaOrdenCompra = async (data) => {
            const response = await PostRoute(`${nameController}/${'ingresarOrdenCompra'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)
            mutate(`${nameController}/allHistorico`)

        },
        UpdateAnalista = async (data) => {
            const response = await PostRoute(`${nameController}/${'UpdateAnalista'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)
            mutate(`${nameController}/allHistorico`)

        },
        AsignaCUR = async (data) => {
            const response = await PostRoute(`${nameController}/${'ingresarCUR'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)
            mutate(`${nameController}/allHistorico`)

        },
        AsignaCURDEV = async (data) => {
            const response = await PostRoute(`${nameController}/${'ingresarCURDev'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)
            mutate(`${nameController}/allHistorico`)

        },
        AsignaObjetoGasto = async (data) => {
            const response = await PostRoute(`${nameController}/${'ingresarObjeto'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)
            mutate(`${nameController}/allHistorico`)

        },
        AsignaNoCheque = async (data) => {
            const response = await PostRoute(`${nameController}/${'ingresarCheque'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)
            mutate(`${nameController}/allHistorico`)

        },
        OperarTransferencia = async (data) => {
            const response = await PostRoute(`${nameController}/${'operarTransferencia'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)
            mutate(`${nameController}/allHistorico`)

        },
        OperarReintegro = async (data) => {
            const response = await PostRoute(`${nameController}/${'operarReintegro'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)
            mutate(`${nameController}/allHistorico`)

        },
        AsignaNo1D = async (data) => {
            const response = await PostRoute(`${nameController}/${'ingresarNo1D'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)
            mutate(`${nameController}/allHistorico`)

        },
        AsignaNoFolio = async (data) => {
            const response = await PostRoute(`${nameController}/${'ingresarNoFolio'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)
            mutate(`${nameController}/allHistorico`)

        },
        AsignaAnalistaCompra = async (data) => {
            const response = await PostRoute(`${nameController}/${'asignarEnCompras'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)
            mutate(`${nameController}/allHistorico`)

        },
        IngresarExistencia = async (data) => {
            const response = await PostRoute(`${nameController}/${'ingresarExistencia'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)
            mutate(`${nameController}/allHistorico`)

        },
        IngresarCategoria = async (data) => {
            const response = await PostRoute(`${nameController}/${'ingresarCategoria'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)
            mutate(`${nameController}/allHistorico`)

        },
        EnviarARevision = async (data, opcion) => {
            toggleModal(opcion)
            const response = await PostRoute(`${nameController}/${'enviarARevision'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)
            mutate(`${nameController}/allHistorico`)


        },
        Imprimir = async (data) => {
            const response = await PostRoute(`PedidoDetalle/allDetalle`, { id: data?.id })
            const TOTAL = await PostRoute(`PedidoDetalle/totalDetallePedido`, { id: data?.id })
            if (response?.response === 0) {
                toast.error(response.message, OptionsToast)
            } else {
                if (data?.modalidad === 1) {
                    const response3 = await PDFPedido(data, response, TOTAL)
                    //const response3 = await FormatoRazonamientoCarta(data, dataPersonaLog.data[0], dataJefe.data[0])
                    return response3
                }
                if (data?.modalidad === 2) {
                    const response3 = await PDFGasto(data, response, TOTAL)
                    //const response3 = await FormatoRazonamientoCarta(data, dataPersonaLog.data[0], dataJefe.data[0])
                    return response3
                }
                if (data?.modalidad === 3) {
                    const response3 = await PDFCajaChica(data, response, TOTAL)
                    //const response3 = await FormatoRazonamientoCarta(data, dataPersonaLog.data[0], dataJefe.data[0])
                    return response3
                }



            }

        },
        labelTipoPago = [
            { value: 1, label: 'Cheque' },
            { value: 2, label: 'Transferencia' },
          ],
        value = {
            Actions,
            One,
            setId,
            setIdPresupuestoPoa,
            setAgregarRenglon,
            setAccion,
            StoreUpdate,
            toggleModal,
            useDataUser,
            agregarRenglon,
            idAccion,
            allData,
            idPresupuestoPoa,
            labelAcciones,
            labelFand,
            labelJefeDireccion,
            labelTipoGasto,
            labelTipoPedido,
            labelTipoPago,
            labelCategoria,
            modal,
            nameComponent,
            oneData,
            opcion,
            solicitante,
            mutateSaldoAccion, setMutateSaldoAccion,
            code,
            setIdModalidad,
            idModalidad,
            Imprimir,
            pdf, setPdf,
            UpdateEstadoPedido,
            DataBitacoraPedido,
            BitacoraPedido,
            EnviarARevision,
            AsignaPreOrdenCompra,
            IngresarExistencia,
            labelAnalistas,
            labelOjetoGasto,
            AsignaAnalistaCompra,
            nombreSolicitante,
            AsignaOrdenCompra,
            AsignaCUR,
            AsignaObjetoGasto,
            IngresarCategoria,
            AsignaNoCheque,
            UpdateAnalista,
            AsignaCURDEV,
            allDataHistorica,
            AsignaNoFolio,
            idTipoPago, setidTipoPago,
            OperarTransferencia,
            OperarReintegro,
            AsignaNo1D
        }
    // console.log(useDataUser()?.dataUser?.nombreCompleto)
    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}
