
// import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useContext, useEffect, useState, useRef } from 'react'
import { ContentContext } from './context'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback, CardBody, CardTitle } from 'reactstrap'
import { useForm, Controller } from "react-hook-form"
import * as Icon from 'react-feather'
import Select from 'react-select'
import { formatDate, customStyles, stringToDate } from '../../../Globales/index'
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput'
import classnames from 'classnames'
import { Text } from "@nextui-org/react";
import Header from './header'
import Detalle from '../AprobarPedidoDetalle/form'
import { ContentProvider } from '../AprobarPedidoDetalle/context'
import { ContentProvider as ContentProvider2 } from '../AprobarPedido/Adjunto/context'
import Attached from './Adjunto/Attached'
import revision from '../../../assets/images/Checklist.png'
import DatePicker, { registerLocale } from "react-datepicker"
import es from "date-fns/locale/es"
import Moment from 'react-moment';
import 'moment/locale/es-mx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";
import "/node_modules/swiper/swiper.scss";
import { ContentContext as ContentContextTheme } from '../../../components/CommonForBoth/TopbarDropdown/context'

registerLocale("es", es); // register it with the name you want


const Formulario = () => {
    // const [startDate, setStartDate] = useState(Date.parse(2019) + 1);
    const { theme } = useContext(ContentContextTheme)
    const [startDate, setStartDate] = useState(new Date());
    const [validador, setValidador] = useState(false);
    const [tipoRechazo, setTipoRechazo] = useState(0);
    const { toggleModal, modal, StoreUpdate, opcion, oneData, labelAcciones, setId, agregarRenglon, setAgregarRenglon, labelFinanciamiento, idPresupuestoPoa, labelTipoGasto, labelTipoPedido, labelFand, useDataUser, nameComponent, labelJefeDireccion, solicitante, pdf, UpdateEstadoPedido, DataBitacoraPedido, EnviarARevision, AsignaPreOrdenCompra, IngresarExistencia, labelAnalistas, AsignaAnalistaCompra, AsignaOrdenCompra, AsignaCUR, AsignaObjetoGasto, AsignaNoCheque, idModalidad, labelOjetoGasto, UpdateAnalista, AsignaCURDEV, AsignaNoFolio, labelTipoPago, idTipoPago, setidTipoPago, OperarTransferencia, labelCategoria, IngresarCategoria, OperarReintegro, AsignaNo1D } = useContext(ContentContext),
        { register, handleSubmit, formState: { errors }, control, reset, watch } = useForm(),
        inputTipoRechazo = watch("tipoRechazo"),
        inputTipoPago = watch("tipoPago"),
        onSubmitEstado = (data) => {
            if (opcion === 7) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: Number(data?.tipoRechazo),
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 1
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 8) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: Number(data?.existencia) === 1 ? 14 : 5,
                    existencia: Number(data?.existencia)
                }

                UpdateEstadoPedido(json)
                IngresarExistencia(json)
            }
            if (opcion === 9) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 3
                }

                UpdateEstadoPedido(json)
            }
            if (opcion === 10) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: Number(data?.tipoRechazo),
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 1
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 11) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 4,
                    objetoGasto: data?.objetoGasto?.value
                }
                UpdateEstadoPedido(json)
                AsignaObjetoGasto(json)
                // if (idModalidad === 2) {
                // }
            }
            if (opcion === 12) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: Number(data?.tipoRechazo),
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 1
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 13) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 6,
                    noPreordenCompra: data?.preorden
                }
                UpdateEstadoPedido(json)
                AsignaPreOrdenCompra(json)
            }
            if (opcion === 14) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: Number(data?.tipoRechazo),
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 1
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 15) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    // tipoRechazo: null,
                    descripcion: (data?.descripcionAsignar?.length !== 0) ? data?.descripcionAsignar : null,
                    estado: 8,
                    tecnicoCompras: data?.labelAnalistas?.value
                }
                AsignaAnalistaCompra(json)
            }
            if (opcion === 16) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: Number(data?.tipoRechazo),
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 1
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 17) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 15
                }

                UpdateEstadoPedido(json)
            }
            if (opcion === 18) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 17,
                    noOrdenCompra: data?.noOrdenCompra
                }
                UpdateEstadoPedido(json)
                AsignaOrdenCompra(json)
            }
            if (opcion === 19) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: Number(data?.tipoRechazo),
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 1
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 20) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 18,
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 21) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: 3,
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 33,
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 22) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: oneData?.modalidad === 1 ? 20 : 35,
                    cur: data?.cur
                }
                const json2 = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 49,
                    cur: data?.cur
                }
                if (oneData?.idEstado === 73) {

                    UpdateEstadoPedido(json2)
                    AsignaCUR(json2)
                } else {

                    UpdateEstadoPedido(json)
                    AsignaCUR(json)
                }

            }
            if (opcion === 23) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 20,
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 24) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 21,
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 25) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 22,
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 26) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: Number(data?.tipoRechazo),
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 1
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 27) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 18
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 28) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 40
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 29) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 23
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 30) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 24
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 31) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 35
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 32) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: oneData?.fand === 'N/A' ? 4 : null,
                    descripcion: oneData?.fand === 'N/A' ? 'No aplica espera de documentos de respaldo no es FADN/ASOC.' : null,
                    estado: oneData?.fand === 'N/A' ? 18 : 25
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 33) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: oneData?.idEstado === 75 ? 48 : 28,
                    cur: data?.cur
                }
                UpdateEstadoPedido(json)
                AsignaCURDEV(json)
            }
            if (opcion === 34) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 28
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 35) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    tipoPago: data?.tipoPago?.value,
                    descripcion: null,
                    noCheque: data?.noCheque,
                    estado: idTipoPago === 1 ? 29 : 44
                }
                UpdateEstadoPedido(json)
                AsignaNoCheque(json)
            }
            if (opcion === 36) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 30
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 37) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 36
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 38) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 37
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 41) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 38
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 42) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 31
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 43) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    categoriaExpediente: data?.categoriaExpediente?.value,
                    estado: 32
                }
                IngresarCategoria(json)
                UpdateEstadoPedido(json)
            }
            if (opcion === 44) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 39
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 45) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 26
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 46) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    noFolios: data?.folios,
                    estado: 42
                }
                UpdateEstadoPedido(json)
                AsignaNoFolio(json)
            }
            if (opcion === 47) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    noTransferenciaNDPRO: data?.noCheque,
                    estado: 45
                }
                UpdateEstadoPedido(json)
                OperarTransferencia(json)
            }



            if (opcion === 39) {//Cambiar Analista
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tecnicoCompras: data?.labelAnalistas?.value
                }
                UpdateAnalista(json)
            }
            if (opcion === 40) {//operar anulacion
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 0,
                }
                UpdateEstadoPedido(json)
            }

            //LIQUIDACIONES REINTEGROS DE GASTOS
            if (opcion === 100) { //operar reintegro de gastos
                const json = {
                    id: (oneData) ? oneData?.id : null,
                }
                OperarReintegro(json)

            }
            if (opcion === 101) { //Ingresare 1D
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 50,
                    no1D: data?.no1D
                }
                UpdateEstadoPedido(json)
                AsignaNo1D(json)

            }
            reset()
            toggleModal(0)
        };

    const prevInputTipoRechazo = useRef(null);

    useEffect(() => {
        if (!isNaN(inputTipoRechazo)) {
            setTipoRechazo(Number(inputTipoRechazo));
            prevInputTipoRechazo.current = inputTipoRechazo;
        }
    }, [inputTipoRechazo]);


    useEffect(() => {
        let abortController = new AbortController();
        async function fetchMyAPI() {
            if (inputTipoPago) {
                if (inputTipoPago?.value !== 0) {
                    setidTipoPago(inputTipoPago?.value)
                } else {
                    setidTipoPago(0)
                }
            } if (inputTipoPago === null || inputTipoPago === '') {
                setidTipoPago(0)
            }
        }
        fetchMyAPI()
        return () => { abortController.abort(); }
    }, [inputTipoPago])


    // console.log(oneData)
    // console.log(idModalidad)
    return (
        <Fragment>
            {/* <Button onClick={() => toggleModal(1)} id="crear" color="primary" size="sm">
                <Icon.Plus size={16} />
            </Button>
            <UncontrolledTooltip placement='left' target='crear' placementPrefix="bs-tooltip">
                Crear
            </UncontrolledTooltip> */}
            {/* Opcion Crear - Actualizar */}
            {opcion > 0 && opcion < 5 && <Modal
                isOpen={modal}
                backdrop='static'
                style={{ maxWidth: '1600px', width: '95%', margin: '10px auto' }}
                toggle={() => toggleModal()}
                className='modal-dialog-centered modal-xl'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}> {opcion === 1 ? "Crear" : opcion === 3 ? "Actualizar" : "Visualizar"}{` ${nameComponent}`}</ModalHeader>
                <ModalBody style={(opcion === 1 ? {} : { backgroundColor: 'rgb(0 0 0 / 7%)', paddingBottom: '0' })}>
                    <Header />
                    {(oneData && oneData.id) &&
                        <ContentProvider>
                            <Detalle oneData={oneData} agregarRenglon={agregarRenglon} setAgregarRenglon={setAgregarRenglon} />
                        </ContentProvider>
                    }
                </ModalBody>
            </Modal>}
            {/* Opcion Ver PROCESO */}
            {opcion === 6 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered modal-xl'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>Proceso</ModalHeader>
                <Form autoComplete="off"
                >
                    <ModalBody>
                        <>
                            <Row>
                                <Col lg="12">
                                    <div>
                                        <CardBody>
                                            <CardTitle className="mb-4 text-center">Tiempo transcurrido: {DataBitacoraPedido && DataBitacoraPedido[DataBitacoraPedido.length - 1]?.tiempoTranscurrido} || Solicitado:   <Moment locale="es" format='lll' fromNow>{DataBitacoraPedido && DataBitacoraPedido[0]?.fechaCreado}</Moment></CardTitle>
                                            {/* <h5 className="mb-4">Línea de Tiempo</h5> */}

                                            <div className="hori-timeline">
                                                <Swiper
                                                    slidesPerView={1}
                                                    // spaceBetween={10}
                                                    navigation
                                                    pagination={{
                                                        clickable: true,
                                                    }}
                                                    breakpoints={{
                                                        678: {
                                                            slidesPerView: 2,
                                                        },
                                                        992: {
                                                            slidesPerView: 3,
                                                        },
                                                        1400: {
                                                            slidesPerView: 4,
                                                        }
                                                    }}
                                                    loop={false}
                                                    modules={[Pagination, Navigation]}
                                                    className="owl-carousel owl-theme  navs-carousel events"
                                                    id="timeline-carousel"
                                                >
                                                    {DataBitacoraPedido && DataBitacoraPedido?.map((item, index) => (
                                                        <SwiperSlide
                                                            key={index}
                                                            className="item event-list"
                                                            style={{ display: "inline-table" }}
                                                        >
                                                            <div>
                                                                <div className="event-date">
                                                                    <div className="text-primary mb-1">
                                                                        <Moment locale="es" format='lll' fromNow>{item?.creado}</Moment>
                                                                    </div>
                                                                    <h5 className="mb-4">{item?.estadoTexto}</h5>
                                                                </div>
                                                                <div className="event-down-icon">
                                                                    <i className={`bx ${item?.estadoTexto === 'Rechazado' ? 'bx-x-circle text-danger' : 'bx-check-circle text-success'} h1  down-arrow-icon`} />
                                                                </div>

                                                                <div className="mt-3 px-3">
                                                                    <p className="text-muted">
                                                                        {item?.usuario}
                                                                    </p>
                                                                </div>
                                                                <div className="mt-3 px-3">
                                                                    <p className="text-muted">
                                                                        <strong>{item?.rechazoTipoTexto}</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}

                                                </Swiper>
                                            </div>
                                        </CardBody>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Regresar
                        </UncontrolledTooltip>
                        {/* {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Printer size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    Imprimir
                                </UncontrolledTooltip>
                            </Fragment>
                        } */}
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Ver PDF */}
            {opcion === 5 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered modal-xl'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>PDF</ModalHeader>
                <Form autoComplete="off"
                >
                    <ModalBody>
                        <Row>
                            <Col >
                                <object
                                    className='Visor'
                                    width='100%'
                                    height='600vh'
                                    id='pdf-ficha'
                                    aria-labelledby='perfil'
                                    type='application/pdf'
                                    // data={`https://s1.q4cdn.com/806093406/files/doc_downloads/test.pdf`}
                                    data={`${pdf}`}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Regresar
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <a className="btn btn-success " id="imprimir" download="pedido.pdf" href={`${pdf}`} target="_blank" rel="noopener noreferrer"><Icon.Download size={16} /> Descargar</a>
                                {/* <Button color='success' type="submit" id="accion">
                                    <Icon.Printer size={16} />
                                </Button> */}
                                {/* <UncontrolledTooltip placement='top' target='imprimir' placementPrefix="bs-tooltip">
                                    Imprimir
                                </UncontrolledTooltip> */}
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Aprobar Almacén */}
            {opcion === 8 && <Modal
                isOpen={modal}
                backdrop='static'
                style={{ maxWidth: '1600px', width: '95%', margin: '10px auto' }}
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 8 && 'Actualizar'} Solicitud</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                    <ModalBody>
                        <Row>
                            <Header />
                            {(oneData && oneData.id) &&
                                <ContentProvider>
                                    <Detalle oneData={oneData} agregarRenglon={agregarRenglon} setAgregarRenglon={setAgregarRenglon} />
                                </ContentProvider>
                            }
                        </Row>
                        <Row>
                            <Label className=" justify-content-between">Indique Estado de Insumo</Label>
                            <Col >
                                <div className="form-check mb-2">
                                    <input className="form-check-input" {...register("existencia", { required: true })} type="radio" value={1} />
                                    <label className="form-check-label" htmlFor={1}>
                                        En Existencia
                                    </label>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" {...register("existencia", { required: true })} type="radio" value={2} />
                                    <label className="form-check-label" htmlFor={2}>
                                        Sin Existencia
                                    </label>
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 8 && 'Aprobar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Aprobar Unidad */}
            {opcion === 9 && <Modal
                isOpen={modal}
                backdrop='static'
                style={{ maxWidth: '1600px', width: '95%', margin: '10px auto' }}
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 9 && 'Aprobar'} Solicitud</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                    <ModalBody>
                        <Row >
                            <Header />
                            {(oneData && oneData.id) &&
                                <ContentProvider>
                                    <Detalle oneData={oneData} agregarRenglon={agregarRenglon} setAgregarRenglon={setAgregarRenglon} />
                                </ContentProvider>
                            }
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    ¿Está seguro de que todo está bien y desea proceder?
                                </Text>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 9 && 'Aprobar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Rechazar Unidad */}
            {opcion === 10 && <Modal
                isOpen={modal}
                backdrop='static'
                style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 10 && 'Rechazar'} esta solicitud de compra?</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                    <ModalBody>
                        <Row>
                            <Label className=" justify-content-between">Motivos de Rechazo</Label>
                            <Col >
                                <div className="form-check mb-2">
                                    <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={1} />
                                    <label className="form-check-label" htmlFor={1}>
                                        Cambio en Justificación
                                    </label>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={2} />
                                    <label className="form-check-label" htmlFor={2}>
                                        Cambio en Descripción / Insumo
                                    </label>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={3} />
                                    <label className="form-check-label" htmlFor={3}>
                                        Otro (Especifique)
                                    </label>
                                </div>
                            </Col>

                        </Row>
                        {tipoRechazo === 3 &&
                            <Row>
                                <Col >
                                    <FormGroup>
                                        <Label for="descripcion" className="">* Motivo Rechazo</Label>
                                        <Controller

                                            control={control}
                                            id='descripcion'
                                            name='descripcion'
                                            render={({ field }) => (
                                                <Input
                                                    rows={2}
                                                    type='textarea'
                                                    // readOnly={updateData}
                                                    invalid={errors.descripcion && true}
                                                    defaultValue=''
                                                    {...field}
                                                />
                                            )
                                            }
                                            rules={
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido'
                                                    }
                                                }
                                            }
                                        />
                                        <FormFeedback>
                                            {!!errors.descripcion && errors.descripcion.message}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Save size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 10 && 'Operar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Aprobar Financiero */}
            {opcion === 11 && <Modal
                isOpen={modal}
                backdrop='static'
                style={{ maxWidth: '1600px', width: '95%', margin: '10px auto' }}
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 11 && 'Aprobar'} Solicitud</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                    <ModalBody>
                        <Row >
                            <Header />
                            {(oneData && oneData.id) &&
                                <ContentProvider>
                                    <Detalle oneData={oneData} agregarRenglon={agregarRenglon} setAgregarRenglon={setAgregarRenglon} />
                                </ContentProvider>
                            }
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    ¿Está seguro de que todo está bien y desea proceder?
                                </Text>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                {

                                    <FormGroup>
                                        <Label for="objetoGasto" className="d-flex justify-content-between">
                                            <span>*  Objeto de la solicitud</span>
                                        </Label>
                                        <Controller
                                            name="objetoGasto"
                                            control={control}
                                            render={({ field }) => {
                                                return (
                                                    <Select
                                                        {...field}
                                                        isClearable
                                                        onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                        onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                        defaultValue={null}
                                                        options={labelOjetoGasto}
                                                        // theme={selectThemeColors}
                                                        // isDisabled={!allMedidaInsumo}
                                                        className='selector-react1'
                                                        classNamePrefix='select'
                                                        placeholder={'Seleccione una opción'}
                                                        noOptionsMessage={() => 'sin resultados'}
                                                        styles={(!!errors.objetoGasto) ? customStyles : {}}
                                                    />
                                                )
                                            }
                                            }
                                            rules={
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido'
                                                    }
                                                }
                                            }
                                        />
                                        <small className="text-danger">
                                            {!!errors.objetoGasto && errors.objetoGasto.message}
                                        </small>
                                    </FormGroup>
                                }
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 11 && 'Aprobar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Rechazar Financiero */}
            {
                opcion === 12 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 12 && 'Rechazar'} esta solicitud de compra?</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>
                            <Row>
                                <Label className=" justify-content-between">Motivos de Rechazo</Label>
                                <Col >
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={1} />
                                        <label className="form-check-label" htmlFor={1}>
                                            Cambio en Justificación
                                        </label>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={2} />
                                        <label className="form-check-label" htmlFor={2}>
                                            Cambio en Descripción / Insumo
                                        </label>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={3} />
                                        <label className="form-check-label" htmlFor={3}>
                                            Otro (Especifique)
                                        </label>
                                    </div>
                                </Col>

                            </Row>
                            {tipoRechazo === 3 &&
                                <Row>
                                    <Col >
                                        <FormGroup>
                                            <Label for="descripcion" className="">* Motivo Rechazo</Label>
                                            <Controller

                                                control={control}
                                                id='descripcion'
                                                name='descripcion'
                                                render={({ field }) => (
                                                    <Input
                                                        rows={2}
                                                        type='textarea'
                                                        // readOnly={updateData}
                                                        invalid={errors.descripcion && true}
                                                        defaultValue=''
                                                        {...field}
                                                    />
                                                )
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <FormFeedback>
                                                {!!errors.descripcion && errors.descripcion.message}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            {
                                opcion !== 2 &&
                                <Fragment>
                                    <Button color='success' type="submit" id="accion">
                                        <Icon.Save size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                        {opcion === 12 && 'Operar'}
                                    </UncontrolledTooltip>
                                </Fragment>
                            }
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Aprobar Planificacion  */}
            {
                opcion === 13 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '1600px', width: '95%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 13 && 'Aprobar'} Solicitud</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>
                            <Row >
                                <Header />
                                {(oneData && oneData.id) &&
                                    <ContentProvider>
                                        <Detalle oneData={oneData} agregarRenglon={agregarRenglon} setAgregarRenglon={setAgregarRenglon} />
                                    </ContentProvider>
                                }

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                    <FormGroup>
                                        <Label for="preorden"> * No. Pre Orden de compra </Label>
                                        <Controller
                                            // defaultValue='undefined'
                                            control={control}
                                            id='preorden'
                                            name='preorden'
                                            render={({ field }) => (
                                                <Input
                                                    type='number'
                                                    // readOnly={opcion === 2}
                                                    invalid={errors.preorden && true}
                                                    {...field}
                                                />
                                            )
                                            }
                                            rules={
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido'
                                                    }
                                                }
                                            }
                                        />
                                        <FormFeedback>
                                            {!!errors.preorden && errors.preorden.message}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>


                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            {
                                opcion !== 2 &&
                                <Fragment>
                                    <Button color='success' type="submit" id="accion">
                                        <Icon.CheckSquare size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                        {opcion === 13 && 'Aprobar'}
                                    </UncontrolledTooltip>
                                </Fragment>
                            }
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Rechazar Planificacion */}
            {
                opcion === 14 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 14 && 'Rechazar'} esta solicitud de compra?</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>
                            <Row>
                                <Label className=" justify-content-between">Motivos de Rechazo</Label>
                                <Col >
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={1} />
                                        <label className="form-check-label" htmlFor={1}>
                                            Cambio en Justificación
                                        </label>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={2} />
                                        <label className="form-check-label" htmlFor={2}>
                                            Cambio en Descripción / Insumo
                                        </label>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={3} />
                                        <label className="form-check-label" htmlFor={3}>
                                            Otro (Especifique)
                                        </label>
                                    </div>
                                </Col>

                            </Row>
                            {tipoRechazo === 3 &&
                                <Row>
                                    <Col >
                                        <FormGroup>
                                            <Label for="descripcion" className="">* Motivo Rechazo</Label>
                                            <Controller

                                                control={control}
                                                id='descripcion'
                                                name='descripcion'
                                                render={({ field }) => (
                                                    <Input
                                                        rows={2}
                                                        type='textarea'
                                                        // readOnly={updateData}
                                                        invalid={errors.descripcion && true}
                                                        defaultValue=''
                                                        {...field}
                                                    />
                                                )
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <FormFeedback>
                                                {!!errors.descripcion && errors.descripcion.message}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            {
                                opcion !== 2 &&
                                <Fragment>
                                    <Button color='success' type="submit" id="accion">
                                        <Icon.Save size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                        {opcion === 14 && 'Operar'}
                                    </UncontrolledTooltip>
                                </Fragment>
                            }
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Ingreso a Compras Asigna Tecnico  */}
            {
                opcion === 15 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '1600px', width: '95%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 15 && 'Aprobar'} Solicitud</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>
                            <Row >
                                <Header />
                                {(oneData && oneData.id) &&
                                    <ContentProvider>
                                        <Detalle oneData={oneData} agregarRenglon={agregarRenglon} setAgregarRenglon={setAgregarRenglon} />
                                    </ContentProvider>
                                }

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {

                                        <FormGroup>
                                            <Label for="labelAnalistas" className="d-flex justify-content-between">
                                                <span>*  Analista</span>
                                            </Label>
                                            <Controller
                                                name="labelAnalistas"
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            {...field}
                                                            isClearable
                                                            onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                            onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                            defaultValue={null}
                                                            options={labelAnalistas}
                                                            // theme={selectThemeColors}
                                                            // isDisabled={!allMedidaInsumo}
                                                            className='selector-react1'
                                                            classNamePrefix='select'
                                                            placeholder={'Seleccione una opción'}
                                                            noOptionsMessage={() => 'sin resultados'}
                                                            styles={(!!errors.labelAnalistas) ? customStyles : {}}
                                                        />
                                                    )
                                                }
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <small className="text-danger">
                                                {!!errors.labelAnalistas && errors.labelAnalistas.message}
                                            </small>
                                        </FormGroup>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col >
                                    <FormGroup>
                                        <Label for="descripcionAsignar" className="">Observaciones (opcional)</Label>
                                        <Controller

                                            control={control}
                                            id='descripcionAsignar'
                                            name='descripcionAsignar'
                                            render={({ field }) => (
                                                <Input
                                                    rows={2}
                                                    type='textarea'
                                                    // readOnly={updateData}
                                                    // invalid={errors.descripcion && true}
                                                    defaultValue=''
                                                    {...field}
                                                />
                                            )
                                            }

                                        />

                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            {
                                opcion !== 2 &&
                                <Fragment>
                                    <Button color='success' type="submit" id="accion">
                                        <Icon.CheckSquare size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                        {opcion === 15 && 'Aprobar'}
                                    </UncontrolledTooltip>
                                </Fragment>
                            }
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Rechazar Ingreso Compra */}
            {
                opcion === 16 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 16 && 'Rechazar'} esta solicitud de compra?</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>
                            <Row>
                                <Label className=" justify-content-between">Motivos de Rechazo</Label>
                                <Col >
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={1} />
                                        <label className="form-check-label" htmlFor={1}>
                                            Cambio en Justificación
                                        </label>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={2} />
                                        <label className="form-check-label" htmlFor={2}>
                                            Cambio en Descripción / Insumo
                                        </label>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={3} />
                                        <label className="form-check-label" htmlFor={3}>
                                            Otro (Especifique)
                                        </label>
                                    </div>
                                </Col>

                            </Row>
                            {tipoRechazo === 3 &&
                                <Row>
                                    <Col >
                                        <FormGroup>
                                            <Label for="descripcion" className="">* Motivo Rechazo</Label>
                                            <Controller

                                                control={control}
                                                id='descripcion'
                                                name='descripcion'
                                                render={({ field }) => (
                                                    <Input
                                                        rows={2}
                                                        type='textarea'
                                                        // readOnly={updateData}
                                                        invalid={errors.descripcion && true}
                                                        defaultValue=''
                                                        {...field}
                                                    />
                                                )
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <FormFeedback>
                                                {!!errors.descripcion && errors.descripcion.message}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            {
                                opcion !== 2 &&
                                <Fragment>
                                    <Button color='success' type="submit" id="accion">
                                        <Icon.Save size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                        {opcion === 16 && 'Operar'}
                                    </UncontrolledTooltip>
                                </Fragment>
                            }
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Inicio de Proceso Cotizacion  */}
            {
                opcion === 17 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    // style={{ maxWidth: '1600px', width: '95%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 17 && 'Iniciar Proceso de Cotización de'} Solicitud</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>
                            <Row >
                                <Col className="text-center">
                                    <Text
                                        h2
                                        size={20}
                                        weight="medium"
                                    >
                                        Esta acción cambiara el estado de la solicitud
                                    </Text>
                                    <Text
                                        h1
                                        size={24}
                                        color='warning'
                                        weight="medium"
                                    >
                                        ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            {
                                opcion !== 2 &&
                                <Fragment>
                                    <Button color='success' type="submit" id="accion">
                                        <Icon.CheckSquare size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                        {opcion === 17 && 'Aprobar'}
                                    </UncontrolledTooltip>
                                </Fragment>
                            }
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Adjudicar OC  */}
            {
                opcion === 18 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 18 && 'INGRESO ORDEN DE COMPRA A'} Solicitud</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                    <Row >

                                        <FormGroup>
                                            <Label for="noOrdenCompra"> * No. Orden de compra </Label>
                                            <Controller
                                                // defaultValue
                                                control={control}
                                                id='noOrdenCompra'
                                                name='noOrdenCompra'
                                                render={({ field }) => (
                                                    <Input
                                                        type='number'
                                                        // readOnly={opcion === 2}
                                                        invalid={errors.noOrdenCompra && true}
                                                        {...field}
                                                    />
                                                )
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <FormFeedback>
                                                {!!errors.noOrdenCompra && errors.noOrdenCompra.message}
                                            </FormFeedback>
                                        </FormGroup>

                                    </Row>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 18 && 'Aprobar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Anular Solicitud */}
            {
                opcion === 21 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 21 && 'SOLICITAR ANULACION DE'} proceso de compra</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>
                            <Row>
                                <Label className=" justify-content-between">Motivos</Label>
                                <Col >
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={1} />
                                        <label className="form-check-label" htmlFor={1}>
                                            Cambio en Justificación
                                        </label>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={2} />
                                        <label className="form-check-label" htmlFor={2}>
                                            Cambio en Descripción / Insumo
                                        </label>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={3} />
                                        <label className="form-check-label" htmlFor={3}>
                                            Otro (Especifique)
                                        </label>
                                    </div>
                                </Col>

                            </Row>
                            {tipoRechazo === 3 &&
                                <Row>
                                    <Col >
                                        <FormGroup>
                                            <Label for="descripcion" className="">* Otro Motivo </Label>
                                            <Controller

                                                control={control}
                                                id='descripcion'
                                                name='descripcion'
                                                render={({ field }) => (
                                                    <Input
                                                        rows={2}
                                                        type='textarea'
                                                        // readOnly={updateData}
                                                        invalid={errors.descripcion && true}
                                                        defaultValue=''
                                                        {...field}
                                                    />
                                                )
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <FormFeedback>
                                                {!!errors.descripcion && errors.descripcion.message}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            {
                                opcion !== 2 &&
                                <Fragment>
                                    <Button color='success' type="submit" id="accion">
                                        <Icon.Save size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                        {opcion === 21 && 'Operar'}
                                    </UncontrolledTooltip>
                                </Fragment>
                            }
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Recibir Expediente de Compras  */}
            {
                opcion === 20 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 20 && 'REcibir en financiero la '} Solicitud</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 20 && 'Aprobar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Recibir para COMPROMISO  */}
            {
                opcion === 27 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 27 && 'REcibir EN SORF PARA COMPROMISO la '} Solicitud</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 27 && 'Recibir'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Ingresar CUR  */}
            {
                opcion === 22 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 22 && 'ASIGNAR CUR a '} Solicitud</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, Si debe ingresar un nuevo CUR COM, ingreselo separado por comas sin eliminar el anterior.
                                    </Text>
                                </Col>
                            </Row>
                            <Row >
                                <FormGroup>
                                    <Label for="cur"> * CUR </Label>
                                    <Controller
                                        defaultValue={oneData && oneData?.cur}
                                        control={control}
                                        id='cur'
                                        name='cur'
                                        render={({ field }) => (
                                            <Input
                                                type='text'
                                                // readOnly={opcion === 2}
                                                invalid={errors.cur && true}
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.cur && errors.cur.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 22 && 'Asignar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Aprobar CUR  */}
            {
                opcion === 23 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 23 && 'APROBAR CUR COMPROMISO DE '} Solicitud</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    CUR: <p className='text-danger fw-bold fs-1'>{oneData?.cur}</p>
                                </Text>
                            </Col>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 23 && 'Aprobar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Recibir Expediente de Financiero  */}
            {
                opcion === 24 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 24 && 'REcibir de COMPROMISO el EXPEDIENTE DE la '} Solicitud en COMPRAS</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 24 && 'Aprobar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Iniciar Liquidación Compras  */}
            {
                opcion === 25 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '1800px', width: '95%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>
                        <ul className="nav nav-tabs nav-tabs-custom nav-line-tabs-2x mb-1 fs-5 border-transparent fw-bolder" role="tablist">
                            <li className="nav-item" role="presentation" >
                                <a
                                    className="nav-link active"
                                    data-bs-toggle="tab"
                                    href="#liquidar"
                                    role="tab"
                                    aria-selected="false"
                                >
                                    <span className="d-block d-sm-none">
                                        <i className="fas fa-cog" />
                                    </span>
                                    <span className="d-none d-sm-block">{opcion === 25 && 'proceso de liquidación de'} Solicitud</span>
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link"
                                    data-bs-toggle="tab"
                                    href="#carga"
                                    role="tab"
                                    aria-selected="false"
                                    tabIndex={-1}
                                >
                                    <span className="d-block d-sm-none">
                                        <i className="far fa-envelope" />
                                    </span>
                                    <span className="d-none d-sm-block">Adjuntos</span>
                                </a>
                            </li>
                        </ul>
                    </ModalHeader>
                    <div className="tab-content p-3 text-muted">
                        <div className="tab-pane active show" id="liquidar" role="tabpanel">
                            <ModalBody>
                                <Row >
                                    <Header />
                                    {(oneData && oneData.id) &&
                                        <ContentProvider>
                                            <Detalle oneData={oneData} agregarRenglon={agregarRenglon} setAgregarRenglon={setAgregarRenglon} opcion={opcion} />
                                        </ContentProvider>
                                    }
                                </Row>
                            </ModalBody>
                        </div>
                        <div className="tab-pane" id="carga" role="tabpanel">
                            <div className="p-3">
                                <ContentProvider2>
                                    <Attached />
                                </ContentProvider2>
                            </div>
                        </div>
                    </div>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                    </ModalFooter>
                </Modal>
            }
            {/* Opcion Rechazar Unidad */}
            {
                opcion === 26 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 26 && 'Rechazar'} esta solicitud de compra?</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>
                            <Row>
                                <Label className=" justify-content-between">Motivos de Rechazo</Label>
                                <Col >
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={1} />
                                        <label className="form-check-label" htmlFor={1}>
                                            Cambio en Justificación
                                        </label>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={2} />
                                        <label className="form-check-label" htmlFor={2}>
                                            Cambio en Descripción / Insumo
                                        </label>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={3} />
                                        <label className="form-check-label" htmlFor={3}>
                                            Otro (Especifique)
                                        </label>
                                    </div>
                                </Col>

                            </Row>
                            {tipoRechazo === 3 &&
                                <Row>
                                    <Col >
                                        <FormGroup>
                                            <Label for="descripcion" className="">* Motivo Rechazo</Label>
                                            <Controller

                                                control={control}
                                                id='descripcion'
                                                name='descripcion'
                                                render={({ field }) => (
                                                    <Input
                                                        rows={2}
                                                        type='textarea'
                                                        // readOnly={updateData}
                                                        invalid={errors.descripcion && true}
                                                        defaultValue=''
                                                        {...field}
                                                    />
                                                )
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <FormFeedback>
                                                {!!errors.descripcion && errors.descripcion.message}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            {
                                opcion !== 2 &&
                                <Fragment>
                                    <Button color='success' type="submit" id="accion">
                                        <Icon.Save size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                        {opcion === 26 && 'Rechazar'}
                                    </UncontrolledTooltip>
                                </Fragment>
                            }
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Solicitar doctos soporte compras liq */}
            {
                opcion === 28 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 28 && 'SOLICITAR DOCUMENTOS DE SOPORTE DE '} Solicitud</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                            {/* <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    CUR: <p className='text-danger fw-bold fs-1'>{oneData?.cur}</p>
                                </Text>
                            </Col> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 28 && 'Solicitar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Recibir doctos soporte compras liq */}
            {
                opcion === 29 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 29 && 'RECIBIR DOCUMENTOS DE SOPORTE DE '} Solicitud</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                            {/* <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    CUR: <p className='text-danger fw-bold fs-1'>{oneData?.cur}</p>
                                </Text>
                            </Col> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 29 && 'Recibir'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Finalizar liq */}
            {
                opcion === 30 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 30 && 'FINALIZAR PROCESO DE LIQUIDACIÓN DE '} Solicitud</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                            {/* <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    CUR: <p className='text-danger fw-bold fs-1'>{oneData?.cur}</p>
                                </Text>
                            </Col> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 30 && 'Finalizar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Recibir DEV PDD */}
            {
                opcion === 31 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 31 && 'RECIBIR PARA DEVENGADO LA '} Solicitud</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                            {/* <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    CUR: <p className='text-danger fw-bold fs-1'>{oneData?.cur}</p>
                                </Text>
                            </Col> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 31 && 'Recibir'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Recibir DEV GST */}
            {
                opcion === 32 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 32 && 'RECIBIR PARA DEVENGADO LA '} Solicitud</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                            {/* <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    CUR: <p className='text-danger fw-bold fs-1'>{oneData?.cur}</p>
                                </Text>
                            </Col> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 32 && 'Recibir'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Ingresar CUR DEV PDD */}
            {
                opcion === 33 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 33 && 'ASIGNAR CUR DEVENGADO PDD a '} Solicitud</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, Si debe ingresar un nuevo CUR DEV, ingreselo separado por comas sin eliminar el anterior.
                                    </Text>
                                </Col>
                            </Row>
                            <Row >
                                <FormGroup>
                                    <Label for="cur"> * CUR DEV </Label>
                                    <Controller
                                        defaultValue={oneData && oneData?.curDev}
                                        control={control}
                                        id='cur'
                                        name='cur'
                                        render={({ field }) => (
                                            <Input
                                                type='text'
                                                // readOnly={opcion === 2}
                                                invalid={errors.cur && true}
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.cur && errors.cur.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 33 && 'Asignar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Aprobar CUR DEV */}
            {
                opcion === 34 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 34 && 'APROBAR CUR DEVENGADO DE '} Solicitud</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    CUR DEV: <p className='text-danger fw-bold fs-1'>{oneData?.curDev}</p>
                                </Text>
                            </Col>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 34 && 'Aprobar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Ingresar No CHEQUE */}
            {
                opcion === 35 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 35 && 'INGRESAR TIPO DE PAGO A '} Solicitud</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {

                                        <FormGroup>
                                            <Label for="tipoPago" className="d-flex justify-content-between">
                                                <span>*  Tipo de Pago</span>
                                            </Label>
                                            <Controller
                                                name="tipoPago"
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            {...field}
                                                            isClearable
                                                            onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                            onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                            defaultValue={null}
                                                            options={labelTipoPago}
                                                            // theme={selectThemeColors}
                                                            // isDisabled={!allMedidaInsumo}
                                                            className='selector-react1'
                                                            classNamePrefix='select'
                                                            placeholder={'Seleccione una opción'}
                                                            noOptionsMessage={() => 'sin resultados'}
                                                            styles={(!!errors.tipoPago) ? customStyles : {}}
                                                        />
                                                    )
                                                }
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <small className="text-danger">
                                                {!!errors.tipoPago && errors.tipoPago.message}
                                            </small>
                                        </FormGroup>
                                    }
                                </Col>
                            </Row>

                            {idTipoPago === 1 &&
                                <Row >
                                    <FormGroup>
                                        {/* <Label for="noCheque"> * No.Cheque o Transferencia </Label> */}
                                        <Label for="noCheque"> * No. Cheque</Label>
                                        <Controller
                                            // defaultValue
                                            control={control}
                                            id='noCheque'
                                            name='noCheque'
                                            render={({ field }) => (
                                                <Input
                                                    type='text'
                                                    // readOnly={opcion === 2}
                                                    invalid={errors.noCheque && true}
                                                    {...field}
                                                />
                                            )
                                            }
                                            rules={
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido'
                                                    }
                                                }
                                            }
                                        />
                                        <FormFeedback>
                                            {!!errors.noCheque && errors.noCheque.message}
                                        </FormFeedback>
                                    </FormGroup>
                                </Row>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 35 && 'Ingresar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion FIRMAR CHEQUE SORF */}
            {
                opcion === 36 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 36 && 'MARCAR COMO CHEQUE FIRMADO POR SORF LA '} Solicitud</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                            {/* <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    CUR: <p className='text-danger fw-bold fs-1'>{oneData?.cur}</p>
                                </Text>
                            </Col> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 36 && 'Marcar como firmado'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion RECIBIR CHEQUE GG */}
            {
                opcion === 37 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 37 && 'RECIBIR CHEQUE EN GG '} </ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                            {/* <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    CUR: <p className='text-danger fw-bold fs-1'>{oneData?.cur}</p>
                                </Text>
                            </Col> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 37 && 'Marcar como Recibido GG'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion FIRMAR CHEQUE GG */}
            {
                opcion === 38 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 38 && 'MARCAR COMO CHEQUE FIRMADO POR GG '} </ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                            {/* <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    CUR: <p className='text-danger fw-bold fs-1'>{oneData?.cur}</p>
                                </Text>
                            </Col> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 38 && 'Marcar como firmado GG'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion RECIBIR CHEQUE FIRMADO */}
            {
                opcion === 41 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 41 && 'RECIBIR CHEQUE FIRMADO POR SORF Y GG '} </ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                            {/* <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    CUR: <p className='text-danger fw-bold fs-1'>{oneData?.cur}</p>
                                </Text>
                            </Col> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 41 && 'Recibir'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Entregar CHEQUE (PAGAR A PROVEEDOR) */}
            {
                opcion === 42 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 42 && 'ENTREGAR CHEQUE (PAGADO) '} </ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                            {/* <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    CUR: <p className='text-danger fw-bold fs-1'>{oneData?.cur}</p>
                                </Text>
                            </Col> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 42 && 'Entregar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion RECIBIR EXPEDIENTE EN ARCHIVO */}
            {
                opcion === 43 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 43 && 'MARCAR COMO EXPEDIENTE RECIBIDO EN ARCHIVO '} </ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                            <Row >
                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                    >
                                        CUR DEV: <p className='text-danger fw-bold fs-2'>{oneData?.curDev}</p>
                                    </Text>
                                </Col>
                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                    >
                                        Tipo de Pago: <p className='text-primary fw-bold fs-2'>{oneData?.tipoPago}</p>
                                    </Text>
                                </Col>
                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                    >
                                        #<p className='text-success fw-bold fs-3'>{oneData?.idtipoPago === 1 ? oneData?.noCheque : oneData?.noTransferenciaNDPRO}</p>
                                    </Text>
                                </Col>

                            </Row>
                            <Row >
                                <Col>

                                    <FormGroup>
                                        <Label for="categoriaExpediente" className="d-flex justify-content-between">
                                            <span>*  Categoria</span>
                                        </Label>
                                        <Controller
                                            name="categoriaExpediente"
                                            control={control}
                                            render={({ field }) => {
                                                return (
                                                    <Select
                                                        {...field}
                                                        isClearable
                                                        onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                        onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                        defaultValue={null}
                                                        options={labelCategoria}
                                                        // theme={selectThemeColors}
                                                        // isDisabled={!allMedidaInsumo}
                                                        className='selector-react1'
                                                        classNamePrefix='select'
                                                        placeholder={'Seleccione una opción'}
                                                        noOptionsMessage={() => 'sin resultados'}
                                                        styles={(!!errors.categoriaExpediente) ? customStyles : {}}
                                                    />
                                                )
                                            }
                                            }
                                            rules={
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido'
                                                    }
                                                }
                                            }
                                        />
                                        <small className="text-danger">
                                            {!!errors.categoriaExpediente && errors.categoriaExpediente.message}
                                        </small>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 43 && 'Recibir'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion EXPEDIENTE RESGUARDADO EN ARCHIVO */}
            {
                opcion === 44 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '95%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 44 && 'MARCAR COMO EXPEDIENTE RESGUARDADO EN ARCHIVO '} </ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                            {/* <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    CUR: <p className='text-danger fw-bold fs-1'>{oneData?.cur}</p>
                                </Text>
                            </Col> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 44 && 'Marcar como Resguardado'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion RECIBIR DOCTOS DE RESPALDO GASTOS */}
            {
                opcion === 45 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 45 && 'MARCAR COMO RECIBIDO '} </ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                            {/* <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    CUR: <p className='text-danger fw-bold fs-1'>{oneData?.cur}</p>
                                </Text>
                            </Col> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 45 && 'Marcar como Recibido'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Enviar a Archivo */}
            {
                opcion === 46 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '1300px', width: '95%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 46 && 'ENVIAR EXPEDIENTE A ARCHIVO'}</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                            <Row >
                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                    >
                                        CUR DEV: <p className='text-danger fw-bold fs-2'>{oneData?.curDev}</p>
                                    </Text>
                                </Col>
                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                    >
                                        Tipo de Pago: <p className='text-primary fw-bold fs-2'>{oneData?.tipoPago}</p>
                                    </Text>
                                </Col>
                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                    >
                                        #<p className='text-success fw-bold fs-3'>{oneData?.idtipoPago === 1 ? oneData?.noCheque : oneData?.noTransferenciaNDPRO}</p>
                                    </Text>
                                </Col>

                            </Row>
                            <Row >
                                <FormGroup>
                                    <Label for="folios"> * Numero de folios</Label>
                                    <Controller
                                        // defaultValue
                                        control={control}
                                        id='folios'
                                        name='folios'
                                        render={({ field }) => (
                                            <Input
                                                type='text'
                                                // readOnly={opcion === 2}
                                                invalid={errors.folios && true}
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.folios && errors.folios.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 46 && 'Enviar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion Ingresar Datos de transferencia*/}
            {
                opcion === 47 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 47 && 'INGRESAR DATOS DE TRANSFERENCIA A'} Solicitud</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>


                            <Row >
                                <FormGroup>
                                    {/* <Label for="noCheque"> * No.Cheque o Transferencia </Label> */}
                                    <Label for="noCheque"> * No. Nota Debito / PRO </Label>
                                    <Controller
                                        // defaultValue
                                        control={control}
                                        id='noCheque'
                                        name='noCheque'
                                        render={({ field }) => (
                                            <Input
                                                type='text'
                                                // readOnly={opcion === 2}
                                                invalid={errors.noCheque && true}
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.noCheque && errors.noCheque.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 47 && 'Ingresar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion EXPEDIENTE RESGUARDADO EN ARCHIVO */}
            {
                opcion === 48 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '1600px', width: '95%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 48 && 'CARGA DE DIGITALIZACIÓN EN ARCHIVO '} </ModalHeader>
                    {/* <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}> */}
                    <ModalBody>
                        <ContentProvider2>
                            <Attached />
                        </ContentProvider2>
                        {/* <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    CUR: <p className='text-danger fw-bold fs-1'>{oneData?.cur}</p>
                                </Text>
                            </Col> */}
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                    </ModalFooter>
                    {/* </Form> */}
                </Modal>
            }








            {/* Opcion Ingreso a Compras Asigna Tecnico  */}
            {
                opcion === 39 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '1000px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 39 && 'Modificar Analista'} Solicitud</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>
                            <Row >
                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Asignación actual: <p className='text-success'>{oneData && oneData?.analistaAsignado}</p>
                                        {/* ¿Desea Cambiar de Analista? */}
                                    </Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {

                                        <FormGroup>
                                            <Label for="labelAnalistas" className="d-flex justify-content-between">
                                                <span>*  Analista</span>
                                            </Label>
                                            <Controller
                                                name="labelAnalistas"
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            {...field}
                                                            isClearable
                                                            onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                            onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                            defaultValue={null}
                                                            options={labelAnalistas}
                                                            // theme={selectThemeColors}
                                                            // isDisabled={!allMedidaInsumo}
                                                            className='selector-react1'
                                                            classNamePrefix='select'
                                                            placeholder={'Seleccione una opción'}
                                                            noOptionsMessage={() => 'sin resultados'}
                                                            styles={(!!errors.labelAnalistas) ? customStyles : {}}
                                                        />
                                                    )
                                                }
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <small className="text-danger">
                                                {!!errors.labelAnalistas && errors.labelAnalistas.message}
                                            </small>
                                        </FormGroup>
                                    }
                                </Col>
                            </Row>

                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            {
                                opcion !== 2 &&
                                <Fragment>
                                    <Button color='success' type="submit" id="accion">
                                        <Icon.CheckSquare size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                        {opcion === 39 && 'Modificar'}
                                    </UncontrolledTooltip>
                                </Fragment>
                            }
                        </ModalFooter>
                    </Form>
                </Modal>
            }
            {/* Opcion operar Anulacion */}
            {
                opcion === 40 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '1600px', width: '95%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 40 && 'OPERAR ANULACIÓN DE '} Solicitud</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>
                            <Row >
                                <Header />
                                {(oneData && oneData.id) &&
                                    <ContentProvider>
                                        <Detalle oneData={oneData} agregarRenglon={agregarRenglon} setAgregarRenglon={setAgregarRenglon} opcion={opcion} />
                                    </ContentProvider>
                                }
                            </Row>
                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Operar esta acción anula todo el proceso de la solicitud, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 40 && 'Operar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }

            {/* Opcion Iniciar Liquidación STH  */}
            {
                opcion === 200 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '1800px', width: '95%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>
                        <ul className="nav nav-tabs nav-tabs-custom nav-line-tabs-2x mb-1 fs-5 border-transparent fw-bolder" role="tablist">
                            <li className="nav-item" role="presentation" >
                                <a
                                    className="nav-link active"
                                    data-bs-toggle="tab"
                                    href="#liquidar"
                                    role="tab"
                                    aria-selected="false"
                                >
                                    <span className="d-block d-sm-none">
                                        <i className="fas fa-cog" />
                                    </span>
                                    <span className="d-none d-sm-block">{opcion === 200 && 'proceso de liquidación de'} Solicitud</span>
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link"
                                    data-bs-toggle="tab"
                                    href="#carga"
                                    role="tab"
                                    aria-selected="false"
                                    tabIndex={-1}
                                >
                                    <span className="d-block d-sm-none">
                                        <i className="far fa-envelope" />
                                    </span>
                                    <span className="d-none d-sm-block">Adjuntos</span>
                                </a>
                            </li>
                        </ul>
                    </ModalHeader>
                    <div className="tab-content p-3 text-muted">
                        <div className="tab-pane active show" id="liquidar" role="tabpanel">
                            <ModalBody>
                                <Row >
                                    <Header />
                                    {(oneData && oneData.id) &&
                                        <ContentProvider>
                                            <Detalle oneData={oneData} agregarRenglon={agregarRenglon} setAgregarRenglon={setAgregarRenglon} opcion={opcion} />
                                        </ContentProvider>
                                    }
                                </Row>
                            </ModalBody>
                        </div>
                        <div className="tab-pane" id="carga" role="tabpanel">
                            <div className="p-3">
                                <ContentProvider2>
                                    <Attached />
                                </ContentProvider2>
                            </div>
                        </div>
                    </div>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                    </ModalFooter>
                </Modal>
            }

            {/* Opcion 1D*/}
            {
                opcion === 100 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 100 && 'OPERAR REINTEGRO DE '} Solicitud</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción opera el reintegro / liquidacion en SIPA segun datos del deposito recibidos y verificados para el GASTO: <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 100 && 'Operar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }

            {/* Opcion 1D*/}
            {
                opcion === 101 && <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 101 && 'INGRESO DE NO. de 1D'}</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEstado)}>
                        <ModalBody>

                            <Row >

                                <Col className="text-center">
                                    <Text
                                        h1
                                        size={20}
                                        weight="medium"
                                        color={`${theme === 'solarized' ? 'dark' : 'white'}`}
                                    >
                                        Esta acción cambia el estado de la solicitud <strong>{oneData && oneData?.correlativo}</strong>, ¿Está seguro de que todo está bien y desea proceder?
                                    </Text>
                                </Col>
                            </Row>
                            <Row >
                                <FormGroup>
                                    <Label for="no1D"> * No. de 1D </Label>
                                    <Controller
                                        // defaultValue
                                        control={control}
                                        id='no1D'
                                        name='no1D'
                                        render={({ field }) => (
                                            <Input
                                                type='text'
                                                // readOnly={opcion === 2}
                                                invalid={errors.no1D && true}
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.no1D && errors.no1D.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            <Fragment>
                                <Button color='success' type="submit" id="btnAdjudicar">
                                    <Icon.CheckSquare size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='btnAdjudicar' placementPrefix="bs-tooltip">
                                    {opcion === 101 && 'Ingresar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        </ModalFooter>
                    </Form>
                </Modal>
            }
        </Fragment >
    )
}

export default Formulario