/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react'
import { CardTitle, CardText, Form, Input, Button, Label, Row, Col, CardBody, Card, Alert, Container,  FormFeedback } from 'reactstrap'
import { Link } from 'react-router-dom'
import * as Icon from 'react-feather'
import { OptionsToast } from '../../Globales/index'
import { toast } from 'react-toastify'
import { useForm, Controller } from 'react-hook-form'
import { PostRoute } from '../../services/Public'
import { toAbsoluteUrl } from '../../helpers/AssetHelper'

const Forgot = () => {

  const { handleSubmit, formState: { errors }, control } = useForm(),
    onSubmit = async (data) => {
      const response = await PostRoute(`usuario/reset/default/password`,
        { email: data.email })
      toast.success(response.message, OptionsToast)
    }

  return (
    <Fragment>
      <div className="p-0 container-fluid">
        <div className="g-0 row">
          <div className="col-xl-8 col-lg-8">
            <div className="auth-full-bg pt-lg-5 p-4">
              <div className="w-100">
                <div className="bg-overlay" />
                <div className="d-flex h-100 flex-column">
                  <div style={{ marginBlock: "auto" }}>
                    <div className="row justify-content-center">
                      <div className="col-lg-8">
                        <div className="text-center">
                          <span >
                            <img
                              src={"https://i.ibb.co/NN7tCj7/Logo-COGblanco.png"}
                              alt=""
                              // className="rounded-circle"
                              height="500"
                            />
                          </span>
                          <div dir="ltr">
                            <div className="carousel-root slider_css">
                              <div
                                className="carousel carousel-slider"
                                style={{ width: "100%" }}
                              >
                                <div className="slider-wrapper axis-horizontal">
                                </div>
                                <button
                                  type="button"
                                  aria-label="next slide / item"
                                  className="control-arrow control-next"
                                />
                                <p className="carousel-status">1 of 2</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl col-lg">
            <div className="auth-full-page-content p-md-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="text-center">
                    <span>
                      <img
                        src={toAbsoluteUrl("/media/logos/SIPA-completo.png")}
                        alt=""
                        // className="rounded-circle"
                        height="100%"
                        width="55%"
                      />
                    </span>
                  </div>
                  <div className="my-auto">
                    <CardTitle tag='h2' className='fw-bold mb-4 text-center'>
                      ¿Has olvidado tu contraseña?
                    </CardTitle>
                    <CardText className='mb-5 text-center'>
                      Ingrese su correo electrónico y le enviaremos instrucciones para restablecer su contraseña
                    </CardText>
                    <Form autoComplete="off"className='auth-login-form mt-2' onSubmit={handleSubmit(onSubmit)}>
                      <div className='mb-1'>
                        <Label className='form-label' for='email'>
                          Correo Electrónico
                        </Label>
                        <Controller
                          defaultValue=''
                          control={control}
                          name='email'
                          autoFocus
                          render={({ field }) => (
                            <Input
                              type='text'
                              invalid={errors.email && true}
                              placeholder='john@example.com'
                              {...field}
                            />
                          )
                          }
                          rules={
                            {
                              required: {
                                value: true,
                                message: 'Este campo es requerido'
                              }
                            }
                          }
                        />
                      </div>
                      <Button color='primary' type='submit' block >
                        Enviar
                      </Button>
                      <div className='pt-2 text-center w-100'>
                        <Link to="/login">
                          <Icon.ChevronLeft size={18} /> Atrás para iniciar sesión
                        </Link>
                      </div>
                    </Form>
                  </div>
                  <div className="mt-4 mt-md-5 text-center">
                    <p className="mb-0">
                      © {new Date().getFullYear()} SIPA {"|"} Comité Olímpico Guatemalteco
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </Fragment>
  )
}

export default Forgot