import React, { useContext, useState } from 'react'
import { Button, Form, Row, Col, UncontrolledTooltip } from 'reactstrap'
import { ContentContext } from '../context'
import { useForm } from "react-hook-form"
import * as Icon from 'react-feather'
import { OptionsToast } from 'Globales/index'
import { toast } from 'react-toastify'
import List from './List'
import { ContentProvider } from './context'
export const Attached = () => {
  const
    { register, handleSubmit, setValue, } = useForm(),
    [Visualizar, setVisualizar] = useState(''),
    [getFoto, setGetFoto] = useState([]),
    { storeFile, idSolicitud } = useContext(ContentContext),
    onSubmitFile = async (data) => {
      var formData = new FormData();
      formData.append('solicitudInsumo', idSolicitud);
      formData.append('file', data.file[0]);
      Visualizar === '' ? toast.error('Debe seleccionar un archivo', OptionsToast) : storeFile(formData)
      Limpiar()
    },
    // setData = async () => {
    //   await setValue('vigente', oneData.vigente)
    //   await setValue('ejecutado', oneData.ejecutado)
    //   await setValue('total', oneData.total)
    // },
    MostrarDoc = (value) => {
      if (value.target.value) {
        var pdffile = value.target.files[0],
          pdffile_url = URL.createObjectURL(pdffile);
        setGetFoto(pdffile)
        setVisualizar(`${pdffile_url}`);
      }
      else {
        setVisualizar('');
      }
    },
    Limpiar = () => {
      setVisualizar('');
      setValue('file', null);
      setGetFoto()
    }

  return (
    <>
      <Row>
        <Col>
          <ContentProvider>
            <List />
          </ContentProvider>
        </Col>
        <Col>
          <Row>
            <Col>
              <Form onSubmit={handleSubmit(onSubmitFile)} >
                <Row>
                  <Col >
                    <div className="file-input" id="Cargar">
                      <input
                        type="file"
                        id="file"
                        {
                        ...register('file'
                        )
                        }
                        className="file"
                        accept="application/pdf,image/png,image/jpeg,image/jpg"
                        onChange={(e) => MostrarDoc(e)}

                      />
                      <label htmlFor="file">
                        <Icon.UploadCloud size={15} />
                        <p className="file-name"></p>
                      </label>
                      <UncontrolledTooltip placement='top' target='Cargar' placementPrefix="bs-tooltip">
                        Cargar Adjunto
                      </UncontrolledTooltip>
                    </div></Col>
                  {Visualizar.length > 0 ?
                    <Col lg={1}>
                      <Button
                        className='symbol symbol-circle symbol-50px mt-3'
                        color="info"
                        type="submit"
                        id='Confirmar'
                      >
                        <Icon.Check size={18} />
                      </Button>
                      <UncontrolledTooltip placement='top' target='Confirmar' placementPrefix="bs-tooltip">
                        Confirmar
                      </UncontrolledTooltip>
                    </Col> : null}
                  {Visualizar.length > 0 ?
                    <Col lg={1}>
                      <Button
                        className='symbol symbol-circle symbol-50px mt-3'
                        color="danger"
                        // type="submit"
                        onClick={() => Limpiar()}
                        id='Cancelar'
                      >
                        <Icon.X size={18} />
                      </Button>
                      <UncontrolledTooltip placement='top' target='Cancelar' placementPrefix="bs-tooltip">
                        Cancelar
                      </UncontrolledTooltip>
                    </Col> : null}
                </Row>
              </Form>
              <object className='Visor my-3' width="100%" height="420" id='nombre-visor' aria-labelledby='cotizacion-solicitud' type="application/pdf" data={Visualizar} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default Attached