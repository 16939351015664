import React, { useEffect, useState, createContext, useContext } from 'react'
import { GetRoute, PostRoute } from '../../../../services/Private'
import { OptionsToast } from '../../../../Globales/index'
import { toast } from 'react-toastify'
// import { LoadingContext } from '../../../../context/loadingContext'

export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {

    const 
    // { setShowLoad } = useContext(LoadContext),
        [oneData, setOneData] = useState(null),
        [allData, setAllData] = useState([]),
        nameController = 'correo'

    const One = async () => {
            const response = await GetRoute(`${nameController}/one`)
            setOneData((response[0]) ? response : [])
        
    },
        All = async () => {
            const response = await GetRoute(`${nameController}/all`)
            setAllData((response.length) ? response : [])
        },
        StoreUpdate = async (data) => {
            
            let response = []
            response = await PostRoute(`${nameController}/${!data.id ? 'store' : 'update'}`, data)
            toast.success(response.message, OptionsToast)
            // One()
            All()
            
        },
        Actions = async (data) => {
            
            const response = await PostRoute(`${nameController}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data.id })
            toast.success(response.message, OptionsToast)
            All()
            
        },
        value = {
            One,
            StoreUpdate,
            Actions,
            oneData,
            allData,
            setOneData
        }

    // Modal End
    useEffect(() => {
        One()
    }, [])

    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}
