import React, { Fragment, useContext, useEffect } from 'react'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback } from 'reactstrap'
import { ContentContext } from './context'
import * as Icon from 'react-feather'
import { useForm, Controller } from "react-hook-form"
import Select from 'react-select'
import { customStyles } from '../../../Globales/index'

const Formulario = () => {
    const { toggleModal, modal, StoreUpdate, opcion, oneData, labelObjetivos, labelUnidades } = useContext(ContentContext),
        { handleSubmit, formState: { errors }, setValue, control, reset } = useForm(),
        onSubmit = (data) => {
            const json = {
                id: (oneData) ? oneData.id : null,
                nombre: data.nombre,
                unidad: data.unidad.value,
                objetivo: data.objetivo.value
            }
            StoreUpdate(json)
        },
        setData = async () => {
            await setValue('nombre', oneData.nombre)
            await setValue('objetivo', { label: oneData.objetivoOperativo, value: oneData.idObjetivoOperativo })
            await setValue('unidad', { label: oneData.unidad, value: oneData.idUnidad })
        },
        clearData = async () => {
            reset({ nombre: '', objetivo: '', unidad: '' })
        }

    useEffect(
        () => {
            async function fetchMyAPI() {
                if (await opcion > 1 && oneData) { await setData() }
                if (await opcion === 1) { clearData() }
            }

            fetchMyAPI()

        }, [opcion, oneData]
    )

    return (
        <Fragment>
            <Button onClick={() => toggleModal(1)} id="crear" color="primary" size="sm">
                <Icon.Plus size={16} />
            </Button>
            <UncontrolledTooltip placement='left' target='crear' placementPrefix="bs-tooltip">
                Crear
            </UncontrolledTooltip>

            <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 1 && 'crear'} Objetivo Unidad</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup >
                                    <Label >* Unidad</Label>
                                    <Controller
                                        name="unidad"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                    isDisabled={opcion === 2}
                                                    options={labelUnidades}
                                                    className='react-select'
                                                    classNamePrefix='select'
                                                    placeholder={'Seleccione una opción'}
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={(!!errors.unidad) ? customStyles : {}}

                                                />
                                            )
                                        }
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <small className="text-danger">{!!errors.unidad && errors.unidad.message}</small>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup >
                                    <Label >* Objetivo Operativo</Label>
                                    <Controller
                                        name="objetivo"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                    isDisabled={opcion === 2}
                                                    options={labelObjetivos}
                                                    className='react-select'
                                                    classNamePrefix='select'
                                                    placeholder={'Seleccione una opción'}
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={(!!errors.objetivo) ? customStyles : {}}

                                                />
                                            )
                                        }
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <small className="text-danger">{!!errors.objetivo && errors.objetivo.message}</small>
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Save size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion !== 2 && opcion === 1 ? 'Crear' : 'Actualizar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default Formulario