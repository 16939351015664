import React, { useContext, useEffect } from 'react'
import { Card, CardHeader, CardTitle, CardBody, FormFeedback, Row, Col, Button, Form, Input, Label, FormGroup } from 'reactstrap'
import InputPasswordToggle from '../../../../components/input-password-toggle/index'
import { ContentContext } from './context'
import { useForm, Controller } from "react-hook-form"
import * as Icon from 'react-feather'

const Formulario = () => {

    const { StoreUpdate, oneData, setOneData } = useContext(ContentContext),
        { handleSubmit, formState: { errors }, setValue, control } = useForm(),
        onSubmit = async (data) => {
            if (!oneData) {
                const json = {
                    smtp: data.smtp,
                    port: data.port,
                    email: data.email,
                    password: data.password
                }
                StoreUpdate(json)
            }
            setOneData([])
            setValue('email', "")
            setValue('smtp', "")
            setValue('port', "")
            setValue('password', "")
        }

    useEffect(
        () => {
            async function fetchMyAPI() {
                if (await oneData) {
                    await setValue('email', oneData[0].emailServerAccount)
                    await setValue('smtp', oneData[0].smtpServer)
                    await setValue('port', oneData[0].smtpPort)
                }
            }
            fetchMyAPI()
        }, [oneData]
    )

    return (
        <>
            <Row>
                <Col>
                    <Card >
                        <CardBody>
                            <Form autoComplete="off"onSubmit={handleSubmit(onSubmit)}>

                                <Row>
                                    <Col lg={6} sm={6}>
                                        <FormGroup>
                                            <Label for='email' className="">* Cuenta del servidor de correo electrónico</Label>
                                            <Controller
                                                defaultValue='undefined'
                                                control={control}

                                                id='email'
                                                name='email'
                                                render={({ field }) => (
                                                    <Input
                                                        type='text'
                                                        autoComplete="new-password"
                                                        invalid={errors.email && true}
                                                        {...field}
                                                    />
                                                )
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <FormFeedback>
                                                {!!errors.email && errors.email.message}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6} sm={6}>
                                        <FormGroup>
                                            <Label for='password' className="">* Contraseña de correo electrónico</Label>
                                            <Controller
                                                defaultValue=''
                                                control={control}
                                                id='password'
                                                name='password'
                                                render={({ field }) => (
                                                    <InputPasswordToggle
                                                        label=''
                                                        autoComplete="new-password"
                                                        htmlFor='basic-default-password'
                                                        invalid={errors.password && true}
                                                        {...field}
                                                    />
                                                )
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <FormFeedback>
                                                {!!errors.password && errors.password.message}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6} sm={6}>
                                        <FormGroup>
                                            <Label for='smtp' className="">* Servidor SMTP</Label>
                                            <Controller
                                                defaultValue='undefined'
                                                control={control}
                                                id='smtp'
                                                name='smtp'
                                                render={({ field }) => (
                                                    <Input
                                                        autoComplete='off'
                                                        type='text'
                                                        invalid={errors.smtp && true}
                                                        {...field}
                                                    />
                                                )
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <FormFeedback>
                                                {!!errors.smtp && errors.smtp.message}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6} sm={6}>
                                        <FormGroup>
                                            <Label for='port' className="">* Puerto SMTP</Label>
                                            <Controller
                                                defaultValue='undefined'
                                                control={control}
                                                id='port'
                                                name='port'
                                                render={({ field }) => (
                                                    <Input
                                                        type='text'
                                                        invalid={errors.port && true}
                                                        {...field}
                                                    />
                                                )
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <FormFeedback>
                                                {!!errors.port && errors.port.message}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} className="d-flex justify-content-end">
                                        <Button color="success" type="submit"><Icon.Save size={16} /> Guardar</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Formulario