import React, { createContext, useState } from 'react'
import { PostRoute } from '../../../services/Private'
import { OptionsToast } from '../../../Globales/index'
import { toast } from 'react-toastify'
import { useRequest } from '../../../hooks/useRequestSWR'
import { useSWRConfig } from 'swr'

export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    // Modal Init
    const { mutate } = useSWRConfig();
    const [accion, setAccion] = useState(0);
    const nameController = 'Acciones';
    const nameController2 = 'Dependencias';
    const nameController3 = 'PresupuestoPOA';
    const nameController4 = 'ObjetivoUnidad';
    const nameController5 = 'DetalleAcciones';
    const { data: allData } = useRequest(`${nameController}/all`, 'GET');
    const { data: labelDependencias } = useRequest(`${nameController2}/label`, 'GET');
    const { data: labelPOA } = useRequest(`${nameController3}/label`, 'GET');
    const { data: labelObjetivos } = useRequest(`${nameController4}/label`, 'GET');
    const { data: total } = useRequest(`${nameController5}/total`, 'POST', { accion: accion } );
    const
        [modal, setModal] = useState(null),
        [oneData, setOneData] = useState([]),
        // [total, setTotal] = useState([]),
        [opcion, setOpcion] = useState(0),
        [agregarRenglon, setAgregarRenglon] = useState(0),
        toggleModal = async (data) => {
            
            setOpcion(data)
            if (data === 1) { setOneData(null) }
            setModal(!modal)
            
        },
        One = async (data, opcion) => {
            
            // setIdAccion(data.id)
            const response = await PostRoute(`${nameController}/one`, { id: data.id })
            setOneData((response[0]) ? response[0] : [])
            // const responseTwo = await PostRoute(`${nameController5}/total`, { accion: data.id })
            // setTotal((responseTwo[0]) ? responseTwo[0] : 0)
            toggleModal(opcion)
            
        },
        StoreUpdate = async (data, id) => {
            
            let response = []
            response = await PostRoute(`${nameController}/${!data.id ? 'store' : 'update'}`, data)
            const responseTwo = await PostRoute(`${nameController}/one`, { id: (!response.id ? id : response.id) })
            setOneData((responseTwo[0]) ? responseTwo[0] : [])
            setOpcion(3)
            toast.success(response.message, OptionsToast)
            // toggleModal(0)
            mutate(`${nameController}/all`)
            
        },
        Actions = async (data) => {
            
            const response = await PostRoute(`${nameController}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data.id })
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)
            
        },
        value = {
            One,
            Actions,
            StoreUpdate,
            setAgregarRenglon,
            setAccion,
            toggleModal,
            agregarRenglon,
            allData,
            modal,
            oneData,
            opcion,
            labelDependencias,
            labelObjetivos,
            labelPOA,
            total
        }

    // Modal End

    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}
