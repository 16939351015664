import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { ContentContext } from './context'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback } from 'reactstrap'
import { useForm, Controller } from "react-hook-form"
import * as Icon from 'react-feather'
import DatePicker from "react-datepicker";
import Select from 'react-select'
import { customStyles } from '../../../Globales/index'
import classnames from 'classnames'
import { Text } from "@nextui-org/react";
import { useHistory } from "react-router-dom"
// import Dependencias from './Dependencias/index'
// import Objetivos from './Objetivos/index'
// import Acciones from './Acciones/index'
// import Presupuesto from './Presupuesto/index'
// import POA from './POA/index'
// import SALDOS from './Saldos/index'

const CardOne = () => {
    const history = useHistory();
    const [percentEjecucion, setPercentEjecucion] = useState(0);
    const [tab, setTab] = useState(1);
    const { allData, mutate, nameController, allData4 } = useContext(ContentContext),
        TabActive = (No) => {
            setTab(No)
        };

    const percentage_ejecucion = (n1, n2) => {
        // console.log(n1, n2)
        if (n1 !== null && n2 !== null && n1 !== undefined && n2 !== undefined) {
            // Actual
            const num1 = parseInt(n1);
            // Limite
            const num2 = parseInt(n2);
            const result = (num1 * 100) / num2;

            setPercentEjecucion(result.toFixed(2));
        }
    }

    useEffect(
        () => {
            const functionFetch = async () => {
                if (allData && allData[0] !== undefined) {
                    percentage_ejecucion(Number(allData[0]?.totalEjecutadoNumerico), Number(allData[0]?.presupuestoModificadoActualNumerico))
                }
            }
            functionFetch();
        }, [allData]
    )

    return (
        <Fragment>

            <div className="card">
                <Row className="m-2">
                    <div className="col-12">
                        <Row className="d-flex align-items-center">
                            <Col sm={12} lg={3} md={5} className="p-3" >
                                <div
                                    className="text-center d-flex justify-content-center align-self-center" style={{ height: '12vh' }}
                                >
                                    <span className="avatar-title rounded-4 text-ligt content-colorized" style={{ fontSize: '4.6vh' }}>
                                        {allData && allData[0] !== undefined && allData[0].iniciales}
                                    </span>
                                </div>
                            </Col>
                            <Col sm={12} lg={9} md={7}>
                                <div className="d-flex text-center align-items-center ">
                                    <a
                                        href="#"
                                        className="text-dark fs-4 fw-bold"
                                    >
                                        {allData && allData[0] !== undefined && allData[0].unidad}
                                    </a>
                                    {/* {allData && allData[0] !== undefined && allData[0].estado === 1 ?
                                                <span className="badge badge-light-success me-auto">
                                                    Activo
                                                </span> :
                                                <span className="badge badge-light-danger me-auto">
                                                    Inactivo
                                                </span>} */}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="col-12 p-4">
                        <Row className="text-center">
                            <Col sm={12} md={4} lg={3} className="text-center" >
                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-2">
                                    <div
                                        className="fs-4 fw-bold counted text-center" >
                                        {allData && allData[0] !== undefined && allData[0]?.totalPresupuestoInicial}
                                    </div>
                                    <div className="fs-6 text-gray-400">Ppto. Inicial</div>
                                </div>
                            </Col>
                            <Col sm={12} md={4} lg={3} className="text-center" >
                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-2">
                                    <div
                                        className="fs-4 fw-bold counted text-center" >
                                        {allData && allData[0] !== undefined && allData[0]?.presupuestoModificadoActual}
                                    </div>
                                    <div className="fs-6 text-gray-400">Ppto. Actual Modificado</div>
                                </div>
                            </Col>
                            <Col sm={12} md={4} lg={3} className="text-center" >
                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-2">
                                    <div
                                        className="fs-4 fw-bold counted" >
                                        {allData && allData[0] !== undefined && allData[0]?.saldoTotalFormato}

                                    </div>
                                    <div className="fs-6 text-gray-400">Ppto. Disponible </div>
                                </div>
                            </Col>
                            <Col sm={12} md={4} lg={3} className="text-center" >
                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-2">
                                    <div
                                        className="fs-4 fw-bold counted" >
                                        {allData && allData[0] !== undefined && allData[0]?.totalEjecutadoFormato}

                                    </div>
                                    <div className="fs-6 text-gray-400">Ppto. Ejecutado </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="d-flex align-items-center flex-column m-3">
                                    <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                        <span className="fw-semibold fs-9 text-secondary">Porcentaje Ejecutado</span>
                                        <span className="fw-bold fs-7">{percentEjecucion}%</span>
                                    </div>
                                    <div className="h-5px mx-3 w-100 bg-light mb-3 progress-bar">
                                        <div className="progress-md progress">
                                            <div
                                                className="progress-bar bg-success"
                                                role="progressbar"
                                                style={{ width: `${percentEjecucion}%` }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Row>
            </div >

        </Fragment >
    )
}
export default CardOne