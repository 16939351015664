
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback } from 'reactstrap'
import { GetRoute, PostRoute } from 'services/Private';
import { toAbsoluteUrl } from 'helpers/index'
import { useForm, Controller } from "react-hook-form";
import React, { useState, useEffect, useContext, useMemo } from 'react'
import * as Icons from 'react-feather';
import Pagination from 'components/pagination/Pagination'
import Select from 'react-select'

const PageSize = 12
const List = () => {
    const { formState: { errors }, watch, setValue, control } = useForm(),
        [allArchivos, setAllArchivos] = useState([]),
        [currentPage, setCurrentPage] = useState(1),
        [data, setData] = useState([]),
        [onePrograma, setOnePrograma] = useState([]),
        [file, setFile] = useState([]),
        [opcion, setOpcion] = useState(false),
        [show, setShow] = useState(false),
        [width, setWidth] = useState(window.innerWidth),
        // { setShowLoad } = useContext(LoadingContext),
        toggleModal = (data) => {
            setOpcion(data)
            setShow(!show)
        },
        AllArchivos = async (id) => {
            const response = await GetRoute('cargaInsumos/all')
            setAllArchivos((response.length > 0) ? response : []);
            ;
        },
        OneArchivo = async (id) => {
            const response = await PostRoute('cargaInsumos/one', { id: id })
            setOnePrograma((response.length > 0) ? response : []);
            if (response.length > 0) {
                Download(id)
            }
            ;
        },
        Download = async (id) => {
            const response = await PostRoute('cargaInsumos/download', { id: id })
            setFile((response.length > 0) ? response : []);
            ;
        },
        handleResize = () => {
            setWidth(window.innerWidth);
        },
        currentTableData = useMemo(() => {
            const firstPageIndex = (currentPage - 1) * PageSize
            const lastPageIndex = firstPageIndex + PageSize
            return (data).slice(firstPageIndex, lastPageIndex)
        }, [currentPage, data])

    useEffect(() => {
        AllArchivos();
    }, []);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [width]);

    useEffect(() => {
        if (allArchivos.length > 0) {
            setData(allArchivos)
        } else if (allArchivos.length === 0) {
            setData([])
        }
    }, [allArchivos]);


    return (
        <>
            {opcion === 1 && //Modal
                <Modal
                    isOpen={show}
                    backdrop='static'
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered'
                >
                    <ModalBody className='align-self-center'>
                        <div className="card-header border-0 pt-5">
                            <h3 className="card-title text-center flex-column">
                                <span className="card-label fw-bolder text-dark">{onePrograma.length > 0 && onePrograma[0].nombre}</span>
                            </h3>
                        </div>
                        <div className="card-body pt-5">
                            {/*begin::Item*/}
                            <div className="d-flex align-items-center mb-7">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-50px me-5">
                                    <span className="symbol-label bg-light-success">
                                        {/*begin::Svg Icon | path: icons/duotune/abstract/abs027.svg*/}
                                        <span className="svg-icon svg-icon-2x svg-icon-success">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <path
                                                    opacity="0.3"
                                                    d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                    </span>
                                </div>
                                {/*end::Symbol*/}
                                {/*begin::Text*/}
                                <div className="d-flex flex-column">
                                    <span className="text-muted fw-bold">Estado: </span>
                                    <a href="#" className="text-dark text-hover-primary fs-6 fw-bolder">
                                        {onePrograma.length > 0 && onePrograma[0].estado}
                                    </a>
                                </div>
                                {/*end::Text*/}
                            </div>
                            {/*end::Item*/}
                            {/*begin::Item*/}
                            <div className="d-flex align-items-center mb-7">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-50px me-5">
                                    <span className="symbol-label bg-light-warning">
                                        {/*begin::Svg Icon | path: icons/duotune/art/art005.svg*/}
                                        <span className="svg-icon svg-icon-2x svg-icon-warning">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <path
                                                    opacity="0.3"
                                                    d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                    </span>
                                </div>
                                {/*end::Symbol*/}
                                {/*begin::Text*/}
                                <div className="d-flex flex-column">
                                    <span className="text-muted fw-bold">Fecha de Subida</span>
                                    <a href="#" className="text-dark text-hover-primary fs-6 fw-bolder">
                                        {onePrograma.length > 0 && onePrograma[0].creado}
                                    </a>
                                </div>
                                {/*end::Text*/}
                            </div>

                            <div className="d-flex align-items-center">
                                {/*begin::Symbol*/}
                                <div className="symbol symbol-50px me-5">
                                    <span className="symbol-label bg-light-info">
                                        {/*begin::Svg Icon | path: icons/duotune/general/gen049.svg*/}
                                        <span className="svg-icon svg-icon-2x svg-icon-info">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <path
                                                    opacity="0.3"
                                                    d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M12.0006 11.1542C13.1434 11.1542 14.0777 10.22 14.0777 9.0771C14.0777 7.93424 13.1434 7 12.0006 7C10.8577 7 9.92348 7.93424 9.92348 9.0771C9.92348 10.22 10.8577 11.1542 12.0006 11.1542Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M15.5652 13.814C15.5108 13.6779 15.4382 13.551 15.3566 13.4331C14.9393 12.8163 14.2954 12.4081 13.5697 12.3083C13.479 12.2993 13.3793 12.3174 13.3067 12.3718C12.9257 12.653 12.4722 12.7981 12.0006 12.7981C11.5289 12.7981 11.0754 12.653 10.6944 12.3718C10.6219 12.3174 10.5221 12.2902 10.4314 12.3083C9.70578 12.4081 9.05272 12.8163 8.64456 13.4331C8.56293 13.551 8.49036 13.687 8.43595 13.814C8.40875 13.8684 8.41781 13.9319 8.44502 13.9864C8.51759 14.1133 8.60828 14.2403 8.68991 14.3492C8.81689 14.5215 8.95295 14.6757 9.10715 14.8208C9.23413 14.9478 9.37925 15.0657 9.52439 15.1836C10.2409 15.7188 11.1026 15.9999 11.9915 15.9999C12.8804 15.9999 13.7421 15.7188 14.4586 15.1836C14.6038 15.0748 14.7489 14.9478 14.8759 14.8208C15.021 14.6757 15.1661 14.5215 15.2931 14.3492C15.3838 14.2312 15.4655 14.1133 15.538 13.9864C15.5833 13.9319 15.5924 13.8684 15.5652 13.814Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </span>
                                        {/*end::Svg Icon*/}
                                    </span>
                                </div>
                                {/*end::Symbol*/}
                                {/*begin::Text*/}
                                <div className="d-flex flex-column justify-content-between">
                                    <span className="text-muted fw-bold">Subido Por</span>
                                    <a href="#" className="text-dark text-hover-primary fs-6 fw-bolder">
                                        {onePrograma.length > 0 && onePrograma[0].creadoPor}
                                    </a>
                                </div>
                                {/*end::Text*/}
                            </div>
                            {/*end::Item*/}
                        </div>
                    </ModalBody>
                    <ModalFooter style={{ height: 70 }}>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icons.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>

                        {file.length > 0 && file[0].archivo
                            ?
                            <a className="btn btn-success " download={onePrograma.length > 0 && onePrograma[0].nombre} href={`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${encodeURI(file.length > 0 && file[0].archivo)}`}><Icons.DownloadCloud size={16} />{" Descargar"}</a>
                            :
                            <a className="btn btn-success btn-sm disabled" >
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                {" "} Cargando...
                            </a>

                        }
                    </ModalFooter>
                </Modal>
            }
            <Row>
                <Col></Col>
                <Col></Col>

                <Col lg={2} className="align-self-center">
                    <FormGroup className="text-center">
                        <Label className="fw-bolder fs-4"><Icons.Folder size={25} className="text-warning" /> {" " + allArchivos.length} {allArchivos.length === 1 ? "item" : "items"}</Label>
                    </FormGroup>
                </Col>
            </Row>
            <div className={`row g-6 g-xl-9 ${currentTableData.length !== 0 && 'my-1'}`}>
                {
                    currentTableData.length > 0 && currentTableData.map((item, index) => {
                        return (
                            <div className="col-md-6 col-lg-4 col-xl-3" style={{ cursor: 'pointer' }} key={index} onClick={() => [OneArchivo(item.id), toggleModal(1)]} >
                                <div className="card ">
                                    <div className="card-body text-center">
                                        <span
                                            className="text-gray-800 text-hover-primary d-flex flex-column"
                                        >
                                            <div className="p-2">
                                                <div className="flex-shrink-0 me-3 ">
                                                    <img
                                                        className="rounded avatar-sm"
                                                        src={toAbsoluteUrl("/media/logos/excel-logo.png")}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="fs-5 fw-bolder mb-2">{item.nombre}</div>
                                        </span>
                                        <div className="fs-7 fw-bold text-gray-400">{item.creado}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }



            </div>
            {
                currentTableData.length === 0 &&

                <div className="card card-custom mb-12 my-5">

                    <div
                        className="card-body rounded p-0 d-flex"
                        style={{ backgroundColor: "#BDEFC9" }}
                    >
                        <Col className={'my-20'} >
                            <ul >

                                <li className={"fs-4 mx-8 my-3 "} >
                                    Sin Registros
                                </li >
                            </ul>
                        </Col>
                    </div>
                </div>
            }
            <Row>
                <Col className="pt-5">
                    <Pagination
                        className="pagination-bar justify-content-center"
                        currentPage={currentPage}
                        totalCount={data.length}
                        pageSize={PageSize}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </Col>
            </Row>
        </>
    )
}
export default List