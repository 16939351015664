
// import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useContext, useEffect, useState, useRef } from 'react'
import { ContentContext } from './context'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback, CardBody, CardTitle } from 'reactstrap'
import { useForm, Controller } from "react-hook-form"
import * as Icon from 'react-feather'
import Select from 'react-select'
import { formatDate, customStyles, stringToDate } from '../../../Globales/index'
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput'
import classnames from 'classnames'
import { Text } from "@nextui-org/react";
import Header from './header'
// import Detalle from '../DetallePedidos/form'
import Attached from './Adjunto/Attached'
// import { ContentProvider } from '../DetallePedidos/context'
import { ContentProvider as ContentProvider2 } from '../HistoricoSIPA/Adjunto/context'
import revision from '../../../assets/images/Checklist.png'
import DatePicker, { registerLocale } from "react-datepicker"
import es from "date-fns/locale/es"
import Moment from 'react-moment';
import 'moment/locale/es-mx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";
import "/node_modules/swiper/swiper.scss";
import { ContentContext as ContentContextTheme } from '../../../components/CommonForBoth/TopbarDropdown/context'
registerLocale("es", es); // register it with the name you want


const Formulario = () => {
    const { theme } = useContext(ContentContextTheme)
    // const [startDate, setStartDate] = useState(Date.parse(2019) + 1);
    const [startDate, setStartDate] = useState(new Date());
    const [validador, setValidador] = useState(false);
    const [tipoRechazo, setTipoRechazo] = useState(0);
    const { toggleModal, modal, StoreUpdate, opcion, oneData, labelAcciones, setId, agregarRenglon, setAgregarRenglon, labelFinanciamiento, idPresupuestoPoa, labelTipoGasto, labelTipoPedido, labelFand, useDataUser, nameComponent, labelJefeDireccion, solicitante, pdf, UpdateEstadoPedido, DataBitacoraPedido, EnviarARevision, opcion2, toggleModal2, modal2, } = useContext(ContentContext),
        { register, handleSubmit, formState: { errors }, control, reset, watch } = useForm(),
        inputTipoRechazo = watch("tipoRechazo"),
        onSubmitEstado = (data) => {
            if (opcion === 7) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: Number(data?.tipoRechazo),
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 1
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 8) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 2
                }

                UpdateEstadoPedido(json)
            }
            if (opcion === 9) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 3
                }

                UpdateEstadoPedido(json)
            }
            if (opcion === 10) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: Number(data?.tipoRechazo),
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 1
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 11) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 4
                }

                UpdateEstadoPedido(json)
            }
            if (opcion === 12) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: Number(data?.tipoRechazo),
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 1
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 13) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 6
                }

                UpdateEstadoPedido(json)
            }
            if (opcion === 14) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: Number(data?.tipoRechazo),
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 1
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 21) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: 3,
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 33,
                }
                UpdateEstadoPedido(json)
            }
            reset()
            toggleModal(0)
        },
        onSubmitEnviarRevision = (data) => {
            if (opcion === 20) {
                if (oneData?.idtipoPedido === 2) {//bien
                    const json2 = {
                        id: (oneData) ? oneData.id : null,
                        tipoRechazo: null,
                        descripcion: null,
                        estado: 13
                    }
                    const json = {
                        id: (oneData) ? oneData.id : null
                    }
                    UpdateEstadoPedido(json2)
                    EnviarARevision(json)
                }
                if (oneData?.idtipoPedido === 1) {//bien
                    const json2 = {
                        id: (oneData) ? oneData.id : null,
                        tipoRechazo: null,
                        descripcion: null,
                        estado: 2
                    }
                    const json = {
                        id: (oneData) ? oneData.id : null
                    }
                    UpdateEstadoPedido(json2)
                    EnviarARevision(json)
                }

            }
            if (opcion === 25) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 43
                }

                UpdateEstadoPedido(json)
            }

            reset()
            toggleModal(0)
        };

    const prevInputTipoRechazo = useRef(null);

    useEffect(() => {
        if (!isNaN(inputTipoRechazo)) {
            setTipoRechazo(Number(inputTipoRechazo));
            prevInputTipoRechazo.current = inputTipoRechazo;
        }
    }, [inputTipoRechazo]);

    return (
        <Fragment>
            <Button onClick={() => toggleModal(1)} id="crear" color="primary" size="sm">
                <Icon.Plus size={16} />
            </Button>
            <UncontrolledTooltip placement='left' target='crear' placementPrefix="bs-tooltip">
                Crear
            </UncontrolledTooltip>

            {opcion > 0 && opcion < 5 && <Modal
                isOpen={modal}
                backdrop='static'
                style={{ maxWidth: '1600px', width: '95%', margin: '10px auto' }}
                toggle={() => toggleModal()}
                className='modal-dialog-centered modal-xl'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}> {opcion === 1 ? "Crear" : opcion === 3 ? "Actualizar" : "Visualizar"}{` Solicitud de Compra Historica (Gasto - pedido)`}</ModalHeader>
                <ModalBody style={(opcion === 1 ? {} : { backgroundColor: 'rgb(0 0 0 / 7%)', paddingBottom: '0' })}>
                    <Header />
                    {(oneData && oneData.id) &&
                        <ContentProvider2>
                        <Attached />
                    </ContentProvider2>
                    }
                </ModalBody>
            </Modal>}
            
        </Fragment >
    )
}

export default Formulario