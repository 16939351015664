import React, { useContext, useEffect, useState, Fragment } from "react"
import { Link } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip,
  Button,
  Form,
} from "reactstrap"
import * as Icon from "react-feather"
import { EstadoText } from "../../../../CustomList/index"
//Import Image
import avatar2 from "../../../../assets/images/users/avatar-2.jpg"
import img3 from "../../../../assets/images/small/img-3.jpg"
import img4 from "../../../../assets/images/small/img-4.jpg"
import { Badge } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//Import Email Sidebar
import EmailSideBar from "./email-sidebar"

//Import Email Topbar
import EmailToolbar from "./email-toolbar"
import { ContentContext } from "./../context"
import { useHistory } from "react-router-dom"
import { useRequest } from "../../../../hooks/useRequestSWR"
import { useRouteCode } from "hooks/useRouteCode"

import Select from "react-select"
import { customStyles } from "../../../../Globales/index"
import { useForm, Controller } from "react-hook-form"

import Moment from "react-moment"
import "moment/locale/es-mx"

import { DragDrop } from "@uppy/react"
import Uppy from "@uppy/core"

const ErrorToast = () => (
  <>
    <div className="toastify-header">
      <div className="title-wrapper">
        <h6 className="toast-title">Tipo no admitido</h6>
      </div>
      <small className="text-muted">Hace un momento.</small>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        Solo son aceptados los archivos con extensión:{" "}
        <span className="font-weight-bolder">.pdf</span>,{" "}
        {/* <span className="font-weight-bolder">.xls</span> &{" "}
        <span className="font-weight-bolder">.csv</span> !. */}
      </span>
    </div>
  </>
)

const EmailRead = () => {
  const {
    toggleModal,
    toggleModal2,
    modal,
    modal2,
    StoreUpdate,
    StoreUpdate2,
    opcion,
    opcion2,
    oneData,
    oneData2,
    nameComponent,
    labelUnidades,
    labelTipoOrigen,
    labelFand,
    labelOrigenExterno,
    dataEmpleados: empleados,
    file: fileOne,
    mutate,
    setId,
    labelUbicacionVisita,
    setFechaInicio,
    setFechaFin,
    labelDependencia,
  } = useContext(ContentContext)
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
  } = useForm()
  const seleccionableUnidad = watch("unidad")
  const nameController = "Documentos"
  const history = useHistory()
  const cod = useRouteCode()?.codigo
  const { data: allDetalle } = useRequest(
    `${nameController}/one-Documento`,
    "POST",
    { id: 0, code: cod }
  )

  const Variante = ["danger", "primary", "success", "warning"]
  useEffect(() => {
    history.location.pathname === "/documentos/detalle" && history.push("/")
  }, [history])

  useEffect(() => {
    let abortController = new AbortController()
    async function fetchMyAPI() {
      if (
        seleccionableUnidad !== null ||
        seleccionableUnidad !== "" ||
        seleccionableUnidad !== undefined
      ) {
        setId(seleccionableUnidad?.value)
      }
      if (
        seleccionableUnidad === null ||
        seleccionableUnidad === "" ||
        seleccionableUnidad === undefined
      ) {
        setValue("persona", "")
        setId(0)
      }
    }
    fetchMyAPI()
    return () => {
      abortController.abort()
    }
  }, [seleccionableUnidad])

  // useEffect(() => {
  //     if (Array.isArray(empleados)) {
  //         // Definir el nuevo objeto a añadir
  //         const nuevoElemento = { label: 'No Aplica', value: null, idUnidad: 0 };
  //         // Añadir el nuevo objeto al array
  //         empleados.push(nuevoElemento);
  //     }
  // }, [empleados]);

  console.log(seleccionableUnidad)

  const [file, setFile] = useState(fileOne)
  const uppy = new Uppy({
    restrictions: {
      maxNumberOfFiles: 1,
      maxTotalFileSize: 100 * 1024 * 1024,
      allowedFileTypes: ["application/pdf"],
    },
    autoProceed: true,
    fieldName: "document",
  })

  uppy.on("complete", result => {
    // setShowLoad(true)

    if (
      result.successful[0].extension === "pdf"
      // ||
      // result.successful[0].extension === "csv" ||
      // result.successful[0].extension === "xls"
    ) {
      setFile(result.successful[0].data)
      setName(result.successful[0].data.name)
      setSize(result.successful[0].data.size)
      // setShowLoad(false)
    } else {
      toast.error(<ErrorToast />, { hideProgressBar: true })
      // setShowLoad(false)
    }
  })

  const onSubmit = data => {
    const json = {
      documento: allDetalle[0]?.id,
      dependencia: data.dependencia.value,
    }
    /*  StoreUpdate(json) */
    console.log(json)
    reset()
  }

  const validateFile = () => {
    if(!file || file?.size === 0){
      setError("file", { type: "custom", message: "Debe adjuntar un archivo PDF" })
      return false
    }
    
    
    
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Documento" breadcrumbItem="Detalle Documento" />
          <Row>
            <Col xs="12">
              {/* Render Email SideBar */}
              <EmailSideBar />

              <div className="email-rightbar mb-3">
                <Card>
                  {/* Render Email Top Tool Bar */}
                  {/* <EmailToolbar /> */}

                  <CardBody>
                    <div className="d-flex mb-4">
                      <img
                        className="d-flex me-3 rounded-circle avatar-sm"
                        src={avatar2}
                        alt="skote"
                      />
                      <div className="flex-grow-1">
                        <div className="d-flex justify-content-between">
                          <h5 className="font-size-14 mt-1">
                            {allDetalle && allDetalle[0]?.creadoPor}
                          </h5>
                          <Badge
                            color={
                              Variante[allDetalle && allDetalle[0]?.idEstado]
                            }
                            className={`badge-soft-${
                              Variante[allDetalle && allDetalle[0]?.idEstado]
                            } text-center font-weight-bolder fs-6`}
                            style={{ padding: "8px", width: "13%" }}
                          >
                            {allDetalle && allDetalle[0]?.estado}
                          </Badge>
                        </div>
                        <div className="d-flex justify-content-between">
                          <small className="text-muted pr-2">
                            {allDetalle && allDetalle[0]?.puestoCreadoPor} -{" "}
                            {allDetalle && allDetalle[0]?.unidadCreadoPor}
                          </small>
                          <h5 className="font-size-14 mt-1">
                            {
                              <Moment locale="es" fromNow>
                                {allDetalle &&
                                  allDetalle[0]?.creado
                                    ?.toString()
                                    ?.toUpperCase()}
                              </Moment>
                            }
                          </h5>
                        </div>
                      </div>
                    </div>

                    <h4 className="mt-0 font-size-16">
                      {allDetalle && allDetalle[0]?.nombre}
                    </h4>

                    <p className="d-flex mt-0 ">
                      {allDetalle && allDetalle[0]?.tipoOrigen} -{" "}
                      {allDetalle && allDetalle[0]?.origen}
                    </p>
                    <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>* Unidad</Label>
                            <Controller
                              name="unidad"
                              control={control}
                              defaultValue={null}
                              render={({ field }) => {
                                return (
                                  <Select
                                    {...field}
                                    isClearable
                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                    isDisabled={opcion === 4 || opcion === 2}
                                    options={labelUnidades}
                                    className="react-select"
                                    classNamePrefix="select"
                                    placeholder={"Seleccione una opción"}
                                    noOptionsMessage={() => "sin resultados"}
                                    styles={!!errors.unidad ? customStyles : {}}
                                  />
                                )
                              }}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Este campo es requerido",
                                },
                              }}
                            />
                            <small className="text-danger">
                              {!!errors.unidad && errors.unidad.message}
                            </small>
                          </FormGroup>
                        </Col>
                        {labelDependencia &&
                          labelDependencia.value !== 2009 && (
                            <Col>
                              <FormGroup>
                                <Label>* Dependencia</Label>
                                <Controller
                                  name="dependencia"
                                  control={control}
                                  defaultValue={null}
                                  render={({ field }) => {
                                    return (
                                      <Select
                                        {...field}
                                        isClearable
                                        isDisabled={
                                          opcion === 4 || opcion === 2
                                        }
                                        onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                        onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                        options={labelDependencia}
                                        className="react-select"
                                        classNamePrefix="select"
                                        placeholder={"Seleccione una opción"}
                                        noOptionsMessage={() =>
                                          "sin resultados"
                                        }
                                        styles={
                                          !!errors.dependencia
                                            ? customStyles
                                            : {}
                                        }
                                      />
                                    )
                                  }}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: "Este campo es requerido",
                                    },
                                  }}
                                />
                                <small className="text-danger">
                                  {!!errors.dependencia &&
                                    errors.dependencia.message}
                                </small>
                              </FormGroup>
                            </Col>
                          )}
                      </Row>
                      <Row>
                        {/* <Col>
                        <FormGroup >
                          <Label >* Unidad donde se dirige</Label>
                          <Controller
                            name="unidad"
                            control={control}
                            defaultValue={null}
                            render={({ field }) => {
                              return (
                                <Select
                                  {...field}
                                  isClearable
                                  onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                  onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                  isDisabled={opcion === 2}
                                  options={labelUnidades}
                                  className='selector-react1'
                                  classNamePrefix='select'
                                  placeholder={'Seleccione una opción'}
                                  noOptionsMessage={() => 'sin resultados'}
                                  styles={(!!errors.unidad) ? customStyles : {}}

                                />
                              )
                            }
                            }
                            rules={
                              {
                                required: {
                                  value: true,
                                  message: 'Este campo es requerido'
                                }
                              }
                            }
                          />
                          <small className="text-danger">{!!errors.unidad && errors.unidad.message}</small>
                        </FormGroup>
                      </Col> */}
                        <Col>
                          <FormGroup>
                            <Label>* Dirigido A</Label>
                            <Controller
                              name="persona"
                              control={control}
                              defaultValue={null}
                              render={({ field }) => {
                                return (
                                  <Select
                                    {...field}
                                    isClearable
                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                    isDisabled={opcion === 2}
                                    options={empleados}
                                    className="selector-react2"
                                    classNamePrefix="select"
                                    placeholder={"Seleccione una opción"}
                                    noOptionsMessage={() => "sin resultados"}
                                    styles={
                                      !!errors.persona ? customStyles : {}
                                    }
                                  />
                                )
                              }}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Este campo es requerido",
                                },
                              }}
                            />
                            <small className="text-danger">
                              {!!errors.persona && errors.persona.message}
                            </small>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label for="descripcion" className="">
                              * Descripcion:{" "}
                            </Label>
                            <Controller
                              control={control}
                              id="descripcion"
                              name="descripcion"
                              render={({ field }) => (
                                <Input
                                  rows={2}
                                  type="textarea"
                                  // readOnly={updateData}
                                  invalid={errors.descripcion && true}
                                  defaultValue=""
                                  {...field}
                                />
                              )}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Este campo es requerido",
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {/*    <p>
                      Praesent dui ex, dapibus eget mauris ut, finibus
                      vestibulum enim. Quisque arcu leo, facilisis in fringilla
                      id, luctus in tortor. Nunc vestibulum est quis orci varius
                      viverra. Curabitur dictum volutpat massa vulputate
                      molestie. In at felis ac velit maximus convallis.
                    </p>
                    <p>
                      Sed elementum turpis eu lorem interdum, sed porttitor eros
                      commodo. Nam eu venenatis tortor, id lacinia diam. Sed
                      aliquam in dui et porta. Sed bibendum orci non tincidunt
                      ultrices. Vivamus fringilla, mi lacinia dapibus
                      condimentum, ipsum urna lacinia lacus, vel tincidunt mi
                      nibh sit amet lorem.
                    </p>
                    <p>Sincerly,</p>
                    <hr /> */}

                      <Row>
                        <Col xl="12" xs="6">
                          <Card>
                            {
                              <FormGroup>
                                <DragDrop
                                  uppy={uppy}
                                  locale={{
                                    strings: {
                                      dropHereOr:
                                        file?.size > 0
                                          ? "Documento cargado y listo para subir"
                                          : "Arrastra un documento PDF aquí o %{browse}",
                                      browse: "busca",
                                      // browse: "browse"
                                    },
                                  }}
                                />
                                {!file && file?.size === 0 && (
                                  <div className="text-danger">
                                    {errors?.file?.message}
                                  </div>
                                )}
                              </FormGroup>
                            }
                            {/*     <img
                            className="card-img-top img-fluid"
                            src={img4}
                            alt="skote"
                          />
                          <div className="py-2 text-center">
                            <Link to="" className="fw-medium">
                              Download
                            </Link>
                          </div> */}
                          </Card>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="d-flex justify-content-start">
                          <Button
                            id="salir"
                            color="secondary"
                            onClick={() => toggleModal(0)}
                          >
                            <Icon.CornerUpLeft size={16} /> Salir
                          </Button>
                        </Col>
                        {opcion !== 2 && (
                          <Col className="d-flex justify-content-end">
                            <Button
                              color="success"
                              disabled={opcion === 1 && file?.length === 0}
                              type="submit"
                              id="accion"
                            >
                              <Icon.Save size={16} /> Trasladar
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EmailRead
