import React, { useContext } from 'react'
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { ContentContext } from './context'

const SalesAnalytics = props => {

  const { allIndicadorDiasDI } = useContext(ContentContext),
    indicadorDias = (Array.isArray(allIndicadorDiasDI) && allIndicadorDiasDI.length > 0 && allIndicadorDiasDI?.value !== 2009)
      ? allIndicadorDiasDI[0]
      : [];
  const series = [indicadorDias?.ticketsEnTiempo, indicadorDias?.ticketsFueraTiempo];
  const options = {
    labels: ["En Tiempo", "Fuera de Tiempo"],
    colors: ["#556ee6", "#f1b44c"],
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "60%",
        },
      },
    },
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4 text-center fs-5">90 % DE TICKET ATENDIDOS EN 3 DIAS (SOPORTE IT)</CardTitle>

          <div>
            <div id="donut-chart">
              <ReactApexChart
                options={options}
                series={series}
                type="donut"
                height={260}
                className="apex-charts"
              />
            </div>
          </div>

          <div className="text-center text-muted">
            <Row>
              <Col xs="4">
                <div className="mt-4">
                  <p className="mb-2 text-truncate">
                    <i className="mdi  text-primary me-1" /> Tickets
                  </p>
                  <h5>{indicadorDias?.totalTickets}</h5>
                </div>
              </Col>
              <Col xs="4">
                <div className="mt-4">
                  <p className="mb-2 text-truncate">
                    <i className="mdi mdi-circle text-primary me-1" /> En Tiempo
                  </p>
                  <h5>{indicadorDias?.ticketsEnTiempo}</h5>
                </div>
              </Col>
              <Col xs="4">
                <div className="mt-4">
                  <p className="mb-2 text-truncate">
                    <i className="mdi mdi-circle text-warning me-1" /> Fuera de Tiempo
                  </p>
                  <h5>{indicadorDias?.ticketsFueraTiempo}</h5>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SalesAnalytics;
