import React, { createContext, useState, useEffect } from 'react'
import { PostRoute } from '../../../services/Private'
import { OptionsToast } from '../../../Globales/index'
import { toast } from 'react-toastify'
import { useRequest } from '../../../hooks/useRequestSWR'
import { useSWRConfig } from 'swr'
import { useDataUser } from 'hooks/useDataUser'
import PDFTransportes from './PDFTransportes.jsx'
import PDFGasto from './PDFGasto.jsx'
import PDFCajaChica from './PDFCajaChica.jsx'


export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    // Modal Init
    const code = useDataUser()?.dataUser?.codigoUnidad;
    const solicitante = useDataUser()?.dataUser?.i;
    const puestoUserLogged = useDataUser()?.dataUser?.puesto;
    const nombreSolicitante = useDataUser()?.dataUser?.nombreCompleto;
    const [idAccion, setAccion] = useState(0);
    const idFADN = useDataUser()?.dataUser?.idFADN;
    const esFADN = useDataUser()?.dataUser?.esFADN;
    const { mutate } = useSWRConfig();
    const [id, setId] = useState(0);
    const [mutateSaldoAccion, setMutateSaldoAccion] = useState(false);
    const [idPresupuestoPoa, setIdPresupuestoPoa] = useState(0);
    const [idModalidad, setIdModalidad] = useState(0);
    const [validation, setValidation] = useState(0);
    const [pdf, setPdf] = useState([]);
    const nameComponent = 'Gestión';
    const nameController = 'Gestiones';
    const nameController2 = 'Acciones';
    const nameController3 = 'TipoPedido';
    const nameController4 = 'TipoGasto';
    const nameController5 = 'Fand';
    const nameController6 = 'Persona';
    const { data: allData } = useRequest(`${nameController}/all`, 'GETSWR');
    const { data: allDataAsignaciones } = useRequest(`${nameController}/allDetalleAsignacion`, 'POSTValidation', { id: id }, {}, validation);
    // const { data: labelAcciones } = useRequest(`${nameController2}/label-Unidad`, 'POST', { code: code });
    // const { data: labelJefeDireccion } = useRequest(`${nameController6}/jefeDireccion`, 'POST', {});
    // const { data: labelTipoPedido } = useRequest(`${nameController3}/label`, 'GET');
    // const { data: labelTipoGasto } = useRequest(`${nameController4}/label`, 'GET');
    const { data: labelPilotos } = useRequest(`${nameController}/labelPilotos`, 'GET');
    const { data: labelTecnicos } = useRequest(`${nameController}/labelTecnicos`, 'GET');
    const { data: labelVehiculos } = useRequest(`${nameController}/labelVehiculos`, 'GET');
    const
        [modal, setModal] = useState(null),
        // [allData, setAllData] = useState([]),
        [oneData, setOneData] = useState([]),
        [DataBitacoraPedido, setDataBitacoraPedido] = useState([]),
        [agregarRenglon, setAgregarRenglon] = useState(0),
        [opcion, setOpcion] = useState(0),
        toggleModal = async (data) => {

            setOpcion(data)
            if (data === 1) { setOneData(null), setIdPresupuestoPoa(0) }
            setModal(!modal)

        },
        // activaMutateTotalSaldo = async (data) => {

        //     setOpcion(data)
        //     if (data === 1) { setOneData(null), setIdPresupuestoPoa(0) }
        //     setModal(!modal)

        // },
        // All = async () => {
        //     const response = await GetRoute(`${nameController}/all`)
        //     setAllData((response.length) ? response : [])
        // },
        One = async (data, opcion) => {
            const response = await PostRoute(`${nameController}/one`, { id: data.id })
            if (response?.value !== 2009) {
                setIdModalidad(response[0]?.idActividadGestion)
                setValidation(1)
                setId(data?.id)
            }
            setOneData((response[0]) ? response[0] : [])
            toggleModal(opcion)

        },
        BitacoraPedido = async (data, opcion) => {
            const response = await PostRoute(`${nameController}/bitacoraGestiones`, { id: data.id })
            setDataBitacoraPedido((response) ? response : [])
            toggleModal(opcion)

        },
        StoreUpdate = async (data, id) => {
            let response = []
            response = await PostRoute(`${nameController}/${!data.id ? 'store' : 'update'}`, data)
            const responseTwo = await PostRoute(`${nameController}/one`, { id: (!id ? response?.id : id) })
            setOneData((responseTwo[0]) ? responseTwo[0] : [])
            setOpcion(3)
            if (response.response === 0) {
                toast.error(response.message, OptionsToast)
            } else {
                toast.success(response.message, OptionsToast)
                setAccion(responseTwo[0]?.idAccion)

            }
            // toast.success(response.message, OptionsToast)
            // toggleModal(0)
            mutate(`${nameController}/all`)

        },
        Actions = async (data) => {

            const response = await PostRoute(`${nameController}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data.id })
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)

        },
        DestroyAsignacion = async (data) => {
            const response = await PostRoute(`${nameController}/destroyAsignacion`, { id: data?.id })
            toast.success(response.message, OptionsToast)
            mutate([`${nameController}/allDetalleAsignacion`, { id: id }])

        },
        UpdateEstadoPedido = async (data) => {
            const response = await PostRoute(`${nameController}/${'UpdateEstadoGestiones'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)

        },
        FinalizarST = async (data) => {
            const response = await PostRoute(`${nameController}/${'finalizarST'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)

        },
        IniciarConteoTiempo = async (data) => {
            const response = await PostRoute(`${nameController}/${'iniciarConteoTiempo'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)

        },
        EnviarARevision = async (data, opcion) => {
            toggleModal(opcion)
            const response = await PostRoute(`${nameController}/${'enviarARevision'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)
        },
        AsignarGestTransporte = async (data, type) => {
            if (type === 0) {
                toast.error('La persona ya está asignada a esta gestión', OptionsToast)
            } else {
                const response = await PostRoute(`${nameController}/${'asignar'}`, data)
                toast.success(response.message, OptionsToast)
                mutate(`${nameController}/all`)
                mutate([`${nameController}/allDetalleAsignacion`, { id: id }])
            }
        },
        Imprimir = async (data) => {
            const response = await PostRoute(`GestionesDetalle/allDetalle`, { id: data?.id })
            // const TOTAL = await PostRoute(`PedidoDetalle/totalDetallePedido`, { id: data?.id })
            if (response?.response === 0) {
                toast.error(response.message, OptionsToast)
            } else {
                if (data?.idActividadGestion === 1) {
                    const response3 = await PDFTransportes(data, response)
                    //const response3 = await FormatoRazonamientoCarta(data, dataPersonaLog.data[0], dataJefe.data[0])
                    return response3
                }
                if (data?.modalidad === 2) {
                    const response3 = await PDFGasto(data, response, TOTAL)
                    //const response3 = await FormatoRazonamientoCarta(data, dataPersonaLog.data[0], dataJefe.data[0])
                    return response3
                }
                if (data?.modalidad === 3) {
                    const response3 = await PDFCajaChica(data, response, TOTAL)
                    //const response3 = await FormatoRazonamientoCarta(data, dataPersonaLog.data[0], dataJefe.data[0])
                    return response3
                }



            }

        },
        labelActividades = [
            { value: 1, label: 'Transporte' },
            { value: 2, label: 'Vehículo' },
            { value: 3, label: 'Soporte Técnico' },
            { value: 4, label: 'Audiovisuales' },
            { value: 5, label: 'Mantenimiento' },
            { value: 6, label: 'Infraestructura' },
            { value: 7, label: 'Préstamo de Equipo' }
        ],
        value = {
            Actions,
            One,
            setId,
            setIdPresupuestoPoa,
            setAgregarRenglon,
            setAccion,
            StoreUpdate,
            toggleModal,
            useDataUser,
            agregarRenglon,
            idAccion,
            allData,
            idPresupuestoPoa,
            // labelAcciones,
            // labelFand,
            // labelJefeDireccion,
            // labelTipoGasto,
            // labelTipoPedido
            modal,
            nameComponent,
            oneData,
            opcion,
            solicitante,
            nombreSolicitante,
            puestoUserLogged,
            mutateSaldoAccion, setMutateSaldoAccion,
            code,
            setIdModalidad,
            idModalidad,
            Imprimir,
            pdf, setPdf,
            UpdateEstadoPedido,
            DataBitacoraPedido,
            BitacoraPedido,
            EnviarARevision,
            labelActividades,
            idFADN,
            esFADN,
            labelPilotos,
            labelVehiculos,
            labelTecnicos,
            AsignarGestTransporte,
            allDataAsignaciones,
            DestroyAsignacion,
            IniciarConteoTiempo,
            FinalizarST
        }

    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}
