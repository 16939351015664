import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Row, Col, Form, FormGroup, Label, FormFeedback, Input, Button, UncontrolledTooltip, UncontrolledButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap'
import { useForm, Controller } from 'react-hook-form'
import { ContentContext } from './context'
// import { selectThemeColors } from '@utils'
import * as Icon from 'react-feather'
import Select from 'react-select'
import List from './list'
import ListLiqui from './listLiquidacion'
import ListAjuste from './listAjuste'
import { customStyles } from 'Globales/index'
import { CustomText, EstadoLista, TextDetalleAccion } from 'CustomList/index'
import CurrencyInput from 'components/CurrencyInput/CurrencyInput'
import classnames from 'classnames'
import DataTable from 'react-data-table-component'
import { ContentContext as ContentContextTheme } from 'components/CommonForBoth/TopbarDropdown/context'



const DetalleAcciones = (props) => {
    const { theme } = useContext(ContentContextTheme)
    const { handleSubmit, formState: { errors }, control, setValue, reset, watch } = useForm(),
        inputRenglon = watch("renglon"),
        inputCostoUnitario = watch("costoU"),
        inputCostoReal = watch("costoReal"),
        inputCantidad = watch("cantidad"),
        [btnDisable, setBtnDisable] = useState(false),
        { StoreUpdate, setOneData, One, oneData, labelRenglon, setAccion, setRenglon, allInsumos, labelUnidadMedida, actualiza, totalDetallePedido, labelProveedores, Liquidar, Opcion2, oneData2 } = useContext(ContentContext),
        [searchValue, setSearchValue] = useState(''),
        [closeSeleccionableInsumos, setCloseSeleccionableInsumos] = useState(false),
        [alertaSaldo, setAlertaSaldo] = useState(false),
        [alertaSaldoReal, setAlertaSaldoReal] = useState(false),
        [saldo, setSaldo] = useState(0),
        [filteredData, setFilteredData] = useState([]),
        [insumo, setInsumo] = useState([]),
        [presentacion, setPresentacion] = useState([]),
        SeleccionaInsumo = async (data) => {

            setCloseSeleccionableInsumos(true)
            setValue('descripcion', data?.nombre + ' - ' + data?.caracteristicas + ' / ' + data?.presentacion + ' ' + data?.unidadMedida + ' | Código de insumo:' + ' ' +
                (data?.codigoInsumo) + ', Código presentacion: ' + (data?.codigoPresentacion));
            setInsumo(data?.codigoInsumo);
            setPresentacion(data?.codigoPresentacion);
            setSearchValue('');
        },
        handleFilter = e => {
            const value = e.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            setSearchValue(value);
            let updatedData = []


            if (value.length) {
                const updatedData = allInsumos?.filter(item => {
                    const nombre = item.nombre.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    const codigoInsumo = item.codigoInsumo.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    const caracteristicas = item.caracteristicas.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

                    return nombre.includes(value) || codigoInsumo.includes(value) || caracteristicas.includes(value);
                });

                setFilteredData(updatedData)
                setSearchValue(value)
            }
        },
        Columns = [
            {
                name: 'Seleccionar',
                column: 'id',
                sortable: true,
                center: true,
                grow: 0.5,
                cell: row => (
                    <Fragment>
                        <div className={`btn-icon  px-2 cursor-pointer ${btnDisable && 'cursor-not-allowed'}`} color='success' id='Actualizar' disabled={btnDisable} style={{ outline: 'none' }} size="sm"
                            onClick={() => [SeleccionaInsumo(row)]}>
                            <Icon.CheckSquare size={22} />
                        </div>
                        <UncontrolledTooltip placement='top' target='Actualizar'>
                            Seleccionar
                        </UncontrolledTooltip>

                    </Fragment>
                )
            },
            {
                name: 'Renglón',
                column: 'renglon',
                sortable: true,
                center: false,
                width: '100px',
                cell: row => TextDetalleAccion(row['renglon'])
            },
            {
                name: 'Cod. Insumo',
                column: 'codigoInsumo',
                sortable: true,
                center: false,
                width: '125px',
                cell: row => TextDetalleAccion(row['codigoInsumo'])
            },
            {
                name: 'Nombre',
                column: 'nombre',
                sortable: true,
                grow: 1, // Esto permite que la columna crezca para ajustarse al contenido
                wrap: true,// Esto permite que el texto se envuelva y no sea truncado,
                cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify' }}>{row.nombre}</div>
            },
            {
                name: 'Caracteristicas',
                column: 'caracteristicas',
                sortable: true,
                grow: 3, // Ajusta el valor según sea necesario para dar más espacio a la columna
                wrap: true,
                cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify' }}>{row.caracteristicas}</div> // Esto asegura que el contenido no se trunca

            },
            {
                name: 'U. de Medida',
                column: 'unidadMedida',
                sortable: true,
                grow: 0.5, // Ajusta el valor según sea necesario para dar más espacio a la columna
                wrap: true,
                cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'center' }}>{row.unidadMedida}</div> // Esto asegura que el contenido no se trunca

            },
            {
                name: 'Cod. Presentación',
                column: 'codigoPresentacion',
                sortable: true,
                center: true,
                grow: 0.2, // Ajusta el valor según sea necesario para dar más espacio a la columna
                wrap: true,
                cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'center' }}>{row.codigoPresentacion}</div> // Esto asegura que el contenido no se trunca

            }
            // {
            //     name: 'Acciones',
            //     column: 'id',
            //     sortable: true,
            //     center: true,
            //     width: '10px',
            //     cell: row => (
            //         <UncontrolledButtonDropdown style={{ margin: 0 }} >

            //             <DropdownToggle style={{ margin: 0 }} className='pr-1' tag='span'>
            //                 <Icon.MoreVertical size={15} />
            //             </DropdownToggle>
            //             <DropdownMenu className="!m-0" style={{ margin: 0 }} >

            //                 <DropdownItem className="w-100" onClick={() => One(row, 2)}>
            //                     <Icon.Eye className="me-2" size={15} />
            //                     <span className='align-middle ml-50'>Visualizar</span>
            //                 </DropdownItem>
            //             </DropdownMenu>
            //         </UncontrolledButtonDropdown>
            //     )
            // }
        ],
        clearData = async () => {
            reset({ costo: '', costoU: '', renglon: '', unidadMedida: '', cantidad: '', descripcion: '' })
            //     setValue('descripcion', '')
            //     setValue('cantidad', '')
            //     setValue('unidadMedida', '')
            //     setValue('renglon', '')
            //     setValue('costo', '')
            //     setValue('costoU', '')
            setRenglon([])
            setInsumo([]);
            setPresentacion([]);
            setAlertaSaldo(false)
        },
        onSubmit = (data) => {
            const descripcion = oneData?.descripcion
                ? oneData.descripcion // Usa la descripción de oneData si tiene contenido
                : inputRenglon?.generaInsumo === 'No Genera insumo'
                    ? `${data?.descripcion}` + ' | Código de insumo:' + ' N/A' + ', Código presentacion: ' + 'N/A' // Usa "N/A" si generaInsumo es 'No Genera insumo'
                    : `${data?.descripcion}`; // Construye la descripción si no se cumple ninguna de las condiciones anteriores

            const json = {
                detalleAccion: inputRenglon?.value,
                pedido: props?.oneData && props?.oneData?.id,
                unidadMedida: inputRenglon?.generaInsumo === 'No Genera insumo' ? data?.unidadMedida.value : 0,
                descripcion: descripcion,
                costoPedido: String(data?.costo).includes('Q') ? data?.costo.replace('Q', '').replace(',', '').replace(',', '') : data?.costo,
                costoEstimado: String(data?.costo).includes('Q') ? data?.costo.replace('Q', '').replace(',', '').replace(',', '') : data?.costo,
                costoUnitario: String(data?.costoU).includes('Q') ? Number(data?.costoU.replace('Q', '').replace(',', '').replace(',', '')) : Number(data?.costoU),
                cantidad: Number(data?.cantidad),
                insumo: inputRenglon?.generaInsumo === 'No Genera insumo' ? "N/A" : insumo,
                presentacion: inputRenglon?.generaInsumo === 'No Genera insumo' ? "N/A" : presentacion,
                id: (oneData?.length !== 0) ? oneData?.id : null,
            }
            StoreUpdate(json)
            setBtnDisable(false)
            props.setAgregarRenglon(0),
                clearData(),
                setCloseSeleccionableInsumos(false)
        },
        onSubmit2 = (data) => {


            const json = {

                pedido: props?.oneData && props?.oneData?.id,
                proveedor: data?.proveedor.value,
                costoReal: String(data?.costoReal).includes('Q') ? data?.costoReal.replace('Q', '').replace(',', '').replace(',', '') : data?.costoReal,
                serieFactura: data?.serie,
                noFactura: data?.noFactura,
                id: (oneData?.length !== 0) ? oneData?.id : null,
            }
            Liquidar(json)
            setBtnDisable(false)
            props.setAgregarRenglon(0),
                clearData(),
                setCloseSeleccionableInsumos(false)
        },
        setData = async () => {
            setValue('descripcion', oneData?.descripcionConPartida)
            setValue('costo', oneData?.costoOne)
            setValue('costoU', oneData?.costoUnitarioOne)
            setValue('costoPedido', oneData?.costoOne)
            setValue('cantidad', oneData?.cantidad)
            setValue('unidadMedida', { label: oneData?.unidadMedida, value: oneData?.idUnidadMedida })
            setValue('renglon', { label: oneData?.renglon, value: oneData?.detalleAccion, generaInsumo: oneData?.generaInsumo, saldoRenglonNumeros: oneData?.saldoRenglonNumeros })
            setRenglon(oneData?.idRenglon)
            setInsumo(oneData?.insumo);
            setPresentacion(oneData?.presentacion);
            setCloseSeleccionableInsumos(true)
        }

    useEffect(
        () => {
            // async function fetchMyAPI() {
            if (actualiza === true) {
                setData()
            }
            // }
            // fetchMyAPI()
        }, [actualiza, oneData]
    )

    useEffect(() => {
        const functionFetch = async () => {
            if (props?.oneData && props?.oneData?.id) {
                // console.log(props?.oneData?.id)
                await setAccion(props?.oneData?.id)
            }
        }
        functionFetch()
    }, [props?.oneData])

    useEffect(() => {
        if (inputRenglon) {
            setRenglon(inputRenglon?.idRenglon)
            setSaldo(inputRenglon?.saldoRenglonNumeros)
        }
        if (inputRenglon === null || inputRenglon === '') {
            setValue('renglon', '');
            // setRenglon([])
        }
    }, [inputRenglon]);

    useEffect(() => {
        if (Number(inputCantidad) > 0 && Number(String(inputCostoUnitario)?.includes('Q') ? inputCostoUnitario?.replace('Q', '')?.replace(',', '')?.replace(',', '') : inputCostoUnitario) > 0) {
            setValue('costo', Number(inputCantidad) * Number(String(inputCostoUnitario)?.includes('Q') ? inputCostoUnitario?.replace('Q', '')?.replace(',', '')?.replace(',', '') : inputCostoUnitario))
            const CostoTotal = Number(inputCantidad) * Number(String(inputCostoUnitario)?.includes('Q') ? inputCostoUnitario?.replace('Q', '')?.replace(',', '')?.replace(',', '') : inputCostoUnitario)
            // console.log(CostoTotal)
            // console.log(Number(saldo))
            if (CostoTotal > Number(saldo)) {
                setAlertaSaldo(true)
            } else {
                setAlertaSaldo(false)
            }
        }
        else {
            setValue('costo', '');
        }
    }, [inputCantidad, inputCostoUnitario, saldo]);


    useEffect(() => {
        const formatNumber = (num) => {
            return Number(num?.toFixed(6));
        };

        if (Number(inputCostoReal?.replace('Q', '')?.replace(',', '')?.replace(',', '')?.replace(',', '')) > 0) {


            if (formatNumber(Number(inputCostoReal?.replace('Q', '')?.replace(',', '')?.replace(',', '')?.replace(',', ''))) > Number(oneData?.costoOne)) {
                setAlertaSaldoReal(true);
            } else {
                setAlertaSaldoReal(false);
            }
        }
    }, [inputCostoReal, oneData?.costoOne]);
    return (
        <Fragment>
            <div className={props.agregarRenglon && props.agregarRenglon === 1 ? ` p-3` : `null`}>
                {props.agregarRenglon && props.agregarRenglon === 1 ?
                    <Form
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        {props.agregarRenglon && props.agregarRenglon === 1 ?
                            <Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="renglon" className="d-flex justify-content-between">
                                                <span>* Renglón</span>
                                            </Label>
                                            <Controller
                                                name="renglon"
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            {...field}
                                                            isClearable
                                                            onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                            onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                            defaultValue={null}
                                                            options={labelRenglon}
                                                            // theme={selectThemeColors}
                                                            // isDisabled={!allMedidaInsumo}
                                                            className='selector-react1'
                                                            classNamePrefix='select'
                                                            placeholder={'Seleccione una opción'}
                                                            noOptionsMessage={() => 'sin resultados'}
                                                            styles={(!!errors.renglon) ? customStyles : {}}
                                                        />
                                                    )
                                                }
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <small className="text-danger">
                                                {!!errors.renglon && errors.renglon.message}
                                            </small>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {inputRenglon?.idRenglon && closeSeleccionableInsumos === false &&
                                    <div>
                                        {inputRenglon?.generaInsumo === 'No Genera insumo' ? null : <Row className='justify-content-between mx-0'>
                                            <Col></Col>
                                            <Col></Col>
                                            <Col className='justify-content-end' md='4' sm='12'>
                                                <FormGroup>
                                                    <Input
                                                        className='dataTable-filter'
                                                        type='text'
                                                        bsSize='md'
                                                        placeholder={`Buscar código de insumo o nombre`}
                                                        id='search-input'
                                                        value={searchValue}
                                                        onChange={handleFilter}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>}
                                        <DataTable
                                            dense
                                            striped
                                            noHeader
                                            highlightOnHover
                                            pagination
                                            theme={theme}
                                            data={searchValue.length ? filteredData : (allInsumos && allInsumos.value !== 2009 ? allInsumos : [])}
                                            columns={Columns}
                                            className='table-responsive mt-4'
                                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                            paginationComponentOptions={
                                                {
                                                    rowsPerPageText: '',
                                                    rangeSeparatorText: ''
                                                }
                                            }
                                            noDataComponent={inputRenglon?.generaInsumo === 'No Genera insumo' ? 'No Genera insumo' : 'No genera Insumos'}
                                        />
                                    </div>}
                                <Row>
                                    <Col >
                                        <FormGroup>
                                            <Label for="descripcion" className="">* Descripción</Label>
                                            <Controller
                                                control={control}
                                                id='descripcion'
                                                name='descripcion'
                                                render={({ field }) => (
                                                    <Input
                                                        {...field}
                                                        rows={3}
                                                        type='textarea'
                                                        disabled={inputRenglon?.generaInsumo === 'No Genera insumo' ? false : true}
                                                        invalid={errors.descripcion && true}
                                                    // defaultValue=''
                                                    />
                                                )
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <FormFeedback>
                                                {!!errors.descripcion && errors.descripcion.message}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Col >
                                    <FormGroup>
                                        <Label for="cantidad"> * Cantidad </Label>
                                        <Controller
                                            defaultValue='undefined'
                                            control={control}
                                            id='cantidad'
                                            name='cantidad'
                                            render={({ field }) => (
                                                <Input
                                                    type='number'
                                                    // readOnly={opcion === 2}
                                                    invalid={errors.cantidad && true}
                                                    {...field}
                                                />
                                            )
                                            }
                                            rules={
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido'
                                                    }
                                                }
                                            }
                                        />
                                        <FormFeedback>
                                            {!!errors.cantidad && errors.cantidad.message}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for='cantidad' className="">* Costo Unitario</Label>
                                        <Controller
                                            defaultValue=''
                                            control={control}
                                            id='costoU'
                                            name='costoU'
                                            render={({ field }) => (
                                                <CurrencyInput placeholder="Q. 0.00" type="text"
                                                    // className="form-control"
                                                    className={classnames('form-control  invoice-edit-input due-date-picker', {
                                                        'is-invalid': errors.costoU && true
                                                    })}
                                                    // readOnly={opcion === 2}
                                                    // disabled={opcion === 3 || opcion === 4}
                                                    // invalid={errors.costoU && true}
                                                    {...field}
                                                />
                                            )
                                            }
                                            rules={
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido'
                                                    }
                                                }
                                            }
                                        />
                                        <FormFeedback>
                                            {!!errors.costoU && errors.costoU.message}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for='costo' className="">* Costo Total</Label>
                                        <Controller
                                            defaultValue=''
                                            control={control}
                                            id='costo'
                                            name='costo'
                                            render={({ field }) => (
                                                <CurrencyInput placeholder="Q. 0.00" type="text"
                                                    // className="form-control"
                                                    className={classnames('form-control  invoice-edit-input due-date-picker', {
                                                        'is-invalid': errors.costo && true
                                                    })}
                                                    // readOnly={opcion === 2}
                                                    disabled={true}
                                                    // invalid={errors.costo && true}
                                                    {...field}
                                                />
                                            )
                                            }
                                            rules={
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido'
                                                    }
                                                }
                                            }
                                        />
                                        <FormFeedback>
                                            {!!errors.costo && errors.costo.message}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                {inputRenglon?.generaInsumo === 'No Genera insumo' &&
                                    <Col>
                                        <FormGroup>
                                            <Label for="unidadMedida" className="d-flex justify-content-between">
                                                <span>* Unidad de Medida</span>
                                            </Label>
                                            <Controller
                                                name="unidadMedida"
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            {...field}
                                                            isClearable
                                                            onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                            onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                            defaultValue={null}
                                                            options={labelUnidadMedida}
                                                            // theme={selectThemeColors}
                                                            // isDisabled={!allMedidaInsumo}
                                                            className='selector-react1'
                                                            classNamePrefix='select'
                                                            placeholder={'Seleccione una opción'}
                                                            noOptionsMessage={() => 'sin resultados'}
                                                            styles={(!!errors.unidadMedida) ? customStyles : {}}
                                                        />
                                                    )
                                                }
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <small className="text-danger">
                                                {!!errors.unidadMedida && errors.unidadMedida.message}
                                            </small>
                                        </FormGroup>
                                    </Col>
                                }
                                <Col sm={2} lg={2} className="text-center">
                                    <Button type="submit" color={!btnDisable ? 'success' : "info"} disabled={alertaSaldo} size="sm" id="AgAct2" className="btn-icon mt-2 me-1">
                                        <Icon.Save size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='AgAct2'>
                                        {'Agregar'}
                                    </UncontrolledTooltip>
                                    <Button color="danger" size="sm" type="button" className="btn-icon text-center mt-2 me-1" id="Cancelar" onClick={() => [props.setAgregarRenglon(0),
                                    clearData(),
                                    setCloseSeleccionableInsumos(false)]}>
                                        <Icon.Slash size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='Cancelar'>
                                        Cancelar
                                    </UncontrolledTooltip>
                                </Col>
                                {alertaSaldo === true && <Row>
                                    <FormGroup>
                                        <small className="text-danger fw-bolder">
                                            {'* El Costo Total no puede ser mayor al saldo disponible del Renglón.'}
                                        </small>
                                    </FormGroup>
                                </Row>}
                            </Row>
                            : null}

                    </Form>
                    : null}
            </div>
            {
                props.agregarRenglon && props.agregarRenglon === 2 ?
                    <Form
                        onSubmit={handleSubmit(onSubmit2)}
                    >
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for='costoPedido' className="">* Costo Inicial</Label>
                                    <Controller
                                        // defaultValue=''
                                        control={control}
                                        id='costoPedido'
                                        name='costoPedido'
                                        render={({ field }) => (
                                            <CurrencyInput placeholder="Q. 0.00" type="text"
                                                // className="form-control"
                                                className={classnames('form-control  invoice-edit-input due-date-picker', {
                                                    'is-invalid': errors.costoPedido && true
                                                })}
                                                // readOnly={opcion === 2}
                                                disabled={true}
                                                // invalid={errors.costoPedido && true}
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.costoPedido && errors.costoPedido.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for='costoReal' className="">* Costo Real</Label>
                                    <Controller
                                        // defaultValue=''
                                        control={control}
                                        id='costoReal'
                                        name='costoReal'
                                        render={({ field }) => (
                                            <CurrencyInput placeholder="Q. 0.00" type="text"
                                                // className="form-control"
                                                className={classnames('form-control  invoice-edit-input due-date-picker', {
                                                    'is-invalid': errors.costoReal && true
                                                })}
                                                // readOnly={opcion === 2}
                                                // disabled={opcion === 3 || opcion === 4}
                                                // invalid={errors.costoReal && true}
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.costoReal && errors.costoReal.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="proveedor" className="d-flex justify-content-between">
                                        <span>* Proveedor</span>
                                    </Label>
                                    <Controller
                                        name="proveedor"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                    // defaultValue={null}
                                                    options={labelProveedores}
                                                    // theme={selectThemeColors}
                                                    // isDisabled={!allMedidaInsumo}
                                                    className='selector-react1'
                                                    classNamePrefix='select'
                                                    placeholder={'Seleccione una opción'}
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={(!!errors.proveedor) ? customStyles : {}}
                                                />
                                            )
                                        }
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <small className="text-danger">
                                        {!!errors.proveedor && errors.proveedor.message}
                                    </small>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for='serie' className="">* Serie Factura</Label>
                                    <Controller
                                        // defaultValue=''
                                        control={control}
                                        id='serie'
                                        name='serie'
                                        render={({ field }) => (
                                            <Input
                                                type='text'
                                                invalid={errors.serie && true}
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.serie && errors.serie.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for='noFactura' className="">* No. Factura</Label>
                                    <Controller
                                        // defaultValue=''
                                        control={control}
                                        id='noFactura'
                                        name='noFactura'
                                        render={({ field }) => (
                                            <Input
                                                type='text'
                                                invalid={errors.noFactura && true}
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.noFactura && errors.noFactura.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col sm={2} lg={2} className="text-center">
                                <Button type="submit" color={!btnDisable ? 'success' : "info"} size="sm" disabled={alertaSaldoReal} id="AgAct3" className="btn-icon mt-2 me-1">
                                    <Icon.Save size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='AgAct3'>
                                    {'Guardar Datos Liquidación'}
                                </UncontrolledTooltip>
                                <Button color="danger" size="sm" type="button" className="btn-icon text-center mt-2 me-1" id="Cancelar" onClick={() => [props.setAgregarRenglon(0),
                                clearData(),
                                setCloseSeleccionableInsumos(false)]}>
                                    <Icon.Slash size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='Cancelar'>
                                    Cancelar
                                </UncontrolledTooltip>
                            </Col>
                        </Row>
                        {alertaSaldoReal === true && <Row>
                            <FormGroup>
                                <small className="text-danger fw-bolder">
                                    {'* El Costo Real no puede ser mayor al Costo Total del insumo'}
                                </small>
                            </FormGroup>
                        </Row>}
                    </Form>

                    : null
            }
            <>
                {(Opcion2 === 25 || Opcion2 === 200) &&
                    < ListLiqui agregarRenglon={props?.setAgregarRenglon} agregar={props?.agregarRenglon} />
                }
            </>
            <>
                {(Opcion2 !== 25 && Opcion2 !== 200) && oneData2?.ajuste !== 1 &&
                    <List agregarRenglon={props?.setAgregarRenglon} agregar={props?.agregarRenglon} />
                }
            </>
            <>
                {oneData2?.ajuste === 1 &&
                    <ListAjuste agregarRenglon={props?.setAgregarRenglon} agregar={props?.agregarRenglon} />
                }
            </>
        </Fragment >
    )
}

export default DetalleAcciones

