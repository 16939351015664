import React, { Fragment, useState, useContext } from "react"
import DataTable from "react-data-table-component"
import {
  Row,
  Col,
  FormGroup,
  Input,
  UncontrolledButtonDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap"
import * as Icon from "react-feather"
import { CustomText, EstadoLista, EstadoText } from "../../../CustomList/index"
import { ContentContext } from "./context"
import { ContentContext as ContentContextTheme } from "../../../components/CommonForBoth/TopbarDropdown/context"
import { useHistory } from "react-router-dom"
import Moment from "react-moment"
import "moment/locale/es-mx"

const List = () => {
  const { allData, One, Actions, roles } = useContext(ContentContext)
  const { theme } = useContext(ContentContextTheme)
  const [searchValue, setSearchValue] = useState(""),
    history = useHistory(),
    [filteredData, setFilteredData] = useState([]),
    handleFilter = e => {
      const value = e.target.value
      let updatedData = []
      setSearchValue(value)

      if (value.length) {
        updatedData =
          DocumentosXUnidad &&
          DocumentosXUnidad.value !== 2009 &&
          DocumentosXUnidad.filter(item => {
            const startsWith = item.numeroReferencia
              .toLowerCase()
              .startsWith(value.toLowerCase())

            const includes = item.numeroReferencia
              .toLowerCase()
              .startsWith(value.toLowerCase())

            if (startsWith) {
              return startsWith
            } else if (!startsWith && includes) {
              return includes
            } else return null
          })
        setFilteredData(updatedData)
        setSearchValue(value)
      }
    },
    Columns = [
      {
        name: "Numero de Referencia",
        column: "numeroReferencia",
        sortable: true,
        center: false,
        cell: row => CustomText(row["numeroReferencia"]),
        grow: 3,
      },
      {
        name: "Correlativo",
        column: "correlativo",
        sortable: true,
        center: false,
        cell: row => CustomText(row["correlativo"]),
        grow: 3,
      },
      {
        name: "Firmado Por",
        column: "firmaDocumento",
        sortable: true,
        center: false,
        cell: row => CustomText(row["firmaDocumento"]),
        grow: 3,
      },
      {
        name: "Creado Por",
        column: "creadoPor",
        sortable: true,
        center: false,
        cell: row => CustomText(row["creadoPor"]),
        grow: 4,
      },
      {
        name: "Creado",
        column: "Creado",
        sortable: true,
        center: true,
        cell: row => (
          <Moment locale="es" format="lll" fromNow>
            {row.creado}
          </Moment>
        ),
        grow: 4,
      },
      {
        name: "Estado",
        column: "estado",
        sortable: true,
        center: true,
        width: "200px",
        cell: row => <EstadoText row={row} />,
        grow: 3,
      },
      {
        name: "Acciones",
        column: "id",
        sortable: true,
        center: true,
        // width: '150px',
        cell: row => (
          <UncontrolledButtonDropdown style={{ margin: 0 }}>
            <DropdownToggle style={{ margin: 0 }} className="pr-1" tag="span">
              <Icon.MoreVertical size={15} />
            </DropdownToggle>
            <DropdownMenu className="!m-0" style={{ margin: 0 }}>
              {/*   <DropdownItem className="w-100" onClick={() => history.push(`/documentos/detalle/${row.correlativo}`)}>
                <Icon.FileText className="me-2" size={15} />
                <span className='align-middle ml-50'>Ver Detalle</span>
              </DropdownItem> */}

              <DropdownItem className="w-100" onClick={() => One(row, 2)}>
                <Icon.Eye className="me-2" size={15} />
                <span className="align-middle ml-50">Visualizar</span>
              </DropdownItem>

              {(row.idEstado === 1 || row.idEstado === 2) && (
                <DropdownItem className="w-100" onClick={() => One(row, 5)}>
                  <Icon.Upload className="me-2" size={15} />
                  <span className="align-middle ml-50">
                    {row.idEstado === 1 ? "Adjuntar" : "Corregir"} Documento
                  </span>
                </DropdownItem>
              )}

              {row.idEstado === 2 && (
                <DropdownItem className="w-100" onClick={() => One(row, 7)}>
                  <Icon.Send className="me-2" size={15} />
                  <span className="align-middle ml-50">Enviar a Gerencia</span>
                </DropdownItem>
              )}

              {row.idEstado === 3 && (
                <DropdownItem className="w-100" onClick={() => One(row, 9)}>
                  <Icon.CheckCircle className="me-2" size={15} />
                  <span className="align-middle ml-50">Marcar de Recibido</span>
                </DropdownItem>
              )}

              {/* <DropdownItem className="w-100" onClick={() => Actions(row)}>
                {row.idEstado < 2 ? <Icon.Trash className="me-2" size={15} /> : <Icon.Check className="me-2" size={15} />}
                <span className='align-middle ml-50'>{row.idEstado === 1 ? 'Desactivar' : 'Activar'}</span>
              </DropdownItem> */}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        ),
      },
    ],
    DocumentosXUnidad =
      allData && allData?.value !== 2009
        ? allData?.filter(
            item =>
              (item?.idEstado === 1 &&
                roles?.includes("8A3D8")) /** Documentos Creados */ ||
              (item?.idEstado === 2 &&
                roles?.includes("8A3D8")) /** Documentos con Adjunto */ ||
              (item?.idEstado === 3 &&
                roles?.includes(
                  "6A3FD"
                )) /** Documentos Enviados a Gerencia */ ||
              (item?.idEstado === 4 &&
                roles?.includes("6A3FD")) /** Documentos Enviados a Gerencia */
          )
        : []

  return (
    <Fragment>
      <Row className="justify-content-end mx-0">
        <Col></Col>
        <Col className="justify-content-end mt-1" md="4" sm="12">
          <FormGroup>
            <Input
              className="dataTable-filter mb-50"
              type="text"
              bsSize="md"
              placeholder={`Buscar`}
              id="search-input"
              value={searchValue}
              onChange={handleFilter}
            />
          </FormGroup>
        </Col>
      </Row>
      <div className="card">
        <DataTable
          // dense
          striped
          noHeader
          highlightOnHover
          pagination
          theme={theme}
          data={
            searchValue.length
              ? filteredData
              : DocumentosXUnidad && DocumentosXUnidad.value !== 2009
              ? DocumentosXUnidad
              : []
          }
          columns={Columns}
          className="table-responsive mt-4"
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          paginationComponentOptions={{
            rowsPerPageText: "",
            rangeSeparatorText: "",
          }}
          noDataComponent="Sin Registros"
        />
      </div>
    </Fragment>
  )
}

export default List
