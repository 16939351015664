import React, { Fragment, useContext, useEffect } from 'react'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback } from 'reactstrap'
import { ContentContext } from './context'
import * as Icon from 'react-feather'
import { useForm, Controller } from "react-hook-form"
import Select from 'react-select'
import { customStyles } from '../../../../Globales/index'
import { useRouteCode } from 'hooks/useRouteCode'
import List from './listDetalle'

const Formulario = () => {
    const code = useRouteCode()?.codigo;
    const { toggleModal, modal, StoreUpdate, opcion, oneData, labelObjetivosEstrategicos } = useContext(ContentContext),
        { handleSubmit, formState: { errors }, setValue, control, reset } = useForm(),
        onSubmit = (data) => {
            const json = {
                id: (oneData) ? oneData.id : null,
                nombre: data.nombre,
                objetivoEstrategico: data.objetivoEstrategico.value,
                code: code
            }
            StoreUpdate(json)
        },
        setData = async () => {
            await setValue('nombre', oneData.nombre)
            await setValue('objetivoEstrategico', { label: oneData.objetivoEstrategico, value: oneData.idObjetivoEstrategico })
        },
        clearData = async () => {
            reset({ nombre: '', objetivoEstrategico: '' })
        }

    useEffect(
        () => {
            async function fetchMyAPI() {
                if (await opcion > 1 && oneData) { await setData() }
                if (await opcion === 1) { clearData() }
            }

            fetchMyAPI()

        }, [opcion, oneData]
    )

    return (
        <Fragment>
            {/* <Button onClick={() => toggleModal(1)} id="crear" color="primary" size="sm">
                <Icon.Plus size={16} />
            </Button>
            <UncontrolledTooltip placement='left' target='crear' placementPrefix="bs-tooltip">
                Crear
            </UncontrolledTooltip> */}

            <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered modal-lg'
                style={{ maxWidth: '2000px', width: '90%', margin: '10px auto' }}
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>Detalle Resumen</ModalHeader>
                    <ModalBody>
                        <List />
                    </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default Formulario