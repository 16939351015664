import { Alert, Form, Input, FormFeedback, Label } from "reactstrap";
import { toAbsoluteUrl } from '../../helpers/AssetHelper'
import { useFormik } from "formik";
import { userService } from '../../services/UserServices'
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import * as Yup from "yup";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

const Login = props => {


  const dispatch = useDispatch();
  const [validInput, setValidInput] = useState(false),
    [messageError, setMessageError] = useState(''),
    [loading, setLoading] = useState(false),
    [width, setWidth] = useState(window.innerWidth);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // email: "admin@themesbrand.com" || '',
      // password: "123456" || '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Por favor Ingresa tu Usuario"),
      password: Yup.string().required("Por favor Ingresa tu Contraseña"),
    }),
    onSubmit: (data) => {
      setMessageError('')
      setValidInput(true)
      setLoading(true)
      userService.login(data).then(
        () => {
          window.location.href = "/"
        },
        error => {
          setValidInput(false)
          setLoading(false)
          if (String(error) === 'TypeError: Failed to fetch') {
            setMessageError('El navegador no pudo establecer una conexión al servidor')
          } else {
            setMessageError(error)
          }
        }
      )
    }
  });

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width]);

  return (
    <React.Fragment>
      <div className="p-0 container-fluid">
        <div className="g-0 row">
          <div className="col-xxl-8 col-xl-8 col-lg-12 col-sm-12">
            <div className="auth-full-bg pt-lg-5 p-4">
              <div className="w-100">
                <div className="bg-overlay" />
                <div className="d-flex h-100 flex-column">
                  <div style={{ marginBlock: "auto" }}>
                    <div className="row justify-content-center">
                      <div className="col-lg-8">
                        <div className="text-center">
                          <span >
                            {width <= 1199
                              ?
                              <img
                                src={"/media/logos/SIPA-completo-ligth.png"}
                                alt=""
                                // className="rounded-circle"
                                height={"80"}
                              />
                              :
                              <img
                                src={"/media/logos/Logo-COGblanco.png"}
                                alt=""
                                // className="rounded-circle"
                                height={"325"} 
                              />}
                          </span>
                          <div dir="ltr">
                            <div className="carousel-root slider_css">
                              <div
                                className="carousel carousel-slider"
                                style={{ width: "100%" }}
                              >
                                <div className="slider-wrapper axis-horizontal">
                                </div>
                                <button
                                  type="button"
                                  aria-label="next slide / item"
                                  className="control-arrow control-next"
                                />
                                <p className="carousel-status">1 of 2</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl col-xl col-lg col-sm">
            <div className="auth-full-page-content p-md-5 p-4">
              <div className="w-100">
                <div className="d-flex flex-column h-100">
                  <div className="text-center">
                    <span>
                      {width <= 1199 ?
                        <div className="py-5"></div>
                        : <img
                          src={toAbsoluteUrl("/media/logos/SIPA-completo.png")}
                          alt=""
                          // className="rounded-circle"
                          height="100%"
                          width="55%"
                        />}
                    </span>
                  </div>
                  <div className="my-auto">
                    <div>
                      <h5 className="text-primary">¡Hola!</h5>
                      <p>Inicia Sesión para acceder.</p>
                    </div>
                    <div className="mt-4">
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">Usuario</Label>
                          <Input
                            name="username"
                            className="form-control"
                            placeholder="Ingrese su usuario"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            // value={validation.values.username || ""}
                            invalid={
                              validation.touched.username && validation.errors.username ? true : false
                            }
                          />
                          {validation.touched.username && validation.errors.username ? (
                            <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Contraseña</Label>
                          <Input
                            name="password"
                            // value={validation.values.password || ""}
                            type="password"
                            placeholder="Ingrese su contraseña"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div>

                        {/* <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customControlInline"
                          >
                            Recuerdame
                          </label>
                        </div> */}
                        <div className="mt-3 d-grid">
                          <button className="btn btn-primary btn-block" type="submit">
                            {" "}
                            Iniciar Sesión{" "}
                          </button>
                        </div>
                        {messageError &&
                          <Alert className='mt-2 fw-bolder' color="danger">
                            <div className='alert-body'>
                              {messageError}
                            </div>
                          </Alert>
                        }
                      </Form>
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Olvidaste tu Contraseña?
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 mt-md-5 text-center">
                    <p className="mb-0">
                      © {new Date().getFullYear()} SIPA {"|"} Comité Olímpico Guatemalteco
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
