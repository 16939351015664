import React, { createContext, useState, useContext } from 'react'
import { OptionsToast } from 'Globales/index'
import { toast } from 'react-toastify'
import { PostRoute } from 'services/Private'
// import { LoadingContext } from '../../../../utility/Loading/provider'
import { useSWRConfig } from 'swr'
import { useRequest } from 'hooks/useRequestSWR'
import { ContentContext as ContentMeta } from '../context'
export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {

    const
        { idSolicitud, code, allAdjuntos } = useContext(ContentMeta),
        { mutate } = useSWRConfig(),
        [show, setShow] = useState(false),
        [oneData, setOneData] = useState([]),
        [opcion, setOpcion] = useState(0),
        nameController = 'InsumosAdjuntos',
        { data: allData } = useRequest(`${nameController}/all`, 'POST', { solicitudInsumo: idSolicitud }),
        toggleModal = data => {
            setOpcion(data)
            if (data === 1) { setOneData([]) }
            setShow(!show)
        },
        One = async (data, opcion) => {
            const response = await PostRoute(`${nameController}/one`, { id: data.id })
            setOneData((response.length) ? response[0] : [])
            toggleModal(opcion)
        },
        
        Actions = async (data) => {
            const response = await PostRoute(`${nameController}/status`, {
                id: data.id,
                estado: data.idEstado === 1 ? 0 : 1,
            })
            toast.success(response.message, OptionsToast)
            mutate([`${nameController}/all`, { solicitudInsumo: idSolicitud }])
        },
        value = {
            Actions,
            One,
            setOpcion,
            toggleModal,
            allData,
            allAdjuntos,
            oneData,
            opcion,
            show
        }

        return (
        <ContentContext.Provider value={value} >
            {children}
        </ContentContext.Provider>
    )
}
