import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Row, Col, Form, FormGroup, Label, FormFeedback, Input, Button, UncontrolledTooltip } from 'reactstrap'
import { useForm, Controller } from 'react-hook-form'
import { ContentContext } from './context'
// import { selectThemeColors } from '@utils'
import * as Icon from 'react-feather'
import Select from 'react-select'
import List from './list'
import { customStyles } from '../../../Globales/index'
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput'
import classnames from 'classnames'


const DetalleAcciones = (props) => {

    const { handleSubmit, formState: { errors }, control, setValue, reset } = useForm(),
        [btnDisable, setBtnDisable] = useState(false),
        { StoreUpdate, One, oneData, labelRenglon, labelFinanciamiento, setAccion, allData } = useContext(ContentContext),
        clearData = async () => {
            reset({ monto: '', renglon: '', financiamiento: '' })
        },
        onSubmit = (data) => {
            if (allData && allData?.value !== 2009 && allData?.some(item => item?.idRenglon === data?.renglon?.value) === true) {

                StoreUpdate(null, 0)
            } else {
                const json = {
                    id: (oneData?.length !== 0) ? oneData?.id : null,
                    accion: props?.oneData && props?.oneData?.id,
                    renglon: data?.renglon?.value,
                    monto: String(data?.monto)?.includes('Q') ? data?.monto?.replace('Q', '')?.replace(',', '')?.replace(',', '') : data?.monto,
                    financiamiento: data?.financiamiento?.value
                }
                StoreUpdate(json)
                setBtnDisable(false)
                clearData()
            }
        },

        // onSubmitAsignarPersonal = (data: any) => {
        //     if (allDataPersonas.some(item => item.idPersona === data.persona.value) === true) {
        //       StorePersonal(null, 0)
        //       reset()
        //     } else {
        //       let jsData = {
        //         idPersona: data.persona.value,
        //         tipoPersonaNombramiento: data.tipoPersonaNombramiento.value,
        //         idTipoFiscalizacion: oneData?.idTipoFiscalizacion,
        //         idCooperativa: idCooperativa,
        //         idPlanAnual: props?.idStep1
        //       }
        //       StorePersonal(jsData, 1)
        //       setValue('persona', "")
        //       setValue('tipoPersonaNombramiento', "")
        //     }

        //   },
        setData = async () => {
            await setValue('monto', oneData.costoOne)
            await setValue('financiamiento', { label: oneData.financiamiento, value: oneData.idFinanciamiento })
            await setValue('renglon', { label: oneData.renglon, value: oneData.idRenglon })
        }

    useEffect(
        () => {
            async function fetchMyAPI() {
                if (await oneData.length !== 0) {
                    setData()
                }
            }
            fetchMyAPI()
        }, [oneData]
    )

    useEffect(() => {
        const functionFetch = async () => {
            if (props.oneData && props.oneData.id) {
                await setAccion(props.oneData.id)
            }
        }
        functionFetch()
    }, [props.oneData])


    return (
        <Fragment>
            <Form
                onSubmit={handleSubmit(onSubmit)}
            >
                {props.agregarRenglon && props.agregarRenglon === 1 ?
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="renglon" className="d-flex justify-content-between">
                                    <span>* Renglón</span>
                                </Label>
                                <Controller
                                    name="renglon"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <Select
                                                {...field}
                                                isClearable
                                                onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                defaultValue={null}
                                                options={labelRenglon}
                                                // theme={selectThemeColors}
                                                // isDisabled={!allMedidaInsumo}
                                                className='selector-react4'
                                                classNamePrefix='select'
                                                placeholder={'Seleccione una opción'}
                                                noOptionsMessage={() => 'sin resultados'}
                                                styles={(!!errors.renglon) ? customStyles : {}}
                                            />
                                        )
                                    }
                                    }
                                    rules={
                                        {
                                            required: {
                                                value: true,
                                                message: 'Este campo es requerido'
                                            }
                                        }
                                    }
                                />
                                <small className="text-danger">
                                    {!!errors.renglon && errors.renglon.message}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for='monto' className="">* Monto</Label>
                                <Controller
                                    defaultValue=''
                                    control={control}
                                    id='monto'
                                    name='monto'
                                    render={({ field }) => (
                                        <CurrencyInput placeholder="Q. 0.00" type="text"
                                            // className="form-control"
                                            className={classnames('form-control  invoice-edit-input due-date-picker', {
                                                'is-invalid': errors.monto && true
                                            })}
                                            // readOnly={opcion === 2}
                                            // disabled={opcion === 3 || opcion === 4}
                                            // invalid={errors.monto && true}
                                            {...field}
                                        />
                                    )
                                    }
                                    rules={
                                        {
                                            required: {
                                                value: true,
                                                message: 'Este campo es requerido'
                                            }
                                        }
                                    }
                                />
                                <FormFeedback>
                                    {!!errors.monto && errors.monto.message}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="financiamiento" className="d-flex justify-content-between">
                                    <span>* Fuente</span>
                                </Label>
                                <Controller
                                    name="financiamiento"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <Select
                                                {...field}
                                                isClearable
                                                onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                defaultValue={null}
                                                options={labelFinanciamiento}
                                                // theme={selectThemeColors}
                                                // isDisabled={!allMedidaInsumo}
                                                className='selector-react4'
                                                classNamePrefix='select'
                                                placeholder={'Seleccione una opción'}
                                                noOptionsMessage={() => 'sin resultados'}
                                                styles={(!!errors.financiamiento) ? customStyles : {}}
                                            />
                                        )
                                    }
                                    }
                                    rules={
                                        {
                                            required: {
                                                value: true,
                                                message: 'Este campo es requerido'
                                            }
                                        }
                                    }
                                />
                                <small className="text-danger">
                                    {!!errors.financiamiento && errors.financiamiento.message}
                                </small>
                            </FormGroup>
                        </Col>
                        <Col sm={2} lg={2} className="text-center">
                            <Button type="submit" color={!btnDisable ? 'success' : "info"} size="sm" id="AgAct2" className="btn-icon mt-2 me-1">
                                <Icon.Save size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='AgAct2'>
                                {'Agregar'}
                            </UncontrolledTooltip>
                            <Button color="danger" size="sm" type="button" className="btn-icon text-center mt-2 me-1" id="Cancelar" onClick={() => [props.setAgregarRenglon(0), clearData()]}>
                                <Icon.Slash size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='Cancelar'>
                                Cancelar
                            </UncontrolledTooltip>
                        </Col>
                    </Row> : null}
            </Form>
            <List agregarRenglon={props.setAgregarRenglon} total={props.total} />
        </Fragment>
    )
}

export default DetalleAcciones