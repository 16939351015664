import React, { Fragment, useState, useContext, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { Row, Col, FormGroup, Input, UncontrolledButtonDropdown, UncontrolledTooltip, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap'
import * as Icon from 'react-feather'
import { CustomText, CustomTextHora, EstadoText } from '../../../CustomList/index'
import { ContentContext } from './context'
import { ContentContext as ContentContextTheme } from '../../../components/CommonForBoth/TopbarDropdown/context'
import Select from 'react-select'
import { customStyles } from '../../../Globales/index'
import { useForm } from "react-hook-form"
// import { ExportPDF } from './export/ExportPDF'

const List = () => {
  const { allData, One, Actions, FechaInicio, FechaFin, hoy, Controller, control, nameFileExport } = useContext(ContentContext)
  const { theme } = useContext(ContentContextTheme)
  const [searchValue, setSearchValue] = useState(''),
    [filteredData, setFilteredData] = useState([]),
    handleFilter = e => {
      const value = e.target.value
      let updatedData = []
      setSearchValue(value)

      if (value.length) {
        updatedData = allData?.filter(item => {
          const startsWith =
            item.nombre.toLowerCase().startsWith(value.toLowerCase()) ||
            item.ubicacion.toLowerCase().startsWith(value.toLowerCase()) ||
            item.visitaA.toLowerCase().startsWith(value.toLowerCase()) ||
            item.horaEntrada.toLowerCase().startsWith(value.toLowerCase()) ||
            item.estado.toLowerCase().startsWith(value.toLowerCase()) ||
            item.ubicacionVisita.toLowerCase().startsWith(value.toLowerCase())

          const includes =
            item.nombre.toLowerCase().startsWith(value.toLowerCase()) ||
            item.ubicacion.toLowerCase().startsWith(value.toLowerCase()) ||
            item.visitaA.toLowerCase().startsWith(value.toLowerCase()) ||
            item.horaEntrada.toLowerCase().startsWith(value.toLowerCase()) ||
            item.estado.toLowerCase().startsWith(value.toLowerCase()) ||
            item.ubicacionVisita.toLowerCase().startsWith(value.toLowerCase())

          if (startsWith) {
            return startsWith
          } else if (!startsWith && includes) {
            return includes
          } else return null
        })
        setFilteredData(updatedData)
        setSearchValue(value)
      }
    },
    Columns = [
      {
        name: 'Foto',
        column: 'foto',
        sortable: true,
        center: true,
        cell: row => (
          row['foto'] === "" ? "Sin foto" :
            <img
              className="rounded-circle avatar-md"
              src={`data:image/${row['extensionFoto']};base64,${row['foto']}`}
              data-holder-rendered="true"
            />

        )
      },
      {
        name: 'Visitante',
        column: 'nombre',
        sortable: true,
        width: '200px',
        center: false,
        cell: row => CustomText(row['nombre'])
      },
      {
        name: 'Unidad que visita',
        column: 'ubicacion',
        sortable: true,
        center: false,
        cell: row => CustomText(row['ubicacion'])
      },
      {
        name: 'Persona a quien visita',
        column: 'visitaA',
        sortable: true,
        center: false,
        cell: row => CustomText(row['visitaA'])
      },
      {
        name: 'De donde visita',
        column: 'ubicacionVisita',
        sortable: true,
        center: false,
        cell: row => CustomText(row['ubicacionVisita'])
      },
      {
        name: 'H. Entrada',
        column: 'horaEntrada',
        sortable: true,
        width: '160px',
        center: false,
        cell: row => CustomTextHora(row['horaEntrada'])
      },
      {
        name: 'H. Salida Rec',
        column: 'horaSalida',
        sortable: true,
        width: '160px',
        center: false,
        cell: row => CustomTextHora(row['horaSalida'])
      },
      {
        name: 'H. Salida Garita',
        column: 'horaSalidaGarita',
        sortable: true,
        width: '160px',
        center: false,
        cell: row => CustomTextHora(row['horaSalidaGarita'])
      },
      {
        name: 'Estado',
        column: 'estado',
        sortable: true,
        center: true,
        width: '200px',
        cell: row => <EstadoText row={row} />
      },
      {
        name: 'Acciones',
        column: 'id',
        sortable: true,
        center: true,
        cell: row => (
          <UncontrolledButtonDropdown style={{ margin: 0 }} >

            <DropdownToggle style={{ margin: 0 }} className='pr-1' tag='span'>
              <Icon.MoreVertical size={15} />
            </DropdownToggle>
            <DropdownMenu className="!m-0" style={{ margin: 0 }} >

              <DropdownItem className="w-100" onClick={() => One(row, 2)}>
                <Icon.Eye className="me-2" size={15} />
                <span className='align-middle ml-50'>Visualizar</span>
              </DropdownItem>

              {
                // row.idEstado === 1 &&
                // <DropdownItem className="w-100" onClick={() => One(row, 3)}>
                //   <Icon.Edit className="me-2" size={15} />
                //   <span className='align-middle ml-50'>Actualizar</span>
                // </DropdownItem>
              }

              {/* <DropdownItem className="w-100" onClick={() => Actions(row)}>
                {row.idEstado === 1 && <Icon.LogOut className="me-2" size={15} /> }
                <span className='align-middle ml-50'>{row.idEstado === 1 && 'Marcar como retirado'}</span>
                {/* <span className='align-middle ml-50'>{row.idEstado === 1 ? 'Marcar como retirado' : 'Activar'}</span> */}
              {/*</DropdownItem> */}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        )
      }
    ]


  return (
    <Fragment>
      <Row className='justify-content-end mx-0'>
        {/* <Col>
          <ExportPDF
            nameFile={nameFileExport} data={searchValue.length ? filteredData : allData}
          />
        </Col> */}
        <Col className='justify-content-end mt-1' md='2' sm='12'>
          {<Controller
            name='FechaInicial'
            control={control}
            render={({ field }) => {
              return (
                <FormGroup className="mb-3">

                  <div className="col-12">
                    <input {...field} max={FechaFin} className="form-control f-i" placeholder={"Fecha Inicial"} type="date" name='FechaInicial' value={FechaInicio ?? '2020-01-01'} id="FechaInicial" />
                  </div>

                  <UncontrolledTooltip placement='left' target='FechaInicial' placementPrefix="bs-tooltip">
                    Fecha Inicial
                  </UncontrolledTooltip>

                </FormGroup>
              )
            }
            }
          />
          }
        </Col>
        <Col className='justify-content-end mt-1' md='2' sm='12'>
          {<Controller
            name='FechaFinal'
            control={control}
            onHide={() => { }}
            render={({ field }) => {
              return (
                <FormGroup className="mb-3">

                  <div className="col-12">
                    <input {...field} className="form-control f-f" type="date" name='FechaFinal' value={FechaFin ?? hoy?.toLocaleDateString('en-CA')?.split('T')[0]} id="FechaFinal" />
                  </div>

                  <UncontrolledTooltip placement='left' target='FechaFinal' placementPrefix="bs-tooltip">
                    Fecha Final
                  </UncontrolledTooltip>

                </FormGroup>
              )
            }
            }
          />
          }
        </Col>
        <Col className='justify-content-end mt-1' md='4' sm='12'>
          <FormGroup>
            <Input
              className='dataTable-filter mb-50'
              type='text'
              bsSize='md'
              placeholder={`Buscar`}
              id='search-input'
              value={searchValue}
              onChange={handleFilter}
            />
          </FormGroup>
        </Col>
      </Row>
      <div className='card'>
        <DataTable
          // dense
          striped
          noHeader
          highlightOnHover
          pagination
          theme={theme}
          data={searchValue?.length ? filteredData : allData?.value === 2009 ? [] : allData}
          columns={Columns}
          className='table-responsive mt-4'
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          paginationComponentOptions={
            {
              rowsPerPageText: '',
              rangeSeparatorText: ''
            }
          }
          noDataComponent='Sin Registros'
        /></div>
    </Fragment>
  )
}

export default List