import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter, useLocation, Link, useParams } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = props => {
  const { codigo } = useParams();
  const actualRoute = useLocation();
  const [roles, setRoles] = useState([]);
  const [dataL, setDataL] = useState([]);
  const ref = useRef();
  const Blowfish = require('javascript-blowfish');
  const bf = new Blowfish(process.env.REACT_APP_CONFIG_KEY);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const Decript = bf.decrypt(bf.base64Decode(localStorage.getItem('authUser')));
      const data = JSON.parse(Decript.replace(/\0/g, ''));
      // const obj = JSON.parse(localStorage.getItem("authUser"));
      setRoles(data.roles);
      setDataL(data);
    }
  }, [props.success]);
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/#" >
                <i className="bx bx-home-circle"></i>
                {/* <span className="badge rounded-pill bg-info float-end">
                  04
                </span> */}
                <span>{props.t("Dashboards")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/dashboard">{props.t("Principal")}</Link>
                </li>
              </ul>
            </li>
            <li className="menu-title">Módulos</li>
            <li className={roles.includes('E71A6') ? ` ` : `d-none`}>
              <Link to="/#" >
                <i className="bx bx-buildings"></i>
                {/* <span className="badge rounded-pill bg-success float-end">
                  {props.t("New")}
                </span> */}
                <span>{props.t("Gestion Administrativa")}</span>
              </Link>
              <ul className="sub-menu">
                <li>

                  <Link to={`/gestion-administrativa/unidad/${dataL.codigoUnidad}`}>{props.t("Unidad")}</Link>
                </li>
                {/* <li className={roles.includes('0032F') ? ` ` : `d-none`}>
                  <Link to="/gestion-administrativa/saldos-unidades">{props.t("Saldos Unidades")}</Link>
                </li> */}
              </ul>
            </li>
            <li className={roles.includes('F43B0') ? ` ` : `d-none`}>
              <Link to="/#" >
                <i className="bx bx-user-circle"></i>
                {/* <span className="badge rounded-pill bg-success float-end">
                  {props.t("New")}
                </span> */}
                <span>{props.t("IT")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/it/smtp">{props.t("Servidor SMTP")}</Link>
                </li>
                <li>
                  <Link to="/it/rol">{props.t("Roles")}</Link>
                </li>
                <li>
                  <Link to="/it/tipo-persona">{props.t("Tipo Persona")}</Link>
                </li>
                <li>
                  <Link to="/it/persona">{props.t("Persona")}</Link>
                </li>
                <li>
                  <Link to="/it/usuario">{props.t("Usuario")}</Link>
                </li>
                <li>
                  <Link to="/it/bitacora-expedientes">{props.t("Bitácora Expedientes")}</Link>
                </li>
              </ul>
            </li>
            {/* : null} */}
            {/* ADMIN FINANCIERO */}
            <li className={roles.includes('026AE') ? ` ` : `d-none`}>
              <Link to="/#" >
                <i className="bx bx-comment-x"></i>
                {/* <span className="badge rounded-pill bg-success float-end">
                  {props.t("New")}
                </span> */}
                <span>{props.t("Admin Financiero")}</span>
              </Link>
              <ul className="sub-menu">

                <li>
                  <Link to="/admin/financiero/actividades">{props.t("Actividades")}</Link>
                </li>
                <li>
                  <Link to="/admin/financiero/programas">{props.t("Programas")}</Link>
                </li>
                <li>
                  <Link to="/admin/financiero/programa-unidad">{props.t("Programas por Unidad")}</Link>
                </li>
              </ul>
            </li>
            {/* {roles.includes('AFCCA') ? */}
            <li className={roles.includes('AFCCA') ? ` ` : `d-none`}>
              <Link to="/#" >
                <i className="bx bx-comment-x"></i>
                {/* <span className="badge rounded-pill bg-success float-end">
                  {props.t("New")}
                </span> */}
                <span>{props.t("Admin")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/admin/grupos">{props.t("Grupos")}</Link>
                </li>
                <li>
                  <Link to="/admin/subgrupos">{props.t("Sub-Grupos")}</Link>
                </li>
                <li>
                  <Link to="/admin/renglones">{props.t("Renglones")}</Link>
                </li>
                <li>
                  <Link to="/admin/unidades">{props.t("Unidades")}</Link>
                </li>
                <li>
                  <Link to="/admin/puestos">{props.t("Puestos")}</Link>
                </li>
                <li>
                  <Link to="/admin/actividades">{props.t("Actividades")}</Link>
                </li>
                <li>
                  <Link to="/admin/programas">{props.t("Programas")}</Link>
                </li>
                <li className={roles.includes('6FEE5') ? ` ` : `d-none`}>
                  <Link to="/admin/tipo-visita">{props.t("Tipo Visita")}</Link>
                </li>
                <li className={roles.includes('6FEE5') ? ` ` : `d-none`}>
                  <Link to="/admin/ubicacion-visita">{props.t("De Donde Visita")}</Link>
                </li>
                <li className={roles.includes('6FEE5') ? ` ` : `d-none`}>
                  <Link to="/admin/tipo-origen">{props.t("Tipo de Origen")}</Link>
                </li>
                <li className={roles.includes('6FEE5') ? ` ` : `d-none`}>
                  <Link to="/admin/origen-externo">{props.t("Origen Externo")}</Link>
                </li>
              </ul>
            </li>
            {/* : null} */}
            {/* {roles.includes('B9E8D') ? */}
            <li className={roles.includes('74AFF') || roles.includes('30D7E') ? ` ` : `d-none`}>
              <Link to="/#" >
                <i className="bx bx-cart-alt"></i>
                {/* <span className="badge rounded-pill bg-success float-end">
                  {props.t("New")}
                </span> */}
                <span>{props.t("Centro de Costos")}</span>
              </Link>
              <ul className="sub-menu">
                <li className={roles.includes('74AFF') ? `sub-menu ` : `d-none`}>
                  <Link to="/insumos/gestion-insumos">{props.t("Gestión de Insumos")}</Link>
                </li>
                <li>
                  <Link to="/insumos/solicitud">{props.t("Solicitud de Insumos")}</Link>
                </li>
                {/* <li>
                  <Link to="/it/rol">{props.t("Roles")}</Link>
                </li>
                <li>
                  <Link to="/it/persona">{props.t("Persona")}</Link>
                </li>
                <li>
                  <Link to="/it/usuario">{props.t("Usuario")}</Link>
                </li> */}
              </ul>
            </li>
            {/* : null} */}
            {/* {roles.includes('31409') ? */}
            <li className={roles.includes('31409') ? ` ` : `d-none`}>
              <Link to="/#" >
                <i className="bx bx-money"></i>
                {/* <span className="badge rounded-pill bg-success float-end">
                  {props.t("New")}
                </span> */}
                <span>{props.t("POA")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/poa/tipo-beneficiario">{props.t("Tipo Beneficiario")}</Link>
                </li>
                <li>
                  <Link to="/poa/financiamiento">{props.t("Financiamiento")}</Link>
                </li>
                <li>
                  <Link to="/poa/dependencia">{props.t("Dependencia")}</Link>
                </li>
                {/* <li>
                  <Link to="/poa/presupuesto-dependencia">{props.t("Presupuesto Dependencia")}</Link>
                </li> */}
                <li>
                  <Link to="/poa/ejes-de-gobernanza">{props.t("Ejes Estratégicos")}</Link>
                </li>
                <li>
                  <Link to="/poa/plan-operativo-anual">{props.t("Gestión POA")}</Link>
                </li>
                {/* <li>
                  <Link to="/poa/objetivos-estrategicos">{props.t("Objetivos Estratégicos")}</Link>
                </li>
                <li>
                  <Link to="/poa/objetivos-operativos">{props.t("Objetivos Operativos")}</Link>
                </li>
                <li>
                  <Link to="/poa/objetivos-unidad">{props.t("Objetivos Unidad")}</Link>
                </li> */}
                <li>
                  <Link to="/poa/presupuesto-poa">{props.t("Asignar Techo Presupuestario")}</Link>
                  {actualRoute.pathname === `/poa/presupuesto-poa/${codigo}` ? <ul className="sub-menu">
                    <li>
                      <Link to={`/poa/presupuesto-poa/${codigo}`}>{props.t("Detalle")}</Link>
                    </li>
                  </ul> : null}
                </li>
                <li>
                  <Link to="/poa/programa-unidad">{props.t("Asignar Programa Por Unidad")}</Link>
                </li>
                {/* <li>
                  <Link to="/poa/acciones">{props.t("Acciones")}</Link>
                </li> */}

              </ul>
            </li>
            {/* : null} */}
            {/* {roles.includes('B9E8D') ? */}
            <li className={roles.includes('B9E8D') || roles.includes('F821C') ? ` ` : `d-none`}>
              <Link to="/#" >
                <i className="bx bx-package"></i>
                {/* <span className="badge rounded-pill bg-success float-end">
                  {props.t("New")}
                </span> */}
                <span>{props.t("Pedidos")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/pedidos/pedido">{props.t("Pedidos")}</Link>
                </li>
                <li className={(roles.includes('F870C')) || (roles.includes('47E0A')) || (roles.includes('7A25E')) || (roles.includes('046C2')) || (roles.includes('B9E8D') ||
                  (roles.includes('7B49E') || (roles.includes('32795')) || (roles.includes('51657')) || (roles.includes('3A353')) || (roles.includes('D1B49')) || (roles.includes('F5C2')) || (roles.includes('59641')) || (roles.includes('BAB13')) || (roles.includes('0A0CC')) || (roles.includes('4966A') || (roles?.includes('88399') || (roles?.includes('3A61E')) || (roles?.includes('44E7A')) || (roles?.includes('BA4C9'))))) 
                ) ? ` ` : `d-none`}>
                  <Link to="/pedidos/aprobar-pedido">{props.t("Aprobar Pedidos")}</Link>
                </li>
                <li className={roles.includes('43899') ? ` ` : `d-none`}>
                  <Link to="/pedidos/modificacion-presupuestaria">{props.t("Modificación Presupuestaria")}</Link>
                </li>
                <li className={(roles.includes('F870C')) || (roles.includes('47E0A')) ? ` ` : `d-none`}>
                  <Link to="/pedidos/aprobar-modificacion-presupuestaria">{props.t("Aprobar Modificación Presupuestaria")}</Link>
                </li>
                <li className={(roles.includes('0A0CC')) || (roles.includes('88399')) || (roles.includes('7B49E') || (roles?.includes('F5E0C')) ) ? ` ` : `d-none`}>
                  <Link to="/pedidos/proveedores">{props.t("Proveedores")}</Link>
                </li>
                <li >
                  <Link to="/pedidos/insumos/catalogo-insumos">{props.t("Catálogo de Insumos")}</Link>
                </li>
                <li >
                  <Link to="/pedidos/insumos/solicitud-creacion-insumos">{props.t("Creación de Insumos")}</Link>
                </li>
                {/* <li>
                  <Link to="/it/rol">{props.t("Roles")}</Link>
                </li>
                <li>
                  <Link to="/it/persona">{props.t("Persona")}</Link>
                </li>
                <li>
                  <Link to="/it/usuario">{props.t("Usuario")}</Link>
                </li> */}
              </ul>
            </li>
            <li className={roles.includes('E4F43') || roles.includes('6FEE5') || roles.includes('77920') ? ` ` : `d-none`}>
              <Link to="/#" >
                <i className="bx bx-file"></i>
                {/* <span className="badge rounded-pill bg-success float-end">
                  {props.t("New")}
                </span> */}
                <span>{props.t("Recepción")}</span>
              </Link>
              {/* {roles.includes('6FEE5') ?
               
                  <li className={roles.includes('6FEE5') ? ` ` : `d-none`}>
                    <Link to="/recepcion/tipo-visita" >
                      <i className="bx bx-folder-open"></i>
                      <span>{props.t("Catalogos")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/recepcion/tipo-visita">{props.t("Tipo Visita")}</Link>
                      </li>
                      <li>
                        <Link to="/recepcion/ubicacion-visita">{props.t("De Donde Visita")}</Link>
                      </li>
                    </ul>
                  </li>
                : null} */}
              <ul className="sub-menu">
                <li className={roles.includes('E4F43') ? ` ` : `d-none`}>
                  <Link to="/recepcion/visitantes">{props.t("Visitantes")}</Link>
                </li>
                <li className={roles.includes('77920') ? ` ` : `d-none`}>
                  <Link to="/recepcion/visitantes-garita">{props.t("Visitantes Garita")}</Link>
                </li>
              </ul>
            </li>

            {/* Modulo de Reportes */}
            <li className={roles.includes('92255') ? ` ` : `d-none`}>
              <Link to="/#" >
                <i className="bx bx-line-chart"></i>
                {/* <span className="badge rounded-pill bg-success float-end">
                  {props.t("New")}
                </span> */}
                <span>{props.t("Consultas y Reportes")}</span>
              </Link>
              <ul className="sub-menu">
                <li className={roles.includes('B41ED') ? ` ` : `d-none`}>
                  <Link to="/reportes/consulta-expedientes">{props.t("Consulta Expedientes")}</Link>
                </li>
                <li className={roles.includes('0032F') ? ` ` : `d-none`}>
                  <Link to="/reportes/saldos-unidades">{props.t("Saldos Unidades")}</Link>
                </li>
              </ul>
             
            </li>
            {/* Modulo de Historico Pedidos - Archivo */}
            <li className={roles.includes('3A61E') ? ` ` : `d-none`}>
              <Link to="/#" >
                <i className="bx bx-file-find"></i>
                {/* <span className="badge rounded-pill bg-success float-end">
                  {props.t("New")}
                </span> */}
                <span>{props.t("Archivo")}</span>
              </Link>
              <ul className="sub-menu">
                <li className={roles.includes('3A61E') ? ` ` : `d-none`}>
                  <Link to="/archivo/historico-sipa">{props.t("Historico SIPA")}</Link>
                </li>
              </ul>
            </li>

            {/* Modulo de Documentos */}
            <li className={roles.includes('6A3FD') || roles.includes('6A3FD') || roles.includes('6A3FD') ? ` ` : `d-none`}>
              <Link to="/#" >
                <i className="bx bx-file"></i>
                {/* <span className="badge rounded-pill bg-success float-end">
                  {props.t("New")}
                </span> */}
                <span>{props.t("Documentos")}</span>
              </Link>
              <ul className="sub-menu">
                <li className={roles.includes('6A3FD') || roles.includes('8A3D8') ? ` ` : `d-none`}>
                  <Link to="/documentos/recepcion">{props.t("Recepción")}</Link>
                </li>
                <li className={roles.includes('6A3FD') || roles.includes('9098') ? ` ` : `d-none`}>
                  <Link to="/documentos/unidad">{props.t("Unidad")}</Link>
                </li>
              </ul>
            </li>
            <li className={roles.includes('D7ECB') || roles.includes('F8E50') ? ` ` : `d-none`}>
              <Link to="/#" >
                <i className="bx bx-task"></i>
                {/* <span className="badge rounded-pill bg-success float-end">
                  {props.t("New")}
                </span> */}
                <span>{props.t("Gestiones")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/gestiones/solicitud">{props.t("Solicitud")}</Link>
                </li>
                <li className={(roles.includes('48372')) || (roles.includes('34E6E')) || (roles.includes('52B6E')) || (roles.includes('CA85D')) || (roles.includes('DA346'))  || (roles.includes('C7F42')) ? ` ` : `d-none`}>
                  <Link to="/gestiones/aprobar-gestiones">{props.t("Atender Gestiones")}</Link>
                </li>
                <li className={(roles.includes('D7ECB')) ? ` ` : `d-none`}>
                  <Link to="/gestiones/admin-gestiones">{props.t("Consulta de Gestiones")}</Link>
                </li>

                {/* <li>
                  <Link to="/it/rol">{props.t("Roles")}</Link>
                </li>
                <li>
                  <Link to="/it/persona">{props.t("Persona")}</Link>
                </li>
                <li>
                  <Link to="/it/usuario">{props.t("Usuario")}</Link>
                </li> */}
              </ul>
            </li>
          </ul>
        </div >
      </SimpleBar >
    </>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
