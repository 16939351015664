import React from "react";
import { ContentProvider } from './context'
import CardOne from './form'
const Index = () => {

    return (
        <>
            <ContentProvider>
                <div className="page-content">
                    <CardOne />
                </div>
            </ContentProvider>
        </>
    );
}

export default Index