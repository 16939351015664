import React from "react";
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import Reset from "../pages/Authentication/ResetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
//IT
import Rol from "../pages/IT/Rol"
import Persona from "../pages/IT/Persona/index"
import Usuario from "../pages/IT/Usuario/index"
import SMPT from "../pages/IT/Configuracion/SMTP"
import TipoPersona from "../pages/IT/TipoPersona"
import BitacoraExpedientes from "../pages/IT/BitacoraExpedientes"
//ADMIN
import Grupo from "../pages/ADMIN/Grupo"
import SubGrupo from "../pages/ADMIN/SubGrupo"
import Unidades from "../pages/ADMIN/Unidades"
import Renglones from "../pages/ADMIN/Renglones"
import Puestos from "../pages/ADMIN/Puestos"
import Actividades from "../pages/ADMIN/Actividades"
import Programas from "../pages/ADMIN/Programas"
//INSUMOS
import Insumos from "../pages/INSUMOS/GestionInsumos/"
import SolicitudInsumos from "../pages/INSUMOS/SolicitudInsumos/"
//POA
import TipoBeneficiario from "../pages/POA/TipoBeneficiario"
import Dependencia from "../pages/POA/Dependencia"
import Acciones from "../pages/POA/Acciones"
import ObjetivosEstrategicos from "../pages/POA/ObjetivosEstrategicos"
import ObjetivosOperativos from "../pages/POA/ObjetivosOperativos"
import ObjetivosUnidad from "../pages/POA/ObjetivoUnidad"
import PresupuestoDependencia from "../pages/POA/PresupuestoDependencia"
import Financiamiento from "../pages/POA/Financiamiento"
import Eje from "../pages/POA/Eje"
import POA from "../pages/POA/Poa/index"
import PoaOne from "../pages/POA/PoaOne/index"
import PresupuestoPoa from "../pages/POA/PresupuestoPoa"
import ProgramaActividadUnidad from "../pages/POA/ProgramaActividadUnidad"
import GestionAdministrativa from "../pages/UNIDAD/GestionAdministrativa/index"
import SaldosUnidades from "../pages/REPORTES/SaldosUnidades/index"
//PEDIDOS
import Pedido from "../pages/PEDIDOS/Pedido"
import AprobarPedido from "../pages/PEDIDOS/AprobarPedido"
import ModificacionPresupuestaria from "../pages/PEDIDOS/ModificacionesPresupuestarias"
import AprobarModificacionPresupuestaria from "../pages/PEDIDOS/AprobarModificaciones"
import CatalogoInsumos from "../pages/PEDIDOS/CatalogoInsumos/Insumos"
import Proveedores from "../pages/PEDIDOS/Proveedores"
//RECEPCION
import Recepcion from "../pages/RECEPCION/Visitantes"
import TipoVisita from "../pages/RECEPCION/TipoVisita"
import UbicacionVisitante from "../pages/RECEPCION/UbicacionVisita"
import RecepcionGarita from "../pages/RECEPCION/VisitantesGarita"
import TipoOrigen from "../pages/RECEPCION/Catalogos/TipoOrigen"
import OrigenExterno from "../pages/RECEPCION/Catalogos/OrigenExterno"
//DOCUMENTOS
import Documentos from "../pages/RECEPCION/Documentos"
import DocumentosDetalle from "../pages/RECEPCION/Documentos/Detalle"
//GESTIONES
import Gestion from "../pages/GESTIONES/Gestiones"
import AprobarGestiones from "../pages/GESTIONES/AprobarGestiones"
import AdminGestiones from "../pages/GESTIONES/AdminGestiones"
//CONSULTAS Y REPORTES
import ConsultaExpedientes from "../pages/REPORTES/ConsultaExpedientes"
//ARCHIVO
import HistoricoSIPA from "../pages/ARCHIVO/HistoricoSIPA"


const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },
  //IT
  { path: "/it/rol", component: Rol },
  { path: "/it/persona", component: Persona },
  { path: "/it/usuario", component: Usuario },
  { path: "/it/tipo-persona", component: TipoPersona },
  { path: "/it/smtp", component: SMPT },
  { path: "/it/bitacora-expedientes", component: BitacoraExpedientes },
  //ADMIN
  { path: "/admin/grupos", component: Grupo },
  { path: "/admin/subgrupos", component: SubGrupo },
  { path: "/admin/unidades", component: Unidades },
  { path: "/admin/renglones", component: Renglones },
  { path: "/admin/puestos", component: Puestos },
  { path: "/admin/actividades", component: Actividades },
  { path: "/admin/programas", component: Programas },
  { path: "/admin/tipo-visita", component: TipoVisita },
  { path: "/admin/ubicacion-visita", component: UbicacionVisitante },
  { path: "/admin/tipo-origen", component: TipoOrigen },
  { path: "/admin/origen-externo", component: OrigenExterno },

  //INSUMOS
  { path: "/insumos/gestion-insumos", component: Insumos },
  { path: "/insumos/solicitud", component: SolicitudInsumos },
  //ADMIN FINANCIERO
  { path: "/admin/financiero/actividades", component: Actividades },
  { path: "/admin/financiero/programas", component: Programas },
  { path: "/admin/financiero/programa-unidad", component: ProgramaActividadUnidad },

  //POA
  { path: "/poa/tipo-beneficiario", component: TipoBeneficiario },
  { path: "/poa/dependencia", component: Dependencia },
  { path: "/poa/acciones", component: Acciones },
  { path: "/poa/programa-unidad", component: ProgramaActividadUnidad },
  { path: "/poa/objetivos-estrategicos", component: ObjetivosEstrategicos },
  { path: "/poa/objetivos-operativos", component: ObjetivosOperativos },
  { path: "/poa/objetivos-unidad", component: ObjetivosUnidad },
  { path: "/poa/presupuesto-dependencia", component: PresupuestoDependencia },
  { path: "/poa/financiamiento", component: Financiamiento },
  { path: "/poa/plan-operativo-anual", component: POA },
  { path: "/poa/ejes-de-gobernanza", component: Eje },
  { path: "/poa/presupuesto-poa", component: PresupuestoPoa },
  { path: "/poa/presupuesto-poa/:codigo", component: PoaOne },
  //GESTION ADMINISTRATIVA
  { path: "/gestion-administrativa/unidad/:codigo", component: GestionAdministrativa },
  // { path: "/gestion-administrativa/saldos-unidades", component: SaldosUnidades },
  //PEDIDOS
  { path: "/pedidos/pedido", component: Pedido },
  { path: "/pedidos/aprobar-pedido", component: AprobarPedido },
  { path: "/pedidos/modificacion-presupuestaria", component: ModificacionPresupuestaria },
  { path: "/pedidos/aprobar-modificacion-presupuestaria", component: AprobarModificacionPresupuestaria },
  { path: "/pedidos/insumos/catalogo-insumos", component: CatalogoInsumos },
  { path: "/pedidos/insumos/solicitud-creacion-insumos", component: SolicitudInsumos },
  { path: "/pedidos/proveedores", component: Proveedores },
  //RECEPCION
  { path: "/recepcion/visitantes", component: Recepcion },
  { path: "/recepcion/visitantes-garita", component: RecepcionGarita },
  //DOCUMENTOS
  { path: "/documentos/recepcion", component: Documentos },
  { path: "/documentos/unidad", component: Documentos },
  { path: "/documentos/detalle/:codigo", component: DocumentosDetalle },
  //GESTIONES
  { path: "/gestiones/solicitud", component: Gestion },
  { path: "/gestiones/aprobar-gestiones", component: AprobarGestiones },
  { path: "/gestiones/admin-gestiones", component: AdminGestiones },
  //CONSULTAS Y REPORTES
  { path: "/reportes/consulta-expedientes", component: ConsultaExpedientes },
  { path: "/reportes/saldos-unidades", component: SaldosUnidades },
  //ARCHIVO
  { path: "/archivo/historico-sipa", component: HistoricoSIPA },




  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/reset-password/:codigo", component: Reset },
]

export { publicRoutes, authProtectedRoutes }
