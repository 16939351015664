import React, { createContext, useState, useEffect } from 'react'
import { PostRoute, GetRoute } from '../../../services/Private'
import { OptionsToast } from '../../../Globales/index'
import { toast } from 'react-toastify'
import { useRequest } from '../../../hooks/useRequestSWR'
import { useSWRConfig } from 'swr'
import { useDataUser } from 'hooks/useDataUser'
import PDFTransportes from './PDFTransportes.jsx'
import PDFVehiculos from './PDFVehiculos'
import PDFPrestamo from './PDFPrestamo'


export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    // Modal Init
    const code = useDataUser()?.dataUser?.codigoUnidad;
    const solicitante = useDataUser()?.dataUser?.i;
    const idFADN = useDataUser()?.dataUser?.idFADN;
    const esFADN = useDataUser()?.dataUser?.esFADN;
    const [idAccion, setAccion] = useState(0);
    const [idValidaEncuesta, setValidaEncuesta] = useState(0);
    const { mutate } = useSWRConfig();
    const [id, setId] = useState(0);
    const [mutateSaldoAccion, setMutateSaldoAccion] = useState(false);
    const [idPresupuestoPoa, setIdPresupuestoPoa] = useState(0);
    const [idModalidad, setIdModalidad] = useState(0);
    const [idTipoSolicitudSoporte, setidTipoSolicitudSoporte] = useState(0);
    const [idEquipoPrestamo, setidEquipoPrestamo] = useState(0);
    const [pdf, setPdf] = useState([]);
    const nameComponent = 'Gestión';
    const nameController = 'Gestiones';
    const nameController2 = 'Acciones';
    const nameController3 = 'TipoPedido';
    const nameController4 = 'TipoGasto';
    const nameController5 = 'Fand';
    const nameController6 = 'Persona';
    const { data: allData } = useRequest(`${nameController}/all`, 'GETSWR');
    const { data: labelActividades } = useRequest(`${nameController}/labelTipoActividadesCOG`, 'GETSWR');
    const { data: labelActividadesFADN } = useRequest(`${nameController}/labelTipoActividadesFADN`, 'GETSWR');
    // const { data: allDataAsignaciones } = useRequest(`${nameController}/allDetalleAsignacion`, 'POSTValidation', { id: id }, {}, validation);
    // const { data: labelAcciones } = useRequest(`${nameController2}/label-Unidad`, 'POST', { code: code });
    // const { data: labelJefeDireccion } = useRequest(`${nameController6}/jefeDireccion`, 'POST', {});
    // const { data: labelTipoPedido } = useRequest(`${nameController3}/label`, 'GET');
    // const { data: labelTipoGasto } = useRequest(`${nameController4}/label`, 'GET');
    // const { data: labelFand } = useRequest(`${nameController5}/label`, 'GET');
    const [modal2, setModal2] = useState(false);
    const [opcion2, setOpcion2] = useState(0);

    const toggleModal2 = async (data) => {

        setOpcion2(data)
        // if (data === 1) { setOneData(null), setIdPresupuestoPoa(0) }
        setModal2(!modal2)

    };
    // const toggleModal2 = (data) => {
    //     setOpcion2(data);
    //     data === 1 && setOneData([]);
    //     setModal(!modal2)
    // };
    const One2 = async (opcion, estado) => {
        if (estado === 11) {
            // setShowLoad(true);
            // const response = await PostRoute(`${nameController}/one`, { id: data.id });
            // setOneData(response[0] ? response[0] : []);
            toggleModal2(opcion);
            // setShowLoad(false);
        }
    };
    const
        [modal, setModal] = useState(null),
        // [allData, setAllData] = useState([]),
        [oneData, setOneData] = useState([]),
        [DataBitacoraPedido, setDataBitacoraPedido] = useState([]),
        [agregarRenglon, setAgregarRenglon] = useState(0),
        [opcion, setOpcion] = useState(0),
        toggleModal = async (data) => {

            setOpcion(data)
            if (data === 1) { setOneData(null), setIdPresupuestoPoa(0) }
            setModal(!modal)

        },
        // activaMutateTotalSaldo = async (data) => {

        //     setOpcion(data)
        //     if (data === 1) { setOneData(null), setIdPresupuestoPoa(0) }
        //     setModal(!modal)

        // },
        // All = async () => {
        //     const response = await GetRoute(`${nameController}/all`)
        //     setAllData((response.length) ? response : [])
        // },
        One = async (data, opcion) => {
            const response = await PostRoute(`${nameController}/one`, { id: data.id })
            setOneData((response[0]) ? response[0] : [])
            toggleModal(opcion)

        },
        BitacoraPedido = async (data, opcion) => {
            const response = await PostRoute(`${nameController}/bitacoraGestiones`, { id: data.id })
            setDataBitacoraPedido((response) ? response : [])
            toggleModal(opcion)

        },
        StoreUpdate = async (data, id) => {
            let response = []
            response = await PostRoute(`${nameController}/${!data.id ? 'store' : 'update'}`, data)
            const responseTwo = await PostRoute(`${nameController}/one`, { id: (!id ? response?.id : id) })
            setOneData((responseTwo[0]) ? responseTwo[0] : [])
            setOpcion(3)
            if (response.response === 0) {
                toast.error(response.message, OptionsToast)
            } else {
                toast.success(response.message, OptionsToast)
                setAccion(responseTwo[0]?.idAccion)

            }
            // toast.success(response.message, OptionsToast)
            // toggleModal(0)
            mutate(`${nameController}/all`)

        },
        Actions = async (data) => {

            const response = await PostRoute(`${nameController}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data.id })
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)

        },
        UpdateEstadoPedido = async (data) => {
            const response = await PostRoute(`${nameController}/${'UpdateEstadoGestiones'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)

        },
        EnviarARevision = async (data, opcion) => {
            toggleModal(opcion)
            const response = await PostRoute(`${nameController}/${'enviarARevision'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)
        },
        EnviarEncuesta = async (data, opcion) => {
            toggleModal(opcion)
            const response = await PostRoute(`${nameController}/${'enviarEncuesta'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)


        },
        onSubmitEstado = (id, estado, idActividadGestion) => {
            if (estado === 11) { // Marcar como Recibida y Concluída la Gestión
                const json = {
                    id: id,
                    tipoRechazo: 4,
                    descripcion: null,
                    estado: 12,
                }
                UpdateEstadoPedido(json)
            }
            if (estado === 17) { // Marcar como Recibida y Concluída la Gestión
                const json = {
                    id: id,
                    tipoRechazo: 4,
                    descripcion: null,
                    estado: 18,
                }
                UpdateEstadoPedido(json)
            }
            if (estado === 5 && idActividadGestion === 1 ) { // Marcar como Impresa y en Proceso (Transporte)
                const json = {
                    id: id,
                    tipoRechazo: 4,
                    descripcion: null,
                    estado: 24,
                }
                UpdateEstadoPedido(json)
            }
            if (estado === 5 && idActividadGestion === 2 ) { // Marcar como Impresa y en Proceso (Prestamo Vehiculo)
                const json = {
                    id: id,
                    tipoRechazo: 4,
                    descripcion: null,
                    estado: 18,
                }
                UpdateEstadoPedido(json)
            }
            // reset()
            // toggleModal(0)
        },
        Imprimir = async (data) => {
            if (data?.idActividadGestion === 1) {
                const response = await PostRoute(`GestionesDetalle/allDetalle`, { id: data?.id })
                const response2 = await PostRoute(`${nameController}/allDetalleAsignacion`, { id: data?.id })
                const Firmantes = await GetRoute(`${nameController}/labelFirmantesPDFTransportes`)
                if (response?.response === 0) {
                    toast.error(response.message, OptionsToast)
                } else {
                    const response3 = await PDFTransportes(data, response[0], response2)
                    //const response3 = await FormatoRazonamientoCarta(data, dataPersonaLog.data[0], dataJefe.data[0])
                    return response3
                }

            }
            if (data?.idActividadGestion === 2) {
                const response = await PostRoute(`GestionesDetalle/allDetalle`, { id: data?.id })
                const response2 = await PostRoute(`${nameController}/allDetalleAsignacion`, { id: data?.id })
                const Firmantes = await GetRoute(`${nameController}/labelFirmantesPDFTransportes`)
                if (response?.response === 0) {
                    toast.error(response.message, OptionsToast)
                } else {
                    const response3 = await PDFVehiculos(data, response[0], response2)
                    //const response3 = await FormatoRazonamientoCarta(data, dataPersonaLog.data[0], dataJefe.data[0])
                    return response3
                }

            }
            if (data?.idActividadGestion === 7) {
                const response = await PostRoute(`GestionesDetalle/allDetallePrestamo`, { id: data?.id })
                const response2 = await PostRoute(`${nameController}/allDetalleAsignacion`, { id: data?.id })
                // const Firmantes = await GetRoute(`${nameController}/labelFirmantesPDFTransportes`)
                if (response?.response === 0) {
                    toast.error(response.message, OptionsToast)
                } else {
                    const response3 = await PDFPrestamo(data, response[0],  response2)
                    //const response3 = await FormatoRazonamientoCarta(data, dataPersonaLog.data[0], dataJefe.data[0])
                    return response3
                }
            }



        },
        // labelActividades = [
        //     { value: 1, label: 'Transporte' },
        //     { value: 2, label: 'Vehículo' },
        //     { value: 3, label: 'Soporte Técnico (Dirección de Informática)' },
        //     // { value: 4, label: 'Audiovisuales' },
        //     // { value: 5, label: 'Mantenimiento' },
        //     // { value: 6, label: 'Infraestructura' },
        //     { value: 7, label: 'Préstamo de Equipo' },
        //   ],
        // labelActividadesFADN = [
        //     { value: 1, label: 'Transporte' },
        //     { value: 4, label: 'Audiovisuales' },
        //   ],
        value = {
            Actions,
            One,
            setId,
            setIdPresupuestoPoa,
            setAgregarRenglon,
            setAccion,
            StoreUpdate,
            toggleModal,
            useDataUser,
            agregarRenglon,
            idAccion,
            allData,
            idPresupuestoPoa,
            onSubmitEstado,
            // labelAcciones,
            // labelFand,
            // labelJefeDireccion,
            // labelTipoGasto,
            // labelTipoPedido
            modal,
            nameComponent,
            oneData,
            opcion,
            solicitante,
            mutateSaldoAccion, setMutateSaldoAccion,
            code,
            setIdModalidad,
            idModalidad,
            Imprimir,
            pdf, setPdf,
            UpdateEstadoPedido,
            DataBitacoraPedido,
            BitacoraPedido,
            EnviarARevision,
            EnviarEncuesta,
            labelActividades,
            labelActividadesFADN,
            idFADN,
            esFADN,
            solicitante,
            One2,
            toggleModal2,
            opcion2,
            modal2, setModal2,
            idTipoSolicitudSoporte, setidTipoSolicitudSoporte,
            idEquipoPrestamo, setidEquipoPrestamo,
            idValidaEncuesta, setValidaEncuesta
        }
    // console.log(useDataUser())
    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}
