import React, { createContext, useState } from 'react'
import { PostRoute, PostRouteFD } from 'services/Private'
import { OptionsToast } from 'Globales/index'
import { toast } from 'react-toastify'
import { useRequest } from 'hooks/useRequestSWR'
import { useSWRConfig } from 'swr'
import { useDataUser } from 'hooks/useDataUser'
export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    // Modal Init
    const { mutate } = useSWRConfig();
    const roles = useDataUser()?.dataUser?.roles;
    const nameComponent = 'Solicitud Insumos';
    const nameController = 'InsumosSolicitud';
    const nameController2 = 'InsumosAdjuntos';
    const [showPDF, setShowPDF] = useState(false);
    const [checkInput, setCheckInput] = useState([]);
    const [idSolicitud, setIdSolicitud] = useState(0);
    const { data: allData } = useRequest(`${nameController}/All`, 'POST', { solicitudInsumo: idSolicitud });
    const { data: allAdjuntos } = useRequest(`${nameController2}/All`, 'POST', { solicitudInsumo: idSolicitud });
    // const { data: labelUnidades } = useRequest(`${nameController2}/label`, 'GETSWR');
    // const { data: labelActividades } = useRequest(`${nameController3}/label`, 'GETSWR');
    // const { data: labelProgramas } = useRequest(`${nameController4}/label`, 'GETSWR');
    const
        [modal, setModal] = useState(null),
        // [allData, setAllData] = useState([]),
        [oneData, setOneData] = useState([]),
        [opcion, setOpcion] = useState(0),
        toggleModal = async (data) => {

            setOpcion(data)
            if (data === 1) { setOneData(null) }
            setModal(!modal)

        },
        // All = async () => {
        //     const response = await GetRoute(`${nameController}/all`)
        //     setAllData((response.length) ? response : [])
        // },
        One = async (data, opcion) => {
            setIdSolicitud(data.id)
            const response = await PostRoute(`${nameController}/one`, { id: data.id })
            setOneData((response[0]) ? response[0] : [])
            toggleModal(opcion)

        },
        StoreUpdate = async (data) => {

            let response = []
            response = await PostRoute(`${nameController}/${!data.id ? 'store' : 'update'}`, data)
            if (response.response === 0) {
                toast.error(response.message, OptionsToast)
            } else {
                toast.success(response.message, OptionsToast)
                setIdSolicitud(response.id)
            }
            // toggleModal(0)
            mutate([`${nameController}/All`, {}])

        },
        storeFile = async (data) => {
            // setShowLoad(true)
            let response = []
            response = await PostRouteFD(`${nameController2}/store`, data)
            if (response.response === 0) {
                toast.error(response.message, OptionsToast)
            } else {
                toast.success(response.message, OptionsToast)
            }
            mutate([`${nameController2}/All`, { solicitudInsumo: idSolicitud }])
            // toggleModal(0)
            // setShowLoad(false)
        },
        Actions = async (data) => {

            const response = await PostRoute(`${nameController}/${data.idEstado === 1 ? 'destroy' : 'active'}`, { id: data.id })
            toast.success(response.message, OptionsToast)
            mutate([`${nameController}/All`, { solicitudInsumo: idSolicitud }])

        },
        value = {
            allAdjuntos,
            One,
            Actions,
            StoreUpdate,
            setIdSolicitud,
            setShowPDF,
            setCheckInput,
            toggleModal,
            storeFile,
            idSolicitud,
            allData,
            modal,
            nameComponent,
            oneData,
            opcion,
            showPDF,
            checkInput,
            roles
            // labelUnidades,
            // labelActividades,
            // labelProgramas
        }

    // Modal End

    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}
