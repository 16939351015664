import React, { createContext, useState, useContext } from "react"
import { OptionsToast } from "Globales/index"
import { toast } from "react-toastify"
import { PostRoute, PostRouteFD } from "services/Private"
// import { LoadingContext } from '../../../../utility/Loading/provider'
import { useSWRConfig } from "swr"
import { useRequest } from "hooks/useRequestSWR"
import { ContentContext as ContentMeta } from "../context"
export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
  const { oneData: data2, file: oneFile, toggleModal: toggleParentModal, mutate : mutateMain} = useContext(ContentMeta),
    { mutate } = useSWRConfig(),
    [show, setShow] = useState(false),
    [oneData, setOneData] = useState([]),
    [opcion, setOpcion] = useState(0),
    nameController = "Documentos",
    docName = data2?.numeroReferencia,
    { data: allData } = useRequest(
      `${nameController}/all-DocumentoAdjunto`,
      "POST",
      { documento: data2?.id }
    ),
    toggleModal = data => {
      toggleParentModal(data)
      setOpcion(data)
      if (data === 1) {
        setOneData([])
      }
      setShow(!show)
    },
    One = async (data, opcion) => {
      const response = await PostRoute(
        `${nameController}/one-DocumentoAdjunto`,
        { id: data.documento }
      )
      setOneData(response.length ? response[0] : [])
      toggleModal(opcion)
    },
    storeFile = async data => {
      // setShowLoad(true)
      let response = []
      let responseTwo = []
      response = await PostRouteFD(
        `${nameController}/store-DocumentoAdjunto`,
        data
      )

      if (response.response === 0) {
        toast.error(response.message, OptionsToast)
        //toggleModal(0)
        return
      }

      responseTwo = await PostRoute(`${nameController}/actions-Documento`, {
        correlativo: data2.correlativo,
        estado: 2,
      })

      if (responseTwo.response === 0) {
        toast.error(responseTwo.message, OptionsToast)

        toggleModal(0)
        return
      }

      toast.success(response.message, OptionsToast)

      toggleModal(0)
      mutate([
        `${nameController}/all-DocumentoAdjunto`,
        { documento: data2?.id },
      ])
     // mutateMain(`${nameController}/all-documento`)
      // setShowLoad(false)
    },
    Actions = async data => {
      const response = await PostRoute(`${nameController}/status`, {
        id: data.id,
        estado: data.idEstado === 1 ? 0 : 1,
      })
      toast.success(response.message, OptionsToast)
      mutate([
        `${nameController}/all-DocumentoAdjunto`,
        { documento: data2?.id },
      ])
     // mutateMain(`${nameController}/all-documento`)
    },
    value = {
      Actions,
      One,
      setOpcion,
      toggleModal,
      allData,
      storeFile,
      oneData,
      opcion,
      show,
      data2,
      docName,
      oneFile
    }

  return (
    <ContentContext.Provider value={value}>{children}</ContentContext.Provider>
  )
}
