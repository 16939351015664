import React, { Fragment, useContext, useEffect, useState, useRef } from "react"
import {
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  UncontrolledTooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormFeedback,
} from "reactstrap"
import { ContentContext } from "./context"
import * as Icon from "react-feather"
import { useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { customStyles, formatDate } from "../../../Globales/index"
import FileUpload from "./File/FileUpload"
import { ContentProvider as ContentProvider2 } from "./File/context"
import { DragDrop } from "@uppy/react"
import Uppy from "@uppy/core"
import XHRUpload from "@uppy/xhr-upload"
import "@uppy/core/dist/style.css"
import "@uppy/drag-drop/dist/style.css"
import { OptionsToast } from "Globales/index"
import { toast } from "react-toastify"
import { PostRouteFD } from "services/Private"
import Webcam from "react-webcam"
import Camara from "components/Camara/camara"
import FormOrigenExterno from "./formOrigenExterno"
import { ContentProvider as ContentVisita } from "../Catalogos/OrigenExterno/context"

import DatePicker, { registerLocale } from "react-datepicker"
import classnames from "classnames"

import { ContentProvider as FileProvider } from "./Adjunto/context"
import Attached from "./Adjunto/Attached"
import PDF from "./Adjunto/export/PDF"
import { Text } from "@nextui-org/react"
import Moment from "react-moment"

import Referencia from "./Referencia"

const ErrorToast = () => (
  <>
    <div className="toastify-header">
      <div className="title-wrapper">
        <h6 className="toast-title">Tipo no admitido</h6>
      </div>
      <small className="text-muted">Hace un momento.</small>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        Solo son aceptados los archivos con extensión:{" "}
        <span className="font-weight-bolder">.pdf</span>,{" "}
        {/* <span className="font-weight-bolder">.xls</span> &{" "}
          <span className="font-weight-bolder">.csv</span> !. */}
      </span>
    </div>
  </>
)

const Formulario = () => {
  const [file, setFile] = useState([])
  const [mostrar, setMostrar] = useState(0)

  function niceBytes(x) {
    let l = 0,
      n = parseInt(x, 10) || 0

    while (n >= 1024 && ++l) {
      n = n / 1024
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l]
  }
  const [load, setLoad] = useState(false),
    [name, setName] = useState(""),
    [size, setSize] = useState([]),
    [dateDocument, setDateDocument] = useState(new Date())
  const {
      toggleModal,
      toggleModal2,
      modal,
      modal2,
      StoreUpdate,
      StoreUpdate2,
      opcion,
      setOpcion,
      opcion2,
      oneData,
      oneData2,
      nameComponent,
      labelUnidades,
      labelTipoOrigen,
      labelFand,
      labelOrigenExterno,
      dataEmpleados: empleados,
      nameController,
      file: fileOne,
      mutate,
      setId,
      labelUbicacionVisita,
      setFechaInicio,
      setFechaFin,
      labelDependencia,
      UpdateEstadoDocumento,
      One,
      dimension,
      setDimension,
      roles,
    } = useContext(ContentContext),
    {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
      control,
      reset,
      watch,
    } = useForm(),
    seleccionableUnidad = watch("unidad"),
    seleccionableTipoOrigen = watch("tipoOrigen"),
    onSubmit2 = data => {
      const json = {
        id: oneData ? oneData.id : null,
        nombre: data.nombre,
      }
      StoreUpdate2(json)
    },
    onSubmit = data => {
      const json = {
        id: oneData ? oneData.id : null,
        correlativo: oneData ? oneData.correlativo : null,
        numeroReferencia: data.numeroReferencia,
        tipoOrigen: data.tipoOrigen.value,
        origenFADN: data.tipoOrigen.value === 1 ? data.fand.value : null,
        origenExterno:
          data.tipoOrigen.value === 2 ? data.origenExterno.value : null,
        descripcion: data.descripcion,
        dirigidoA: data.dirigidoA,
        firmaDocumento: data.firmaDocumento,
        fechaDocumento: dateDocument,
      }

      StoreUpdate(json)
      reset()
    },
    setData2 = async () => {
      await setValue("nombre", oneData.nombre)
    },
    clearData2 = async () => {
      reset({ nombre: "", codigo: "" })
    }

  const UpdateEstado = state => {
    UpdateEstadoDocumento(oneData, state)
  }

  const setData = async () => {
      await setValue("tipoOrigen", {
        label: oneData.tipoOrigen,
        value: oneData.idTipoOrigen,
      })
      await setValue("numeroReferencia", oneData.numeroReferencia)
      //await setValue("fechaDocumento", oneData.fechaDocumento)
      setDateDocument(new Date(oneData.fechaDocumento.toString()))
      await setValue("firmaDocumento", oneData.firmaDocumento)
      await setValue("dirigidoA", oneData.dirigidoA)
      await setValue("origenExterno", {
        label: oneData.origen,
        value: oneData.idOrigen,
      })
      await setValue("fand", { label: oneData.origen, value: oneData.idOrigen })
      await setValue("descripcion", oneData.descripcion)
    },
    clearData = async () => {
      setDateDocument(new Date())
      reset({
        numeroReferencia: "",
        firmaDocumento: "",
        dirigidoA: "",
        descripcion: "",
        origenExterno: "",
        tipoOrigen: {
          label: "",
          value: "",
        },
      })
    }

  useEffect(() => {
    async function fetchMyAPI() {
      if ((await opcion) > 1 && oneData) {
        await setData()
      }
      if ((await opcion) === 1) {
        clearData()
      }
    }

    fetchMyAPI()
  }, [opcion, oneData])

  useEffect(() => {
    let abortController = new AbortController()
    async function fetchMyAPI() {
      if (seleccionableUnidad) {
        // setValue('dependencia', '');
        setId(seleccionableUnidad.value)
        // setMostrar(true)
      }
      if (seleccionableUnidad === null || seleccionableUnidad === "") {
        setValue("dependencia", "")
        setId(0)
        setMostrar(false)
      }
    }
    fetchMyAPI()
    return () => {
      abortController.abort()
    }
  }, [seleccionableUnidad])

  useEffect(() => {
    let abortController = new AbortController()
    async function fetchMyAPI() {
      if (
        seleccionableTipoOrigen !== undefined &&
        seleccionableTipoOrigen?.value === 2 &&
        opcion !== 2
      ) {
        setMostrar(true)
      } else {
        setMostrar(false)
      }
      if (
        seleccionableTipoOrigen === null ||
        seleccionableTipoOrigen === "" ||
        seleccionableTipoOrigen === undefined
      ) {
        setMostrar(false)
      }
    }
    fetchMyAPI()
    return () => {
      abortController.abort()
    }
  }, [seleccionableTipoOrigen])

  useEffect(() => {
    if (Array.isArray(empleados)) {
      // Definir el nuevo objeto a añadir
      const nuevoElemento = { label: "No Aplica", value: null, idUnidad: 0 }
      // Añadir el nuevo objeto al array
      empleados.push(nuevoElemento)
    }
  }, [empleados])

  const handleMutate = () => {
    mutate(`${nameController}/all-Documento`)
  }

  return (
    <Fragment>
      <Button
        onClick={() => toggleModal(1)}
        id="crear"
        color="primary"
        size="sm"
      >
        <Icon.Plus size={16} />
      </Button>
      <UncontrolledTooltip
        placement="left"
        target="crear"
        placementPrefix="bs-tooltip"
      >
        Crear
      </UncontrolledTooltip>

      {
        /* Visualizar o Crear Documento */ opcion <= 3 && (
          <Modal
            isOpen={modal}
            backdrop="static"
            toggle={() => toggleModal()}
            className="modal-dialog-centered"
            style={dimension}
          >
            <ModalHeader
              className="text-uppercase"
              toggle={() => toggleModal(0)}
            >
              {opcion === 1 ? "Crear" : "Visualizar"} {` ${nameComponent}`}
            </ModalHeader>
            <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <ModalBody>
                {opcion === 2 ? (
                  <Resumen onEvent={setOpcion} onRedimension={setDimension} />
                ) : (
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label for="numeroReferencia" className="">
                              * Numero de Referencia
                            </Label>
                            <Controller
                              defaultValue=""
                              control={control}
                              id="numeroReferencia"
                              name="numeroReferencia"
                              render={({ field }) => (
                                <Input
                                  type="text"
                                  invalid={errors.numeroReferencia && true}
                                  disabled={opcion === 2}
                                  {...field}
                                />
                              )}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Este campo es requerido",
                                },
                              }}
                            />
                            <FormFeedback>
                              {!!errors.numeroReferencia &&
                                errors.numeroReferencia.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col sm={3}>
                          {opcion === 2 ? (
                            <>
                              <Label>Fecha Del Documento</Label>
                              <br></br>
                              <span>
                                <Moment locale="es" format="DD/MM/yyyy" fromNow>
                                  {oneData?.fechaDocumento}
                                </Moment>
                              </span>
                            </>
                          ) : (
                            <FormGroup>
                              <Label for="fechaDocumento">
                                * Fecha Del Documento
                              </Label>
                              <Controller
                                name="fechaDocumento"
                                control={control}
                                render={() => {
                                  return (
                                    <DatePicker
                                      className={classnames(
                                        "form-control invoice-edit-input due-date-picker ",
                                        {
                                          "is-invalid":
                                            errors.fechaDocumento && true,
                                        }
                                      )}
                                      selected={dateDocument}
                                      dateFormat="dd/MM/yyyy"
                                      // disabled={true}
                                      locale="es"
                                      onChange={fechaDocumento =>
                                        setDateDocument(fechaDocumento)
                                      }
                                      // showYearPicker
                                      name="fechaDocumento"
                                      id="fechaDocumento"
                                      // dateFormat="yyyy"
                                    />
                                  )
                                }}
                              />
                              <FormFeedback>
                                {!!errors.fechaUso && errors.fechaUso.message}
                              </FormFeedback>
                            </FormGroup>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label>* Tipo de Origen</Label>
                            <Controller
                              name="tipoOrigen"
                              control={control}
                              defaultValue={null}
                              render={({ field }) => {
                                return (
                                  <Select
                                    {...field}
                                    isClearable
                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                    isDisabled={opcion === 2}
                                    options={labelTipoOrigen}
                                    className="selector-react3"
                                    classNamePrefix="select"
                                    placeholder={"Seleccione una opción"}
                                    noOptionsMessage={() => "sin resultados"}
                                    styles={
                                      !!errors.tipoOrigen ? customStyles : {}
                                    }
                                  />
                                )
                              }}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Este campo es requerido",
                                },
                              }}
                            />
                            <small className="text-danger">
                              {!!errors.tipoOrigen && errors.tipoOrigen.message}
                            </small>
                          </FormGroup>
                        </Col>
                        {seleccionableTipoOrigen !== undefined &&
                          seleccionableTipoOrigen?.value === 1 && (
                            <Col>
                              <FormGroup>
                                <div className="d-flex justify-content-between">
                                  <Label>* Federación</Label>
                                </div>
                                <Controller
                                  name="fand"
                                  control={control}
                                  defaultValue={null}
                                  render={({ field }) => {
                                    return (
                                      <Select
                                        {...field}
                                        isClearable
                                        onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                        onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                        isDisabled={opcion === 2}
                                        options={labelFand}
                                        className="selector-react3"
                                        classNamePrefix="select"
                                        placeholder={"Seleccione una opción"}
                                        noOptionsMessage={() =>
                                          "sin resultados"
                                        }
                                        styles={
                                          !!errors.fand ? customStyles : {}
                                        }
                                      />
                                    )
                                  }}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: "Este campo es requerido",
                                    },
                                  }}
                                />
                                <small className="text-danger">
                                  {!!errors.fand && errors.fand.message}
                                </small>
                              </FormGroup>
                            </Col>
                          )}
                        {seleccionableTipoOrigen !== undefined &&
                          seleccionableTipoOrigen?.value === 2 && (
                            <Col>
                              <FormGroup>
                                <div className="d-flex justify-content-between">
                                  <Label>* Entidades Estatales</Label>
                                </div>
                                <Controller
                                  name="origenExterno"
                                  control={control}
                                  defaultValue={null}
                                  render={({ field }) => {
                                    return (
                                      <Select
                                        {...field}
                                        isClearable
                                        onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                        onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                        isDisabled={opcion === 2}
                                        options={labelOrigenExterno}
                                        className="selector-react3"
                                        classNamePrefix="select"
                                        placeholder={"Seleccione una opción"}
                                        noOptionsMessage={() =>
                                          "sin resultados"
                                        }
                                        styles={
                                          !!errors.origenExterno
                                            ? customStyles
                                            : {}
                                        }
                                      />
                                    )
                                  }}
                                  rules={{
                                    required: {
                                      value: true,
                                      message: "Este campo es requerido",
                                    },
                                  }}
                                />
                                <small className="text-danger">
                                  {!!errors.origenExterno &&
                                    errors.origenExterno.message}
                                </small>
                              </FormGroup>
                            </Col>
                          )}
                        {mostrar === true && (
                          <Col className="align-self-center text-center" lg={1}>
                            <FormOrigenExterno />
                          </Col>
                        )}
                      </Row>

                      <Row>
                        <Col>
                          <FormGroup>
                            <Label for="firmaDocumento" className="">
                              * Firmado Por
                            </Label>
                            <Controller
                              defaultValue=""
                              control={control}
                              id="firmaDocumento"
                              name="firmaDocumento"
                              render={({ field }) => (
                                <Input
                                  type="text"
                                  invalid={errors.firmaDocumento && true}
                                  disabled={opcion === 2}
                                  {...field}
                                />
                              )}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Este campo es requerido",
                                },
                              }}
                            />
                            <FormFeedback>
                              {!!errors.firmaDocumento &&
                                errors.firmaDocumento.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label for="dirigidoA" className="">
                              * Dirigido a
                            </Label>
                            <Controller
                              defaultValue=""
                              control={control}
                              id="dirigidoA"
                              name="dirigidoA"
                              render={({ field }) => (
                                <Input
                                  type="text"
                                  invalid={errors.dirigidoA && true}
                                  disabled={opcion === 2}
                                  {...field}
                                />
                              )}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Este campo es requerido",
                                },
                              }}
                            />
                            <FormFeedback>
                              {!!errors.dirigidoA && errors.dirigidoA.message}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormGroup>
                            <Label for="descripcion" className="">
                              * Descripcion:{" "}
                            </Label>
                            <Controller
                              control={control}
                              id="descripcion"
                              name="descripcion"
                              render={({ field }) => (
                                <Input
                                  rows={2}
                                  type="textarea"
                                  // readOnly={updateData}
                                  invalid={errors.descripcion && true}
                                  {...field}
                                  disabled={opcion === 2}
                                />
                              )}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Este campo es requerido",
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        {opcion > 2 && (
                          <Col
                            lg={2}
                            xl={2}
                            className="align-self-center text-center"
                          >
                            <Fragment>
                              <Button
                                id="Guardar1"
                                color="success"
                                size="sm"
                                type="submit"
                                className="text-center  me-2"
                              >
                                <Icon.Save size={16} />
                              </Button>

                              <UncontrolledTooltip
                                placement="top"
                                target="Guardar1"
                              >
                                Actualizar Expediente
                              </UncontrolledTooltip>

                              <Button
                                id="back"
                                color="danger"
                                size="sm"
                                type="button"
                                className="text-center"
                                onClick={() => setOpcion(2)}
                              >
                                <Icon.Slash size={16} />
                              </Button>

                              <UncontrolledTooltip
                                placement="top"
                                target="back"
                              >
                                Cancelar
                              </UncontrolledTooltip>
                            </Fragment>
                          </Col>
                        )}
                      </Row>

                      {/* {
                    (file && opcion === 2) && (
                      <PDF hasFile={file} />
                    )
                  } */}
                    </Col>
                  </Row>
                )}
              </ModalBody>
              {opcion === 1 && (
                <ModalFooter>
                  <Button
                    id="salir"
                    color="secondary"
                    onClick={() => toggleModal(0)}
                  >
                    <Icon.CornerUpLeft size={16} />
                  </Button>
                  <UncontrolledTooltip
                    placement="top"
                    target="salir"
                    placementPrefix="bs-tooltip"
                  >
                    Salir
                  </UncontrolledTooltip>
                  <Fragment>
                    <Button
                      disabled={opcion === 2}
                      color="success"
                      type="submit"
                      id="accion"
                    >
                      <Icon.Save size={16} />
                    </Button>
                    <UncontrolledTooltip
                      placement="top"
                      target="accion"
                      placementPrefix="bs-tooltip"
                    >
                      {opcion !== 2 && opcion === 1 ? "Crear" : "Actualizar"}
                    </UncontrolledTooltip>
                  </Fragment>
                </ModalFooter>
              )}
            </Form>
          </Modal>
        )
      }

      {
        /* Subir o modificar el adjunto */ opcion === 5 && (
          <Modal
            isOpen={modal}
            backdrop="static"
            style={{ maxWidth: "1800px", width: "50%", margin: "10px auto" }}
            toggle={() => toggleModal()}
            className="modal-dialog-centered"
          >
            <ModalHeader
              className="text-uppercase"
              toggle={() => toggleModal(0)}
            >
              {opcion === 5 && (!fileOne || fileOne.length === 0)
                ? "Adjuntar "
                : "Corregir "}
              {` ${nameComponent}`}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col>
                  <FileProvider>
                    <Attached onUpload={handleMutate} />
                  </FileProvider>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>
        )
      }

      {
        /* Enviar a Gerencia */ opcion === 7 && (
          <Modal
            isOpen={modal}
            backdrop="static"
            style={{ maxWidth: "800px", width: "80%", margin: "10px auto" }}
            toggle={() => toggleModal()}
            className="modal-dialog-centered"
          >
            <ModalHeader
              className="text-uppercase"
              toggle={() => toggleModal(0)}
            >
              {opcion === 7 && "Enviar el Expediente "} {oneData?.correlativo}
            </ModalHeader>
            <Form
              autoComplete="off"
              onSubmit={handleSubmit(() => UpdateEstado(3))}
            >
              <ModalBody>
                <Row>
                  <Col className="text-center">
                    <Text h1 size={20} weight="medium">
                      Esta acción cambia el estado del expediente{" "}
                      <strong>{oneData && oneData?.correlativo}</strong>, ¿Está
                      seguro de que todo está bien y desea proceder?
                    </Text>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  id="salir"
                  color="secondary"
                  onClick={() => toggleModal(0)}
                >
                  <Icon.CornerUpLeft size={16} />
                </Button>
                <UncontrolledTooltip
                  placement="top"
                  target="salir"
                  placementPrefix="bs-tooltip"
                >
                  Salir
                </UncontrolledTooltip>
                <Fragment>
                  <Button color="success" type="submit" id="btnAdjudicar">
                    <Icon.CheckSquare size={16} />
                  </Button>
                  <UncontrolledTooltip
                    placement="top"
                    target="btnAdjudicar"
                    placementPrefix="bs-tooltip"
                  >
                    {opcion === 7 && "Enviar"}
                  </UncontrolledTooltip>
                </Fragment>
              </ModalFooter>
            </Form>
          </Modal>
        )
      }

      {
        /* Visto Bueno de Gerencia */ opcion === 9 && (
          <Modal
            isOpen={modal}
            backdrop="static"
            style={{ maxWidth: "800px", width: "80%", margin: "10px auto" }}
            toggle={() => toggleModal()}
            className="modal-dialog-centered"
          >
            <ModalHeader
              className="text-uppercase"
              toggle={() => toggleModal(0)}
            >
              {opcion === 9 && "Recibir Expediente "} {oneData?.correlativo}
            </ModalHeader>
            <Form
              autoComplete="off"
              onSubmit={handleSubmit(() => UpdateEstado(4))}
            >
              <ModalBody>
                <Row>
                  <Col className="text-center">
                    <Text h1 size={20} weight="medium">
                      Esta acción cambia el estado del expediente{" "}
                      <strong>{oneData && oneData?.correlativo}</strong>, ¿Está
                      seguro de que todo está bien y desea proceder?
                    </Text>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  id="salir"
                  color="secondary"
                  onClick={() => toggleModal(0)}
                >
                  <Icon.CornerUpLeft size={16} />
                </Button>
                <UncontrolledTooltip
                  placement="top"
                  target="salir"
                  placementPrefix="bs-tooltip"
                >
                  Salir
                </UncontrolledTooltip>
                <Fragment>
                  <Button color="success" type="submit" id="btnAdjudicar">
                    <Icon.CheckSquare size={16} />
                  </Button>
                  <UncontrolledTooltip
                    placement="top"
                    target="btnAdjudicar"
                    placementPrefix="bs-tooltip"
                  >
                    {opcion === 7 && "Enviar"}
                  </UncontrolledTooltip>
                </Fragment>
              </ModalFooter>
            </Form>
          </Modal>
        )
      }
    </Fragment>
  )
}

const Resumen = ({ onEvent, onRedimension }) => {
  const { oneData, file: fileOne } = useContext(ContentContext)
  const [styleProps, setStyleProps] = useState({
    height: "315px",
  })
  const RoutePathServer = process.env.REACT_APP_PATH_SERVER
  //const fileUrl = `${RoutePathServer + file[0]??.archivo}`;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm()

  // fileUrl = `${RoutePathServer + fileOne[0]?.archivo}`;

  //desarrollo:
  const fileUrl = `${
    RoutePathServer +
    "Uploads/AdjuntosPedido/Factura_solicitud_GCC-0004-2024_orden_de_compra_0_fecha_25-07-2024-02-08-06.pdf"
  }`

  const isPdf = fileOne[0]?.extension?.toLowerCase() === ".pdf"

  const onEdit = () => onEvent(3)

  useEffect(() => {
    console.log(fileOne)
    console.log(oneData)

    if (fileOne.length > 0) {
      onRedimension({
        maxWidth: "1800px",
        width: "85%",
        margin: "10px auto",
      })
      if (oneData?.idEstado === 4)
        setStyleProps({
          height: "750px",
        })
    }
  }, [])

  return (
    <Row>
      <Col>
        <div className="card p-3">
          <Row>
            <Col>
              <>
                <Label>Correlativo</Label>
                <br></br>
                <span>{oneData?.correlativo}</span>
              </>
            </Col>
            <Col>
              <>
                <Label>Numero de Referencia</Label>
                <br></br>
                <span>{oneData?.numeroReferencia}</span>
              </>
            </Col>
            <Col>
              <>
                <Label>Fecha Del Documento</Label>
                <br></br>
                <span>
                  <Moment locale="es" format="DD/MM/yyyy" fromNow>
                    {oneData?.fechaDocumento}
                  </Moment>
                </span>
              </>
            </Col>
          </Row>
        </div>
        <div className="card p-3">
          <Row>
            <Col>
              <>
                <Label>Tipo de Origen</Label>
                <br></br>
                <span>{oneData?.tipoOrigen}</span>
              </>
            </Col>
            {
              <Col>
                <>
                  <Label>
                    {" "}
                    {oneData?.idTipoOrigen === 1
                      ? "Federacion"
                      : "Entidad Estatal"}{" "}
                  </Label>
                  <br></br>
                  <span>{oneData?.origen}</span>
                </>
              </Col>
            }
          </Row>

          <Row>
            <Col className="py-2">
              <>
                <Label>Firmado Por</Label>
                <br></br>
                <span style={{ fontSize: "12px" }}>
                  {oneData?.firmaDocumento}
                </span>
              </>
            </Col>
            <Col className="py-2">
              <>
                <Label>Dirigido a</Label>
                <br></br>
                <span style={{ fontSize: "12px" }}>{oneData?.dirigidoA}</span>
              </>
            </Col>
          </Row>

          <Row>
            <Col className="py-2">
              <>
                <Label>Descripcion</Label>
                <br></br>
                <span style={{ fontSize: "12px" }}>{oneData?.descripcion}</span>
              </>
            </Col>
            {oneData?.idEstado < 3 && (
              <Col lg={2} xl={2} className="align-self-center text-center">
                <Fragment>
                  <Button
                    id="Actualizar1"
                    color="warning"
                    size="sm"
                    type="button"
                    className="text-center"
                    onClick={e => onEdit(3)}
                  >
                    <Icon.Edit size={16} />
                  </Button>
                  <UncontrolledTooltip placement="top" target="Actualizar1">
                    Actualizar Expediente
                  </UncontrolledTooltip>
                </Fragment>
              </Col>
            )}
          </Row>
        </div>
        {oneData?.idEstado === 4 && (
          <div className="card">
            <Referencia />
          </div>
        )}

        {oneData?.idEstado === 4 && (
          <div className="card ">
            <Row>
              <Col>
                <FormGroup>
                  <Label for="descripcion" className="mt-3">
                    * Observaciones:{" "}
                  </Label>
                  <Controller
                    control={control}
                    id="descripcion"
                    name="descripcion"
                    render={({ field }) => (
                      <Input
                        rows={4}
                        height={"100%"}
                        type="textarea"
                        // readOnly={updateData}
                        invalid={errors.descripcion && true}
                        defaultValue=""
                        {...field}
                      />
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: "Este campo es requerido",
                      },
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        )}
      </Col>

      {fileOne.length > 0 && (
        <Col>
          <div className="d-flex justify-content-around">
            {
              <>
                {isPdf ? (
                  <object
                    className="Visor text-center"
                    width="100%"
                    style={styleProps}
                    id="pdf-ficha"
                    aria-labelledby="perfil"
                    type="application/pdf"
                    data={fileUrl}
                  >
                    <p>
                      Tu navegador no soporta visualizar PDFs.{" "}
                      <a href={fileUrl}>Descargar el PDF</a>.
                    </p>
                  </object>
                ) : (
                  <img
                    src={fileUrl}
                    width="100%"
                    height="100%"
                    className="text-center"
                    alt="Adjunto"
                  />
                )}
              </>
            }
          </div>
        </Col>
      )}
    </Row>
  )
}

export default Formulario
