import React, { useEffect, useState, createContext, useContext } from 'react'
import { GetRoute, PostRoute, PostRouteFD } from '../../../services/Private'
import { OptionsToast } from '../../../Globales/index'
import { toast } from 'react-toastify'
import { useSWRConfig } from 'swr'
// import { LoadingContext } from '../../../../context/loadingContext'
import { useRequest } from 'hooks/useRequestSWR'
export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {

    //Modal Init

    const
        // { setShowLoad } = useContext(LoadContext),
        [modal, setModal] = useState(false),
        // [allData, setAllData] = useState([]),
        [id, setId] = useState(0),
        [allPuestos, setAllPuestos] = useState([]),
        { mutate } = useSWRConfig(),
        [allRenglones, setAllRenglones] = useState([]),
        [allUnidades, setAllUnidades] = useState([]),
        [allDependencias, setAllDependencias] = useState([]),
        [oneData, setOneData] = useState([]),
        [opcion, setOpcion] = useState(0),
        nameController = 'persona',
        // nameController2 = 'Dependencias',
        nameController3 = 'tipoPersona',
        nameController5 = 'Fand';
    // const { data: labelDependencia } = useRequest(`${nameController2}/by-unidad`, 'POST', { id: id });
    const { data: labelTipoPersona } = useRequest(`${nameController3}/label`, 'GET');
    const { data: allData } = useRequest(`${nameController}/all`, 'GET');
    const { data: labelFand } = useRequest(`${nameController5}/label`, 'GET');
    const toggleModal = data => {
        setOpcion(data)
        if (data === 1) { setOneData(null) }
        setModal(!modal)
    },
        // All = async () => {
        //     // await setShowLoad(true)
        //     let response = []
        //     response = await GetRoute(`${nameController}/all`)
        //     setAllData((response.length) ? response : [])
        //     // await setShowLoad(false)
        // },
        One = async (Id, opcion) => {

            const response = await PostRoute(`${nameController}/one`, { id: Id })
            setOneData((response[0]) ? response[0] : [])
            toggleModal(opcion)

        },
        StoreUpdate = async (data, id) => {

            let response = []
            response = await PostRouteFD(`${nameController}/${!id ? 'store' : 'update-data'}`, data)
            toast.success(response.message, OptionsToast)
            toggleModal(0)
            All()
            mutate(`${nameController}/all`)

        },
        Actions = async (data) => {

            const response = await PostRoute(`${nameController}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data.id })
            toast.success(response.message, OptionsToast)
            All()

        },
        AllPuestos = async () => {
            const response = await GetRoute(`puesto/label`)
            setAllPuestos((response.length) ? response : [])
        },
        AllRenglones = async () => {
            const response = await GetRoute(`renglon/label`)
            setAllRenglones((response.length) ? response : [])
        },
        AllUnidades = async () => {
            const response = await GetRoute(`unidad/label`)
            setAllUnidades((response.length) ? response : [])
        },
        AllDependencias = async (data) => {
            const response = await PostRoute(`dependencias/by-unidad`, { id: data })
            setAllDependencias((response.length) ? response : [])
        },
        labelTipousuarioFADNS = [
            { value: 1, label: 'Operador' },
            { value: 2, label: 'Administrativo' }
        ],
        value = {
            // All,
            AllDependencias,
            One,
            Actions,
            setId,
            StoreUpdate,
            toggleModal,
            allData,
            allPuestos,
            allRenglones,
            allUnidades,
            allDependencias,
            labelTipoPersona,
            labelTipousuarioFADNS,
            labelFand,
            oneData,
            modal,
            opcion
        }

    // Modal End
    useEffect(() => {

        AllPuestos(); AllRenglones(); AllUnidades();
    }, [])


    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}