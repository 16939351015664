import React, { createContext, useState } from 'react'
import { useRequest } from '../../../hooks/useRequestSWR'
import { PostRoute } from '../../../services/Private'
import { useSWRConfig } from 'swr'
import { OptionsToast } from '../../../Globales/index'
import { toast } from 'react-toastify'
import { useRouteCode } from 'hooks/useRouteCode'

export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    const { mutate } = useSWRConfig();
    // const code = useRouteCode()?.codigo;
    const [code, setCode] = useState([]);
    const nameController = 'Acciones';
    const nameController2 = 'DetalleAcciones';
    const nameController3 = 'PlanOperativoAnual';
    const nameController4 = 'ObjetivosEstrategicos';
    const nameController5 = 'ObjetivosOperativos';
    const nameController6 = 'Unidad';
    const { data: labelUnidades } = useRequest(`${nameController6}/label`, 'GET');
    const { data: allData } = useRequest(`${nameController}/Acciones-X-Unidad`, 'POST', { code: code });
    const { data: allData2 } = useRequest(`${nameController2}/allDetalles`, 'POST', { code: code });
    const { data: allData3 } = useRequest(`${nameController2}/subTotales`, 'POST', { code: code });
    const { data: allData4 } = useRequest(`${nameController2}/totalPoa`, 'POST', { code: code });
    const { data: allDataOE } = useRequest(`${nameController4}/All-code`, 'POST', { code: code });
    const { data: allDataOOU } = useRequest(`${nameController5}/Objetivos-Op-X-Unidad`, 'POST', { code: code });
    const { data: poaFechaEnvio } = useRequest(`${nameController3}/poaFechaEnvio`, 'POST', { code: code });
    const [opcion, setOpcion] = useState(0);
    const [modal, setModal] = useState(null);
    const toggleModal = async (data) => {

        setOpcion(data)
        if (data === 1) { setOneData(null), setIdPresupuestoPoa(0) }
        setModal(!modal)
    };
    const One = async (data, opcion) => {
        // const response = await PostRoute(`${nameController}/one`, { id: data.id })
        // setOneData((response[0]) ? response[0] : [])
        toggleModal(opcion)

    };
    const updatePOA = async (data) => {
        let response = []
        response = await PostRoute(`${nameController3}/store`, data)
        toast.success(response.message, OptionsToast)
        toggleModal(0)
        // mutate(`${nameController}/all`)
        mutate(`${nameController3}/new-poa`)
        mutate([`${nameController3}/poaFechaEnvio`, { code: code }])
    };


    const

        value = {
            allData,
            allData2,
            allData3,
            allData4,
            allDataOE,
            allDataOOU,
            code,
            modal,
            poaFechaEnvio,
            opcion,
            labelUnidades,
            One,
            updatePOA,
            toggleModal,
            setCode
        }

    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}
