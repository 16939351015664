import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import { toAbsoluteUrl } from './../../helpers/AssetHelper'
import ProfileNew from './newProfileView'
const UserProfile = () => {

  //meta title
  //  document.title="Profile | Skote - React Admin & Dashboard Template";

  const dispatch = useDispatch();
  const Blowfish = require('javascript-blowfish');
  const bf = new Blowfish(process.env.REACT_APP_CONFIG_KEY);
  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [idx, setidx] = useState(1);

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }));

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const Decript = bf.decrypt(bf.base64Decode(localStorage.getItem('authUser')));
                const data = JSON.parse(Decript.replace(/\0/g, ''));
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(data.displayName);
        setemail(data.email);
        setidx(data.uid);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(data.username);
        setemail(data.email);
        setidx(data.uid);
      }
      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: name || '',
      idx: idx || '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your UserName"),
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values));
    }
  });


  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumb title="SIPA" breadcrumbItem="Perfil" />
        <Container fluid>
          <ProfileNew />
          {/* Render Breadcrumb */}

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              {/* <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                       src={toAbsoluteUrl("/media/logos/170.jpg")}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{name}</h5>
                        <p className="mb-1">{email}</p>
                        <p className="mb-0">Id no: #{idx}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card> */}
            </Col>
          </Row>
          
          {/* <Row>
            <Col>
              <Card>
                <div className="card-body">
                  <div className="mb-4 h4 card-title">Social Source</div>
                  <div className="text-center">
                    <div className="avatar-sm mx-auto mb-4">
                      <span className="avatar-title rounded-circle bg-primary bg-soft font-size-24">
                        <i className="mdi mdi-facebook text-primary" />
                      </span>
                    </div>
                    <p className="font-16 text-muted mb-2" />
                    <h5>
                      <a className="text-dark" href="/dashboard">
                        Facebook - <span className="text-muted font-16">125 sales</span>{" "}
                      </a>
                    </h5>
                    <p className="text-muted">
                      Maecenas nec odio et ante tincidunt tempus. Donec vitae sapien ut
                      libero venenatis faucibus tincidunt.
                    </p>
                    <a className="text-primary font-16" href="/dashboard">
                      Learn more <i className="mdi mdi-chevron-right" />
                    </a>
                  </div>
                  <div className="mt-4 row">
                    <div className="col-4">
                      <div className="social-source text-center mt-3">
                        <div className="avatar-xs mx-auto mb-3">
                          <span className="avatar-title rounded-circle bg-primary font-size-16">
                            <i className="mdi mdi-facebook text-white" />
                          </span>
                        </div>
                        <h5 className="font-size-15">Facebook</h5>
                        <p className="text-muted mb-0">125 sales</p>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="social-source text-center mt-3">
                        <div className="avatar-xs mx-auto mb-3">
                          <span className="avatar-title rounded-circle bg-info font-size-16">
                            <i className="mdi mdi-twitter text-white" />
                          </span>
                        </div>
                        <h5 className="font-size-15">Twitter</h5>
                        <p className="text-muted mb-0">112 sales</p>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="social-source text-center mt-3">
                        <div className="avatar-xs mx-auto mb-3">
                          <span className="avatar-title rounded-circle bg-pink font-size-16">
                            <i className="mdi mdi-instagram text-white" />
                          </span>
                        </div>
                        <h5 className="font-size-15">Instagram</h5>
                        <p className="text-muted mb-0">104 sales</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col>
              <Card>
                <div className="card-body">
                  <div className="mb-5 h4 card-title">Activity</div>
                  <ul className="verti-timeline list-unstyled">
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18" />
                      </div>
                      <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                          <h5 className="font-size-14">
                            22 Nov{" "}
                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                          </h5>
                        </div>
                        <div className="flex-grow-1">
                          <div>Responded to need “Volunteer Activities</div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18" />
                      </div>
                      <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                          <h5 className="font-size-14">
                            17 Nov{" "}
                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                          </h5>
                        </div>
                        <div className="flex-grow-1">
                          <div id="activitytext">
                            Everyone realizes why a new common language would be
                            desirable... <a href="/dashboard">Read more</a>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list active">
                      <div className="event-timeline-dot">
                        <i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right" />
                      </div>
                      <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                          <h5 className="font-size-14">
                            15 Nov{" "}
                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                          </h5>
                        </div>
                        <div className="flex-grow-1">
                          <div>Joined the group “Boardsmanship Forum”</div>
                        </div>
                      </div>
                    </li>
                    <li className="event-list">
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle font-size-18" />
                      </div>
                      <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                          <h5 className="font-size-14">
                            12 Nov{" "}
                            <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                          </h5>
                        </div>
                        <div className="flex-grow-1">
                          <div>Responded to need “In-Kind Opportunity”</div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div className="text-center mt-4">
                    <a className="btn btn-primary btn-sm" href="/">
                      View More <i className="mdi mdi-arrow-right ms-1" />
                    </a>
                  </div>
                </div>
              </Card>
            </Col>
            <Col>
              <Card>
                <div className="card-body">
                  <div className="mb-4 h4 card-title">Personal Information</div>
                  <p className="text-muted mb-4 py-4 ">
                    Hi I'm Cynthia Price,has been the industry's standard dummy text To an
                    English person, it will seem like simplified English, as a skeptical
                    Cambridge.
                  </p>
                  <div className="table-responsive">
                    <table className="table-nowrap mb-0 table">
                      <tbody>
                        <tr>
                          <th scope="row">Full Name :</th>
                          <td>Cynthia Price</td>
                        </tr>
                        <tr>
                          <th scope="row">Mobile :</th>
                          <td>(123) 123 1234</td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td>cynthiaskote@gmail.com</td>
                        </tr>
                        <tr>
                          <th scope="row">Location :</th>
                          <td>California, United States</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
