import React, { createContext, useState } from 'react'
import { PostRoute } from '../../../../services/Private'
import { OptionsToast } from '../../../../Globales/index'
import { toast } from 'react-toastify'
import { useRequest } from '../../../../hooks/useRequestSWR'
import { useSWRConfig } from 'swr'
import { useRouteCode } from 'hooks/useRouteCode'

export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    // Modal Init
    const { mutate } = useSWRConfig();
    const code = useRouteCode()?.codigo;
    const [accion, setAccion] = useState(0);
    const [idDependencia, setIdDependencia] = useState(0);
    const nameComponent = 'Acciones';
    const nameController = 'Acciones';
    const nameController2 = 'Dependencias';
    const nameController3 = 'PresupuestoDependencia';
    const nameController4 = 'ObjetivosOperativos';
    const nameController5 = 'DetalleAcciones';
    const nameController6 = 'ProgramaActividadUnidad';
    //Acciones-X-Unidad
    // const { data: allData } = useRequest(`${nameController}/all`, 'GET');
    const { data: labelDependencias } = useRequest(`${nameController2}/label-X-Unidad`,  'POST', { code: code } );
    const { data: labelPOA } = useRequest(`${nameController3}/Presupuesto-X-Dependencia`,  'POST', { id: idDependencia });
    const { data: labelObjetivos } = useRequest(`${nameController4}/label`,  'POST', { code: code } );
    const { data: total } = useRequest(`${nameController5}/total`, 'POST', { accion: accion } );
    const { data: allData } = useRequest(`${nameController}/Acciones-X-Unidad`, 'POST', { code: code } );
    const { data: allData2 } = useRequest(`${nameController}/one`, 'POST', { id: accion } );
    const { data: labelProgramasActividades } = useRequest(`${nameController6}/label-Unidad`, 'POST', { code: code } );
    const
        [modal, setModal] = useState(null),
        [oneData, setOneData] = useState([]),
        // [total, setTotal] = useState([]),
        [opcion, setOpcion] = useState(0),
        [agregarRenglon, setAgregarRenglon] = useState(0),
        toggleModal = async (data) => {
            
            setOpcion(data)
            if (data === 1) { setOneData(null) }
            setModal(!modal)
            
        },
        One = async (data, opcion) => {
            
            // setIdAccion(data.id)
            const response = await PostRoute(`${nameController}/one`, { id: data.id })
            setOneData((response[0]) ? response[0] : [])
            // const responseTwo = await PostRoute(`${nameController5}/total`, { accion: data.id })
            // setTotal((responseTwo[0]) ? responseTwo[0] : 0)
            toggleModal(opcion)
            
        },
        StoreUpdate = async (data, id) => {
            
            let response = []
            response = await PostRoute(`${nameController}/${!data.id ? 'store' : 'update'}`, data)
            const responseTwo = await PostRoute(`${nameController}/one`, { id: (!response.id ? id : response.id) })
            setOneData((responseTwo[0]) ? responseTwo[0] : [])
            setOpcion(3)
            if (response.response === 0) {
                toast.error(response.message, OptionsToast)
            } else {
                toast.success(response.message, OptionsToast)
                setAccion(response?.id)
                
            }
            // toggleModal(0)
            mutate([`${nameController}/Acciones-X-Unidad`, { code: code }])
            
        },
        Actions = async (data) => {
            
            const response = await PostRoute(`${nameController}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data.id })
            toast.success(response.message, OptionsToast)
            mutate([`${nameController}/Acciones-X-Unidad`, { code: code }])
            
        },
        value = {
            One,
            Actions,
            StoreUpdate,
            setAgregarRenglon,
            setAccion,
            toggleModal,
            agregarRenglon,
            allData,
            allData2,
            code,
            modal,
            nameComponent,
            oneData,
            opcion,
            labelDependencias,
            labelObjetivos,
            labelProgramasActividades,
            labelPOA,
            total,
            setIdDependencia
        }

    // Modal End

    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}
