import React, { createContext, useState, useEffect } from "react"
import { PostRoute } from "../../../services/Private"
import { OptionsToast } from "../../../Globales/index"
import { toast } from "react-toastify"
import { useRequest } from "../../../hooks/useRequestSWR"
import { useSWRConfig } from "swr"
import { useForm, Controller } from "react-hook-form"
import { useDataUser } from "../../../hooks/useDataUser"

// import { useRouteCode } from 'hooks/useRouteCode'
import { useHistory } from "react-router-dom"

export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
  // Modal Init
  const history = useHistory()
  const { watch, control } = useForm()
  const { mutate } = useSWRConfig()
  const [id, setId] = useState(0)
  const roles = useDataUser()?.dataUser?.roles;
  const unitCode = useDataUser()?.dataUser?.codigoUnidad;

  const [dimension, setDimension] = useState({
    maxWidth: "1800px",
    width: "55%",
    margin: "10px auto",
  })

  // const codigo =  useRouteCode();
  const [code, setCode] = useState("")
  const nameController = "Documentos"
  const nameController2 = "Dependencias"
  const nameController3 = "Fand"
  const nameFileExport = "Reporte Documentos"
  const { data: labelTipoOrigen } = useRequest(
    `${nameController}/label-tipoOrigen`,
    "GETSWR"
  )
  const { data: labelUnidades } = useRequest(
    `${nameController2}/label`,
    "GETSWR"
  )
  const { data: labelDependencia } = useRequest(
    `${nameController2}/by-unidad`,
    "POST",
    { id: id }
  )
  const { data: labelOrigenExterno } = useRequest(
    `${nameController}/label-OrigenExterno`,
    "GETSWR"
  )
  const { data: labelFand } = useRequest(`${nameController3}/label`, "GETSWR")
  const { data: labelUbicacionVisita } = useRequest(
    `${nameController}/label`,
    "GETSWR"
  )
  const nameComponent = "Documentos"
  const nameComponent2 = "Entidad Estatal (Origen Externo)"
  const [file, setFile] = useState([])
  const tiempoTranscurrido = Date?.now()
  const hoy = new Date(tiempoTranscurrido)
  const FechaInicio = watch("FechaInicial")
  const FechaFin = watch("FechaFinal")
  let setFechaInicio = FechaInicio ?? "2020-01-01"
  let setFechaFin = FechaFin ?? hoy?.toLocaleDateString("en-CA")?.split("T")[0]
  // const [allData, setAllData] = useState([]);
  const { data: allData } = useRequest(
    `${nameController}/all-Documento`,
    "GETSWR"
  )
  const { data: dataEmpleados } = useRequest(
    `Visitantes/label-x-Unidad`,
    "POST",
    { id: id ?? 0 }
  )


  const [modal, setModal] = useState(null),
    [modal2, setModal2] = useState(null),
    // [allData, setAllData] = useState([]),
    [oneData, setOneData] = useState([]),
    [oneData2, setOneData2] = useState([]),
    [opcion, setOpcion] = useState(0),
    [opcion2, setOpcion2] = useState(0),
    toggleModal = async data => {
      setOpcion(data)
      if (data === 1) {
        setOneData(null)
      }
      setModal(!modal)
    },
    toggleModal2 = async data => {
      setOpcion2(data)
      setModal2(!modal2)
    },
    One = async (data, opcion) => {
      const response = await PostRoute(`${nameController}/one-Documento`, {
        id: data.id,
        correlativo: data.correlativo,
      })
      const responseTwo = await PostRoute(
        `${nameController}/one-DocumentoAdjunto`,
        {
          id: data.id,
        }
      )
      setOneData(response[0] ? response[0] : [])
      setFile(responseTwo.length > 0 ? responseTwo : [])
      opcion === 4 ? (opcion = 2) : toggleModal(opcion)
      setOpcion(opcion)

      setDimension({
        maxWidth: "1800px",
        width: "55%",
        margin: "10px auto",
      })
    },
    StoreUpdate = async data => {
      console.log(data)
      let response = []
      response = await PostRoute(
        `${nameController}/${
          !data.id ? "store-Documento" : "update-Documento"
        }`,
        data
      )
      toast.success(response.message, OptionsToast)
      !data.id ? toggleModal(0) : One(data, 4)

      mutate(`${nameController}/all-Documento`)

      // mutate([`${nameController}/all`, { fechaInicial: setFechaInicio, fechaFinal: setFechaFin }])
    },
    StoreUpdate2 = async data => {
      let response = []
      response = await PostRoute(
        `${nameController}/${
          !data.id ? "store-OrigenExterno" : "update-OrigenExterno"
        }`,
        data
      )
      toast.success(response.message, OptionsToast)
      toggleModal2(0)
      mutate(`${nameController}/label-OrigenExterno`)
    },
    Actions = async data => {
      const response = await PostRoute(`${nameController}/actions-Documento`, {
        correlativo: data.correlativo,
        estado: data.idEstado === 1 ? 0 : 1,
      })
      toast.success(response.message, OptionsToast)
      mutate(`${nameController}/all-documento`)
      /*  mutate([
        `${nameController}/all-documento`,
        { fechaInicial: setFechaInicio, fechaFinal: setFechaFin },
      ]) */
    },
    UpdateEstadoDocumento = async (data, state) => {
      const response = await PostRoute(`${nameController}/actions-Documento`, {
        correlativo: data.correlativo,
        estado: state,
      })
      toast.success(response.message, OptionsToast)
      toggleModal(0)
      mutate(`${nameController}/all-Documento`)
    },
    value = {
      One,
      Actions,
      StoreUpdate,
      StoreUpdate2,
      toggleModal,
      toggleModal2,
      mutate,
      setFile,
      setId,
      Controller,
      nameFileExport,
      control,
      file,
      allData,
      // allDetalle,
      // allDentro,
      setCode,
      dataEmpleados,
      modal,
      modal2,
      oneData,
      oneData2,
      opcion,
      opcion2,
      labelUnidades,
      labelDependencia,
      labelTipoOrigen,
      labelOrigenExterno,
      labelFand,
      labelUbicacionVisita,
      nameComponent,
      nameComponent2,
      nameController,
      FechaInicio,
      FechaFin,
      setFechaInicio,
      setFechaFin,
      hoy,
      UpdateEstadoDocumento,
      setOpcion,
      dimension,
      setDimension,
      roles,
      id
    }

  return (
    <ContentContext.Provider value={value}>{children}</ContentContext.Provider>
  )
}
