import React, { Row, Col } from 'reactstrap'
import { ContentProvider } from './context'
import Formulario from './form'
import List from './list'
import OE from 'pages/UNIDAD/GestionAdministrativa/OEstrategicos/index'
import OO from 'pages/POA/ObjetivosOperativos/index'
const Index = () => {
    return (
        <ContentProvider>
            {/* <div className="page-content"> */}
            <div className="card">
                <div className="card-body">

                    {/* <h4 className="card-title mb-4">Buy/Sell Crypto</h4> */}
                    <div className="crypto-buy-se-ll-nav">
                        <ul role="tablist" className="nav nav-tabs nav-tabs-custom nav-line-tabs-2x mb-1 fs-5 border-transparent fw-bolder" >
                            <li className="nav-item">
                                <a
                                    className="nav-link active"
                                    data-bs-toggle="tab"
                                    href="#estrategicos"
                                    role="tab"
                                    aria-selected="true"
                                    tabIndex={-1}
                                >Estrategicos</a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link "
                                    data-bs-toggle="tab"
                                    href="#operativos"
                                    role="tab"
                                    aria-selected="true"
                                    tabIndex={-1}
                                >Operativos</a>
                            </li>
                        </ul>
                        <div className="tab-content p-3 text-muted">
                            <div className="tab-pane active show" id="estrategicos" role="tabpanel">
                                <OE />
                            </div>
                            <div className="tab-pane " id="operativos" role="tabpanel">
                                <OO />
                                {/* <p className="mb-0">
                                    <Row>
                                        <Col lg="2" sm="2">
                                        </Col>
                                        <Col>
                                            <div className="h1 font-weight-bolder text-uppercase text-center">Catálogo de Objetivos Operativos
                                            </div>
                                        </Col>
                                        <Col className="d-flex justify-content-end" lg="2" sm="2">
                                            <Formulario />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <List />
                                        </Col>
                                    </Row>
                                </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* </div> */}
        </ContentProvider>
    )
}

export default Index