
// import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { ContentContext } from './context'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback, Card, CardBody, CardTitle, CardSubtitle, CardText, Badge } from 'reactstrap'
import { useForm, Controller } from "react-hook-form"
import * as Icon from 'react-feather'
import Select from 'react-select'
import { formatDate, customStyles, stringToDate } from '../../../Globales/index'
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput'
import classnames from 'classnames'
import { Text } from "@nextui-org/react";
// import Objetivos from '../ObjetivosEstrategicos/index'
// import { ContentProvider } from '../ObjetivosEstrategicos/context'
import revision from '../../../assets/images/Checklist.png'
import DatePicker, { registerLocale } from "react-datepicker"
import es from "date-fns/locale/es"
registerLocale("es", es); // register it with the name you want


const Header = () => {
    // const [startDate, setStartDate] = useState(Date.parse(2019) + 1);
    const [startDate, setStartDate] = useState(new Date());
    const { toggleModal, modal, StoreUpdate, opcion, oneData, setId, idPresupuestoPoa, labelTipoGasto, labelTipoPedido, labelFand, useDataUser, nameComponent, labelJefeDireccion, solicitante, setAgregarRenglon, setIdModalidad, idModalidad, labelTipoModificacion, setValidador, } = useContext(ContentContext),
        [updateData, setUpdateData] = useState(false),
        { handleSubmit, formState: { errors }, setValue, control, reset, watch } = useForm(),
        seleccionableTipoSolicitud = watch('tiposolicitud'),
        onSubmit = (data) => {
            const json = {
                id: oneData?.id ?? idPresupuestoPoa ?? null,
                justificacion: data.justificacion,
                solicitante: solicitante,
                tipoModificacion: data.tiposolicitud.value,
                // fechaPedido: formatDate(startDate),
                // modalidad: idModalidad
            }
            StoreUpdate(json, (oneData) ? oneData.id : null)
            // setUpdateData(true)
        },
        setData = async () => {
            await setValue('justificacion', oneData.justificacion)
            // await setIdModalidad(oneData.modalidad)
            setStartDate(new Date(oneData.fechaPedido))
            // await setValue('tipoPedido', { label: oneData.tipoPedido, value: oneData.idtipoPedido })
            // await setValue('accion', { label: oneData.accion, value: oneData.idAccion })
            await setValue('tiposolicitud', { label: oneData.tipoModificacionTexto, value: oneData.tipoModificacion })
            // await setValue('jefeDireccion', { label: oneData.jefeDireccion, value: oneData.idJefeDireccion })

        },
        clearData = async () => {
            reset({ monto: '', unidad: '', financiamiento: '' })
        },
        setEstado = (estado) => {
            setUpdateData(estado)
        },
        habilitarEdicion = (e, estado) => {
            e.preventDefault()
            setEstado(estado)
        }

    useEffect(
        () => {
            if (oneData) {
                setUpdateData(true)
            }
        }, [oneData]
    )
    useEffect(
        () => {
            async function fetchMyAPI() {
                if (await opcion > 1 && oneData) { await setData() }
                if (await opcion === 1) { clearData(), setUpdateData(false) }
            }

            fetchMyAPI()

        }, [opcion, oneData]
    )

    useEffect(() => {
        let abortController = new AbortController();
        async function fetchMyAPI() {
            if (seleccionableTipoSolicitud) {
                if (seleccionableTipoSolicitud.value === 3) {
                    setValidador(true)
                } else {
                    setValidador(false)
                    // setIdUnidad('')
                }
            } if (seleccionableTipoSolicitud === null || seleccionableTipoSolicitud === '') {
                setValidador(false)
            }
        }
        fetchMyAPI()
        return () => { abortController.abort(); }
    }, [seleccionableTipoSolicitud])


    // console.log('tipoPedido: ' + idModalidad)
    return (
        <Fragment>
            <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className={opcion === 1 || !updateData ? ` p-3` : `card p-3`}>
                    <Row>
                        <Col>
                            {updateData ? <><Label >Unidad Solicitante</Label><br></br><span>{oneData?.nombreUnidad}</span></>
                                : <FormGroup >
                                    <Label >* Tipo de Solicitud</Label>
                                    <Controller
                                        name="tiposolicitud"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                    isDisabled={updateData}
                                                    options={labelTipoModificacion}
                                                    className='selector-react1'
                                                    classNamePrefix='select'
                                                    placeholder={'Seleccione una opción'}
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={(!!errors.tiposolicitud) ? customStyles : {}}

                                                />
                                            )
                                        }
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <small className="text-danger">{!!errors.tiposolicitud && errors.tiposolicitud.message}</small>
                                </FormGroup>}
                        </Col>


                        {updateData ? <Col><Label >Tipo de Solicitud</Label><br></br><span>{oneData?.tipoModificacionTexto}</span> </Col>
                            : null}

                        <Col md={3}>
                            {updateData ? <><Label >Fecha Solicitud</Label><br></br><span>{oneData?.fechaPedido4}</span></>
                                :

                                <FormGroup>
                                    <Label for="fechaPedido">* Fecha Solicitud</Label>
                                    <Controller
                                        name='fechaPedido'
                                        control={control}
                                        render={() => {
                                            return (
                                                < DatePicker
                                                    className={classnames('form-control invoice-edit-input due-date-picker ', {
                                                        'is-invalid': errors.fechaPedido && true
                                                    })}
                                                    selected={startDate}
                                                    disabled={true}
                                                    locale="es"
                                                    onChange={(fechaPedido) => setStartDate(fechaPedido)}
                                                    // showYearPicker
                                                    name='fechaPedido'
                                                    id="fechaPedido"
                                                // dateFormat="yyyy"
                                                />
                                            )
                                        }
                                        }
                                    // rules={
                                    //     {
                                    //         required: {
                                    //             value: true,
                                    //             message: 'Este campo es requerido'
                                    //         }
                                    //     }
                                    // }
                                    />
                                    <FormFeedback>
                                        {!!errors.fechaPedido && errors.fechaPedido.message}
                                    </FormFeedback>
                                </FormGroup>}
                        </Col>
                    </Row>
                    <Row>
                        {updateData ? <Row >
                            <Col className="py-2" lg={12} xl={12}>
                                <Label>Justificación</Label><br></br>
                                <span style={{ fontSize: '12px' }}>{oneData?.justificacion}</span>
                            </Col>
                        </Row> : null}
                        {updateData ? null
                            :
                            <Col>
                                <FormGroup>
                                    <Label for='justificacion' className="d-flex justify-content-between">* Justificación</Label>
                                    <Controller

                                        control={control}
                                        id='justificacion'
                                        name='justificacion'
                                        render={({ field }) => (
                                            <Input
                                                rows={3}
                                                type='textarea'
                                                // readOnly={opcion === 2}
                                                disabled={updateData}
                                                invalid={errors.justificacion && true}
                                                defaultValue={null}
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.justificacion && errors.justificacion.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>}
                        <Col lg={2} xl={2} className="align-self-center text-center">
 
                            {
                                !updateData &&
                                <Fragment>
                                    <Button color="success" size="sm" type="submit" className="text-center me-2" id="Guardar">
                                        <Icon.Save size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='Guardar'>
                                        Guardar
                                    </UncontrolledTooltip>
                                    {
                                        (((oneData && oneData.id)) && !updateData) &&
                                        <Fragment>
                                            <Button color="danger" size="sm" type="button" className="text-center" id="Cancelar1" onClick={(e) => habilitarEdicion(e, true)}>
                                                <Icon.Slash size={16} />
                                            </Button>
                                            <UncontrolledTooltip placement='top' target='Cancelar1'>
                                                Cancelar
                                            </UncontrolledTooltip>
                                        </Fragment>
                                    }
                                </Fragment>
                            }

                        </Col>
                        {updateData ? <>
                        <Row>
                            <Col className='py-2'><Label >Solicitante</Label><br></br><span style={{ fontSize: '12px' }}>{oneData?.solicitante}</span></Col>
                            <Col className='py-2'><Label >Estado Actual</Label><br></br><span style={{ fontSize: '12px' }}>{oneData?.estado}</span></Col>
                            <Col className='py-2'><Label >Estado Siguiente</Label><br></br><span style={{ fontSize: '12px' }}>{oneData?.siguientePaso}</span></Col>
                            <Col lg={2} xl={2} className="align-self-center text-center">
                            {
                                updateData &&
                                <Fragment>
                                    <Button id='Actualizar1' color="warning" size="sm" type="button" className="text-center  me-2" onClick={(e) => habilitarEdicion(e, false)}>
                                        <Icon.Edit size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='Actualizar1'>
                                        Actualizar Encabezado
                                    </UncontrolledTooltip>
                                    <Button id='Renglon' color="primary" size="sm" type="button" className="text-center" onClick={() => setAgregarRenglon(1)}>
                                        <Icon.Plus size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='Renglon'>
                                        Agregar Renglón
                                    </UncontrolledTooltip>
                                </Fragment>
                            }
                            {
                                !updateData &&
                                <Fragment>
                                    <Button color="success" size="sm" type="submit" className="text-center me-2" id="Guardar">
                                        <Icon.Save size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='Guardar'>
                                        Guardar
                                    </UncontrolledTooltip>
                                    {
                                        (((oneData && oneData.id)) && !updateData) &&
                                        <Fragment>
                                            <Button color="danger" size="sm" type="button" className="text-center" id="Cancelar1" onClick={(e) => habilitarEdicion(e, true)}>
                                                <Icon.Slash size={16} />
                                            </Button>
                                            <UncontrolledTooltip placement='top' target='Cancelar1'>
                                                Cancelar
                                            </UncontrolledTooltip>
                                        </Fragment>
                                    }
                                </Fragment>
                            }

                        </Col>
                            {/* <Col className='py-2'>
                                <Label >Saldo Inicial</Label><br></br>
                                <span >{oneData.montoDependenciaActual}</span>
                            </Col>
                            <Col className='py-2'>
                                <Label >Saldo Actual</Label><br></br>
                                
                                <span >{allData2 && allData2[0]?.actualProgramaDependenciaConQ ? allData2[0]?.actualProgramaDependenciaConQ : `Q.00`}</span>
                            </Col> */}
                        </Row>
                    </>
                        :
                        <>
                        </>

                    }
                    </Row>
                    {/* <Row>
                        {updateData ?
                            <>
                                <Col>
                                    <Label >Correlativo</Label><br></br><span >{oneData?.correlativo}</span>
                                </Col> </> : null
                        }
                        <Col>
                            {updateData ? <><Label >Acción</Label><br></br><span >{oneData?.accion}</span></>
                                :
                                <FormGroup >
                                    <Label >* Acción</Label>
                                    <Controller
                                        name="accion"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                    isDisabled={updateData}
                                                    options={labelAcciones}
                                                    className='selector-react2'
                                                    classNamePrefix='select'
                                                    placeholder={'Seleccione una opción'}
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={(!!errors.accion) ? customStyles : {}}

                                                />
                                            )
                                        }
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <small className="text-danger">{!!errors.accion && errors.accion.message}</small>
                                </FormGroup>}
                        </Col>
                        
                        {updateData ?
                            <>
                                <Col>
                                    <Label >Para</Label><br></br><span >{oneData?.tipoGasto}</span>
                                </Col> </> : null
                        }
                        {updateData ?
                            <>
                                <Col>
                                    <Label >Tipo Pedido</Label><br></br><span >{oneData?.tipoPedido}</span>
                                </Col> </> : null
                        }
                    </Row>
                    <Row>
                        {updateData ?
                            <>
                                <Col>
                                    <Label >Correlativo</Label><br></br><span >{oneData?.correlativo}</span>
                                </Col> </> : null
                        }
                        <Col>
                            {updateData ? <><Label >Operación</Label><br></br><span >{oneData?.accion}</span></>
                                :
                                <FormGroup>
                                    <Label for="operacion" className="d-flex justify-content-between">
                                        <span>* Operación</span>
                                    </Label>
                                    <Controller
                                        name="operacion"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                    defaultValue={null}
                                                    options={labelTipoOperacion}
                                                    // theme={selectThemeColors}
                                                    // isDisabled={!allMedidaInsumo}
                                                    className='selector-react3'
                                                    classNamePrefix='select'
                                                    placeholder={'Seleccione una opción'}
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={(!!errors.operacion) ? customStyles : {}}
                                                />
                                            )
                                        }
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <small className="text-danger">
                                        {!!errors.financiamiento && errors.financiamiento.message}
                                    </small>
                                </FormGroup>}
                        </Col>
                        <Col md={6}>
                            {updateData ? <><Label >Fuente</Label><br></br><span>{oneData?.fechaPedido4}</span></>
                                :

                                <FormGroup>
                                    <Label for="financiamiento" className="d-flex justify-content-between">
                                        <span>* Fuente</span>
                                    </Label>
                                    <Controller
                                        name="financiamiento"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                    defaultValue={null}
                                                    options={labelFinanciamiento}
                                                    // theme={selectThemeColors}
                                                    // isDisabled={!allMedidaInsumo}
                                                    className='selector-react3'
                                                    classNamePrefix='select'
                                                    placeholder={'Seleccione una opción'}
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={(!!errors.financiamiento) ? customStyles : {}}
                                                />
                                            )
                                        }
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <small className="text-danger">
                                        {!!errors.financiamiento && errors.financiamiento.message}
                                    </small>
                                </FormGroup>}
                        </Col>
                        {updateData ?
                            <>
                                <Col>
                                    <Label >Para</Label><br></br><span >{oneData?.tipoGasto}</span>
                                </Col> </> : null
                        }
                        {updateData ?
                            <>
                                <Col>
                                    <Label >Tipo Pedido</Label><br></br><span >{oneData?.tipoPedido}</span>
                                </Col> </> : null
                        }
                    </Row>
                    <Row>
                        {updateData ?
                            <>
                                <Col>
                                    <Label >Correlativo</Label><br></br><span >{oneData?.correlativo}</span>
                                </Col> </> : null
                        }
                        <Col>
                            {updateData ? <><Label >Renglón</Label><br></br><span >{oneData?.accion}</span></>
                                :
                                <FormGroup>
                                    <Label for="renglon" className="d-flex justify-content-between">
                                        <span>* Renglón</span>
                                    </Label>
                                    <Controller
                                        name="renglon"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                    defaultValue={null}
                                                    options={tipoRenglon === true ? labelAllRenglon : labelRenglonXAccion}
                                                    // theme={selectThemeColors}
                                                    // isDisabled={!allMedidaInsumo}
                                                    className='selector-react4'
                                                    classNamePrefix='select'
                                                    placeholder={'Seleccione una opción'}
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={(!!errors.renglon) ? customStyles : {}}
                                                />
                                            )
                                        }
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <small className="text-danger">
                                        {!!errors.renglon && errors.renglon.message}
                                    </small>
                                </FormGroup>}
                        </Col>
                        <Col md={3}>
                            {updateData ? <><Label >Monto</Label><br></br><span>{oneData?.fechaPedido4}</span></>
                                :

                                <FormGroup>
                                    <Label for='cantidad' className="">* Monto</Label>
                                    <Controller
                                        defaultValue=''
                                        control={control}
                                        id='costoU'
                                        name='costoU'
                                        render={({ field }) => (
                                            <CurrencyInput placeholder="Q. 0.00" type="text"
                                                // className="form-control"
                                                className={classnames('form-control  invoice-edit-input due-date-picker', {
                                                    'is-invalid': errors.costoU && true
                                                })}
                                                // readOnly={opcion === 2}
                                                // disabled={opcion === 3 || opcion === 4}
                                                // invalid={errors.costoU && true}
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.costoU && errors.costoU.message}
                                    </FormFeedback>
                                </FormGroup>
                            }
                        </Col>
                        {updateData ?
                            <>
                                <Col>
                                    <Label >Para</Label><br></br><span >{oneData?.tipoGasto}</span>
                                </Col> </> : null
                        }
                        {updateData ?
                            <>
                                <Col>
                                    <Label >Tipo Pedido</Label><br></br><span >{oneData?.tipoPedido}</span>
                                </Col> </> : null
                        }
                    </Row>*/}
                </div>
               
            </Form>

        </Fragment >
    )
}

export default Header