import React, { Fragment, useState, useContext, useEffect } from 'react'
import DataTable from 'react-data-table-component'
import { Row, Col, FormGroup, Input, UncontrolledButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu, Card } from 'reactstrap'
import * as Icon from 'react-feather'
import { CustomText, EstadoText, SiguientePasoText } from '../../../CustomList/index'
import { ContentContext } from './context'
import { ContentContext as ContentContextTheme } from '../../../components/CommonForBoth/TopbarDropdown/context'

import 'moment/locale/es-mx';

const List = (props) => {
  const { theme } = useContext(ContentContextTheme)
  const [roles, setRoles] = useState([]);
  const Blowfish = require('javascript-blowfish');
  const bf = new Blowfish(process.env.REACT_APP_CONFIG_KEY);
  const { allDataHistorica: allData, One, setAccion, setIdPresupuestoPoa, code, setPdf, BitacoraPedido, nombreSolicitante, Imprimir } = useContext(ContentContext)
  const [searchValue, setSearchValue] = useState(''),
    // PedidosXUnidad = allData ,

    // idEstado=== 4 roles.includes('F870C') //financiero
    // idEstado=== 3 roles.includes('7A25E') //unidad
    // idEstado=== 5 roles.includes('6B1D5') //planificacion
    // idEstado=== 25 roles.includes('046C2') //vista previa

    PedidosXUnidad = (allData && allData?.value !== 2009 ? allData?.filter((item, index, self) => {
      if (roles?.includes('F870C') || roles?.includes('47E0A')) {
        return item?.roles?.includes('F870C') || item?.roles?.includes('47E0A');
      } else {
        return item?.usuario === nombreSolicitante;
      }
    }).filter((item, index, self) => self.findIndex(t => t.correlativo === item.correlativo) === index) : []),

    [filteredData, setFilteredData] = useState([]),
    handleFilter = e => {
      const value = e.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      setSearchValue(value);


      if (value?.length) {
        const updatedData = PedidosXUnidad && PedidosXUnidad?.filter(item => {
          const correlativo = item?.correlativo?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          const justificacion = item?.justificacion?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          const fechaPedido = item?.fechaPedido2?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");


          return correlativo?.includes(value) || justificacion?.includes(value) || fechaPedido?.includes(value);
        });

        setFilteredData(updatedData)
        setSearchValue(value)
      }
      if (value === '') {
        setFilteredData([])
      }
    },
    MuestraPDF = async (data) => {
      const doc = await Imprimir(data)
      setPdf(doc)
    },
    Columns = [
      {
        name: 'Correlativo',
        column: 'correlativo',
        sortable: true,
        center: false,
        grow: 5,
        cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '13px' }}>{row?.correlativo}</div> // Esto asegura que el contenido no se trunca
      },
      {
        name: 'Fecha',
        column: 'fechaPedido3',
        sortable: true,
        center: false,
        grow: 2,
        cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '11px' }}>{row?.fechaPedido2}</div> // Esto asegura que el contenido no se trunca
      },
      ((roles?.includes('0A0CC')) ?
        {
          name: 'Analista',
          column: 'analistaAsignado',
          grow: 4,
          cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '11px' }}>{row?.analistaAsignado}</div>
        }
        : []),
      {
        name: 'Justificación',
        column: 'justificacion',
        sortable: true,
        grow: 10, // Ajusta el valor según sea necesario para dar más espacio a la columna
        wrap: true,
        cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '10px' }}>{row?.justificacion}</div> // Esto asegura que el contenido no se trunca

      },
      // {
      //   name: 'Año',
      //   column: 'año',
      //   sortable: true,
      //   center: true,
      //   cell: row => CustomText(row['año'])
      // },
      {
        name: 'Estado',
        column: 'estadoActual',
        sortable: true,
        center: true,
        grow: 8,
        // width: '14%',
        cell: row => <EstadoText row={row} />
      },
      {
        name: 'Siguiente Paso',
        column: 'siguientePaso',
        sortable: true,
        center: true,
        grow: 9, // Ajusta el valor según sea necesario para dar más espacio a la columna
        wrap: true,
        // width: '22%',
        cell: row => <SiguientePasoText row={row} />
      },
      {
        name: 'Acciones',
        column: 'id',
        sortable: true,
        center: true,
        grow: 4,
        cell: row => (
          <UncontrolledButtonDropdown direction='start'>

            <DropdownToggle className='pr-1' tag='span'>
              <Icon.MoreVertical size={15} />
            </DropdownToggle>
            <DropdownMenu className="!m-0"  >

              {
                // (row.idEstado === 25) || (row.idEstado === 26) ? null :
                <DropdownItem className="w-100" onClick={() => [BitacoraPedido(row, 6), setIdPresupuestoPoa(row.id)]}>
                  <Icon.GitPullRequest className="me-2" size={15} />
                  <span className='align-middle ml-50'>Ver Proceso</span>
                </DropdownItem>
              }
              {
                // ((row?.idEstado === 26) || (row?.idEstado === 2)) &&
                <DropdownItem className="w-100" onClick={() => [One(row, 2), setAccion(row.idAccion)]}>
                  <Icon.Eye className="me-2" size={15} />
                  <span className='align-middle ml-50'>Ver Solicitud</span>
                </DropdownItem>
              }
              {
                row?.analistaAsignado !== 'Sin Técnico Asignado' &&
                <DropdownItem className={(roles.includes('0A0CC')) || (roles.includes('ED1F6')) ? ` w-100` : `d-none w-100`} onClick={() => One(row, 39)}>
                  <Icon.UserPlus className="me-2" size={15} />
                  <span className='align-middle ml-50'>Cambiar Analista</span>
                </DropdownItem>
              }

              {
                ((row?.idEstado === 56 || row?.idEstado === 64 )&& (roles?.includes('3A61E'))) ?
                  <DropdownItem className={(roles?.includes('3A61E')) ? ` w-100` : `d-none w-100`} onClick={() => One(row, 48)}>
                    <Icon.Paperclip className="me-2" size={15} />
                    <span className='align-middle ml-50'>Añadir Expediente Digitalizado</span>
                  </DropdownItem> : null
              }
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        )
      }
    ];
  // ExpandedComponent = ({ data }) => {
  //   return (

  //     <Card className='p-5'>
  //       <h1>Ultimo Proceso</h1>
  //       <p className="fs-5"><b>{'Descripción de Solicitud: '}</b>{data?.dataBitacora}</p>
  //       <span><b>{'Teléfono: '}</b>{data?.telefono ?? 'No registro teléfono'}</span>
  //       <br />
  //       <Moment locale="es" format='lll' fromNow>{data.creado}</Moment>
  //       <span><b>{'Tipo de Respuesta: '}</b>{data?.tipoRespuesta}</span>
  //     </Card>
  //   )
  // };
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const Decript = bf.decrypt(bf.base64Decode(localStorage.getItem('authUser')));
      const data = JSON.parse(Decript.replace(/\0/g, ''));
      // const obj = JSON.parse(localStorage.getItem("authUser"));
      setRoles(data.roles);
    }
  }, [props.success]);

  return (
    <Fragment>
      <Row className='justify-content-end mx-0'>
        <Col>

        </Col>
        <Col className='justify-content-end mt-1' md='4' sm='12'>
          <FormGroup>
            <Input
              className='dataTable-filter mb-50'
              type='text'
              bsSize='md'
              placeholder={`Buscar`}
              id='search-input'
              value={searchValue}
              onChange={handleFilter}
            />
          </FormGroup>
        </Col>
      </Row>
      <div className='card'>
        <DataTable
          // dense
          striped
          noHeader
          highlightOnHover
          // expandableRows expandableRowsComponent={ExpandedComponent}
          pagination
          theme={theme}
          data={searchValue.length ? filteredData : (PedidosXUnidad && PedidosXUnidad.value !== 2009 ? PedidosXUnidad : [])}
          columns={Columns}
          className='table-responsive mt-4'
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          paginationPerPage={100}
          paginationComponentOptions={
            {
              rowsPerPageText: '',
              rangeSeparatorText: ''
            }
          }
          noDataComponent='Sin Registros'
        />
      </div>
    </Fragment>
  )
}

export default List