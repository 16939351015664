/* eslint-disable no-unused-vars */
import { Card, CardBody, Row, Col, UncontrolledTooltip, UncontrolledButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu, Input, Label } from 'reactstrap'
import Avatar from '../avatar/index'
import * as Icon from 'react-feather'
import React, { useState, useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
const cardPersona = (props) => {
    return (
        <Row className="p-3">
            <div className="text-center card">
                <PerfectScrollbar style={{ maxHeight: '220px' }}>
                    <div className="card-body">
                        <div className="avatar-md mx-auto mb-4 text-center">
                            {props.data.foto === "" ?
                                <span className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24">
                                    {props.data.persona.charAt(0)}
                                </span>
                                :
                                <img
                                    src={(`${(props?.RoutePathServer) + (props?.data && props?.data?.foto)}`)}
                                    alt=""
                                    className="avatar-md mx-auto rounded-circle mb-4 text-center"
                                />
                            }
                        </div>
                        <h5 className="font-size-15 mb-1">
                            <span className="text-dark" >
                                {props.data.persona}
                            </span>
                        </h5>
                        <p className="text-muted">{props?.data && props?.data?.esFADN === 1 ?  props?.data?.tipoUsuarioFADN + "/" + props?.data?.nombreFADN: props.data.puesto + "/" + props.data.iniciales}</p>
                        <div>
                            {/* <a className="badge bg-primary font-size-11 m-1" href="/contacts-grid">
                    Photoshop
                </a>
                <a className="badge bg-primary font-size-11 m-1" href="/contacts-grid">
                    illustrator
                </a> */}
                            <span className={`badge ${props.data.estado === 1 ? 'bg-success' : 'bg-danger'} font-size-11 m-1`}>
                                {props.data.estado === 1 ? "Activo" : "Inactivo"}
                            </span>
                        </div>
                    </div>
                </PerfectScrollbar>
                <div className="bg-transparent border-top card-footer">
                    <div className="contact-links d-flex font-size-20">
                        <div className="cursor-pointer flex-fill" id="visualizar" onClick={() => props.One(props.data.id, 2)}>
                            <UncontrolledTooltip placement='top' target='visualizar' placementPrefix="bs-tooltip">
                                Visualizar
                            </UncontrolledTooltip>
                            <Icon.Eye size={20} className="me-2" />
                        </div>
                        <div className="cursor-pointer flex-fill" id="actualizar" onClick={() => props.One(props.data.id, 3)}>
                            <UncontrolledTooltip placement='top' target='actualizar' placementPrefix="bs-tooltip">
                                Actualizar
                            </UncontrolledTooltip>
                            <Icon.Edit size={20} className="me-2" />
                        </div>
                        <div className="cursor-pointer flex-fill" id="actions" onClick={() => props.Actions(props.data)}>
                            <UncontrolledTooltip placement='top' target='actions' placementPrefix="bs-tooltip">
                                {props.data.estado === 1 ? 'Deshabilitar' : 'Habilitar'}
                            </UncontrolledTooltip>
                            {props.data.estado === 1 ? <Icon.ToggleLeft size={25} className="me-2" /> : <Icon.ToggleRight size={25} className="me-2" />}
                        </div>
                    </div>
                </div>
            </div>
        </Row>
    )
}

export default cardPersona