import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './context/ThemeColors'
import NotificationFavicon from './components/CommonForBoth/TopbarDropdown/FavIconNotificatios.js';
import store from "./store";
import './assets/css/base.css';
import './assets/scss/core.scss';
import './assets/scss/react/libs/toastify/toastify.scss';
import 'prismjs'
import 'prismjs/components/prism-jsx.min'
import 'prismjs/themes/prism-tomorrow.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { ContentProvider } from './components/CommonForBoth/TopbarDropdown/context'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter>
        <ThemeContext>
          <ContentProvider>
            <NotificationFavicon />
          </ContentProvider>
          <App />
          <ToastContainer newestOnTop />
        </ThemeContext>
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);

serviceWorker.unregister()
