import React, { createContext, useState, useContext } from "react"
import { OptionsToast } from "Globales/index"
import { toast } from "react-toastify"
import { PostRoute, PostRouteFD } from "services/Private"
// import { LoadingContext } from '../../../../utility/Loading/provider'
import { useSWRConfig } from "swr"
import { useRequest } from "hooks/useRequestSWR"
import { ContentContext as ContentMeta } from "../context"
export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
  const {
    oneData: data2,
    labelUnidades,
    labelDependencia,
    dataEmpleados: empleados,
    togleModal: toggleParentModal,
    id,
    setId
  } = useContext(ContentMeta)
  const nameController = "Documentos",
  nameController2 = "Persona",
    [show, setShow] = useState(false),
    [oneData, setOneData] = useState([]),
    [opcion, setOpcion] = useState(0),
    { data: allData } = useRequest(
      `${nameController}/all-DocumentoUnidadesInvolucradas`,
      "POST",
      { documento: data2?.id }
    ),
    { data: chiefUnit } = useRequest(
      `${nameController2}/jefe-x-unidad`,
      "POST",
      { unidad: id ?? 0 }
    ),
    toggleModal = data => {
     // toggleParentModal(data)
      setOpcion(data)
      if (data === 1) {
        setOneData([])
      }
      setShow(!show)
    },
    One = async (data, opcion) => {
      const response = await PostRoute(
        `${nameController}/one-DocumentoUnidadesInvolucradas`,
        { id: data.documento }
      )
      setOneData(response.length ? response[0] : [])
      toggleModal(opcion)
    },
    StoreUpdate = async data => {
      console.log(data)
      let response = []
      response = await PostRoute(
        `${nameController}/${
          (!data.id ? "store-" : "update-") + "DocumentoUnidadesInvolucradas"
        }`,
        data
      )
      toast.success(response.message, OptionsToast)
      !data.id ? toggleModal(0) : One(data, 4)

      mutate(`${nameController}/all-Documento`)

      // mutate([`${nameController}/all`, { fechaInicial: setFechaInicio, fechaFinal: setFechaFin }])
    },
    Actions = async data => {
      const response = await PostRoute(
        `${nameController}/actions-DocumentoUnidadesInvolucradas`,
        {
          correlativo: data.correlativo,
          estado: data.idEstado === 1 ? 0 : 1,
        }
      )
      toast.success(response.message, OptionsToast)
      mutate(`${nameController}/all-documento`)
      /*  mutate([
          `${nameController}/all-documento`,
          { fechaInicial: setFechaInicio, fechaFinal: setFechaFin },
        ]) */
    },
    value = {
        Actions,
        StoreUpdate,
        One,
        toggleModal,
        labelUnidades,
        labelDependencia,
        empleados,
        allData,
        oneData,
        opcion,
        show,
        id,
        setId,
        chiefUnit
    }

  return (
    <ContentContext.Provider value={value}>{children}</ContentContext.Provider>
  )
}
