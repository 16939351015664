import React, { Fragment, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { ContentContext } from './context';
import { Row, Col, Button } from 'reactstrap'
import { toAbsoluteUrl } from 'helpers/index'
import { ContentContext as ContentContextTheme } from '../../../../components/CommonForBoth/TopbarDropdown/context';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const List = () => {
  const { oneData } = useContext(ContentContext);
  const { theme } = useContext(ContentContextTheme);

  const Columns = [
    {
      name: 'Accion',
      selector: 'accion', // Corrección aquí
      sortable: true,
      center: false,
      grow: 3,
      wrap: true,
      cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.accion}</div>
    },
    {
      name: 'Correlativo',
      selector: 'correlativo', // Corrección aquí
      sortable: true,
      center: false,
      grow: 3,
      wrap: true,
      cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.correlativo}</div>
    },
    {
      name: 'Modalidad',
      selector: 'modalidad', // Corrección aquí
      sortable: true,
      center: false,
      grow: 2,
      wrap: true,
      cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.modalidad}</div>
    },
    {
      name: 'Descripción',
      selector: 'descripcion', // Corrección aquí
      sortable: true,
      center: false,
      grow: 10,
      wrap: true,
      cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.descripcion}</div>
    },
    {
      name: 'Renglón',
      selector: 'renglon', // Corrección aquí
      sortable: true,
      center: false,
      grow: 2,
      wrap: true,
      cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.renglon}</div>
    },
    {
      name: 'Fuente',
      selector: 'financiamiento', // Corrección aquí
      sortable: true,
      center: false,
      grow: 2,
      wrap: true,
      cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.financiamiento}</div>
    },
    {
      name: 'Estimado',
      selector: 'estimado', // Corrección aquí
      sortable: true,
      center: false,
      grow: 2,
      wrap: true,
      cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.estimado}</div>
    },
    {
      name: 'Real',
      selector: 'realizado', // Corrección aquí
      sortable: true,
      center: false,
      grow: 2,
      wrap: true,
      cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.realizado}</div>
    },
    {
      name: 'Nit',
      selector: 'nit', // Corrección aquí
      sortable: true,
      center: false,
      grow: 2,
      wrap: true,
      cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.nit}</div>
    },
    {
      name: 'Proveedor',
      selector: 'proveedor', // Corrección aquí
      sortable: true,
      center: false,
      grow: 3,
      wrap: true,
      cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.proveedor}</div>
    },
    {
      name: 'Dependencia',
      selector: 'dependencia', // Corrección aquí
      sortable: true,
      center: false,
      grow: 3,
      wrap: true,
      cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.dependencia}</div>
    },
    {
      name: 'FADN',
      selector: 'fand', // Corrección aquí
      sortable: true,
      center: false,
      grow: 2,
      wrap: true,
      cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.fand}</div>
    },
    {
      name: 'Analista Asignado',
      selector: 'analistaAsignado', // Corrección aquí
      sortable: true,
      center: false,
      grow: 4,
      wrap: true,
      cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.analistaAsignado}</div>
    },
  ];

  const exportToExcel = () => {
    const worksheetData = (oneData && oneData?.value !== 2009 ? oneData : []).map(item => {
      let rowData = {};
      Columns.forEach(col => {
        rowData[col.name] = item[col.selector];
      });
      return rowData;
    });

    // Create a new worksheet and workbook
    const ws = XLSX.utils.json_to_sheet(worksheetData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');

    // Write the workbook to a binary array
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob from the binary array and use file-saver to save the file
    const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(dataBlob, 'DetalleResumen.xlsx');
  };

  return (
    <Fragment>
      {oneData && oneData?.length > 0 ?
        <>
          <Row>
            <Col lg={12} className='text-center'>
              <Button color='success' onClick={exportToExcel} >
                <img
                  className="rounded avatar-sm"
                  src={toAbsoluteUrl("/media/logos/excel-logo.png")}
                  alt=""
                />
                {" "} Exportar a Excel
              </Button>
            </Col>
          </Row>
        </> : null

      }
      <DataTable
        striped
        noHeader
        highlightOnHover
        pagination
        theme={theme}
        data={(oneData && oneData?.value !== 2009 ? oneData : [])}
        columns={Columns}
        className='table-responsive mt-4'
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        paginationPerPage={100} // Aquí estableces 100 filas por página por defecto
        paginationComponentOptions={{
          rowsPerPageText: '',
          rangeSeparatorText: ''
        }}
        noDataComponent='Sin Registros'
      />
    </Fragment >
  );
};

export default List;
