import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { ContentContext } from './context'
import { ContentContext as ContentContextUnidad } from '../context'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback } from 'reactstrap'
import { useForm, Controller } from "react-hook-form"
import * as Icon from 'react-feather'
import DatePicker from "react-datepicker";
import Select from 'react-select'
import { customStyles } from '../../../../Globales/index'
import classnames from 'classnames'
import CurrencyInput from '../../../../components/CurrencyInput/CurrencyInput'
import maskMoney from '../../../../components/CurrencyInput/maskMoney'
import { toast } from 'react-toastify'
import { OptionsToast } from '../../../../Globales/index'
const Formulario = () => {
    const [startDate, setStartDate] = useState(new Date());
    const { toggleModal, modal, StoreUpdate, UpdatePresupuesto, opcion, oneData, labelDependencia, labelUnidades, setId, presupuestoActual, code, allData } = useContext(ContentContext),
        // { allData } = useContext(ContentContextUnidad),
        uni = labelUnidades && labelUnidades.filter(x => x.codigo === code),
        { handleSubmit, formState: { errors }, setValue, control, reset, watch } = useForm(),
        seleccionableUnidad = watch('unidad'),
        seleccionableDependencia = watch('dependencia'),
        seleccionableMonto = watch('monto'),
        onSubmit = (data) => {
            if (opcion === 4) {
                const json = {
                    id: oneData && oneData.id,
                    montoAgregado: data.montoAmpliacion,
                    unidad: uni && uni[0].value,
                    año: startDate
                }
                UpdatePresupuesto(json)
                setStartDate(new Date())
            } else {
                if (validarDuplicidad(data.dependencia.value)) {
                    if (data.monto.replace('Q', '').replace(',', '').replace(',', ''.replace(',', '')) > allData[0].monto2) {
                        toast.error('El monto asignado no puede ser mayor al presupuesto actual', OptionsToast)
                    } else {
                        const json = {
                            id: (oneData) ? oneData.id : null,
                            monto: data.monto.replace('Q', '').replace(',', '').replace(',', '').replace(',', ''),
                            dependencia: data.dependencia.value,
                            presupuestoPadre: seleccionableDependencia.label === seleccionableUnidad.label ? 1 : 0,
                            año: startDate
                        }
                        StoreUpdate(json)
                        if (seleccionableDependencia.label !== seleccionableUnidad.label && !oneData) {
                            const jsonMonto = {
                                id: (presupuestoActual && presupuestoActual[0] && presupuestoActual[0].idPresupuestoPadre),
                                monto: (presupuestoActual && presupuestoActual[0] && presupuestoActual[0].montoActual) - (seleccionableMonto).replace('Q', '').replace(',', '').replace(',', '').replace(',', ''),
                                dependencia: (presupuestoActual && presupuestoActual[0] && presupuestoActual[0].idDependenciaPadre),
                                año: startDate
                            }
                            StoreUpdate(jsonMonto)
                        }

                    }
                } else {
                    toast.error('La dependencia seleccionada ya posee presupuesto', OptionsToast)
                }
                setStartDate(new Date())
            }
        },
        setData = async () => {
            setValue('monto', oneData.monto)
            // setValue('año', oneData.año)
            setStartDate(new Date(oneData.año, '01', '01'))
            setValue('unidad', { label: oneData.unidad, value: oneData.idUnidad })
            setValue('dependencia', { label: oneData && oneData.dependencia, value: oneData && oneData.idDependencia })
        },
        clearData = async () => {
            reset({ monto: '', año: '', unidad: '', dependencia: '', montoAmpliacion: '' })
        },
        validarDuplicidad = (valor) => {
            const found = allData.find(element => (element.idDependencia === valor));
            return (found === undefined) ? true : false
        }
    useEffect(
        () => {
            async function fetchMyAPI() {
                if (await opcion > 1 && oneData) { await setData() }
                if (await opcion === 1) { clearData() }
                if (await opcion === 4) { reset({ montoAmpliacion: '' }) }
            }

            fetchMyAPI()

        }, [opcion, oneData]
    )

    useEffect(() => {
        let abortController = new AbortController();
        async function fetchMyAPI() {
            if (seleccionableUnidad) {
                // setValue('dependencia', '');
                setId(seleccionableUnidad.value)
            }
            if (seleccionableUnidad === null || seleccionableUnidad === '') {
                setValue('dependencia', '');
                setId(0);
            }
        }
        fetchMyAPI()
        return () => { abortController.abort(); }
    }, [seleccionableUnidad])

    return (
        <Fragment>
            <Button onClick={() => toggleModal(1)} className={"m-2"} id="crear" color="primary" size="sm">
                <Icon.Plus size={16} />
            </Button>
            <UncontrolledTooltip placement='left' target='crear' placementPrefix="bs-tooltip">
                Crear
            </UncontrolledTooltip>

            <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered modal-lg'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 1 ? 'crear' : opcion === 2 ? 'Visualizar' : opcion === 4 ? 'Ampliar' : ''} Presupuesto Dependencia</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup >
                                    <Label >* Unidad</Label>
                                    <Controller
                                        name="unidad"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                    isDisabled={opcion === 4 || opcion === 2}
                                                    options={uni}
                                                    className='react-select'
                                                    classNamePrefix='select'
                                                    placeholder={'Seleccione una opción'}
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={(!!errors.unidad) ? customStyles : {}}

                                                />
                                            )
                                        }
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <small className="text-danger">{!!errors.unidad && errors.unidad.message}</small>
                                </FormGroup>
                            </Col>
                            {labelDependencia && labelDependencia.value !== 2009 &&
                                <Col>
                                    <FormGroup >
                                        <Label >* Dependencia</Label>
                                        <Controller
                                            name="dependencia"
                                            control={control}
                                            defaultValue={null}
                                            render={({ field }) => {
                                                return (
                                                    <Select
                                                        {...field}
                                                        isClearable
                                                        isDisabled={opcion === 4 || opcion === 2}
                                                        onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                        onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                        options={labelDependencia}
                                                        className='react-select'
                                                        classNamePrefix='select'
                                                        placeholder={'Seleccione una opción'}
                                                        noOptionsMessage={() => 'sin resultados'}
                                                        styles={(!!errors.dependencia) ? customStyles : {}}

                                                    />
                                                )
                                            }
                                            }
                                            rules={
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido'
                                                    }
                                                }
                                            }
                                        />
                                        <small className="text-danger">{!!errors.dependencia && errors.dependencia.message}</small>
                                    </FormGroup>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for='monto'>* Monto</Label>
                                    <Controller
                                        defaultValue=''
                                        control={control}
                                        id='monto'
                                        name='monto'
                                        render={({ field }) => (
                                            <CurrencyInput placeholder="Q. 0.00" type="text"
                                                // className="form-control"
                                                className={classnames('form-control  invoice-edit-input due-date-picker', {
                                                    'is-invalid': errors.monto && true
                                                })}
                                                disabled={opcion === 2}
                                                // disabled={opcion === 3 || opcion === 4}
                                                // invalid={errors.monto && true}
                                                {...field}
                                            />
                                            // <Input
                                            //     type='number'
                                            //     // min={1}
                                            //     // max={presupuestoActual && presupuestoActual[0] && presupuestoActual[0].montoActual ? presupuestoActual[0].montoActual : allData[0]?.monto}
                                            //     step="0.01"
                                            //     readOnly={opcion === 2}
                                            //     disabled={opcion === 3 || opcion === 4}
                                            //     invalid={errors.monto && true}
                                            //     {...field}
                                            // />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                },
                                                // min: 1,
                                                // max: {
                                                //     value: presupuestoActual && presupuestoActual[0] && presupuestoActual[0].montoActual ? (allData && allData[0]?.monto ? allData[0]?.monto : 0) - (presupuestoActual[0].montoActual) : allData && allData[0]?.monto,
                                                //     message: `El monto no puede ser mayor al presupuesto actual: ${new Intl.NumberFormat('es-GT', { style: 'currency', currency: 'GTQ' }).format(presupuestoActual && presupuestoActual[0] && presupuestoActual[0].montoActual ? (allData && allData[0]?.monto ? allData[0]?.monto : 0) - (presupuestoActual[0].montoActual) : allData && allData[0]?.monto)}`
                                                // }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.monto && errors.monto.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col >
                                <FormGroup>
                                    <Label for="año">* Año</Label>
                                    <Controller
                                        name='año'
                                        control={control}
                                        render={() => {
                                            return (
                                                < DatePicker
                                                    className={classnames('form-control  invoice-edit-input due-date-picker', {
                                                        'is-invalid': errors.año && true
                                                    })}
                                                    selected={startDate}
                                                    disabled={opcion === 4 || opcion === 2}
                                                    onChange={(año) => setStartDate(año)}
                                                    showYearPicker
                                                    locale="es"
                                                    name='año'
                                                    id="año"
                                                    dateFormat="yyyy"
                                                />
                                            )
                                        }
                                        }
                                    // rules={
                                    //     {
                                    //         required: {
                                    //             value: true,
                                    //             message: 'Este campo es requerido'
                                    //         }
                                    //     }
                                    // }
                                    />
                                    <FormFeedback>
                                        {!!errors.año && errors.año.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        {opcion === 4 && <Row>
                            <Col>
                                <FormGroup>
                                    <Label for='montoAmpliacion' className="">* Monto Ampliación</Label>
                                    <Controller
                                        defaultValue=''
                                        control={control}
                                        id='montoAmpliacion'
                                        name='montoAmpliacion'
                                        render={({ field }) => (
                                            <Input
                                                type='number'
                                                step="0.25"
                                                invalid={errors.montoAmpliacion && true}
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.montoAmpliacion && errors.montoAmpliacion.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>}
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Save size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion !== 2 && opcion === 1 ? 'Crear' : 'Actualizar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default Formulario