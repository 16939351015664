import React, { Fragment, useState, useContext, useEffect } from 'react'
import DataTable, { ExpanderComponentProps } from 'react-data-table-component'
import { Row, Col, FormGroup, Input, UncontrolledButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu, Card } from 'reactstrap'
import * as Icon from 'react-feather'
import { CustomText, EstadoText, SiguientePasoText } from '../../../CustomList/index'
import { ContentContext } from './context'
import { useHistory } from 'react-router-dom'
import { ContentContext as ContentContextTheme } from '../../../components/CommonForBoth/TopbarDropdown/context'
import Moment from 'react-moment';
import 'moment/locale/es-mx';

const List = (props) => {
  const { theme } = useContext(ContentContextTheme)
  const history = useHistory();
  const [roles, setRoles] = useState([]);
  const Blowfish = require('javascript-blowfish');
  const bf = new Blowfish(process.env.REACT_APP_CONFIG_KEY);
  const { allDataH, One, Actions, setIdPresupuestoPoa, setAccion, code, Imprimir, setPdf, BitacoraPedido, puestoUserLogged, nombreSolicitante } = useContext(ContentContext)
  const [searchValue, setSearchValue] = useState(''),
    GestionesXAprobador = ((allDataH?.length > 0) && (
      allDataH?.value !== 2009 && allDataH?.value !== 7001)) &&
      allDataH?.filter(item => (
        nombreSolicitante === item?.usuario && item?.estadoTexto !== 'Gestión Enviada'
      )).filter((item, index, self) => self.findIndex(t => t.correlativo === item.correlativo) === index),

    // GestionesXAprobador = (allDataH && allDataH?.value !== 2009  ? allDataH?.filter((item) => {

    //   item?.usuario === nombreSolicitante

    // }) : []),
    [filteredData, setFilteredData] = useState([]),
    handleFilter = e => {
      const value = e.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      setSearchValue(value);
      let updatedData = []


      if (value?.length) {
        const updatedData = GestionesXAprobador && GestionesXAprobador?.filter(item => {
          const correlativo = item?.correlativo?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          const descripcion = item?.descripcion?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          const fechaPedido = item?.fechaSolicitada2?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          const estado = item?.estado?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          const siguientePaso = item?.actividadGestion?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");



          return correlativo?.includes(value) || descripcion?.includes(value) || fechaPedido?.includes(value) || estado?.includes(value) || siguientePaso?.includes(value);
        });

        setFilteredData(updatedData)
        setSearchValue(value)
      }
      if (value === '') {
        setFilteredData([])
      }
    },
    MuestraPDF = async (data) => {
      const doc = await Imprimir(data)
      setPdf(doc)
    },
    Columns = [
      {
        name: 'Correlativo',
        column: 'correlativo',
        sortable: true,
        center: false,
        grow: 2,
        cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '13px' }}>{row?.correlativo}</div> // Esto asegura que el contenido no se trunca
      },
      {
        name: 'Solicitante',
        column: 'correlativo',
        sortable: true,
        center: false,
        grow: 2,
        cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '13px' }}>{row?.solicitantePersona}</div> // Esto asegura que el contenido no se trunca
      },
      {
        name: 'Asignado a la Gestión',
        column: 'fechaUso4',
        sortable: true,
        center: false,
        grow: 2, // Ajusta el valor según sea necesario para dar más espacio a la columna
        wrap: true,
        cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '11px' }}>{row?.personasAsignadas}</div> // Esto asegura que el contenido no se trunca
      },
      {
        name: 'Fecha de Solicitud',
        column: 'fechaPedido2',
        sortable: true,
        center: false,
        grow: 2,
        cell: row => CustomText(row['fechaPedido2'])
      },
      {
        name: 'Tipo de Gestión',
        column: 'actividadGestion',
        sortable: true,
        center: false,
        grow: 3, // Ajusta el valor según sea necesario para dar más espacio a la columna
        wrap: true,
        cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '11px' }}>{row?.actividadGestion}</div> // Esto asegura que el contenido no se trunca
      },
      // {
      //   name: 'Justificación',
      //   column: 'justificacion',
      //   sortable: true,
      //   grow: 7, // Ajusta el valor según sea necesario para dar más espacio a la columna
      //   wrap: true,
      //   cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row?.justificacion}</div> // Esto asegura que el contenido no se trunca

      // },
      // {
      //   name: 'Año',
      //   column: 'año',
      //   sortable: true,
      //   center: true,
      //   cell: row => CustomText(row['año'])
      // },
      {
        name: 'Estado',
        column: 'estado',
        sortable: true,
        center: true,
        grow: 2,
        // width: '20%',
        cell: row => <EstadoText row={row} />
      },
      // {
      //   name: 'Siguiente Paso',
      //   column: 'siguientePaso',
      //   sortable: true,
      //   center: true,
      //   grow: 7, // Ajusta el valor según sea necesario para dar más espacio a la columna
      //   wrap: true,
      //   width: '22%',
      //   cell: row => <SiguientePasoText row={row} />
      // },
      {
        name: 'Acciones',
        column: 'id',
        sortable: true,
        grow: 1,
        center: true,
        // width: '10%',
        cell: row => (
          <UncontrolledButtonDropdown direction='start'>

            <DropdownToggle className='pr-1' tag='span'>
              <Icon.MoreVertical size={15} />
            </DropdownToggle>
            <DropdownMenu className="!m-0"  >

              {
                // Operadores de Transporte
                row?.idActividadGestion === 1 ?

                  <>
                    <DropdownItem className="w-100" onClick={() => [One(row, 2), setAccion(row.idAccion)]}>
                      <Icon.Edit className="me-2" size={15} />
                      <span className='align-middle ml-50'>{ 'Ver Gestión'}</span>
                    </DropdownItem>
                  </>
                  : null
              }

              {
                // Operadores de Vehiculos (los mismos de transportes solo COG)
                row?.idActividadGestion === 2 ?
                  <>
                    <DropdownItem className="w-100" onClick={() => [One(row, 2), setAccion(row.idAccion)]}>
                      <Icon.Edit className="me-2" size={15} />
                      <span className='align-middle ml-50'>{ 'Ver Gestión'}</span>
                    </DropdownItem>
                  </>
                  : null
              }

              {
                // Operadores de SOPORTE (los mismos de transportes solo COG)
                row?.idActividadGestion === 3 ?
                  <>
                    <DropdownItem className="w-100" onClick={() => [One(row, 2), setAccion(row.idAccion)]}>
                      <Icon.Edit className="me-2" size={15} />
                      <span className='align-middle ml-50'>{ 'Ver Gestión'}</span>
                    </DropdownItem>
                  </>
                  : null
              }

              {
                // Operadores de Prestamo de Equipos
                row?.idActividadGestion === 7 ?
                  <>
                    <DropdownItem className="w-100" onClick={() => [One(row, 2), setAccion(row.idAccion)]}>
                      <Icon.Edit className="me-2" size={15} />
                      <span className='align-middle ml-50'>{'Ver Gestión'}</span>
                    </DropdownItem>
                  </>
                  : null
              }

              
              {
                ((row.idEstado === 1)) ? null :
                  <DropdownItem className="w-100" onClick={() => [BitacoraPedido(row, 6), setIdPresupuestoPoa(row.id)]}>
                    <Icon.GitPullRequest className="me-2" size={15} />
                    <span className='align-middle ml-50'>Ver Proceso</span>
                  </DropdownItem>
              }
             
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        )
      }
    ];

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const Decript = bf.decrypt(bf.base64Decode(localStorage.getItem('authUser')));
      const data = JSON.parse(Decript.replace(/\0/g, ''));
      // const obj = JSON.parse(localStorage.getItem("authUser"));
      setRoles(data.roles);
    }
  }, [props.success]);

  return (
    <Fragment>
      <Row className='justify-content-end mx-0'>
        <Col>

        </Col>
        <Col className='justify-content-end mt-1' md='4' sm='12'>
          <FormGroup>
            <Input
              className='dataTable-filter mb-50'
              type='text'
              bsSize='md'
              placeholder={`Buscar`}
              id='search-input'
              value={searchValue}
              onChange={handleFilter}
            />
          </FormGroup>
        </Col>
      </Row>
      <div className='card'>
        <DataTable
          // dense
          striped
          noHeader
          highlightOnHover
          // expandableRows expandableRowsComponent={ExpandedComponent}
          pagination
          theme={theme}
          data={searchValue.length ? filteredData : (GestionesXAprobador && GestionesXAprobador.value !== 2009 ? GestionesXAprobador : [])}
          columns={Columns}
          className='table-responsive mt-4'
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          paginationPerPage={100}
          paginationComponentOptions={
            {
              rowsPerPageText: '',
              rangeSeparatorText: ''
            }
          }
          noDataComponent='Sin Registros'
        />
      </div>
    </Fragment>
  )
}

export default List