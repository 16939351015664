import React, { useState } from 'react'
import { Row, Col, Card } from 'reactstrap'
import { ContentProvider } from './context'
import Formulario from './form'
import List from './list'

const Index = () => {

    return (
        <ContentProvider>
            <div>
                <Row>
                    <Col>
                        <div className="h5 font-weight-bolder text-uppercase px-3 mt-2" >
                            Referencias
                        </div>
                    </Col>
                    <Col className="d-flex justify-content-end" lg="2" sm="2">
                        <Formulario />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <List />
                    </Col>
                </Row>
            </div>
        </ContentProvider >
    )
}

export default Index