import React, { useState, Fragment, useContext } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback } from 'reactstrap'
import { ContentContext } from '../context'
import * as Icon from 'react-feather'

export const PDF = () => {
    const { oneData } = useContext(ContentContext);
    const [show, setShow] = useState(false),
        showModal = async () => {
            await setShow(true)
            // volver()
        }

    return (
        <Fragment>
            <span className="dropdown-item" onClick={() => [showModal()]}>
                <Icon.FileText size={14} /> {"Ver Adjunto"}
            </span>
            <Modal
                isOpen={show}
                backdrop='static'
                size="llg"
                className='modal-dialog-centered'
            >
                <ModalHeader >
                    <span className='h1 fw-bolder'>{"Visualizar"}</span>
                </ModalHeader>
                <ModalBody>
                    
                        <div className="d-flex justify-content-around">
                            {
                                (oneData?.extension === '.pdf')
                                    ?
                                    <object className='Visor text-center' width="100%" height="750px" id="pdf-ficha" aria-labelledby="perfil" type="application/pdf" data={`data:application/pdf;base64,${encodeURI(oneData?.archivo)}`} />
                                    :
                                    <img src={`data:image/${oneData?.extension?.replace('.', '')};base64,${oneData?.archivo}`} width="100%" height="100%" className=" text-center" alt="Cotización" />
                            }
                        </div>
                   
                </ModalBody>
                <ModalFooter>
                    <Button variant='secondary'  onClick={() => setShow(false)}>
                        <Icon.ArrowLeft size={16} /> {'Salir'}
                    </Button>
                    <a className='btn btn-danger ' download={`${oneData?.extension === '.pdf' ? 'adjunto.pdf' : 'adjunto.jpg'}`} href={`${oneData?.extension === '.pdf' ? `data:application/pdf;base64,${encodeURI(oneData?.archivo)}` : `data:image/${oneData?.extension?.replace('.', '')};base64,${oneData?.archivo}`}`}>
                        <Icon.Download size={16} /> {'Descargar'}
                    </a>
                </ModalFooter>
            </Modal>
        </Fragment>
    )
}
export default PDF