import React, { Fragment, useState, useContext } from 'react'
import DataTable from 'react-data-table-component'
import { Row, Col } from 'reactstrap'
import * as Icon from 'react-feather'
import { CustomText } from '../../../CustomList/index'
import { ContentContext } from './context'
import { ContentContext as ContentContextTheme } from '../../../components/CommonForBoth/TopbarDropdown/context'

const List = () => {

  const { allDataAsignaciones : allData, One, Actions, oneData, DestroyAsignacion } = useContext(ContentContext)
  const { theme } = useContext(ContentContextTheme)
  const [searchValue, setSearchValue] = useState(''),
    [filteredData, setFilteredData] = useState([]),
    handleFilter = e => {
      const value = e.target.value
      let updatedData = []
      setSearchValue(value)

      if (value.length) {
        updatedData = allData && allData.value !== 2009 && allData.filter(item => {
          const startsWith =
            item.nombre.toLowerCase().startsWith(value.toLowerCase())

          const includes =
            item.nombre.toLowerCase().startsWith(value.toLowerCase())

          if (startsWith) {
            return startsWith
          } else if (!startsWith && includes) {
            return includes
          } else return null
        })
        setFilteredData(updatedData)
        setSearchValue(value)
      }
    },
    Columns = [
      {
        name: 'Persona',
        column: 'persona',
        grow: 2, // Ajusta el valor según sea necesario para dar más espacio a la columna
        wrap: true,
        cell: row => CustomText(row['persona'])
      },
      {
        name: 'Vehiculo',
        column: 'vehiculo',
        grow: 2, // Ajusta el valor según sea necesario para dar más espacio a la columna
        wrap: true,
        cell: row => CustomText(row['vehiculo'])
      },
      {
        name: 'Observación',
        column: 'comentario',
        center: false,
        grow: 2, // Ajusta el valor según sea necesario para dar más espacio a la columna
        wrap: true,
        cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '10px' }}>{row?.comentario}</div> // Esto asegura que el contenido no se trunca

      },
      // {
      //   name: 'Año',
      //   column: 'año',
      //   sortable: true,
      //   center: true,
      //   width: '85px',
      //   cell: row => CustomText(row['año'])
      // },
      // {
      //   name: 'Estado',
      //   column: 'estado',
      //   sortable: true,
      //   center: true,
      //   width: '150px',
      //   cell: row => <EstadoLista row={row} />
      // },
      {
        name: "Eliminar",
        sortable: true,
        center: true,
        cell: (row) =>
        (<>

                <span
                    className="text-center cursor-pointer text-danger"
                    onClick={() => DestroyAsignacion(row)}
                >
                    <Fragment>
                        <Icon.Trash2 size={14} className="mx-4" />
                    </Fragment>
                </span>
        </>)
    }
      // {
      //   name: 'Acciones',
      //   column: 'id',
      //   sortable: true,
      //   center: true,
      //   width: '150px',
      //   cell: row => (
      //     <UncontrolledButtonDropdown style={{ margin: 0 }} >

      //       <DropdownToggle style={{ margin: 0 }} className='pr-1' tag='span'>
      //         <Icon.MoreVertical size={15} />
      //       </DropdownToggle>
      //       <DropdownMenu className="!m-0"style={{ margin: 0 }} >
      //         {/* 
      //         {
      //           row.estado === 1 &&
      //           <DropdownItem className="w-100" onClick={() => One(row, 3)}>
      //             <Icon.Edit className="me-2" size={15} />
      //             <span className='align-middle ml-50'>Actualizar</span>
      //           </DropdownItem>
      //         } */}

      //         <DropdownItem className="w-100" onClick={() => Actions(row)}>
      //           {row.estado === 1 ? <Icon.Trash className="me-2" size={15} /> : <Icon.Check className="me-2" size={15} />}
      //           <span className='align-middle ml-50'>{row.estado === 1 ? 'Desactivar' : 'Activar'}</span>
      //         </DropdownItem>
      //       </DropdownMenu>
      //     </UncontrolledButtonDropdown>
      //   )
      // }
    ]

  return (
    <Fragment>
      <Row className='justify-content-end mx-0'>
        <Col>

        </Col>

      </Row>
      <DataTable
        noHeader
        highlightOnHover
        pagination
        theme={theme}
        data={searchValue.length ? filteredData : (allData && allData.value !== 2009 ? allData : [])}
        columns={Columns}
        className='table-responsive mt-4'
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        paginationComponentOptions={
          {
            rowsPerPageText: '',
            rangeSeparatorText: ''
          }
        }
        noDataComponent='Sin Registros'
      />
    </Fragment>
  )
}

export default List