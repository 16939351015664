import React, { createContext, useState } from 'react'
import { GetRoute, PostRoute } from '../services/Private'
import { useRequest } from '../hooks/useRequestSWR'
import { useSWRConfig } from 'swr'

export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    const nameController = 'Theme';
    const [theme, setTheme] = useState('light'),
    // { data: allData } = useRequest(`${nameController}/all`, 'POSTSWR', { } ),
        value = {
            setTheme,
            theme,
            // allData
        }

    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}

