import React, { Fragment, useState, useContext } from 'react'
import DataTable from 'react-data-table-component'
import { Row, Col, FormGroup, Input, UncontrolledButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap'
import * as Icon from 'react-feather'
import { CustomText, EstadoLista } from '../../../CustomList/index'
import { ContentContext } from './context'
import { ContentContext as ContentContextTheme } from '../../../components/CommonForBoth/TopbarDropdown/context'

const List = () => {

  const { allDataAsignaciones: allData, One, Actions, oneData, DestroyAsignacion, roles } = useContext(ContentContext)
  const { theme } = useContext(ContentContextTheme)
  const [searchValue, setSearchValue] = useState(''),
    [filteredData, setFilteredData] = useState([]),
    Columns = [
      {
        name: 'Persona',
        column: 'persona',
        grow: 2, // Ajusta el valor según sea necesario para dar más espacio a la columna
        wrap: true,
        cell: row => CustomText(row['persona'])
      },
      {
        name: 'Instrucción',
        column: 'comentario',
        grow: 2, // Ajusta el valor según sea necesario para dar más espacio a la columna
        wrap: true,
        cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '13px' }}>{row?.comentario === null ? 'Sin Comentario' : row?.comentario}</div> // Esto asegura que el contenido no se trunca
      },
      ((roles?.includes('49366')) ?
        {
          name: "Eliminar",
        sortable: true,
        center: true,
        cell: (row) =>
        (<>

          <span
            className="text-center cursor-pointer text-danger"
            onClick={() => DestroyAsignacion(row)}
          >
            <Fragment>
              <Icon.Trash2 size={14} className="mx-4" />
            </Fragment>
          </span>
        </>)
        }
        : []),
      
    ]

  return (
    <Fragment>
      <Row className='justify-content-end mx-0'>
        <Col>

        </Col>

      </Row>
      <DataTable
        noHeader
        highlightOnHover
        pagination
        theme={theme}
        data={searchValue.length ? filteredData : (allData && allData.value !== 2009 ? allData : [])}
        columns={Columns}
        className='table-responsive mt-4'
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        paginationComponentOptions={
          {
            rowsPerPageText: '',
            rangeSeparatorText: ''
          }
        }
        noDataComponent='Sin Registros'
      />
    </Fragment>
  )
}

export default List