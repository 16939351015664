import React, { createContext, useState, useEffect, useContext } from 'react'
import { GetRoute, PostRoute } from '../../../services/Private'
// import { Notification } from '../../../components/notify/index'
// import { LoadContext } from '../../../utility/context/loadingContext'
import { OptionsToast } from '../../../Globales/index'
import { useRequest } from '../../../hooks/useRequestSWR'
import { toast } from 'react-toastify'

export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
  const { data: dataRoles } = useRequest(`rol/all`, 'GET');
  const { data: dataEmpleados } = useRequest(`usuario/all`, 'GET');
  // const { data: dataGetRoles } = useRequest(`usuario/get-roles`,'POST',{id: 12} );
  const [empleado, setEmpleado] = useState([]),
    [misRoles, setMisRoles] = useState([]),
    [rolUsuario, setRolUsuario] = useState([]),
    [dataUser, setDataUser] = useState([]),
    [showRoles, setShowRoles] = useState(false),
    getRolUsuario = async (data) => {
      
      setShowRoles(false)
      setMisRoles([])
      // const { data: response } = useRequest(`usuario/get-roles`,'POST',{id: data.persona} );
      const response = await PostRoute(`usuario/get-roles`, { id: data.persona })
      setEmpleado(data)
      setDataUser((response[0]) ? response[0] : [])
      setRolUsuario((response[0] && response[0].roles !== null) ? JSON.parse(response[0].roles) : [])
      setMisRoles((response[0] && response[0].roles !== null) ? JSON.parse(response[0].roles) : [])
      setShowRoles(true)
      
    },
    sendRolesUsuario = async (data, tipo) => {
      
      const response = await PostRoute(`usuario/asignar-rol`, { id: empleado.usuario, persona: empleado.persona, roles: JSON.stringify(data) })
      //   setRolUsuario((response.roles)?JSON.parse(response.roles):[])
      setRolUsuario(misRoles)
      
      if (tipo) {
        // Notification(response.message, 1)
        toast.success(response.message, OptionsToast)
      } else {
        // Notification(response.message, 2)
        toast.error(response.message, OptionsToast)
      }
    },
    defaultPassword = async () => {
      
      const response = await PostRoute(`usuario/reset-password`, { id: dataUser.id })
      setRolUsuario((response.roles) ? JSON.parse(response.roles) : [])
      toast.success(response.message, OptionsToast)
      
    },
    sendCredencialesUsuario = async () => {
      
      const response = await PostRoute(`usuario/credential-notify`, { id: dataUser.id })
      setRolUsuario((response.roles) ? JSON.parse(response.roles) : [])
      toast.success(response.message, OptionsToast)
      
    },

    value = {
      dataRoles,
      empleado,
      misRoles,
      dataUser,
      dataEmpleados,
      showRoles,
      rolUsuario,
      // setShowLoad,
      setMisRoles,
      setShowRoles,
      getRolUsuario,
      defaultPassword,
      sendRolesUsuario,
      sendCredencialesUsuario
    }

  // useEffect(
  //   () => {
  //     GetPersonas()
  //     // getRoles()
  //   }, []
  // )

  return (
    <ContentContext.Provider value={value}>
      {children}
    </ContentContext.Provider>
  )
}

