import React, { useState, useContext, useEffect } from "react";
import Moment from 'react-moment';
import 'moment/locale/es-mx';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { ContentContext } from './context';
import avatar3 from "../../../assets/images/COG11.jpg";
import { withTranslation } from "react-i18next";

const NotificationDropdown = props => {
  const [roles, setRoles] = useState([]);
  const Blowfish = require('javascript-blowfish');
  const bf = new Blowfish(process.env.REACT_APP_CONFIG_KEY);
  const [menu, setMenu] = useState(false);
  const { AllDataFilter, NotificacionesFilter, notificacionesSolInsumoUserFilter, NotificationActions, GestionesSolicitantesXUnidad, GestionesXAprobador, PedidosSolicitantes, PedidosXAprobador, ModificacionesSolicitantes, ModificacionesXAprobador } = useContext(ContentContext);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const Decript = bf.decrypt(bf.base64Decode(localStorage.getItem('authUser')));
      const data = JSON.parse(Decript.replace(/\0/g, ''));
      setRoles(data.roles);
    }
  }, [props.success]);

  // Consolidar todas las notificaciones en un solo array
  const allNotifications = [
    ...(AllDataFilter?.value !== 2009 && AllDataFilter?.value !== 7001 && AllDataFilter || []),
    ...(NotificacionesFilter?.value !== 2009 && NotificacionesFilter?.value !== 7001 && NotificacionesFilter || []),
    ...(notificacionesSolInsumoUserFilter?.value !== 2009 && notificacionesSolInsumoUserFilter?.value !== 7001 && notificacionesSolInsumoUserFilter || []),
    ...(GestionesSolicitantesXUnidad?.value !== 2009 && GestionesSolicitantesXUnidad?.value !== 7001 && GestionesSolicitantesXUnidad || []),
    ...(GestionesXAprobador?.value !== 2009 && GestionesXAprobador?.value !== 7001 && GestionesXAprobador || []),
    ...(PedidosSolicitantes?.value !== 2009 && PedidosSolicitantes?.value !== 7001 && PedidosSolicitantes || []),
    ...(PedidosXAprobador?.value !== 2009 && PedidosXAprobador?.value !== 7001 && PedidosXAprobador || []),
    ...(ModificacionesSolicitantes?.value !== 2009 && ModificacionesSolicitantes?.value !== 7001 && ModificacionesSolicitantes || []),
    ...(ModificacionesXAprobador?.value !== 2009 && ModificacionesXAprobador?.value !== 7001 && ModificacionesXAprobador || [])
  ];

  // Calcular la longitud total de las notificaciones
  const totalNotifications = allNotifications?.length;

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle style={{ margin: 0, top: 0, position: 'relative' }}
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          {/* Efecto de Notificacion */}
          <i className={totalNotifications > 0 ? `bx bx-bell bx-tada` : `bx bx-bell`} />
          {/* Contador de Notificaciones */}
          {totalNotifications > 0 ? <span className="badge bg-danger rounded-pill">{totalNotifications}</span> : null}
        </DropdownToggle>

        <DropdownMenu style={{ margin: 0 }} className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 !m-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notificaciones")} </h6>
              </Col>
            </Row>
          </div>
          <SimpleBar style={{ height: "50vh" }}>
            {roles.includes('6B1D5') && AllDataFilter && AllDataFilter?.length > 0 && AllDataFilter?.map((item, index) => (
              <Link to="/poa/plan-operativo-anual" className="text-reset notification-item" key={index}>
                <div className="d-flex">
                  <div className="avatar-xs me-3 text-center">
                    <span className=" rounded-circle font-size-10 text-center">
                      <img
                        src={avatar3}
                        // style={{ width: '10vw', height: '3vh' }}
                        className="me-3 rounded-circle avatar-xs"
                      />
                    </span>
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1">
                      {props.t("POA ")} {item.poa} {props.t("creado!")}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {props.t("Gestiónalo dando clic aquí")}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />{" "}
                        <Moment locale="es" fromNow>{item.creado}</Moment>
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}

            {roles.includes('74AFF') && NotificacionesFilter && NotificacionesFilter?.length > 0 && NotificacionesFilter?.map((item, index) => (
              <Link to="/insumos/gestion-insumos#solicitudes" className="text-reset notification-item" key={index} onClick={() => NotificationActions(item)}>
                <div className="d-flex">
                  <div className="avatar-xs me-3 text-center">
                    <span className=" rounded-circle font-size-10 text-center">
                      <img
                        src={avatar3}
                        // style={{ width: '10vw', height: '3vh' }}
                        className="me-3 rounded-circle avatar-xs"
                      />
                    </span>
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1">
                      {props.t(item?.nombre)}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {props.t("Gestiónalo dando clic aquí")}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />{" "}
                        <Moment locale="es" fromNow>{item.creado}</Moment>
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}

            {roles.includes('30D7E') && notificacionesSolInsumoUserFilter && notificacionesSolInsumoUserFilter?.length > 0 && notificacionesSolInsumoUserFilter?.map((item, index) => (
              <Link to="/insumos/solicitud" className="text-reset notification-item" key={index} onClick={() => NotificationActions(item)}>
                <div className="d-flex">
                  <div className="avatar-xs me-3 text-center">
                    <span className=" rounded-circle font-size-10 text-center">
                      <img
                        src={avatar3}
                        // style={{ width: '10vw', height: '3vh' }}
                        className="me-3 rounded-circle avatar-xs"
                      />
                    </span>
                  </div>
                  <div className="flex-grow-4">
                    <h6 className="mt-0 mb-1">
                      {props.t(item?.nombre)}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {props.t("Verifica tu solicitud dando clic aquí")}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />{" "}
                        <Moment locale="es" fromNow>{item.creado}</Moment>
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}

            {GestionesXAprobador && GestionesXAprobador.length > 0 && GestionesXAprobador.map((item, index) => (
              <Link to="/gestiones/aprobar-gestiones" className="text-reset notification-item" key={index}>
                <div className="d-flex" style={{ width: 'content-fit' }}>
                  <div className="avatar-xs me-3 text-center">
                    <span className=" rounded-circle font-size-10 text-center">
                      <img
                        src={avatar3}
                        // style={{ width: '10vw', height: '3vh' }}
                        className="me-3 rounded-circle avatar-xs"
                      />
                    </span>
                  </div>
                  <div className="flex-grow-4">
                    <h6 className="mt-0 mb-1" style={{ whiteSpace: 'normal', textAlign: 'left', fontSize: '13px' }}>
                      {(item?.idEstado === 3 && 'Solicitud Validada por Transportes') ||
                        (item?.idEstado === 7 && 'Solicitud Aprobada por Transportes') ||
                        (item?.idEstado === 4 && 'Solicitud Validada por SNLD') ||
                        (item?.idEstado === 5 && 'Solicitud con VoBo') ||
                        (item?.idEstado === 10 && 'Nueva Solicitud') ||
                        (item?.idEstado === 11 &&  'Solicitud Finalizada') ||
                        (item?.idEstado === 23 &&  'Se solicitó anular la gestión!') ||
                        (item?.idEstado === 8 && item?.idActividadGestion === 7 && 'Preparar Equipo') ||
                        (item?.idEstado === 18 && item?.idActividadGestion === 7 && 'Firmar Formulario y entregar equipo a prestar') ||
                        (item?.idEstado === 18 && item?.idActividadGestion === 2 && 'Recibir Formulario y marcar vehículo como prestado') ||
                        (item?.idEstado === 19 && item?.idActividadGestion === 7 && 'Recibir Equipo y marcar como devuelto') ||
                        (item?.idEstado === 8 && (item?.idActividadGestion === 1 || item?.idActividadGestion === 12)&& 'Gestión aprobada y asignada por Jefatura de transportes, dar VoBo') ||
                        (item?.idEstado === 8 && (item?.idActividadGestion === 3 )&& 'Gestión Asignada, Atender') ||
                        (item?.idEstado === 24 && 'Gestión en proceso, firmar formulario y marcar finalizar, cuando culmine solicitud') ||
                        (item?.idEstado === 2 && 'Solicitud Rechazada')}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {props.t(`La solicitud ${item?.correlativo} requiere atención`)}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />{" "}
                        <Moment locale="es" fromNow>{item?.creadoMoment}</Moment>
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}

            {GestionesSolicitantesXUnidad && GestionesSolicitantesXUnidad.length > 0 && GestionesSolicitantesXUnidad.map((item, index) => (
              <Link to="/gestiones/solicitud" className="text-reset notification-item" key={index}>
                <div className="d-flex" style={{ width: 'content-fit' }}>
                  <div className="avatar-xs me-3 text-center">
                    <span className=" rounded-circle font-size-10 text-center">
                      <img
                        src={avatar3}
                        // style={{ width: '10vw', height: '3vh' }}
                        className="me-3 rounded-circle avatar-xs"
                      />
                    </span>
                  </div>
                  <div className="flex-grow-4">
                    <h6 className="mt-0 mb-1">
                      {(item?.idEstado === 11 && 'Gestión Finalizada') ||
                        (item?.idEstado === 3 && 'Gestión Validada por Transportes') ||
                        (item?.idEstado === 7 && 'Gestión Aprobada por Transportes') ||
                        (item?.idEstado === 4 && 'Gestión Validada por SNLD') ||
                        (item?.idEstado === 10 && 'Nueva Gestión') ||
                        (item?.idEstado === 2 && 'Gestión Rechazada') ||
                        (item?.idEstado === 17 && 'Equipo autorizado, imprimir y firmar') ||
                        (item?.idEstado === 14 && 'Gestión Finalizada, favor responder encuesta de satisfacción!') ||
                        (item?.idEstado === 20 && 'Gestión Finalizada, favor responder encuesta de satisfacción!') ||
                        // (item?.idEstado === 8 && item?.idActividadGestion === 2 && 'Gestión Asignada, favor de imprimir PDF y entregar firmado a transportes!') ||
                        (item?.idEstado === 5 && 'Gestión con VoBo, favor de imprimir PDF y entregar firmado a transportes!')}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {props.t(`La Gestión ${item?.correlativo} requiere atención`)}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />{" "}
                        <Moment locale="es" fromNow>{item?.creadoMoment}</Moment>
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}

            {PedidosSolicitantes && PedidosSolicitantes.length > 0 && PedidosSolicitantes.map((item, index) => (
              <Link to="/pedidos/pedido" className="text-reset notification-item" key={index}>
                <div className="d-flex" style={{ width: 'content-fit' }}>
                  <div className="avatar-xs me-3 text-center">
                    <span className=" rounded-circle font-size-10 text-center">
                      <img
                        src={avatar3}
                        // style={{ width: '10vw', height: '3vh' }}
                        className="me-3 rounded-circle avatar-xs"
                      />
                    </span>
                  </div>
                  <div className="flex-grow-4">
                    <h6 className="mt-0 mb-1">
                      {(item?.idEstado === 21 && 'Imprimir la solicitud para continuar con el proceso!') ||
                        (item?.idEstado === 2 && 'Solicitud Rechazada') ||
                        (item?.idEstado === 65 && 'Se solicitan doctos. de soporte para liquidación') ||
                        (item?.idEstado === 1 && 'Solicitud Anulada')}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {props.t(`La solicitud ${item?.correlativo} requiere atención`)}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />{" "}
                        <Moment locale="es" fromNow>{item?.creadoMoment}</Moment>
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}

            {PedidosXAprobador && PedidosXAprobador.length > 0 && PedidosXAprobador.map((item, index) => (
              <Link to="/pedidos/aprobar-pedido" className="text-reset notification-item" key={index}>
                <div className="d-flex" style={{ width: 'content-fit' }}>
                  <div className="avatar-xs me-3 text-center">
                    <span className=" rounded-circle font-size-10 text-center">
                      <img
                        src={avatar3}
                        // style={{ width: '10vw', height: '3vh' }}
                        className="me-3 rounded-circle avatar-xs"
                      />
                    </span>
                  </div>
                  <div className="flex-grow-4">
                    <h6 className="mt-0 mb-1">
                      {/* {(item?.idEstado === 25 && 'Revisar Solicitud') ||
                        (item?.idEstado === 3 && 'Aprobar solicitud Unidad') ||
                        (item?.idEstado === 20 && 'Solicitud Aprobada por Almacén') ||
                        (item?.idEstado === 4 && 'Solicitud Aprobada por Unidad') ||
                        (item?.idEstado === 5 && 'Solicitud Aprobada por SORF') ||
                        (item?.idEstado === 58 && item?.modalidad !== 3 && 'Solicitud impresa pendiente de recepcion en compras') ||
                        (item?.idEstado === 58 && item?.modalidad === 3 && 'Solicitud impresa pendiente de recepcion en Caja Chica') ||
                        (item?.idEstado === 23 && 'Solicitud Asignada') ||
                        (item?.idEstado === 39 && 'Proceso de Cotización de Solicitud') ||
                        (item?.idEstado === 41 && 'Recibir Expediente de Solicitud') ||
                        (item?.idEstado === 42 && 'Ingresar CUR') ||
                        (item?.idEstado === 43 && 'Aprobar CUR') ||
                        (item?.idEstado === 44 && 'Recibir Expediente de Solicitud') ||
                        (item?.idEstado === 45 && 'Inicia Proceso de Liquidación') ||
                        (item?.idEstado === 57 && 'Nueva solicitud de anulación de proceso de compras') ||
                        (item?.idEstado === 10 && 'Nueva Solicitud') ||
                        (item?.idEstado === 11 && 'Solicitud Finalizada') ||
                        
                        
                        (item?.idEstado === 2 && 'Solicitud Rechazada')} */}

{(item?.modalidad === 2 && item?.idEstadoLiquidado === 71) && 'Solicitud reintegro recibida' ||
(item?.modalidad === 2 && item?.idEstadoLiquidado === 72) && 'Reintegro operado, ingrese No. de 1D' ||
(item?.modalidad === 2 && item?.idEstado === 75) && 'Modificar CUR DEV por Reintegro' ||
(item?.modalidad === 2 && item?.idEstado === 73) && 'Modificar CUR COM por Reintegro' ||
(item?.modalidad === 2 && item?.idEstado === 74) && 'Enviar a Archivo por Reintegro' ||
                      item?.estado}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {props.t(`La solicitud ${item?.correlativo} requiere atención`)}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />{" "}
                        <Moment locale="es" fromNow>{item?.creadoMoment?.trim()}</Moment>
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}

            {ModificacionesSolicitantes && ModificacionesSolicitantes.length > 0 && ModificacionesSolicitantes.map((item, index) => (
              <Link to="/pedidos/modificacion-presupuestaria" className="text-reset notification-item" key={index}>
                <div className="d-flex">
                  <div className="avatar-xs me-3 text-center">
                    <span className=" rounded-circle font-size-10 text-center">
                      <img
                        src={avatar3}
                        // style={{ width: '10vw', height: '3vh' }}
                        className="me-3 rounded-circle avatar-xs"
                      />
                    </span>
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1">
                      {
                        (item?.idEstado === 5 && 'Solicitud Operada') ||
                        (item?.idEstado === 4 && 'Puede imprimir su solicitud de modificación presupuestaria') ||
                        // (item?.idEstado === 2 && 'Solicitud de Modificación Presupuestaria recibida') ||
                        // (item?.idEstado === 3 && 'Solicitud Aprobada por Planificación') ||
                        (item?.idEstado === 1 && 'Solicitud Rechazada')}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {props.t(`La solicitud ${item?.correlativo} requiere atención`)}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />{" "}
                        <Moment locale="es" fromNow>{item?.creadoMoment}</Moment>
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}

            {ModificacionesXAprobador && ModificacionesXAprobador.length > 0 && ModificacionesXAprobador.map((item, index) => (
              <Link to="/pedidos/aprobar-modificacion-presupuestaria" className="text-reset notification-item" key={index}>
                <div className="d-flex">
                  <div className="avatar-xs me-3 text-center">
                    <span className=" rounded-circle font-size-10 text-center">
                      <img
                        src={avatar3}
                        // style={{ width: '10vw', height: '3vh' }}
                        className="me-3 rounded-circle avatar-xs"
                      />
                    </span>
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1">
                      {
                        (item?.idEstado === 5 && 'Solicitud Operada') ||
                        (item?.idEstado === 4 && 'Solicitud Aprobada por SORF') ||
                        (item?.idEstado === 2 && 'Solicitud de modificación presupuestaria recibida') ||
                        (item?.idEstado === 3 && 'Solicitud Aprobada por Planificación') ||
                        (item?.idEstado === 1 && 'Solicitud Rechazada')}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {props.t(`La solicitud ${item?.correlativo} requiere atención`)}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />{" "}
                        <Moment locale="es" fromNow>{item?.creadoMoment}</Moment>
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
            {
              totalNotifications === 0 ?
                <> <span className="d-flex justify-content-center text-center text-primary pt-5 fs-3" >
                  {"Sin Notificaciones pendientes! "}
                  < br />
                </span>
                  <p className="d-flex justify-content-center text-center fs-1">{"😉💯"}</p>
                </>
                : null
            }
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};