import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback } from 'reactstrap'
import { ContentContext } from './context'
import * as Icon from 'react-feather'
import { useForm, Controller } from "react-hook-form"
import Select from 'react-select'
import { customStyles } from 'Globales/index'
import Attached from './Adjunto/Attached'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";
import "/node_modules/swiper/swiper.scss";
import Moment from 'react-moment';
import 'moment/locale/es-mx';

const Formulario = () => {
    const { toggleModal, modal, StoreUpdate, opcion, oneData, nameComponent, setIdSolicitud, idSolicitud, roles, Actions2 } = useContext(ContentContext),
        { handleSubmit, formState: { errors }, setValue, control, reset } = useForm(),
        [tab, setTab] = useState(1),
        TabActive = (No) => {
            setTab(No)
        },
        onSubmit = (data) => {
            const json = {
                id: (oneData) ? oneData.id : null,
                nombre: data.nombre,
                caracteristicas: data.caracteristicas,
                presentacion: data.presentacion,
                unidadMedida: data.unidadMedida,
                link: data.link
            }
            StoreUpdate(json)
        },
        onSubmit2 = (data) => {
            const json = {
                id: (oneData) ? oneData.id : null,
                estado: data.estado.value
            }
            Actions2(json)
        },
        setData = async () => {
            await setValue('nombre', oneData?.nombre)
            await setValue('caracteristicas', oneData?.caracteristicas)
            await setValue('presentacion', oneData?.presentacion)
            await setValue('unidadMedida', oneData?.unidadMedida)
            await setValue('link', oneData?.link)
        },
        clearData = async () => {
            reset({ nombre: '', caracteristicas: '', presentacion: '', unidadMedida: '', link: '' })
            setIdSolicitud(0)
        }

    useEffect(
        () => {
            async function fetchMyAPI() {
                if (await opcion > 1 && oneData) { await setData() }
                if (await opcion === 1 || await opcion === 4) { clearData() }
            }

            fetchMyAPI()

        }, [opcion, oneData]
    )
    const statuses = [
        {
            id: 1,
            stausTitle: "Ordered",
            iconClass: "bx-copy-alt",
            description: "New common language will be more simple and regular than the existing.",
        },
        {
            id: 2,
            stausTitle: "Packed",
            iconClass: "bx-package",
            description:
                "To achieve this, it would be necessary to have uniform grammar.",
        },
        {
            id: 3,
            stausTitle: "Shipped",
            iconClass: "bx-car",
            description:
                "It will be as simple as Occidental in fact, it will be Occidental..",
        },
        {
            id: 4,
            stausTitle: "Delivered",
            iconClass: "bx-badge-check",
            description:
                "To an English person, it will seem like simplified English.",
        },
        {
            id: 5,
            stausTitle: "developer",
            iconClass: "bx-badge-check",
            description:
                "To an English person, it will seem like simplified English.",
        },
    ];

    const optionsStatus = [
        { value: 2, label: 'En Proceso' },
        { value: 3, label: 'Asignado' },
        { value: 4, label: 'Insumo Cargado' }
    ];
    return (
        <Fragment>
            {opcion === 4 &&
                <>
                    <Modal
                        isOpen={modal}
                        backdrop='static'
                        toggle={() => toggleModal()}
                        size="llg"
                        className='modal-dialog-centered'
                    >
                        <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>Actualizar Estado de Solicitud</ModalHeader>
                        <Form autoComplete="off" onSubmit={handleSubmit(onSubmit2)}>
                            <ModalBody>
                                <Row>
                                    <Col lg="12">
                                        <div>
                                            <CardBody>
                                                <CardTitle className="mb-4">Línea de Tiempo</CardTitle>

                                                <div className="hori-timeline">
                                                    <Swiper
                                                        slidesPerView={1}
                                                        // spaceBetween={10}
                                                        navigation
                                                        pagination={{
                                                            clickable: true,
                                                        }}
                                                        breakpoints={{
                                                            678: {
                                                                slidesPerView: 2,
                                                            },
                                                            992: {
                                                                slidesPerView: 3,
                                                            },
                                                            1400: {
                                                                slidesPerView: 4,
                                                            }
                                                        }}
                                                        loop={false}
                                                        modules={[Pagination, Navigation]}
                                                        className="owl-carousel owl-theme  navs-carousel events"
                                                        id="timeline-carousel"
                                                    >
                                                        {oneData?.creado !== "0" ? <SwiperSlide
                                                            className="item event-list"
                                                            style={{ display: "inline-table" }}
                                                        >
                                                            <div>
                                                                <div className="event-date">
                                                                    <div className="text-primary mb-1">
                                                                        <Moment locale="es" format='lll' fromNow>{oneData?.creado}</Moment>
                                                                    </div>
                                                                    <h5 className="mb-4">{"Solicitado"}</h5>
                                                                </div>
                                                                <div className="event-down-icon">
                                                                    <i className="bx bx-check-circle h1 text-primary down-arrow-icon" />
                                                                </div>

                                                                <div className="mt-3 px-3">
                                                                    <p className="text-muted">
                                                                        {oneData?.solicitante}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide> : null}

                                                        {oneData?.fechaActualizacionAnulado !== "0" ?
                                                            <SwiperSlide
                                                                className="item event-list"
                                                                style={{ display: "inline-table" }}
                                                            >
                                                                <div>
                                                                    <div className="event-date">
                                                                        <div className="text-primary mb-1">
                                                                            <Moment locale="es" format='lll' fromNow>{oneData?.fechaActualizacionAnulado}</Moment>
                                                                        </div>
                                                                        <h5 className="mb-4">{"Anulado"}</h5>
                                                                    </div>
                                                                    <div className="event-down-icon">
                                                                        <i className="bx bx-check-circle h1 text-primary down-arrow-icon" />
                                                                    </div>

                                                                    <div className="mt-3 px-3">
                                                                        <p className="text-muted">
                                                                            {oneData?.actualizadoPor}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                            :
                                                            null
                                                        }
                                                        {oneData?.fechaActualizacionEnProceso !== "0" ?
                                                            <SwiperSlide
                                                                className="item event-list"
                                                                style={{ display: "inline-table" }}
                                                            >
                                                                <div>
                                                                    <div className="event-date">
                                                                        <div className="text-primary mb-1">
                                                                            <Moment locale="es" format='lll' fromNow>{oneData?.fechaActualizacionEnProceso}</Moment>
                                                                        </div>
                                                                        <h5 className="mb-4">{"En Proceso"}</h5>
                                                                    </div>
                                                                    <div className="event-down-icon">
                                                                        <i className="bx bx-check-circle h1 text-primary down-arrow-icon" />
                                                                    </div>

                                                                    <div className="mt-3 px-3">
                                                                        <p className="text-muted">
                                                                            {oneData?.actualizadoPor}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                            :
                                                            null
                                                        }
                                                        {oneData?.fechaActualizacionAsignado !== "0" ?
                                                            <SwiperSlide
                                                                className="item event-list"
                                                                style={{ display: "inline-table" }}
                                                            >
                                                                <div>
                                                                    <div className="event-date">
                                                                        <div className="text-primary mb-1">
                                                                            <Moment locale="es" format='lll' fromNow>{oneData?.fechaActualizacionAsignado}</Moment>
                                                                        </div>
                                                                        <h5 className="mb-4">{"Asignado"}</h5>
                                                                    </div>
                                                                    <div className="event-down-icon">
                                                                        <i className="bx bx-check-circle h1 text-primary down-arrow-icon" />
                                                                    </div>

                                                                    <div className="mt-3 px-3">
                                                                        <p className="text-muted">
                                                                            {oneData?.actualizadoPor}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                            :
                                                            null
                                                        }
                                                        
                                                        {oneData?.fechaActualizacionInsumoCargado !== "0" ?
                                                            <SwiperSlide
                                                                className="item event-list"
                                                                style={{ display: "inline-table" }}
                                                            >
                                                                <div>
                                                                    <div className="event-date">
                                                                        <div className="text-primary mb-1">
                                                                            <Moment locale="es" format='lll' fromNow>{oneData?.fechaActualizacionInsumoCargado}</Moment>
                                                                        </div>
                                                                        <h5 className="mb-4">{"Insumo Cargado"}</h5>
                                                                    </div>
                                                                    <div className="event-down-icon">
                                                                        <i className="bx bx-check-circle h1 text-primary down-arrow-icon" />
                                                                    </div>

                                                                    <div className="mt-3 px-3">
                                                                        <p className="text-muted">
                                                                            {oneData?.actualizadoPor}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </SwiperSlide>
                                                            :
                                                            null
                                                        }
                                                    </Swiper>
                                                </div>
                                            </CardBody>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label className=" justify-content-between">* Estado</Label>
                                            <Controller
                                                name="estado"
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            {...field}
                                                            isClearable
                                                            onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                            onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                            // defaultValue={null}
                                                            options={optionsStatus}
                                                            // theme={selectThemeColors}
                                                            // isDisabled={updateData}
                                                            className='react-select'
                                                            classNamePrefix='select'
                                                            placeholder={'Seleccione una opción'}
                                                            noOptionsMessage={() => 'sin resultados'}
                                                            styles={(!!errors.estado) ? customStyles : {}}
                                                        />
                                                    )
                                                }
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <small className="text-danger">
                                                {!!errors.estado && errors.estado.message}
                                            </small>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                    <Icon.CornerUpLeft size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                    Salir
                                </UncontrolledTooltip>
                                {
                                    opcion !== 2 &&
                                    <Fragment>
                                        <Button color='success' type="submit" id="accion">
                                            <Icon.Save size={16} />
                                        </Button>
                                        <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                            {opcion !== 2 && opcion === 1 ? 'Crear' : 'Actualizar'}
                                        </UncontrolledTooltip>
                                    </Fragment>
                                }
                            </ModalFooter>
                        </Form>
                    </Modal>
                </>
            }
            {opcion !== 4 &&
                <Modal
                    isOpen={modal}
                    backdrop='static'
                    toggle={() => toggleModal()}
                    size="llg"
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>
                        <ul className="nav nav-tabs nav-tabs-custom nav-line-tabs-2x mb-1 fs-5 border-transparent fw-bolder" role="tablist">
                            <li className="nav-item" role="form" onClick={() => TabActive(1)}>
                                <a
                                    className="nav-link active"
                                    data-bs-toggle="tab"
                                    href="#form"
                                    role="tab"
                                    aria-selected="false"
                                >
                                    <span className="d-block d-sm-none">
                                        <i className="fas fa-cog" />
                                    </span>
                                    <span className="d-none d-sm-block">{nameComponent}</span>
                                </a>
                            </li>
                            {idSolicitud !== 0 &&
                                <li className="nav-item" role="adjuntos">
                                    <a
                                        className="nav-link"
                                        data-bs-toggle="tab"
                                        href="#adjuntos"
                                        role="tab"
                                        aria-selected="false"
                                        tabIndex={-1}
                                    >
                                        <span className="d-block d-sm-none">
                                            <i className="far fa-envelope" />
                                        </span>
                                        <span className="d-none d-sm-block">Adjuntos</span>
                                    </a>
                                </li>}
                        </ul>
                    </ModalHeader>

                    <div className="tab-content p-3 text-muted">
                        <div className="tab-pane active show" id="form" role="tabpanel">
                            <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                                <ModalBody>
                                    <div className=" ">
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for='nombre' className="">* Nombre</Label>
                                                    <Controller
                                                        defaultValue=''
                                                        control={control}
                                                        id='nombre'
                                                        name='nombre'
                                                        render={({ field }) => (
                                                            <Input
                                                                type='text'
                                                                invalid={errors.nombre && true}
                                                                {...field}
                                                            />
                                                        )
                                                        }
                                                        rules={
                                                            {
                                                                required: {
                                                                    value: true,
                                                                    message: 'Este campo es requerido'
                                                                }
                                                            }
                                                        }
                                                    />
                                                    <FormFeedback>
                                                        {!!errors.nombre && errors.nombre.message}
                                                    </FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label for='presentacion' className="">* Presentación</Label>
                                                    <Controller
                                                        defaultValue=''
                                                        control={control}
                                                        id='presentacion'
                                                        name='presentacion'
                                                        render={({ field }) => (
                                                            <Input
                                                                type='text'
                                                                invalid={errors.presentacion && true}
                                                                {...field}
                                                            />
                                                        )
                                                        }
                                                        rules={
                                                            {
                                                                required: {
                                                                    value: true,
                                                                    message: 'Este campo es requerido'
                                                                }
                                                            }
                                                        }
                                                    />
                                                    <FormFeedback>
                                                        {!!errors.presentacion && errors.presentacion.message}
                                                    </FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="caracteristicas"> * Características</Label>
                                                    <Controller

                                                        control={control}
                                                        id='caracteristicas'
                                                        name='caracteristicas'
                                                        render={({ field }) => (
                                                            <Input
                                                                rows={2}
                                                                type='textarea'
                                                                readOnly={opcion === 2}
                                                                invalid={errors.caracteristicas && true}
                                                                defaultValue=''
                                                                {...field}
                                                            />
                                                        )
                                                        }
                                                        rules={
                                                            {
                                                                required: {
                                                                    value: true,
                                                                    message: 'Este campo es requerido'
                                                                }
                                                            }
                                                        }
                                                    />
                                                    <FormFeedback>
                                                        {!!errors.caracteristicas && errors.caracteristicas.message}
                                                    </FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for='unidadMedida' className="">* Unidad de Medida</Label>
                                                    <Controller
                                                        defaultValue=''
                                                        control={control}
                                                        id='unidadMedida'
                                                        name='unidadMedida'
                                                        render={({ field }) => (
                                                            <Input
                                                                type='text'
                                                                invalid={errors.unidadMedida && true}
                                                                {...field}
                                                            />
                                                        )
                                                        }
                                                        rules={
                                                            {
                                                                required: {
                                                                    value: true,
                                                                    message: 'Este campo es requerido'
                                                                }
                                                            }
                                                        }
                                                    />
                                                    <FormFeedback>
                                                        {!!errors.unidadMedida && errors.unidadMedida.message}
                                                    </FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label for='link' className="">* Link</Label>
                                                    <Controller
                                                        defaultValue=''
                                                        control={control}
                                                        id='link'
                                                        name='link'
                                                        render={({ field }) => (
                                                            <Input
                                                                type='url'
                                                                invalid={errors.link && true}
                                                                {...field}
                                                            />
                                                        )
                                                        }
                                                        rules={
                                                            {
                                                                required: {
                                                                    value: true,
                                                                    message: 'Este campo es requerido'
                                                                }
                                                            }
                                                        }
                                                    />
                                                    <FormFeedback>
                                                        {!!errors.link && errors.link.message}
                                                    </FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>

                                    <ModalFooter>
                                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                                            <Icon.CornerUpLeft size={16} />
                                        </Button>
                                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                            Salir
                                        </UncontrolledTooltip>
                                        {
                                            opcion !== 2 &&
                                            <Fragment>
                                                <Button color='success' type="submit" id="accion">
                                                    <Icon.Save size={16} />
                                                </Button>
                                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                                    {opcion !== 2 && opcion === 1 ? 'Crear' : 'Actualizar'}
                                                </UncontrolledTooltip>
                                            </Fragment>
                                        }
                                    </ModalFooter>
                                </ModalBody>
                            </Form>
                        </div>

                        <div className="tab-pane" id="adjuntos" role="tabpanel">
                            <div className="p-3">
                                <Attached />
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </Fragment >
    )
}

export default Formulario