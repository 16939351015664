import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { ContentContext } from './context'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback, Card } from 'reactstrap'
import { useForm, Controller } from "react-hook-form"
import * as Icon from 'react-feather'
import DatePicker from "react-datepicker";
import Select from 'react-select'
import { customStyles } from '../../../Globales/index'
import classnames from 'classnames'
import Detalle from '../DetalleAcciones/form'

const Formulario = () => {
    const [startDate, setStartDate] = useState(new Date());
    const { toggleModal, modal, StoreUpdate, opcion, oneData, labelDependencias, labelObjetivos, labelPOA, setAgregarRenglon, total } = useContext(ContentContext),
        [updateData, setUpdateData] = useState(false),
        { handleSubmit, formState: { errors }, setValue, control, reset, watch } = useForm(),
        onSubmit = (data) => {
            const json = {
                id: (oneData) ? oneData.id : null,
                nombre: data.nombre,
                poa: data.poa.value,
                dependencia: data.dependencia.value,
                objetivo: data.objetivo.value
            }
            StoreUpdate(json, (oneData) ? oneData.id : null)
        },
        setData = async () => {
            await setValue('nombre', oneData.nombre)
            await setValue('unidad', { label: oneData.unidad, value: oneData.idUnidad })
            await setValue('dependencia', { label: oneData.dependencia, value: oneData.idDependencia })
            await setValue('poa', { label: oneData.poa, value: oneData.idPoa })
            await setValue('objetivo', { label: oneData.objetivoOperativo, value: oneData.idObjetivoOperativo })
        },
        clearData = async () => {
            reset({ nombre: '', fechaInicio: '', fechaFin: '', unidad: '', dependencia: '', poa: '', objetivo: '', actividad: '' })
        },
        setEstado = (estado) => {
            setUpdateData(estado)
        },
        habilitarEdicion = (e, estado) => {
            e.preventDefault()
            setEstado(estado)
        }

    useEffect(
        () => {
            async function fetchMyAPI() {
                if (await opcion > 1 && oneData) { await setData() }
                if (await opcion === 1) { clearData(), setUpdateData(false) }
            }

            fetchMyAPI()

        }, [opcion, oneData]
    )

    useEffect(
        () => {
            if (oneData) {
                setUpdateData(true)
            }
        }, [oneData]
    )

    return (
        <Fragment>
            <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className={opcion === 1 || !updateData ? ` p-3` : `card p-3`}>
                    <Row>

                        {updateData ?
                            <>
                                <Col>
                                    <Label >Correlativo</Label><br></br><span >{oneData.accion}</span>
                                </Col>
                                <Col>
                                    <Label >Unidad</Label><br></br><span>{oneData.unidad}</span>
                                </Col> </> : null
                        }
                        <Col>
                            {updateData ? <><Label >Dependencia</Label><br></br><span >{oneData.dependencia}</span></>
                                : <FormGroup >
                                    <Label >* Dependencia</Label>
                                    <Controller
                                        name="dependencia"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                    isDisabled={updateData}
                                                    options={labelDependencias}
                                                    className='react-select'
                                                    classNamePrefix='select'
                                                    placeholder={'Seleccione una opción'}
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={(!!errors.dependencia) ? customStyles : {}}

                                                />
                                            )
                                        }
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <small className="text-danger">{!!errors.dependencia && errors.dependencia.message}</small>
                                </FormGroup>}
                        </Col>
                        <Col>
                            {updateData ? <><Label >Presupuesto</Label><br></br><span >{oneData.poa}</span></>
                                : <FormGroup >
                                    <Label >* Presupuesto</Label>
                                    <Controller
                                        name="poa"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                    isDisabled={updateData}
                                                    options={labelPOA && labelPOA.length && labelPOA}
                                                    className='react-select'
                                                    classNamePrefix='select'
                                                    placeholder={'Seleccione una opción'}
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={(!!errors.poa) ? customStyles : {}}

                                                />
                                            )
                                        }
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <small className="text-danger">{!!errors.poa && errors.poa.message}</small>
                                </FormGroup>}
                        </Col>
                    </Row>
                </div>
                <div className={opcion === 1 || !updateData ? ` p-3` : `card p-3`}>
                    <Row>
                        {updateData ? <Row >
                            <Col className="py-2" lg={12} xl={12}>
                                <Label>Objetivo</Label><br></br>
                                <span style={{ fontSize: '12px' }}>{oneData.objetivoOperativo}</span>
                            </Col>
                        </Row> : null}
                        {updateData ? null
                            : <Col><FormGroup >
                                <Label >* Objetivo</Label>
                                <Controller
                                    name="objetivo"
                                    control={control}
                                    defaultValue={null}
                                    render={({ field }) => {
                                        return (
                                            <Select
                                                {...field}
                                                isClearable
                                                onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                isDisabled={updateData}
                                                options={labelObjetivos}
                                                className='selector-react'
                                                classNamePrefix='select'
                                                placeholder={'Seleccione una opción'}
                                                noOptionsMessage={() => 'sin resultados'}
                                                styles={(!!errors.objetivo) ? customStyles : {}}

                                            />
                                        )
                                    }
                                    }
                                    rules={
                                        {
                                            required: {
                                                value: true,
                                                message: 'Este campo es requerido'
                                            }
                                        }
                                    }
                                />
                                <small className="text-danger">{!!errors.objetivo && errors.objetivo.message}</small>
                            </FormGroup></Col>}

                    </Row>

                    {updateData && <Row>

                    </Row>}
                    <Row>
                        {updateData ? <><Col className='py-2'><Label >Nombre Acción</Label><br></br><span style={{ fontSize: '12px' }}>{oneData.nombre}</span></Col>

                            <Col className='py-2'>
                                <Label >Saldo Inicial</Label><br></br>
                                <span >{oneData.montoDependenciaActual}</span>
                            </Col>
                            <Col className='py-2'>
                                <Label >Saldo Actual</Label><br></br>
                                <span >{ total && total.at(0).actualConQ }</span>
                            </Col>
                        </>
                            :
                            <Col lg={10} xl={10}>
                                <FormGroup>
                                    <Label for='nombre' className="">* Nombre Acción</Label>
                                    <Controller
                                        defaultValue=''
                                        control={control}
                                        id='nombre'
                                        name='nombre'
                                        render={({ field }) => (
                                            <Input
                                                type='text'
                                                // step="0.25"
                                                readOnly={updateData}
                                                // disabled={updateData}
                                                invalid={errors.nombre && true}
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.nombre && errors.nombre.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>}
                        <Col lg={2} xl={2} className="align-self-center text-center">
                            {
                                updateData &&
                                <Fragment>
                                    <Button id='Actualizar1' color="warning" size="sm" type="button" className="text-center  me-2" onClick={(e) => habilitarEdicion(e, false)}>
                                        <Icon.Edit size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='Actualizar1'>
                                        Actualizar Acción
                                    </UncontrolledTooltip>
                                    <Button id='Renglon' color="primary" size="sm" type="button" className="text-center" onClick={() => setAgregarRenglon(1)}>
                                        <Icon.Plus size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='Renglon'>
                                        Agregar Renglón
                                    </UncontrolledTooltip>
                                </Fragment>
                            }
                            {
                                !updateData &&
                                <Fragment>
                                    <Button color="success" size="sm" type="submit" className="text-center me-2" id="Guardar">
                                        <Icon.Save size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='Guardar'>
                                        Guardar
                                    </UncontrolledTooltip>
                                    {
                                        (((oneData && oneData.id)) && !updateData) &&
                                        <Fragment>
                                            <Button color="danger" size="sm" type="button" className="text-center" id="Cancelar1" onClick={(e) => habilitarEdicion(e, true)}>
                                                <Icon.Slash size={16} />
                                            </Button>
                                            <UncontrolledTooltip placement='top' target='Cancelar1'>
                                                Cancelar
                                            </UncontrolledTooltip>
                                        </Fragment>
                                    }
                                </Fragment>
                            }

                        </Col>
                    </Row>
                </div>
            </Form>
        </Fragment >
    )
}

export default Formulario