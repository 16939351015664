import React, { Row, Col } from 'reactstrap'
import { ContentProvider } from './context'
import Formulario from './form'
const Index = () => {
    return (
        <ContentProvider>
            <div className="page-content">
                <Row>
                    <Col>
                        <div className="h1 font-weight-bolder text-uppercase text-center">
                            Configuración de Servidor SMTP
                        </div>
                    </Col>
                </Row>
                <Formulario />
            </div>
        </ContentProvider>
    )
}

export default Index