import React, { useState, useEffect } from "react";
import './newProfileView.css'
const ProfileNew = props => {
    const RoutePathServer = process.env.REACT_APP_PATH_SERVER;
    const Blowfish = require('javascript-blowfish');
    const bf = new Blowfish(process.env.REACT_APP_CONFIG_KEY);
    const [data, setData] = useState([]);
    const [username, setusername] = useState("Admin");

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
                const obj = JSON.parse(localStorage.getItem("authUser"));
                setusername(obj.displayName);
            } else if (
                process.env.REACT_APP_DEFAULTAUTH === "fake" ||
                process.env.REACT_APP_DEFAULTAUTH === "jwt"
            ) {
                const Decript = bf.decrypt(bf.base64Decode(localStorage.getItem('authUser')));
                const data = JSON.parse(Decript.replace(/\0/g, ''));
                // setUserAuth(data);
                // const obj = JSON.parse(localStorage.getItem("authUser"));
                setData(data)
                setusername(data.nombreCompleto);
            }
        }
    }, [props.success]);
    return (
        <>
            <div className="row d-flex justify-content-center">
                <div className="col-12">
                    <div className="card p-5 py-6">
                        <div className="text-center">
                            {data.length === 0 ?
                                null :
                                <img
                                src={(`${(RoutePathServer) + (data && data?.foto)}`)}
                                    width={125}
                                    className="rounded-circle"
                                />}

                        </div>
                        <div className="text-center mt-3">
                            {/* <span className="bg-secondary p-1 px-4 rounded text-white">Pro</span> */}
                            <h5 className="mt-2 mb-0">{username}</h5>
                            <span>{data && data?.esFADN === 1 ?  data?.tipoUsuarioFADN + "/" + data?.nombreFADN: data && data?.puesto + "/" + data?.iniciales}</span>
                            {/* <div className="px-4 mt-1">
                                <p className="fonts">
                                    Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                    consequat.{" "}
                                </p>
                            </div> */}
                            {/* <ul className="social-list">
                                <li>
                                    <i className="bx bxl-facebook-square" />
                                </li>
                                <li>
                                    <i className="bx bxl-dribbble" />
                                </li>
                                <li>
                                    <i className="bx bxl-instagram" />
                                </li>
                                <li>
                                    <i className="bx bxl-linkedin-square" />
                                </li>
                                <li>
                                    <i className="bx bxl-google-plus" />
                                </li>
                            </ul> */}
                            {/* <div className="buttons">
                                <button className="btn btn-outline-primary px-4">Message</button>
                                <button className="btn btn-primary px-4 ms-3">Contact</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ProfileNew