import React, { useContext } from 'react'
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { ContentContext } from './context'

const SalesAnalytics = props => {

  const { allIndicadorSatisPrestamo } = useContext(ContentContext),
    indicadorSatis = (Array?.isArray(allIndicadorSatisPrestamo) && allIndicadorSatisPrestamo?.length > 0 && allIndicadorSatisPrestamo?.value !== 2009)
      ? allIndicadorSatisPrestamo[0]
      : [];

  const series = [indicadorSatis?.satisfecho, indicadorSatis?.noSatisfecho];
  const options = {
    labels: ["Satisfecho", "No Satisfecho"],
    colors: ["#34c38f", "#f46a6a"],
    legend: { show: !1 },
    plotOptions: {
      pie: {
        donut: {
          size: "60%",
        },
      },
    },
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4 text-center fs-5">% DE CLIENTE INTERNO SATISFECHO (PRESTAMO EQ.)</CardTitle>

          <div>
            <div id="donut-chart">
              <ReactApexChart
                options={options}
                series={series}
                type="donut"
                height={260}
                className="apex-charts"
              />
            </div>
          </div>

          <div className="text-center text-muted">
            <Row>
              <Col xs="4">
                <div className="mt-4">
                  <p className="mb-2 text-truncate">
                    <i className="mdi  text-primary me-1" /> Encuestas
                  </p>
                  <h5>{indicadorSatis?.totalEvaluaciones}</h5>
                </div>
              </Col>
              <Col xs="4">
                <div className="mt-4">
                  <p className="mb-2 text-truncate">
                    <i className="mdi mdi-circle text-success me-1" /> Satisfecho
                  </p>
                  <h5>{indicadorSatis?.satisfecho}</h5>
                </div>
              </Col>
              <Col xs="4">
                <div className="mt-4">
                  <p className="mb-2 text-truncate">
                    <i className="mdi mdi-circle text-danger me-1" /> No Satisfecho
                  </p>
                  <h5>{indicadorSatis?.noSatisfecho}</h5>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SalesAnalytics;