import React, { createContext, useState, useContext } from 'react'
import { OptionsToast } from 'Globales/index'
import { toast } from 'react-toastify'
import { PostRoute, PostRouteFD } from 'services/Private'
// import { LoadingContext } from '../../../../utility/Loading/provider'
import { useSWRConfig } from 'swr'
import { useRequest } from 'hooks/useRequestSWR'
import { ContentContext as ContentMeta } from '../context'
import { useDataUser } from 'hooks/useDataUser'
export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    const Roles = useDataUser()?.dataUser?.roles;
    const
        { oneData: idSolicitud } = useContext(ContentMeta),
        { mutate } = useSWRConfig(),
        [show, setShow] = useState(false),
        [oneData, setOneData] = useState([]),
        [opcion, setOpcion] = useState(0),
        nameController = 'Pedido',
        nameController2 = 'PedidosHistoricos',
        ExpedienteName = "Expediente_" + idSolicitud?.correlativo,
        facturaName = "Factura_solicitud_" + idSolicitud?.correlativo + "_orden_de_compra_" + idSolicitud?.noOrdenCompra,
        { data: allData } = useRequest(`${nameController}/AllAdjuntosHistorico`, 'POST', { pedidoHistorico: idSolicitud?.id }),
        toggleModal = data => {
            setOpcion(data)
            if (data === 1) { setOneData([]) }
            setShow(!show)
        },
        One = async (data, opcion) => {
            const response = await PostRoute(`${nameController}/oneFacturaHistorico`, { id: data.id })
            setOneData((response.length) ? response[0] : [])
            toggleModal(opcion)
        },
        storeFile = async (data) => {
            // setShowLoad(true)
            let response = []
            response = await PostRouteFD(`${nameController}/storeFacturaHistorico`, data)
            if (response.response === 0) {
                toast.error(response.message, OptionsToast)
            } else {
                toast.success(response.message, OptionsToast)
            }
            toggleModal(0)
            mutate([`${nameController}/AllAdjuntosHistorico`, { pedidoHistorico: idSolicitud?.id }])
            mutate(`${nameController2}/all`)
            // setShowLoad(false)
        },
        Actions = async (data) => {
            const response = await PostRoute(`${nameController}/status`, {
                id: data.id,
                estado: data.idEstado === 1 ? 0 : 1,
            })
            toast.success(response.message, OptionsToast)
            mutate([`${Pedido}/AllAdjuntosHistorico`, { pedidoHistorico: idSolicitud?.id }])
        },
        value = {
            Actions,
            One,
            setOpcion,
            toggleModal,
            allData,
            storeFile,
            oneData,
            opcion,
            show,
            idSolicitud,
            facturaName,
            ExpedienteName,
            Roles
        }

    return (
        <ContentContext.Provider value={value} >
            {children}
        </ContentContext.Provider>
    )
}
