import React from "react"
import {
  Card, CardBody, CardTitle, Row, Col
} from "reactstrap"
import T1 from './WelcomeTwo'
import IndicadorDiasDI from './IndicadorDiasDI'
import IndicadorSatisDI from './IndicadorSatisDI'
import IndicadorSatisPrestamo from './IndicadorSatisPrestamo'
import IndicadorSatisSGTransportes from './IndicadorSatisSGTransportes'
import HeaderUnidad from './HeaderUnidadDashboard/index'
import HeaderDI from "./HeaderDI"
import HeaderSG from "./HeaderSG"
import { ContentProvider } from './context'
import { useDataUser } from 'hooks/useDataUser'


const Dashboard = () => {
  const roles = useDataUser()?.dataUser?.roles;
  const dataUser = useDataUser()?.dataUser;
  //meta title
  // document.title = "Sistema Integrado de Procesos Administrativos (SIPA)";
  return (
    <React.Fragment>
      <div className="page-content">
        <h4>Dashboard</h4>
        {/* <T1 /> */}
        {dataUser && dataUser?.esFADN === "1" ? null
          :
          <HeaderUnidad />
        }
        {/* <Cards /> CARDS DE DASHBOARD TIPO BI*/}
        {/* <div className="row">
          <div className="col-md-4">
            <div className="mini-stats-wid card">
              <div className="card-body">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Orders</p>
                    <h4 className="mb-0">1,235</h4>
                  </div>
                  <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                    <span className="avatar-title">
                      <i className="bx bx-copy-alt font-size-24" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mini-stats-wid card">
              <div className="card-body">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Revenue</p>
                    <h4 className="mb-0">$35, 723</h4>
                  </div>
                  <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                    <span className="avatar-title">
                      <i className="bx bx-archive-in font-size-24" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mini-stats-wid card">
              <div className="card-body">
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Average Price</p>
                    <h4 className="mb-0">$16.2</h4>
                  </div>
                  <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                    <span className="avatar-title">
                      <i className="bx bx-purchase-tag-alt font-size-24" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* CARDS DE DASHBOARD TIPO BI */}
        {/* <Row>

          <T3 />    
         
        </Row> */}
        {/* <Col> */}
        {/* <T2 CARDS DE DASHBOARD TIPO BI /> */}
        {/* </Col>  */}
        {/* <Col>

<T1 />
</Col> */}
        {(roles?.includes('BEAB4')) ? //Rol modulo de indicadores
          <Row>
            <Col>
              {(roles?.includes('89BF4')) ? //Roles de indicadores de DI
                <ContentProvider>
                  <Card>
                    <CardBody>
                      <HeaderDI />
                      <Row>
                        <Col xs={12} md={12} lg={4} >
                          <IndicadorDiasDI />
                        </Col>
                        <Col xs={12} md={12} lg={4} >
                          <IndicadorSatisDI />
                        </Col>
                        <Col xs={12} md={12} lg={4} >
                          <IndicadorSatisPrestamo />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </ContentProvider>
                : null
              }
              {(roles?.includes('619A9')) ? //Roles de indicadores de SG
                <ContentProvider>
                  <Card>
                    <CardBody>
                      <HeaderSG />
                      <Row>
                        <Col xs={12} md={12} lg={12} >
                          <IndicadorSatisSGTransportes />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </ContentProvider>
                : null
              }
            </Col>
          </Row>
          : null
        }
      </div>
    </React.Fragment>
  );
}

export default Dashboard;
