import React, { Row, Col } from 'reactstrap'
import { ContentProvider } from './context'
import Formulario from './form'
import List from './list'
import ListHistorico from './listHistorico'

const Index = () => {
    return (
        <ContentProvider>
            <div className="page-content">
                <Row>
                    <Col lg="2" sm="2">
                    </Col>
                    <Col>
                        <div className="h1 font-weight-bolder text-uppercase text-center">
                            BANDEJA de Gestiones POR ATENDER
                        </div>
                    </Col>
                    <Col className="d-flex justify-content-end" lg="2" sm="2">
                        <Formulario />
                    </Col>
                </Row>
                <div className="card">
                    <div className="card-body">

                        {/* <h4 className="card-title mb-4">Buy/Sell Crypto</h4> */}
                        <div className="crypto-buy-se-ll-nav">
                            <ul role="tablist" className="nav nav-tabs nav-tabs-custom nav-line-tabs-2x mb-1 fs-5 border-transparent fw-bolder" >
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        data-bs-toggle="tab"
                                        href="#pendientes"
                                        role="tab"
                                        aria-selected="true"
                                        tabIndex={-1}
                                    >Pendientes</a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link "
                                        data-bs-toggle="tab"
                                        href="#historico"
                                        role="tab"
                                        aria-selected="true"
                                        tabIndex={-1}
                                    >Historico</a>
                                </li>
                            </ul>
                            <div className="tab-content p-3 text-muted">
                                <div className="tab-pane active show" id="pendientes" role="tabpanel">
                                    <List />
                                </div>
                                <div className="tab-pane " id="historico" role="tabpanel">
                                    <ListHistorico />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ContentProvider>
    )
}

export default Index