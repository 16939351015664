import React, { Fragment, useState, useContext } from "react"
import DataTable from "react-data-table-component"
import {
  Button,
  UncontrolledTooltip,
} from "reactstrap"
import * as Icon from "react-feather"
import { CustomText } from "../../../../CustomList/index"
import { ContentContext } from "./context"
import { ContentContext as ContentContextTheme } from "../../../../components/CommonForBoth/TopbarDropdown/context"
import Moment from "react-moment"
import "moment/locale/es-mx"

const List = () => {
  const { allData, One, Actions } = useContext(ContentContext),
    { theme } = useContext(ContentContextTheme),
    Columns = [
      {
        name: "Dependencia",
        column: "nombreDependencia",
        sortable: true,
        center: false,
        cell: row => CustomText(row["nombreDependencia"]),
        grow: 3,
      },
      {
        name: "Acciones",
        column: "id",
        sortable: true,
        center: true,
        // width: '150px',
        cell: row => (
          <Fragment>
            <Button
              id="Edit"
              color="warning"
              size="sm"
              type="button"
              onClick={() => {}}
              className="text-center  me-2"
            >
              <Icon.Edit size={16} />
            </Button>

            <UncontrolledTooltip placement="top" target="Edit">
              Modificar Referencia
            </UncontrolledTooltip>

            <Button
              id="back"
              color="danger"
              size="sm"
              type="button"
              className="text-center"
              onClick={() => () => {}}
            >
              <Icon.Slash size={16} />
            </Button>

            <UncontrolledTooltip placement="top" target="back">
              Eliminar
            </UncontrolledTooltip>
          </Fragment>
        ),
      },
    ]

  return (
    <Fragment>
      {/*  <Row className="justify-content-end mx-0">
        <Col></Col>
        <Col className="justify-content-end mt-1" md="4" sm="12">
          <FormGroup>
            <Input
              className="dataTable-filter mb-50"
              type="text"
              bsSize="md"
              placeholder={`Buscar`}
              id="search-input"
              value={searchValue}
              onChange={handleFilter}
            />
          </FormGroup>
        </Col>
      </Row> */}
      <div className="card">
        <DataTable
          // dense
          striped
          noHeader
          highlightOnHover
          pagination
          theme={theme}
          data={allData && allData.value !== 2009 ? allData : []}
          columns={Columns}
          className="table-responsive mt-4"
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          paginationComponentOptions={{
            rowsPerPageText: "",
            rangeSeparatorText: "",
          }}
          noDataComponent="Sin Registros"
        />
      </div>
    </Fragment>
  )
}

export default List
