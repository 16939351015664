/* eslint-disable react/no-unknown-property */
/* eslint-disable multiline-ternary */
/* eslint-disable no-unneeded-ternary */
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Form, Row, Col, FormGroup, Input, Button, Modal, ModalHeader, ModalBody, Label, FormFeedback, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { Link } from "react-router-dom";
import { ContentContext } from './context'
import * as Icon from 'react-feather'
import { useForm, Controller } from "react-hook-form"
import Select from 'react-select'
import { customStyles } from '../../../Globales/index'

const Formulario = () => {
    const { toggleModal, modal, StoreUpdate, opcion, oneData, allPuestos, allRenglones, allUnidades, setId, allDependencias, labelTipoPersona, AllDependencias, labelFand, labelTipousuarioFADNS } = useContext(ContentContext),
        { register, handleSubmit, formState: { errors }, setValue, control, reset, watch } = useForm(),
        [Visualizar, setVisualizar] = useState(''),
        [getFoto, setGetFoto] = useState([]),
        [activeTab, setActiveTab] = useState(1),
        [tabFADN, setTabFADN] = useState(1),
        [passedSteps, setPassedSteps] = useState([1]),
        [updateData, setUpdateData] = useState(false),
        FechaNacimiento = watch('fechaNacimiento'),
        seleccionableUnidad = watch('unidades'),
        InputEsFADN = watch('esFADN'),
        onSubmit = async (data) => {
            const formData = await new FormData()
            if (oneData && oneData.id) { formData.append('id', oneData ? oneData.id : null) }
            formData.append('nombre1', data.nombre1)
            formData.append('nombre2', data.nombre2)
            formData.append('apellido1', data.apellido1)
            formData.append('apellido2', data.apellido2)
            formData.append('dpi', data.dpi)
            formData.append('telefono', data.telefono)
            formData.append('fechaNacimiento', FechaNacimiento)
            formData.append('email', data.correo)
            formData.append('direccion', data.direccion)
            if (InputEsFADN === 0 || InputEsFADN === '0') {
                formData.append('puesto', data.puestos.value)
                formData.append('renglon', data.renglones.value)
                formData.append('unidad', data.unidades.value)
                formData.append('dependencia', data.dependencia.value)
                formData.append('tipoPersona', data?.tipoPersona.value)
                formData.append('jefe', data.tipoPersona.value === 2 || data.tipoPersona.value === 3 ? 1 : 0)
            }
            if (InputEsFADN === 1 || InputEsFADN === '1') {
                formData.append('FADN', data.fadn.value)
                formData.append('tipoUsuarioFADN', data.tipoUsuarioFADN.value)
            }
            formData.append('esFADN', data.esFADN)
            if (data.foto) {
                formData.append('foto', getFoto)
            }
            StoreUpdate(formData, oneData ? oneData.id : null)
            // setUpdateData(true)
        },

        // onSubmit = (data) => {
        //     const json = {
        //         id: (oneData) ? oneData.id : null,
        //         nombre1: data.nombre1,
        //         nombre2: data.nombre2,
        //         nombre3: data.nombre3,
        //         nombre4: data.nombre4,
        //         apellido1: data.apellido1,
        //         apellido2: data.apellido2,
        //         apellido3: data.apellido3,
        //         dpi: data.dpi,
        //         telefono: data.telefono,
        //         fechaNacimiento: FechaNacimiento,
        //         email: data.correo,
        //         direccion: data.direccion,
        //         puesto: data.puestos.value,
        //         renglon: data.renglones.value,
        //         unidad: data.unidades.value,
        //         jefe: data.jefe
        //     }
        //     console.log(json)
        //     // StoreUpdate(json)
        // },
        setData = async () => {
            await setValue('nombre1', oneData.nombre1)
            await setValue('nombre2', oneData.nombre2)
            await setValue('nombre3', oneData.nombre3)
            await setValue('nombre4', oneData.nombre4)
            await setValue('apellido1', oneData.apellido1)
            await setValue('apellido2', oneData.apellido2)
            await setValue('apellido3', oneData.apellido3)
            await setValue('fechaNacimiento', oneData.fechaNacimiento)
            await setValue('correo', oneData.email)
            await setValue('telefono', oneData.telefono)
            await setValue('dpi', oneData.dpi)
            await setValue('direccion', oneData.direccion)
            await setValue('puestos', { label: oneData.puesto, value: oneData.idPuesto })
            await setValue('renglones', { label: oneData.renglon, value: oneData.idRenglon })
            await setValue('tipoPersona', { label: oneData.tipoPersona, value: oneData.idTipoPersona })
            await setValue('dependencia', { label: oneData.dependencia, value: oneData.idDependencia })
            await setValue('unidades', { label: oneData.unidad, value: oneData.idUnidad })
            await setValue('fadn', { label: oneData.nombreFADN, value: oneData.idFADN })
            await setValue('tipoUsuarioFADN', { label: oneData.tipoUsuarioFADN, value: oneData.idTipoUsuarioFADN })
            await setValue('jefe', oneData.jefe)
            await setValue('esFADN', oneData.esFADN)
            await setVisualizar(`data:image/${oneData && oneData.extension};base64,${oneData && oneData.foto}`)
        },
        clearData = async () => {
            reset(
                {
                    nombre1: '',
                    nombre2: '',
                    apellido1: '',
                    apellido2: '',
                    dpi: '',
                    telefono: '',
                    fechaNacimiento: '',
                    correo: '',
                    direccion: '',
                    puestos: '',
                    renglones: '',
                    unidades: '',
                    jefe: '',
                    esFADN: ''
                }
            )
            clearFoto();
        },
        MostrarDoc = (value) => {
            if (value.target.value) {
                const pdffile = value.target.files[0],
                    pdffile_url = URL.createObjectURL(pdffile)
                setGetFoto(pdffile)
                setVisualizar(`${pdffile_url}`)
            } else {
                setVisualizar('')
            }
        },
        clearFoto = () => {
            setVisualizar("")
            setValue('foto', "")
        },

        toggleTab = (tab) => {
            if (activeTab !== tab) {
                if (tab >= 1 && tab <= 3) {
                    var modifiedSteps = [...passedSteps, tab];
                    setActiveTab(tab)
                    setPassedSteps(modifiedSteps)

                }
            }
        }

    useEffect(
        () => {
            async function fetchMyAPI() {
                if (await opcion > 1 && oneData) { await setData(), toggleTab(1) }
                if (await opcion === 1) { clearData(), toggleTab(1) }
            }

            fetchMyAPI()
        }, [opcion, oneData]
    )

    useEffect(() => {
        let abortController = new AbortController();
        async function fetchMyAPI() {
            if (seleccionableUnidad) {
                // setValue('dependencia', '');
                AllDependencias(seleccionableUnidad.value)
            }
            if (seleccionableUnidad === null || seleccionableUnidad === '') {
                setValue('dependencia', '');
                // setId(0);
            }
        }
        fetchMyAPI()
        return () => { abortController.abort(); }
    }, [seleccionableUnidad])

    useEffect(() => {
        let abortController = new AbortController();
        async function fetchMyAPI() {

            if (InputEsFADN === 1) {
                setTabFADN(1)
            }
            if (InputEsFADN === 0) {
                setTabFADN(0)
            }
        }
        fetchMyAPI()
        return () => { abortController.abort(); }
    }, [InputEsFADN])

    return (
        <Fragment>
            <Button onClick={() => [toggleModal(1), toggleTab(1)]} color="primary" size="sm">
                <Icon.Plus size={16} />
            </Button>

            <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered modal-llg'
            >
                <ModalHeader toggle={() => toggleModal(0)}>{opcion === 1 && 'Crear'} Persona</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody>
                        <Col lg="12">
                            <div className="wizard clearfix">
                                <div className="steps clearfix">
                                    <ul>
                                        <NavItem
                                            className={activeTab === 1 ? `current` : ` `}
                                        >
                                            <NavLink
                                                className={activeTab === 1 ? `fw-bolder` : ` `}
                                                onClick={() => {
                                                    toggleTab(1);
                                                }}
                                            >
                                                <span className="number">1.</span>{" "}
                                                Datos Personales
                                                {errors && errors.apellido1 || errors.correo || errors.direccion || errors.dpi || errors.fechaNacimiento || errors.foto || errors.nombre1 || errors.telefono ? <span className="p-2 text-danger"><Icon.AlertCircle className="me-3 " size={18} /></span> :
                                                    null}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem
                                            className={activeTab === 2 ? `current` : ` `}
                                        >
                                            <NavLink
                                                className={activeTab === 2 ? `fw-bolder` : ` `}
                                                onClick={() => {
                                                    toggleTab(2);
                                                }}
                                            >
                                                <span className="number">2.</span>{" "}
                                                <span>Datos Laborales</span>
                                                {errors && errors.unidades || errors.renglones || errors.puestos || errors.jefe || errors.esFADN ? <span className="p-2 text-danger"><Icon.AlertCircle className="me-3 " size={18} /></span> :
                                                    null}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem
                                            className={activeTab === 3 ? `current` : ` `}
                                        >
                                            <NavLink
                                                className={activeTab === 3 ? `fw-bolder` : ` `}
                                                onClick={() => {
                                                    toggleTab(3);
                                                }}
                                            >
                                                <span className="number">3.</span>{" "}
                                                Confirmación
                                            </NavLink>
                                        </NavItem>
                                    </ul>
                                </div>
                                <div className="content clearfix">
                                    <TabContent
                                        activeTab={activeTab}
                                        className="body"
                                    >
                                        <TabPane tabId={1}>
                                            <Form autoComplete="off">
                                                <Row rows={2}>
                                                    <Col className="text-center">
                                                        <div className={`kt-avatar kt-avatar--outline ${(Visualizar) && 'kt-avatar--changed'}`} id="kt_user_avatar">
                                                            <div className={`kt-avatar__holder ${(errors.foto) && (errors.foto.message) && 'border-danger'}`} style={{ backgroundImage: `url('${Visualizar}')` }}></div>
                                                            {opcion === 1 &&
                                                                <>
                                                                    <Label className="kt-avatar__upload p-0" data-toggle="kt-tooltip" title="Cargar Foto" data-original-title="Change avatar">

                                                                        <i className={`fa ${(opcion === 3) ? 'fa-edit' : 'fa-image'}`}></i>
                                                                        <input
                                                                            {
                                                                            ...register('foto',
                                                                                {
                                                                                    required:
                                                                                    {
                                                                                        value: true,
                                                                                        message: "Este campo es requerido"
                                                                                    }
                                                                                }
                                                                            )
                                                                            }
                                                                            lang="es"
                                                                            type="file"
                                                                            className="fileInput"
                                                                            disabled={updateData}
                                                                            accept=".jpg, .jpeg, .png"
                                                                            onChange={(e) => MostrarDoc(e)}
                                                                        />
                                                                    </Label>
                                                                    <span className="kt-avatar__cancel" onClick={() => clearFoto()} >
                                                                        <i className="fas fa-trash text-danger"></i>
                                                                    </span>
                                                                </>
                                                            }
                                                        </div>

                                                    </Col>
                                                    <Col >
                                                        <FormGroup>
                                                            <Label for="nombre1">* Nombre1</Label>
                                                            <Controller
                                                                defaultValue='undefined'
                                                                control={control}

                                                                id='nombre1'
                                                                name='nombre1'
                                                                render={({ field }) => (
                                                                    <Input
                                                                        type='text'
                                                                        readOnly={opcion === 2}
                                                                        invalid={errors.nombre1 && true}
                                                                        {...field}
                                                                    />
                                                                )
                                                                }
                                                                rules={
                                                                    {
                                                                        required: {
                                                                            value: true,
                                                                            message: 'Este campo es requerido'
                                                                        }
                                                                    }
                                                                }
                                                            />
                                                            <FormFeedback>
                                                                {!!errors.nombre1 && errors.nombre1.message}
                                                            </FormFeedback>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="apellido1">* Apellido1</Label>
                                                            <Controller
                                                                defaultValue='undefined'
                                                                control={control}
                                                                id='apellido1'
                                                                name='apellido1'
                                                                render={({ field }) => (
                                                                    <Input
                                                                        type='text'
                                                                        readOnly={opcion === 2}
                                                                        invalid={errors.apellido1 && true}
                                                                        {...field}
                                                                    />
                                                                )
                                                                }
                                                                rules={
                                                                    {
                                                                        required: {
                                                                            value: true,
                                                                            message: 'Este campo es requerido'
                                                                        }
                                                                    }
                                                                }
                                                            />
                                                            <FormFeedback>
                                                                {!!errors.apellido1 && errors.apellido1.message}
                                                            </FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col >
                                                        <FormGroup>
                                                            <Label for="nombre2">Nombre2</Label>
                                                            <Controller
                                                                defaultValue='undefined'
                                                                control={control}
                                                                id='nombre2'
                                                                name='nombre2'
                                                                render={({ field }) => (
                                                                    <Input
                                                                        type='text'
                                                                        readOnly={opcion === 2}
                                                                        invalid={errors.nombre2 && true}
                                                                        {...field}
                                                                    />
                                                                )
                                                                }
                                                            />
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label for="apellido2"> Apellido2</Label>
                                                            <Controller
                                                                defaultValue='undefined'
                                                                control={control}
                                                                id='apellido2'
                                                                name='apellido2'
                                                                render={({ field }) => (
                                                                    <Input
                                                                        type='text'
                                                                        readOnly={opcion === 2}
                                                                        invalid={errors.apellido2 && true}
                                                                        {...field}
                                                                    />
                                                                )
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col lg="1">
                                                        <Label className=" justify-content-between">* Es FADN</Label>
                                                        <div className="form-check mb-2">
                                                            <input className="form-check-input" {...register("esFADN", { required: true })} type="radio" value={1} />
                                                            <label className="form-check-label" htmlFor="si">
                                                                Si
                                                            </label>

                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" {...register("esFADN", { required: true })} type="radio" value={0} />
                                                            <label className="form-check-label" htmlFor="no">
                                                                No
                                                            </label>
                                                        </div>


                                                    </Col>
                                                    <Col >
                                                        <FormGroup>
                                                            <Label for="correo">* Correo Electronico</Label>
                                                            <Controller
                                                                defaultValue='undefined'
                                                                control={control}
                                                                id='correo'
                                                                name='correo'
                                                                render={({ field }) => (
                                                                    <Input
                                                                        type='email'
                                                                        readOnly={opcion === 2}
                                                                        invalid={errors.correo && true}
                                                                        {...field}
                                                                    />
                                                                )
                                                                }
                                                                rules={
                                                                    {
                                                                        required: {
                                                                            value: true,
                                                                            message: 'Este campo es requerido'
                                                                        }
                                                                    }
                                                                }
                                                            />
                                                            <FormFeedback>
                                                                {!!errors.correo && errors.correo.message}
                                                            </FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col >
                                                        <FormGroup>
                                                            <Label for="telefono"> * Teléfono </Label>
                                                            <Controller
                                                                defaultValue='undefined'
                                                                control={control}
                                                                id='telefono'
                                                                name='telefono'
                                                                render={({ field }) => (
                                                                    <Input
                                                                        type='number'
                                                                        readOnly={opcion === 2}
                                                                        invalid={errors.telefono && true}
                                                                        {...field}
                                                                    />
                                                                )
                                                                }
                                                                rules={
                                                                    {
                                                                        required: {
                                                                            value: true,
                                                                            message: 'Este campo es requerido'
                                                                        }
                                                                    }
                                                                }
                                                            />
                                                            <FormFeedback>
                                                                {!!errors.telefono && errors.telefono.message}
                                                            </FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col >
                                                        <FormGroup>
                                                            <Label for="dpi"> * DPI</Label>
                                                            <Controller
                                                                defaultValue='undefined'
                                                                control={control}
                                                                id='dpi'
                                                                name='dpi'
                                                                render={({ field }) => (
                                                                    <Input
                                                                        type='number'
                                                                        readOnly={opcion === 2}
                                                                        invalid={errors.dpi && true}
                                                                        {...field}
                                                                    />
                                                                )
                                                                }
                                                                rules={
                                                                    {
                                                                        required: {
                                                                            value: true,
                                                                            message: 'Este campo es requerido'
                                                                        }
                                                                    }
                                                                }
                                                            />
                                                            <FormFeedback>
                                                                {!!errors.dpi && errors.dpi.message}
                                                            </FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="fechaNacimiento">* Fecha de Nacimiento</Label>
                                                            <Controller
                                                                name='fechaNacimiento'
                                                                control={control}
                                                                render={({ field }) => {
                                                                    return (
                                                                        <input
                                                                            {...field}
                                                                            className="form-control"
                                                                            type="date"
                                                                            name='fechaNacimiento'
                                                                            id="fechaNacimiento"
                                                                            invalid={errors.fechaNacimiento && true} />

                                                                        // <input
                                                                        //     {...field}
                                                                        //     type="date"
                                                                        //     readOnly={opcion === 2}
                                                                        //     name='fechaNacimiento'
                                                                        //     id="fechaNacimiento"
                                                                        //     invalid={errors.fechaNacimiento && true} />
                                                                    )
                                                                }
                                                                }
                                                                rules={
                                                                    {
                                                                        required: {
                                                                            value: true,
                                                                            message: 'Este campo es requerido'
                                                                        }
                                                                    }
                                                                }
                                                            />
                                                            <FormFeedback>
                                                                {!!errors.fechaNacimiento && errors.fechaNacimiento.message}
                                                            </FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="direccion"> * Dirección</Label>
                                                            <Controller

                                                                control={control}
                                                                id='direccion'
                                                                name='direccion'
                                                                render={({ field }) => (
                                                                    <Input
                                                                        rows={1}
                                                                        type='textarea'
                                                                        readOnly={opcion === 2}
                                                                        invalid={errors.direccion && true}
                                                                        defaultValue='null'
                                                                        {...field}
                                                                    />
                                                                )
                                                                }
                                                                rules={
                                                                    {
                                                                        required: {
                                                                            value: true,
                                                                            message: 'Este campo es requerido'
                                                                        }
                                                                    }
                                                                }
                                                            />
                                                            <FormFeedback>
                                                                {!!errors.direccion && errors.direccion.message}
                                                            </FormFeedback>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </TabPane>
                                        <TabPane tabId={2}>
                                            <div>
                                                <Form>
                                                    {InputEsFADN === 0 || InputEsFADN === '0' ?
                                                        <>
                                                            <Row>
                                                                <Col >
                                                                    <FormGroup>
                                                                        <Label className=" justify-content-between">* Unidad</Label>
                                                                        <Controller
                                                                            name="unidades"
                                                                            control={control}
                                                                            render={({ field }) => {
                                                                                return (
                                                                                    <Select
                                                                                        {...field}
                                                                                        isClearable
                                                                                        onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                                                        onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                                                        // defaultValue={null}
                                                                                        options={allUnidades}
                                                                                        // theme={selectThemeColors}
                                                                                        // isDisabled={updateData}
                                                                                        className='react-select'
                                                                                        classNamePrefix='select'
                                                                                        placeholder={'Seleccione una opción'}
                                                                                        noOptionsMessage={() => 'sin resultados'}
                                                                                        styles={(!!errors.unidades) ? customStyles : {}}
                                                                                    />
                                                                                )
                                                                            }
                                                                            }
                                                                            rules={
                                                                                {
                                                                                    required: {
                                                                                        value: true,
                                                                                        message: 'Este campo es requerido'
                                                                                    }
                                                                                }
                                                                            }
                                                                        />
                                                                        <small className="text-danger">
                                                                            {!!errors.unidades && errors.unidades.message}
                                                                        </small>
                                                                    </FormGroup>
                                                                </Col>
                                                                {allDependencias && allDependencias.value !== 2009 &&
                                                                    <Col>
                                                                        <FormGroup >
                                                                            <Label >* Dependencia</Label>
                                                                            <Controller
                                                                                name="dependencia"
                                                                                control={control}
                                                                                defaultValue={null}
                                                                                render={({ field }) => {
                                                                                    return (
                                                                                        <Select
                                                                                            {...field}
                                                                                            isClearable
                                                                                            isDisabled={opcion === 4 || opcion === 2}
                                                                                            onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                                                            onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                                                            options={allDependencias}
                                                                                            className='react-select'
                                                                                            classNamePrefix='select'
                                                                                            placeholder={'Seleccione una opción'}
                                                                                            noOptionsMessage={() => 'sin resultados'}
                                                                                            styles={(!!errors.dependencia) ? customStyles : {}}

                                                                                        />
                                                                                    )
                                                                                }
                                                                                }
                                                                                rules={
                                                                                    {
                                                                                        required: {
                                                                                            value: true,
                                                                                            message: 'Este campo es requerido'
                                                                                        }
                                                                                    }
                                                                                }
                                                                            />
                                                                            <small className="text-danger">{!!errors.dependencia && errors.dependencia.message}</small>
                                                                        </FormGroup>
                                                                    </Col>
                                                                }
                                                            </Row>
                                                            <Row>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <Label className=" justify-content-between">* Puesto</Label>
                                                                        <Controller
                                                                            name="puestos"
                                                                            control={control}
                                                                            render={({ field }) => {
                                                                                return (
                                                                                    <Select
                                                                                        {...field}
                                                                                        isClearable
                                                                                        onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                                                        onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                                                        // defaultValue={null}
                                                                                        options={allPuestos}
                                                                                        // theme={selectThemeColors}
                                                                                        // isDisabled={updateData}
                                                                                        className=''
                                                                                        classNamePrefix='select'
                                                                                        placeholder={'Seleccione una opción'}
                                                                                        noOptionsMessage={() => 'sin resultados'}
                                                                                        styles={(!!errors.puestos) ? customStyles : {}}
                                                                                    />
                                                                                )
                                                                            }
                                                                            }
                                                                            rules={
                                                                                {
                                                                                    required: {
                                                                                        value: true,
                                                                                        message: 'Este campo es requerido'
                                                                                    }
                                                                                }
                                                                            }
                                                                        />
                                                                        <small className="text-danger">
                                                                            {!!errors.puestos && errors.puestos.message}
                                                                        </small>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col lg="6">
                                                                    <FormGroup>
                                                                        <Label className=" justify-content-between">* Tipo Persona</Label>
                                                                        <Controller
                                                                            name="tipoPersona"
                                                                            control={control}
                                                                            render={({ field }) => {
                                                                                return (
                                                                                    <Select
                                                                                        {...field}
                                                                                        isClearable
                                                                                        onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                                                        onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                                                        // defaultValue={null}
                                                                                        options={labelTipoPersona}
                                                                                        // theme={selectThemeColors}
                                                                                        // isDisabled={updateData}
                                                                                        className=''
                                                                                        classNamePrefix='select'
                                                                                        placeholder={'Seleccione una opción'}
                                                                                        noOptionsMessage={() => 'sin resultados'}
                                                                                        styles={(!!errors.tipoPersona) ? customStyles : {}}
                                                                                    />
                                                                                )
                                                                            }
                                                                            }
                                                                            rules={
                                                                                {
                                                                                    required: {
                                                                                        value: true,
                                                                                        message: 'Este campo es requerido'
                                                                                    }
                                                                                }
                                                                            }
                                                                        />
                                                                        <small className="text-danger">
                                                                            {!!errors.tipoPersona && errors.tipoPersona.message}
                                                                        </small>
                                                                    </FormGroup>
                                                                </Col>


                                                            </Row>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    {InputEsFADN === 1 || InputEsFADN === '1' ?
                                                        <>
                                                            <Row>
                                                                <Col>
                                                                    <FormGroup >
                                                                        <Label >* FADN</Label>
                                                                        <Controller
                                                                            name="fadn"
                                                                            control={control}
                                                                            defaultValue={null}
                                                                            render={({ field }) => {
                                                                                return (
                                                                                    <Select
                                                                                        {...field}
                                                                                        isClearable
                                                                                        onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                                                        onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                                                        isDisabled={updateData}
                                                                                        options={labelFand}
                                                                                        className='selector-react1'
                                                                                        classNamePrefix='select'
                                                                                        placeholder={'Seleccione una opción'}
                                                                                        noOptionsMessage={() => 'sin resultados'}
                                                                                        styles={(!!errors.fadn) ? customStyles : {}}

                                                                                    />
                                                                                )
                                                                            }
                                                                            }
                                                                            rules={
                                                                                {
                                                                                    required: {
                                                                                        value: true,
                                                                                        message: 'Este campo es requerido'
                                                                                    }
                                                                                }
                                                                            }
                                                                        />
                                                                        <small className="text-danger">{!!errors.fadn && errors.fadn.message}</small>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col>
                                                                    <FormGroup >
                                                                        <Label >* Tipo Usuario FADN</Label>
                                                                        <Controller
                                                                            name="tipoUsuarioFADN"
                                                                            control={control}
                                                                            defaultValue={null}
                                                                            render={({ field }) => {
                                                                                return (
                                                                                    <Select
                                                                                        {...field}
                                                                                        isClearable
                                                                                        onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                                                        onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                                                        isDisabled={updateData}
                                                                                        options={labelTipousuarioFADNS}
                                                                                        className='selector-react1'
                                                                                        classNamePrefix='select'
                                                                                        placeholder={'Seleccione una opción'}
                                                                                        noOptionsMessage={() => 'sin resultados'}
                                                                                        styles={(!!errors.tipoUsuarioFADN) ? customStyles : {}}

                                                                                    />
                                                                                )
                                                                            }
                                                                            }
                                                                            rules={
                                                                                {
                                                                                    required: {
                                                                                        value: true,
                                                                                        message: 'Este campo es requerido'
                                                                                    }
                                                                                }
                                                                            }
                                                                        />
                                                                        <small className="text-danger">{!!errors.tipoUsuarioFADN && errors.tipoUsuarioFADN.message}</small>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                        :
                                                        null
                                                    }
                                                </Form>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId={3}>
                                            <div className="row justify-content-center">
                                                <Col lg="6">
                                                    <div className="text-center">
                                                        <div className="mb-4">
                                                            <i className="mdi mdi-check-circle-outline text-success display-4" />
                                                        </div>
                                                        <div>
                                                            <h5>Confirmar Formulario</h5>
                                                            <p className="text-muted text-justify">
                                                                Validar si existe algún icono de advertencia sobre cada encabezado del formulario, si es así, llene el formulario y luego presione el boton de guardar.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </div>
                                <div className="actions clearfix">
                                    <ul>
                                        <li
                                            className={"previous"}
                                        >
                                            {activeTab === 1 ? null : <Link
                                                to="#"
                                                onClick={() => {
                                                    toggleTab(activeTab - 1);
                                                }}
                                            >
                                                Regresar
                                            </Link>}
                                        </li>
                                        <li
                                            className={"next"
                                            }
                                        >
                                            {activeTab === 3 ?
                                                <Col className="d-flex align-self-center text-center">
                                                    {
                                                        opcion !== 2 &&
                                                        <Button color='success' className='mt-2 me-1' type="submit">
                                                            <Icon.Save size={16} />
                                                        </Button>
                                                    }
                                                </Col>
                                                :

                                                <Link
                                                    to="#"
                                                    onClick={() => {
                                                        toggleTab(activeTab + 1);
                                                    }}
                                                >
                                                    Siguiente
                                                </Link>}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>

                    </ModalBody>
                </Form >
            </Modal >
        </Fragment >
    )
}

export default Formulario