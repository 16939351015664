import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Row, Col, Form, FormGroup, Label, FormFeedback, Input, Button, UncontrolledTooltip, UncontrolledButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap'
import { useForm, Controller } from 'react-hook-form'
import { ContentContext } from './context'
// import { selectThemeColors } from '@utils'
import * as Icon from 'react-feather'
import Select from 'react-select'
import List from './list'
import { customStyles } from 'Globales/index'
import { CustomText, EstadoLista, TextDetalleAccion } from 'CustomList/index'
import CurrencyInput from 'components/CurrencyInput/CurrencyInput'
import classnames from 'classnames'
import DataTable from 'react-data-table-component'
import { ContentContext as ContentContextTheme } from 'components/CommonForBoth/TopbarDropdown/context'




const DetalleModificaciones = (props) => {
    const { theme } = useContext(ContentContextTheme)
    const [tipoRenglon, setTipoRenglon] = useState(false);
    const [updateData, setUpdateData] = useState(false);
    const { handleSubmit, formState: { errors }, control, setValue, reset, watch } = useForm(),
        inputRenglon = watch("renglon"),
        inputCostoUnitario = watch("costoU"),
        inputCantidad = watch("cantidad"),
        seleccionableRenglon = watch('operacion'),
        seleccionableAccion = watch('accion'),
        seleccionableUnidad = watch('unidad'),
        [btnDisable, setBtnDisable] = useState(false),
        { StoreUpdate, setOneData, One, oneData, setAccion, setRenglon, allInsumos, labelUnidadMedida, actualiza, totalDetallePedido, labelAcciones, labelFinanciamiento, labelTipoOperacion, labelAllRenglon, labelRenglonXAccion, labelUnidades, setIdUnidad, setIdModificacion, allData } = useContext(ContentContext),
        [searchValue, setSearchValue] = useState(''),
        [closeSeleccionableInsumos, setCloseSeleccionableInsumos] = useState(false),
        [alertaSaldo, setAlertaSaldo] = useState(false),
        [saldo, setSaldo] = useState(0),
        [filteredData, setFilteredData] = useState([]),
        [insumo, setInsumo] = useState([]),
        [presentacion, setPresentacion] = useState([]),

        clearData = async () => {
            reset({ unidad: '', accion: '', operacion: '', financiamiento: '', renglon: '', costoU: '' })
            //     setValue('descripcion', '')
            //     setValue('cantidad', '')
            //     setValue('unidadMedida', '')
            //     setValue('renglon', '')
            //     setValue('costo', '')
            //     setValue('costoU', '')
            setRenglon([])
            setInsumo([]);
            setPresentacion([]);
            setAlertaSaldo(false)
        },
        validateRenglonSelection = (allData, inputRenglon) => {
            console.log(allData, inputRenglon);
            if (!allData || !inputRenglon) return false;

            const allDataRenglon = allData?.value !== 2009 && allData?.map(item => item?.renglon);
            const inputDatarenglon = inputRenglon?.label;
            console.log(allDataRenglon, inputDatarenglon);

            // Verificar si hay algún label que comience con '3XX'
            const has3XX = allData?.value !== 2009 && allData?.some(item => item?.renglon?.startsWith('3'));

            // Verificar si hay algún label que no comience con '3XX'
            const hasNon3XX = allData?.value !== 2009 && allData?.some(item => !item?.renglon?.startsWith('3'));

            // Verificar si el inputRenglon comienza con '1XX', '2XX' o '3XX'
            const inputStartsWith3 = inputRenglon?.label?.startsWith('3');
            const inputStartsWith1Or2 = inputRenglon?.label?.startsWith('1') || inputRenglon?.label?.startsWith('2');

            // Verificar si hay un movimientoTexto con "CRÉDITO"
            const creditoIn3XX = allData?.value !== 2009 && allData?.some(item => item?.renglon?.startsWith('3') && item?.movimientoTexto === 'CRÉDITO');
            const creditoInOtherXX = allData?.value !== 2009 && allData?.some(item => !item?.renglon?.startsWith('3') && item?.movimientoTexto === 'CRÉDITO');

            // Si hay un "CRÉDITO" en el grupo 3, permitir cualquier grupo
            if (creditoIn3XX) {
                return true;
            }

            // Si hay un "CRÉDITO" en cualquier otro grupo, no permitir combinación con 3XX
            if (creditoInOtherXX && inputStartsWith3) {
                return false;
            }

            // Si hay un '3XX' y el input es '1XX' o '2XX', devolver false
            if (has3XX && inputStartsWith1Or2) {
                console.log(has3XX, inputStartsWith1Or2);
                return false;
            }

            // Si ya hay '3XX' y el input no es '3XX', devolver false
            if (has3XX && !inputStartsWith3) {
                console.log(has3XX, inputStartsWith3);
                return false;
            }

            // Si hay un renglon que no comience con '3XX' y el input es '3XX', devolver false
            if (hasNon3XX && inputStartsWith3) {
                console.log(hasNon3XX, inputStartsWith3);
                return false;
            }

            return true;
        },

        onSubmit = (data) => {
            if (validateRenglonSelection(allData, inputRenglon)) {
                const json = {
                    idModificacion: props?.oneData && props?.oneData?.id,
                    unidad: data?.unidad?.value ?? props?.oneData?.idUnidad,
                    accion: data?.accion?.value,
                    detalleAccion: (inputRenglon?.value?.length !== 0) ? inputRenglon?.value : oneData?.idDetalleAccion,
                    movimiento: data?.operacion?.value,
                    financiamiento: data?.financiamiento?.value,
                    montoAnterior: seleccionableRenglon?.value === 3 ? 0 : inputRenglon?.saldoRenglonNumeros,
                    montoNuevo: 0,
                    montoDebito: seleccionableRenglon?.value === 1 ? String(data?.costoU).includes('Q') ? Number(data?.costoU.replace('Q', '').replace(',', '').replace(',', '')) : Number(data?.costoU) : 0,
                    montoCredito: seleccionableRenglon?.value === 2 ? String(data?.costoU).includes('Q') ? Number(data?.costoU.replace('Q', '').replace(',', '').replace(',', '')) : Number(data?.costoU) : 0,
                    montoCreditoNuevoRenglon: seleccionableRenglon?.value === 3 ? String(data?.costoU).includes('Q') ? Number(data?.costoU.replace('Q', '').replace(',', '').replace(',', '')) : Number(data?.costoU) : 0,
                    renglonNuevo: seleccionableRenglon?.value === 3 ? inputRenglon?.value : 0,
                    id: (oneData?.length !== 0) ? oneData?.id : null,
                }
                StoreUpdate(json)
                setBtnDisable(false)
                props.setAgregarRenglon(0),
                    clearData(),
                    setCloseSeleccionableInsumos(false)

            } else {


                StoreUpdate(null, 0)

            }
        },
        setData = async () => {
            const monto = oneData?.idMovimiento === 1
                ? oneData?.montoDebitoOne
                : (oneData?.idMovimiento === 2
                    ? (oneData?.montoCreditoOne || 0) + (oneData?.montoCreditoNuevoRenglonOne || 0)
                    : null);

            setValue('unidad', { label: oneData?.nombreUnidadLabel, value: oneData?.idUnidad })
            setValue('accion', { label: oneData?.accion, value: oneData?.idAccion })
            setValue('operacion', { label: oneData?.movimientoTexto, value: oneData?.idMovimiento })
            setValue('financiamiento', { label: oneData?.financiamiento, value: oneData?.idFinanciamiento })
            setValue('renglon', { label: oneData?.renglonLabel, value: oneData?.idDetalleAccion, noRenglon: oneData?.noRenglon })
            setValue('costoU', monto);
            // setValue('costo', oneData?.costoOne)
            // setValue('cantidad', oneData?.cantidad)
            // setValue('unidadMedida', { label: oneData?.unidadMedida, value: oneData?.idUnidadMedida })
            // setValue('renglon', { label: oneData?.renglon, value: oneData?.idDetalleAccion, generaInsumo: oneData?.generaInsumo, saldoRenglonNumeros: oneData?.saldoRenglonNumeros })
            // setRenglon(oneData?.idRenglon)
            // setInsumo(oneData?.insumo);
            // setPresentacion(oneData?.presentacion);
            // setCloseSeleccionableInsumos(true)
        }

    useEffect(
        () => {
            // async function fetchMyAPI() {
            if (actualiza === true) {
                setData()
            }
            // }
            // fetchMyAPI()
        }, [actualiza, oneData]
    )

    // useEffect(() => {
    //     const functionFetch = async () => {
    //         if (props?.oneData && props?.oneData?.id) {
    //             // console.log(props?.oneData?.id)
    //             await setAccion(props?.oneData?.id)
    //         }
    //     }
    //     functionFetch()
    // }, [props?.oneData])




    useEffect(() => {
        if (seleccionableRenglon?.value === 3) {
            setTipoRenglon(true)
        } else {
            setTipoRenglon(false)
            // setValue('renglon', { label: '', value: '' })
        }
        if (seleccionableRenglon === null || seleccionableRenglon === '') {
            setTipoRenglon(false)

            // setRenglon([])
        }
    }, [seleccionableRenglon]);



    useEffect(() => {
        let abortController = new AbortController();
        async function fetchMyAPI() {
            if (seleccionableAccion) {
                if (seleccionableAccion?.value !== 0) {
                    setAccion(seleccionableAccion?.value)
                    // setValidador(true)
                } else {
                    setValidador(false)
                    // setValue('renglon', { label: '', value: '' })
                }
            } if (seleccionableAccion === null || seleccionableAccion === '') {
                setAccion('')
                // setValue('renglon', { label: '', value: '' })
            }
        }
        fetchMyAPI()
        return () => { abortController.abort(); }
    }, [seleccionableAccion])

    useEffect(() => {
        let abortController = new AbortController();
        async function fetchMyAPI() {
            if (seleccionableUnidad) {
                if (seleccionableUnidad?.value !== 0) {
                    setIdUnidad(seleccionableUnidad?.codigo)
                    // setValidador(true)
                } else {
                    setValidador(false)
                }
            } if (seleccionableUnidad === null || seleccionableUnidad === '') {
                setIdUnidad('')
                setValue('accion', { label: '', value: '' })
            }
        }
        fetchMyAPI()
        return () => { abortController.abort(); }
    }, [seleccionableUnidad])
    // console.log(alertaSaldo)


    useEffect(() => {
        const functionFetch = async () => {
            if (props?.oneData && props?.oneData?.id) {
                // console.log(props?.oneData?.id)
                await setIdModificacion(props?.oneData?.id)
            }
        }
        functionFetch()
    }, [props?.oneData])

    // console.log(validateRenglonSelection(allData, inputRenglon))
    return (
        <Fragment>
            <div className={props.agregarRenglon && props.agregarRenglon === 1 ? ` p-3` : `null`}>
                <Form
                    onSubmit={handleSubmit(onSubmit)}
                >
                    {props.agregarRenglon && props.agregarRenglon === 1 ?
                        <Row>
                            <Row>
                                {props.validador === true &&
                                    <Col>
                                        <FormGroup >
                                            <Label >* Unidad</Label>
                                            <Controller
                                                name="unidad"
                                                control={control}
                                                defaultValue={null}
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            {...field}
                                                            isClearable
                                                            onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                            onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                            isDisabled={updateData}
                                                            options={labelUnidades}
                                                            className='selector-react1'
                                                            classNamePrefix='select'
                                                            placeholder={'Seleccione una opción'}
                                                            noOptionsMessage={() => 'sin resultados'}
                                                            styles={(!!errors.unidad) ? customStyles : {}}

                                                        />
                                                    )
                                                }
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <small className="text-danger">{!!errors.unidad && errors.unidad.message}</small>
                                        </FormGroup>
                                    </Col>
                                }
                                <Col>
                                    <FormGroup >
                                        <Label >* Acción</Label>
                                        <Controller
                                            name="accion"
                                            control={control}
                                            defaultValue={null}
                                            render={({ field }) => {
                                                return (
                                                    <Select
                                                        {...field}
                                                        isClearable
                                                        onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                        onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                        isDisabled={updateData}
                                                        options={labelAcciones}
                                                        className='selector-react2'
                                                        classNamePrefix='select'
                                                        placeholder={'Seleccione una opción'}
                                                        noOptionsMessage={() => 'sin resultados'}
                                                        styles={(!!errors.accion) ? customStyles : {}}

                                                    />
                                                )
                                            }
                                            }
                                            rules={
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido'
                                                    }
                                                }
                                            }
                                        />
                                        <small className="text-danger">{!!errors.accion && errors.accion.message}</small>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                {updateData ?
                                    <>
                                        <Col>
                                            <Label >Correlativo</Label><br></br><span >{oneData?.correlativo}</span>
                                        </Col> </> : null
                                }
                                <Col>
                                    {updateData ? <><Label >Operación</Label><br></br><span >{oneData?.accion}</span></>
                                        :
                                        <FormGroup>
                                            <Label for="operacion" className="d-flex justify-content-between">
                                                <span>* Operación</span>
                                            </Label>
                                            <Controller
                                                name="operacion"
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            {...field}
                                                            isClearable
                                                            onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                            onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                            defaultValue={null}
                                                            options={labelTipoOperacion}
                                                            // theme={selectThemeColors}
                                                            // isDisabled={!allMedidaInsumo}
                                                            className='selector-react3'
                                                            classNamePrefix='select'
                                                            placeholder={'Seleccione una opción'}
                                                            noOptionsMessage={() => 'sin resultados'}
                                                            styles={(!!errors.operacion) ? customStyles : {}}
                                                        />
                                                    )
                                                }
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <small className="text-danger">
                                                {!!errors.financiamiento && errors.financiamiento.message}
                                            </small>
                                        </FormGroup>}
                                </Col>
                                <Col md={6}>
                                    {updateData ? <><Label >Fuente</Label><br></br><span>{oneData?.fechaPedido4}</span></>
                                        :

                                        <FormGroup>
                                            <Label for="financiamiento" className="d-flex justify-content-between">
                                                <span>* Fuente</span>
                                            </Label>
                                            <Controller
                                                name="financiamiento"
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            {...field}
                                                            isClearable
                                                            onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                            onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                            defaultValue={null}
                                                            options={labelFinanciamiento}
                                                            // theme={selectThemeColors}
                                                            // isDisabled={!allMedidaInsumo}
                                                            className='selector-react3'
                                                            classNamePrefix='select'
                                                            placeholder={'Seleccione una opción'}
                                                            noOptionsMessage={() => 'sin resultados'}
                                                            styles={(!!errors.financiamiento) ? customStyles : {}}
                                                        />
                                                    )
                                                }
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <small className="text-danger">
                                                {!!errors.financiamiento && errors.financiamiento.message}
                                            </small>
                                        </FormGroup>}
                                </Col>
                                {updateData ?
                                    <>
                                        <Col>
                                            <Label >Para</Label><br></br><span >{oneData?.tipoGasto}</span>
                                        </Col> </> : null
                                }
                                {updateData ?
                                    <>
                                        <Col>
                                            <Label >Tipo Pedido</Label><br></br><span >{oneData?.tipoPedido}</span>
                                        </Col> </> : null
                                }
                            </Row>
                            <Row>
                                {updateData ?
                                    <>
                                        <Col>
                                            <Label >Correlativo</Label><br></br><span >{oneData?.correlativo}</span>
                                        </Col> </> : null
                                }
                                <Col>
                                    {updateData ? <><Label >Renglón</Label><br></br><span >{oneData?.accion}</span></>
                                        :
                                        <FormGroup>
                                            <Label for="renglon" className="d-flex justify-content-between">
                                                <span>* Renglón</span>
                                            </Label>
                                            <Controller
                                                name="renglon"
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            {...field}
                                                            isClearable
                                                            onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                            onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                            defaultValue={null}
                                                            options={tipoRenglon === true ? labelAllRenglon : labelRenglonXAccion}
                                                            // theme={selectThemeColors}
                                                            // isDisabled={!allMedidaInsumo}
                                                            className='selector-react4'
                                                            classNamePrefix='select'
                                                            placeholder={'Seleccione una opción'}
                                                            noOptionsMessage={() => 'sin resultados'}
                                                            styles={(!!errors.renglon) ? customStyles : {}}
                                                        />
                                                    )
                                                }
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <small className="text-danger">
                                                {!!errors.renglon && errors.renglon.message}
                                            </small>
                                        </FormGroup>}
                                </Col>
                                <Col md={3}>
                                    {updateData ? <><Label >Monto</Label><br></br><span>{oneData?.fechaPedido4}</span></>
                                        :

                                        <FormGroup>
                                            <Label for='cantidad' className="">* Monto</Label>
                                            <Controller
                                                defaultValue=''
                                                control={control}
                                                id='costoU'
                                                name='costoU'
                                                render={({ field }) => (
                                                    <CurrencyInput placeholder="Q. 0.00" type="text"
                                                        // className="form-control"
                                                        className={classnames('form-control  invoice-edit-input due-date-picker', {
                                                            'is-invalid': errors.costoU && true
                                                        })}
                                                        // readOnly={opcion === 2}
                                                        // disabled={opcion === 3 || opcion === 4}
                                                        // invalid={errors.costoU && true}
                                                        {...field}
                                                    />
                                                )
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <FormFeedback>
                                                {!!errors.costoU && errors.costoU.message}
                                            </FormFeedback>
                                        </FormGroup>
                                    }
                                </Col>

                                <Col sm={2} lg={2} className="text-center">
                                    <Button type="submit" color={!btnDisable ? 'success' : "info"} disabled={alertaSaldo} size="sm" id="AgAct2" className="btn-icon mt-2 me-1">
                                        <Icon.Save size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='AgAct2'>
                                        {'Agregar'}
                                    </UncontrolledTooltip>
                                    <Button color="danger" size="sm" type="button" className="btn-icon text-center mt-2 me-1" id="Cancelar" onClick={() => [props.setAgregarRenglon(0),
                                    clearData(),
                                    setCloseSeleccionableInsumos(false)]}>
                                        <Icon.Slash size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='Cancelar'>
                                        Cancelar
                                    </UncontrolledTooltip>
                                </Col>
                                {alertaSaldo === true &&
                                    <Row>
                                        <FormGroup>
                                            <small className="text-danger fw-bolder">
                                                {'* El Costo Total no puede ser mayor al saldo disponible del Renglón.'}
                                            </small>
                                        </FormGroup>
                                    </Row>}
                                {updateData ?
                                    <>
                                        <Col>
                                            <Label >Para</Label><br></br><span >{oneData?.tipoGasto}</span>
                                        </Col> </> : null
                                }
                                {updateData ?
                                    <>
                                        <Col>
                                            <Label >Tipo Pedido</Label><br></br><span >{oneData?.tipoPedido}</span>
                                        </Col> </> : null
                                }
                            </Row>

                        </Row>
                        : null}

                </Form>
            </div>
            <List agregarRenglon={props.setAgregarRenglon} />
        </Fragment>
    )
}

export default DetalleModificaciones