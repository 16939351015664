import React from 'react'
import { Alert, Badge } from 'reactstrap'
import { createTheme } from 'react-data-table-component';

createTheme('dark', {
  text: {
    primary: '#FFFFFF',
    secondary: 'rgba(255, 255, 255, 0.7)',
    disabled: 'rgba(0,0,0,.12)',
  },
  background: {
    default: '#1E1E2D',
  },
  context: {
    background: '#E91E63',
    text: '#FFFFFF',
  },
  divider: {
    default: 'rgba(81, 81, 81, 1)',
  },
  button: {
    default: '#FFFFFF',
    focus: 'rgba(255, 255, 255, .54)',
    hover: 'rgba(255, 255, 255, .12)',
    disabled: 'rgba(255, 255, 255, .18)',
  },
  selected: {
    default: 'rgba(0, 0, 0, .7)',
    text: '#FFFFFF',
  },
  highlightOnHover: {
    default: '#1E1E2D',
    text: '#FFFFFF',
    background: '#e3f2fd',
  },
  striped: {
    default: '#151521',
    text: '#FFFFFF',
  },
}, 'solarized')


export const CustomText = (data) => {
  return <div data-tag="allowRowEvents" style={{ fontSize: '15px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
    {data}
  </div>
}

export const CustomTextHora = (data) => {
  return <div data-tag="allowRowEvents" style={{ fontSize: '12px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
    {data}
  </div>
}

export const TextDetalleAccion = (data) => {
  return <div data-tag="allowRowEvents" style={{ fontSize: '12px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
    {data}
  </div>
}


export const EstadoLista = ({ row }) => {
  const Variante = [
    'danger',
    'success',
    'warning'
  ]


  return (
    <Badge color={Variante[row.estado]} className={`badge-soft-${Variante[row.estado]} text-center font-weight-bolder fs-6`} style={{ padding: '8px', width: '90%' }}>
      {(row.estado === 0) && 'Inactivo'}
      {(row.estado === 1) && 'Activo'}
    </Badge>
  )
}

export const EstadoPOA = ({ row }) => {
  const Variante = [
    'danger',
    'primary',
    'success',
    'warning'
  ]

  return (
    <Badge color={Variante[row.estado]} className={`badge-soft-${Variante[row.estado]} text-center font-weight-bolder fs-6`} style={{ padding: '8px', width: '90%' }}>
      {(row.estado === 0) && 'Desactivado'}
      {(row.estado === 1) && 'Creado'}
      {(row.estado === 2) && 'Aprobado'}
      {(row.estado === 3) && 'Rechazado'}
    </Badge>
  )
}
export const SiguientePasoText = ({ row }) => {
  const Variante = [
    'warning',  // Estado 4, 10
    // 'dark' ,     // Estado 6, 12
    'primary',  // Estado 2, 8
    'success',  // Estado 3, 9
    // 'danger',   // Estado 1, 7
    'info',     // Estado 5, 11
];

  return (
    <Badge color={Variante[row.idEstado % 6+1]} className={`badge-soft-${Variante[row.idEstado % 6+1]} text-center font-weight-bolder `} style={{ padding: '8px', width: '100%', fontSize: '11.5px' }}>
      {(row.siguientePaso)}
    </Badge>
  )
}
export const EstadoGastosLiqText = ({ row }) => {
  const Variante = [
    'warning',  // Estado 4, 10
    // 'dark' ,     // Estado 6, 12
    'primary',  // Estado 2, 8
    'success',  // Estado 3, 9
    // 'danger',   // Estado 1, 7
    'info',     // Estado 5, 11
];

  return (
    <Badge color={Variante[row.idEstadoLiquidado % 6+1]} className={`badge-soft-${Variante[row.idEstado % 6+1]} text-center font-weight-bolder `} style={{ padding: '8px',  fontSize: '9px' }}>
      {(row.nombreEstadoLiquidado)}
    </Badge>
  )
}
export const EstadoText = ({ row }) => {
  const Variante = [
    'primary',  // Estado 1
    'warning',  // Estado 3
    'success',  // Estado 2
    'info',     // Estado 4
    'primary' , // Estado 5
    // Los estados 6 a 43 repetirán los colores de los estados 0 a 5
  ];

  let color = Variante[row.idEstado % 6]; // Utilizamos % 6 para repetir los colores de los primeros 6 estados

  if (row.idEstado === 2) {
    color = 'danger'; // Estado 2 será siempre 'danger'
  }

  return (
    <Badge color={color} className={`badge-soft-${color} text-center font-weight-bolder `} style={{ padding: '8px', width: '100%', fontSize: '1.7vh' }}>
      {row?.estado}
    </Badge>
  );
}
export const EstadoTextMP = ({ row }) => {
  const Variante = [
      'danger',   // Estado 0
      'warning',  // Estado 3
      'primary',  // Estado 1
      'dark' ,     // Estado 5
      'info',     // Estado 4
      'success',  // Estado 2
      // Los estados 6 a 11 repetirán los colores de los estados 0 a 5
  ];

  return (
      <Badge color={Variante[row.idEstado % 5]} className={`badge-soft-${Variante[row.idEstado % 5]} text-center font-weight-bolder fs-6`} style={{ padding: '8px', width: '90%' }}>
          {row.estado}
      </Badge>
  );
}

export const EstadoGeneraInsumo = ({ row }) => {
  const Variante = [
    'primary',
    'warning'
  ]

  return (
    <Badge color={Variante[row.generaInsumo]} className={`badge-soft-${Variante[row.generaInsumo]} text-center font-weight-bolder fs-6`} style={{ padding: '8px', width: '90%' }}>
      {(row.generaInsumo === 0) && 'No'}
      {(row.generaInsumo === 1) && 'Si'}
    </Badge>
  )
}

export default (
  CustomText,
  EstadoTextMP,
  CustomTextHora,
  TextDetalleAccion,
  EstadoLista,
  EstadoGeneraInsumo,
  EstadoPOA,
  SiguientePasoText,
  EstadoGastosLiqText
)