import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { useDataUser } from 'hooks/useDataUser'
import { toAbsoluteUrl } from '../../../helpers/AssetHelper'
// users
// import user1 from "../../../assets/images/users/avatar-1.jpg";
// import user1 from "../../../../public/media/logos/170.jpg";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const dataUser = useDataUser()?.dataUser;
  const RoutePathServer = process.env.REACT_APP_PATH_SERVER;
  const [menu, setMenu] = useState(false);
  // const Blowfish = require('javascript-blowfish');
  // const bf = new Blowfish(process.env.REACT_APP_CONFIG_KEY);
  // const [username, setusername] = useState("Admin");
  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  //     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //       const obj = JSON.parse(localStorage.getItem("authUser"));
  //       setusername(obj.displayName);
  //     } else if (
  //       process.env.REACT_APP_DEFAULTAUTH === "fake" ||
  //       process.env.REACT_APP_DEFAULTAUTH === "jwt"
  //     ) {
  //       const Decript = bf.decrypt(bf.base64Decode(localStorage.getItem('authUser')));
  //       const data = JSON.parse(Decript.replace(/\0/g, ''));
  //       setData(data)
  //       setusername(data.nombreCompleto);
  //     }
  //   }
  // }, [props.success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle style={{ margin: 0 }}
          className="btn header-item d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          {dataUser && dataUser?.foto ?
            <img
              src={(`${(RoutePathServer) + (dataUser && dataUser?.foto)}`)}
              alt=""
              className="rounded-circle header-profile-user"
            />
            : null}
          <span className="d-none d-xl-inline-block ms-2 me-1">{dataUser && dataUser?.nombreCompleto}<p className="mb-0" style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '10px', flexWrap: 'wrap' }}>{dataUser && dataUser?.esFADN === "1" ? dataUser?.tipoUsuarioFADN + "/" + dataUser?.nombreFADN : dataUser && dataUser?.puesto + "/" + dataUser?.iniciales}</p></span>

          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu style={{ margin: 0 }} className="dropdown-menu-end !m-0">
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {"Perfil"}{" "}
          </DropdownItem>
          {/* <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t("My Wallet")}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </DropdownItem> */}
          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
