import { Button, Col, Label, Row, UncontrolledTooltip } from 'reactstrap'
import { ContentContext } from './context'
import { TextDetalleAccion, EstadoLista } from '../../../CustomList/index'
import * as Icon from 'react-feather'
import DataTable from 'react-data-table-component'
import React, { Fragment, useContext, useState, useEffect } from 'react'
import { ContentContext as ContentContextTheme } from '../../../components/CommonForBoth/TopbarDropdown/context'


const List = (props) => {
  const { theme } = useContext(ContentContextTheme)
  const { allData, OneDetalle, Actions, btnDisable } = useContext(ContentContext)


  const [columns, setColumns] = useState([]);
  const fechaCorte = new Date('2024-12-30');

  useEffect(() => {
    const hoy = new Date();
    const columnasBase = [
      {
        name: 'Renglón',
        column: 'renglon',
        sortable: true,
        center: false,
        width: '25%',
        cell: row => TextDetalleAccion(row['renglon'])
      },
      {
        name: 'Fuente',
        column: 'financiamiento',
        sortable: true,
        center: false,
        width: '15%',
        cell: row => TextDetalleAccion(row['financiamiento'])
      },
      {
        name: 'Inicial',
        column: 'costo',
        sortable: true,
        center: false,
        width: '15%',
        cell: row => TextDetalleAccion(row['costo'])
      },
      {
        name: 'Comprometido',
        column: 'ejecutadoCostoAccion',
        sortable: true,
        center: false,
        width: '15%',
        cell: row => TextDetalleAccion(row['ejecutadoCostoAccion'])
      },
      {
        name: 'Saldo',
        column: 'saldoCostoAccion',
        sortable: true,
        center: false,
        width: '15%',
        // cell: row => TextDetalleAccion('Q.0.00')
        cell: row => TextDetalleAccion(row['saldoCostoAccion'])
      },
      // {
      //   name: 'Estado',
      //   column: 'estado',
      //   sortable: true,
      //   center: true,
      //   width: '12%',
      //   cell: row => <EstadoLista row={row} />
      // },

    ];

    if (hoy <= fechaCorte) {
      columnasBase.push({
        name: 'Acciones',
        column: 'id',
        sortable: true,
        center: true,
        width: '15%',
        cell: row => (
          <Fragment>
            <div className={`btn-icon  px-2 cursor-pointer ${btnDisable && 'cursor-not-allowed'}`} color='flat-white' id='Actualizar' disabled={btnDisable} style={{ outline: 'none' }} size="sm"
              onClick={() => [props.agregarRenglon(1), OneDetalle(row)]}>
              <Icon.Edit size={16} />
            </div>
            <UncontrolledTooltip placement='top' target='Actualizar'>
              Actualizar
            </UncontrolledTooltip>
            <div className={`btn-icon px-2 cursor-pointer ${btnDisable && 'cursor-not-allowed'}`} id='Eliminar' color='flat-white' disabled={btnDisable} size="sm" style={{ outline: 'none' }}
              onClick={() => Actions(row)}
            >
              {row.estado === 1 ? <Icon.Trash size={19} className="me-2" /> : <Icon.ToggleRight size={19} className="me-2" />}
            </div>

            <UncontrolledTooltip placement='top' target='Eliminar'>
              {row.estado === 1 ? 'Eliminar' : 'Habilitar'}
            </UncontrolledTooltip>
          </Fragment>
        )
      });
    }

    setColumns(columnasBase);
  }, []); // Dependencias vacías para que se ejecute solo al montar el componente




  return (
    <Fragment>
      <div className='card'>
        <DataTable
          // dense
          striped
          noHeader
          fixedHeader
          fixedHeaderScrollHeight="275px"
          highlightOnHover
          theme={theme}
          // pagination
          data={(allData && allData?.value !== 2009 ? allData : [])}
          columns={columns}
          // className='ps'
          noDataComponent='Sin Registros'
        />
        <Row style={{ padding: '0' }}>
          <Col className='text-center'>
            <div className='card'><Row ><Label >Total Renglones Inicial</Label><br></br><span className='fs-4'>{
              props?.total && props?.total[0]?.totalPOAconQ ? props.total?.at(0)?.totalPOAconQ : `Q.00`
            }</span></Row></div>
          </Col>
          <Col className='text-center'>
            <div className='card'><Row ><Label >Total Ejecutado</Label><br></br><span className='fs-4'>{props?.total && props?.total[0]?.totalEjecutadoQ ? props.total?.at(0)?.totalEjecutadoQ : `Q.00`}</span></Row></div>
          </Col>
          <Col className='text-center'>
            <div className='card'><Row ><Label >Total Saldo</Label><br></br><span className='fs-4'>{props?.total && props?.total[0]?.saldoNumerosQ ? props.total?.at(0)?.saldoNumerosQ : `Q.00`}</span></Row></div>
          </Col>
        </Row>
      </div>
    </Fragment>
  )
}

export default List