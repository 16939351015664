import React, { Fragment, useEffect, useContext } from "react"
import { Card, CardBody, Row, Col, Button, UncontrolledTooltip } from "reactstrap";

import { ToastContainer, toast } from 'react-toastify';
// import { OptionsToast } from '../../../../utility/FuncionesGlobales/index'
import { CornerDownLeft } from "react-feather";
import * as Icon from "react-feather";
import { useHistory } from 'react-router-dom'
import { useRequest } from '../../../hooks/useRequestSWR'
import { useRouteCode } from 'hooks/useRouteCode'
import { ContentProvider } from './context'
import EmailComponent from './Email/email-read'

const Index = () => {
  const nameController = 'Documentos';
  const history = useHistory()
  const cod = useRouteCode()?.codigo;
  const { data: allDetalle } = useRequest(`${nameController}/one-Documento`, 'POST', { id: 0, code: cod });
  useEffect(
    () => {
      (history.location.pathname === '/documentos/detalle') && history.push("/")
    }, [history]
  )



  // function interpretHTML() {
  //   return {
  //     __html: (dataBorrador) && dataBorrador.contenido
  //   };
  // };

 return (

    <Fragment>
      <ContentProvider>
        <EmailComponent />
      </ContentProvider>
      <div className="page-content">
        <Row>
          {/* <Card>
            <CardBody>

              {allDetalle && allDetalle[0]?.nombre}
            </CardBody>
          </Card> */}
          <Col lg={8}>
            {/* <Formulario
            dataBorrador={dataBorrador}
            code={code}
            interpretHTML={interpretHTML}
            showFirmantes={showFirmantes}
          /> */}
          </Col>
          <Col lg={4}>
            {/* <Comentarios
            dataBorrador={dataBorrador}
          /> */}
          </Col>
        </Row>
        <ToastContainer />
      </div>
    </Fragment>
  );
}

export default Index;
