import { Button, Col, Label, Row, UncontrolledTooltip } from 'reactstrap'
import { ContentContext } from './context'
import { TextDetalleAccion, EstadoLista } from '../../../CustomList/index'
import * as Icon from 'react-feather'
import DataTable from 'react-data-table-component'
import React, { Fragment, useContext } from 'react'
import { ContentContext as ContentContextTheme } from '../../../components/CommonForBoth/TopbarDropdown/context'


const List = (props) => {
  const { theme } = useContext(ContentContextTheme)
  const { allData, OneDetalle, Actions, btnDisable, setActualiza, totalDetallePedido } = useContext(ContentContext),


    Columns = [
      {
        name: 'Renglón',
        column: 'renglon',
        sortable: true,
        center: false,
        cell: row => TextDetalleAccion(row['renglon'])
      },
      {
        name: 'Medida',
        column: 'unidadMedidaInsumo',
        sortable: true,
        center: false,
        cell: row => TextDetalleAccion(row['unidadMedidaInsumo'])
      },
      {
        name: 'Cantidad',
        column: 'cantidad',
        center: false,
        cell: row => TextDetalleAccion(row['cantidad'])
      },
      {
        name: 'Descripción',
        column: 'descripcion',
        sortable: true,
        grow: 7, // Ajusta el valor según sea necesario para dar más espacio a la columna
        wrap: true,
        cell: row => <div style={{ whiteSpace: 'normal', textAlign: 'justify', fontSize: '12px' }}>{row.descripcion}</div> // Esto asegura que el contenido no se trunca

    },
      {
        name: 'Costo U.',
        column: 'costoUnitario',
        sortable: true,
        center: false,
        cell: row => TextDetalleAccion(row['costoUnitario'])
      },
      {
        name: 'Costo Total',
        column: 'costo',
        sortable: true,
        center: false,
        cell: row => TextDetalleAccion(row['costo'])
      },
      // {
      //   name: 'Cod. Insumo',
      //   column: 'insumo',
      //   sortable: true,
      //   center: false,
      //   cell: row => TextDetalleAccion(row['insumo'])
      // },
      // {
      //   name: 'Cod. Pres',
      //   column: 'presentacion',
      //   sortable: true,
      //   center: false,
      //   cell: row => TextDetalleAccion(row['presentacion'])
      // },
      {
        name: 'Acciones',
        column: 'id',
        sortable: true,
        center: true,
        cell: row => (
          <Fragment>
            <div className={`btn-icon  px-2 cursor-pointer ${btnDisable && 'cursor-not-allowed'}`} color='flat-white' id='Actualizar' disabled={btnDisable} style={{ outline: 'none' }} size="sm"
              onClick={() => [props?.agregarRenglon(1), OneDetalle(row), setActualiza(true)]}>
              <Icon.Edit size={16} />
            </div>
            <UncontrolledTooltip placement='top' target='Actualizar'>
              Actualizar
            </UncontrolledTooltip>
            <div className={`btn-icon px-2 cursor-pointer ${btnDisable && 'cursor-not-allowed'}`} id='Eliminar' color='flat-white' disabled={btnDisable} size="sm" style={{ outline: 'none' }}
              onClick={() => Actions(row)}
            >
              {row.estado === 1 ? <Icon.Trash size={19} className="me-2" /> : <Icon.ToggleRight size={19} className="me-2" />}
            </div>

            <UncontrolledTooltip placement='top' target='Eliminar'>
              {row.estado === 1 ? 'Eliminar' : 'Habilitar'}
            </UncontrolledTooltip>
          </Fragment>
        )
      }
    ]

  return (
    <Fragment>
      <div className='card'>
        <DataTable
          // dense
          striped
          noHeader
          fixedHeader
          fixedHeaderScrollHeight="275px"
          highlightOnHover
          theme={theme}
          // pagination
          data={(allData && allData.value !== 2009 ? allData : [])}
          columns={Columns}
          // className='ps'
          noDataComponent='Sin Registros'
        />
        <Row style={{ padding: '0' }}>
          <Col className='text-center'>
            {/* <div className='card'><Row ><Label >Total POA</Label><br></br><span className='fs-4'>{
              props.total && props?.total[0]?.totalPOAconQ ? props.total?.at(0)?.totalPOAconQ : `Q.00`
            }</span></Row></div> */}
          </Col>
          <Col className='text-center'>
            {/* <div className='card'><Row ><Label >Total Ejecutado</Label><br></br><span className='fs-4'>{'Q.0.00'}</span></Row></div> */}
          </Col>
          <Col className='text-center'>
            <div className='card'><Row ><Label >Total Pedido</Label><br></br><span className='fs-4 fw-bold'>{totalDetallePedido?.[0]?.totalQ ? totalDetallePedido?.[0]?.totalQ : `Q.00`}</span></Row></div>
          </Col>
        </Row>
        
      </div>
    </Fragment>
  )
}

export default List