import React, { Fragment, useContext, useEffect, useState, useRef } from "react"
import {
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  UncontrolledTooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormFeedback,
} from "reactstrap"
import { ContentContext } from "./context"
import * as Icon from "react-feather"
import { useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { customStyles, formatDate } from "../../../../Globales/index"


const Formulario = () => {
  const { oneData, show, labelUnidades, labelDependencia, empleados, toggleModal, id, setId, chiefUnit } = useContext(ContentContext)
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
    watch,
  } = useForm()

  const [persona, setPersona] = useState({
    label: "",
    value: 0
  })

  const seleccionableUnidad = watch("unidad")

  useEffect(() => {
    let abortController = new AbortController()
    async function fetchMyAPI() {
      if (
        seleccionableUnidad !== null ||
        seleccionableUnidad !== "" ||
        seleccionableUnidad !== undefined
      ) {
        setId(seleccionableUnidad?.value)
        setValue("persona", {
          label: chiefUnit[0]?.label,
          value: chiefUnit[0]?.value
        })
      }
      if (
        seleccionableUnidad === null ||
        seleccionableUnidad === "" ||
        seleccionableUnidad === undefined
      ) {
        setValue("persona", "")
        setId(0)
      }
    }
    fetchMyAPI()
    return () => {
      abortController.abort()
    }
  }, [ chiefUnit])

  const onSubmit = data => {
    const json = {
      documento: oneData.id,
      dependencia: data.dependencia.value,
    }
    /*  StoreUpdate(json) */
    console.log(json)
    reset()
  }

  return (
    <Fragment>
      <Button
        onClick={() => toggleModal(1)}
        id="crearRef"
        color="primary"
        size="sm"
        className="mx-1 my-1"
      >
        <Icon.Plus size={16} />
      </Button>
      <UncontrolledTooltip
        placement="left"
        target="crearRef"
        placementPrefix="bs-tooltip"
      >
        Crear
      </UncontrolledTooltip>

      <Modal
        isOpen={show}
        backdrop="static"
        //size="lg"
        style={{ maxWidth: "1800px", width: "50%", margin: "10px auto", zIndex: -1 }}
        toggle={() => toggleModal()}
        className="modal-dialog-centered"
      
      >
        <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>
          {oneData ? "Agregar " : "Modificar "  +  " Referencia"}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>* Unidad</Label>
                      <Controller
                        name="unidad"
                        control={control}
                        defaultValue={null}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              isClearable
                              onChange={field.onChange} //resuelve el tener que dar doble click en movil
                              onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                             // isDisabled={opcion === 4 || opcion === 2}
                              options={labelUnidades}
                              className="react-select"
                              classNamePrefix="select"
                              placeholder={"Seleccione una opción"}
                              noOptionsMessage={() => "sin resultados"}
                              styles={!!errors.unidad ? customStyles : {}}
                            />
                          )
                        }}
                        rules={{
                          required: {
                            value: true,
                            message: "Este campo es requerido",
                          },
                        }}
                      />
                      <small className="text-danger">
                        {!!errors.unidad && errors.unidad.message}
                      </small>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>* Dirigido A</Label>
                      <Controller
                        name="persona"
                        control={control}
                        defaultValue={persona}
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              isClearable
                              onChange={field.onChange} //resuelve el tener que dar doble click en movil
                              onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                              //isDisabled={opcion === 2}
                              options={empleados}
                              className="selector-react2"
                              classNamePrefix="select"
                              placeholder={"Seleccione una opción"}
                              noOptionsMessage={() => "sin resultados"}
                              styles={!!errors.persona ? customStyles : {}}
                            />
                          )
                        }}
                        rules={{
                          required: {
                            value: true,
                            message: "Este campo es requerido",
                          },
                        }}
                      />
                      <small className="text-danger">
                        {!!errors.persona && errors.persona.message}
                      </small>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
                  <Button
                    id="salir"
                    color="secondary"
                    onClick={() => toggleModal(0)}
                  >
                    <Icon.CornerUpLeft size={16} />
                  </Button>
                  <UncontrolledTooltip
                    placement="top"
                    target="salir"
                    placementPrefix="bs-tooltip"
                  >
                    Salir
                  </UncontrolledTooltip>
                  <Fragment>
                    <Button
                      color="success"
                      type="submit"
                      id="accion"
                    >
                      <Icon.Save size={16} />
                    </Button>
                    <UncontrolledTooltip
                      placement="top"
                      target="accion"
                      placementPrefix="bs-tooltip"
                    >
                      {oneData.length === 0 ? "Crear" : "Actualizar"}
                    </UncontrolledTooltip>
                  </Fragment>
                </ModalFooter>
      </Modal>
    </Fragment>
  )
}

export default Formulario
