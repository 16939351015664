
// import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useContext, useEffect, useState, useRef } from 'react'
import { ContentContext } from './context'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback, CardBody, CardTitle } from 'reactstrap'
import { useForm, Controller } from "react-hook-form"
import * as Icon from 'react-feather'
import Select from 'react-select'
import { formatDate, customStyles, stringToDate } from '../../../Globales/index'
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput'
import classnames from 'classnames'
import { Text } from "@nextui-org/react";
import Header from './header'
import Detalle from '../DetalleGestiones/form'
import { ContentProvider } from '../DetalleGestiones/context'
import revision from '../../../assets/images/Checklist.png'
import DatePicker, { registerLocale } from "react-datepicker"
import es from "date-fns/locale/es"
import Moment from 'react-moment';
import 'moment/locale/es-mx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";
import "/node_modules/swiper/swiper.scss";
registerLocale("es", es); // register it with the name you want


const Formulario = () => {
    // const [startDate, setStartDate] = useState(Date.parse(2019) + 1);
    const { toggleModal, modal, opcion, oneData, agregarRenglon, setAgregarRenglon, nameComponent, pdf, UpdateEstadoPedido, DataBitacoraPedido, EnviarARevision, EnviarEncuesta, opcion2, toggleModal2, modal2, esFADN, idValidaEncuesta } = useContext(ContentContext),
        { register, handleSubmit, formState: { errors }, control, reset, watch } = useForm(),
        inputTipoRechazo = watch("tipoRechazo"),
        inputEvaluacionSoporte = watch("evaluacionSoporte");
    const [tipoRechazo, setTipoRechazo] = useState(0);
    const [valorEncuesta, setValorEncuesta] = useState(0),
        onSubmitEstado = (data) => {
            if (opcion === 7) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: Number(data?.tipoRechazo),
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 1
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 8) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 2
                }

                UpdateEstadoPedido(json)
            }
            if (opcion === 9) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 3
                }

                UpdateEstadoPedido(json)
            }
            if (opcion === 10) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: Number(data?.tipoRechazo),
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 1
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 11) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 4
                }

                UpdateEstadoPedido(json)
            }
            if (opcion === 12) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: Number(data?.tipoRechazo),
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 1
                }
                UpdateEstadoPedido(json)
            }
            if (opcion === 13) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 6
                }

                UpdateEstadoPedido(json)
            }
            if (opcion === 14) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: Number(data?.tipoRechazo),
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 1
                }
                UpdateEstadoPedido(json)
            }
            reset()
            toggleModal(0)
        },
        onSubmitEnviarRevision = (data) => {
            if (opcion === 20) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 10
                }

                EnviarARevision(json)
                UpdateEstadoPedido(json)
            }
            if (opcion === 21) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    evaluacionSoporte: Number(data?.evaluacionSoporte),
                    descripcion: (data?.comentario?.length !== 0) ? data?.comentario : null,
                    estado: 15
                }

                EnviarEncuesta(json)
                UpdateEstadoPedido(json)
            }
            if (opcion === 25) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: 3,
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 23,
                }
                UpdateEstadoPedido(json)
            }

            reset()
            toggleModal(0)
        };

    const prevInputTipoRechazo = useRef(null);

    useEffect(() => {
        if (!isNaN(inputTipoRechazo)) {
            setTipoRechazo(Number(inputTipoRechazo));
            prevInputTipoRechazo.current = inputTipoRechazo;
        }
    }, [inputTipoRechazo]);


    const prevEvaluacionSoporte = useRef(null);

    useEffect(() => {
        if (!isNaN(inputEvaluacionSoporte)) {
            // console.log(Number(inputEvaluacionSoporte))
            setValorEncuesta(Number(inputEvaluacionSoporte));
            prevEvaluacionSoporte.current = inputEvaluacionSoporte;
        }
    }, [inputEvaluacionSoporte]);


    return (
        <Fragment>
            {idValidaEncuesta !== 0 ?
                <>
                    <Button id="error" color="danger" size="sm">
                        <Icon.X size={16} />
                    </Button>
                    <UncontrolledTooltip placement='left' target='error' placementPrefix="bs-tooltip">
                        No es posible crear una nueva gestión hasta que se haya respondido la encuesta de satisfacción!
                    </UncontrolledTooltip>
                </>
                :
                <>
                    <Button onClick={() => toggleModal(1)} id="crear" color="primary" size="sm">
                        <Icon.Plus size={16} />
                    </Button>
                    <UncontrolledTooltip placement='left' target='crear' placementPrefix="bs-tooltip">
                        Crear
                    </UncontrolledTooltip>
                </>
            }
            {opcion > 0 && opcion < 5 &&
                <Modal
                    isOpen={modal}
                    backdrop='static'
                    style={{ maxWidth: '1600px', width: '80%', margin: '10px auto' }}
                    toggle={() => toggleModal()}
                    className='modal-dialog-centered modal-xl'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}> {opcion === 1 ? "Crear" : opcion === 3 ? "Actualizar" : "Visualizar"}{` ${nameComponent}`}</ModalHeader>
                    <ModalBody style={(opcion === 1 ? {} : { backgroundColor: 'rgb(0 0 0 / 7%)', paddingBottom: '0' })}>
                        <Header />
                        {(oneData && oneData.id) &&
                            <ContentProvider>
                                <Detalle oneData={oneData} agregarRenglon={agregarRenglon} setAgregarRenglon={setAgregarRenglon} />
                            </ContentProvider>
                        }
                    </ModalBody>
                    {/* <ModalFooter>
                    <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                        <Icon.CornerUpLeft size={16} />
                    </Button>
                    <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                        Salir
                    </UncontrolledTooltip>
                </ModalFooter> */}
                </Modal>}
            {/* Opcion Ver PROCESO */}
            {opcion === 6 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered modal-xl'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>Proceso</ModalHeader>
                <Form autoComplete="off"
                >
                    <ModalBody>
                        <>
                            <Row>
                                <Col lg="12">
                                    <div>
                                        <CardBody>
                                            <CardTitle className="mb-4 text-center">Tiempo transcurrido: {DataBitacoraPedido && DataBitacoraPedido[DataBitacoraPedido.length - 1]?.tiempoTranscurrido} || Solicitado:   <Moment locale="es" format='lll' fromNow>{DataBitacoraPedido && DataBitacoraPedido[0]?.fechaCreado}</Moment></CardTitle>
                                            {/* <h5 className="mb-4">Línea de Tiempo</h5> */}

                                            <div className="hori-timeline">
                                                <Swiper
                                                    slidesPerView={1}
                                                    // spaceBetween={10}
                                                    navigation
                                                    pagination={{
                                                        clickable: true,
                                                    }}
                                                    breakpoints={{
                                                        678: {
                                                            slidesPerView: 2,
                                                        },
                                                        992: {
                                                            slidesPerView: 3,
                                                        },
                                                        1400: {
                                                            slidesPerView: 4,
                                                        }
                                                    }}
                                                    loop={false}
                                                    modules={[Pagination, Navigation]}
                                                    className="owl-carousel owl-theme  navs-carousel events"
                                                    id="timeline-carousel"
                                                >
                                                    {DataBitacoraPedido && DataBitacoraPedido?.map((item, index) => (
                                                        <SwiperSlide
                                                            key={index}
                                                            className="item event-list"
                                                            style={{ display: "inline-table" }}
                                                        >
                                                            <div>
                                                                <div className="event-date">
                                                                    <div className="text-primary mb-1">
                                                                        <Moment locale="es" format='lll' fromNow>{item?.creado}</Moment>
                                                                    </div>
                                                                    <h5 className="mb-4">{item?.estadoTexto}</h5>
                                                                </div>
                                                                <div className="event-down-icon">
                                                                    <i className={`bx ${item?.estadoTexto?.includes('Rechazado') ? 'bx-x-circle text-danger' : 'bx-check-circle text-success'} h1  down-arrow-icon`} />
                                                                </div>

                                                                <div className="mt-3 px-3">
                                                                    <p className="text-muted">
                                                                        {item?.usuario}
                                                                    </p>
                                                                </div>
                                                                <div className="mt-3 px-3">
                                                                    <p className="text-muted">
                                                                        <strong>{item?.rechazoTipoTexto}</strong>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}

                                                </Swiper>
                                            </div>
                                        </CardBody>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Regresar
                        </UncontrolledTooltip>
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Ver PDF */}
            {opcion === 5 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered modal-xl'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>PDF</ModalHeader>
                <Form autoComplete="off"
                >
                    <ModalBody>
                        <Row>
                            <Col >
                                <object
                                    className='Visor'
                                    width='100%'
                                    height='600vh'
                                    id='pdf-ficha'
                                    aria-labelledby='perfil'
                                    type='application/pdf'
                                    // data={`https://s1.q4cdn.com/806093406/files/doc_downloads/test.pdf`}
                                    data={`${pdf}`}
                                />
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Regresar
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <a className="btn btn-success " id="imprimir" download="documento.pdf" href={`${pdf}`} target="_blank" rel="noopener noreferrer"><Icon.Download size={16} /> Descargar</a>
                                {/* <Button color='success' type="submit" id="accion">
                                    <Icon.Printer size={16} />
                                </Button> */}
                                {/* <UncontrolledTooltip placement='top' target='imprimir' placementPrefix="bs-tooltip">
                                    Imprimir
                                </UncontrolledTooltip> */}
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}

            {/* Opcion Enviar a Revisión Vista Previa */}
            {opcion === 20 && <Modal
                isOpen={modal}
                backdrop='static'
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 20 && 'Enviar la'} Gestión?</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEnviarRevision)}>
                    <ModalBody>
                        <Row >
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    ¿Está seguro de que todo está bien y desea proceder?
                                </Text>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Send size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 20 && 'Enviar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion Responder Encuesta */}
            {opcion === 21 && <Modal
                isOpen={modal}
                backdrop='static'
                style={{ maxWidth: '1600px', width: '80%', margin: '10px auto' }}
                toggle={() => toggleModal()}
                size='lg'
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 21 && 'RESPONDER ENCUESTA DE SATISFACCIÓN DE LA'} Gestión</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEnviarRevision)}>
                    <ModalBody>
                        <Row>
                            <Label className=" justify-content-between">Seleccione una opción</Label>
                            <Col >
                                <div className="form-check mb-2" style={{ display: 'flex', alignItems: 'center', marginLeft: '40px' }}>
                                    <input className="form-check-input"  {...register("evaluacionSoporte")} type="radio" style={{ fontSize: 'large', backgroundColor: '#8b9094' }} value={1} />
                                    <label className="form-check-label ps-3" htmlFor={1}>
                                        Satisfecho
                                    </label>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-check mb-2" style={{ display: 'flex', alignItems: 'center', marginLeft: '40px' }}>
                                    <input className="form-check-input " {...register("evaluacionSoporte")} type="radio" style={{ fontSize: 'large', backgroundColor: '#8b9094' }} value={2} />
                                    <label className="form-check-label ps-3" htmlFor={2}>
                                        No Satisfecho
                                    </label>
                                </div>
                            </Col>
                            {/* <FormFeedback>
                                        {!!errors.comentario && errors.comentario.message}
                                    </FormFeedback> */}
                        </Row>
                        <Row>
                            <Col >
                                <FormGroup>
                                    <Label for="comentario" className="d-flex justify-content-between"> Comentario <small>(Opcional - Máximo 500 caracteres)</small> </Label>
                                    <Controller

                                        control={control}
                                        id='comentario'
                                        name='comentario'
                                        render={({ field }) => (
                                            <Input
                                                rows={2}
                                                type='textarea'
                                                // readOnly={updateData}
                                                // invalid={errors.comentario && true}
                                                defaultValue=''
                                                {...field}
                                            />
                                        )
                                        }
                                    // rules={
                                    //     {
                                    //         required: {
                                    //             value: true,
                                    //             message: 'Este campo es requerido'
                                    //         }
                                    //     }
                                    // }
                                    />
                                    {/* <FormFeedback>
                                        {!!errors.comentario && errors.comentario.message}
                                    </FormFeedback> */}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Header />
                        {(oneData && oneData.id) &&
                            <ContentProvider>
                                <Detalle oneData={oneData} agregarRenglon={agregarRenglon} setAgregarRenglon={setAgregarRenglon} />
                            </ContentProvider>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            valorEncuesta !== 0 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Send size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 21 && 'Enviar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {opcion2 === 24 && <Modal
                isOpen={modal2}
                backdrop='static'
                toggle={() => toggleModal2()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal2(0)}>Notificación</ModalHeader>
                <Form autoComplete="off" >
                    <ModalBody>
                        <Row >
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    ¡La solicitud fue marcada como recibida!
                                </Text>
                            </Col>
                            <br />
                            <br />
                            <span className='text-center'>
                                {` ${esFADN === '1' ? 'Esto notifica a Comité Olímpico Guatemalteco que la solicitud fue recibida ha conformidad por la Federación / Asociación Deportiva Nacional.' : 'Esto notifica a la Unidad Administrativa del Comité Olímpico Guatemalteco que la solicitud fue recibida ha conformidad '} `}
                            </span>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal2(0)}>
                            <Icon.X size={16} /> Cerrar
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>}

            {/* Opcion Anular Solicitud */}
            {opcion === 25 && <Modal
                isOpen={modal}
                backdrop='static'
                style={{ maxWidth: '800px', width: '80%', margin: '10px auto' }}
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 25 && 'SOLICITAR ANULACION DE'} proceso</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEnviarRevision)}>
                    <ModalBody>
                        <Row>
                            <Label className=" justify-content-between">Motivos</Label>

                            <Col>
                                <div className="form-check mb-2">
                                    <input className="form-check-input" {...register("tipoRechazo", { required: true })} type="radio" value={3} checked={true} onChange={() => setTipoRechazo(3)} />
                                    <label className="form-check-label" htmlFor={3}>
                                        Otro (Especifique)
                                    </label>
                                </div>
                            </Col>

                        </Row>
                        {/* {tipoRechazo === 3 && */}
                        <Row>
                            <Col >
                                <FormGroup>
                                    <Label for="descripcion" className=""></Label>
                                    <Controller

                                        control={control}
                                        id='descripcion'
                                        name='descripcion'
                                        render={({ field }) => (
                                            <Input
                                                rows={2}
                                                type='textarea'
                                                // readOnly={updateData}
                                                invalid={errors.descripcion && true}
                                                defaultValue=''
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.descripcion && errors.descripcion.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        {/* } */}
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Save size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion === 25 && 'Enviar Solicitud de Anulación'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>}
            {/* Opcion 50 Visualizar Gestion Solivitante */}
            {opcion === 50 && <Modal
                isOpen={modal}
                backdrop='static'
                style={{ maxWidth: '1600px', width: '80%', margin: '10px auto' }}
                toggle={() => toggleModal()}
                size='lg'
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 50 && 'VISUALIZACION DE'} Gestión</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmitEnviarRevision)}>
                    <ModalBody>
                        <Header />
                        {(oneData && oneData.id) &&
                            <ContentProvider>
                                <Detalle oneData={oneData} agregarRenglon={agregarRenglon} setAgregarRenglon={setAgregarRenglon} />
                            </ContentProvider>
                        }
                    </ModalBody>

                </Form>
            </Modal>}
        </Fragment >
    )
}

export default Formulario