import React, { Fragment } from 'react'
import { Row, Col } from 'reactstrap'
import List from './List'

const Index = () => {

    return (
        <Fragment>
            {/* <div className="page-content"> */}
            <Row>
                    <Col lg="2" sm="2">
                    </Col>
                    <Col>
                        <div className="h1 font-weight-bolder text-uppercase text-center">
                            Historico de Archivos de Insumos
                        </div>
                    </Col>
                    <Col className="d-flex justify-content-end" lg="2" sm="2">
                        {/* <Formulario /> */}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <List />
                    </Col>
                </Row>
            {/* </div> */}
        </Fragment>
    )
}

export default Index