import React, { createContext, useState, useEffect } from 'react'
import { PostRoute } from '../../../services/Private'
import { OptionsToast, returnYear } from '../../../Globales/index'
import { toast } from 'react-toastify'
import { useRequest } from '../../../hooks/useRequestSWR'
import { useSWRConfig } from 'swr'
import { useDataUser } from 'hooks/useDataUser'



export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    const [startDate, setStartDate] = useState(new Date());
    const code = useDataUser()?.dataUser?.codigoUnidad;
    const solicitante = useDataUser()?.dataUser?.i;
    const [idAccion, setAccion] = useState(0);
    const { mutate } = useSWRConfig();
    const [id, setId] = useState(0);
    const [idFADN, setIdFADN] = useState(0);
    const [mutateSaldoAccion, setMutateSaldoAccion] = useState(false);
    const [idPresupuestoPoa, setIdPresupuestoPoa] = useState(0);
    const [idModalidad, setIdModalidad] = useState(0);
    const [pdf, setPdf] = useState([]);
    const nameComponent = 'Solicitud de Compra';
    const nameController = 'Pedido';
    const nameController2 = 'Acciones';
    const nameController3 = 'TipoPedido';
    const nameController4 = 'TipoGasto';
    const nameController5 = 'Fand';
    const nameController6 = 'Persona';
    const { data: allData } = useRequest(`${nameController}/allBuscador`, 'POST', { año: returnYear(startDate) });
    const { data: labelAcciones } = useRequest(`${nameController2}/label-Unidad`, 'POST', { code: code });
    const { data: labelJefeDireccion } = useRequest(`${nameController6}/jefeDireccion`, 'POST', {});
    const { data: labelTipoPedido } = useRequest(`${nameController3}/label`, 'GET');
    const { data: labelTipoGasto } = useRequest(`${nameController4}/label`, 'GET');
    const { data: labelFand } = useRequest(`${nameController5}/label`, 'GET');
    const [modal2, setModal2] = useState(false);
    const [opcion2, setOpcion2] = useState(0);

    const toggleModal2 = async (data) => {
        setOpcion2(data)
        setModal2(!modal2)
    };
    const One2 = async (opcion, estado) => {
        if (estado === 11) {
            toggleModal2(opcion);
        }
    };
    const
        [modal, setModal] = useState(null),
        // [allData, setAllData] = useState([]),
        [oneData, setOneData] = useState([]),
        [DataBitacoraPedido, setDataBitacoraPedido] = useState([]),
        [agregarRenglon, setAgregarRenglon] = useState(0),
        [opcion, setOpcion] = useState(0),
        toggleModal = async (data) => {

            setOpcion(data)
            if (data === 1) { setOneData(null), setIdPresupuestoPoa(0) }
            setModal(!modal)

        },
        // activaMutateTotalSaldo = async (data) => {

        //     setOpcion(data)
        //     if (data === 1) { setOneData(null), setIdPresupuestoPoa(0) }
        //     setModal(!modal)

        // },
        // All = async () => {
        //     const response = await GetRoute(`${nameController}/allBuscador`)
        //     setAllData((response.length) ? response : [])
        // },
        One = async (data, opcion) => {
            const response = await PostRoute(`${nameController}/one`, { id: data.id })
            setOneData((response[0]) ? response[0] : [])
            toggleModal(opcion)

        },
        BitacoraExpediente = async (data) => {
            const response = await PostRoute(`${nameController}/storeBitacoraAbiertos`, data)
            toast.success(response.message, OptionsToast)
            // setDataBitacoraPedido((response) ? response : [])
        },
        StoreUpdate = async (data, id, type) => {
            if (type === 0) {
                toast.error('No ha seleccionado el tipo de solicitud de compra o pago (Pedido, Gasto o Caja Chica)', OptionsToast)
            } else {
                let response = []
                response = await PostRoute(`${nameController}/${!data.id ? 'store' : 'update'}`, data)
                const responseTwo = await PostRoute(`${nameController}/one`, { id: (!id ? response?.id : id) })
                setOneData((responseTwo[0]) ? responseTwo[0] : [])
                setOpcion(3)
                if (response.response === 0) {
                    toast.error(response.message, OptionsToast)
                } else {
                    toast.success(response.message, OptionsToast)
                    setAccion(responseTwo[0]?.idAccion)

                }
                // toast.success(response.message, OptionsToast)
                // toggleModal(0)
                mutate(`${nameController}/allBuscador`)
            }

        },
        Actions = async (data) => {

            const response = await PostRoute(`${nameController}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data.id })
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/allBuscador`)

        },
        UpdateEstadoPedido = async (data) => {
            const response = await PostRoute(`${nameController}/${'UpdateEstadoPedido'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/allBuscador`)

        },
        EnviarARevision = async (data, opcion) => {
            toggleModal(opcion)
            const response = await PostRoute(`${nameController}/${'enviarARevision'}`, data)
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/allBuscador`)


        },
        onSubmitEstado = (id, estado) => {
            if (estado === 21) { // Marcar como Recibida y Concluída la Gestión
                const json = {
                    id: id,
                    tipoRechazo: 4,
                    descripcion: 'Pendiente de entregar a compras con firmas respectivas y documentos de soporte',
                    estado: 34,
                }
                UpdateEstadoPedido(json)
            }
            // reset()
            // toggleModal(0)
        },
        value = {
            Actions,
            One,
            setId,
            setIdPresupuestoPoa,
            setAgregarRenglon,
            setAccion,
            StoreUpdate,
            toggleModal,
            useDataUser,
            agregarRenglon,
            idAccion,
            allData,
            idPresupuestoPoa,
            labelAcciones,
            labelFand,
            labelJefeDireccion,
            labelTipoGasto,
            labelTipoPedido,
            modal,
            nameComponent,
            oneData,
            opcion,
            solicitante,
            mutateSaldoAccion, setMutateSaldoAccion,
            code,
            setIdModalidad,
            idModalidad,
            pdf, setPdf,
            UpdateEstadoPedido,
            DataBitacoraPedido,
            BitacoraExpediente,
            EnviarARevision,

            One2,
            toggleModal2,
            opcion2,
            modal2, setModal2,
            onSubmitEstado,
            startDate, setStartDate,
            idFADN, setIdFADN
        }
    // console.log(useDataUser())
    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}
