import React, { Fragment, useState, useContext } from 'react'
import DataTable from 'react-data-table-component'
import { Row, Col, FormGroup, Input, UncontrolledButtonDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap'
import * as Icon from 'react-feather'
import { CustomText, EstadoText } from 'CustomList/index'
import { ContentContext } from './context'
import { ContentContext as ContentContextTheme } from 'components/CommonForBoth/TopbarDropdown/context'
import Moment from 'react-moment';
import 'moment/locale/es-mx';

const List = () => {

  const { allData, One, Actions } = useContext(ContentContext)
  const { theme } = useContext(ContentContextTheme)
  const [searchValue, setSearchValue] = useState(''),
    [filteredData, setFilteredData] = useState([]),
    handleFilter = e => {
      const value = e.target.value
      let updatedData = []
      setSearchValue(value)

      if (value.length) {
        updatedData = allData && allData.value !== 2009 && allData.filter(item => {
          const startsWith =
            item.nombre.toLowerCase().startsWith(value.toLowerCase())

          const includes =
            item.nombre.toLowerCase().startsWith(value.toLowerCase())

          if (startsWith) {
            return startsWith
          } else if (!startsWith && includes) {
            return includes
          } else return null
        })
        setFilteredData(updatedData)
        setSearchValue(value)
      }
    },
    Columns = [
      {
        name: 'Nombre',
        column: 'nombre',
        sortable: true,
        center: false,
        cell: row => CustomText(row['nombre'])
      },
      {
        name: 'Características',
        column: 'caracteristicas',
        sortable: true,
        center: false,
        width: '270px',
        cell: row => CustomText(row['caracteristicas'])
      },
      {
        name: 'Presentación',
        column: 'presentacion',
        sortable: true,
        center: true,
        // width: '150px',
        cell: row => CustomText(row['presentacion'])
      },
      {
        name: 'Fecha',
        column: 'unidadMedida',
        sortable: true,
        center: true,
        cell: row => (
          <Moment locale="es" fromNow>{row.creado}</Moment>
        )
      },
      {
        name: 'Solicitante',
        column: 'solicitante',
        sortable: true,
        center: false,
        // width: '300px',
        cell: row => CustomText(row['solicitante'])
      },
      {
        name: 'Estado',
        column: 'estado',
        sortable: true,
        center: true,
        width: '15%',
        cell: row => <EstadoText row={row} />
      },
      {
        name: 'Acciones',
        column: 'id',
        sortable: true,
        center: true,
        cell: row => (
          <UncontrolledButtonDropdown style={{ margin: 0 }} >

            <DropdownToggle style={{ margin: 0 }} className='pr-1' tag='span'>
              <Icon.MoreVertical size={15} />
            </DropdownToggle>
            <DropdownMenu className="!m-0" style={{ margin: 0 }} >

              <DropdownItem className="w-100" onClick={() => One(row, 2)}>
                <Icon.Eye className="me-2" size={15} />
                <span className='align-middle ml-50'>Visualizar</span>
              </DropdownItem>
              {
                row.idEstado !== 0 && row.idEstado !== 4 && <DropdownItem className="w-100" onClick={() => One(row, 4)}>
                  <Icon.Edit className="me-2" size={15} />
                  <span className='align-middle ml-50'>Gestionar</span>
                </DropdownItem>
              }
              {/* 
              <DropdownItem className="w-100" onClick={() => Actions(row)}>
                {row.idEstado === 1 ? <Icon.XCircle className="me-2" size={15} /> : <Icon.Check className="me-2" size={15} />}
                <span className='align-middle ml-50'>{row.idEstado === 1 ? 'Anular' : 'Activar'}</span>
              </DropdownItem> */}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        )
      }
    ]

  return (
    <Fragment>
      <Row className='justify-content-end mx-0'>
        <Col>

        </Col>
        <Col className='justify-content-end mt-1' md='4' sm='12'>
          <FormGroup>
            <Input
              className='dataTable-filter mb-50'
              type='text'
              bsSize='md'
              placeholder={`Buscar`}
              id='search-input'
              value={searchValue}
              onChange={handleFilter}
            />
          </FormGroup>
        </Col>
      </Row>
      <div className='card'>
        <DataTable
          // dense
          striped
          noHeader
          highlightOnHover
          pagination
          theme={theme}
          data={searchValue.length ? filteredData : (allData && allData.value !== 2009 ? allData : [])}
          columns={Columns}
          className='table-responsive mt-4'
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          paginationComponentOptions={
            {
              rowsPerPageText: '',
              rangeSeparatorText: ''
            }
          }
          noDataComponent='Sin Registros'
        /></div>
    </Fragment>
  )
}

export default List