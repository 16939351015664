import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useContext, useEffect, useState  } from 'react'
import { ContentContext } from './context'
import { Col, Label, Row, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, Card, ModalFooter } from 'reactstrap'
import * as Icon from 'react-feather'
import Detalle from './../../../POA/DetalleAcciones/form'
import Header from './header'
import { ContentProvider } from './../../../POA/DetalleAcciones/context'

const Formulario = () => {
    const { toggleModal, modal, opcion, oneData, agregarRenglon, setAgregarRenglon, total, nameComponent } = useContext(ContentContext)
    const [mostrarBoton, setMostrarBoton] = useState(true);
    const fechaCorte = new Date('2023-12-26');
  
    useEffect(() => {
      const hoy = new Date();
      if (hoy > fechaCorte) {
        setMostrarBoton(false);
      }
    }, []);

    return (
        <Fragment>
             {/* {mostrarBoton && */}
            <Button onClick={() => toggleModal(1)}  className={"m-2"}  id="crear" color="primary" size="sm">
                <Icon.Plus size={16} />
            </Button>
            {/* } */}
            <UncontrolledTooltip placement='left' target='crear' placementPrefix="bs-tooltip">
                Crear
            </UncontrolledTooltip>

            <Modal
                isOpen={modal}
                backdrop='static'
                style={{maxWidth: '1600px', width: '80%', margin: '10px auto'}}
                toggle={() => toggleModal()}
                className='modal-dialog-centered modal-xl'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 1 ? 'crear' : 'visualizar'} {` ${nameComponent}`}</ModalHeader>
                <ModalBody style={(opcion === 1 ? {  }:{backgroundColor: 'rgb(0 0 0 / 7%)', paddingBottom: '0'}) }>
                    <Header />
                    {(oneData && oneData.id) &&
                        <ContentProvider>
                            <Detalle oneData={oneData} agregarRenglon={agregarRenglon} setAgregarRenglon={setAgregarRenglon} total={total} />
                        </ContentProvider>
                    }
                </ModalBody>
            </Modal>
        </Fragment>
    )
}

export default Formulario