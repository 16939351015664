import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'
import { GetRoute, GetRouteSWR, PostRoute, PostRouteSWR, PostRouteFD } from '../services/Private'


export const useRequest = (path, method, form, config, validation) => {
    if (method === 'GET') {
        const { data } = useSWR(path, GetRoute);
        return { data }
    }
    if (method === 'GETSWR') {
        let { data, error } = useSWR(path, GetRouteSWR, config);
        return {
            // data: data?.data ?? [],
            data: data ?? [],
            isLoading: !data,
            isError: error
        }
    }
    if (method === 'GET5MILISEC') {
        const { data, error } = useSWR(path, GetRoute, { refreshInterval: 5000 });
        return {
            data: data ?? [],
            isLoading: !data,
            isError: error
        }
    }
    if (method === 'GET30MILISEC') {
        const { data, error } = useSWR(path, GetRoute, { refreshInterval: 30000 });
        return {
            data: data ?? [],
            isLoading: !data,
            isError: error
        }
    }
    if (method === 'GET2MIN') {
        const { data, error } = useSWR(path, GetRoute, { refreshInterval: 120000 });
        return {
            data: data ?? [],
            isLoading: !data,
            isError: error
        }
    }
    if (method === 'getInmutable') {
        let { data, error } = useSWRImmutable(path, GetRouteSWR);
        return {
            data: data ?? [],
            isLoading: !data,
            isError: error
        }
    }
    if (method === 'POST') {
        const { data } = useSWR([path, form], PostRoute);
        return {
            data: data ?? []
        }
    }
    if (method === 'POSTSWR') {
        let { data, error } = useSWR([path, form], PostRouteSWR, config);
        return {
            //    data: data?.data ?? [],
            data: data ?? [],
            isLoading: !data,
            isError: error
        }
    }
    if (method === 'postInmutable') {
        let { data, error } = useSWRImmutable([path, form], PostRoute);
        return {
            data: data || [],
            isLoading: !data,
            isError: error
        }
    }
    if (method === 'postInmutableValidation') {
        let { data, error } = useSWRImmutable([path, form], validation === 1 ? PostRoute : null);
        return {
            data: data || [],
            isLoading: !data,
            isError: error
        }
    }
    //path, method, form, config, validation
    if (method === 'POSTValidation') {

        const { data } = useSWR([path, form], validation === 1 ? PostRoute : null);
        return {
            data: data ?? []
        }
    }
    if (method === 'FORMDATA') {
        const { data } = useSWR([path, form], PostRouteFD());
        return { data }
    }
}