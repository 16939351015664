import React, { Fragment, useMemo, useState, useEffect, useContext } from 'react'
import { Row, Col, FormGroup, Input, Label } from 'reactstrap'
import { ContentContext } from './context'
// import { ExportPDFPersona } from '../../../utility/TemplatePdf/index'
import Pagination from '../../../components/pagination/Pagination'
// import { ExportExcelPersona } from '../../../utility/ExportExcel/index'
import CardPersona from '../../../components/cardPersona/index'

const PageSize = 8

const List = () => {
    const RoutePathServer = process.env.REACT_APP_PATH_SERVER;
    const { allData, One, Actions } = useContext(ContentContext),
        [data, setData] = useState([]),
        [currentPage, setCurrentPage] = useState(1),
        [searchValue, setSearchValue] = useState([]),
        [filteredData, setFilteredData] = useState([]),
        handleFilter = e => {
            const value = e.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            setSearchValue(value);
            let updatedData = []
      
      
            if (value?.length) {
                const updatedData = allData && allData?.filter(item => {
                    const name = item?.persona?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    const puesto = item?.puesto?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      
      
                    return name?.includes(value) || puesto?.includes(value);
                });
      
                setFilteredData(updatedData)
                setSearchValue(value)
            }
            if (value === '') {
                setFilteredData([])
            }
        }

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize
        const lastPageIndex = firstPageIndex + PageSize
        return (searchValue.length ? filteredData : data).slice(firstPageIndex, lastPageIndex)
    }, [currentPage, data, filteredData, searchValue])


    useEffect(() => {

        if (allData?.length > 0) {
            setData(allData)
        } else if (allData?.length === 0) {
            setData([])
        }
    }, [allData])
    // console.log(allData)
    return (
        <Fragment>
            <Row className='justify-content-end mx-0'>
                <Col className='justify-content-end mt-1' lg={3} md={6} sm={12}>
                    <FormGroup>
                        <Input
                            className='dataTable-filter mb-50'
                            type='text'
                            placeholder='Buscar'
                            id='search-input'
                            value={searchValue}
                            onChange={handleFilter}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <div className={`row `}>
                {
                    currentTableData.length !== 0 && currentTableData.map((item, index) => {
                        return (
                            <Col xl={3} sm={6} key={index}>
                                <CardPersona
                                    key={index}
                                    data={item}
                                    Actions={Actions}
                                    One={One}
                                    RoutePathServer={RoutePathServer}
                                />
                            </Col>
                        )
                    }
                    )
                }

                {
                    currentTableData.length === 0 &&
                    <Row>
                        <Col className="text-center rdt_TableHeadRow py-1">
                            No se ha encontrado ningún registro
                        </Col>
                    </Row>
                }

            </div>
            <Row>
                <Col className="pt-5">
                    <Pagination
                        className="pagination-bar justify-content-center"
                        currentPage={currentPage}
                        totalCount={data.length}
                        pageSize={PageSize}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </Col>
            </Row>
        </Fragment>
    )
}

export default List