import React, { Fragment, useContext, useEffect, useState } from "react"
import {
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  UncontrolledTooltip,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormFeedback,
} from "reactstrap"
import { ContentContext } from "./context"
import * as Icon from "react-feather"
import { useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { customStyles } from "../../../Globales/index"
import { DragDrop } from "@uppy/react"
import Uppy from "@uppy/core"
import "@uppy/core/dist/style.css"
import "@uppy/drag-drop/dist/style.css"
import { OptionsToast } from "Globales/index"
import { toast } from "react-toastify"
import { PostRouteFD } from "services/Private"
import Camara from "components/Camara/camara"
import DeDondeVisita from "../UbicacionVisita/form"
import { ContentProvider as ContentVisita } from "../UbicacionVisita/context"

const ErrorToast = () => (
  <>
    <div className="toastify-header">
      <div className="title-wrapper">
        <h6 className="toast-title">Tipo no admitido</h6>
      </div>
      <small className="text-muted">Hace un momento.</small>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text">
        Solo son aceptados los archivos con extensión:{" "}
        <span className="font-weight-bolder">.pdf</span>,{" "}
        {/* <span className="font-weight-bolder">.xls</span> &{" "}
          <span className="font-weight-bolder">.csv</span> !. */}
      </span>
    </div>
  </>
)

const Formulario = () => {
  const [file, setFile] = useState([])
  const [foto, setFoto] = useState(null)
  const [fileFoto, setFileFoto] = useState(null)
  const [image, setImage] = useState(null)
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  const [isNA, setIsNa] = useState(false)

  const labelEntidades = [
    {
      label: "Comite Olimpico Guatemalteco",
      value: 1,
    },
    {
      label: "Federación",
      value: 2,
    },
  ]

  function niceBytes(x) {
    let l = 0,
      n = parseInt(x, 10) || 0

    while (n >= 1024 && ++l) {
      n = n / 1024
    }

    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l]
  }
  const [load, setLoad] = useState(false),
    [name, setName] = useState(""),
    [size, setSize] = useState([])
  const {
      toggleModal,
      toggleModal2,
      modal,
      modal2,
      StoreUpdate,
      StoreUpdate2,
      opcion,
      opcion2,
      oneData,
      oneData2,
      nameComponent,
      labelUnidades,
      dataEmpleados: empleados,
      nameController,
      file: fileOne,
      mutate,
      setId,
      labelTipoVisita,
      labelUbicacionVisita,
      setFechaInicio,
      setFechaFin,
      labelFand,
    } = useContext(ContentContext),
    {
      register,
      handleSubmit,
      handleSubmit: handleSubmit2,
      formState: { errors },
      setValue,
      control,
      reset,
      watch,
    } = useForm(),
    seleccionableUnidad = watch("unidad"),
    seleccionableEntidad = watch("entidad"),
    onSubmit2 = data => {
      const json = {
        id: oneData ? oneData.id : null,
        nombre: data.nombre,
      }
      StoreUpdate2(json)
    },
    setData2 = async () => {
      await setValue("nombre", oneData.nombre)
    },
    clearData2 = async () => {
      reset({ nombre: "", codigo: "" })
    },
    onSubmit = async data => {
      // handleClose()
      // setShowLoading(true)
      if (oneData?.id) {
        setLoad(true)
        var formData = new FormData()
        // formData.append("archivo", file)
        formData.append("dpi", data.dpi)
        formData.append("comentario", data.comentario)
        formData.append("id", oneData?.id)
        formData.append("foto", fileFoto)

        if (data.entidad?.value === 2) {
          formData.append("ubicacionFand", data.fand.value)
        } else {
          formData.append(
            "visitaA",
            data.persona?.value === null ? 0 : data.persona?.value ?? 0
          )

          formData.append("ubicacion", data.unidad?.value ?? null)
        }

        formData.append("nombre", data.nombre)

        formData.append("tipoVisita", data.tipoVisita.value)
        formData.append("deDondeVisita", data.ubicacionVisita.value)
        const response = await PostRouteFD(`${nameController}/update`, formData)
        if (response.response === 1) {
          setFoto([])
          setFileFoto([])
          setFile([])
          setName("")
          setSize([])
          // Notification(responseTwo.message, responseTwo.response)
          toast.success(response.message, OptionsToast)
          setLoad(false)
          toggleModal()
          mutate(`${nameController}/all-dentro`)
          mutate([
            `${nameController}/all`,
            { fechaInicial: setFechaInicio, fechaFinal: setFechaFin },
          ])
          clearData()
        }
      } else {
        setLoad(true)
        let formData = new FormData()
        console.log(data)
        formData.append("foto", fileFoto)
        formData.append(
          "visitaA",
          data.persona?.value === null ? 0 : data.persona?.value ?? 0
        )
        formData.append("ubicacion", data.unidad?.value ?? null)
        if (data.entidad?.value === 2)
          formData.append("ubicacionFand", data.fand.value)
        formData.append("nombre", data.nombre)
        formData.append("tipoVisita", data.tipoVisita.value)
        formData.append("deDondeVisita", data.ubicacionVisita.value)
        formData.append("dpi", data.dpi)
        formData.append("comentario", data.comentario)
        console.log(formData)
        const response = await PostRouteFD(`${nameController}/store`, formData)
        if (response.response === 1) {
          setFoto([])
          setFileFoto([])
          setFile([])
          setName("")
          setSize([])
          // Notification(responseTwo.message, responseTwo.response)
          toast.success(response.message, OptionsToast)
          setLoad(false)
          toggleModal()
          mutate(`${nameController}/all-dentro`)
          mutate([
            `${nameController}/all`,
            { fechaInicial: setFechaInicio, fechaFinal: setFechaFin },
          ])
          clearData()
        } else {
          // Notification(response.message, response.response)
          toast.success(response.message, OptionsToast)
          setLoad(false)
          toggleModal()
          clearData()
        }
      }
    },
    uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        maxTotalFileSize: 100 * 1024 * 1024,
        allowedFileTypes: ["application/pdf"],
      },
      autoProceed: true,
      fieldName: "document",
    })

  uppy.on("complete", result => {
    // setShowLoad(true)

    if (
      result.successful[0].extension === "pdf"
      // ||
      // result.successful[0].extension === "csv" ||
      // result.successful[0].extension === "xls"
    ) {
      setFile(result.successful[0].data)
      setName(result.successful[0].data.name)
      setSize(result.successful[0].data.size)
      // setShowLoad(false)
    } else {
      toast.error(<ErrorToast />, { hideProgressBar: true })
      // setShowLoad(false)
    }
  })
  // EraseFile = () => {
  //     setFile([])
  //     setName("")
  // },
  const setData = async () => {
      await setValue("nombre", oneData.nombre)
      await setValue("dpi", oneData?.dpi)
      await setValue("comentario", oneData.comentario)

      if (oneData?.idVisitaA) {
        await setValue("entidad", {
          label: labelEntidades[0].label,
          value: labelEntidades[0].value,
        })
        await setValue("persona", {
          label: oneData.visitaA,
          value: oneData.idVisitaA,
        })
        await setValue("unidad", {
          label: oneData.ubicacion,
          value: oneData.idUbicacion,
        })
      }
      await setValue("tipoVisita", {
        label: oneData.tipoVisita,
        value: oneData.idTipoVisita,
      })
      await setValue("ubicacionVisita", {
        label: oneData.ubicacionVisita,
        value: oneData.idUbicacionVisita,
      })
      if (oneData?.ubicacionFand) {
        await setValue("entidad", {
          label: labelEntidades[1].label,
          value: labelEntidades[1].value,
        })
        await setValue("fand", {
          label: oneData.ubicacionFand,
          value: oneData?.idUbicacionFand,
        })
      }
    },
    clearData = async () => {
      reset({
        nombre: "",
        persona: "",
        unidad: "",
        tipoVisita: "",
        ubicacionVisita: "",
        fand: "",
        entidad: "",
        comentario: "",
        dpi: "",
      })
      setFileFoto([])
      setFoto([])
    }

  useEffect(() => {
    async function fetchMyAPI() {
      reset()
      if ((await opcion) > 1 && oneData) {
        await setData()
      }
      if ((await opcion) === 1) {
        clearData()
      }
    }

    fetchMyAPI()
    console.log(fileFoto)
  }, [opcion, oneData])
  useEffect(() => {
    async function fetchMyAPI() {
      if ((await opcion2) > 1 && oneData2) {
        await setData2()
      }
      if ((await opcion2) === 1) {
        clearData2()
      }
    }

    fetchMyAPI()
  }, [opcion2, oneData2])

  useEffect(() => {
    let abortController = new AbortController()
    async function fetchMyAPI() {
      if (seleccionableUnidad) {
        // setValue('dependencia', '');
        setId(seleccionableUnidad.value)
      }
      if (seleccionableUnidad === null || seleccionableUnidad === "") {
        setValue("dependencia", "")
        setId(0)
      }
    }
    fetchMyAPI()
    return () => {
      abortController.abort()
    }
  }, [seleccionableUnidad])

  useEffect(() => {
    let abortController = new AbortController()
    async function fetchMyAPI() {
      if (
        seleccionableEntidad === null ||
        seleccionableEntidad === "" ||
        seleccionableEntidad === undefined
      ) {
        setValue("entidad", "")
      }
    }
    fetchMyAPI()
    return () => {
      abortController.abort()
    }
  }, [seleccionableEntidad])

  useEffect(() => {
    console.log(empleados)
    if (Array.isArray(empleados)) {
      // Definir el nuevo objeto a añadir
      const nuevoElemento = { label: "No Aplica", value: null, idUnidad: 0 }
      // Añadir el nuevo objeto al array
      empleados.push(nuevoElemento)
      setIsNa(false)
    }else{
        setIsNa(true)
    }
  }, [empleados])

  return (
    <Fragment>
      <Button
        onClick={() => toggleModal(1)}
        id="crear"
        color="primary"
        size="sm"
      >
        <Icon.Plus size={16} />
      </Button>
      <UncontrolledTooltip
        placement="left"
        target="crear"
        placementPrefix="bs-tooltip"
      >
        Crear
      </UncontrolledTooltip>

      <Modal
        isOpen={modal}
        backdrop="static"
        toggle={() => toggleModal()}
        size="llg"
        className="modal-dialog-centered"
      >
        <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>
          {opcion === 1 ? "Crear" : opcion === 3 ? "Actualizar" : "Visualizar"}{" "}
          {` ${nameComponent}`}
        </ModalHeader>
        <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            {
              <Row>
                {opcion === 2 && fileOne && fileOne[0]?.foto !== "" && (
                  <Col lg="5" md="12" sm="12">
                    <Col className="text-center">
                      <div
                        className={`kt-avatar kt-avatar--outline'}`}
                        id="kt_user_avatar"
                      >
                        <div
                          className={`kt-avatar__holder2 '}`}
                          style={{
                            width: "360px !important;",
                            backgroundSize: "contain;",
                            backgroundImage: `url('data:image/${
                              fileOne && fileOne[0]?.extensionFoto
                            };base64,${fileOne && fileOne[0]?.foto}')`,
                          }}
                        ></div>
                      </div>
                    </Col>
                  </Col>
                )}
                <Col>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="nombre" className="">
                          * Nombre Visitante
                        </Label>
                        <Controller
                          defaultValue=""
                          control={control}
                          id="nombre"
                          name="nombre"
                          render={({ field }) => (
                            <Input
                              type="text"
                              invalid={errors.nombre && true}
                              disabled={opcion === 2}
                              {...field}
                            />
                          )}
                          rules={{
                            required: {
                              value: true,
                              message: "Este campo es requerido",
                            },
                          }}
                        />
                        <FormFeedback>
                          {!!errors.nombre && errors.nombre.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label for="dpi" className="">
                          * DPI
                        </Label>
                        <Controller
                          defaultValue=""
                          control={control}
                          id="dpi"
                          name="dpi"
                          render={({ field }) => (
                            <Input
                              type="text"
                              invalid={errors.dpi && true}
                              disabled={opcion === 2}
                              {...field}
                              maxLength={13}
                            />
                          )}
                          rules={{
                            required: {
                              value: true,
                              message: "Este campo es requerido",
                            },
                          }}
                        />
                        <FormFeedback>
                          {!!errors.dpi && errors.dpi.message}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>* Entidad que visita</Label>
                        <Controller
                          name="entidad"
                          control={control}
                          value={null}
                          render={({ field }) => {
                            return (
                              <Select
                                {...field}
                                isClearable
                                onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                isDisabled={opcion === 2}
                                options={labelEntidades}
                                className="selector-react1"
                                classNamePrefix="select"
                                placeholder={"Seleccione una opción"}
                                noOptionsMessage={() => "sin resultados"}
                                styles={!!errors.unidad ? customStyles : {}}
                              />
                            )
                          }}
                          rules={{
                            required: {
                              value: true,
                              message: "Este campo es requerido",
                            },
                          }}
                        />
                        <small className="text-danger">
                          {!!errors.entidad && errors.entidad.message}
                        </small>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    {seleccionableEntidad?.value === 1 && (
                      <Col>
                        <FormGroup>
                          <Label>* Unidad que visita</Label>
                          <Controller
                            name="unidad"
                            control={control}
                            defaultValue={null}
                            render={({ field }) => {
                              return (
                                <Select
                                  {...field}
                                  isClearable
                                  onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                  onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                  isDisabled={opcion === 2}
                                  options={labelUnidades}
                                  className="selector-react1"
                                  classNamePrefix="select"
                                  placeholder={"Seleccione una opción"}
                                  noOptionsMessage={() => "sin resultados"}
                                  styles={!!errors.unidad ? customStyles : {}}
                                />
                              )
                            }}
                            rules={{
                              required: {
                                value: true,
                                message: "Este campo es requerido",
                              },
                            }}
                          />
                          <small className="text-danger">
                            {!!errors.unidad && errors.unidad.message}
                          </small>
                        </FormGroup>
                      </Col>
                    )}
                    {seleccionableEntidad?.value === 1 && (
                      <Col>
                        <FormGroup>
                          <Label>* Visita A</Label>
                          <Controller
                            name="persona"
                            control={control}
                            defaultValue={null}
                            render={({ field }) => {
                              return (
                                <Select
                                  {...field}
                                  isClearable
                                  onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                  onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                  isDisabled={opcion === 2}
                                  options={!isNA ? empleados : [{ label: "No Aplica", value: null, idUnidad: 0 }]}
                                  className="selector-react2"
                                  classNamePrefix="select"
                                  placeholder={"Seleccione una opción"}
                                  noOptionsMessage={() => "sin resultados"}
                                  styles={!!errors.persona ? customStyles : {}}
                                />
                              )
                            }}
                            rules={{
                              required: {
                                value: true,
                                message: "Este campo es requerido",
                              },
                            }}
                          />
                          <small className="text-danger">
                            {!!errors.persona && errors.persona.message}
                          </small>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    {seleccionableEntidad?.value === 2 && (
                      <Col>
                        <FormGroup>
                          <Label>* Federación que visita</Label>
                          <Controller
                            name="fand"
                            control={control}
                            defaultValue={null}
                            render={({ field }) => {
                              return (
                                <Select
                                  {...field}
                                  isClearable
                                  onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                  onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                  isDisabled={opcion === 2}
                                  options={labelFand}
                                  className="selector-react1"
                                  classNamePrefix="select"
                                  placeholder={"Seleccione una opción"}
                                  noOptionsMessage={() => "sin resultados"}
                                  styles={!!errors.fand ? customStyles : {}}
                                />
                              )
                            }}
                            rules={{
                              required: {
                                value: true,
                                message: "Este campo es requerido",
                              },
                            }}
                          />
                          <small className="text-danger">
                            {!!errors.unidad && errors.unidad.message}
                          </small>
                        </FormGroup>
                      </Col>
                    )}
                    <Col>
                      <FormGroup>
                        <Label>* Tipo de Visita</Label>
                        <Controller
                          name="tipoVisita"
                          control={control}
                          defaultValue={null}
                          render={({ field }) => {
                            return (
                              <Select
                                {...field}
                                isClearable
                                onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                isDisabled={opcion === 2}
                                options={labelTipoVisita}
                                className="selector-react3"
                                classNamePrefix="select"
                                placeholder={"Seleccione una opción"}
                                noOptionsMessage={() => "sin resultados"}
                                styles={!!errors.tipoVisita ? customStyles : {}}
                              />
                            )
                          }}
                          rules={{
                            required: {
                              value: true,
                              message: "Este campo es requerido",
                            },
                          }}
                        />
                        <small className="text-danger">
                          {!!errors.tipoVisita && errors.tipoVisita.message}
                        </small>
                      </FormGroup>
                    </Col>
                    <Col lg={5} md={5} sm={5}>
                      <FormGroup>
                        <div className="d-flex justify-content-between">
                          <Label>* De donde Visita</Label>
                          {/* <Button onClick={() => toggleModal(5)} id="crear" color="primary" size="sm"> */}
                          {/* <Icon.Plus size={20} onClick={() => toggleModal2(1)} id='nuevo' className='text-primary'/>
                                        <UncontrolledTooltip placement='left' target='nuevo' placementPrefix="bs-tooltip">
                                            Registrar Nuevo
                                        </UncontrolledTooltip> */}

                          {/* </Button> */}
                        </div>
                        <Controller
                          name="ubicacionVisita"
                          control={control}
                          defaultValue={null}
                          render={({ field }) => {
                            return (
                              <Select
                                {...field}
                                isClearable
                                onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                isDisabled={opcion === 2}
                                options={labelUbicacionVisita}
                                className="selector-react3"
                                classNamePrefix="select"
                                placeholder={"Seleccione una opción"}
                                noOptionsMessage={() => "sin resultados"}
                                styles={
                                  !!errors.ubicacionVisita ? customStyles : {}
                                }
                              />
                            )
                          }}
                          rules={{
                            required: {
                              value: true,
                              message: "Este campo es requerido",
                            },
                          }}
                        />
                        <small className="text-danger">
                          {!!errors.ubicacionVisita &&
                            errors.ubicacionVisita.message}
                        </small>
                      </FormGroup>
                    </Col>

                    {opcion === 2 ? null : (
                      <Col className="align-self-center text-center" lg={1}>
                        <ContentVisita>
                          <DeDondeVisita />
                        </ContentVisita>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            }
            <Row>
              <Col>
                <FormGroup>
                  <Label
                    for="descripcionOtroServicio"
                    className="d-flex justify-content-between"
                  >
                    {" "}
                    Comentario<small>(Opcional - Máximo 500 caracteres)</small>
                  </Label>
                  <Controller
                    control={control}
                    id="comentario"
                    name="comentario"
                    render={({ field }) => (
                      <Input
                        rows={2}
                        type="textarea"
                        // readOnly={updateData}
                        // invalid={errors.comentario && true}
                        disabled={opcion === 2}
                        defaultValue=""
                        {...field}
                      />
                    )}
                    // rules={
                    //     {
                    //         required: {
                    //             value: true,
                    //             message: 'Este campo es requerido'
                    //         }
                    //     }
                    // }
                  />
                  {/* <FormFeedback>
                                        {!!errors.comentario && errors.comentario.message}
                                    </FormFeedback> */}
                </FormGroup>
              </Col>
            </Row>
            {opcion !== 2 && (
              <Col height={24}>
                <FormGroup>
                  {/* Agregar los demás campos del formulario */}
                  <Camara setFoto={setFoto} setFileFoto={setFileFoto} />
                  {foto && <img src={foto} />}
                  {/* <Button type="submit">Enviar formulario</Button> */}
                </FormGroup>
              </Col>
            )}

            {/* <Row>
                            {opcion !== 2 && <FormGroup >
                                <DragDrop
                                    uppy={uppy}
                                    locale={{
                                        strings: {
                                            dropHereOr:
                                                file?.size > 0
                                                    ? "Documento cargado y listo para subir"
                                                    : "Arrastra un documento PDF aquí o %{browse}",
                                            browse: "busca",
                                        }
                                    }}
                                />
                                {!file && file?.size === 0 && (
                                    <div className="text-danger">Debe adjuntar un archivo PDF</div>
                                )}
                            </FormGroup>}

                            {opcion === 2 && fileOne && fileOne[0]?.archivo !== "" && <object className='Visor my-3' width="100%" height="420" id='nombre-visor' aria-labelledby='cotizacion-solicitud' type="application/pdf" data={`data:application/pdf;base64,${fileOne[0]?.archivo}`} />}
                        </Row> */}
          </ModalBody>
          <ModalFooter>
            <Button id="salir" color="secondary" onClick={() => toggleModal(0)}>
              <Icon.CornerUpLeft size={16} />
            </Button>
            <UncontrolledTooltip
              placement="top"
              target="salir"
              placementPrefix="bs-tooltip"
            >
              Salir
            </UncontrolledTooltip>
            {opcion !== 2 && (
              <Fragment>
                <Button
                  color="success"
                  disabled={
                    opcion === 1 &&
                    (fileFoto === null || fileFoto?.length === 0)
                  }
                  type="submit"
                  id="accion"
                >
                  <Icon.Save size={16} />
                </Button>
                <UncontrolledTooltip
                  placement="top"
                  target="accion"
                  placementPrefix="bs-tooltip"
                >
                  {opcion !== 2 && opcion === 1 ? "Crear" : "Actualizar"}
                </UncontrolledTooltip>
              </Fragment>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      {/* {opcion2 === 1 &&
                <Modal
                    isOpen={modal2}
                    backdrop='static'
                    toggle={() => toggleModal2()}
                    className='modal-dialog-centered'
                >
                    <ModalHeader className="text-uppercase" toggle={() => toggleModal2(0)}>{opcion2 === 1 && 'crear'} De donde Visita</ModalHeader>
                    <Form autoComplete="off" onSubmit={handleSubmit2(onSubmit2)}>
                        <ModalBody>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for='nombre' className="">* Nombre</Label>
                                        <Controller
                                            defaultValue=''
                                            control={control}
                                            id='nombre'
                                            name='nombre'
                                            render={({ field }) => (
                                                <Input
                                                    type='text'
                                                    invalid={errors.nombre && true}
                                                    {...field}
                                                />
                                            )
                                            }
                                            rules={
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'Este campo es requerido'
                                                    }
                                                }
                                            }
                                        />
                                        <FormFeedback>
                                            {!!errors.nombre && errors.nombre.message}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>


                        </ModalBody>
                        <ModalFooter>
                            <Button id="salir" color='secondary' onClick={() => toggleModal2(0)}>
                                <Icon.CornerUpLeft size={16} />
                            </Button>
                            <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                                Salir
                            </UncontrolledTooltip>
                            {
                                opcion2 !== 2 &&
                                <Fragment>
                                    <Button color='success' type="submit" id="accion">
                                        <Icon.Save size={16} />
                                    </Button>
                                    <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                        {opcion2 !== 2 && opcion2 === 1 ? 'Crear' : 'Actualizar'}
                                    </UncontrolledTooltip>
                                </Fragment>
                            }
                        </ModalFooter>
                    </Form>
                </Modal>
            } */}
    </Fragment>
  )
}

export default Formulario
