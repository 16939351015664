
import React, { Fragment, useContext, useEffect } from 'react'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback } from 'reactstrap'
import { ContentContext } from './context'
import * as Icon from 'react-feather'
import { useForm, Controller } from "react-hook-form"
import Select from 'react-select'
import { customStyles } from 'Globales/index'

const Formulario = () => {
    const { toggleModal, modal, StoreUpdate, opcion, oneData, labelEjes, nameComponent } = useContext(ContentContext),
        { handleSubmit, formState: { errors }, setValue, control, reset } = useForm(),
        onSubmit = (data) => {
            const json = {
                id: (oneData) ? oneData.id : null,
                nombre: data.nombre,
                eje: data.eje.value
            }
            StoreUpdate(json)
        },
        setData = async () => {
            await setValue('nombre', oneData.nombre)
            await setValue('eje', { label: oneData.eje, value: oneData.idEje })
        },
        clearData = async () => {
            reset({ nombre: '', eje: '' })
        }

    useEffect(
        () => {
            async function fetchMyAPI() {
                if (await opcion > 1 && oneData) { await setData() }
                if (await opcion === 1) { clearData() }
            }

            fetchMyAPI()

        }, [opcion, oneData]
    )

    return (
        <Fragment>
            {/* <Button onClick={() => toggleModal(1)} className={"m-2"} id="crear" color="primary" size="sm">
                <Icon.Plus size={16} />
            </Button>
            <UncontrolledTooltip placement='left' target='crear' placementPrefix="bs-tooltip">
                Crear
            </UncontrolledTooltip> */}

            <Modal
                isOpen={modal}
                backdrop='static'
                size='lg'
                toggle={() => toggleModal()}
                className='modal-dialog-centered'
            >
                <ModalHeader className="text-uppercase" toggle={() => toggleModal(0)}>{opcion === 1 ? 'crear' : 'visualizar'} {` ${nameComponent}`}</ModalHeader>
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody>
                        <div className="col-xl-12 p-3">
                            <div className="mt-xl-3">
                                <h1 className="text-primary text-center mb-3">
                                    {oneData?.nombre}
                                </h1>
                                <div className='d-flex align-content-between justify-content-between'>
                                <span className="mb-3">Insumo Cargado el: <strong>{oneData?.fechaCreado}</strong></span> 
                                <span className="mb-3">Por: <strong>{oneData?.creadoPor}</strong></span>

                                </div>
                                <h5 className="mb-4">
                                Código de Insumo 
                                    <span className="text-muted me-2">
                                    </span>
                                    #<b>{oneData?.codigoInsumo}</b>
                                </h5>
                                <p className="text-muted mb-4 fs-5">
                                    <strong>Caracteristicas: </strong> {oneData?.caracteristicas}
                                </p>
                                <div className="mb-3 row">
                                    <div className="col-md-6">
                                        <div>
                                            <p className="text-muted fs-5">
                                                <i className="fa fa-caret-right font-size-18 align-middle text-primary me-2" />
                                                Renglón: {oneData?.renglon}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="text-muted fs-5">
                                                <i className="fa fa-caret-right font-size-18 align-middle text-primary me-2" />
                                                Presentación: {oneData?.presentacion}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="text-muted fs-5">
                                                <i className="fa fa-caret-right font-size-18 align-middle text-primary me-2" />
                                                Unidad de Medida: {oneData?.unidadMedida}
                                            </p>
                                        </div>
                                        {/* <div>
                                            <p className="text-muted fs-5">
                                                <i className="fa fa-caret-right font-size-18 align-middle text-primary me-2" />
                                                Excellent Washing and Light Fastness
                                            </p>
                                        </div> */}
                                    </div>
                                    <div className="col-md-6">
                                        <div>
                                            <p className="text-muted fs-5">
                                                <i className="fa fa-caret-right font-size-18 align-middle text-primary me-2" />
                                                Cod. Insumo: {oneData?.codigoInsumo}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="text-muted fs-5">
                                                <i className="fa fa-caret-right font-size-18 align-middle text-primary me-2" />
                                                Cod. Presentación {oneData?.codigoPresentacion}
                                            </p>
                                        </div>
                                        {/* <div>
                                            <p className="text-muted fs-5">
                                                <i className="fa fa-caret-right font-size-18 align-middle text-primary me-2" />
                                                Suitable for all weather condition
                                            </p>
                                        </div>
                                        <div>
                                            <p className="text-muted">
                                                <i className="fa fa-caret-right font-size-18 align-middle text-primary me-2" />
                                                Excellent Washing and Light Fastness
                                            </p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button id="salir" color='secondary' onClick={() => toggleModal(0)}>
                            <Icon.CornerUpLeft size={16} />
                        </Button>
                        <UncontrolledTooltip placement='top' target='salir' placementPrefix="bs-tooltip">
                            Salir
                        </UncontrolledTooltip>
                        {
                            opcion !== 2 &&
                            <Fragment>
                                <Button color='success' type="submit" id="accion">
                                    <Icon.Save size={16} />
                                </Button>
                                <UncontrolledTooltip placement='top' target='accion' placementPrefix="bs-tooltip">
                                    {opcion !== 2 && opcion === 1 ? 'Crear' : 'Actualizar'}
                                </UncontrolledTooltip>
                            </Fragment>
                        }
                    </ModalFooter>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default Formulario