import React, { useEffect, useContext, Fragment, useState } from 'react'
import { Button, Row, Col, FormGroup, Card, CardBody, CardHeader, CardTitle, CardText, Badge, Input, Label } from "reactstrap"
import PerfectScrollbar from 'react-perfect-scrollbar'
import * as Icon from 'react-feather'
import resetPass from '../../../assets/images/email/password-reset.jpg'
import sendMail from '../../../assets/images/email/Notifications.png'
import { ContentContext } from './context'
import { ContentContext as ContentContextTheme } from '../../../components/CommonForBoth/TopbarDropdown/context'

const List = () => {
    const { dataUser, dataRoles: roles, rolUsuario, showRoles, misRoles, defaultPassword, sendCredencialesUsuario, sendRolesUsuario, setMisRoles } = useContext(ContentContext),
        [count, setCount] = useState(false),
        [verificarCheck, setVerficarCheck] = useState(false)

    const Checar = (e) => {
        const Rol = e.target
        if (Rol.checked) {
            setMisRoles([...misRoles, String(Rol.value)])
            setVerficarCheck(true)
        } else {
            const r = misRoles
            for (let i = 0; i < r.length; i++) {

                if (r[i] === Rol.value) {

                    r.splice(i, 1)
                }
            }
            setMisRoles([...r])
            setVerficarCheck(false)
        }
        setCount(1)
    }

    useEffect(() => {
        if (count) {

            sendRolesUsuario(misRoles, verificarCheck)
            setCount(!count)
        }
    }, [count, verificarCheck]
    )

    return (
        <Fragment>

            <Row>
                <Col>
                    <Card>
                        <CardTitle className="w-100 p-4">
                            <Row>
                                <Col className="text-center">
                                    <h3>Asignar roles </h3>
                                </Col>
                            </Row>
                            <Row>
                                {showRoles &&
                                    <Col className="d-flex justify-content-between">
                                        <span>{dataUser.username && 'Usuario:'} <strong>{dataUser && dataUser?.email}</strong></span>
                                        <span className={`badge bg-success font-size-18 m-1`}>
                                            {misRoles && misRoles.length}
                                        </span>
                                    </Col>
                                }
                            </Row>
                        </CardTitle>
                        {showRoles &&
                            <CardBody>
                                <PerfectScrollbar style={{ maxHeight: '450px' }}>
                                    {

                                        roles && roles.length > 0 && roles.map(rol => {
                                            return (
                                                <FormGroup className="ms-2" key={rol.id}>
                                                    <div className='form-check form-check-primary font-size-20'>
                                                        <Input
                                                            type='checkbox'
                                                            key={rol.codigo}
                                                            id={String(rol.codigo)}
                                                            // bsSize="md"
                                                            value={String(rol.codigo)}
                                                            onClick={(e) => Checar(e)}
                                                            defaultChecked={rolUsuario.includes(rol.codigo)}
                                                        />
                                                        <Label className="ms-2 text-uppercase" for={String(rol.codigo)}>{rol.nombre}</Label>
                                                    </div>
                                                </FormGroup>
                                            )
                                        }
                                        )
                                    }
                                </PerfectScrollbar>
                            </CardBody>
                        }
                    </Card>
                </Col>
                <Col>
                        <div className="col">
                            <div className="card" onClick={() => defaultPassword()}>
                                <div className="row no-gutters align-items-center">
                                    <div className="col-md-4">
                                        <img className="card-img img-fluid text-center" style={{ height: '100px', }} src={resetPass} />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body">
                                            {/* <h5 className="card-title">Card title</h5> */}
                                            <p className="card-text">
                                                Restablecer contraseña
                                            </p>
                                            {showRoles && <p className="card-text">
                                                <small className="text-muted text-center">{dataUser && dataUser?.email}</small>
                                            </p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card" onClick={() => sendCredencialesUsuario()}>
                                <div className="row no-gutters align-items-center">
                                    <div className="col-md-8">
                                        <div className="card-body">
                                            {/* <h5 className="card-title">Card title</h5> */}
                                            <p className="card-text">
                                                Notificar credenciales
                                            </p>
                                            {showRoles && <p className="card-text">
                                                <small className="text-muted text-center">{dataUser && dataUser?.email}</small>
                                            </p>}
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <img className="card-img img-fluid" src={sendMail} style={{ height: '100px', }} alt='Medal Pic' />
                                    </div>
                                </div>
                            </div>
                        </div>
                </Col>
                </Row>

            {/* <Row>
                <Col lg={4}>
                </Col>
                <Col>
                    <Row>

                    </Row>
                </Col>
            </Row> */}
        </Fragment>
    )
}

export default List