
// import "react-datepicker/dist/react-datepicker.css";
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { ContentContext } from './context'
import { Form, Row, Col, FormGroup, Input, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback, Card, CardBody, CardTitle, CardSubtitle, CardText, Badge } from 'reactstrap'
import { useForm, Controller } from "react-hook-form"
import * as Icon from 'react-feather'
import Select from 'react-select'
import { formatDate, customStyles, stringToDate } from '../../../Globales/index'
import CurrencyInput from '../../../components/CurrencyInput/CurrencyInput'
import classnames from 'classnames'
import { Text } from "@nextui-org/react";
// import Objetivos from '../ObjetivosEstrategicos/index'
// import { ContentProvider } from '../ObjetivosEstrategicos/context'
import revision from '../../../assets/images/Checklist.png'
import DatePicker, { registerLocale } from "react-datepicker"
import es from "date-fns/locale/es"
registerLocale("es", es); // register it with the name you want


const Header = () => {
    // const [startDate, setStartDate] = useState(Date.parse(2019) + 1);
    const [startDate, setStartDate] = useState(new Date());
    const [validador, setValidador] = useState(false);
    const { toggleModal, modal, StoreUpdate, opcion, oneData, labelAcciones, setId, labelFinanciamiento, idPresupuestoPoa, labelTipoGasto, labelTipoPedido, labelFand, useDataUser, nameComponent, labelJefeDireccion, solicitante, setAgregarRenglon, setIdModalidad, idModalidad } = useContext(ContentContext),
        [updateData, setUpdateData] = useState(false),
        { handleSubmit, formState: { errors }, setValue, control, reset, watch } = useForm(),
        seleccionableTipoGasto = watch('tipogasto'),
        onSubmit = (data) => {
            const json = {
                id: oneData?.id ?? idPresupuestoPoa ?? null,
                accion: data.accion.value,
                fand: data?.fadn?.value ?? null,
                justificacion: data.justificacion,
                jefeDireccion: labelJefeDireccion[0]?.value ?? null,
                tipoPedido: idModalidad === 2 ? 1 : data.tipoPedido.value,
                fechaPedido: formatDate(startDate),
                solicitante: solicitante,
                modalidad: idModalidad
            }
            StoreUpdate(json, (oneData) ? oneData.id : null, idModalidad)
            // setUpdateData(true)
        },
        setData = async () => {
            await setValue('justificacion', oneData.justificacion)
            await setIdModalidad(oneData.modalidad)
            setStartDate(new Date(oneData.fechaPedido))
            await setValue('tipoPedido', { label: oneData.tipoPedido, value: oneData.idtipoPedido })
            await setValue('accion', { label: oneData.accion, value: oneData.idAccion })
            await setValue('tipogasto', { label: oneData.tipoGasto, value: oneData.idTipoGasto })
            await setValue('jefeDireccion', { label: oneData.jefeDireccion, value: oneData.idJefeDireccion })

        },
        // setJefeFirmante = async () => {
        //     setValue('jefeDireccion', { label: labelJefeDireccion[0]?.label, value: labelJefeDireccion[0]?.value })

        // },
        clearData = async () => {
            reset({ monto: '', unidad: '', financiamiento: '' })
        },
        setEstado = (estado) => {
            setUpdateData(estado)
        },
        habilitarEdicion = (e, estado) => {
            e.preventDefault()
            setEstado(estado)
        }

    useEffect(
        () => {
            if (oneData) {
                setUpdateData(true)
            }
        }, [oneData]
    )
    useEffect(
        () => {
            async function fetchMyAPI() {
                if (await opcion > 1 && oneData) { await setData() }
                if (await opcion === 1) {
                    clearData(), setUpdateData(false)
                    setIdModalidad(0)
                }
            }

            fetchMyAPI()

        }, [opcion, oneData]
    )
    // useEffect(
    //     () => {
    //         async function fetchMyAPI() {
    //             if (opcion === 1) {
    //                 setJefeFirmante()
    //             }
    //         }

    //         fetchMyAPI()

    //     }, [opcion]
    // )

    // useEffect(() => {

    //     if (opcion === 1) {
    //         setJefeFirmante()
    //     }
    // }, []);


    useEffect(() => {
        let abortController = new AbortController();
        async function fetchMyAPI() {
            if (seleccionableTipoGasto) {
                if (seleccionableTipoGasto.value === 2) {

                    setValidador(true)
                } else {
                    setValidador(false)
                }
            } if (seleccionableTipoGasto === null || seleccionableTipoGasto === '') {
                setValidador(false)
            }
        }
        fetchMyAPI()
        return () => { abortController.abort(); }
    }, [seleccionableTipoGasto])


    // console.log(labelJefeDireccion[0])
    return (
        <Fragment>
            <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                {opcion === 1 && <>
                    <div className="container py-5">
                        <h1 className='text-center mb-10'>Tipo de Compra</h1>
                        <p className="text-center">
                            {" "}
                            Elige el tipo de compra para iniciar el proceso correspondiente.{" "}
                        </p>

                        <div className="row mx-0 gy-3 px-lg-5">
                            {/* Basic */}
                            <div className="col-lg mb-md-0 mb-4">
                                <div className={`card ${idModalidad === 1 ? 'card-box-shadow-active' : ''} border shadow-none`}>
                                    <div className="card-body">
                                        <h3 className={`card-title text-center text-capitalize mb-1 ${idModalidad === 1 ? '' : 'text-muted'}`}>Pedido</h3>
                                        {/* <p className="text-center">A simple start for everyone</p>
                                        <div className="text-center">
                                            <div className="d-flex justify-content-center">
                                                <sup className="h6 pricing-currency mt-3 mb-0 me-1 text-primary">
                                                    $
                                                </sup>
                                                <h1 className="display-4 mb-0 text-primary">0</h1>
                                                <sub className="h6 pricing-duration mt-auto mb-2 text-muted fw-normal">
                                                    /month
                                                </sub>
                                            </div>
                                        </div>
                                        <ul className="ps-3 my-4 pt-2">
                                            <li className="mb-2">100 responses a month</li>
                                            <li className="mb-2">Unlimited forms and surveys</li>
                                            <li className="mb-2">Unlimited fields</li>
                                            <li className="mb-2">Basic form creation tools</li>
                                            <li className="mb-0">Up to 2 subdomains</li>
                                        </ul> */}
                                        <div className='text-center'><Button color="success" onClick={() => setIdModalidad(1)}>Elegir</Button></div>
                                    </div>
                                </div>
                            </div>
                            {/* Pro */}
                            <div className="col-lg mb-md-0 mb-4">
                                <div className={`card ${idModalidad === 2 ? 'card-box-shadow-active' : ''} border shadow-none`}>
                                    <div className="card-body position-relative">
                                        <div className="position-absolute end-0 me-4 top-0 mt-4">
                                            <span className="badge bg-label-primary">Popular</span>
                                        </div>
                                        <h3 className={`card-title text-center text-capitalize mb-1 ${idModalidad === 2 ? '' : 'text-muted'} `}>Gasto</h3>
                                        {/* <p className="text-center">For small to medium businesses</p>
                                        <div className="text-center">
                                            <div className="d-flex justify-content-center">
                                                <sup className="h6 pricing-currency mt-3 mb-0 me-1 text-primary">
                                                    $
                                                </sup>
                                                <h1 className="price-toggle price-yearly display-4 text-primary mb-0">
                                                    7
                                                </h1>
                                                <h1 className="price-toggle price-monthly display-4 text-primary mb-0 d-none">
                                                    9
                                                </h1>
                                                <sub className="h6 text-muted pricing-duration mt-auto mb-2 fw-normal">
                                                    /month
                                                </sub>
                                            </div>
                                        </div>
                                        <ul className="ps-3 my-4 pt-2">
                                            <li className="mb-2">Up to 5 users</li>
                                            <li className="mb-2">120+ components</li>
                                            <li className="mb-2">Basic support on Github</li>
                                            <li className="mb-2">Monthly updates</li>
                                            <li className="mb-0">Integrations</li>
                                        </ul> */}
                                        <div className='text-center'><Button color="primary" onClick={() => setIdModalidad(2)}>Elegir</Button></div>
                                    </div>
                                </div>
                            </div>
                            {/* Enterprise */}
                            <div className="col-lg">
                                <div className={`card ${idModalidad === 3 ? 'card-box-shadow-active' : ''} border shadow-none`}>
                                    <div className="card-body">
                                        <h3 className={`card-title text-center text-capitalize mb-1 ${idModalidad === 3 ? '' : 'text-muted'}`}>
                                            Gasto Caja Chica
                                        </h3>
                                        {/* <p className="text-center">Solution for big organizations</p>
                                        <div className="text-center">
                                            <div className="d-flex justify-content-center">
                                                <sup className="h6 text-primary pricing-currency mt-3 mb-0 me-1">
                                                    $
                                                </sup>
                                                <h1 className="price-toggle price-yearly display-4 text-primary mb-0">
                                                    16
                                                </h1>
                                                <h1 className="price-toggle price-monthly display-4 text-primary mb-0 d-none">
                                                    19
                                                </h1>
                                                <sub className="h6 pricing-duration mt-auto mb-2 fw-normal text-muted">
                                                    /month
                                                </sub>
                                            </div>
                                        </div>
                                        <ul className="ps-3 my-4 pt-2">
                                            <li className="mb-2">Up to 10 users</li>
                                            <li className="mb-2">150+ components</li>
                                            <li className="mb-2">Basic support on Github</li>
                                            <li className="mb-2">Monthly updates</li>
                                            <li className="mb-0">Speedy build tooling</li>
                                        </ul> */}
                                        <div className='text-center'><Button color="danger" onClick={() => setIdModalidad(3)}>Elegir</Button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
                <div className={opcion === 1 || !updateData ? ` p-3` : `card p-3`}>
                    <Row>
                        {updateData ?
                            <>
                                <Col>
                                    <Label >Correlativo</Label><br></br><span >{oneData?.correlativo}</span>
                                </Col> </> : null
                        }
                        <Col>
                            {updateData ? <><Label >Acción</Label><br></br><span >{oneData?.accion}</span></>
                                :
                                <FormGroup >
                                    <Label >* Acción</Label>
                                    <Controller
                                        name="accion"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                    isDisabled={updateData}
                                                    options={labelAcciones}
                                                    className='react-select'
                                                    classNamePrefix='select'
                                                    placeholder={'Seleccione una opción'}
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={(!!errors.accion) ? customStyles : {}}

                                                />
                                            )
                                        }
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <small className="text-danger">{!!errors.accion && errors.accion.message}</small>
                                </FormGroup>}
                        </Col>
                        <Col md={3}>
                            {updateData ? <><Label >Fecha Pedido</Label><br></br><span>{oneData?.fechaPedido4}</span></>
                                :

                                <FormGroup>
                                    <Label for="fechaPedido">* Fecha Solicitud</Label>
                                    <Controller
                                        name='fechaPedido'
                                        control={control}
                                        render={() => {
                                            return (
                                                < DatePicker
                                                    className={classnames('form-control invoice-edit-input due-date-picker ', {
                                                        'is-invalid': errors.fechaPedido && true
                                                    })}
                                                    selected={startDate}
                                                    disabled={true}
                                                    locale="es"
                                                    onChange={(fechaPedido) => setStartDate(fechaPedido)}
                                                    // showYearPicker
                                                    name='fechaPedido'
                                                    id="fechaPedido"
                                                // dateFormat="yyyy"
                                                />
                                            )
                                        }
                                        }
                                    // rules={
                                    //     {
                                    //         required: {
                                    //             value: true,
                                    //             message: 'Este campo es requerido'
                                    //         }
                                    //     }
                                    // }
                                    />
                                    <FormFeedback>
                                        {!!errors.fechaPedido && errors.fechaPedido.message}
                                    </FormFeedback>
                                </FormGroup>}
                        </Col>
                        {updateData ?
                            <>
                                <Col>
                                    <Label >Para</Label><br></br><span >{oneData?.tipoGasto}</span>
                                </Col> </> : null
                        }
                        {updateData ?
                            <>
                                <Col>
                                    <Label >Tipo Pedido</Label><br></br><span >{oneData?.tipoPedido}</span>
                                </Col> </> : null
                        }
                    </Row>
                    <Row>
                        <Col>
                            {updateData ? null
                                : <FormGroup >
                                    <Label >* Tipo de Gasto</Label>
                                    <Controller
                                        name="tipogasto"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                    isDisabled={updateData}
                                                    options={labelTipoGasto}
                                                    className='selector-react1'
                                                    classNamePrefix='select'
                                                    placeholder={'Seleccione una opción'}
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={(!!errors.tipogasto) ? customStyles : {}}

                                                />
                                            )
                                        }
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <small className="text-danger">{!!errors.tipogasto && errors.tipogasto.message}</small>
                                </FormGroup>}
                        </Col>
                        {validador === true &&
                            <Col>
                                <FormGroup >
                                    <Label >* FADN</Label>
                                    <Controller
                                        name="fadn"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) => {
                                            return (
                                                <Select
                                                    {...field}
                                                    isClearable
                                                    onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                    onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                    isDisabled={updateData}
                                                    options={labelFand}
                                                    className='selector-react1'
                                                    classNamePrefix='select'
                                                    placeholder={'Seleccione una opción'}
                                                    noOptionsMessage={() => 'sin resultados'}
                                                    styles={(!!errors.fadn) ? customStyles : {}}

                                                />
                                            )
                                        }
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <small className="text-danger">{!!errors.fadn && errors.fadn.message}</small>
                                </FormGroup>
                            </Col>
                        }
                    </Row>
                </div>
                <div className={opcion === 1 || !updateData ? ` p-3` : `card p-3`}>
                    <Row>
                        {updateData ? <Row >
                            <Col className="py-2" lg={12} xl={12}>
                                <Label>Justificación</Label><br></br>
                                <span style={{ fontSize: '12px' }}>{oneData?.justificacion}</span>
                            </Col>
                        </Row> : null}
                        {updateData ? null
                            :
                            <Col>
                                <FormGroup>
                                    <Label for='justificacion' className="d-flex justify-content-between">* Justificación</Label>
                                    <Controller

                                        control={control}
                                        id='justificacion'
                                        name='justificacion'
                                        render={({ field }) => (
                                            <Input
                                                rows={3}
                                                type='textarea'
                                                // readOnly={opcion === 2}
                                                disabled={updateData}
                                                invalid={errors.justificacion && true}
                                                defaultValue={null}
                                                {...field}
                                            />
                                        )
                                        }
                                        rules={
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Este campo es requerido'
                                                }
                                            }
                                        }
                                    />
                                    <FormFeedback>
                                        {!!errors.justificacion && errors.justificacion.message}
                                    </FormFeedback>
                                </FormGroup>
                            </Col>}
                    </Row>
                    <Row>
                        {updateData ? <>
                            <Col className='py-2'><Label >Solicitante</Label><br></br><span style={{ fontSize: '12px' }}>{oneData?.solicitante}</span></Col>
                            <Col className='py-2'><Label >Max. Autoridad Unidad</Label><br></br><span style={{ fontSize: '12px' }}>{oneData?.jefeDireccion}</span></Col>
                            <Col className='py-2'><Label >Estado Actual</Label><br></br><span style={{ fontSize: '12px' }}>{oneData?.estadoActual}</span></Col>
                            <Col className='py-2'><Label >Estado Siguiente</Label><br></br><span style={{ fontSize: '12px' }}>{oneData?.estadoSiguiente}</span></Col>
                            <Col className='py-2'><Label >Pre Orden de Compra</Label><br></br><span style={{ fontSize: '12px' }}>{oneData?.noPreordenCompra}</span></Col>

                            {/* <Col className='py-2'>
                                <Label >Saldo Inicial</Label><br></br>
                                <span >{oneData.montoDependenciaActual}</span>
                            </Col>
                            <Col className='py-2'>
                                <Label >Saldo Actual</Label><br></br>
                                
                                <span >{allData2 && allData2[0]?.actualProgramaDependenciaConQ ? allData2[0]?.actualProgramaDependenciaConQ : `Q.00`}</span>
                            </Col> */}
                        </>
                            :
                            // <Col>
                            //     <FormGroup>
                            //         <Label for="jefeDireccion" className="d-flex justify-content-between">
                            //             <span>* Maxima Autoridad Unidad (Firmante)</span>
                            //         </Label>
                            //         <Controller
                            //             name="jefeDireccion"
                            //             control={control}
                            //             render={({ field }) => {
                            //                 return (
                            //                     <Select
                            //                         {...field}
                            //                         isClearable
                            //                         onChange={field.onChange} //resuelve el tener que dar doble click en movil
                            //                         onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                            //                         defaultValue={{ label: labelJefeDireccion[0]?.label, value: labelJefeDireccion[0]?.value }}
                            //                         // defaultInputValue={{value: labelJefeDireccion[0]?.value }}
                            //                         options={labelJefeDireccion}
                            //                         // theme={selectThemeColors}
                            //                         // isDisabled={!allMedidaInsumo}
                            //                         // defaultValue={null}
                            //                         isDisabled={true}
                            //                         classNamePrefix='select'
                            //                         className='selector-react2'
                            //                         placeholder={'Seleccione una opción'}
                            //                         noOptionsMessage={() => 'sin resultados'}
                            //                         styles={(!!errors.jefeDireccion) ? customStyles : {}}
                            //                     />
                            //                 )
                            //             }
                            //             }
                            //             rules={
                            //                 {
                            //                     required: {
                            //                         value: true,
                            //                         message: 'Este campo es requerido'
                            //                     }
                            //                 }
                            //             }
                            //         />
                            //         <small className="text-danger">
                            //             {!!errors.jefeDireccion && errors.jefeDireccion.message}
                            //         </small>
                            //     </FormGroup>
                            // </Col>
                            null
                        }
                        {updateData ? null

                            : <>
                                {idModalidad !== 2 &&
                                    <Col>
                                        <FormGroup>
                                            <Label for="tipoPedido" className="d-flex justify-content-between">
                                                <span>* Tipo Pedido</span>
                                            </Label>
                                            <Controller
                                                name="tipoPedido"
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <Select
                                                            {...field}
                                                            isClearable
                                                            onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                            onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                            defaultValue={null}
                                                            options={labelTipoPedido}
                                                            // theme={selectThemeColors}
                                                            // isDisabled={!allMedidaInsumo}
                                                            isDisabled={updateData}
                                                            classNamePrefix='select'
                                                            className='selector-react2'
                                                            placeholder={'Seleccione una opción'}
                                                            noOptionsMessage={() => 'sin resultados'}
                                                            styles={(!!errors.tipoPedido) ? customStyles : {}}
                                                        />
                                                    )
                                                }
                                                }
                                                rules={
                                                    {
                                                        required: {
                                                            value: true,
                                                            message: 'Este campo es requerido'
                                                        }
                                                    }
                                                }
                                            />
                                            <small className="text-danger">
                                                {!!errors.tipoPedido && errors.tipoPedido.message}
                                            </small>
                                        </FormGroup>
                                    </Col>
                                }
                            </>}
                        {
                            (opcion === 50 || opcion === 80) ? null :
                                (
                                    <> <Col lg={idModalidad === 2 ? 12 : 2} xl={idModalidad === 2 ? 12 : 2} className="align-self-center text-center">

                                        {updateData &&
                                            <Fragment>
                                                <Button id='Actualizar1' color="warning" size="sm" type="button" className="text-center  me-2" onClick={(e) => habilitarEdicion(e, false)}>
                                                    <Icon.Edit size={16} />
                                                </Button>
                                                <UncontrolledTooltip placement='top' target='Actualizar1'>
                                                    Actualizar Encabezado
                                                </UncontrolledTooltip>
                                                <Button id='Renglon' color="primary" size="sm" type="button" className="text-center" onClick={() => setAgregarRenglon(1)}>
                                                    <Icon.Plus size={16} />
                                                </Button>
                                                <UncontrolledTooltip placement='top' target='Renglon'>
                                                    Agregar Renglones (insumos)
                                                </UncontrolledTooltip>
                                            </Fragment>}

                                        {
                                            !updateData &&
                                            <Fragment>
                                                <Button color="success" size="sm" type="submit" className="text-center me-2" id="Guardar">
                                                    <Icon.Save size={16} />
                                                </Button>
                                                <UncontrolledTooltip placement='top' target='Guardar'>
                                                    Guardar
                                                </UncontrolledTooltip>
                                                {
                                                    (((oneData && oneData.id)) && !updateData) &&
                                                    <Fragment>
                                                        <Button color="danger" size="sm" type="button" className="text-center" id="Cancelar1" onClick={(e) => habilitarEdicion(e, true)}>
                                                            <Icon.Slash size={16} />
                                                        </Button>
                                                        <UncontrolledTooltip placement='top' target='Cancelar1'>
                                                            Cancelar
                                                        </UncontrolledTooltip>
                                                    </Fragment>
                                                }
                                            </Fragment>
                                        }

                                    </Col>
                                    </>
                                )
                        }
                    </Row>
                </div>
            </Form>

        </Fragment >
    )
}

export default Header