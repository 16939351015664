import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Row, Col, Form, Card, FormGroup, Label, FormFeedback, Input, Button, UncontrolledTooltip } from 'reactstrap'
import { useForm, Controller } from 'react-hook-form'
import { ContentContext } from './context'
// import { selectThemeColors } from '@utils'
import * as Icon from 'react-feather'
import Select from 'react-select'
import { Text } from "@nextui-org/react";
import { formatDateHours, customStyles } from 'Globales/index'
import classnames from 'classnames'
import DatePicker from "react-datepicker"
import { ContentContext as ContentContextTheme } from 'components/CommonForBoth/TopbarDropdown/context'



const DetalleAcciones = (props) => {
    const { theme } = useContext(ContentContextTheme)
    const { handleSubmit, formState: { errors }, control, setValue, reset, watch } = useForm(),
        seleccionableTipoServicio = watch('tipoSolicitud'),
        seleccionaEquipoPrestamo = watch('tipoEquipoPrestamo'),
        [updateData, setUpdateData] = useState(false),
        { StoreUpdate, StoreUpdateSoporte, oneData, setGestion, idModalidad: idTipoActividad, labelTipoVehiculo, allData, allDataSoporte, labelUbicaciones, labelTiposServiciosST, setidTipoSolicitudSoporte, idTipoSolicitudSoporte, idEquipoPrestamo, setidEquipoPrestamo, oneDataALL, StoreUpdatePrestamo, allDataPrestamo, opcionModal } = useContext(ContentContext),

        [idDetalle, setIdDetalle] = useState(0),

        [startDate, setStartDate] = useState(new Date()),
        [startDateFin, setStartDateFin] = useState(new Date()),
        onSubmitXTipo = (data) => {
            if (idTipoActividad === 1) {
                const json = {
                    gestion: (props?.oneData?.id) ? props?.oneData?.id : null,
                    id: idDetalle ?? null,
                    fechaInicioTransporte: formatDateHours(startDate),
                    fechaFinTransporte: formatDateHours(startDateFin),
                    noPersonasTransp: Number(data?.cantidad1),
                    tipoVehiculo: Number(data?.tipoVehiculo1.value),
                    direcciones: (data?.recorrido1?.length !== 0) ? data?.recorrido1 : null,
                    tipo: '1'
                }
                StoreUpdate(json)
                setUpdateData(false)
                // console.log(json)
            }
            if (idTipoActividad === 2) {
                const json = {
                    gestion: (props?.oneData?.id) ? props?.oneData?.id : null,
                    id: idDetalle ?? null,
                    fechaInicioTransporte: formatDateHours(startDate),
                    fechaFinTransporte: formatDateHours(startDateFin),
                    noPersonasTransp: Number(data?.cantidad1),
                    tipoVehiculo: Number(data?.tipoVehiculo1.value),
                    direcciones: (data?.recorrido1?.length !== 0) ? data?.recorrido1 : null,
                    tipo: '2'
                }
                StoreUpdate(json)
                setUpdateData(false)
            }
            if (idTipoActividad === 3) {
                const json = {
                    gestion: (props?.oneData?.id) ? props?.oneData?.id : null,
                    id: idDetalle ?? null,
                    tipoServicioSoporte: Number(data?.tipoSolicitud.value),
                    descripcionOtroSoporte: (data?.descripcionOtroServicio?.length !== 0) ? data?.descripcionOtroServicio : null,
                    ubicacion: Number(data?.ubicacion.value)
                }
                StoreUpdateSoporte(json)
                setUpdateData(false)
            }
            if (idTipoActividad === 4) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: Number(data?.tipoRechazo),
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 1
                }
                UpdateEstadoPedido(json)
            }
            if (idTipoActividad === 5) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: null,
                    descripcion: null,
                    estado: 4
                }

                UpdateEstadoPedido(json)
            }
            if (idTipoActividad === 6) {
                const json = {
                    id: (oneData) ? oneData.id : null,
                    tipoRechazo: Number(data?.tipoRechazo),
                    descripcion: (data?.descripcion?.length !== 0) ? data?.descripcion : null,
                    estado: 1
                }
                UpdateEstadoPedido(json)
            }
            if (idTipoActividad === 7) {
                const json = {
                    gestion: (props?.oneData?.id) ? props?.oneData?.id : null,
                    id: idDetalle ?? null,
                    descripcionEQSol: (data?.descripcionEQSol?.length !== 0) ? data?.descripcionEQSol : null,
                    descripcionAcitividadEvento: (data?.descripcionAcitividadEvento?.length !== 0) ? data?.descripcionAcitividadEvento : null,
                    fechaUso: formatDateHours(startDate),
                    fechaDevolucion: formatDateHours(startDateFin)
                }
                StoreUpdatePrestamo(json)
                setUpdateData(false)
            }

            reset({ fechaInicio1: '', fechaFin1: '', cantidad1: '', tipoVehiculo1: '', recorrido1: '', tipoSolicitud: '', ubicacion: '', descripcionOtroServicio: '' })
            // toggleModal(0)
        },

        setEstado = (estado) => {
            setUpdateData(estado)
        },
        habilitarEdicion = (e, estado) => {
            e.preventDefault()
            setEstado(estado)
        },

        setData = async (data) => {
            setStartDate(new Date(data?.fechaInicioTransporte))
            setStartDateFin(new Date(data?.fechaFinTransporte))

            setValue('cantidad1', data?.noPersonasTransp)
            setValue('tipoVehiculo1', { label: data?.tipoVehiculo, value: data?.idTipoVehiculo })
            setValue('recorrido1', data?.direcciones)
            if (idTipoActividad === 3) {
                setValue('tipoSolicitud', { label: data?.tipoServicioSoporte, value: data?.idTipoServicioSoporte })
                setValue('ubicacion', { label: data?.ubicacion, value: data?.idUbicacion })
                setValue('descripcionOtroServicio', data?.descripcionOtroTipoServicio)
            }
            if (idTipoActividad === 7) {
                setValue('descripcionAcitividadEvento', data?.descripcionNombreActividad)
                setValue('descripcionEQSol', data?.descripcionEQSol)
                setStartDate(new Date(data?.fechaUso))
                setStartDateFin(new Date(data?.fechaDevolucion))
            }

        }

    useEffect(
        () => {
            async function fetchMyAPI() {
                if ((allData && allData?.value) === 2009) {
                    setUpdateData(false)
                    setIdDetalle(0)
                } else if (allData && allData?.value !== 2009 && allData.length > 0) {
                    setUpdateData(true)
                    if (idTipoActividad === 1 || idTipoActividad === 2) {
                        setData(allData[0])
                        setIdDetalle(allData[0]?.id)

                    }

                }
            }
            fetchMyAPI()
        }, [allData]
    )

    useEffect(
        () => {
            async function fetchMyAPI() {
                if ((allDataSoporte && allDataSoporte?.value === 2009)) {
                    setUpdateData(false)
                    setIdDetalle(0)
                } else if (allDataSoporte && allDataSoporte?.value !== 2009 && allDataSoporte.length > 0) {
                    setUpdateData(true)

                    if (idTipoActividad === 3) {
                        setData(allDataSoporte[0])
                        setIdDetalle(allDataSoporte[0]?.id)
                    }
                }
            }
            fetchMyAPI()
        }, [allDataSoporte]
    )
    useEffect(
        () => {
            async function fetchMyAPI() {
                if ((allDataPrestamo && allDataPrestamo?.value === 2009)) {
                    setUpdateData(false)
                    setIdDetalle(0)
                } else if (allDataPrestamo && allDataPrestamo?.value !== 2009 && allDataPrestamo.length > 0) {
                    setUpdateData(true)

                    if (idTipoActividad === 7) {
                        setData(allDataPrestamo[0])
                        setIdDetalle(allDataPrestamo[0]?.id)
                    }
                }
            }
            fetchMyAPI()
        }, [allDataPrestamo]
    )

    useEffect(() => {
        const functionFetch = async () => {
            if (props?.oneData && props?.oneData?.id) {
                // console.log(props?.oneData?.id)
                await setGestion(props?.oneData?.id)
            }
        }
        functionFetch()
    }, [props?.oneData])


    useEffect(() => {
        let abortController = new AbortController();
        async function fetchMyAPI() {
            if (seleccionableTipoServicio) {
                if (seleccionableTipoServicio?.value !== 0) {
                    setidTipoSolicitudSoporte(seleccionableTipoServicio?.value)
                } else {
                    setidTipoSolicitudSoporte(0)
                }
            } if (seleccionableTipoServicio === null || seleccionableTipoServicio === '') {
                setidTipoSolicitudSoporte(0)
            }
        }
        fetchMyAPI()
        return () => { abortController.abort(); }
    }, [seleccionableTipoServicio])

    // useEffect(() => {
    //     let abortController = new AbortController();
    //     async function fetchMyAPI() {
    //         if (seleccionaEquipoPrestamo) {
    //             if (seleccionaEquipoPrestamo?.value !== 0) {
    //                 setidEquipoPrestamo(seleccionaEquipoPrestamo?.value)
    //             } else {
    //                 setidEquipoPrestamo(0)
    //             }
    //         } if (seleccionaEquipoPrestamo === null || seleccionaEquipoPrestamo === '') {
    //             setidEquipoPrestamo(0)
    //         }
    //     }
    //     fetchMyAPI()
    //     return () => { abortController.abort(); }
    // }, [seleccionaEquipoPrestamo])

    return (
        <Fragment>
            {/* Actividad 1 Transporte */}
            {idTipoActividad === 1 &&
                <Form autoComplete="off"
                    onSubmit={handleSubmit(onSubmitXTipo)}
                >
                    <div className={((allData && allData?.value) === 2009 || (allData && allData?.value) === undefined) && !updateData ? ` p-3` : `card p-3`}>
                        <Row>
                            <Col>
                                <>
                                    <Row>
                                        <Col md={3}
                                        // className='d-none'
                                        >
                                            {
                                                (allData && allData?.value !== 2009 && updateData) ? <><Label >Fecha y Hora Inicio</Label><br></br><span>{allData && allData?.value !== 2009 && allData[0]?.fechaInicioTransporte2}</span></>
                                                    :
                                                    <FormGroup>
                                                        <Label for="fechaInicio1">* Fecha y Hora Inicio</Label>
                                                        <Controller
                                                            name='fechaInicio1'
                                                            control={control}
                                                            render={() => {
                                                                return (
                                                                    < DatePicker
                                                                        className={classnames('form-control invoice-edit-input due-date-picker ', {
                                                                            'is-invalid': errors.fechaInicio1 && true
                                                                        })}
                                                                        selected={startDate}
                                                                        // disabled={true}
                                                                        locale="es"
                                                                        onChange={(fechaInicio1) => setStartDate(fechaInicio1)}
                                                                        name='fechaInicio1'
                                                                        id="fechaInicio1"
                                                                        timeCaption="Hora"
                                                                        showTimeSelect
                                                                        timeFormat="HH:mm:ss"
                                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                                    />
                                                                )
                                                            }
                                                            }
                                                        // rules={
                                                        //     {
                                                        //         required: {
                                                        //             value: true,
                                                        //             message: 'Este campo es requerido'
                                                        //         }
                                                        //     }
                                                        // }
                                                        />
                                                        <FormFeedback>
                                                            {!!errors.fechaInicio1 && errors.fechaInicio1.message}
                                                        </FormFeedback>
                                                    </FormGroup>
                                            }
                                        </Col>
                                        <Col md={3}
                                        // className='d-none'
                                        >
                                            {
                                                allData && allData?.value !== 2009 && updateData ? <><Label >Fecha y Hora Fin</Label><br></br><span>{allData && allData?.value !== 2009 && allData[0]?.fechaFinTransporte2}</span></>
                                                    :
                                                    <FormGroup>
                                                        <Label for="fechaFin1">* Fecha y Hora Fin</Label>
                                                        <Controller
                                                            name='fechaFin1'
                                                            control={control}
                                                            render={() => {
                                                                return (
                                                                    < DatePicker
                                                                        className={classnames('form-control invoice-edit-input due-date-picker ', {
                                                                            'is-invalid': errors.fechaFin1 && true
                                                                        })}
                                                                        selected={startDateFin}
                                                                        // disabled={true}
                                                                        locale="es"
                                                                        onChange={(fechaFin1) => setStartDateFin(fechaFin1)}
                                                                        name='fechaFin1'
                                                                        id="fechaFin1"
                                                                        timeCaption="Hora"
                                                                        showTimeSelect
                                                                        timeFormat="HH:mm:ss"
                                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                                    />
                                                                )
                                                            }
                                                            }
                                                        // rules={
                                                        //     {
                                                        //         required: {
                                                        //             value: true,
                                                        //             message: 'Este campo es requerido'
                                                        //         }
                                                        //     }
                                                        // }
                                                        />
                                                        <FormFeedback>
                                                            {!!errors.fechaFin1 && errors.fechaFin1.message}
                                                        </FormFeedback>
                                                    </FormGroup>
                                            }
                                        </Col>
                                        <Col >
                                            {
                                                allData && allData?.value !== 2009 && updateData ? <><Label >No. de Personas a transportar</Label><br></br><span>{allData && allData?.value !== 2009 && allData[0]?.noPersonasTransp}</span></>
                                                    :
                                                    <FormGroup>
                                                        <Label for="cantidad1"> * No. de Personas a transportar </Label>
                                                        <Controller
                                                            defaultValue='undefined'
                                                            control={control}
                                                            id='cantidad1'
                                                            name='cantidad1'
                                                            render={({ field }) => (
                                                                <Input
                                                                    type='number'
                                                                    // readOnly={opcion === 2}
                                                                    invalid={errors.cantidad1 && true}
                                                                    {...field}
                                                                />
                                                            )
                                                            }
                                                            rules={
                                                                {
                                                                    required: {
                                                                        value: true,
                                                                        message: 'Este campo es requerido'
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <FormFeedback>
                                                            {!!errors.cantidad1 && errors.cantidad1.message}
                                                        </FormFeedback>
                                                    </FormGroup>
                                            }
                                        </Col>
                                        <Col>
                                            {
                                                allData && allData?.value !== 2009 && updateData ? <><Label >Tipo de Vehículo</Label><br></br><span>{allData && allData?.value !== 2009 && allData[0]?.tipoVehiculo}</span></>
                                                    :
                                                    <FormGroup>
                                                        <Label for="tipoVehiculo1" className="d-flex justify-content-between">
                                                            <span>* Tipo de Vehículo</span>
                                                        </Label>
                                                        <Controller
                                                            name="tipoVehiculo1"
                                                            control={control}
                                                            render={({ field }) => {
                                                                return (
                                                                    <Select
                                                                        {...field}
                                                                        isClearable
                                                                        onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                                        onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                                        defaultValue={null}
                                                                        options={labelTipoVehiculo}
                                                                        // theme={selectThemeColors}
                                                                        // isDisabled={!allMedidaInsumo}
                                                                        className='selector-react1'
                                                                        classNamePrefix='select'
                                                                        placeholder={'Seleccione una opción'}
                                                                        noOptionsMessage={() => 'sin resultados'}
                                                                        styles={(!!errors.tipoVehiculo1) ? customStyles : {}}
                                                                    />
                                                                )
                                                            }
                                                            }
                                                            rules={
                                                                {
                                                                    required: {
                                                                        value: true,
                                                                        message: 'Este campo es requerido'
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <small className="text-danger">
                                                            {!!errors.tipoVehiculo1 && errors.tipoVehiculo1.message}
                                                        </small>
                                                    </FormGroup>
                                            }
                                        </Col>
                                    </Row>
                                    <br></br>
                                    <Row>
                                        <Col >
                                            {
                                                allData && allData?.value !== 2009 && updateData ?
                                                    <>
                                                        <Label >Recorrido a realizar (Direcciones)</Label><br></br>
                                                        <span>{allData && allData?.value !== 2009 && allData[0]?.direcciones}</span>
                                                    </>
                                                    :
                                                    <FormGroup>
                                                        <Label for="recorrido1" className="d-flex justify-content-between">* Recorrido a realizar (Direcciones)<small>(Máximo 500 caracteres)</small></Label>
                                                        <Controller
                                                            control={control}
                                                            id='recorrido1'
                                                            name='recorrido1'
                                                            render={({ field }) => (
                                                                <Input
                                                                    {...field}
                                                                    rows={3}
                                                                    type='textarea'
                                                                    maxLength={500}
                                                                    // disabled={inputRenglon?.generaInsumo === 'No Genera insumo' ? false : true}
                                                                    invalid={errors.recorrido1 && true}
                                                                // defaultValue=''
                                                                />
                                                            )
                                                            }
                                                            rules={
                                                                {
                                                                    required: {
                                                                        value: true,
                                                                        message: 'Este campo es requerido'
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <FormFeedback>
                                                            {!!errors.recorrido1 && errors.recorrido1.message}
                                                        </FormFeedback>
                                                    </FormGroup>
                                            }
                                        </Col>
                                    </Row>
                                    {oneDataALL && oneDataALL?.value !== 2009 && oneDataALL?.idEstado === 14 &&
                                        <Row>
                                            <Col>
                                                {
                                                    oneDataALL && oneDataALL?.value !== 2009 && updateData ? <><Label className="pt-3">Persona Asignada</Label><br></br><span>{oneDataALL && oneDataALL?.value !== 2009 && oneDataALL?.personasAsignadas}</span></>
                                                        :
                                                        null
                                                }
                                            </Col>
                                            <Col>
                                                {
                                                    oneDataALL && oneDataALL?.value !== 2009 && updateData ? <><Label className="pt-3">Comentario:</Label><br></br><span>{oneDataALL && oneDataALL?.value !== 2009 && oneDataALL?.comentarioSoporte}</span></>
                                                        :
                                                        null
                                                }
                                            </Col>
                                        </Row>
                                    }
                                </>
                            </Col>
                            {(opcionModal === 21 || opcionModal === 50) ? null :
                                <Col lg={(allData && allData?.value !== 2009 && !updateData) ? 2 : 1} xl={(allData && allData?.value !== 2009 && !updateData) ? 2 : 1} className="align-self-center text-center">
                                    {
                                        (allData && allData?.value !== 2009 && updateData) &&
                                        <Fragment>
                                            <Button id='ActualizarD' color="dark" size="sm" type="button" className="text-center  me-2" onClick={(e) => habilitarEdicion(e, false)}>
                                                <Icon.Edit size={16} />
                                            </Button>
                                            <UncontrolledTooltip placement='top' target='ActualizarD'>
                                                Actualizar Detalle
                                            </UncontrolledTooltip>

                                        </Fragment>
                                    }
                                    {
                                        (allData && allData?.value !== 2009 && !updateData) &&
                                        <Fragment>
                                            <>
                                                <Button color="success" size="sm" type="submit" className="text-center me-2" id="GuardarDet">
                                                    <Icon.Save size={16} />
                                                </Button>
                                                <UncontrolledTooltip placement='top' target='GuardarDet'>
                                                    Guardar
                                                </UncontrolledTooltip>
                                            </>
                                            {
                                                (((allData && allData?.value !== 2009)) && !updateData) &&
                                                <Fragment>
                                                    <Button color="danger" size="sm" type="button" className="text-center" id="CancelarDet" onClick={(e) => habilitarEdicion(e, true)}>
                                                        <Icon.Slash size={16} />
                                                    </Button>
                                                    <UncontrolledTooltip placement='top' target='CancelarDet'>
                                                        Cancelar
                                                    </UncontrolledTooltip>
                                                </Fragment>
                                            }
                                        </Fragment>
                                    }
                                    {
                                        (allData && allData?.value === 2009) &&
                                        <Fragment>
                                            <>
                                                <Button color="success" size="sm" type="submit" className="text-center me-2" id="GuardarDet">
                                                    <Icon.Save size={16} />
                                                </Button>
                                                <UncontrolledTooltip placement='top' target='GuardarDet'>
                                                    Guardar
                                                </UncontrolledTooltip>
                                            </>
                                        </Fragment>
                                    }

                                </Col>
                            }
                        </Row>
                    </div>
                </Form>
            }
            {/* Actividad 2 Vehículo */}
            {
                idTipoActividad === 2 &&
                <Form autoComplete="off"
                    onSubmit={handleSubmit(onSubmitXTipo)}
                >
                    <div className={((allData && allData?.value) === 2009 || (allData && allData?.value) === undefined) && !updateData ? ` p-3` : `card p-3`}>
                        <Row>
                            <Col>
                                <>
                                    <Row>
                                        <Col md={3}
                                        // className='d-none'
                                        >
                                            {
                                                (allData && allData?.value !== 2009 && updateData) ? <><Label >Fecha y Hora Inicio</Label><br></br><span>{allData && allData?.value !== 2009 && allData[0]?.fechaInicioTransporte2}</span></>
                                                    :
                                                    <FormGroup>
                                                        <Label for="fechaInicio1">* Fecha y Hora Inicio</Label>
                                                        <Controller
                                                            name='fechaInicio1'
                                                            control={control}
                                                            render={() => {
                                                                return (
                                                                    < DatePicker
                                                                        className={classnames('form-control invoice-edit-input due-date-picker ', {
                                                                            'is-invalid': errors.fechaInicio1 && true
                                                                        })}
                                                                        selected={startDate}
                                                                        // disabled={true}
                                                                        locale="es"
                                                                        onChange={(fechaInicio1) => setStartDate(fechaInicio1)}
                                                                        name='fechaInicio1'
                                                                        id="fechaInicio1"
                                                                        timeCaption="Hora"
                                                                        showTimeSelect
                                                                        timeFormat="HH:mm:ss"
                                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                                    />
                                                                )
                                                            }
                                                            }
                                                        // rules={
                                                        //     {
                                                        //         required: {
                                                        //             value: true,
                                                        //             message: 'Este campo es requerido'
                                                        //         }
                                                        //     }
                                                        // }
                                                        />
                                                        <FormFeedback>
                                                            {!!errors.fechaInicio1 && errors.fechaInicio1.message}
                                                        </FormFeedback>
                                                    </FormGroup>
                                            }
                                        </Col>
                                        <Col md={3}
                                        // className='d-none'
                                        >
                                            {
                                                allData && allData?.value !== 2009 && updateData ? <><Label >Fecha y Hora Fin</Label><br></br><span>{allData && allData?.value !== 2009 && allData[0]?.fechaFinTransporte2}</span></>
                                                    :
                                                    <FormGroup>
                                                        <Label for="fechaFin1">* Fecha y Hora Fin</Label>
                                                        <Controller
                                                            name='fechaFin1'
                                                            control={control}
                                                            render={() => {
                                                                return (
                                                                    < DatePicker
                                                                        className={classnames('form-control invoice-edit-input due-date-picker ', {
                                                                            'is-invalid': errors.fechaFin1 && true
                                                                        })}
                                                                        selected={startDateFin}
                                                                        // disabled={true}
                                                                        locale="es"
                                                                        onChange={(fechaFin1) => setStartDateFin(fechaFin1)}
                                                                        name='fechaFin1'
                                                                        id="fechaFin1"
                                                                        timeCaption="Hora"
                                                                        showTimeSelect
                                                                        timeFormat="HH:mm:ss"
                                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                                    />
                                                                )
                                                            }
                                                            }
                                                        // rules={
                                                        //     {
                                                        //         required: {
                                                        //             value: true,
                                                        //             message: 'Este campo es requerido'
                                                        //         }
                                                        //     }
                                                        // }
                                                        />
                                                        <FormFeedback>
                                                            {!!errors.fechaFin1 && errors.fechaFin1.message}
                                                        </FormFeedback>
                                                    </FormGroup>
                                            }
                                        </Col>
                                        <Col >
                                            {
                                                allData && allData?.value !== 2009 && updateData ? <><Label >No. de Personas a transportar</Label><br></br><span>{allData && allData?.value !== 2009 && allData[0]?.noPersonasTransp}</span></>
                                                    :
                                                    <FormGroup>
                                                        <Label for="cantidad1"> * No. de Personas a transportar </Label>
                                                        <Controller
                                                            defaultValue='undefined'
                                                            control={control}
                                                            id='cantidad1'
                                                            name='cantidad1'
                                                            render={({ field }) => (
                                                                <Input
                                                                    type='number'
                                                                    // readOnly={opcion === 2}
                                                                    invalid={errors.cantidad1 && true}
                                                                    {...field}
                                                                />
                                                            )
                                                            }
                                                            rules={
                                                                {
                                                                    required: {
                                                                        value: true,
                                                                        message: 'Este campo es requerido'
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <FormFeedback>
                                                            {!!errors.cantidad1 && errors.cantidad1.message}
                                                        </FormFeedback>
                                                    </FormGroup>
                                            }
                                        </Col>
                                        <Col>
                                            {
                                                allData && allData?.value !== 2009 && updateData ? <><Label >Tipo de Vehículo</Label><br></br><span>{allData && allData?.value !== 2009 && allData[0]?.tipoVehiculo}</span></>
                                                    :
                                                    <FormGroup>
                                                        <Label for="tipoVehiculo1" className="d-flex justify-content-between">
                                                            <span>* Tipo de Vehículo</span>
                                                        </Label>
                                                        <Controller
                                                            name="tipoVehiculo1"
                                                            control={control}
                                                            render={({ field }) => {
                                                                return (
                                                                    <Select
                                                                        {...field}
                                                                        isClearable
                                                                        onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                                        onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                                        defaultValue={null}
                                                                        options={labelTipoVehiculo}
                                                                        // theme={selectThemeColors}
                                                                        // isDisabled={!allMedidaInsumo}
                                                                        className='selector-react1'
                                                                        classNamePrefix='select'
                                                                        placeholder={'Seleccione una opción'}
                                                                        noOptionsMessage={() => 'sin resultados'}
                                                                        styles={(!!errors.tipoVehiculo1) ? customStyles : {}}
                                                                    />
                                                                )
                                                            }
                                                            }
                                                            rules={
                                                                {
                                                                    required: {
                                                                        value: true,
                                                                        message: 'Este campo es requerido'
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <small className="text-danger">
                                                            {!!errors.tipoVehiculo1 && errors.tipoVehiculo1.message}
                                                        </small>
                                                    </FormGroup>
                                            }
                                        </Col>
                                    </Row>
                                    <br></br>
                                    <Row>
                                        <Col >
                                            {
                                                allData && allData?.value !== 2009 && updateData ?
                                                    <>
                                                        <Label >Recorrido a realizar (Direcciones)</Label><br></br>
                                                        <span>{allData && allData?.value !== 2009 && allData[0]?.direcciones}</span>
                                                    </>
                                                    :
                                                    <FormGroup>
                                                        <Label for="recorrido1" className="d-flex justify-content-between">* Recorrido a realizar (Direcciones)<small>(Máximo 500 caracteres)</small></Label>
                                                        <Controller
                                                            control={control}
                                                            id='recorrido1'
                                                            name='recorrido1'
                                                            render={({ field }) => (
                                                                <Input
                                                                    {...field}
                                                                    rows={3}
                                                                    type='textarea'
                                                                    maxLength={500}
                                                                    // disabled={inputRenglon?.generaInsumo === 'No Genera insumo' ? false : true}
                                                                    invalid={errors.recorrido1 && true}
                                                                // defaultValue=''
                                                                />
                                                            )
                                                            }
                                                            rules={
                                                                {
                                                                    required: {
                                                                        value: true,
                                                                        message: 'Este campo es requerido'
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <FormFeedback>
                                                            {!!errors.recorrido1 && errors.recorrido1.message}
                                                        </FormFeedback>
                                                    </FormGroup>
                                            }
                                        </Col>
                                    </Row>
                                </>
                            </Col>
                            {(opcionModal === 21 || opcionModal === 50) ? null :
                                <Col lg={(allData && allData?.value !== 2009 && !updateData) ? 2 : 1} xl={(allData && allData?.value !== 2009 && !updateData) ? 2 : 1} className="align-self-center text-center">
                                    {
                                        (allData && allData?.value !== 2009 && updateData) &&
                                        <Fragment>
                                            <Button id='ActualizarD' color="dark" size="sm" type="button" className="text-center  me-2" onClick={(e) => habilitarEdicion(e, false)}>
                                                <Icon.Edit size={16} />
                                            </Button>
                                            <UncontrolledTooltip placement='top' target='ActualizarD'>
                                                Actualizar Detalle
                                            </UncontrolledTooltip>

                                        </Fragment>
                                    }
                                    {
                                        (allData && allData?.value !== 2009 && !updateData) &&
                                        <Fragment>
                                            <>
                                                <Button color="success" size="sm" type="submit" className="text-center me-2" id="GuardarDet">
                                                    <Icon.Save size={16} />
                                                </Button>
                                                <UncontrolledTooltip placement='top' target='GuardarDet'>
                                                    Guardar
                                                </UncontrolledTooltip>
                                            </>
                                            {
                                                (((allData && allData?.value !== 2009)) && !updateData) &&
                                                <Fragment>
                                                    <Button color="danger" size="sm" type="button" className="text-center" id="CancelarDet" onClick={(e) => habilitarEdicion(e, true)}>
                                                        <Icon.Slash size={16} />
                                                    </Button>
                                                    <UncontrolledTooltip placement='top' target='CancelarDet'>
                                                        Cancelar
                                                    </UncontrolledTooltip>
                                                </Fragment>
                                            }
                                        </Fragment>
                                    }
                                    {
                                        (allData && allData?.value === 2009) &&
                                        <Fragment>
                                            <>
                                                <Button color="success" size="sm" type="submit" className="text-center me-2" id="GuardarDet">
                                                    <Icon.Save size={16} />
                                                </Button>
                                                <UncontrolledTooltip placement='top' target='GuardarDet'>
                                                    Guardar
                                                </UncontrolledTooltip>
                                            </>
                                        </Fragment>
                                    }

                                </Col>
                            }
                        </Row>
                    </div>
                </Form>
            }
            {/* Actividad 3 Soporte Técnico*/}
            {
                idTipoActividad === 3 &&
                <Form autoComplete="off"
                    onSubmit={handleSubmit(onSubmitXTipo)}
                >
                    <div className={((allDataSoporte && allDataSoporte?.value) === 2009 || (allDataSoporte && allDataSoporte?.value) === undefined) && !updateData ? ` p-3` : `card p-3`}>

                        <Row >
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            {
                                                allDataSoporte && allDataSoporte?.value !== 2009 && updateData ? <><Label >Tipo de Servicio</Label><br></br><span>{allDataSoporte && allDataSoporte?.value !== 2009 && allDataSoporte[0]?.tipoServicioSoporte}</span></>
                                                    :
                                                    <FormGroup >
                                                        <Label >* Tipo de Servicio</Label>
                                                        <Controller
                                                            name="tipoSolicitud"
                                                            control={control}
                                                            defaultValue={null}
                                                            render={({ field }) => {
                                                                return (
                                                                    <Select
                                                                        {...field}
                                                                        isClearable
                                                                        onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                                        onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                                        isDisabled={updateData}
                                                                        options={labelTiposServiciosST}
                                                                        className='selector-react1'
                                                                        classNamePrefix='select'
                                                                        placeholder={'Seleccione una opción'}
                                                                        noOptionsMessage={() => 'sin resultados'}
                                                                        styles={(!!errors.tipoSolicitud) ? customStyles : {}}
                                                                    />
                                                                )
                                                            }
                                                            }
                                                            rules={
                                                                {
                                                                    required: {
                                                                        value: true,
                                                                        message: 'Este campo es requerido'
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <small className="text-danger">{!!errors.tipoSolicitud && errors.tipoSolicitud.message}</small>
                                                    </FormGroup>
                                            }
                                        </Col>
                                        <Col>
                                            {
                                                allDataSoporte && allDataSoporte?.value !== 2009 && updateData ? <><Label >Ubicación</Label><br></br><span>{allDataSoporte && allDataSoporte?.value !== 2009 && allDataSoporte[0]?.ubicacion}</span></>
                                                    :
                                                    <FormGroup>
                                                        <Label for="ubicacion" className="d-flex justify-content-between">
                                                            <span>* Ubicación</span>
                                                        </Label>
                                                        <Controller
                                                            name="ubicacion"
                                                            control={control}
                                                            render={({ field }) => {
                                                                return (
                                                                    <Select
                                                                        {...field}
                                                                        isClearable
                                                                        onChange={field.onChange} //resuelve el tener que dar doble click en movil
                                                                        onBlur={event => event.preventDefault()} //resuelve el tener que dar doble click en movil
                                                                        defaultValue={null}
                                                                        options={labelUbicaciones}
                                                                        // theme={selectThemeColors}
                                                                        // isDisabled={!allMedidaInsumo}
                                                                        className='selector-react2'
                                                                        classNamePrefix='select'
                                                                        placeholder={'Seleccione una opción'}
                                                                        noOptionsMessage={() => 'sin resultados'}
                                                                        styles={(!!errors.ubicacion) ? customStyles : {}}
                                                                    />
                                                                )
                                                            }
                                                            }
                                                            rules={
                                                                {
                                                                    required: {
                                                                        value: true,
                                                                        message: 'Este campo es requerido'
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <small className="text-danger">
                                                            {!!errors.ubicacion && errors.ubicacion.message}
                                                        </small>
                                                    </FormGroup>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        {(idTipoSolicitudSoporte === 11) &&
                                            <>
                                                <Col >
                                                    {
                                                        allDataSoporte && allDataSoporte?.value !== 2009 && updateData ?
                                                            <>
                                                            <br></br>
                                                                <Label >Datos de Reunión Virtual</Label><br></br>
                                                                <span style={{ whiteSpace: 'pre-wrap' }}>{allDataSoporte && allDataSoporte?.value !== 2009 && allDataSoporte[0]?.descripcionOtroTipoServicio}</span>
                                                            </>
                                                            :
                                                            <FormGroup>
                                                                <Label for="descripcionOtroServicio" className="d-flex justify-content-between">* Datos de Reunión Virtual<small>(Máximo 500 caracteres)</small></Label>
                                                                <Controller
                                                                    control={control}
                                                                    id='descripcionOtroServicio'
                                                                    name='descripcionOtroServicio'
                                                                    render={({ field }) => (
                                                                        <Input
                                                                            {...field}
                                                                            rows={6}
                                                                            type='textarea'
                                                                            maxLength={500}
                                                                            // disabled={inputRenglon?.generaInsumo === 'No Genera insumo' ? false : true}
                                                                            invalid={errors.descripcionOtroServicio && true}
                                                                            defaultValue='NOMBRE DE LA CONFERENCIA: 
                                                                        FECHA:  
                                                                        HORARIO DE INICIO Y FINAL:
                                                                        NOMBRE DE LA PERSONA ENCARGADA O ANFITRIÓN:   
                                                                        CORREO ELECTRÓNICO:  
                                                                        TELÉFONO:  
                                                                        '
                                                                        />
                                                                    )
                                                                    }
                                                                    rules={
                                                                        {
                                                                            required: {
                                                                                value: true,
                                                                                message: 'Este campo es requerido'
                                                                            }
                                                                        }
                                                                    }
                                                                />
                                                                <FormFeedback>
                                                                    {!!errors.descripcionOtroServicio && errors.descripcionOtroServicio.message}
                                                                </FormFeedback>
                                                            </FormGroup>
                                                    }
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        {(idTipoSolicitudSoporte === 12) &&
                                            <>
                                                <Col >
                                                    {
                                                        allDataSoporte && allDataSoporte?.value !== 2009 && updateData ?
                                                            <>
                                                                <Label >Descripción de otro tipo de servicio solicitado</Label><br></br>
                                                                <span style={{ whiteSpace: 'pre-wrap' }}>{allDataSoporte && allDataSoporte?.value !== 2009 && allDataSoporte[0]?.descripcionOtroTipoServicio}</span>
                                                            </>
                                                            :
                                                            <FormGroup>
                                                                <Label for="descripcionOtroServicio" className="d-flex justify-content-between">* Otro (Describa el tipo de servicio que necesita)<small>(Máximo 500 caracteres)</small></Label>
                                                                <Controller
                                                                    control={control}
                                                                    id='descripcionOtroServicio'
                                                                    name='descripcionOtroServicio'
                                                                    render={({ field }) => (
                                                                        <Input
                                                                            {...field}
                                                                            rows={3}
                                                                            type='textarea'
                                                                            maxLength={500}
                                                                            // disabled={inputRenglon?.generaInsumo === 'No Genera insumo' ? false : true}
                                                                            invalid={errors.descripcionOtroServicio && true}
                                                                        // defaultValue=''
                                                                        />
                                                                    )
                                                                    }
                                                                    rules={
                                                                        {
                                                                            required: {
                                                                                value: true,
                                                                                message: 'Este campo es requerido'
                                                                            }
                                                                        }
                                                                    }
                                                                />
                                                                <FormFeedback>
                                                                    {!!errors.descripcionOtroServicio && errors.descripcionOtroServicio.message}
                                                                </FormFeedback>
                                                            </FormGroup>
                                                    }
                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    {oneDataALL && oneDataALL?.value !== 2009 && (oneDataALL?.idEstado === 14 || oneDataALL?.idEstado === 15) &&
                                        <Row>
                                            <Col>
                                                {
                                                    oneDataALL && oneDataALL?.value !== 2009 && updateData ? <><Label className="pt-3">Persona Asignada</Label><br></br><span>{oneDataALL && oneDataALL?.value !== 2009 && oneDataALL?.personasAsignadas}</span></>
                                                        :
                                                        null
                                                }
                                            </Col>
                                            <Col>
                                                {
                                                    oneDataALL && oneDataALL?.value !== 2009 && updateData ? <><Label className="pt-3">Descripción de lo realizado:</Label><br></br><span style={{ whiteSpace: 'pre-wrap' }}>{oneDataALL && oneDataALL?.value !== 2009 && oneDataALL?.comentarioSoporte}</span></>
                                                        :
                                                        null
                                                }
                                            </Col>
                                        </Row>
                                    }
                                </Col>

                                {(opcionModal === 21 || opcionModal === 50) ? null :
                                    <Col lg={(allDataSoporte && allDataSoporte?.value !== 2009 && !updateData) ? 2 : 1} xl={(allDataSoporte && allDataSoporte?.value !== 2009 && !updateData) ? 2 : 1} className="align-self-center text-center">
                                        {
                                            (allDataSoporte && allDataSoporte?.value !== 2009 && updateData) &&
                                            <Fragment>
                                                <Button id='ActualizarD' color="dark" size="sm" type="button" className="text-center  me-2" onClick={(e) => habilitarEdicion(e, false)}>
                                                    <Icon.Edit size={16} />
                                                </Button>
                                                <UncontrolledTooltip placement='top' target='ActualizarD'>
                                                    Actualizar Detalle
                                                </UncontrolledTooltip>

                                            </Fragment>
                                        }
                                        {
                                            (allDataSoporte && allDataSoporte?.value !== 2009 && !updateData) &&
                                            <Fragment>
                                                <>
                                                    <Button color="success" size="sm" type="submit" className="text-center me-2" id="GuardarDet">
                                                        <Icon.Save size={16} />
                                                    </Button>
                                                    <UncontrolledTooltip placement='top' target='GuardarDet'>
                                                        Guardar
                                                    </UncontrolledTooltip>
                                                </>
                                                {
                                                    (((allDataSoporte && allDataSoporte?.value !== 2009)) && !updateData) &&
                                                    <Fragment>
                                                        <Button color="danger" size="sm" type="button" className="text-center" id="CancelarDet" onClick={(e) => habilitarEdicion(e, true)}>
                                                            <Icon.Slash size={16} />
                                                        </Button>
                                                        <UncontrolledTooltip placement='top' target='CancelarDet'>
                                                            Cancelar
                                                        </UncontrolledTooltip>
                                                    </Fragment>
                                                }
                                            </Fragment>
                                        }
                                        {
                                            (allDataSoporte && allDataSoporte?.value === 2009) &&
                                            <Fragment>
                                                <>
                                                    <Button color="success" size="sm" type="submit" className="text-center me-2" id="GuardarDet">
                                                        <Icon.Save size={16} />
                                                    </Button>
                                                    <UncontrolledTooltip placement='top' target='GuardarDet'>
                                                        Guardar
                                                    </UncontrolledTooltip>
                                                </>
                                            </Fragment>
                                        }

                                    </Col>
                                }
                            </Row>
                        </Row>
                    </div>
                </Form>
            }
            {/* Actividad 4 Audiovisuales */}
            {
                idTipoActividad === 4 &&
                <Form autoComplete="off"
                // onSubmit={handleSubmit(onSubmitEstado)}
                >
                    <Card>

                        <Row >
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    TIPO 4
                                </Text>
                            </Col>
                        </Row>
                    </Card>
                </Form>
            }
            {/* Actividad 5 Mantenimiento */}
            {
                idTipoActividad === 5 &&
                <Form autoComplete="off"
                // onSubmit={handleSubmit(onSubmitEstado)}
                >
                    <Card>

                        <Row >
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    TIPO 5
                                </Text>
                            </Col>
                        </Row>
                    </Card>
                </Form>
            }
            {/* Actividad 6 Infraestructura */}
            {
                idTipoActividad === 6 &&
                <Form autoComplete="off"
                // onSubmit={handleSubmit(onSubmitEstado)}
                >
                    <Card>

                        <Row >
                            <Col className="text-center">
                                <Text
                                    h1
                                    size={20}
                                    weight="medium"
                                >
                                    TIPO 6
                                </Text>
                            </Col>
                        </Row>
                    </Card>
                </Form>
            }
            {/* Actividad 7 Préstamo de Equipo */}
            {
                idTipoActividad === 7 &&
                <Form autoComplete="off"
                    onSubmit={handleSubmit(onSubmitXTipo)}
                >
                    <div className={((allData && allData?.value) === 2009 || (allData && allData?.value) === undefined) && !updateData ? ` p-3` : `card p-3`}>
                        <Row>
                            <Col>
                                <>
                                    <Row>
                                        <Col md={3}
                                        // className='d-none'
                                        >
                                            {
                                                (allDataPrestamo && allDataPrestamo?.value !== 2009 && updateData) ? <><Label >Fecha Inicio Prestamo</Label><br></br><span>{allDataPrestamo && allDataPrestamo?.value !== 2009 && allDataPrestamo[0]?.fechaUso2}</span></>
                                                    :
                                                    <FormGroup>
                                                        <Label for="fechaUso">* Fecha Inicio Prestamo</Label>
                                                        <Controller
                                                            name='fechaUso'
                                                            control={control}
                                                            render={() => {
                                                                return (
                                                                    < DatePicker
                                                                        className={classnames('form-control invoice-edit-input due-date-picker ', {
                                                                            'is-invalid': errors.fechaUso && true
                                                                        })}
                                                                        selected={startDate}
                                                                        // disabled={true}
                                                                        locale="es"
                                                                        onChange={(fechaUso) => setStartDate(fechaUso)}
                                                                        name='fechaUso'
                                                                        id="fechaUso"
                                                                        dateFormat={"dd/MM/yyyy"}
                                                                        // endDate={new Date()}
                                                                        minDate={new Date()} // Esto asegurará que no se puedan seleccionar años anteriores
                                                                    // maxDate={new Date()} // Esto asegurará que no se puedan seleccionar años futuros
                                                                    // timeCaption="Hora"
                                                                    // showTimeSelect
                                                                    // timeFormat="HH:mm:ss"
                                                                    // dateFormat="MMMM d, yyyy h:mm aa"
                                                                    />
                                                                )
                                                            }
                                                            }
                                                        // rules={
                                                        //     {
                                                        //         required: {
                                                        //             value: true,
                                                        //             message: 'Este campo es requerido'
                                                        //         }
                                                        //     }
                                                        // }
                                                        />
                                                        <FormFeedback>
                                                            {!!errors.fechaUso && errors.fechaUso.message}
                                                        </FormFeedback>
                                                    </FormGroup>
                                            }
                                        </Col>
                                        <Col md={3}
                                        // className='d-none'
                                        >
                                            {
                                                allDataPrestamo && allDataPrestamo?.value !== 2009 && updateData ? <><Label >Fecha Fin Prestamo</Label><br></br><span>{allDataPrestamo && allDataPrestamo?.value !== 2009 && allDataPrestamo[0]?.fechaDevolucion2}</span></>
                                                    :
                                                    <FormGroup>
                                                        <Label for="fechaDevolucion">* Fecha Fin Prestamo</Label>
                                                        <Controller
                                                            name='fechaDevolucion'
                                                            control={control}
                                                            render={() => {
                                                                return (
                                                                    < DatePicker
                                                                        className={classnames('form-control invoice-edit-input due-date-picker ', {
                                                                            'is-invalid': errors.fechaDevolucion && true
                                                                        })}
                                                                        selected={startDateFin}
                                                                        // disabled={true}
                                                                        locale="es"
                                                                        onChange={(fechaDevolucion) => setStartDateFin(fechaDevolucion)}
                                                                        name='fechaDevolucion'
                                                                        id="fechaDevolucion"
                                                                        dateFormat={"dd/MM/yyyy"}
                                                                        minDate={new Date()}
                                                                    // timeCaption="Hora"
                                                                    // showTimeSelect
                                                                    // timeFormat="HH:mm:ss"
                                                                    // dateFormat="MMMM d, yyyy h:mm aa"
                                                                    />
                                                                )
                                                            }
                                                            }
                                                        // rules={
                                                        //     {
                                                        //         required: {
                                                        //             value: true,
                                                        //             message: 'Este campo es requerido'
                                                        //         }
                                                        //     }
                                                        // }
                                                        />
                                                        <FormFeedback>
                                                            {!!errors.fechaDevolucion && errors.fechaDevolucion.message}
                                                        </FormFeedback>
                                                    </FormGroup>
                                            }
                                        </Col>
                                        <Col >
                                            {
                                                allDataPrestamo && allDataPrestamo?.value !== 2009 && updateData ? <><Label >Nombre de Actividad o evento </Label><br></br><span>{allDataPrestamo && allDataPrestamo?.value !== 2009 && allDataPrestamo[0]?.descripcionNombreActividad}</span></>
                                                    :
                                                    <FormGroup>
                                                        <Label for="descripcionAcitividadEvento"> * Nombre de Actividad o evento  </Label>
                                                        <Controller
                                                            defaultValue=''
                                                            control={control}
                                                            id='descripcionAcitividadEvento'
                                                            name='descripcionAcitividadEvento'
                                                            render={({ field }) => (
                                                                <Input
                                                                    type='text'
                                                                    // readOnly={opcion === 2} 
                                                                    invalid={errors.descripcionAcitividadEvento && true}
                                                                    {...field}
                                                                />
                                                            )
                                                            }
                                                            rules={
                                                                {
                                                                    required: {
                                                                        value: true,
                                                                        message: 'Este campo es requerido'
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <FormFeedback>
                                                            {!!errors.descripcionAcitividadEvento && errors.descripcionAcitividadEvento.message}
                                                        </FormFeedback>
                                                    </FormGroup>
                                            }
                                        </Col>

                                    </Row>
                                    <br></br>
                                    <Row>
                                        <Col >
                                            {
                                                allDataPrestamo && allDataPrestamo?.value !== 2009 && updateData ?
                                                    <>
                                                        <Label >Descripción del equipo solicitado</Label><br></br>
                                                        <span>{allDataPrestamo && allDataPrestamo?.value !== 2009 && allDataPrestamo[0]?.descripcionEQSol}</span>
                                                    </>
                                                    :
                                                    <FormGroup>
                                                        <Label for="descripcionEQSol" className="d-flex justify-content-between">* Descripción del equipo solicitado<small>(Máximo 500 caracteres)</small></Label>
                                                        <Controller
                                                            control={control}
                                                            id='descripcionEQSol'
                                                            name='descripcionEQSol'
                                                            render={({ field }) => (
                                                                <Input
                                                                    {...field}
                                                                    rows={3}
                                                                    type='textarea'
                                                                    maxLength={500}
                                                                    // disabled={inputRenglon?.generaInsumo === 'No Genera insumo' ? false : true}
                                                                    invalid={errors.descripcionEQSol && true}
                                                                // defaultValue=''
                                                                />
                                                            )
                                                            }
                                                            rules={
                                                                {
                                                                    required: {
                                                                        value: true,
                                                                        message: 'Este campo es requerido'
                                                                    }
                                                                }
                                                            }
                                                        />
                                                        <FormFeedback>
                                                            {!!errors.descripcionEQSol && errors.descripcionEQSol.message}
                                                        </FormFeedback>
                                                    </FormGroup>
                                            }
                                        </Col>
                                    </Row>
                                    {oneDataALL && oneDataALL?.value !== 2009 && (oneDataALL?.idEstado === 20 || oneDataALL?.idEstado === 15) &&
                                        <>
                                            {/* <Row>
                                                <Col >

                                                    <Label >Descripción del equipo solicitado</Label><br></br>
                                                    <span>{allDataPrestamo && allDataPrestamo?.value !== 2009 && allDataPrestamo[0]?.descripcionEQSol}</span>

                                                </Col>
                                            </Row> */}
                                            <Row>
                                                <Col>
                                                    {
                                                        oneDataALL && oneDataALL?.value !== 2009 && updateData ? <><Label className="pt-3">Persona Asignada</Label><br></br><span>{oneDataALL && oneDataALL?.value !== 2009 && oneDataALL?.personasAsignadas}</span></>
                                                            :
                                                            null
                                                    }
                                                </Col>
                                                <Col>
                                                    {
                                                        oneDataALL && oneDataALL?.value !== 2009 && updateData ? <><Label className="pt-3">Descripción de lo realizado:</Label><br></br><span>{oneDataALL && oneDataALL?.value !== 2009 && oneDataALL?.comentarioSoporte}</span></>
                                                            :
                                                            null
                                                    }
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </>
                            </Col>
                            {(opcionModal === 21 || opcionModal === 50) ? null :
                                <Col lg={(allDataPrestamo && allDataPrestamo?.value !== 2009 && !updateData) ? 2 : 1} xl={(allDataPrestamo && allDataPrestamo?.value !== 2009 && !updateData) ? 2 : 1} className="align-self-center text-center">
                                    {
                                        (allDataPrestamo && allDataPrestamo?.value !== 2009 && updateData) &&
                                        <Fragment>
                                            <Button id='ActualizarD' color="dark" size="sm" type="button" className="text-center  me-2" onClick={(e) => habilitarEdicion(e, false)}>
                                                <Icon.Edit size={16} />
                                            </Button>
                                            <UncontrolledTooltip placement='top' target='ActualizarD'>
                                                Actualizar Detalle
                                            </UncontrolledTooltip>

                                        </Fragment>
                                    }
                                    {
                                        (allDataPrestamo && allDataPrestamo?.value !== 2009 && !updateData) &&
                                        <Fragment>
                                            <>
                                                <Button color="success" size="sm" type="submit" className="text-center me-2" id="GuardarDet">
                                                    <Icon.Save size={16} />
                                                </Button>
                                                <UncontrolledTooltip placement='top' target='GuardarDet'>
                                                    Guardar
                                                </UncontrolledTooltip>
                                            </>
                                            {
                                                (((allDataPrestamo && allDataPrestamo?.value !== 2009)) && !updateData) &&
                                                <Fragment>
                                                    <Button color="danger" size="sm" type="button" className="text-center" id="CancelarDet" onClick={(e) => habilitarEdicion(e, true)}>
                                                        <Icon.Slash size={16} />
                                                    </Button>
                                                    <UncontrolledTooltip placement='top' target='CancelarDet'>
                                                        Cancelar
                                                    </UncontrolledTooltip>
                                                </Fragment>
                                            }
                                        </Fragment>
                                    }
                                    {
                                        (allDataPrestamo && allDataPrestamo?.value === 2009) &&
                                        <Fragment>
                                            <>
                                                <Button color="success" size="sm" type="submit" className="text-center me-2" id="GuardarDet">
                                                    <Icon.Save size={16} />
                                                </Button>
                                                <UncontrolledTooltip placement='top' target='GuardarDet'>
                                                    Guardar
                                                </UncontrolledTooltip>
                                            </>
                                        </Fragment>
                                    }

                                </Col>
                            }
                        </Row>
                    </div>
                </Form>
            }
            {/* <List agregarRenglon={props.setAgregarRenglon} /> */}
        </Fragment >
    )
}

export default DetalleAcciones