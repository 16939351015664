import React, { Row, Col } from 'reactstrap'
import { ContentProvider } from './context'
import Formulario from './VisualizerPDF'
const Index = () => {
    return (
        <ContentProvider>
            <Formulario />
        </ContentProvider>
    )
}

export default Index