import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

// import logo from "./../../../public/media/logos/SIPA.png";
import logoLightPng from "../../assets/images/logo-light.png";
import logoPNG from "../../assets/images/COG/logo-cog-oficial-1.png";
import logoDark from "../../assets/images/logo-dark.png";
import { toAbsoluteUrl } from '../../helpers/AssetHelper'
const Sidebar = props => {

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">

              <img src={toAbsoluteUrl("/media/logos/SIPA.png")} alt="" height="22" />
              {/* SIPA */}
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="17" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={toAbsoluteUrl("/media/logos/SIPA-2.png")} alt="" height="20" width={"25"} />
            </span>
            <span className="logo-lg">
              <img src={logoPNG} alt="" height="90" style={{paddingTop: '15px', marginRight: '2px'}} />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
