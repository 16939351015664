import React, { Fragment, useContext, useState, useEffect, useMemo } from 'react'
import { Row, Col, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { PDF } from './export/PDF'
import { ContentContext } from './context'
import * as Icon from 'react-feather'
import Moment from 'react-moment';
import 'moment/locale/es-mx';
import Pagination from 'components/pagination/Pagination'
import { toAbsoluteUrl } from 'helpers/index'
import { handleSwtAlert } from 'components/sweetAlert/index'
const PageSize = 6


export const List = () => {
    const { One, allData, Actions } = useContext(ContentContext),
        [currentPage, setCurrentPage] = useState(1),
        [dataAll, setDataAll] = useState([]),

        currentTableData = useMemo(() => {
            const firstPageIndex = (currentPage - 1) * PageSize
            const lastPageIndex = firstPageIndex + PageSize
            return (dataAll).slice(firstPageIndex, lastPageIndex)
        }, [currentPage, dataAll])
    useEffect(() => {

        if (allData !== undefined && allData !== null && allData?.response !== 2009) {
            setDataAll(allData)
        } else {
            setDataAll([])
        }
    }, [allData])

    return (
        <Fragment>
            <div className={`row g-6 g-xl-9 ${currentTableData.length !== 0 && 'my-1'}`}>
                {
                    currentTableData.length > 0 && currentTableData.map((item, index) => {
                        return (
                            <div className="col-md-6 col-lg-3 col-xl-3" style={{ cursor: 'pointer' }} key={index} >
                                <div className="card h-100 ">
                                    <div className='card-title mb-0'>
                                        <Row >
                                            <Col className='text-end m-3'>
                                                <div>
                                                    <div className='me-0'>
                                                        <button
                                                            className='btn btn-sm btn-icon btn-dark btn-active-light-primary mb-0'
                                                            type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                                        >
                                                            <Icon.MoreVertical size={14} />
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <span onClick={() => One(item)}>
                                                                <PDF />
                                                            </span>
                                                            {/* <button
                                                                className="dropdown-item"
                                                                onClick={() => handleSwtAlert(item, Actions)}
                                                            >
                                                                {item.idEstado === 1 ?
                                                                    <Fragment>
                                                                        <Icon.Trash size={14} /> Eliminar
                                                                    </Fragment> :
                                                                    <Fragment>
                                                                        <Icon.Check size={14} /> Activar
                                                                    </Fragment>
                                                                }</button> */}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <Row>
                                        <Col>
                                            <div className="card-body d-flex justify-content-center text-center flex-column" >
                                                <span
                                                    className="text-gray-800 text-hover-primary d-flex flex-column text-center"
                                                >
                                                    <div className="symbol symbol-75px mb-2">
                                                        {item.extension === ".pdf" ?
                                                            <img style={{ height: '87px', width: '70px' }} src={toAbsoluteUrl("/media/logos/pdf.png")} alt="" />
                                                            :
                                                            <span color="transparent" className="cursor-pointer" style={{ outline: "none", backgroundColor: "#FFF" }} id="AdjuntoIMG">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="79" height="79" fill="currentColor" className="bi bi-card-image" viewBox="0 0 16 16">
                                                                    <path style={{ color: "orange" }} d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                    <path style={{ color: "#28c76f" }} d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
                                                                </svg>
                                                            </span>
                                                        }
                                                    </div>
                                                </span>
                                                <span className='fw-semibold fs-8 text-gray-600'>Subido <Moment locale="es" fromNow>{item.creado}</Moment> </span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    currentTableData.length === 0 &&
                    <div className={`row g-6 g-xl-9 ${currentTableData.length !== 0 && 'my-1'}`}>
                        <Row>
                            <Col className="text-center rdt_TableHeadRow py-1">
                                No se ha encontrado ningún registro
                            </Col>
                        </Row>
                    </div>
                }
            </div>
            <Row>
                <Col className="pt-5">
                    <Pagination
                        className="pagination-bar justify-content-center"
                        currentPage={currentPage}
                        totalCount={dataAll.length}
                        pageSize={PageSize}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                </Col>
            </Row>

        </Fragment >
    )
}

export default List