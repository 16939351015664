import React, { createContext, useState } from 'react'
import { PostRoute } from 'services/Private'
import { OptionsToast } from 'Globales/index'
import { toast } from 'react-toastify'
import { useRequest } from 'hooks/useRequestSWR'
import { useSWRConfig } from 'swr'
import { useRouteCode } from 'hooks/useRouteCode'

export const ContentContext = createContext()

export const ContentProvider = ({ children }) => {
    // Modal Init
    const code = useRouteCode()?.codigo;
    const { mutate } = useSWRConfig();
    const nameComponent = 'Insumo';
    const nameController = 'Insumos';
    const [renglon, setRenglon] = useState([]);
    const nameController2 = 'Renglon';
    const { data: allData } = useRequest(`${nameController}/All`, 'POST', { renglon: renglon });
    // const { data: allData } = useRequest(`${nameController}/all`, 'GET');
    const { data: labelRenglones } = useRequest(`${nameController}/renglones`, 'GET');
    const
        [modal, setModal] = useState(null),
        // [allData, setAllData] = useState([]),
        [oneData, setOneData] = useState([]),
        [opcion, setOpcion] = useState(0),
        toggleModal = async (data) => {
            
            setOpcion(data)
            if (data === 1) { setOneData(null) }
            setModal(!modal)
            
        },
        // All = async () => {
        //     const response = await GetRoute(`${nameController}/all`)
        //     setAllData((response.length) ? response : [])
        // },
        One = async (data, opcion) => {
            
            const response = await PostRoute(`${nameController}/one`, { id: data.id })
            setOneData((response[0]) ? response[0] : [])
            toggleModal(opcion)
            
        },
        StoreUpdate = async (data) => {
            
            let response = []
            response = await PostRoute(`${nameController}/${!data.id ? 'store' : 'update'}`, data)
            toast.success(response.message, OptionsToast)
            toggleModal(0)
            mutate(`${nameController}/all`)
            
        },
        Actions = async (data) => {
            
            const response = await PostRoute(`${nameController}/${data.estado === 1 ? 'destroy' : 'active'}`, { id: data.id })
            toast.success(response.message, OptionsToast)
            mutate(`${nameController}/all`)
            
        },
        value = {
            One,
            Actions,
            StoreUpdate,
            setRenglon,
            toggleModal,
            allData,
            labelRenglones,
            modal,
            nameComponent,
            oneData,
            opcion
        }

    // Modal End

    return (
        <ContentContext.Provider value={value}>
            {children}
        </ContentContext.Provider>
    )
}
