import { Bounce } from 'react-toastify'

export const OptionsToast = { position: 'bottom-right', transition: Bounce }

export function formatDate(date) {
    const d = new Date(date)

    return `${d.getFullYear()}-${(`00${(d.getMonth() + 1)}`).slice(-2)}-${(`00${d.getDate()}`).slice(-2)}`
}

export function stringToDate(_date, _format, _delimiter) {
    const formatLowerCase = _format.toLowerCase()
    const formatItems = formatLowerCase.split(_delimiter)
    const dateItems = _date.split(_delimiter)
    const monthIndex = formatItems.indexOf("mm")
    const dayIndex = formatItems.indexOf("dd")
    const yearIndex = formatItems.indexOf("yyyy")
    let month = parseInt(dateItems[monthIndex])
    month -= 1
    const formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex])
    return formatedDate
}

export function formatDateHours(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear(),
      datetext = d.getHours() + ":" + d.getMinutes()
  
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
  
    return [year, month, day].join('-') + ' ' + datetext
  }

  

  export function returnYear(date) {
    var d = new Date(date),
      year = d.getFullYear()
  
    return year + ''
  }

  
export const styleClass = base => (
    {
        ...base,
        border: 'solid 1px #fd397a !important',
        boxShadow: 'none',
        borderRadius: '5px'
    }
)

export const customStyles = {
    control: styleClass
}

export function dataURItoBlob(dataURI) {

    const byteString = atob(dataURI.split(',')[1])
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
    }
    const blob = new Blob([ab], { type: mimeString })

    return blob
}

export default {
    OptionsToast,
    customStyles,
    dataURItoBlob,
    stringToDate,
    formatDateHours,
    formatDate,
    returnYear
}